import React from "react";
import { GLOBAL_THEME } from "../utils/constants";

export const initialThemeState = {
  globalTheme: GLOBAL_THEME.LIGHT,
  setGlobalTheme: () => null,
};

const ThemeContext = React.createContext(initialThemeState);
export default ThemeContext;
