import React from "react";
import LayoutDefault from "../components/LayoutDefault";
import BroadcastTableViewHeader from "../components/Broadcast/BroadcastTableViewHeader";
import BroadcastTableView from "../components/Broadcast/BroadcastTableView";
import SnackbarCollection from "../components/hub/SnackbarCollection";

export default function BroadcastPage() {
  return (
    <>
      <LayoutDefault className="inbox">
        <>
          <BroadcastTableViewHeader />
          <BroadcastTableView />
        </>
      </LayoutDefault>
      <SnackbarCollection />
    </>
  );
}
