import React from "react";
import { ReactComponent as Banner } from "../../assets/img/register/register-banner.svg";
import SnackbarCollection from "../../components/hub/SnackbarCollection";
import BannerCarousel from "../Register/BannerCarousel";

const OnboardingLayout = ({ children }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="register-container row">
      <div className="col-lg-6 col-sm-9 d-flex flex-column justify-content-around align-items-center w-100 left-column">
        {children}
        <p className="copyright">PastorsLine © {currentYear}</p>
      </div>
      <div className="col-lg-6 right-column">
        <Banner className="banner" />
        <BannerCarousel />
      </div>
      <SnackbarCollection />
    </div>
  );
};

export default OnboardingLayout;
