/* SETTINGS TAB ROUTES START */
//SUBMENU USER-SETTINGS ROUTES
export const USER_SETTINGS_ROUTE = "user-settings";
export const USER_PROFILE_ROUTE = "user-profile";
export const USER_NUMBER_ROUTE = "user-number";
export const USER_SIGNATURES_ROUTE = "user-signatures";
export const USER_GREETINGS_ROUTE = "user-greetings";
export const USER_VOICEMAIL_ROUTE = "user-voicemail";
export const USER_NOTIFICATIONS_ROUTE = "user-notifications";
// export const USER_REWARDS_CENTER_ROUTE = "user-rewards-center";

// user-rewards-center routes
// export const USER_REWARDS_CENTER_REFERRAL_ROUTE = "referral";
// export const USER_REWARDS_CENTER_GIFTS_ROUTE = "gifts";
// export const USER_REWARDS_CENTER_INVITED_USERS_ROUTE = "invited-users";
// export const USER_REWARDS_CENTER_AFFILIATE_HISTORY_ROUTE = "affiliate-history";
// export const USER_REWARDS_CENTER_TEMPLATE_CENTER_ROUTE = "template-center";

export const USER_NOTIFICATIONS_ALL_ROUTE = "all";
export const USER_NOTIFICATIONS_CAMPAIGNS_ROUTE = "campaign";
export const USER_AFFILIATE_ROUTE = "user-affilate";
export const USER_AFFILIATE_DASHBOARD_ROUTE = "dashboard-stats";
export const USER_AFFILIATE_COMMISION_ROUTE = "commision-stats";
export const USER_AFFILIATE_LINK_ROUTE = "links";
export const USER_AFFILIATE_PAYOUTS_ROUTE = "payouts";
export const USER_AFFILIATE_REFERRALS_ROUTE = "referrals";
export const USER_ORGANIZATIONS_ROUTE = "user-organizations";
export const USER_AFFILIATE_DETAILS_ROUTE = "user-affiliate-details";

//SUBMENU ADMIN-SETTINGS ROUTE
export const ADMIN_SETTINGS_ROUTE = "admin-settings";
export const ADMIN_ORGANIZATION_PROFILE_ROUTE = "admin-organization-profile";

export const ADMIN_USERS_ROUTE = "admin-users";
// admin-users header routes
export const ADMIN_USERS_ACTIVE_USERS_ROUTE = "active-users";
export const ADMIN_USERS_INVITE_PENDING_ROUTE = "invite-pending";

export const ADMIN_SIGNATURES_ROUTE = "admin-signatures";

export const ADMIN_BILLING_ROUTE = "admin-billing";
// admin-users header routes
export const ADMIN_BILLING_CURRENT_PLAN_ROUTE = "billing";
export const ADMIN_BILLING_ADDONS_ROUTE = "addons";
export const ADMIN_BILLING_SUBSCRIPTION_CHOICES_ROUTE = "subscription-choices";
export const ADMIN_BILLING_TRANSACTION_HISTORY_ROUTE = "transaction-history";
export const ADMIN_BILLING_PAY_BILLING_ROUTE = "pay-billing";

export const ADMIN_NUMBERS_ROUTE = "admin-numbers";
export const ADMIN_NUMBERS_VOICE_BROADCAST = "admin-numbers-voice-broadcast"; // embedded
export const ADMIN_NUMBERS_NUMBER_LANDLINE = "admin-numbers-number-landline"; // embedded
export const ADMIN_NUMBERS_CHECK_STATUS = "admin-numbers-check-status"; // embedded
export const ADMIN_NUMBERS_HOSTED_BRIDGE = "admin-numbers-hosted-bridge"; // embedded
export const ADMIN_NUMBERS_TEXT_SMS = "text-sms"; // embedded
export const ADMIN_ENABLE_SHORTCODE_AND_TOLLFREE =
  "admin-enable-shortcode-toll-free-number"; // embedded

export const ADMIN_INTEGRATIONS_ROUTE = "admin-integrations";
// admin-users header routes
export const ADMIN_INTEGRATIONS_ACTIVE_APPS_ROUTE = "active-apps";
export const ADMIN_INTEGRATIONS_APP_CENTER_ROUTE = "app-center";
export const ADMIN_INTEGRATIONS_FREE_APPS_ROUTE = "free-apps";

export const ADMIN_SECURITY_ROUTE = "admin-security";

//SUBMENU APP SETTINGS
export const APP_SETTINGS_ROUTE = "app-settings";
export const APP_SETTINGS_MESSAGES_ROUTE = "messages";
export const APP_SETTINGS_VOICE_ROUTE = "voice";
export const APP_SETTINGS_PEOPLE_ROUTE = "people";
export const APP_SETTINGS_GROUPS_ROUTE = "groups";
export const APP_SETTINGS_CAMPAINGS_ROUTE = "campaigns";
export const APP_SETTINGS_ANALYTICS_ROUTE = "analytics";
export const APP_SETTINGS_DISPLAY_ROUTE = "display";
/* SETTINGS TAB ROUTES END*/

/* TEMPLATES TAB ROUTES START */
export const TEMPLATES_ROUTE = "templates";
export const MESSAGE_TEMPLATES_ROUTE = "message-templates";
export const VIDEO_LIBRARY_ROUTE = "video-library";
export const MESSAGE_TEMPLATES_SMS_ROUTE = "sms-templates";
export const MESSAGE_TEMPLATES_VOICE_ROUTE = "voice-templates";
export const MESSAGE_TEMPLATES_EMAIL_ROUTE = "email-templates";

export const CAMPAIGN_TEMPLATES_ROUTE = "campaign-templates";
export const CAMPAIGN_TEMPLATES_CAMPAIGN_ROUTE = "campaign";
export const CAMPAIGN_TEMPLATES_CUSTOM_FIELDS_ROUTE = "campaign-custom-fields";
export const CAMPAIGN_TEMPLATES_MODULES_ROUTE = "campaign-modules";

export const SHORTENED_LINKS_ROUTE = "shortened-links";

export const CUSTOM_FIELDS_ROUTE = "custom-fields";
/* TEMPLATES TAB ROUTES END */

/* KEYWORDS TAB ROUTES START */
export const KEYWORDS_ALL_KEYWORDS_ROUTE = "all-keywords";

export const KEYWORDS_FAVORITES_ROUTE = "favorites";

export const KEYWORDS_CAMPAIGN_ALL_ROUTE = "campaign-all";
export const KEYWORDS_CAMPAIGN_LOCAL_NUMBERS_ROUTE = "campaign-local-numbers";
export const KEYWORDS_CAMPAIGN_SHORT_CODE_ROUTE = "campaign-short-code";

export const KEYWORDS_INTEGRATIONS_NONE_ROUTE = "integrations-no";
export const KEYWORDS_INTEGRATIONS_BREEZE_ROUTE = "integrations-breeze";
export const KEYWORDS_INTEGRATIONS_CCB_ROUTE = "integrations-ccb";
export const KEYWORDS_INTEGRATIONS_ELVANTO_ROUTE = "integrations-elvanto";
export const KEYWORDS_INTEGRATIONS_MAILCHIMP_ROUTE = "integrations-mailchimp";
export const KEYWORDS_INTEGRATIONS_PCO_ROUTE = "integrations-pco";

export const KEYWORDS_LOCAL_NUMBERS_ROUTE = "local-numbers";

export const KEYWORDS_SHORT_CODE_MY_KEYWORDS_ROUTE = "short-code-my-keywords";
export const KEYWORDS_SHORT_CODE_WATCH_LIST_ROUTE = "short-code-watch-list";
export const KEYWORDS_SHORT_CODE_STORE_ROUTE = "short-code-store";

export const KEYWORDS_RELEASED_ALL_ROUTE = "released-all";
export const KEYWORDS_RELEASED_LOCAL_NUMBERS_ROUTE = "released-local-numbers";
export const KEYWORDS_RELEASED_SHORT_CODE_ROUTE = "released-short-code";
/* KEYWORDS TAB ROUTES END */

/* ANALYTICS TAB ROUTES START */
export const ANALYTICS_ROUTE = "analytics";
export const ANALYTICS_LOGS_ROUTE = "logs";
export const ANALYTICS_LOGS_NEW_ROUTE = "new-logs";
export const ANALYTICS_LOGS_LEGACY_ROUTE = "legacy-logs";
export const ANALYTICS_REPORTS_ROUTE = "reports";
export const ANALYTICS_MESSAGES_CALLS_ROUTE = "messages-and-calls-summary";
/* ANALYTICS TAB ROUTES END */

/* OTHER TABS ROUTES START */
export const SHORTCODE_KEYWORDS_ROUTE = "shortcode-keywords";
export const CAMPAIGNS_ROUTE = "campaigns";
export const WEB_WIDGETS_ROUTE = "web-widgets";
export const QR_CODE_ROUTE = "QR-code";
/* OTHER TABS ROUTES END */

export const TAGS_ROUTE = "tags";

export const SETTINGS_ROUTE = "settings";
