import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";

function ThreadItemClickableWrapper(props) {
  const {
    url,
    itemRef,
    className,
    style,
    content,
    replace,
    hideHoverEffect,
    toggleCheckbox,
    isActive,
    onClick,
    purpose,
    handleNewContact,
    group,
    isGroup,
    isNewGroup,
    groupName,
    contacts,
    newContact,
    setShowNewContactActionTooltip,
    onClickNew,
    setIsHideCheckbox,
    filter,
    withoutOnTouchStart,
  } = props;
  const breakpoint = useBreakpoint();
  let clickHandler = null;
  const firstContact = contacts[0];
  const threadStyle = { ...style };

  if (onClick) {
    if (isGroup) {
      clickHandler = (event) =>
        onClick(isGroup ? group : firstContact, null, event);
    } else if (contacts.length === 1) {
      clickHandler = (event) => onClick(firstContact, null, event);
    } else if (contacts.length > 1) {
      clickHandler = (event) => onClick(firstContact, contacts, event);
    }
    threadStyle["cursor"] = "pointer";
  } else if (onClickNew && !isMdOrBelowBreakpoint(breakpoint)) {
    clickHandler = (event) => onClickNew(event);
  } else if (purpose === "call-modal" && !isGroup && handleNewContact) {
    clickHandler = (e) => handleNewContact(e, newContact, "call");
    threadStyle["cursor"] = "pointer";
  } else if (handleNewContact && isNewGroup) {
    clickHandler = (e) => handleNewContact(e, groupName, "create-new-group");
    threadStyle["cursor"] = "pointer";
  } else if (handleNewContact && purpose) {
    clickHandler = (e) =>
      handleNewContact(e, newContact, "group-members-modal");
    threadStyle["cursor"] = "pointer";
  } else if (handleNewContact) {
    clickHandler = (e) => handleNewContact(e, newContact, "message");
    threadStyle["cursor"] = "pointer";
  }

  if (purpose === "contact-list-nothing-found") {
    clickHandler = () => {
      setShowNewContactActionTooltip(true);
    };
  }

  useEffect(() => {
    if ((!onClickNew && clickHandler) || hideHoverEffect) {
      setIsHideCheckbox(true);
    }
  }, [clickHandler, setIsHideCheckbox, onClickNew, hideHoverEffect]);

  return (
    <div>
      {clickHandler && (
        <div
          ref={itemRef}
          className={className}
          style={threadStyle}
          onClick={
            isMdOrBelowBreakpoint(breakpoint) && !withoutOnTouchStart
              ? null
              : clickHandler
          }
          onTouchStart={withoutOnTouchStart ? null : clickHandler}
          onMouseEnter={
            !isMdOrBelowBreakpoint(breakpoint) && !hideHoverEffect && onClickNew
              ? toggleCheckbox(true)
              : undefined
          }
          onMouseLeave={
            !isMdOrBelowBreakpoint(breakpoint) && onClickNew
              ? toggleCheckbox(false)
              : undefined
          }
        >
          {content}
        </div>
      )}

      {!clickHandler && !filter?.includes("never-synced") && (
        <>
          {url.substr(0, 4) === "http" && (
            <a
              ref={itemRef}
              className={className}
              style={threadStyle}
              href={url}
            >
              {content}
            </a>
          )}
          {url.substr(0, 4) !== "http" && (
            <Link
              innerRef={itemRef}
              className={className}
              style={threadStyle}
              to={url}
              aria-selected={isActive}
              replace={replace}
              onMouseEnter={
                !isMdOrBelowBreakpoint(breakpoint) &&
                !hideHoverEffect &&
                toggleCheckbox(true)
              }
              onMouseLeave={
                !isMdOrBelowBreakpoint(breakpoint) && toggleCheckbox(false)
              }
            >
              {content}
            </Link>
          )}
        </>
      )}

      {!clickHandler && filter && filter?.includes("never-synced") && (
        <div className={className} style={threadStyle}>
          {content}
        </div>
      )}
    </div>
  );
}

ThreadItemClickableWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  href: PropTypes.string,
  itemRef: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  content: PropTypes.element.isRequired,
  replace: PropTypes.bool,
  hideHoverEffect: PropTypes.bool,
  toggleCheckbox: PropTypes.func,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  purpose: PropTypes.string,
  handleNewContact: PropTypes.func,
  group: PropTypes.object,
  isGroup: PropTypes.bool,
  isNewGroup: PropTypes.bool,
  groupName: PropTypes.string,
  contacts: PropTypes.array,
  newContact: PropTypes.bool,
  withoutOnTouchStart: PropTypes.bool,
};

ThreadItemClickableWrapper.defaultProps = {
  className: "",
  style: "",
  replace: false,
  withoutOnTouchStart: false,
};

export default withRouter(ThreadItemClickableWrapper);
