import React from "react";
import Lightbox from "react-images";

class ImagesViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.closeTimer = null;
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      open: true,
      currentImage: this.props.currentImage ? this.props.currentImage : 0,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimer);
  }

  prev() {
    if (this.state.currentImage > 0) {
      this.setState(
        { currentImage: this.state.currentImage - 1 },
        this.forceUpdate
      );
    }
  }

  next() {
    if (this.state.currentImage + 1 < this.props.images.length) {
      this.setState(
        { currentImage: this.state.currentImage + 1 },
        this.forceUpdate
      );
    }
  }

  close() {
    this.setState({ open: false }, () => {
      this.forceUpdate();
      this.closeTimer = setTimeout(this.props.close, 300);
    });
  }

  render() {
    return (
      <Lightbox
        images={this.props.images}
        currentImage={this.state.currentImage}
        theme={{ container: { zIndex: 100000 } }}
        backdropClosesModal={true}
        isOpen={this.state.open}
        onClickPrev={this.prev}
        onClickNext={this.next}
        onClose={this.close}
      />
    );
  }
}

export default ImagesViewer;
