import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import LegacySpinner from "../Menus/shared/LegacySpinner";
import { useWithEmbeddedLoading } from "../hub/Embedded/useWithEmbeddedLoading";
import IframeLegacySessionExpiredModal from "./IframeLegacySessionExpiredModal";
import {
  MESSAGE,
  PAYMENT_SUCCESSFUL,
  USER_LOGOUT,
} from "../../utils/constants";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import IframeLauncher from "./IframeLauncher";
import { useComponentWillUnmount } from "../../utils/hooks/useComponentWillUnmount";
import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";

const Iframe = (props) => {
  const {
    // Component props
    src,
    title,
    className,
    keyFromParent,
    showLauncher,
  } = props;
  // Redux props
  const { main, togggleShowEmbededScreemOnFullMode } = useMainStore();
  const { fetchCompanies } = useCompaniesStore();
  const { iFrameKey, showEmbededScreenOnFullMode } = main;

  const breakpoint = useBreakpoint();
  const { isLoading, setIsLoading } = useWithEmbeddedLoading();
  const [showLegacySessionExpiredModal, setShowLegacySessionExpiredModal] =
    useState(false);

  const processIframeMessages = useCallback(
    ({ data }) => {
      if (data) {
        if (data?.event === USER_LOGOUT) {
          setShowLegacySessionExpiredModal(!showLegacySessionExpiredModal);
          setIsLoading(false);
        } else if (data?.event === PAYMENT_SUCCESSFUL) {
          fetchCompanies(true);
        }
      }
    },
    [fetchCompanies, setIsLoading, showLegacySessionExpiredModal]
  );

  useComponentWillUnmount(() => {
    togggleShowEmbededScreemOnFullMode(true);
  }, []);

  useEffect(() => {
    window.addEventListener(MESSAGE, processIframeMessages);

    return () => {
      window.removeEventListener(MESSAGE, processIframeMessages);
    };
  }, [processIframeMessages]);

  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const onLaunchIframe = useCallback(() => {
    setIsLoading(true);
    setShowLegacySessionExpiredModal(false);
  }, [setIsLoading]);

  if (
    !isMdOrBelowBreakpoint(breakpoint) &&
    showLauncher &&
    !showEmbededScreenOnFullMode
  )
    return <IframeLauncher title={title} onLaunchIframe={onLaunchIframe} />;

  if (showLegacySessionExpiredModal) return <IframeLegacySessionExpiredModal />;

  return (
    <>
      {isLoading && <LegacySpinner />}
      <iframe
        key={keyFromParent || iFrameKey}
        src={src}
        title={title}
        width={isLoading ? "0" : "100%"}
        height={isLoading ? "0" : "100%"}
        onLoad={onLoad}
        className={className}
      ></iframe>
    </>
  );
};

Iframe.propTypes = {
  onLoad: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showLauncher: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  keyFromParent: PropTypes.string,
};

Iframe.defaultProps = {
  isLoading: false,
  showLauncher: false,
  src: "",
  title: "",
  className: "",
  keyFromParent: "",
};

export default Iframe;
