import { fetchCounts, fetchIncomingThread } from "../../actions/threads";

export const onNewIncomingMessage = () => {
  const callback = (config, dispatch, data) => {
    dispatch(
      fetchIncomingThread(config.companyId, config.numbersFilter, data.id)
    );
    dispatch(fetchCounts(config.companyId, config.numbersFilter));
  };

  return {
    event: "new-message",
    callback,
    timeout: 5000,
  };
};

export const onNewOutgoingMessage = () => {
  const callback = (config, dispatch, data) => {
    const ignoreIndex = config.ignoreOutgoingLogs.indexOf(data.id);
    if (ignoreIndex !== -1) {
      config.ignoreOutgoingLogs.splice(ignoreIndex, 1);
    } else {
      dispatch(
        fetchIncomingThread(config.companyId, config.numbersFilter, data.id)
      );
      dispatch(fetchCounts(config.companyId, config.numbersFilter));
    }
  };

  return {
    event: "new-outgoing-message",
    callback,
    timeout: 500,
  };
};
