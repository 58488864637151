export const BillingIssueEnums = {
  // For this flag, is_suspended value is 0 by default
  NoFailedPaymentAttempt: 0,
  // For this flag, client has X days to fix the card and is_suspended value is still 0
  FirstFailedPaymentAttempt: 1,
  // For this flag, client X days to fix the card, account will be suspended and is_suspended is set to 1
  SecondFailedPaymentAttempt: 2,
  // For this flag, account will be closed after X days and is_suspended set to 2
  ThirdFailedPaymentAttempt: 3,
  // For this flag, account will be closed / released numbers
  ForthFailedPaymentAttempt: 4,
};

export const SuspendedAccountEnums = {
  // This is the default value for an account with no payment issues or a first failed payment attempt
  AccountActive: 0,
  // This is the value set when there is a second failed payment attempt (billing issue 2/BillingIssueEnums.SecondFailedPaymentAttempt)
  AccountSuspended: 1,
  // This is the value set when there is a third failed payment attempt (billing issue 3/BillingIssueEnums.ThirdFailedPaymentAttempt)
  AccountSuspendedTwice: 2,
};

export const IssueKeyEnums = {
  Soft: "soft",
  Medium: "medium",
  Hard: "hard",
  Inactive: "inactive",
  NoCredits: "no-credits",
  InvalidEmail: "invalid-email",
};
