export const testimonials = [
  {
    imageUrl:
      "https://pastorsline.com/wp-content/uploads/2017/10/63-300x300.webp",
    name: "Tommy Carreras",
    title: "Group Leader",
    text: (
      <>
        "PastorsLine has totally changed not only my workflow, but it's taken
        our 'personal' factor up 10x! Just with our welcome process, we have
        gone from a 40% READ rate to a 40% CONVERSATION rate. That's just crazy!
        <br /> So thanks :)",
      </>
    ),
  },
  {
    imageUrl:
      "https://pastorsline.com/wp-content/uploads/2018/10/13450961_10156993056215371_195020106933212431_n-1-.webp",
    name: "Paolo Esposito",
    title: "Pastors, RGF Church",
    text: "PastorsLine has been a fantastic tool which has enhanced communication with out church family. As with any tool, the secret is in learning how to use it effectively. I have been most excited when I see my church members telling their very own unchurched friends to text “PRAY” to our pastorsline number and have them join our prayer sessions.",
  },
  {
    imageUrl:
      "https://pastorsline.com/wp-content/uploads/2018/10/KristenSquare_400x400.webp",
    name: "Pastor John",
    title: "Pastor, Grace Church",
    text: "...the guest connection card...We had a great response -- people whose names and contact info we might not have captured otherwise. Our autoresponder directs them to our Welcome Center...where we have a gift...with their name on it.",
  },
];
