import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { postRequest } from "../utils/requests";

export function fetchShortLinks(companyId, query = "", page = 1, orderBy = "") {
  return function (dispatch) {
    dispatch({
      type: "SHORT_LINKS/FETCH",
      query,
      page,
    });

    const url = "short-links.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        query,
        page,
        orderBy,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "SHORT_LINKS/FETCH.SUCCESS",
          query,
          page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHORT_LINKS/FETCH.ERROR",
          query,
          page,
          payload: error,
        });
      });
  };
}

export function clearShortLinks() {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/FETCH.CLEAR" });
  };
}

export function fetchShortLink(companyId, shortLinkId) {
  return function (dispatch) {
    dispatch({
      type: "SHORT_LINKS/FETCH_SINGLE",
      shortLinkId,
    });

    const url = `short-links/${shortLinkId}.json?companyId=${companyId}`;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHORT_LINKS/FETCH_SINGLE.SUCCESS",
          shortLinkId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHORT_LINKS/FETCH_SINGLE.ERROR",
          shortLinkId,
          payload: error,
        });
      });
  };
}

export function refreshShortLink(data) {
  return function (dispatch) {
    const url = `short-links/refresh.json`;
    dispatch({
      type: "SHORT_LINKS/FETCH_SINGLE",
      shortLinkId: data.short_link_id,
    });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "SHORT_LINKS/FETCH_SINGLE.SUCCESS",
          shortLinkId: data.short_link_id,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHORT_LINKS/FETCH_SINGLE.ERROR",
          shortLinkId: data.short_link_id,
          payload: error,
        });
      });
  };
}

export function addShortLink(companyId, formData) {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/ADD" });

    const data = Object.assign({}, formData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "short-links.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "SHORT_LINKS/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHORT_LINKS/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddShortLink() {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/ADD.CLEAR" });
  };
}

export function addAttachmentsShortLink(companyId, formData) {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/ADD_ATTACHMENTS" });

    const data = Object.assign({}, formData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "short-links/bulk-add.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "SHORT_LINKS/ADD_ATTACHMENTS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHORT_LINKS/ADD_ATTACHMENTS.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddAttachmentsShortLink() {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/ADD_ATTACHMENTS.CLEAR" });
  };
}

export function addVideoShortLink(companyId, formData) {
  const data = Object.assign({}, formData, { company_id: companyId });
  return (dispatch) =>
    postRequest(
      dispatch,
      "SHORT_LINKS/ADD_VIDEO",
      "short-links/bulk-add.json",
      data,
      {
        requestDispatchData: { companyId, formData },
        successDispatchData: { companyId, formData },
        errorDispatchData: { companyId, formData },
      }
    );
}

export function clearAddVideoShortLink() {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/ADD_VIDEO.CLEAR" });
  };
}

export function deleteShortLink(companyId, shortLinkId) {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/DELETE", shortLinkId });

    const url = `short-links/${shortLinkId}.json?companyId=${companyId}`;
    return axios
      .delete(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "SHORT_LINKS/DELETE.SUCCESS",
          shortLinkId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHORT_LINKS/DELETE.ERROR",
          shortLinkId,
          error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearDeleteShortLink() {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/DELETE.CLEAR" });
  };
}

export function insertShortLink(insertTo, shortLink) {
  return function (dispatch) {
    dispatch({
      type: "SHORT_LINKS/INSERT",
      insertTo,
      shortLink,
    });
  };
}

export function clearInsertShortLink() {
  return function (dispatch) {
    dispatch({ type: "SHORT_LINKS/INSERT.CLEAR" });
  };
}

export function editShortLink(companyId, shortLinkId, body) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "SHORT_LINKS/EDIT_SHORT_LINKS",
      `short-links/${shortLinkId}/.json`,
      { ...body, company_id: companyId }
    );
  };
}

export function clearEditShortLink() {
  return (dispatch) =>
    dispatch({
      type: "SHORT_LINKS/EDIT_SHORT_LINKS.CLEAR",
    });
}
