import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReactComponent as CreditCardIcon } from "../../../assets/img/icons-new/billing-card/billing-card-gray.svg";
import { getFormattedAccountCredits, isReactNativeApp } from "../../../helpers";
import { useWithHover } from "../../../utils/hooks/useWithHover";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import AnalyticsAndBillingLinks from "../../hub/HelperComponents/AnalyticsAndBillingLinks";

function mapStateToProps(store) {
  return {
    userPermissions: store.users.permissions,
  };
}

const UserCreditBalance = (props) => {
  const {
    userCredits,
    closePopup,
    className,

    // Redux props
    userPermissions,
  } = props;
  const breakpoint = useBreakpoint();

  const { hoverActive, onMouseEnter, onMouseLeave } = useWithHover();

  let availableCredits = {};
  if (userCredits !== null)
    availableCredits = getFormattedAccountCredits(userCredits);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`user-credits ${className}`}
      onClick={closePopup}
    >
      <div className="d-flex align-items-center">
        <CreditCardIcon className="mr-2" />
        {hoverActive || isMdOrBelowBreakpoint(breakpoint)
          ? availableCredits?.creditsFull
          : availableCredits?.creditsSimple}
      </div>
      {!isReactNativeApp() && (
        <AnalyticsAndBillingLinks userPermissions={userPermissions} />
      )}
    </div>
  );
};

UserCreditBalance.defaultProps = {
  className: "",
  closePopup: () => {},
};

UserCreditBalance.propTypes = {
  userCredits: PropTypes.number.isRequired,
  closePopup: PropTypes.func,
  className: PropTypes.string,
};

export default connect(mapStateToProps)(UserCreditBalance);
