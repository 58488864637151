import React from "react";

const RenderRecipientSidebarDesc = (props) => {
  const { currentFilter } = props;

  if (currentFilter === "all-excluded") {
    return (
      <p className="messages-card__label m-0 mt-3 d-none d-sm-block">
        These are all the numbers that won’t get this message
      </p>
    );
  }

  if (currentFilter === "added-groups") {
    return (
      <p className="messages-card__label m-0 mt-3 d-none d-sm-block">
        These are all the groups that you have added
      </p>
    );
  }

  if (currentFilter === "added-tags") {
    return (
      <p className="messages-card__label m-0 mt-3 d-none d-sm-block">
        These are all the tags that you have added
      </p>
    );
  }

  return (
    <p className="messages-card__label m-0 mt-3 d-none d-sm-block">
      These are all the numbers that we will send this message to
    </p>
  );
};

export default RenderRecipientSidebarDesc;
