/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2018.
 */
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";

import Cookies from "universal-cookie";

const persistConfig = {
  key: "root",
  storage: storage,
  // Users temporary disabled because of timezone change issue
  // https://trello.com/c/yN1nzTpn/4152-changing-timezone-bug
  whitelist: [
    "users",
    "companies",
    "countries",
    "campaigns",
    "settings",
    "version",
    "threads",
    "contacts",
    "groups",
    "numbers",
    "adminSettings",
    "media",
    "affiliate",
  ],
};

export const history = createBrowserHistory();

/*
 * Ensure #modal is not first in a history to may close modal via history.back()
 * Cannot determine this is a first entry in v2 history so need to use #redirect=#modal-x
 * Session may be started, then user may back to previous page (legacy for example) and then click
 * link to v2 again. Session is started in this case but v2 has the first entry in own history
 */
const sessionStarted = sessionStorage.getItem("sessionStarted");
if (history.location.hash.indexOf("#redirect=") !== -1) {
  const redirect = history.location.hash.substr("#redirect=".length);
  const newURL = `${history.location.pathname}${history.location.search}${redirect}`;
  history.replace("/");
  history.push(newURL);
} else if (!sessionStarted && history.location.hash.indexOf("#modal") !== -1) {
  const origUrl = `${history.location.pathname}${history.location.search}${history.location.hash}`;
  history.replace("/");
  history.push(origUrl);
}

if (!sessionStarted) {
  sessionStorage.setItem("sessionStarted", "1");
}

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const preventRehydrateMiddleWare = (storeAPI) => (next) => (action) => {
  if (
    action.type === "persist/REHYDRATE" &&
    process.env.NODE_ENV === "production"
  ) {
    const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
    const version = persistRoot?.version
      ? JSON.parse(persistRoot.version)
      : null;

    if (version?.buildNumber !== process.env.REACT_APP_BUILD_NUMBER) {
      persistor.purge();
    }
  }
  return next(action);
};

export function configureStore(preloadedState) {
  const debug = process.env.NODE_ENV === `development`;
  const composeEnhancers =
    debug && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== "undefined"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  const middlewares = [
    routerMiddleware(history), // for dispatching history actions
    promise,
    thunk,
    preventRehydrateMiddleWare,
  ];
  if (debug) {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
  }
  return createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

export const store = configureStore();

export const persistor = persistStore(store);

export const cookies = new Cookies();
