/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import Cookies from "universal-cookie";
import { cookies } from "../store";
import { getRequest, postRequest, deleteRequest } from "../utils/requests";

export function fetchCompanies(isResetCurrentCompany = false) {
  return function (dispatch) {
    dispatch({ type: "FETCH_COMPANIES" });

    const url = "companies.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_COMPANIES_SUCCESS",
          payload: response.data,
          isResetCurrentCompany,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_COMPANIES_ERROR",
          payload: error,
        });
      });
  };
}

export function setCurrentCompany(id, isAutoSwitched = false) {
  return function (dispatch) {
    dispatch({
      type: "SET_CURRENT_COMPANY",
      payload: {
        companyId: id,
        isAutoSwitched,
      },
    });
    const cookies = new Cookies();
    let expires = new Date();
    expires.setDate(expires.getDate() + 365);
    const companyCookieName = "company_id_" + AxiosConfig.getCookieNameDomain();
    cookies.set(companyCookieName, id, {
      path: "/",
      expires: expires,
      domain: AxiosConfig.getSharedCookieDomain(),
    });

    // save new company on old app session
    //axios.get(AxiosConfig.getPrimaryAppAddress() + 'users/select_company/' + id, {withCredentials: true});
  };
}

export function updateCurrentCompanyAfterFetch(currentCompanyId) {
  return function (dispatch) {
    dispatch({
      type: "UPDATE_CURRENT_COMPANY_AFTER_FETCH",
      currentCompanyId,
    });
  };
}

export function closeAccountIssueBar(companyId, issueKey) {
  return function (dispatch) {
    if (issueKey === null) {
      cookies.remove("account_issue_closed__" + companyId, { path: "/" });
    } else {
      cookies.set("account_issue_closed__" + companyId, issueKey, {
        path: "/",
      });
    }
    dispatch({ type: "COMPANIES/CLOSE_ACCOUNT_ISSUE_BAR" });
  };
}

export function fetchUsers(params) {
  return (dispatch) => {
    getRequest(dispatch, "COMPANIES/FETCH_USERS", "companies/users.json", {
      axiosConfig: { params: { ...params } },
    });
  };
}

export function setTypeOfFetchedUsers(params) {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/SET_TYPE_OF_FETCHED_USERS", payload: params });
  };
}

export function fetchUserInfo(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "COMPANIES/FETCH_USER_INFO",
      `companies/users/${params.userId}/.json`,
      {
        axiosConfig: { params: { companyId: params.companyId } },
      }
    );
  };
}

export function clearUserInfo() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/USER_INFO.CLEAR" });
  };
}

export function updateUserInfo(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/UPDATE_USER_INFO",
      "companies/users.json",
      {
        ...params,
      }
    );
  };
}

export function clearUpdateUserInfoStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CLEAR_UPDATE_USER_INFO_STATUS" });
  };
}

export function inviteNewUser(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/INVITE_NEW_USER",
      "companies/users/invite.json",
      {
        ...params,
      }
    );
  };
}

export function clearInviteNewUserStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CLEAR_INVITE_NEW_USER_STATUS" });
  };
}

export function deleteUser(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/DELETE_USER",
      "companies/users/delete.json",
      {
        ...params,
      }
    );
  };
}

export function clearDeleteUserStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CLEAR_DELETE_USER_STATUS" });
  };
}

export function fetchDeletedUsers(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/FETCH_DELETED_USERS",
      "companies/delete_history.json",
      {
        ...params,
      }
    );
  };
}

export function resendInvitation(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/RESEND_INVITATION",
      "companies/users/resend.json",
      {
        ...params,
      }
    );
  };
}

export function clearResendInvitationStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CLEAR_RESEND_INVITATION_STATUS" });
  };
}

export function removeDeletedUser(data) {
  return function (dispatch) {
    dispatch({
      type: "COMPANIES/REMOVE_DELETED_USER",
      payload: { data },
    });
  };
}

export function getCompanyPackage(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "COMPANIES/GET_COMPANY_PACKAGE",
      `companies/package/${params.companyId}/.json`
    );
  };
}

export function clearIsAutoSwitched() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CLEAR_AUTO_SWITCHED_FLAG" });
  };
}

export function transferCompanyOwnership(companyId, data) {
  const { transfer_email, ...rest } = data;
  return (dispatch) => {
    postRequest(
      dispatch,
      "COMPANIES/TRANSFER_OWNERSHIP",
      `companies/transfer_ownership/${companyId}/.json`,
      rest,
      {
        successDispatchData: { companyId, transfer_email },
      }
    );
  };
}
export function clearTransferCompanyOwnership() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/TRANSFER_OWNERSHIP.CLEAR" });
  };
}

export function cancelCompanyTransferOwnership(companyId) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "COMPANIES/CANCEL_TRANSFER_OWNERSHIP",
      `companies/transfer_ownership/cancel/${companyId}/.json`,
      null,
      {
        successDispatchData: { companyId },
      }
    );
  };
}

export function clearCancelCompanyTransferOwnership() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CANCEL_TRANSFER_OWNERSHIP.CLEAR" });
  };
}

export function resendCompanyTransferOwnership(companyId) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "COMPANIES/RESEND_TRANSFER_OWNERSHIP",
      `companies/transfer_ownership/resend/${companyId}/.json`
    );
  };
}

export function clearResendCompanyTransferOwnership() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/RESEND_TRANSFER_OWNERSHIP.CLEAR" });
  };
}

export function setSelectedCompany(company) {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/SET_SELECTED_COMPANY", payload: company });
  };
}
export function clearSelectedCompany(company) {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/SET_SELECTED_COMPANY.CLEAR" });
  };
}

export function addCompany(data) {
  return (dispatch) => {
    postRequest(dispatch, "COMPANIES/ADD", `companies/add.json`, data);
  };
}
export function clearAddCompany() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/ADD.CLEAR" });
  };
}

export function checkEmail(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/CHECK_EMAIL",
      "companies/check-email.json",
      {
        ...params,
      }
    );
  };
}

export function clearCheckEmail() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/CHECK_EMAIL.CLEAR" });
  };
}

export function leaveCompany(companyId) {
  return function (dispatch) {
    postRequest(dispatch, "COMPANIES/LEAVE_COMPANY", `companies/leave.json`, {
      company_id: companyId,
    });
  };
}

export function clearLeaveCompany() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/LEAVE_COMPANY.CLEAR" });
  };
}

export function fetchExitedCompanies() {
  return function (dispatch) {
    getRequest(
      dispatch,
      "COMPANIES/FETCH_EXITED_COMPANIES",
      `companies/leave_history.json`
    );
  };
}

export function deleteCompany(companyId) {
  return function (dispatch) {
    deleteRequest(
      dispatch,
      "COMPANIES/DELETE_COMPANY",
      `companies/delete/${companyId}/.json`
    );
  };
}
export function clearDeleteCompanyStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/DELETE_COMPANY.CLEAR" });
  };
}

export function updateCompany(company_id, has_sent_test_sms) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/UPDATE_COMPANY",
      "companies/dev-update-company.json",
      { company_id, has_sent_test_sms },
      {
        successDispatchData: { has_sent_test_sms },
      }
    );
  };
}

export function clearUpdateCompanyStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/UPDATE_COMPANY.CLEAR" });
  };
}

export function skipSetupProfileProd(company_id) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "COMPANIES/SKIP_SETUP_PROFILE",
      "companies/skip-setup-profile.json",
      { company_id }
    );
  };
}

export function clearSkipSendTestStatus() {
  return function (dispatch) {
    dispatch({ type: "COMPANIES/SKIP_SETUP_PROFILE.CLEAR" });
  };
}

export function fetchCompanyUsers(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "COMPANIES/FETCH_COMPANY_USERS",
      `companies/users.json`,
      {
        axiosConfig: { params: { ...params } },
      }
    );
  };
}
