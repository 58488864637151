import { englishOrdinalSuffix } from "../helpers";
import { RRule, rrulestr } from "rrule";
import { store } from "../store";

export const daysOfTheWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfTheYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const RepeatType = {
  DoesNotRepeat: "DoesNotRepeat",
  Daily: "Daily",
  Weekly: "Weekly",
  WeekDays: "WeekDays",
  BiWeekly: "BiWeekly",
  MonthlyOnFirstSelectedWeekDay: "MonthlyOnFirstSelectedWeekDay",
  Yearly: "Yearly",
  Custom: "Custom",
};

const RepeatingDaysType = {
  DaysOfTheWeek: "DaysOfTheWeek",
  DaysOfTheMonth: "DaysOfTheMonth",
};

const RepeatingMonthsType = {
  AllMonths: "AllMonths",
  SpecificMonths: "SpecificMonths",
};

export const periodOptions = [
  {
    name: "AM",
    value: "AM",
  },
  {
    name: "PM",
    value: "PM",
  },
];

const getDayValueMap = (reverse = false) => {
  let dayMap = Object.assign(
    {},
    new Array(7).fill(0).map((j, i) => (i + 1) % 7)
  );
  if (reverse) {
    dayMap = Object.fromEntries(
      Object.entries(dayMap).map(([key, value]) => [value, parseInt(key)])
    );
  }
  return dayMap;
};

const RRuleToLocalDateDayOfTheWeekMap = getDayValueMap(false);

const LocalDateToRRuleDayOfTheWeekMap = getDayValueMap(true);

/**
 *
 * @param {object} date - date object
 * @param {boolean} useUTC - boolean to use UTC or not
 * @returns {object} - object with month, calendarDay, dayOfTheWeek, year, hours, minutes
 */
export const getDateValues = (date, useUTC = false) => {
  const [month, calendarDay, dayOfTheWeek, year, hours, minutes] = [
    useUTC ? date.getUTCMonth() : date.getMonth(),
    useUTC ? date.getUTCDate() : date.getDate(),
    useUTC ? date.getUTCDay() : date.getDay(),
    useUTC ? date.getUTCFullYear() : date.getFullYear(),
    useUTC ? date.getUTCHours() : date.getHours(),
    useUTC ? date.getUTCMinutes() : date.getMinutes(),
  ];

  return { month, calendarDay, dayOfTheWeek, year, hours, minutes };
};

/**
 *
 * @param {object} date - date object
 * @returns {object} - object of date with time set to 00:00:00:00
 */
export const resetTimeOnDate = (date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

/**
 *
 * @param {object} date - date object
 * @param {boolean} useUTC - boolean to use UTC or not
 * @returns {string} - string of date in format i.e. "Jan 1st, 2023"
 */
export const getDateText = (date, useUTC) => {
  if (!date) return "";
  const { month, calendarDay, year } = getDateValues(date, useUTC);
  return `${monthsOfTheYear[month]} ${calendarDay}${englishOrdinalSuffix(
    calendarDay
  )}, ${year}`;
};

/**
 *
 * @param {object} date - date object
 * @param {boolean} useUTC - boolean to use UTC or not
 * @returns {string} - string of date in format i.e. "Monday 12-29-2024"
 */
export const getDateTextForAutoResponder = (date, useUTC) => {
  if (!date) return "";
  let dateUse = date;
  if (typeof date.getMonth !== "function") {
    dateUse = new Date(date);
  }
  const { month, calendarDay, year, dayOfTheWeek } = getDateValues(
    dateUse,
    useUTC
  );
  let formattedMonth = month + 1;
  let formattedDay = calendarDay;
  if (formattedMonth < 10) {
    formattedMonth = "0" + formattedMonth;
  }
  if (formattedDay < 10) {
    formattedDay = "0" + formattedDay;
  }
  return `${daysOfTheWeek[dayOfTheWeek]} ${formattedMonth}-${formattedDay}-${year}`;
};

/**
 *
 * @param {object} date - date object
 * @returns {string} - string of date in format i.e. "01/10/2023"
 */

const locale = window.navigator.language || "en-US";
const formatter = new Intl.DateTimeFormat(locale, { date: "short" });
export const getDateFormat = (date) => {
  if (!date) return "";
  return formatter.format(new Date(date));
};

/**
 *
 * @param {object} date - date object
 * @param {*} useUTC - boolean to use UTC or not
 * @returns {object} - object with 12 hour time and period
 */
export const getTimeAndPeriodIn12HrsFromDate = (date, useUTC) => {
  let { hours, minutes } = getDateValues(date, useUTC);
  const { twelveHour, twelveHourPeriod } = get12HourFrom24HourWithPeriod(hours);
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  const twelveHourTime = `${twelveHour}:${minutes}`;
  return { twelveHourTime, twelveHourPeriod };
};

const getHoursAndMinutesFrom12HourFormat = (time, period) => {
  const timeArray = time.split(":");
  const hours =
    parseInt(timeArray[0]) +
    parseInt(period === "PM" && parseInt(timeArray[0]) !== 12 ? 12 : 0);
  const minutes = parseInt(timeArray[1]);
  return { hours, minutes };
};

const getEndDateText = (date) => {
  const { month, calendarDay, year } = getDateValues(date);
  return `${calendarDay} ${monthsOfTheYear[month]} ${year}`;
};

const concatenateArrayToString = (array, isDate = false) => {
  let text = "";
  Array.isArray(array) &&
    array.forEach((value, index) => {
      if (index && index === array.length - 1) {
        text += " and";
      }
      text += ` ${value}${isDate ? englishOrdinalSuffix(value) : ""}`;
      if (index !== array.length - 1 && index !== array.length - 2) {
        text += ",";
      }
    });

  return text;
};

const getRepeatTypeText = (
  repeatType,
  date,
  isMessageSummaryDefault,
  isMessageSummaryExpanded
) => {
  let repeatTypeText = !isMessageSummaryDefault
    ? isMessageSummaryExpanded
      ? "Repeat "
      : " repeats it"
    : "";
  const { dayOfTheWeek } = getDateValues(date);
  if (repeatType === RepeatType.Daily) {
    repeatTypeText += isMessageSummaryDefault ? "Daily" : " daily";
  } else if (repeatType === RepeatType.Weekly) {
    repeatTypeText += isMessageSummaryDefault ? "" : " every week on ";
    repeatTypeText += daysOfTheWeek[dayOfTheWeek];
  } else if (repeatType === RepeatType.WeekDays) {
    repeatTypeText += isMessageSummaryDefault ? "" : " every";
    const weekDays = daysOfTheWeek.filter(
      (_, index) => ![0, 6].includes(index)
    );
    repeatTypeText += concatenateArrayToString(weekDays);
  } else if (repeatType === RepeatType.BiWeekly) {
    repeatTypeText += isMessageSummaryDefault ? "" : " ";
    repeatTypeText += `Bi-Weekly on ${daysOfTheWeek[dayOfTheWeek]}`;
  } else if (repeatType === RepeatType.MonthlyOnFirstSelectedWeekDay) {
    repeatTypeText += isMessageSummaryDefault ? "" : " on the first ";
    repeatTypeText += `${daysOfTheWeek[dayOfTheWeek]} of every month`;
  } else if (repeatType === RepeatType.Yearly) {
    repeatTypeText += isMessageSummaryDefault ? "Yearly" : " every year";
  }

  return repeatTypeText;
};

const getCustomRepeatTypeText = (customValues) => {
  if (!customValues) return "";
  let customText = " then repeat it every";
  const {
    repeatingDaysType,
    repeatingDaysTypeValues,
    repeatingMonthsType,
    repeatingMonthsTypeValues,
  } = customValues || {};
  if (repeatingDaysType === RepeatingDaysType.DaysOfTheWeek) {
    const daysTextValues = repeatingDaysTypeValues.map(
      (dayIndex) => daysOfTheWeek[dayIndex]
    );
    customText += concatenateArrayToString(daysTextValues);
  } else if (repeatingDaysType === RepeatingDaysType.DaysOfTheMonth) {
    customText += concatenateArrayToString(repeatingDaysTypeValues, true);
  }

  if (repeatingMonthsType === RepeatingMonthsType.AllMonths) {
    customText += " of all months";
  } else if (repeatingMonthsType === RepeatingMonthsType.SpecificMonths) {
    if (Array.isArray(repeatingMonthsTypeValues)) {
      const isMoreThanOneMonth = repeatingMonthsTypeValues.length > 1;
      customText += " of the month";
      customText += isMoreThanOneMonth ? "s" : "";
    }
    const monthTextValues = repeatingMonthsTypeValues.map(
      (monthIndex) => monthsOfTheYear[monthIndex]
    );
    customText += concatenateArrayToString(monthTextValues);
  }

  return customText === " then repeat it every" ? "" : customText;
};

const get12HourFrom24HourWithPeriod = (hours) => {
  return {
    twelveHour: hours > 12 ? hours - 12 : hours,
    twelveHourPeriod: hours >= 12 ? "PM" : "AM",
  };
};

const getRRuleOptions = (schedule) => {
  let { calendarDay, dayOfTheWeek, month } = getDateValues(schedule.startDate);
  dayOfTheWeek = LocalDateToRRuleDayOfTheWeekMap[dayOfTheWeek];
  let options = {};
  if (schedule.repeatType === RepeatType.DoesNotRepeat) {
    options.count = 1;
    options.freq = RRule.DAILY;
  }
  if (schedule.repeatType === RepeatType.Daily) {
    options.freq = RRule.DAILY;
  }
  if (schedule.repeatType === RepeatType.Weekly) {
    options.freq = RRule.WEEKLY;
    options.byweekday = [dayOfTheWeek];
  }
  if (schedule.repeatType === RepeatType.BiWeekly) {
    options.interval = 2;
    options.byweekday = [dayOfTheWeek];
    options.freq = RRule.WEEKLY;
  }

  if (schedule.repeatType === RepeatType.WeekDays) {
    options.byweekday = [0, 1, 2, 3, 4];
    options.freq = RRule.WEEKLY;
  }
  if (schedule.repeatType === RepeatType.MonthlyOnFirstSelectedWeekDay) {
    options.bysetpos = 1;
    options.byweekday = [dayOfTheWeek];
    options.freq = RRule.MONTHLY;
  }
  if (schedule.repeatType === RepeatType.Yearly) {
    //Adding 1 because indexing of months in RRule starts from 1 not 0
    options.bymonth = [month + 1];
    options.bymonthday = [calendarDay];
    options.freq = RRule.YEARLY;
  }

  if (schedule.repeatType === RepeatType.Custom) {
    const { customValues } = schedule;
    if (customValues.repeatingDaysType === RepeatingDaysType.DaysOfTheMonth) {
      options.bymonthday = customValues.repeatingDaysTypeValues;
      options.freq = RRule.MONTHLY;
    } else if (
      customValues.repeatingDaysType === RepeatingDaysType.DaysOfTheWeek
    ) {
      options.byweekday = (customValues.repeatingDaysTypeValues || []).map(
        (weekday) => LocalDateToRRuleDayOfTheWeekMap[weekday]
      );
      options.freq = RRule.WEEKLY;
    }
    if (
      customValues.repeatingMonthsType === RepeatingMonthsType.SpecificMonths
    ) {
      options.freq = RRule.DAILY;
    }
    //Adding 1 because indexing of months in RRule starts from 1 not 0
    options.bymonth = customValues.repeatingMonthsTypeValues?.map(
      (value) => value + 1
    );
  }

  return options;
};

const getRepeatType = (options, startDayOfTheWeek) => {
  const { freq, count, interval, byweekday, bymonth, bymonthday } = options;
  let repeatType = "";
  if (freq === RRule.DAILY) {
    if (bymonth) {
      repeatType = RepeatType.Custom;
    } else {
      repeatType = count === 1 ? RepeatType.DoesNotRepeat : RepeatType.Daily;
    }
  } else if (freq === RRule.WEEKLY) {
    if (interval === 1 || !interval) {
      const indexesFromMondayToFriday = [1, 2, 3, 4, 5];
      const isAllWeekDays = (repeatType = indexesFromMondayToFriday.every(
        (weekday) => byweekday.includes(weekday)
      ));
      if (isAllWeekDays) {
        repeatType = RepeatType.WeekDays;
      } else if (byweekday.length === 1 && byweekday[0] === startDayOfTheWeek) {
        repeatType = RepeatType.Weekly;
      } else {
        repeatType = RepeatType.Custom;
      }
    } else if (interval === 2) {
      repeatType = RepeatType.BiWeekly;
    }
  } else if (freq === RRule.MONTHLY) {
    repeatType = bymonthday
      ? RepeatType.Custom
      : RepeatType.MonthlyOnFirstSelectedWeekDay;
  } else if (freq === RRule.YEARLY) {
    repeatType = RepeatType.Yearly;
  }
  return repeatType;
};

const getCustomValues = (options) => {
  const customValues = {
    repeatingDaysType: null,
    repeatingDaysTypeValues: null,
    repeatingMonthsType: null,
    repeatingMonthsTypeValues: null,
  };

  const { byweekday, bymonthday, bymonth } = options;
  if (byweekday) {
    customValues.repeatingDaysType = RepeatingDaysType.DaysOfTheWeek;
    customValues.repeatingDaysTypeValues = byweekday;
  } else if (bymonthday) {
    customValues.repeatingDaysType = RepeatingDaysType.DaysOfTheMonth;
    customValues.repeatingDaysTypeValues = bymonthday;
  }

  if (bymonth?.length === 12) {
    customValues.repeatingMonthsType = RepeatingMonthsType.AllMonths;
  } else {
    customValues.repeatingMonthsType = RepeatingMonthsType.SpecificMonths;
  }

  //Subtracting 1 to match Date library whose month indexing starts at 0 instead of 1 like in RRule
  customValues.repeatingMonthsTypeValues = (bymonth || []).map((m) => m - 1);

  return customValues;
};

/**
 *
 * @param {object} schedule object that contains values selected when scheduling a message
 * @returns {string} message summary created from the schedule message object
 */

export const getScheduleMessageSummary = ({
  startDate,
  specifyTime,
  time,
  period,
  repeatType,
  endDate,
  customValues,
  isMessageSummaryDefault = false,
  isMessageSummaryExpanded = false,
}) => {
  const { timezone } = store.getState().users?.loggedUser || {};

  const repeats = repeatType !== RepeatType.DoesNotRepeat;

  const isMessageSummary = isMessageSummaryDefault || isMessageSummaryExpanded;

  let initialText = isMessageSummary ? "" : "Schedule Message";
  let repeatTypeText = "";
  let dayText = "";
  let timeText = "";
  let endDateText = " (Date not Specified)";

  dayText += ` ${getDateText(startDate)}`;

  if (isMessageSummaryDefault) {
    const { hours, minutes } = getHoursAndMinutesFrom12HourFormat(time, period);
    const displayHours = hours < 10 ? `0${hours}` : hours;
    const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

    timeText = `, ${displayHours}:${displayMinutes},`;
  } else {
    timeText = " " + time;
    timeText += " " + period;
  }

  if (
    repeatType !== RepeatType.DoesNotRepeat &&
    repeatType !== RepeatType.Custom
  ) {
    repeatTypeText = getRepeatTypeText(
      repeatType,
      startDate,
      isMessageSummaryDefault,
      isMessageSummaryExpanded
    );
  } else if (repeatType === RepeatType.Custom) {
    repeatTypeText = getCustomRepeatTypeText(customValues);
  } else if (repeatType === RepeatType.DoesNotRepeat) {
    initialText += " once";
  }

  if (repeatType && endDate) {
    const endDateFormat = isMessageSummaryDefault
      ? getDateFormat
      : getEndDateText;
    endDateText = ` ${endDateFormat(endDate)}`;
  }

  initialText += (!isMessageSummary && " on") || "";
  initialText += (!isMessageSummary && dayText) || "";
  initialText +=
    (!isMessageSummary && repeats && repeatTypeText && " and") || "";
  initialText += (repeats && repeatTypeText) || "";
  initialText += (isMessageSummaryDefault && timeText) || "";
  initialText += (repeats && repeatTypeText && " until") || "";
  initialText += (repeats && repeatTypeText && endDateText) || "";

  initialText += (!isMessageSummaryDefault && " at") || "";
  initialText += (!isMessageSummaryDefault && timeText) || "";

  initialText += (!isMessageSummary && ` ${timezone} Time`) || "";

  return initialText;
};

/**
 *
 * @param {*} schedule object that contains values selected when scheduling a message
 * @returns {string} RRule object converted to string
 */

export const getScheduleRRule = (schedule) => {
  const { startDate, time, period, endDate } = schedule;

  const {
    year: startYear,
    month: startMonth,
    calendarDay: startCalendarDay,
  } = getDateValues(startDate);

  let until = null;
  if (endDate) {
    const {
      year: endYear,
      month: endMonth,
      calendarDay: endCalendarDay,
    } = getDateValues(endDate);
    until = new Date(Date.UTC(endYear, endMonth, endCalendarDay, 0, 0, 0));
  }
  const { hours: startHour, minutes: startMinute } =
    getHoursAndMinutesFrom12HourFormat(time, period);

  const scheduleRule = new RRule({
    dtstart: new Date(
      Date.UTC(
        startYear,
        startMonth,
        startCalendarDay,
        startHour,
        startMinute,
        0
      )
    ),
    wkst: RRule.SU,
    until,
    ...getRRuleOptions(schedule),
  });
  return scheduleRule.toString();
};

/**
 *
 * @param {string} recurringRule message.recurring_rule refers to recurring rule
 * @returns {object} schedule object that contains the values selected when scheduling a message
 */

export const getScheduleFromRRuleText = (recurringRule) => {
  const schedule = {};

  const rRuleSet = rrulestr(recurringRule);
  let { dtstart, until } = rRuleSet.origOptions;
  rRuleSet.origOptions.byweekday = (rRuleSet.options.byweekday || []).map(
    (weekday) => RRuleToLocalDateDayOfTheWeekMap[weekday]
  );

  const {
    dayOfTheWeek: startDateDayOfTheWeek,
    hours: startDateHours,
    minutes: startDateMinutes,
    calendarDay: startDateCalendarDay,
    month: startDateMonth,
    year: startDateYear,
  } = getDateValues(dtstart, true);

  const { twelveHour, twelveHourPeriod } =
    get12HourFrom24HourWithPeriod(startDateHours);

  schedule.startDate = new Date(
    startDateYear,
    startDateMonth,
    startDateCalendarDay
  );
  schedule.endDate = until ? new Date(until) : null;
  schedule.time = `${twelveHour}:${
    startDateMinutes > 9 ? startDateMinutes : `0${startDateMinutes}`
  }`;
  schedule.period = twelveHourPeriod;
  schedule.repeatType = getRepeatType(
    rRuleSet.origOptions,
    startDateDayOfTheWeek
  );
  schedule.customValues =
    schedule.repeatType === RepeatType.Custom
      ? getCustomValues(rRuleSet.origOptions)
      : null;

  return schedule;
};

/**
 * @param {string} sendOn refers to the date when the scheduled message should be sent on
 * @returns {object} schedule object that contains the values selected when scheduling a message
 */
export const getScheduleFromSendOn = (sendOn) => {
  let date = new Date();
  if (sendOn) {
    date = new Date(sendOn);
  }

  if (!sendOn || (sendOn && sendOn.includes("+"))) {
    const timezoneOffset =
      store.getState().users?.loggedUser?.timezone_offset || 0;

    date.setTime(date.getTime() + timezoneOffset * 1000);
    const { year, month, calendarDay, hours, minutes } = getDateValues(
      date,
      true
    );
    date = new Date(year, month, calendarDay, hours, minutes);
  }
  const { twelveHourPeriod, twelveHourTime } =
    getTimeAndPeriodIn12HrsFromDate(date);
  return {
    startDate: resetTimeOnDate(date),
    time: twelveHourTime,
    period: twelveHourPeriod,
    repeatType: RepeatType.DoesNotRepeat,
    endDate: null,
    customValues: null,
  };
};
/**
 *
 * @param {object} date date object
 * @param {string} time 12 hour format
 * @param {string} period AM or PM
 * @returns {string} date string in ISO format
 */

export const getSendOnFromStartDateTimeAndPeriod = (date, time, period) => {
  const { hours, minutes } = getHoursAndMinutesFrom12HourFormat(time, period);
  const { year, month, calendarDay } = getDateValues(date);

  const dateStr =
    year +
    "-" +
    `0${month + 1}`.slice(-2) +
    "-" +
    `0${calendarDay}`.slice(-2) +
    "T" +
    `0${hours}`.slice(-2) +
    ":" +
    `0${minutes}`.slice(-2) +
    ":00";
  return dateStr;
};
// Examples of RRules in string form.
// const doesNotRepeat =
//   "DTSTART:20230224T173100Z RRULE:FREQ=DAILY;COUNT=1;WKST=SU";

// const dailyWithEndDate =
//   "DTSTART:20230224T173100Z RRULE:FREQ=DAILY;UNTIL=20230228T000000Z;WKST=SU";

// const dailyWithoutEndDate = "DTSTART:20230224T173100Z RRULE:FREQ=DAILY;WKST=SU";
// const everyDayOnSelectedDate =
//   "DTSTART:20230224T173100Z RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=FR";

// const onWeekdays =
//   "DTSTART:20230224T173100Z RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=MO,TU,WE,TH,FR";

// const biWeekly =
//   "DTSTART:20230224T173100Z RRULE:FREQ=WEEKLY;INTERVAL=2;WKST=SU;BYDAY=FR";

// const monthlyOnSelectedDay =
//   "DTSTART:20230224T173100Z RRULE:FREQ=MONTHLY;WKST=SU;BYDAY=FR;BYSETPOS=1";

// const yearly =
//   "DTSTART:20230224T173100Z RRULE:FREQ=YEARLY;WKST=SU;BYMONTH=2;BYMONTHDAY=24";

// const customDayOfTheWeek =
//   "DTSTART:20230224T173100Z RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=SU,MO;BYMONTH=1,2,3,4,5,6,7,8,9,10,11,12";

// const customDaysOfTheMonth =
//   "DTSTART:20230224T173100Z RRULE:FREQ=MONTHLY;WKST=SU;BYMONTH=1,2,3,4,5,6,7,8,9,10,11,12;BYMONTHDAY=10,15";

// const customSpecificMonths =
//   "DTSTART:20230224T173100Z RRULE:FREQ=DAILY;WKST=SU;BYMONTH=1,2,3,4,5,6,7,8,9,10,11;BYMONTHDAY=10,15";

// // How to test is pass the rule to the getScheduleFromRRuleText which returns a schedule object
// // Pass the schedule object to getScheduleRRule and compare if the string returns passed matches what you passed to the getScheduleFromRRuleText
// // This helps know that the two functions are working correctly when processing data
// // The test suite below is an example of how to test the functions

// const comparePassedAndCreatedRRule = (param = "") => {
//   const schedule = getScheduleFromRRuleText(param);
//   let rrule = getScheduleRRule(schedule);
//   let [startDateString, otherRulesString] = param.split(" ");
//   otherRulesString = otherRulesString.replace("RRULE:", "");
//   rrule = rrule.replace("RRULE:", "");
//   const ruleArray = [startDateString, ...otherRulesString.split(";")];
//   return ruleArray.every((rule) => {
//     return rrule.includes(rule);
//   });
// };

// export const rruleTestSuite = () => {
//   console.assert(
//     comparePassedAndCreatedRRule(doesNotRepeat),
//     "Does not repeat"
//   );
//   console.assert(
//     comparePassedAndCreatedRRule(dailyWithEndDate),
//     "Date With End Date"
//   );
//   console.assert(
//     comparePassedAndCreatedRRule(dailyWithoutEndDate),
//     "Date Without End Date"
//   );
//   console.assert(
//     comparePassedAndCreatedRRule(everyDayOnSelectedDate),
//     "Every Day On Selected Date"
//   );
//   console.assert(comparePassedAndCreatedRRule(onWeekdays), "On Weekdays");

//   console.assert(comparePassedAndCreatedRRule(biWeekly), "Bi Weekly");
//   console.assert(
//     comparePassedAndCreatedRRule(monthlyOnSelectedDay),
//     "Monthly On Selected Day"
//   );
//   console.assert(comparePassedAndCreatedRRule(yearly), "Yearly");
//   console.assert(
//     comparePassedAndCreatedRRule(customDayOfTheWeek),
//     "Custom Day Of The Week"
//   );
//   console.assert(
//     comparePassedAndCreatedRRule(customDaysOfTheMonth),
//     "Custom Days Of The Month"
//   );
//   console.assert(
//     comparePassedAndCreatedRRule(customSpecificMonths),
//     "Custom Specific Months"
//   );
//   console.log("Assertions passed");
// };

// rruleTestSuite();
