import React, { useEffect, useState, useRef, useCallback } from "react";
import { Popover, PopoverHeader } from "reactstrap";
import ThreadItem from "./hub/ThreadItem";

// import {ReactComponent as MessageSingleSvg} from '../assets/img/icons/notification-icon.svg';

const HeaderUnreadDropdown = (props) => {
  const { open, unreadsStatus, load } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (open && unreadsStatus === null) {
      load();
    }
  }, [open, load, unreadsStatus]);

  const toggle = useCallback(
    (value) => {
      if (popoverOpen && !value) {
        timerRef.current = setTimeout(() => {
          setPopoverOpen(value);
        }, 500);
      } else {
        setPopoverOpen(value);
      }
    },
    [popoverOpen]
  );

  useEffect(() => {
    toggle(open);
  }, [open, toggle]);

  const toggleHovered = (action) => {
    clearTimeout(timerRef.current);
  };

  const showUnread = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    props.showUnread();
    // setOpen(false);
  };

  // if (props.unreadsTotal <= 0) {
  //   return null;
  // }

  return (
    <div className="sidebar-notification-drop-container">
      <Popover
        placement="left-start"
        isOpen={popoverOpen}
        target={props?.target}
        className={"side-menu-popover sidebar-unread-popover"}
        onMouseEnter={() => toggleHovered()}
      >
        {/* <DropdownToggle style={{ display: 'none' }}></DropdownToggle> */}
        <PopoverHeader>
          <span>
            {props.unreadsTotal} Unread{" "}
            {props.unreadsTotal > 1 ? "Threads" : "Thread"}
          </span>
        </PopoverHeader>
        <div className="notifications">
          {(props.unreadsStatus === "loading" ||
            props.undeliveredsStatus === "loading") && (
            <p className="text-muted text-center py-3">Loading...</p>
          )}
          {(props.unreadsStatus === "success" ||
            props.undeliveredsStatus === "success") && (
            <ul className="notification-list">
              {props.unreads && props.unreads.length === 0 && (
                <p className="text-center mt-3">Nothing found...</p>
              )}
              {props.unreads &&
                props.unreads
                  .slice(0, 5)
                  .map((thread, idx) => (
                    <ThreadItem
                      key={`${thread.item_key}-${idx}`}
                      className={"quick-menu-threads"}
                      hideHoverEffect={true}
                      {...thread}
                    />
                  ))}
            </ul>
          )}
          <div className="topnav-dropdown-footer">
            <a href="#void" onClick={showUnread}>
              View All
            </a>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default HeaderUnreadDropdown;
