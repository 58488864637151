import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ThreadsActionsPopover from "../elements/ThreadsActionsPopover";

const ThreadsActionBroadcast = (props) => {
  const {
    handleViewReport,
    isScheduledBroadast,
    handleViewScheduleMessage,
    handleEditScheduleMessage,
    isShowEditScheduleMessage,
    isShowDeleteScheduleMessage,
    handleDeleteScheduleMessage,
    isShowSendNowScheduleMessage,
    handleSendNowSceduleMessage,
    isShowReportActionWithScheduleMessage,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onClickMenu = useCallback(
    (func) => () => {
      func();
      toggle();
    },
    [toggle]
  );
  return (
    <>
      <ThreadsActionsPopover
        isOpen={isOpen}
        toggle={toggle}
        id={"tag-view-dropdown-lhs"}
        placement="bottom-end"
        isOnLHSThreadsActions={true}
        className={"broadcast-thread-actions-btn"}
        menuItems={
          <>
            {isScheduledBroadast ? (
              <>
                <button onClick={onClickMenu(handleViewScheduleMessage)}>
                  View Schedule Message
                </button>
                {isShowEditScheduleMessage && (
                  <button onClick={onClickMenu(handleEditScheduleMessage)}>
                    Edit
                  </button>
                )}
                {isShowSendNowScheduleMessage && (
                  <button onClick={onClickMenu(handleSendNowSceduleMessage)}>
                    Send Now
                  </button>
                )}
                {isShowDeleteScheduleMessage && (
                  <button
                    className="orange-text"
                    onClick={onClickMenu(handleDeleteScheduleMessage)}
                  >
                    Delete
                  </button>
                )}
                {isShowReportActionWithScheduleMessage && (
                  <button onClick={onClickMenu(handleViewReport)}>
                    View Full Report
                  </button>
                )}
              </>
            ) : (
              <button onClick={onClickMenu(handleViewReport)}>
                View Full Report
              </button>
            )}
            {/* TODO: Add event handlers once we're cleared about flow of this two buttons */}
            {/* <button>Duplicate</button>
            <button>Export</button> */}
          </>
        }
      />
    </>
  );
};

ThreadsActionBroadcast.propTypes = {
  handleViewReport: PropTypes.func,
  handleViewScheduleMessage: PropTypes.func,
  handleEditScheduleMessage: PropTypes.func,
  handleDeleteScheduleMessage: PropTypes.func,
  handleSendNowSceduleMessage: PropTypes.func,
  isScheduledBroadast: PropTypes.bool,
  isShowEditScheduleMessage: PropTypes.bool,
  isShowDeleteScheduleMessage: PropTypes.bool,
  isShowSendNowScheduleMessage: PropTypes.bool,
  isShowReportActionWithScheduleMessage: PropTypes.bool,
};

ThreadsActionBroadcast.defaultProps = {
  handleViewReport: () => {},
  handleViewScheduleMessage: () => {},
  handleEditScheduleMessage: () => {},
  handleDeleteScheduleMessage: () => {},
  handleSendNowSceduleMessage: () => {},
  isScheduledBroadast: false,
  isShowEditScheduleMessage: false,
  isShowDeleteScheduleMessage: false,
  isShowSendNowScheduleMessage: false,
  isShowReportActionWithScheduleMessage: false,
};

export default ThreadsActionBroadcast;
