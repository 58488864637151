import React from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { formatPhoneNumber } from "../../../helpers";
import SpanWithTooltip from "../../SpanWithTooltip";
import IntegrationIcon from "../../IntegrationIcon";
import LookupIcon from "../../LookupIcon";
//new icons
import { ReactComponent as HasDuplicateIcon } from "../../../assets/img/icons-new/group-tab/group-icon/group-inverse-gray.svg";
import { ReactComponent as CloseSmallIcon } from "../../../assets/img/icons-new/general/close/close-gray.svg";
import { ReactComponent as DuplicateIcon } from "../../../assets/img/icons-new/general/duplicate/duplicate-black.svg";
import flagUsSvg from "../../../assets/img/icons-new/general/flags/US-flag.svg";

import { plBasicTheme } from "../../../assets/base/theme";

class PreviewContacts extends React.Component {
  constructor(props) {
    super(props);

    this.updateView = this.updateView.bind(this);
    this.populateState = this.populateState.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
      membersData: [],
    };
  }

  componentDidMount() {
    this.populateState();
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
    if (prevProps.membersData !== this.props.membersData) {
      this.populateState();
    }
  }

  populateState() {
    const { membersData: unparsedData } = this.props;
    const membersData = [];

    for (const member in unparsedData) {
      membersData.push(unparsedData[member]);
    }

    this.setState({ membersData });
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  render() {
    const { membersData } = this.state;
    const tableColumns = [
      { id: "first_name", label: "First Name", minWidth: 100 },
      { id: "last_name", label: "Last Name", minWidth: 100 },
      { id: "phone_number", label: "Phone Number", minWidth: 180 },
      { id: "email", label: "Email", minWidth: 100 },
    ];

    return (
      <div
        className={
          "modal fade inbox-modal confirm-group-message" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="SkipContactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SkipContactModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-backdrop fade show"
          onClick={(e) => {
            e.preventDefault();
            this.props.history.goBack();
          }}
        />
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ zIndex: 100 }}
        >
          <div
            className="modal-content themed-modal"
            style={{ minWidth: "740px" }}
          >
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.props.closeModal}
            >
              <CloseSmallIcon />
            </button>
            <div className="modal-body">
              <div className="skip-sending-multiple-content">
                {this.props.modalBodyHeader}
              </div>
              <MuiThemeProvider theme={plBasicTheme}>
                <TableContainer component="div">
                  <Table
                    aria-label="duplicate contacts table"
                    height="40vh"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        {tableColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            colSpan={1}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {membersData &&
                        membersData.map((row) => (
                          <TableRow key={row.id} role="checkbox">
                            {tableColumns.map((column) => {
                              const value = row[column.id];
                              if (column.id === "phone_number") {
                                return (
                                  <TableCell key={column.id}>
                                    <div className="number">
                                      <span className="numeric">
                                        {formatPhoneNumber(
                                          "" +
                                            (row.country
                                              ? row.country.phonecode
                                              : "") +
                                            row.phone_number
                                        )}
                                      </span>
                                      {row._isIntegration && (
                                        <SpanWithTooltip
                                          className="icon ccb"
                                          title={this.props.alertTooltip}
                                        >
                                          <IntegrationIcon
                                            {...row}
                                            baseSize={16}
                                          />
                                        </SpanWithTooltip>
                                      )}
                                      {!row._isIntegration && (
                                        <span className="icon">
                                          <IntegrationIcon
                                            {...row}
                                            baseSize={16}
                                          />
                                        </span>
                                      )}
                                      <span className="icon">
                                        {!!row.is_duplicate && (
                                          <DuplicateIcon
                                            width={20}
                                            className=""
                                          />
                                        )}
                                        {!row.is_duplicate && (
                                          <HasDuplicateIcon
                                            width={20}
                                            className=""
                                          />
                                        )}
                                      </span>
                                      <span className="icon">
                                        <LookupIcon
                                          {...row.number_lookup}
                                          baseSize={16}
                                          newLookupIcon
                                        />
                                      </span>
                                      {row.country &&
                                        row.country.iso === "US" && (
                                          <span className="icon">
                                            <img
                                              src={flagUsSvg}
                                              width="16"
                                              alt="US"
                                            />
                                          </span>
                                        )}
                                    </div>
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell key={column.id}>{value}</TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PreviewContacts);
