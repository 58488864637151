/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

/**
 *
 * @returns {Function}
 */
export function clearErrors() {
  return function (dispatch) {
    dispatch({ type: "CLEAR_ERRORS" });
  };
}

export function toggleSidebar() {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_SIDEBAR",
    });
  };
}

export function setTopBarsHeight(height) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_TOP_BARS_HEIGHT",
      height,
    });
  };
}

export function setIsLeftPanelScrollingDown(isScrolling) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_IS_LEFT_PANEL_SCROLLING_DOWN",
      isScrolling,
    });
  };
}

export function setIsMainPanelScrollingUp(isScrolling) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_IS_MAIN_PANEL_SCROLLING_UP",
      isScrolling,
    });
  };
}

export function showLoading() {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SHOW_LOADING",
    });
  };
}

export function toggleArchiveSwitch(id, value) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_ARCHIVE_SWITCH",
      id,
      value,
    });
  };
}

export function toggleSearch(val) {
  return function (dispatch) {
    dispatch({ type: "TOGGLE_SEARCH", val });
  };
}

export function addInfoSnackBar(data) {
  return function (dispatch) {
    dispatch({ type: "MAIN/ADD_INFO_SNACK_BAR", data });
  };
}

export function removeInfoSnackBar(id) {
  return function (dispatch) {
    dispatch({ type: "MAIN/REMOVE_INFO_SNACK_BAR", id });
  };
}

export function addDataForModal(dataForModal) {
  return function (dispatch) {
    dispatch({ type: "MAIN/ADD_DATA_FOR_MODAL", dataForModal });
  };
}

export function setIsOnPeopleTableView(isPeopleOnTableView) {
  return function (dispatch) {
    dispatch({ type: "MAIN/SET_IS_ON_PEOPLE_TABLE_VIEW", isPeopleOnTableView });
  };
}

export function setIsOnGroupsTableView(isGroupsOnTableView) {
  return function (dispatch) {
    dispatch({ type: "MAIN/SET_IS_ON_GROUPS_TABLE_VIEW", isGroupsOnTableView });
  };
}

export function changeIframeKey() {
  return function (dispatch) {
    dispatch({
      type: "MAIN/CHANGE_IFRAME_KEY",
    });
  };
}

export function extendLegacySession(show) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/EXTEND_LEGACY_SESSION_OPENER",
      show,
    });
  };
}

export function toggleSessionExpired(show) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_SESSION_EXPIRED_MODAL",
      show,
    });
  };
}

export function setRedirectedToDashboardWhenNoAssignedNumber(isRedirected) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_REDIRECTED_DASHBOARD_WHEN_NO_ASSIGNED_NUMBER",
      isRedirected,
    });
  };
}

export function toggleSessionReplacedModal(show) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_SESSION_STARTED_MODAL",
      show,
    });
  };
}

export function toggleShowErrorModal(show) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_SHOW_ERROR_MODAL",
      show,
    });
  };
}

export function togggleShowEmbededScreemOnFullMode(show) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/TOGGLE_SHOW_EMBEDED_SCREEN_ON_FULL_MODE",
      show,
    });
  };
}

export function storeOldRequestInQueueForCFChallenge(orignalRequests) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/STORE_OLD_REQUEST_IN_QUEUE_FOR_CF_CHALLENGE",
      orignalRequests,
    });
  };
}

export function setEmbeddedScreenLoading(isLoading) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_EMBEDDED_SCREEN_LOADING",
      isLoading,
    });
  };
}

export function setUnsupportedMicrophonePermissionModalData(data = {}) {
  return function (dispatch) {
    dispatch({
      type: "MAIN/SET_UNSUPPORTED_MICROPHONE_PERMISSION_MODAL",
      data,
    });
  };
}
