export const CHROME_MICROPHONE_PERMISSIONS_URL =
  "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop";
export const FIREFOX_MICROPHONE_PERMISSIONS_URL =
  "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions";
export const MICROSOFT_EDGE_MICROPHONE_PERMISSIONS_URL =
  "https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge";
export const SAFARI_MICROPHONE_PERMISSIONS_URL =
  "https://help.doxy.me/en/articles/836274-camera-and-microphone-permission-safari";

export const BROWSER_PERMISSIONS_URLS = {
  chrome: CHROME_MICROPHONE_PERMISSIONS_URL,
  firefox: FIREFOX_MICROPHONE_PERMISSIONS_URL,
  edge: MICROSOFT_EDGE_MICROPHONE_PERMISSIONS_URL,
  safari: SAFARI_MICROPHONE_PERMISSIONS_URL,
};

export const browserMicrophonePermissionsGuide = {
  chrome: [
    "Open Chrome and navigate to the website for which you want to enable microphone access.",
    "Click on the lock icon located in the address bar.",
    "In the dropdown menu, click on Site settings.",
    "Under Permissions, find Microphone and select Allow from the dropdown menu.",
  ],
  firefox: [
    "Open Firefox and navigate to the website for which you want to enable microphone access.",
    "Click on the lock icon located in the address bar.",
    "In the dropdown menu, click on the arrow next to the microphone icon.",
    "Find Use the Microphone and check the box Use Default or directly select Allow.",
  ],
  edge: [
    "Open Microsoft Edge and navigate to the website for which you want to enable microphone access.",
    "Click on the lock icon located in the address bar.",
    "In the dropdown menu, click on Site permissions.",
    "Under Permissions, find Microphone and select Allow from the dropdown menu.",
  ],
  safari: [
    "Open Safari and navigate to the website for which you want to enable microphone access.",
    "Click on Safari in the top menu bar and select Preferences.",
    "In the Preferences window, click on the Websites tab.",
    "Find Microphone in the left sidebar and select Allow from the dropdown menu.",
  ],
};
