import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";

import { ReactComponent as CollapseIcon } from "../../assets/img/icons-new/main-nav-v2/collapse/collapse-gray.svg";
import { ReactComponent as ExpandIconPurpleLight } from "../../assets/img/icons-new/main-nav-v2/expand/expand-purple-light.svg";
import { ReactComponent as VacationReplyIcon } from "../../assets/img/icons-new/general/umbrella/umbrella-black.svg";

import SidebarSettingsDropright from "./SidebarSettingsDropright/SidebarSettingsDropright";
import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import { UserMainMenuLookEnums } from "../../utils/enums/usersEnums";
import PropTypes from "prop-types";
import { LightTooltip } from "../../helpers";
import { closeRHSAllowed } from "../hub/ThreadItems/threadItemHelper";
import AutoResponderBanner from "../Menus/shared/NumberSettingsTableBody/AutoResponderBanner";
import SidebarCollapseNotification from "../hub/HelperComponents/SidebarCollapseNotification";
import { useNumbersStore } from "../../utils/hooks/store/useNumbersStore";
import { isNotAnEmptyArray } from "../../utils/settingsHelpers";

const SidebarBottom = (props) => {
  const { isSidebarExpanded } = props;
  const { tab, interlocutorId } = useParams();
  const {
    companies: { currentCompany },
  } = useCompaniesStore();
  const {
    users: { updateUserSettingsStatus, settings },
    updateUserSettings,
    updateMainMenuLook,
    updateMainMenuLookOnMessages,
  } = useUsersStore();
  const { numbers } = useNumbersStore();

  const isVacationReplyOn = useMemo(() => {
    if (numbers?.numbers && isNotAnEmptyArray(numbers?.numbers)) {
      return numbers?.numbers.some((number) => number?.vacation_autoresponder);
    }
    return false;
  }, [numbers]);

  const loading = updateUserSettingsStatus === "loading";

  const onCollapseClick = useCallback(() => {
    if (loading) return;
    const settingsMainMenuLook =
      closeRHSAllowed.includes(tab) && interlocutorId
        ? settings?.main_menu_look_on_messages
        : settings?.main_menu_look;
    const mainMenuLook =
      settingsMainMenuLook === UserMainMenuLookEnums.ICONS_AND_TEXTS
        ? UserMainMenuLookEnums.ICONS
        : UserMainMenuLookEnums.ICONS_AND_TEXTS;
    if (closeRHSAllowed.includes(tab) && interlocutorId) {
      updateMainMenuLookOnMessages(mainMenuLook);
    } else {
      updateMainMenuLook(mainMenuLook);
      updateUserSettings({
        main_menu_look: mainMenuLook,
      });
    }
  }, [
    tab,
    loading,
    interlocutorId,
    settings?.main_menu_look,
    settings?.main_menu_look_on_messages,
    updateUserSettings,
    updateMainMenuLook,
    updateMainMenuLookOnMessages,
  ]);

  return (
    <div
      className={clsx("bottom-container", {
        minimized: !isSidebarExpanded,
      })}
    >
      <SidebarCollapseNotification
        show={!isSidebarExpanded && isVacationReplyOn}
        className="mb-2 mr-3"
      >
        <VacationReplyIcon />
      </SidebarCollapseNotification>

      {isSidebarExpanded && (
        <AutoResponderBanner
          className={"mb-2"}
          isVacationReplyOn={isVacationReplyOn}
          withCloseBtn={true}
        />
      )}

      <SidebarSettingsDropright
        currentCompany={currentCompany}
        isSidebarExpanded={isSidebarExpanded}
      />
      <div
        className={clsx("bottom-container-collapse", {
          minimized: !isSidebarExpanded,
        })}
        onClick={onCollapseClick}
      >
        {isSidebarExpanded ? (
          <span>
            <CollapseIcon /> Collapse
          </span>
        ) : (
          <LightTooltip title="Expand" placement="left">
            <div className="bottom-container__expand flexer-col">
              <ExpandIconPurpleLight className="expand-collapse-icon" />
              <span className="mt-1">Expand</span>
            </div>
          </LightTooltip>
        )}
      </div>
    </div>
  );
};

SidebarBottom.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
};

export default SidebarBottom;
