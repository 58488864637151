import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIsAdminOrOwner } from "../../../utils/hooks/useIsAdminOrOwner";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import PropTypes from "prop-types";

const AccountIssueButtons = (props) => {
  const { url, onClose, withButtons, issueKey } = props;
  const history = useHistory();
  const location = useLocation();

  const { companies } = useCompaniesStore();
  const isAdminOrOwner = useIsAdminOrOwner(companies?.currentCompany);

  const onClickGoToBilling = useCallback(
    (event) => {
      event && event.preventDefault();
      event && event.stopPropagation();
      history.push(url, { backRoute: location?.pathname });
    },
    [history, location?.pathname, url]
  );
  const onClickUserCredits = useCallback(() => {
    history.push(
      "/hub/menus/filter/settings/admin-settings/submenu/admin-users/active-users",
      { backRoute: location?.pathname }
    );
  }, [history, location?.pathname]);

  return (
    <>
      {withButtons && issueKey !== "no-credits" && (
        <div>
          {url && (
            <button
              onClick={onClickGoToBilling}
              type="button"
              className="btn btn-primary text-white mt-3 mr-2"
            >
              Go to Billing
            </button>
          )}
          <button
            type="button"
            className={
              "btn mt-3 " + (url ? "btn-outline-primary" : "btn-primary")
            }
            onClick={onClose}
          >
            Close
          </button>
        </div>
      )}
      {issueKey === "no-credits" && isAdminOrOwner && (
        <div className="no-credits-buttons">
          <button
            onClick={onClickUserCredits}
            className="btn btn-outline-primary"
          >
            View/Adjust Users’ Credits
          </button>
          <button className="btn btn-primary" onClick={onClickGoToBilling}>
            Purchase More Credits
          </button>
        </div>
      )}
    </>
  );
};

AccountIssueButtons.propTypes = {
  withButtons: PropTypes.string,
  issueKey: PropTypes.string,
  onClose: PropTypes.number,
  url: PropTypes.string,
};

AccountIssueButtons.defaultProps = {
  withButtons: true,
  issueKey: "",
  onClose: () => null,
  url: null,
};

export default AccountIssueButtons;
