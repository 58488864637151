import React from "react";
import { ReactComponent as SyncSVG } from "../../../assets/img/icons-new/settings/refresh/refresh-blue.svg";
import SpanWithTooltip from "../../SpanWithTooltip";
import PropTypes from "prop-types";
import { isSyncGroupInProgress } from "../../../utils/groupHelpers";

const ThreadItemGroupSync = (props) => {
  const { group } = props;
  if (!isSyncGroupInProgress(group)) return null;
  return (
    <SpanWithTooltip
      className="sync-icon-tooltip"
      title={"Syncing in progress"}
      placement="bottom"
    >
      <SyncSVG className="sync-icon ml-2" />
    </SpanWithTooltip>
  );
};

ThreadItemGroupSync.propTypes = {
  group: PropTypes.object,
};

ThreadItemGroupSync.defaultProps = {
  group: null,
};

export default ThreadItemGroupSync;
