import { isReactNativeApp } from "./helpers";
import { store } from "./store";
import { USER_LOGOUT } from "./utils/constants";

export default class FcmConfig {
  static userId = null;
  static token = null;
  static refreshToken = null;
  static accessToken = null;
  static primaryAppUrl = process.env.REACT_APP_PRIMARY_APP_URL.replace(
    /(http(|s):\/\/|\/$)/g,
    ""
  );

  static init(userId, refreshToken, accessToken) {
    FcmConfig.userId = userId;
    FcmConfig.token = refreshToken; // this is the refresh token
    FcmConfig.refreshToken = refreshToken; // this is the refresh token
    FcmConfig.accessToken = accessToken; // this is the access token
  }

  // this is the refresh token
  static setRefreshToken(token) {
    FcmConfig.token = token;
    FcmConfig.refreshToken = token;
  }

  // this is the access token
  static setAccessToken(accessToken) {
    FcmConfig.accessToken = accessToken;
  }

  static sendParentMessage(event) {
    if (window.parent && FcmConfig.userId) {
      const user = store.getState().users;
      const intercomUserHashAndroid =
        user?.loggedUser?.intercomUserHashAndroid || "";
      const intercomUserHashIos = user?.loggedUser?.intercomUserHashIOs || "";
      const message =
        event === USER_LOGOUT
          ? null
          : {
              event,
              token: FcmConfig.token,
              value: FcmConfig.userId,
              topic: `${FcmConfig.primaryAppUrl}-${FcmConfig.userId}`,
              intercomUserHashAndroid,
              intercomUserHashIos,
              refreshToken: FcmConfig.refreshToken,
              accessToken: FcmConfig.accessToken,
            };
      window.parent.postMessage(message, "*");
      if (isReactNativeApp()) {
        // "user-logout" message will be posted separately to RN from the router.js component
        window.ReactNativeWebView?.postMessage(JSON.stringify(message));
      }
    }
  }
}
