import { useMemo } from "react";

export const useIsRestrictedUser = (user) => {
  const isRestrictedUser = useMemo(
    () =>
      user &&
      !user.is_admin_user &&
      !user.is_admin_plus &&
      !(user.is_owner || user.is_main_owner || user.invited_owner) &&
      user.contact_management === 1,
    [user]
  );

  return isRestrictedUser;
};

export const isUserOrRestrictedUser = (user) => {
  return (
    user &&
    !user.is_admin_user &&
    !user.is_admin_plus &&
    !(user.is_owner || user.is_main_owner || user.invited_owner)
  );
};
