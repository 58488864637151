import React from "react";
//new icons
import { ReactComponent as CloseSvg } from "../../assets/img/icons-new/general/close/close-gray.svg";

export default class CustomModal extends React.PureComponent {
  render() {
    let buttons = [];
    if (this.props.buttons) {
      for (let i = this.props.buttons.length - 1; i >= 0; i--) {
        let btnClass = "btn";
        if (this.props.buttons[i].type === "confirm") {
          btnClass = "btn btn-primary";
        } else if (this.props.buttons[i].type === "cancel") {
          btnClass = "btn btn-outline-primary";
        } else if (this.props.buttons[i].type === "delete") {
          btnClass = "btn btn-danger";
        }
        buttons.push(
          <button
            key={this.props.buttons[i].type + "-" + i}
            type="button"
            className={`${btnClass} ${this.props.buttons[i].className}`}
            disabled={!!this.props.buttons[i].disabled}
            onClick={this.props.buttons[i].handler}
          >
            {this.props.buttons[i]?.loading && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}{" "}
            {this.props.buttons[i].caption}
          </button>
        );
      }
    }

    return (
      <div
        className={
          "modal fade" +
          (this.props.show ? " show" : "") +
          " " +
          this.props.className
        }
        style={{ display: "block" }}
        id="globalModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="globalModalLabel"
        aria-hidden={this.props.show ? "false" : "true"}
        onClick={this.props.closeOnClick ? this.props.closeModal : null}
      >
        <div
          className={"modal-backdrop fade" + (this.props.show ? " show" : "")}
          onClick={this.props.shakeModal}
        />
        <div
          className={
            "modal-dialog " +
            (this.props.dialogClassName
              ? this.props.dialogClassName
              : "modal-dialog-scrollable modal-dialog-centered ")
          }
          role="document"
          style={{ zIndex: 100, ...this.props.styleDialog }}
        >
          <div className="modal-content themed-modal">
            {!this.props.noHeader && (
              <div className="modal-header">
                <h3 className="themed-text text-break" id="globalModalLabel">
                  {this.props.title}
                </h3>
                <div
                  className="close-icon mt-n3 mr-n3"
                  onClick={this.props.closeModal}
                >
                  <CloseSvg width={24} />
                </div>
              </div>
            )}
            <div className="modal-body">{this.props.children}</div>
            {!this.props.noFooter && (
              <div className={`modal-footer ${this.props.footerClassName}`}>
                {buttons}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
