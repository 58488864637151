import React from "react";

const ErrorPage = (props) => {
  return (
    <div className="error-page-wrapper">
      <div className="logo-icon" />
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="container-error-content col">
            <h1>An Error Occurred</h1>
            <p>
              Try going back to the previous page or see our{" "}
              <a href="https://help.pastorsline.com/en/" className="help-text">
                Help Center
              </a>{" "}
              for more information.
            </p>
            <a
              href="/"
              className="btn btn-outline-primary btn-md dashboard-button"
            >
              Go to Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
