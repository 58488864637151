import AxiosConfig from "../../AxiosConfig";
import { toggleSessionExpired } from "../../actions/main";
import { isReactNativeApp } from "../../helpers";
import { clearTokens } from "../../utils/auth";
import { USER_LOGOUT } from "../../utils/constants";

export const handleSessionEndedEvent = () => {
  const callback = (config, dispatch, data) => {
    if (
      window.appSharedSession === data.sessionId &&
      AxiosConfig.getAuthToken()
    ) {
      clearTokens();
      dispatch(toggleSessionExpired(true));
      if (isReactNativeApp()) {
        window.ReactNativeWebView?.postMessage(USER_LOGOUT);
      }
    }
  };

  return {
    event: "session-ended",
    callback,
  };
};
