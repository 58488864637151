import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../../assets/img/icons-new/general/close/close-white.svg";
import { Link } from "react-router-dom";

const NotifierWrapper = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const {
    className,
    textClassName,
    Icon,
    alertMessage,
    linkText,
    link,
    hasAlert,
    textAfterLink,
    closeIconClassName,
    linkClassName,
    iconClassName,
  } = props;

  useEffect(() => {
    if (hasAlert) {
      setShowAlert(true);
    }
  }, [hasAlert]);

  const closeAlert = useCallback(() => {
    setShowAlert(false);
  }, []);

  return (
    <>
      {showAlert && (
        <div className={`alert top-alert ${className}`}>
          <Icon className={iconClassName} />
          <div className={`ml-2 alert-text ${textClassName}`}>
            {alertMessage}&nbsp;
            {linkText && (
              <Link className={linkClassName} to={link || ""}>
                {linkText}
              </Link>
            )}
            &nbsp;{textAfterLink}
          </div>
          <CloseIcon onClick={closeAlert} className={closeIconClassName} />
        </div>
      )}
    </>
  );
};

NotifierWrapper.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  hasAlert: PropTypes.bool,
  textAfterLink: PropTypes.string,
  closeIconClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  Icon: PropTypes.objectOf(PropTypes.any),
};

NotifierWrapper.defaultProps = {
  className: "",
  textClassName: "",
  linkText: "",
  link: "",
  hasAlert: false,
  textAfterLink: "",
  closeIconClassName: "close-icon",
  linkClassName: "",
  iconClassName: "",
};

export default NotifierWrapper;
