export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDateAndTimeNew = (
  currentDate = "",
  timezoneOffset,
  withTime
) => {
  let date = currentDate ? new Date(currentDate) : new Date();
  if (timezoneOffset) {
    date.setTime(date.getTime() + timezoneOffset * 1000);
  }

  const time = ` - ${("0" + date.getUTCHours()).substr(-2)}:${(
    "0" + date.getUTCMinutes()
  ).substr(-2)}`;

  const formatteeDate = `${
    monthNames[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;

  return withTime ? `${formatteeDate}${time}` : formatteeDate;
};
