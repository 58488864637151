const initialState = {
  // Logs
  logs: null,
  logStatus: null,
  logLoadingMoreStatus: null,
  logPage: null,
  logLoadedAll: null,
  logNextPage: null,
  logSearchId: null,

  // Log Embed url
  logsEmbedURL: "logs",
  logsStatisticsEmbedURL: "",
};

export default function analytics(state = initialState, action) {
  let logs = null;
  switch (action.type) {
    // Analytics logs
    case "ANALYTICS/FETCH_LOGS":
      return {
        ...state,
        logStatus: "loading",
        logLoadingMoreStatus: action.page > 1 ? "loading" : null,
      };
    case "ANALYTICS/FETCH_LOGS.SUCCESS":
      if (action.page === 1 || !state.logs) {
        logs = action.payload.items;
      } else {
        logs = state.logs.concat(action.payload.items);
      }
      return {
        ...state,
        logs,
        logStatus: "success",
        logLoadingMoreStatus: action.page > 1 ? "success" : null,
        logPage: action.page,
        logLoadedAll: action.payload.items.length === 0,
        logNextPage: action.payload.next_page,
        logSearchId: action.payload.search_id,
      };
    case "ANALYTICS/FETCH_LOGS.ERROR":
      return {
        ...state,
        logs: null,
        logStatus: "error",
        logLoadingMoreStatus: action.page > 1 ? "error" : null,
        logPage: null,
        logLoadedAll: null,
        logNextPage: null,
        logSearchId: null,
      };
    case "ANALYTICS/FETCH_LOGS.CLEAR":
      return {
        ...state,
        logs: null,
        logStatus: null,
        logLoadingMoreStatus: null,
        logPage: null,
        logLoadedAll: null,
        logNextPage: null,
        logSearchId: null,
      };

    case "LOGS/SET_LOGS_EMBED_URL": {
      return {
        ...state,
        logsEmbedURL: action.embedURL,
      };
    }

    case "LOGS/SET_STATISTICS_EMBED_URL": {
      return {
        ...state,
        logsStatisticsEmbedURL: action.embedURL,
      };
    }

    default:
      return state;
  }
}
