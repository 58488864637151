import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, TextField } from "@material-ui/core";
import { ReactComponent as EyeGraySvg } from "../../assets/img/icons-new/general/preview/preview-gray.svg";
import { ReactComponent as EyePurpleSvg } from "../../assets/img/icons-new/general/preview/preview-purple.svg";

const useStyles = makeStyles(() => ({
  eye: {
    position: "absolute",
    right: 5,
    bottom: 5,
    cursor: "pointer",
  },
  input: {
    paddingRight: 25,
  },
}));

const TextFieldPassword = (props) => {
  const { label, className } = props;

  const [type, setType] = useState("password");

  const classes = useStyles();

  return (
    <div className={`position-relative ${className}`}>
      <TextField
        label={label}
        fullWidth
        inputProps={{
          type: type,
        }}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      {type === "password" ? (
        <EyeGraySvg
          width={18}
          height={18}
          className={classes.eye}
          onClick={() => setType(type === "password" ? "text" : "password")}
        />
      ) : (
        <EyePurpleSvg
          width={18}
          height={18}
          className={classes.eye}
          onClick={() => setType(type === "password" ? "text" : "password")}
        />
      )}
    </div>
  );
};

TextFieldPassword.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

TextFieldPassword.defaultProps = {
  label: "",
  className: "",
};

export default TextFieldPassword;
