import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseWhiteSvg } from "../../../assets/img/icons/close-white.svg";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import ImagesViewer from "../../ImagesViewer";

const NewImageThumbnail = (props) => {
  const { images, handleRemoveImage } = props;
  const [imageViewer, setImageViewer] = useState(false);
  const [imageViewerImages, setImageViewerImages] = useState([]);
  const [imageViewerCurrentImage, setImageViewerCurrentImage] = useState(null);

  const openImageViewer = useCallback(
    (index) => (event) => {
      event?.stopPropagation();
      event?.preventDefault();
      let previewImages = [];
      let currentImage = 0;
      for (let i = 0; i < images.length; i++) {
        previewImages.push({
          src: images[i].url,
        });
        if (i === index) {
          currentImage = i;
        }
      }
      setImageViewer(true);
      setImageViewerImages(previewImages);
      setImageViewerCurrentImage(currentImage);
    },
    [images]
  );

  const onCloseImageViewer = useCallback(() => {
    setImageViewer(false);
    setImageViewerImages([]);
    setImageViewerCurrentImage(null);
  }, []);

  if (!images || !isNotAnEmptyArray(images)) return null;
  return (
    <>
      <div className="messages-card__textarea-attachments">
        {images.map((item, idx) => (
          <div
            className="messages-card__textarea-image position-relative"
            key={idx}
            onClick={openImageViewer(idx)}
          >
            <img
              className="thumbnail messages-card__textarea-image"
              height={68}
              width={68}
              alt="thumbnail"
              src={item.thumbUrl || item.thumbnail}
            />
            <div
              id={item.key}
              className="messages-card__textarea-image-close"
              onClick={handleRemoveImage(idx)}
            >
              <CloseWhiteSvg height={7} width={7} />
            </div>
          </div>
        ))}
      </div>
      {imageViewer && (
        <ImagesViewer
          images={imageViewerImages}
          currentImage={imageViewerCurrentImage}
          close={onCloseImageViewer}
        />
      )}
    </>
  );
};

NewImageThumbnail.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  handleRemoveImage: PropTypes.func,
};

NewImageThumbnail.defaultProps = {
  images: [],
  handleRemoveImage: () => {},
};

export default NewImageThumbnail;
