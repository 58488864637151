import React from "react";
// import PropTypes from "prop-types";
import Calendar from "react-calendar";
import "../../../assets/components_react/SimplePurpleCalendar.scss";

const SimplePurpleCalendar = (props) => {
  return <Calendar className={["simple-purple-calendar"]} {...props} />;
};

SimplePurpleCalendar.propTypes = {};

export default SimplePurpleCalendar;
