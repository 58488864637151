import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";

const SidebarMenuInfo = (props) => {
  const {
    isSidebarExpanded,
    icon,
    label,
    labelClassName,
    iconWrapperClassName,
    isActive,
    isNewMenu,
    isBetaFeature,
    withMenuIcon,
  } = props;

  return (
    <div
      className={clsx({
        "sidebar-expanded-list": isSidebarExpanded,
        "sidebar-expanded-list-active": isActive,
      })}
    >
      {withMenuIcon && (
        <div
          className={`sidebar-expanded-list-icon-wrapper ${iconWrapperClassName}`}
        >
          {icon}
        </div>
      )}

      {isSidebarExpanded && (
        <>
          <label className={clsx("d-flex", labelClassName)}>
            {label}{" "}
            {isNewMenu && (
              <Chip
                label="NEW"
                color="succes"
                size="small"
                classes={{
                  root: "sidebar-menu-new-chip mt-1",
                  label: "sidebar-menu-new-chip-label",
                }}
              />
            )}
            {isBetaFeature && (
              <Chip
                label="Beta"
                color="succes"
                size="small"
                classes={{
                  root: "sidebar-menu-new-chip mt-1 pl-1 pr-1",
                  label: "sidebar-menu-new-chip-label pt-0",
                }}
              />
            )}
          </label>
        </>
      )}
    </div>
  );
};

SidebarMenuInfo.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  isActive: PropTypes.bool,
  isNewMenu: PropTypes.bool,
  withMenuIcon: PropTypes.bool,
};

SidebarMenuInfo.defaultProps = {
  labelClassName: "",
  iconWrapperClassName: "",
  isNewMenu: false,
  withMenuIcon: true,
};

export default SidebarMenuInfo;
