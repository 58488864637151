import axios from "axios";
import AxiosConfig from "../AxiosConfig";

import { refreshCounts } from "../helpers";
import { store } from "../store";
import { postRequest } from "../utils/requests";
import { setUpdatedMembersCounts } from "./groups";
import { fetchCounts as fetchGroupCounts } from "./groups";
import { logAPIRequest } from "../utils/loggerHelpers";
import { fetchCounts as fetchThreadCounts } from "./threads";
import { replaceFirstDashWithComma } from "../utils/settingsHelpers";

const CancelToken = axios.CancelToken;

// We stores requestIds to not overwrite response from earlier request, from previous selected company
let fetchContactsRequestIds = [];

export function resetFetchContactsRequests() {
  fetchContactsRequestIds = [];
}

function removeFetchContactRequestId(requestId) {
  const index = fetchContactsRequestIds.indexOf(requestId);
  if (index >= 0) {
    fetchContactsRequestIds.splice(index, 1);
  }
}

export function fetchContacts(companyId, page, orderBy) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchContactsRequestIds.push(requestId);
    dispatch({
      type: "CONTACTS/FETCH",
      page: page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        page,
        orderBy,
        noGroupDuplicates: 1,
      },
    });

    return axios
      .get(AxiosConfig.getEndpointAddress() + "contacts.json", config)
      .then((response) => {
        if (fetchContactsRequestIds.indexOf(requestId) >= 0) {
          removeFetchContactRequestId(requestId);
          dispatch({
            type: "CONTACTS/FETCH.SUCCESS",
            page: page,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        if (fetchContactsRequestIds.indexOf(requestId) >= 0) {
          removeFetchContactRequestId(requestId);
          dispatch({
            type: "CONTACTS/FETCH.ERROR",
            page: page,
            payload: error,
          });
        }
      });
  };
}

export function fetchFilteredContacts(
  companyId,
  query,
  countryId,
  countryCode,
  filter = "",
  page = 1,
  orderBy
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FETCH_FILTERED",
      query,
      countryId,
      countryCode,
      page,
    });

    let filterVal = filter;
    if (filter && filterVal.includes("-") && filterVal.includes("archived")) {
      const parsed = filterVal.split("-");
      const parsedRemaining = parsed.slice(1);
      filterVal = `${parsed[0]},${parsedRemaining.join("-")}`;
    }

    let noGroupDuplicates = 1;
    if (filter?.startsWith("multi-app")) {
      noGroupDuplicates = 0;
    }

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        query,
        countryId,
        countryCode,
        filter: filterVal,
        page,
        orderBy,
        noGroupDuplicates,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "contacts.json", config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/FETCH_FILTERED.SUCCESS",
          query,
          countryId,
          countryCode,
          page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/FETCH_FILTERED.ERROR",
          query,
          countryId,
          countryCode,
          page,
          payload: error,
        });
      });
  };
}

export function fetchContactData(
  companyId,
  contactId,
  includeArchive = false,
  onlyArchive = false,
  addContactAsFirst = false,
  archivedGroups = "hide"
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FETCH_DATA",
      contactId: contactId,
    });

    const url = `contacts/view/${contactId}.json?companyId=${companyId}&includeArchive=${
      includeArchive ? 1 : 0
    }&onlyArchive=${onlyArchive ? 1 : 0}&archivedGroups=${archivedGroups}`;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CONTACTS/FETCH_DATA.SUCCESS",
          contactId: contactId,
          addContactAsFirst: addContactAsFirst,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/FETCH_DATA.ERROR",
          contactId: contactId,
          payload: error,
        });
      });
  };
}

export function addContact(companyId, contactData) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD" });

    const data = Object.assign({}, contactData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddContact() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD.CLEAR" });
  };
}

export function addQuickContact(companyId, contactData) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD_QUICK" });

    const data = Object.assign({}, contactData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/add-quick.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/ADD_QUICK.SUCCESS",
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/ADD_QUICK.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function editContactField(companyId, contactId, contactData, requestId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/EDIT_FIELD",
      contactId: contactId,
      contactData: contactData,
      requestId: requestId,
    });

    const data = Object.assign({}, contactData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          "contacts/edit/" +
          contactId +
          ".json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/EDIT_FIELD.SUCCESS",
          contactId: contactId,
          contactData: contactData,
          requestId: requestId,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/EDIT_FIELD.ERROR",
          contactId: contactId,
          contactData: contactData,
          requestId: requestId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function resetEditContactStatus(requestId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/EDIT_FIELD.RESET_STATUS",
      requestId,
    });
  };
}

export function clearEditFeildQueue() {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/CLEAR_EDIT_FIELD_QUEUE",
    });
  };
}

export function editContactsField(
  companyId,
  contactIds,
  contactData,
  requestId,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/BATCH_EDIT_FIELD",
      contactIds,
      contactData: contactData,
      requestId: requestId,
    });

    const data = Object.assign({}, contactData, {
      company_id: companyId,
      contact_ids: contactIds,
      all: allParams,
    });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/batch-edit.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/BATCH_EDIT_FIELD.SUCCESS",
          contactIds,
          contactData: contactData,
          requestId: requestId,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/BATCH_EDIT_FIELD.ERROR",
          contactIds,
          contactData: contactData,
          requestId: requestId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearEditContactsFieldStatus(requestId) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/BATCH_EDIT_FIELD.CLEAR", requestId });
  };
}

export function deleteContact(companyId, contactId, groupId) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/DELETE",
      contactId: contactId,
    });

    const url = "contacts/delete/" + contactId + ".json?companyId=" + companyId;
    return axios
      .delete(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/DELETE.SUCCESS",
          contactId: contactId,
          groupId: groupId,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/DELETE.ERROR",
          contactId: contactId,
          payload: error,
        });
      });
  };
}

export function clearDeleteContact() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/DELETE.CLEAR" });
  };
}

export function deleteContacts(companyId, contactIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/BATCH_DELETE",
      contactIds,
    });

    const url = "contacts/batch-delete.json";
    const data = Object.assign(
      {},
      {
        company_id: companyId,
        contact_ids: contactIds,
      }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/BATCH_DELETE.SUCCESS",
          contactIds,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/BATCH_DELETE.ERROR",
          contactIds,
          payload: error,
        });
      });
  };
}

export function checkPhoneNumber(
  companyId,
  countryId,
  phoneNumber,
  exceptContactId
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/CHECK_PHONE_NUMBER",
      countryId: countryId,
      phoneNumber: phoneNumber,
      exceptContactId: exceptContactId,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        countryId: countryId,
        phoneNumber: phoneNumber,
        exceptContactId: exceptContactId,
      },
    });
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "contacts/check-phone-number.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/CHECK_PHONE_NUMBER.SUCCESS",
          countryId: countryId,
          phoneNumber: phoneNumber,
          exceptContactId: exceptContactId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/CHECK_PHONE_NUMBER.ERROR",
          countryId: countryId,
          phoneNumber: phoneNumber,
          exceptContactId: exceptContactId,
          payload: error,
        });
      });
  };
}

export function clearCheckPhoneNumberStatus() {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/CLEAR_CHECK_PHONE_NUMBER_STATUS",
    });
  };
}
export function fetchContactGroups(
  companyId,
  contactId,
  query = "",
  page = null,
  order_by = null,
  withAdhocGroups = 0
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/GROUPS",
      contactId: contactId,
      query: query,
      page: page,
      order_by: order_by,
    });

    const url = "contacts/" + contactId + "/groups.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        page: page,
        order_by: order_by,
        is_adhoc: withAdhocGroups,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/GROUPS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/GROUPS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactGroups() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/GROUPS.CLEAR" });
  };
}

export function patchContactGroups(
  companyId,
  contactId,
  addedGroupIds,
  removedGroupIds
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_GROUPS",
      contactId: contactId,
      groupIds: addedGroupIds,
    });

    const url = "contacts/" + contactId + "/groups.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        contactId: contactId,
        addedGroupIds,
        removedGroupIds,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_GROUPS.SUCCESS",
          contactId: contactId,
          groupIds: addedGroupIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_GROUPS.ERROR",
          contactId: contactId,
          groupIds: addedGroupIds,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactGroups() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_GROUPS.CLEAR" });
  };
}

export function fetchContactTags(
  companyId,
  contactId,
  query = "",
  page = null,
  order_by = null
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/TAGS",
      contactId: contactId,
      query: query,
      page: page,
      order_by: order_by,
    });

    const url = "contacts/" + contactId + "/tags.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        page: page,
        order_by: order_by,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/TAGS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/TAGS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactTags() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/TAGS.CLEAR" });
  };
}

export function patchContactTags(companyId, contactId, tagIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_TAGS",
      contactId: contactId,
      tagIds: tagIds,
    });

    const url = "contacts/" + contactId + "/tags.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        contactId: contactId,
        tagIds: tagIds,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_TAGS.SUCCESS",
          contactId: contactId,
          tagIds: tagIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_TAGS.ERROR",
          contactId: contactId,
          tagIds: tagIds,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactTags() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_TAGS.CLEAR" });
  };
}

export function fetchContactAmCampaigns(
  companyId,
  contactId,
  query = "",
  page = null
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/AM_CAMPAIGNS",
      contactId: contactId,
      query: query,
      page: page,
    });

    const url = "contacts/" + contactId + "/am-campaigns.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId: companyId,
        query: query,
        page: page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/AM_CAMPAIGNS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/AM_CAMPAIGNS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/AM_CAMPAIGNS.CLEAR" });
  };
}

export function patchContactAmCampaigns(companyId, contactId, campaignIds) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_AM_CAMPAIGNS",
      contactId: contactId,
      campaignIds: campaignIds,
    });

    const url = "contacts/" + contactId + "/am-campaigns.json";
    const data = Object.assign(
      {},
      {
        companyId: companyId,
        campaignIds: campaignIds,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_AM_CAMPAIGNS.SUCCESS",
          contactId: contactId,
          campaignIds: campaignIds,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_AM_CAMPAIGNS.ERROR",
          contactId: contactId,
          campaignIds: campaignIds,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_AM_CAMPAIGNS.CLEAR" });
  };
}

export function fetchContactCampaigns(
  companyId,
  contactId,
  query = "",
  page = null,
  order_by = null
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/CAMPAIGNS",
      contactId: contactId,
      query: query,
      page: page,
      order_by: order_by,
    });

    const url = "contacts/" + contactId + "/campaigns.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        company_id: companyId,
        query: query,
        page: page,
        order_by: order_by,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/CAMPAIGNS.SUCCESS",
          contactId: contactId,
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/CAMPAIGNS.ERROR",
          contactId: contactId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactCampaigns() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/CAMPAIGNS.CLEAR" });
  };
}

export function patchContactCampaigns(
  companyId,
  contactId,
  autoresponder_auto_message_id,
  contest_id
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/PATCH_CAMPAIGNS",
      contactId: contactId,
      autoresponder_auto_message_id: autoresponder_auto_message_id,
      contest_id: contest_id,
    });
    const url = "contacts/" + contactId + "/edit-campaigns.json";
    var data = new URLSearchParams();
    data.append("company_id", companyId);
    if (autoresponder_auto_message_id) {
      for (let i = 0; i < autoresponder_auto_message_id.length; i++) {
        data.append(
          "autoresponder_auto_message_id[]",
          autoresponder_auto_message_id[i]
        );
      }
    }
    if (contest_id) {
      for (let i = 0; i < contest_id.length; i++) {
        data.append("contest_id[]", contest_id[i]);
      }
    }

    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig({
          "Content-Type": "application/x-www-form-urlencoded",
        })
      ) //
      .then((response) => {
        dispatch({
          type: "CONTACTS/PATCH_CAMPAIGNS.SUCCESS",
          contactId: contactId,
          autoresponder_auto_message_id: autoresponder_auto_message_id,
          contest_id: contest_id,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/PATCH_CAMPAIGNS.ERROR",
          contactId: contactId,
          autoresponder_auto_message_id: autoresponder_auto_message_id,
          contest_id: contest_id,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchContactCampaigns() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/PATCH_CAMPAIGNS.CLEAR" });
  };
}

export function fetchContactDetails(companyId, contactId, page = null) {
  const url =
    "contacts/" + contactId + "/changes-log.json?company_id=" + companyId;
  const params = {
    companyId: companyId,
    page: page,
  };

  if (!contactId && typeof contactId !== "number") {
    logAPIRequest("fetchContactDetails()", url, params);
    return function () {
      return Promise.reject().catch(() => {});
    };
  }

  return function (dispatch) {
    dispatch({
      type: "CONTACTS/DETAILS",
      contactId: contactId,
      page: page,
    });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params,
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/DETAILS.SUCCESS",
          contactId: contactId,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/DETAILS.ERROR",
          contactId: contactId,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearContactDetails() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/DETAILS.CLEAR" });
  };
}

export function fetchNumberLookupPrice(
  companyId,
  contactIds,
  groupIds,
  recheckAll,
  allParams,
  requestId
) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP_PRICE", requestId });

    const data = Object.assign(
      {},
      {
        companyId,
        contactIds,
        groupIds,
        recheckAll,
        all: allParams,
        request_id: requestId,
      }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/number-lookup-price.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/LOOKUP_PRICE.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (error?.response?.status === 504) return;
        dispatch({
          type: "CONTACTS/LOOKUP_PRICE.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearNumberLookupPrice() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP_PRICE.CLEAR" });
  };
}

export function clearNumberLookupIds() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/CONTACTS_FOR_NUMBER_LOOK_UP.CLEAR" });
  };
}

export function numberLookup(
  companyId,
  contactIds,
  groupIds,
  recheckAll,
  allParams
) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP" });
    const data = Object.assign(
      {},
      { companyId, contactIds, groupIds, recheckAll, all: allParams }
    );

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/number-lookup.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/LOOKUP.SUCCESS",
          payload: response.data,
        });
      })
      .then((response) => {
        const contactCount = response?.payload?.contacts
          ? Object.keys(response.payload.contacts).length
          : 0;

        dispatch(
          setUpdatedMembersCounts(
            "members_unchecked_count",
            false,
            contactCount
          )
        );
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/LOOKUP.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearNumberLookup() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/LOOKUP.CLEAR" });
  };
}

export function queueNumberLookup(
  companyId,
  contactIds,
  groupIds,
  recheckAll,
  allParams
) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/QUEUE_LOOKUP" });

    const data = Object.assign(
      {},
      { companyId, contactIds, groupIds, recheckAll, all: allParams }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "contacts/queue-number-lookup.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/QUEUE_LOOKUP.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/QUEUE_LOOKUP.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
          status: error.response ? error.response?.status : null,
        });
      });
  };
}

export function clearQueueNumberLookup() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/QUEUE_LOOKUP.CLEAR" });
  };
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/FILTER",
      filter: filter,
    });
  };
}

export function grouprhsItemsSelected(selection, selectedContacts = {}) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/GROUPS.RHS_SELECT",
      selection,
      selectedContacts,
    });
  };
}

export function groupRHSSelectionCleared(selection) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/GROUPS.RHS_SELECTION_CLEARED",
      selection,
    });
  };
}

export function archiveContacts(
  company_id,
  contact_id = [],
  group_id = [],
  new_primary_contact_id = {},
  containDuplicates = false,
  workflow_id,
  needRouting = false,
  isUndo = false,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/ARCHIVE_CONTACTS",
      archivingIds: [...contact_id, ...group_id],
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      // new_primary_contact_id,
      warning_duplicates: allParams && allParams?.enabled ? 0 : 1,
      all: allParams,
    };
    if (containDuplicates) {
      data.warning_duplicates = 0;
      if (new_primary_contact_id) {
        data.new_primary_contact_id = new_primary_contact_id;
      }
    }
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-archive.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (response.data && response.data.archived_ids.length > 0) {
          const { main } = store.getState();
          dispatch({
            type: "CONTACTS/ARCHIVE_CONTACTS.SUCCESS",
            payload: response.data,
            contactIds: response.data.archived_ids,
            primaryAssignees: [], // to send newly assigned primary assignees
            workflow_id,
            needRouting,
            archivingIds: [...contact_id, ...group_id],
            isUndo,
            allParams,
            isPeopleOnTableView: main?.isPeopleOnTableView,
          });
        } else {
          dispatch({
            type: "CONTACTS/ARCHIVE_CONTACTS.FAILURE",
            payload: "Couldn't archive",
            workflow_id,
            archivingIds: [...contact_id, ...group_id],
          });
        }
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/ARCHIVE_CONTACTS.FAILURE",
          payload: error,
          workflow_id,
          archivingIds: [...contact_id, ...group_id],
        });
      });
  };
}

export function unarchiveContacts(
  company_id,
  contact_id = [],
  group_id = [],
  new_primary_contact_id = {},
  containDuplicates = false,
  workflow_id,
  needRouting = false,
  isUndo,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/UNARCHIVE_CONTACTS",
      unarchivingIds: [...contact_id, ...group_id],
    });

    const unarchiveAllParams =
      allParams &&
      allParams?.filters &&
      allParams?.filters?.startsWith("archived-")
        ? {
            ...allParams,
            filters: replaceFirstDashWithComma(allParams?.filters),
          }
        : allParams;

    const data = {
      company_id,
      contact_id,
      group_id,
      // new_primary_contact_id,
      warning_duplicates: allParams && allParams?.enabled ? 0 : 1,
      all: unarchiveAllParams,
    };
    if (containDuplicates) {
      data.warning_duplicates = 0;
      if (new_primary_contact_id) {
        // Might need it in future
        data.new_primary_contact_id = new_primary_contact_id;
      }
    }
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-unarchive.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (response.data && response.data.unarchived_ids.length > 0) {
          const { main } = store.getState();
          dispatch({
            type: "CONTACTS/UNARCHIVE_CONTACTS.SUCCESS",
            payload: response.data,
            contactIds: response.data.unarchived_ids,
            primaryAssignees: [], // to send newly assigned primary assignees
            workflow_id,
            needRouting,
            unarchivingIds: [...contact_id, ...group_id],
            isUndo,
            allParams,
            isPeopleOnTableView: main?.isPeopleOnTableView,
          });
        } else {
          dispatch({
            type: "CONTACTS/UNARCHIVE_CONTACTS.FAILURE",
            payload: "Couldn't archive",
            workflow_id,
            unarchivingIds: [...contact_id, ...group_id],
          });
        }
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/UNARCHIVE_CONTACTS.FAILURE",
          payload: error,
          workflow_id,
          unarchivingIds: [...contact_id, ...group_id],
        });
      });
  };
}

export function clearArchiveData() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/CLEAR_ARCHIVE_DATA",
    });
  };
}

let fetchCountsCancel;

export function cancelFetchCountsContacts() {
  fetchCountsCancel && fetchCountsCancel("CONTACTS/COUNTS/CANCELED");
}

export function fetchCounts(companyId, fields = []) {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/COUNTS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        company_id: companyId,
        all: fields?.length === 0 ? 1 : 0,
        ...(Array.isArray(fields) && { fields }),
      },
      cancelToken: new CancelToken((c) => {
        fetchCountsCancel = c;
      }),
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "contacts/stats.json", config)
      .then((response) => {
        dispatch({
          type: "CONTACTS/COUNTS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/COUNTS.ERROR",
          payload: error,
        });
      });
  };
}

export function unsubscribeContacts(
  company_id,
  contact_id = [],
  group_id = [],
  isUndo = false,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/UNSUBSCRIBE_CONTACTS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-unsubscribe.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/UNSUBSCRIBE_CONTACTS.SUCCESS",
          payload: {
            contactIds: contact_id,
            groupIds: group_id,
          },
          isUndo,
          eventLog: response.data?.items,
        });
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/UNSUBSCRIBE_CONTACTS.FAILURE",
          payload: error,
        });
      });
  };
}

export function clearUnSubscribeContacts() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/UNSUBSCRIBE_CONTACTS.CLEAR",
    });
  };
}

export function subscribeContacts(
  company_id,
  contact_id = [],
  group_id = [],
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/SUBSCRIBE_CONTACTS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-subscribe.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/SUBSCRIBE_CONTACTS.SUCCESS",
          payload: {
            contactIds: contact_id,
            groupIds: group_id,
          },
          eventLog: response.data?.items,
        });
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/SUBSCRIBE_CONTACTS.FAILURE",
          payload: error,
        });
      });
  };
}

export function clearSubscribeContacts() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/SUBSCRIBE_CONTACTS.CLEAR",
    });
  };
}

export function exportContacts(
  company_id,
  contact_id = [],
  group_id = [],
  allParams,
  statusId
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/EXPORT_CONTACTS",
      statusId,
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/export.csv`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/EXPORT_CONTACTS.SUCCESS",
          payload: response.data,
          statusId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/EXPORT_CONTACTS.FAILURE",
          payload: error,
          statusId,
        });
      });
  };
}

export function clearExportContacts(id) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/EXPORT_CONTACTS.CLEAR",
      id,
    });
  };
}

export function doNotCallContacts(
  company_id,
  contact_id = [],
  group_id = [],
  isUndo = false,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/DONOTCALL_CONTACTS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-do-not-call.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/DONOTCALL_CONTACTS.SUCCESS",
          payload: {
            contactIds: contact_id,
            groupIds: group_id,
          },
          isUndo,
          eventLog: response.data?.items,
        });
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/DONOTCALL_CONTACTS.FAILURE",
          payload: error,
        });
      });
  };
}

export function clearDoNotCallContacts() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/DONOTCALL_CONTACTS.CLEAR",
    });
  };
}

export function resetDoNotCallContacts(
  company_id,
  contact_id = [],
  group_id = [],
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/RESET_DONOTCALL_CONTACTS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          `contacts/bulk-reset-do-not-call.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/RESET_DONOTCALL_CONTACTS.SUCCESS",
          payload: {
            contactIds: contact_id,
            groupIds: group_id,
          },
          eventLog: response.data?.items,
        });
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/RESET_DONOTCALL_CONTACTS.FAILURE",
          payload: error,
        });
      });
  };
}

export function clearResetDoNotCallContacts() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/RESET_DONOTCALL_CONTACTS.CLEAR",
    });
  };
}

export function blockContacts(
  company_id,
  contact_id = [],
  group_id = [],
  isUndo = false,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/BLOCK_CONTACTS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-block.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/BLOCK_CONTACTS.SUCCESS",
          payload: {
            contactIds: contact_id,
            groupIds: group_id,
          },
          isUndo,
          eventLog: response.data?.items,
        });
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/BLOCK_CONTACTS.FAILURE",
          payload: error,
        });
      });
  };
}

export function unblockContacts(
  company_id,
  contact_id = [],
  group_id = [],
  isUndo,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "CONTACTS/UNBLOCK_CONTACTS",
    });
    const data = {
      company_id,
      contact_id,
      group_id,
      all: allParams,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `contacts/bulk-unblock.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "CONTACTS/UNBLOCK_CONTACTS.SUCCESS",
          payload: {
            contactIds: contact_id,
            groupIds: group_id,
          },
          isUndo,
          eventLog: response.data?.items,
        });
      })
      .then(() => {
        dispatch(groupRHSSelectionCleared());
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "CONTACTS/UNBLOCK_CONTACTS.FAILURE",
          payload: error,
        });
      });
  };
}

export function addSnackData(data, entity) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/ADD_SNACK_DATA",
      payload: { data, entity },
    });
  };
}

export function removeSnackData(id, entity) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/REMOVE_SNACK_DATA",
      payload: { id, entity },
    });
  };
}

export function addContactsForNumberLookUp(contactIds) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/ADD_CONTACTS_FOR_NUMBER_LOOK_UP",
      contactIds,
    });
  };
}

export function toggleDeleteContactModal(
  value,
  contact,
  deleteContactIds,
  isBatchDelete
) {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/TOGGLE_DELETE_CONTACT_MODAL",
      payload: {
        value,
        contactToDelete: contact,
        deleteContactIds,
        isBatchDelete,
      },
    });
  };
}

export function toggleShowContactDetail() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/SHOW_CONTACTS_DETAIL",
    });
  };
}

export const addTag = (tag_name, company_id) => {
  return (dispatch) => {
    dispatch({ type: "CONTACTS/ADD_TAG" });

    const url = `groups/add-tag.json`;

    let data = {
      tag_name,
      company_id,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((res) => {
        dispatch({ type: "CONTACTS/ADD_TAG.SUCCESS", payload: res.data.tag });
      })
      .catch(() => {
        dispatch({ type: "CONTACTS/ADD_TAG.ERROR" });
      });
  };
};

export function clearAddTag() {
  return function (dispatch) {
    dispatch({ type: "CONTACTS/ADD_TAG.CLEAR" });
  };
}

export function markContactsAsFavorite(
  company_id,
  contact_id = [],
  group_id = [],
  isUndo,
  allParams
) {
  const data = {
    company_id,
    contact_id,
    group_id,
    all: allParams,
  };
  return (dispatch) => {
    postRequest(
      dispatch,
      "CONTACTS/MARK_AS_FAVORITE",
      "contacts/bulk-favorite.json",
      data,
      {
        successDispatchData: {
          contactIds: contact_id,
          groupIds: group_id,
          isUndo,
        },
      }
    ).then(() => {
      dispatch(groupRHSSelectionCleared());
      dispatch(fetchThreadCounts(company_id, "all", ["favorites"]));
    });
  };
}

export function clearMarkContactsAsFavorite() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/MARK_AS_FAVORITE.CLEAR",
    });
  };
}

export function markContactsAsUnFavorite(
  company_id,
  contact_id = [],
  group_id = [],
  isUndo,
  allParams
) {
  const data = {
    company_id,
    contact_id,
    group_id,
    all: allParams,
  };
  return (dispatch) => {
    postRequest(
      dispatch,
      "CONTACTS/MARK_AS_UNFAVORITE",
      "contacts/bulk-unfavorite.json",
      data,
      {
        successDispatchData: {
          contactIds: contact_id,
          groupIds: group_id,
          isUndo,
        },
      }
    ).then(() => {
      dispatch(groupRHSSelectionCleared());
      dispatch(fetchThreadCounts(company_id, "all", ["favorites"]));
    });
  };
}

export function clearMarkContactsAsUnfavorite() {
  return (dispatch) => {
    dispatch({
      type: "CONTACTS/MARK_AS_UNFAVORITE.CLEAR",
    });
  };
}

export function markAsReadUnresolvedConflict(body, contactId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "CONTACTS/MARK_AS_READ_UNRESOLVED_CONFLICT",
      `contacts/mark-hold-contact-as-read/${contactId}.json`,
      body,
      {
        successDispatchData: {
          contactId,
        },
      }
    );
}

export function clearMarkAsReadUnresolvedConflict() {
  return (dispatch) =>
    dispatch({ type: "CONTACTS/MARK_AS_READ_UNRESOLVED_CONFLICT.CLEAR" });
}

export function markAsReadAllUnresolvedConflict(body) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "CONTACTS/MARK_AS_READ_ALL_UNRESOLVED_CONFLICT",
      `contacts/mark-all-hold-contacts-as-read.json`,
      body,
      {
        thenFunction: () => {
          dispatch(fetchGroupCounts(body.company_id, true));
          dispatch(fetchCounts(body.company_id));
        },
      }
    );
}

export function setContactsInitialFetchFlag(fetched = true) {
  return (dispatch) =>
    dispatch({ type: "CONTACTS/FETCH.INITIAL_FETCH_FLAG", fetched });
}

export function clearAddQuick() {
  return (dispatch) => dispatch({ type: "CONTACTS/ADD_QUICK.CLEAR" });
}

export function batchEditTags(payload) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "CONTACTS/BATCH_EDIT_TAGS",
      `contacts/batch-edit-tags.json`,
      payload
    );
}

export function clearBatchEditTagsStatus() {
  return (dispatch) => dispatch({ type: "CONTACTS/BATCH_EDIT_TAGS.CLEAR" });
}

export function clearArchiveContactPathname() {
  return (dispatch) =>
    dispatch({ type: "CONTACTS/ARCHIVE_CONTACTS_PATHNAME.CLEAR" });
}

export function clearUnarchiveContactPathname() {
  return (dispatch) =>
    dispatch({ type: "CONTACTS/UNARCHIVE_CONTACTS_PATHNAME.CLEAR" });
}
