import React, { useCallback, useMemo } from "react";
import CustomModal from "../CustomModal";
import { useIsAdminOrOwner } from "../../../utils/hooks/useIsAdminOrOwner";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import AccountIssueBody from "./AccountIssueBody";
import AccountIssueButtons from "./AccountIssueButtons";
import PropTypes from "prop-types";
import { useAccountIssueInfo } from "../../../utils/hooks/useAccountIssueInfo";
import { IssueKeyEnums } from "../../../utils/enums/accountEnums";
import { isAccountIssueModalIssueKey } from "../../../utils/accountHelpers";

const AccountIssueModal = (props) => {
  const { closeModal, withButtons } = props;

  const {
    companies: { currentCompany },
    closeAccountIssueBar,
  } = useCompaniesStore();

  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);

  const { url, issueKey, billingPackage, billingIssueDays, message } =
    useAccountIssueInfo() || {};

  const displayMessage = useMemo(() => {
    let text = message;
    if (issueKey === IssueKeyEnums.NoCredits) {
      let extraText = "";

      if (isAdminOrOwner) {
        extraText += " You can either:";
      } else {
        extraText += " Please contact your admin to assign you more credits.";
      }
      text = (
        <span>
          You cannot send messages because you are out of credits.
          <br />
          {extraText}
        </span>
      );
    }
    return text;
  }, [message, issueKey, isAdminOrOwner]);

  const modalProps = useMemo(() => {
    let currentModalProps = {
      noHeader: true,
      noFooter: true,
      dialogClassName: "modal-dialog-centered",
      className: `locked-info-modal ${issueKey}`,
    };

    if (issueKey === IssueKeyEnums.Medium) {
      currentModalProps.closeOnClick = true;
    } else if (issueKey === IssueKeyEnums.NoCredits) {
      currentModalProps.noHeader = false;
    }
    return currentModalProps;
  }, [issueKey]);

  const onClose = useCallback(() => {
    closeAccountIssueBar(currentCompany.id, issueKey);
    closeModal();
  }, [closeModal, currentCompany.id, issueKey, closeAccountIssueBar]);

  if (!isAccountIssueModalIssueKey(issueKey)) return null;
  return (
    <CustomModal {...props} {...modalProps}>
      <AccountIssueBody
        issueKey={issueKey}
        billingIssueDays={billingIssueDays}
        billingPackage={billingPackage}
        message={displayMessage}
      />
      <AccountIssueButtons
        issueKey={issueKey}
        url={url}
        onClose={onClose}
        withButtons={withButtons}
      />
    </CustomModal>
  );
};

AccountIssueModal.propTypes = {
  withButtons: PropTypes.bool,
};
AccountIssueModal.defaultProps = {
  withButtons: true,
};

export default AccountIssueModal;
