import React from "react";
import PropTypes from "prop-types";
//new icons
import { ReactComponent as CloseSvg } from "../../../assets/img/icons-new/general/close/close-gray.svg";
import reactDom from "react-dom";

const ModalWrapper = (props) => {
  const {
    show,
    shakeModal,
    closeModal,
    id,
    className,
    children,
    classNameCloseIcon,
    hasCloseBtn,
    dialogClassNaame,
    withFade,
  } = props;

  return reactDom.createPortal(
    <div
      className={`modal ${withFade ? "fade" : ""} inbox-modal ${className}  ${
        show ? " show" : ""
      }`}
      style={show ? { display: "block" } : {}}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
    >
      <div
        className={`modal-backdrop fade ${show ? " show" : ""}`}
        onClick={shakeModal}
      />
      <div
        className={`modal-dialog ${dialogClassNaame} modal-md`}
        style={{ zIndex: 100 }}
      >
        <div className="modal-content themed-modal">
          {hasCloseBtn && (
            <button
              type="button"
              className={`close-modal-icon ${classNameCloseIcon}`}
              aria-label="Close"
              onClick={closeModal}
            >
              <CloseSvg />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("themed-root")
  );
};

ModalWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  shakeModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classNameCloseIcon: PropTypes.string,
  hasCloseBtn: PropTypes.bool,
  withFade: PropTypes.bool,
  dialogClassNaame: PropTypes.string,
};

ModalWrapper.defaultProps = {
  className: "",
  footer: null,
  classNameCloseIcon: "",
  hasCloseBtn: true,
  dialogClassNaame: "modal-dialog-centered",
  withFade: true,
};

export default ModalWrapper;
