import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalWrapper from "../components/modals/Helpers/ModalWrapper";
import { ReactComponent as AccessDeniedIcon } from "../assets/img/access_denied_icon.svg";
import { ReactComponent as LeftArrow } from "../assets/img/icons-new/general/arrow/left-arrow-white.svg";
import { history } from "../store";

const AccessDeniedPage = () => {
  const location = useLocation();

  const goToPreviousPage = useCallback(() => {
    if (location?.state?.backRoute) {
      history.push(location.state.backRoute);
    } else {
      history.goBack();
    }
  }, [location]);

  return (
    <ModalWrapper
      show={true}
      classNameCloseIcon="close-access-denied-modal-icon"
      className="access-denied-wrapper"
    >
      <ModalBody className="access-denied-container">
        <AccessDeniedIcon />
        <h2>403 Access Denied</h2>
        <div className="access-denied-text">
          Uh oh, seems like you do not have the permission to access the
          requested page! Please refer to the administrator for more
          information.
        </div>
        <button
          className="btn btn-primary go-back-button"
          onClick={goToPreviousPage}
        >
          <LeftArrow className="pb-1 pr-1" />
          Go Back
        </button>
      </ModalBody>
    </ModalWrapper>
  );
};

export default AccessDeniedPage;
