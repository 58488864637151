import { resetFetchThreadsRequests } from "../actions/threads";
import {
  addEditScheduledMessage,
  filterGroupAndContactThreads,
  hasScheduledMessages,
} from "../helpers";
import { history } from "../store";
import { CONTACT, GROUP } from "../utils/constants";
import { isNotAnEmptyArray } from "../utils/settingsHelpers";
import {
  getArchiveAndNonArchivedContactsFromSearchResults,
  getBlockedContactsFromSearchResults,
} from "../utils/threadsHelpers";

const softDeleteThreadsMapper = (
  payloadContactIds,
  payloadGroupIds,
  actionName
) => {
  return (thread) => {
    if (
      payloadContactIds?.some(
        (payloadId) =>
          thread.item_key.substr(0, 7) === CONTACT &&
          Number(payloadId) === Number(thread.contacts_ids[0])
      ) ||
      payloadGroupIds.some(
        (payloadId) =>
          thread.item_key.substr(0, 5) === GROUP &&
          Number(payloadId) === Number(thread.group.id)
      )
    ) {
      return { ...thread, doNotShow: true, actionName };
    } else return thread;
  };
};

const restoreDeletedThreadsMapper = (actionName) => {
  return (thread) => {
    if (thread.doNotShow === true && thread.actionName) {
      return { ...thread, doNotShow: false, actionName: undefined };
    } else {
      return thread;
    }
  };
};

const deleteRestoreBasedOnActionVoice = (
  state,
  actionContactIds,
  actionGroupIds,
  isUndo,
  actionName
) => {
  let newVoiceThreads = state.voiceThreads;
  let newVoiceThreadsFiltered = state.voiceThreadsFiltered;
  if (state.voiceThreads && isUndo && state.filter === null) {
    newVoiceThreads = state.voiceThreads.map(
      restoreDeletedThreadsMapper(actionName)
    );
  } else if (state.voiceThreadsFiltered && isUndo && state.filter !== "") {
    newVoiceThreadsFiltered = state.voiceThreadsFiltered.map(
      restoreDeletedThreadsMapper(actionName)
    );
  } else if (state.voiceThreads && state.filter === null) {
    newVoiceThreads = state.voiceThreads.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  } else if (state.voiceThreads && state.filter !== "") {
    newVoiceThreadsFiltered = state.voiceThreadsFiltered.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  }

  return {
    ...state,
    voiceThreads: newVoiceThreads,
    voiceThreadsFiltered: newVoiceThreadsFiltered,
  };
};

const deleteRestoreBasedOnActionMessage = (
  state,
  actionContactIds,
  actionGroupIds,
  isUndo,
  actionName
) => {
  let newThreadsFiltered = state.threadsFiltered; // searched threads
  let newThreads = state.threads; // no filter
  let newUnread = state.unreads;
  let newUndelivereds = state.undelivereds;
  let newThreadsSpecial = state.threadsSpecial; // filter

  if (state.threads && isUndo && state.filter == null) {
    newThreads = state.threads.map(restoreDeletedThreadsMapper(actionName));
  } else if (state.unreads && isUndo && state.filter === "unread") {
    newUnread = state.unreads.map(restoreDeletedThreadsMapper(actionName));
  } else if (state.undelivereds && isUndo && state.filter === "undelivered") {
    newUndelivereds = state.undelivereds.map(
      restoreDeletedThreadsMapper(actionName)
    );
  } else if (
    state.threadsFiltered &&
    isUndo &&
    state.threadsFilteredStatus === "success"
  ) {
    newThreadsFiltered = state.threadsFiltered.map(
      restoreDeletedThreadsMapper(actionName)
    );
  } else if (state.threadsSpecial && isUndo && state.filter != null) {
    newThreadsSpecial = state.threadsSpecial.map(
      restoreDeletedThreadsMapper(actionName)
    );
  } else if (state.threads && state.filter == null) {
    newThreads = state.threads.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  } else if (state.unreads && state.filter === "unread") {
    newUnread = state.unreads.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  } else if (state.undelivereds && state.filter === "undelivered") {
    newUndelivereds = state.undelivereds.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  } else if (
    state.threadsFiltered &&
    state.threadsFilteredStatus === "success"
  ) {
    newThreadsFiltered = state.threadsFiltered.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  } else if (state.threadsSpecial && state.filter != null) {
    newThreadsSpecial = state.threadsSpecial.map(
      softDeleteThreadsMapper(actionContactIds, actionGroupIds, actionName)
    );
  }

  return {
    ...state,
    threads: newThreads,
    unreads: newUnread,
    undelivereds: newUndelivereds,
    threadsSpecial: newThreadsSpecial,
    threadsFiltered: newThreadsFiltered,
  };
};

const deleteRestoreBasedOnAction = (
  state,
  actionContactIds,
  actionGroupIds,
  isUndo,
  actionName
) => {
  const matcher = history.location.pathname.match(
    /hub\/(\w+)(\/filter\/([\w-]+))?/
  );

  if (matcher && matcher[1] === "messages") {
    return deleteRestoreBasedOnActionMessage(
      state,
      actionContactIds,
      actionGroupIds,
      isUndo,
      actionName
    );
  } else if (matcher && matcher[1] === "calls") {
    return deleteRestoreBasedOnActionVoice(
      state,
      actionContactIds,
      actionGroupIds,
      isUndo,
      actionName
    );
  }

  return state;
};

export const getFirstIndexThreadItemNotScheduled = (threadItems) => {
  for (let i = threadItems?.length - 1; i >= 0; i--) {
    if (threadItems[i].schedule_message) {
      continue;
    } else {
      return i + 1;
    }
  }
};

const handleShowFilterItems = (state, action, doNotShow, filterName) => {
  const matcher = history.location.pathname.match(
    /hub\/(\w+)(\/filter\/([\w-]+))?/
  );

  if (
    state.threadsSpecial &&
    matcher &&
    matcher.length === 4 &&
    matcher[1] === "messages" &&
    matcher[3] === filterName
  ) {
    state.threadsSpecial = state.threadsSpecial.map((thread) => {
      if (
        action?.payload?.contactIds?.some(
          (payloadId) =>
            thread.item_key.substr(0, 7) === CONTACT &&
            Number(payloadId) === Number(thread.contacts_ids[0])
        ) ||
        action?.groupIds?.some(
          (payloadId) =>
            thread.item_key.substr(0, 5) === GROUP &&
            Number(payloadId) === Number(thread.group.id)
        )
      ) {
        return { ...thread, doNotShow: doNotShow };
      } else {
        return thread;
      }
    });
  }

  if (
    state.voiceThreadsFiltered &&
    matcher &&
    matcher.length === 4 &&
    matcher[1] === "calls" &&
    matcher[3] === filterName
  ) {
    state.voiceThreadsFiltered = state.voiceThreadsFiltered.map((thread) => {
      if (
        action?.payload?.contactIds?.some(
          (payloadId) =>
            thread.item_key.substr(0, 7) === CONTACT &&
            Number(payloadId) === Number(thread.contacts_ids[0])
        ) ||
        action?.groupIds?.some(
          (payloadId) =>
            thread.item_key.substr(0, 5) === GROUP &&
            Number(payloadId) === Number(thread.group.id)
        )
      ) {
        return { ...thread, doNotShow: doNotShow };
      } else {
        return thread;
      }
    });
  }

  return {
    ...state,
    threadsSpecial: state.threadsSpecial ? [...state.threadsSpecial] : null,
    voiceThreadsFiltered: state.voiceThreadsFiltered
      ? [...state.voiceThreadsFiltered]
      : null,
  };
};

const insertNewLogEvent = (threadItems, eventLog) => {
  const indexFirstNoScheduled =
    getFirstIndexThreadItemNotScheduled(threadItems);
  for (let i = 0; i < eventLog?.length; i++) {
    const threadItem = eventLog[i];
    threadItems.splice(indexFirstNoScheduled, 0, threadItem);
  }
  return threadItems;
};

const getVoiceCount = (count, countInState) => {
  if (count || count === 0) {
    return count;
  }
  return countInState;
};

const initialState = {
  filter: null,
  voiceFilter: null,
  scheduledVisible: false,
  scrollDownChatMessages: false,
  initialLoad: true,
  selectedMessagesTab: null,

  // Fetch threads list
  threads: null,
  threadsStatus: null,
  threadsLoadingMoreStatus: null,
  threadsError: null,
  threadsPage: null,
  threadsLoadedAll: null,
  threadsCompanyId: null,
  threadsUpdated: 0,
  threadsOutdated: false,

  // Fetch threads filtered
  threadsFiltered: null,
  threadsFilteredStatus: null,
  threadsFilteredLoadingMoreStatus: null,
  threadsFilteredPage: null,
  threadsFilteredLoadedAll: null,
  threadsFilteredUpdated: 0,
  threadsFilteredTotal: null,

  // Fetch threads list
  threadsSpecial: null,
  threadsSpecialStatus: null,
  threadsSpecialLoadingMoreStatus: null,
  threadsSpecialPage: null,
  threadsSpecialLoadedAll: null,
  threadsSpecialUpdated: 0,

  // Fetch voice threads
  voiceThreads: null,
  voiceThreadsStatus: null,
  voiceThreadsLoadingMoreStatus: null,
  voiceThreadsPage: null,
  voiceThreadsLoadedAll: null,
  voiceThreadsUpdated: null,

  // Fetch voice threads filtered
  voiceThreadsFiltered: null,
  voiceThreadsFilteredStatus: null,
  voiceThreadsFilteredLoadingMoreStatus: null,
  voiceThreadsFilteredPage: null,
  voiceThreadsFilteredLoadedAll: null,
  voiceThreadsFilteredUpdated: 0,
  voiceThreadsFilteredTotal: null,
  voiceThreadsOutdated: false,

  // Fetch thread items
  threadItems: null,
  threadItemsStatus: null,
  threadItemsStatusMore: "",
  threadItemsError: null,
  threadItemsLoadedAll: null,
  threadItemsCompanyId: null,
  threadItemsThreadType: null,
  threadItemsInterlocutorId: null,
  threadItemsItemKey: null,
  threadItemsOutdated: null,
  threadItemsNewCount: 0,
  threadItemsUpdated: 0,
  threadItemsUpdatedDirection: null,
  threadItemsTypeFilter: null,

  // Fetch voice thread items
  voiceThreadItems: null,
  voiceThreadItemsStatus: null,
  voiceThreadItemsLoadedAll: null,
  voiceThreadItemsThreadType: null,
  voiceThreadItemsInterlocutorId: null,
  voiceThreadItemsItemKey: null,
  voiceThreadItemsNewCount: 0,
  voiceThreadItemsUpdated: 0,
  voiceThreadItemsUpdatedDirection: null,

  // Search receivers
  receivers: null,
  receiversStatus: null,
  receiversPhoneCountryCode: null,
  receiversQuery: null,
  notAllowedContacts: null,
  nonArchivedReceivers: null,
  archivedReceivers: [],
  blockedRecievers: [],

  // Unread
  unreads: null,
  unreadsStatus: null,
  unreadsLoadingMoreStatus: null,
  unreadsLoadedAll: null,
  unreadsTotal: null,
  unreadsPage: null,
  unreadType: null,
  unreadTextStatus: null,

  // Undelivered
  undelivereds: null,
  undeliveredsStatus: null,
  undeliveredsLoadingMoreStatus: null,
  undeliveredsTotal: null,

  // Counts
  countsStatus: null,
  undeliveredReadTotal: null,
  receivedTotal: null,
  sentTotal: null,
  sentContacts: null,
  sentGroups: null,
  sentReprocessed: null,
  campaignsTotal: null,
  campaignsAmTotal: null,
  campaignsAutoRepliesTotal: null,
  campaignsBirthdaysTotal: null,
  campaignsContestsTotal: null,
  campaignsDcTotal: null,
  campaignsPollsTotal: null,
  favoritesTotal: null,
  scheduledTotal: null,
  scheduledAllNums: null,
  scheduledContactsTotal: null,
  scheduledContactsAllNums: null,
  scheduledGroupsTotal: null,
  scheduledGroupsAllNums: null,
  scheduledGroupsUnnamed: null,
  scheduledGroupsUnnamedAllNums: null,
  scheduledGroupsNamed: null,
  scheduledGroupsNamedAllNums: null,
  closedTotal: null,
  optIn: null,
  optInTexts: null,
  optInVoice: null,
  mcTotal: null,
  blockedTotal: null,
  optInTextsUnsubscribed: null,
  optInVoiceUnsubscribed: null,
  optedOutTexts: null,
  optedOutTextsLikely: null,
  doNotCallVoice: null,
  mcSubscribed: null,
  mcUnSubscribed: null,
  mcCleaned: null,
  mcNonSubscribed: null,
  named: null,
  unnamed: null,

  // Mark as read
  markAsReadStatus: null,
  markAsReadLogId: null,
  markAllAsReadStatus: null,
  markAllUndeliveredAsReadStatus: null,
  unreadIgnored: false,

  // Mark as unread
  markAsUnreadStatus: null,
  markAsUnreadLogId: null,

  // Items status
  updateItemsStatusStatus: null,
  updateItemsScheduledStatus: null,
  toggleSelected: 0,
  selectionCleared: false,
  toggleContactForm: false,
  sideContactId: null,
  sideSubContactId: null,
  headerInProgress: false,

  // Info group status
  toggleGroupForm: false,
  sideGroupId: null,
  sideSubGroupId: null,
  toggleEditGroup: false,

  // close
  closeThreadsStatus: "",
  closeNeedRouting: false,
  closingIds: [],
  closedSuccessThreads: [],
  closeSnackbarData: {},

  //reopen
  reopenThreadsStatus: "",
  reopenNeedRouting: false,
  reopeningIds: [],
  reopenedSuccessThreads: [],
  reopenSnackbarData: {},

  // mark as read
  markAsReadThreadsStatus: "",
  markAsReadSnackbarData: {},

  // mark as unread
  markAsUnreadThreadsStatus: "",
  markAsUnReadSnackbarData: {},

  // Clear undelivered and reprocessed label
  clearReprocessedUndeliveredStatus: "",
  clearReprocessedUndeliveredSnackbarData: {},

  // other snackbar
  sendMessageSnackbarData: {},
  callBroadcastSnackbarData: {},

  voiceCountsStatus: null,
  voiceCounts: {
    closedTotal: undefined,
  },
  draftThreadType: null,

  markAsReadItemId: "",
  //draft length
  draftCount: null,

  //closed threads state
  closedThreads: [],
  closedThreadsIds: {
    groups: [],
    contacts: [],
  },

  //thread search state
  searchedQuery: null,
  showSearch: null,
  populateQuery: null,
  threadSearchFilter: null,
  threadSearchCondition: null,
  filteredSearchThreads: {
    messages: {
      data: [],
      archived: [],
    },
    groups: {
      data: [],
      archived: [],
    },
    people: {
      data: [],
      archived: [],
    },
    voice: {
      data: [],
      archived: [],
    },
  },

  //Messages search state
  highlightedTerms: [],
  currentActiveSearchTerm: null,

  //Resend Message state
  resendMessageStatus: null,
  resendFailedErrorMessage: "",

  //send message state
  newMessageLogId: null,

  //initial voice threads
  initialVoiceFetch: false,
  //initial messages threads
  initialMessagesFetch: false,

  // ADD VCARDS
  addVcardsStatus: null,
  vCards: [],
  vCardsData: [],

  // VOICE ONE TO ONE CALLING ID
  voiceOneToOneCallingId: null,

  // CALLING FROM NUMBER
  autoCallingFromNumber: null,
};

const isDeletedItem = (item, logId, groupSmsBlastId, scheduleMessageId) => {
  if (logId) {
    if (typeof item.log !== "undefined" && item.log && item.log.id === logId) {
      return true;
    }
  } else if (scheduleMessageId) {
    if (
      typeof item.schedule_message !== "undefined" &&
      item.schedule_message &&
      item.schedule_message.id === scheduleMessageId
    ) {
      return true;
    }
  } else if (groupSmsBlastId) {
    if (
      typeof item.group_sms_blast !== "undefined" &&
      item.group_sms_blast &&
      item.group_sms_blast.id === groupSmsBlastId
    ) {
      return true;
    }
  }
  return false;
};

const updateStats = (isRead, state, action, withoutNewArray = false) => {
  const stateKeys = [
    "threads",
    "threadsFiltered",
    "threadItems",
    "voiceThreads",
    "voiceThreadsFiltered",
    "voiceThreadItems",
    "unreads",
    "threadsSpecial",
  ];

  let updatedStats = {
    threads: null,
    threadsFiltered: null,
    threadItems: null,
    voiceThreads: null,
    voiceThreadsFiltered: null,
    voiceThreadItems: null,
    unreads: null,
    threadsSpecial: null,
  };

  stateKeys.forEach((key) => {
    let threadTypeCheck = true;
    const keyToCheckForContact = ["threadItems", "voiceThreadItems"];
    if (keyToCheckForContact.includes(key)) {
      threadTypeCheck = state[`${key}ThreadType`] === CONTACT;
    }

    let stateKeyValue = state[key] || [];
    let keyUpdated = state[`${key}Updated`];

    if (threadTypeCheck && action.payload.logsIds.length && key) {
      for (let i = 0; i < stateKeyValue.length; i++) {
        if (
          stateKeyValue[i].log &&
          action.payload.logsIds.indexOf(stateKeyValue[i].log.id) !== -1
        ) {
          stateKeyValue[i].log.read = isRead ? true : false;
          stateKeyValue[i].log.read_failed = isRead ? true : false;
          keyUpdated = state[`${key}Updated`] + 1;
        }
        if (
          key === "threads" &&
          !!stateKeyValue[i].log &&
          (stateKeyValue[i].log.id !== action.payload.logsIds[0] ||
            stateKeyValue[i].log.read) &&
          !!action.payload.thread &&
          !!action.payload.thread[stateKeyValue[i].item_key]
        ) {
          stateKeyValue[i].log =
            action.payload.thread[stateKeyValue[i].item_key].log;
        }

        if (
          action.payload.threadCounts &&
          typeof action.payload.threadCounts[stateKeyValue[i].item_key] !==
            "undefined"
        ) {
          stateKeyValue[i]["undelivered_count"] =
            action.payload.threadCounts[
              stateKeyValue[i].item_key
            ].undelivered_count;
          stateKeyValue[i]["unread_count"] =
            action.payload.threadCounts[stateKeyValue[i].item_key].unread_count;
          stateKeyValue[i]["reprocessed_unread_count"] =
            action.payload.threadCounts[
              stateKeyValue[i].item_key
            ].reprocessed_unread_count;
          keyUpdated = state[`${key}Updated`] + 1;
        }

        // Update undelivered count for threads when user seen the undelivered unseen message
        if (
          ["threadsSpecial", "threads", "threadsFiltered"].includes(key) &&
          stateKeyValue[i] &&
          stateKeyValue[i]?.undelivered_count > 0 &&
          !!action.payload.thread &&
          !!action.payload.thread[stateKeyValue[i].item_key]
        ) {
          stateKeyValue[i].undelivered_count =
            action.payload.thread[stateKeyValue[i].item_key]?.undelivered_count;
        }
      }
    }

    updatedStats = {
      ...updatedStats,
      [key]: withoutNewArray ? stateKeyValue : [...stateKeyValue],
      [`${key}Updated`]: keyUpdated,
    };
  });

  return updatedStats;
};

const updateThreadsForClearLabels = (threads = [], data) => {
  threads.forEach((item) => {
    if (
      item.item_key.startsWith(CONTACT) &&
      data.contact_id?.includes(item.contacts_ids[0])
    ) {
      if (data.filter === "undelivered") {
        item.undelivered_count = 0;
      } else if (data.filter === "reprocessed") {
        item.reprocessed_unread_count = 0;
      }
    }
  });
};

export default function reducer(state = initialState, action) {
  let threads = null;
  let threadsUpdated = null;
  let threadsFiltered = null;
  let threadsFilteredUpdated = null;
  let threadItems = null;
  let threadItemsOutdated = null;
  let threadItemsUpdated = null;
  let voiceThreads = null;
  let voiceThreadItems = null;
  let groupSmsBlastId = null;
  switch (action.type) {
    case "THREADS/SELECTING_ITEMS":
      return {
        ...state,
        toggleSelected: action.number,
      };

    case "THREADS/CLEAR_SELECTION":
      return {
        ...state,
        selectionCleared: action.selection,
        toggleSelected: 0,
      };

    case "THREADS/TOGGLE_CONTACTFORM":
      return {
        ...state,
        toggleContactForm: action.selection,
        sideContactId: action.memberId,
        sideSubContactId: null,
      };

    case "THREADS/TOGGLE_EDITGROUP":
      return {
        ...state,
        toggleEditGroup: action.selection,
        sideGroupId: action.groupId,
        sideSubGroupId: null,
      };

    case "THREADS/ADD_SIDEFORM_SUBCONTACT":
      return {
        ...state,
        sideSubContactId: action.memberId,
      };

    case "THREADS/FILTER":
      return {
        ...state,
        filter: action.filter === "all" ? null : action.filter, // temporally fix, don't know here set to all is done
      };

    case "THREADS/VOICE_FILTER":
      return {
        ...state,
        voiceFilter: action.filter === "all" ? null : action.filter,
      };

    case "FETCH_THREADS.INITIAL_FETCH_FLAG":
      return {
        ...state,
        initialMessagesFetch: action.fetched,
      };

    // Fetch threads list
    case "FETCH_THREADS":
      return {
        ...state,
        threadsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        threadsStatus: "loading",
        threadsError: null,
        threadsOutdated: false,
      };
    case "FETCH_THREADS_SUCCESS":
      threads = action.payload.threads;
      if (state.threads && state.threadsPage + 1 === action.page) {
        let existsKeys = [];
        for (let i = 0; i < threads.length; i++) {
          existsKeys.push(threads[i].item_key);
        }
        let prevThreads = [];
        for (let i = 0; i < state.threads.length; i++) {
          if (existsKeys.indexOf(state.threads[i].item_key) === -1) {
            prevThreads.push(state.threads[i]);
          }
        }
        threads = prevThreads.concat(threads);
      }
      return {
        ...state,
        threads: threads,
        threadsStatus: "success",
        threadsLoadingMoreStatus: action.page > 1 ? "success" : null,
        threadsError: null,
        threadsPage: Number(action.page),
        threadsLoadedAll: action.payload.threads?.length === 0,
        threadsCompanyId: action.companyId,
        threadsUpdated: state.threadsUpdated + 1,
        threadsTotal: action.payload.total,
      };
    case "FETCH_THREADS_ERROR":
      return {
        ...state,
        threads: null,
        threadsStatus: "error",
        threadsLoadingMoreStatus: action.page > 1 ? "error" : null,
        threadsError: action.payload,
        threadsPage: null,
        threadsCompanyId: null,
        threadsTotal: null,
      };
    case "THREADS/FETCH.OUTDATED":
      return {
        ...state,
        threadsOutdated: true,
      };

    //Fetch archived interlocutors
    case "THREADS/FETCH_ARCHIVED_INTERLOCUTORS":
      const filterData = filterGroupAndContactThreads(action.payload.threads);
      if (
        state.threadsFiltered &&
        state.threadsFilteredPage + 1 === action.page
      ) {
        state.filteredSearchThreads.people.archived = [
          ...state.filteredSearchThreads.people.archived,
          ...filterData.people,
        ];
        state.filteredSearchThreads.groups.archived = [
          ...state.filteredSearchThreads.groups.archived,
          ...filterData.groups,
        ];
      } else {
        state.filteredSearchThreads.people.archived = filterData.people;
        state.filteredSearchThreads.groups.archived = filterData.groups;
      }

      if (action.messageType === "all_voice") {
        state.filteredSearchThreads.voice.archived = action.payload.threads;
      } else {
        state.filteredSearchThreads.messages.archived = action.payload.threads;
      }
      return {
        ...state,
        filteredSearchThreads: state.filteredSearchThreads,
      };

    case "THREADS/FETCH_ARCHIVED_INTERLOCUTORS.ERROR":
      return {
        ...state,
        threadsFilteredStatus: "error",
      };

    // Fetch threads filtered
    case "THREADS/FILTERED":
      const initialObj = {
        messages: {
          data: [],
          archived: [],
        },
        groups: {
          data: [],
          archived: [],
        },
        people: {
          data: [],
          archived: [],
        },
        voice: {
          data: [],
          archived: [],
        },
      };
      return {
        ...state,
        threadsFilteredTotal: null,
        threadsFilteredStatus: "loading",
        threadsFilteredLoadingMoreStatus: action.page > 1 ? "loading" : null,
        filteredSearchThreads:
          action.page > 1 ? state.filteredSearchThreads : initialObj,
      };
    case "THREADS/FILTERED.SUCCESS":
      let filterClosedThreadsIds = {
        groups: [],
        contacts: [],
      };
      threads = [];

      if (action.filter === "closed") {
        const filterGroupThreads = action.payload.threads
          ?.filter((item) => item.item_key?.startsWith(GROUP))
          ?.map((el) => el?.group?.id);
        const filterContactThreads = action.payload.threads
          ?.filter((item) => item.item_key?.startsWith(CONTACT))
          ?.map((el) => el?.contacts_ids[0]);
        filterClosedThreadsIds = {
          groups: filterGroupThreads,
          contacts: filterContactThreads,
        };
      }
      const seprateGroupContact = filterGroupAndContactThreads(
        action.payload.threads
      );
      if (
        state.threadsFiltered &&
        state.threadsFilteredPage + 1 === action.page
      ) {
        if (action.messageType === "all_voice") {
          state.filteredSearchThreads.voice.data = [
            ...state.filteredSearchThreads.voice.data,
            ...action.payload.threads,
          ];
        } else {
          state.filteredSearchThreads.messages.data = [
            ...state.filteredSearchThreads.messages.data,
            ...action.payload.threads,
          ];
        }
        state.filteredSearchThreads.people.data = [
          ...state.filteredSearchThreads.people.data,
          ...seprateGroupContact.people,
        ];
        state.filteredSearchThreads.groups.data = [
          ...state.filteredSearchThreads.groups.data,
          ...seprateGroupContact.groups,
        ];
        threads = state.threadsFiltered.concat(action.payload.threads);
      } else {
        if (action.messageType === "all_voice") {
          state.filteredSearchThreads.voice.data = action.payload.threads;
        } else {
          state.filteredSearchThreads.messages.data = action.payload.threads;
        }
        state.filteredSearchThreads.people.data = seprateGroupContact.people;
        state.filteredSearchThreads.groups.data = seprateGroupContact.groups;
        threads = action.payload.threads;
      }
      return {
        ...state,
        threadsFiltered: threads,
        threadsFilteredStatus: "success",
        threadsFilteredLoadingMoreStatus: action.page > 1 ? "success" : null,
        threadsFilteredPage: action.page,
        threadsFilteredLoadedAll: action.payload.threads?.length === 0,
        threadsFilteredUpdated: 0,
        filteredSearchThreads: state.filteredSearchThreads,
        closedThreads: action.payload.threads,
        closedThreadsIds: filterClosedThreadsIds,
        searchedQuery: action.query,
        threadsFilteredTotal: action.payload.total,
      };
    case "THREADS/FILTERED.ERROR":
      return {
        ...state,
        threadsFiltered: null,
        threadsFilteredStatus: "error",
        threadsFilteredLoadingMoreStatus: action.page > 1 ? "error" : null,
        threadsFilteredPage: null,
        threadsFilteredLoadedAll: null,
        threadsFilteredUpdated: 0,
        threadsFilteredTotal: null,
      };

    // Fetch closed threads
    case "THREADS/FETCH_CLOSED_THREADS.SUCCESS":
      const filterGroupThreads = action.payload.threads
        ?.filter((item) => item.item_key?.startsWith(GROUP))
        ?.map((el) => el?.group?.id);
      const filterContactThreads = action.payload.threads
        ?.filter((item) => item.item_key?.startsWith(CONTACT))
        ?.map((el) => el?.contacts_ids[0]);
      const auxFilterClosedThreadsIds = {
        groups: filterGroupThreads,
        contacts: filterContactThreads,
      };
      return {
        ...state,
        closedThreads: action.payload.threads,
        closedThreadsIds: auxFilterClosedThreadsIds,
      };

    // Fetch threads list
    case "THREADS/SPECIAL":
      return {
        ...state,
        // threadsSpecial: null,
        threadsSpecialStatus: "loading",
        threadsSpecialLoadingMoreStatus: action.page > 1 ? "loading" : null,
      };
    case "THREADS/SPECIAL.SUCCESS":
      threads = [];
      if (
        state.threadsSpecial &&
        state.threadsSpecialPage + 1 === action.page
      ) {
        threads = state.threadsSpecial.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        threadsSpecial: threads,
        threadsSpecialStatus: "success",
        threadsSpecialLoadingMoreStatus: action.page > 1 ? "success" : null,
        threadsSpecialPage: action.page,
        threadsSpecialLoadedAll: action.payload.threads?.length === 0,
        threadsSpecialTotal: Number(action.payload.total),
        draftCount:
          action.filter === "drafts"
            ? action.payload.threads?.length
            : state.draftCount,
        draftThreadType: null,
      };
    case "THREADS/SPECIAL.ERROR":
      return {
        ...state,
        threadsSpecial: null,
        threadsSpecialStatus: "error",
        threadsSpecialLoadingMoreStatus: action.page > 1 ? "error" : null,
        threadsSpecialPage: null,
        threadsSpecialTotal: null,
      };

    // Fetch threads list
    case "THREADS/VOICE_THREADS":
      return {
        ...state,
        voiceThreadsStatus: "loading",
        voiceThreadsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        voiceThreadsOutdated: false,
      };
    case "THREADS/VOICE_THREADS.SUCCESS":
      threads = [];
      if (state.voiceThreads && state.voiceThreadsPage + 1 === action.page) {
        threads = state.voiceThreads.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        voiceThreads: threads,
        voiceThreadsStatus: "success",
        voiceThreadsLoadingMoreStatus: action.page > 1 ? "success" : null,
        voiceThreadsPage: Number(action.page),
        voiceThreadsLoadedAll:
          Object.values(action.payload.threads).length === 0,
        voiceThreadsTotal: Number(action.payload.total),
      };
    case "THREADS/VOICE_THREADS.ERROR":
      return {
        ...state,
        voiceThreads: null,
        voiceThreadsStatus: "error",
        voiceThreadsLoadingMoreStatus: action.page > 1 ? "error" : null,
        voiceThreadsPage: null,
        voiceThreadsTotal: null,
      };
    case "THREADS/VOICE_THREADS.OUTDATED":
      return {
        ...state,
        voiceThreadsOutdated: true,
      };

    // Fetch temporary voice thread
    case "THREADS/TEMP_VOICE.SUCCESS":
      threads = action.payload.threads;
      let newVoiceThreadsKeys = [];
      for (let i = 0; i < threads.length; i++) {
        threads[i]["__temp"] = true;
        newVoiceThreadsKeys.push(threads[i].item_key);
      }
      if (state.voiceThreads) {
        for (let i = 0; i < state.voiceThreads.length; i++) {
          if (
            newVoiceThreadsKeys.indexOf(state.voiceThreads[i].item_key) === -1
          ) {
            threads.push(state.voiceThreads[i]);
          }
        }
      }

      return {
        ...state,
        voiceThreads: threads,
      };

    // Fetch single voice thread
    case "THREADS/SINGLE_VOICE.SUCCESS":
      voiceThreads = state.voiceThreads ? [].concat(state.voiceThreads) : [];
      let singleVoiceThread = action.payload.threads[0];
      for (let i = 0; i < voiceThreads.length; i++) {
        if (voiceThreads[i].item_key === singleVoiceThread.item_key) {
          voiceThreads[i] = singleVoiceThread;
          break;
        }
      }

      return {
        ...state,
        voiceThreads,
      };

    case "THREADS/VOICE_FILTERED.INITIAL_FETCH_FLAG":
      return {
        ...state,
        initialVoiceFetch: action.fetched,
      };

    // Fetch voice threads filtered
    case "THREADS/VOICE_FILTERED":
      return {
        ...state,
        voiceThreadsFilteredStatus: "loading",
        voiceThreadsFilteredLoadingMoreStatus:
          action.page > 1 ? "loading" : null,
      };
    case "THREADS/VOICE_FILTERED.SUCCESS":
      threads = [];
      if (
        state.voiceThreadsFiltered &&
        state.voiceThreadsFilteredPage + 1 === action.page
      ) {
        threads = state.voiceThreadsFiltered.concat(action.payload.threads);
      } else {
        threads = action.payload.threads;
      }
      return {
        ...state,
        voiceThreadsFiltered: threads,
        voiceThreadsFilteredStatus: "success",
        voiceThreadsFilteredLoadingMoreStatus:
          action.page > 1 ? "success" : null,
        voiceThreadsFilteredPage: Number(action.page),
        voiceThreadsFilteredLoadedAll: action.payload.threads?.length === 0,
        voiceThreadsFilteredUpdated: 0,
        voiceThreadsFilteredTotal: action.payload.total,
      };
    case "THREADS/VOICE_FILTERED.ERROR":
      return {
        ...state,
        voiceThreadsFiltered: null,
        voiceThreadsFilteredStatus: "error",
        voiceThreadsFilteredLoadingMoreStatus: action.page > 1 ? "error" : null,
        voiceThreadsFilteredPage: null,
        voiceThreadsFilteredLoadedAll: null,
        voiceThreadsFilteredUpdated: 0,
        voiceThreadsFilteredTotal: null,
      };

    // Fetch incoming thread
    case "FETCH_INCOMING_THREAD_SUCCESS":
      threads = action.payload.threads;
      threadItemsOutdated = state.threadItemsOutdated;
      threadsUpdated = state.threadsUpdated;
      if (state.threads) {
        threads = threads.concat(state.threads);
        for (let i = 1; i < threads.length; i++) {
          if (threads[0].item_key === threads[i].item_key) {
            threads.splice(i, 1);
            threadsUpdated = state.threadsUpdated + 1;
            break;
          }
        }
      }

      threadsFiltered = state.threadsFiltered;
      threadsFilteredUpdated = state.threadsFilteredUpdated;
      if (threadsFiltered) {
        for (let i = 0; i < threadsFiltered.length; i++) {
          if (
            action.payload.threads[0].item_key === threadsFiltered[i].item_key
          ) {
            threadsFiltered[i] = action.payload.threads[0];
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
            break;
          }
        }
      }

      threadItems = state.threadItems;
      let threadItemsNewCount = state.threadItemsNewCount;
      if (
        action.companyId === state.threadItemsCompanyId &&
        state.threadItemsItemKey === action.payload.threads[0].item_key
      ) {
        threadItemsOutdated = true;
      }

      return {
        ...state,
        threads,
        threadItemsOutdated,
        threadsUpdated,
        threadsFiltered,
        threadsFilteredUpdated,
        threadItems,
        threadItemsNewCount,
      };

    // Fetch temporary thread
    case "THREADS/TEMP.SUCCESS":
      threads = action.payload.threads;
      let newThreadsKeys = [];
      for (let i = 0; i < threads.length; i++) {
        threads[i]["__temp"] = true;
        newThreadsKeys.push(threads[i].item_key);
      }
      if (state.threads) {
        for (let i = 0; i < state.threads.length; i++) {
          if (newThreadsKeys.indexOf(state.threads[i].item_key) === -1) {
            threads.push(state.threads[i]);
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: state.threadsUpdated + 1,
      };

    // Fetch single thread
    case "THREADS/SINGLE.SUCCESS":
      threads = state.threads ? [].concat(state.threads) : [];
      let singleThread = action.payload.threads[0];
      for (let i = 0; i < threads.length; i++) {
        if (threads[i].item_key === singleThread.item_key) {
          threads[i] = singleThread;
          break;
        }
      }

      return {
        ...state,
        threads,
        threadsUpdated: state.threadsUpdated + 1,
      };

    case "THREADS_RESET_NEW_ITEMS_COUNT":
      return {
        ...state,
        threadItemsNewCount: 0,
      };

    // Fetch thread items
    case "FETCH_THREAD_ITEMS":
      let threadChanged = false;
      if (
        state.threadItemsCompanyId !== action.companyId ||
        state.threadItemsThreadType !== action.threadType ||
        state.threadItemsInterlocutorId !== action.interlocutorId
      ) {
        threadChanged = true;
      }
      return {
        ...state,
        threadItems: threadChanged ? null : state.threadItems,
        threadItemsStatus: "loading",
        threadItemsStatusMore: action.toExcId ? "loading" : "",
        threadItemsError: null,
        threadItemsLoadedAll: false,
        threadItemsCompanyId: action.companyId,
        threadItemsThreadType: action.threadType,
        threadItemsInterlocutorId: action.interlocutorId,
        threadItemsItemKey: action.threadItemKey,
        threadItemsNewCount: threadChanged ? 0 : state.threadItemsNewCount,
        threadsOutdated: false,
      };
    case "FETCH_THREAD_ITEMS_SUCCESS":
      let threadItemsUpdatedDirection = null;
      threadItems = Object.values(action.payload.items);

      // Count new incoming items - only if loading new items
      threadItemsNewCount = state.threadItemsNewCount;
      if (action.fromExcId) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].log &&
            !threadItems[i].schedule_message &&
            threadItems[i].log.route === "inbox" &&
            !threadItems[i].log.read
          ) {
            threadItemsNewCount++;
          }
        }
      }

      // Merge items
      if (state.threadItems && action.toExcId > 0) {
        threadItemsUpdatedDirection = "begin";
        threadItems = threadItems.concat(state.threadItems);
      } else if (state.threadItems && action.fromExcId > 0) {
        threadItemsUpdatedDirection = "end";
        const indexFirstNoScheduled = getFirstIndexThreadItemNotScheduled(
          state.threadItems
        );
        for (let i = 0; i < threadItems?.length; i++) {
          const threadItem = threadItems[i];

          const threadItemExists = state.threadItems.find((item) => {
            if (action.threadType === CONTACT) {
              return item.log?.id === threadItem.log?.id;
            } else if (action.threadType === GROUP) {
              return (
                item.group_sms_blast?.id === threadItem.group_sms_blast?.id
              );
            }
            return null;
          });

          if (threadItemExists) {
            continue;
          }

          state.threadItems.splice(indexFirstNoScheduled + i, 0, threadItem);
        }
        threadItems = state.threadItems;
      }

      return {
        ...state,
        threadItems: [...threadItems],
        threadItemsUpdatedDirection,
        threadItemsStatus: "success",
        threadItemsStatusMore: action.toExcId ? "success" : "",
        threadItemsError: null,
        threadItemsLoadedAll: Object.values(action.payload.items).length === 0,
        threadItemsCompanyId: action.companyId,
        threadItemsThreadType: action.threadType,
        threadItemsInterlocutorId: action.interlocutorId,
        threadItemsOutdated:
          action.fromExcId > 0 ? false : state.threadItemsOutdated,
        threadItemsNewCount,
      };
    case "FETCH_THREAD_ITEMS_ERROR":
      return {
        ...state,
        threadItemsStatus:
          action.payload.message === "FETCH_THREAD_ITEMS_CANCELED"
            ? "loading"
            : "error",
        threadItemsStatusMore: action.toExcId ? "error" : "",
        threadItemsError: null,
        threadItemsLoadedAll: false,
        threadItemsCompanyId: action.companyId,
        threadItemsThreadType: action.threadType,
        threadItemsInterlocutorId: action.interlocutorId,
      };

    case "CLEAR_THREAD_ITEMS_STATUS":
      return {
        ...state,
        threadItemsStatus: null,
      };

    case "FETCH_THREAD_ITEMS.CLEAR":
      return {
        ...state,
        threadItems: null,
        threadItemsStatus: "",
        threadItemsError: null,
        threadItemsLoadedAll: false,
      };

    // Fetch voice thread items
    case "THREAD/VOICE_ITEMS":
      let voiceThreadChanged = false;
      if (
        state.voiceThreadItemsThreadType !== action.threadType ||
        state.voiceThreadItemsInterlocutorId !== action.interlocutorId
      ) {
        voiceThreadChanged = true;
      }
      return {
        ...state,
        voiceThreadItems: voiceThreadChanged ? null : state.voiceThreadItems,
        voiceThreadItemsStatus: "loading",
        voiceThreadItemsLoadedAll: false,
        voiceThreadItemsThreadType: action.threadType,
        voiceThreadItemsInterlocutorId: action.interlocutorId,
        voiceThreadItemsItemKey: action.threadItemKey,
        voiceThreadItemsNewCount: voiceThreadChanged
          ? 0
          : state.voiceThreadItemsNewCount,
      };
    case "THREAD/VOICE_ITEMS.SUCCESS":
      let voiceThreadItemsUpdatedDirection = null;
      threadItems = Object.values(action.payload.items);
      if (state.voiceThreadItems && action.toExcId > 0) {
        voiceThreadItemsUpdatedDirection = "begin";
        threadItems = threadItems.concat(state.voiceThreadItems);
      } else if (state.voiceThreadItems && action.fromExcId > 0) {
        voiceThreadItemsUpdatedDirection = "end";
        threadItems = state.voiceThreadItems.concat(threadItems);
      }

      return {
        ...state,
        voiceThreadItems: threadItems,
        voiceThreadItemsStatus: "success",
        voiceThreadItemsLoadedAll:
          Object.values(action.payload.items).length === 0,
        voiceThreadItemsThreadType: action.threadType,
        voiceThreadItemsInterlocutorId: action.interlocutorId,
        voiceThreadItemsUpdatedDirection,
      };
    case "THREAD/VOICE_ITEMS.ERROR":
      return {
        ...state,
        voiceThreadItems: null,
        voiceThreadItemsStatus: "error",
        voiceThreadItemsLoadedAll: false,
        voiceThreadItemsThreadType: action.threadType,
        voiceThreadItemsInterlocutorId: action.interlocutorId,
      };

    // Search receivers
    case "THREADS_SEARCH_RECEIVERS":
      return {
        ...state,
        receiversStatus: "loading",
      };
    case "THREADS_SEARCH_RECEIVERS_SUCCESS":
      const { archivedContacts, nonArchivedContacts } =
        getArchiveAndNonArchivedContactsFromSearchResults(
          action.payload?.contacts,
          action.payload?.items,
          action.payload?.blocked_contact_ids
        );
      const blockedContacts = getBlockedContactsFromSearchResults(
        action.payload?.blocked_contact_ids
      );

      return {
        ...state,
        receivers: action.payload.items,
        receiversStatus: "success",
        receiversPhoneCountryId: action.phoneCountryId,
        receiversQuery: action.query,
        notAllowedContacts: action.payload.not_allowed_contacts,
        nonArchivedReceivers: nonArchivedContacts,
        archivedReceivers: archivedContacts,
        blockedRecievers: blockedContacts,
      };
    case "THREADS_SEARCH_RECEIVERS_ERROR":
      return {
        ...state,
        receivers: null,
        receiversStatus: "error",
        receiversPhoneCountryId: action.phoneCountryId,
        receiversQuery: action.query,
      };
    case "THREADS/SEARCH_RECEIVERS.CLEAR":
      return {
        ...state,
        receivers: null,
        receiversStatus: null,
        receiversPhoneCountryId: null,
        receiversQuery: null,
        notAllowedContacts: null,
        nonArchivedReceivers: null,
        archivedReceivers: [],
        blockedRecievers: [],
      };
    case "THREADS/SEARCH_RECEIVERS.CLEAR_STATUS":
      return {
        ...state,
        receiversStatus: null,
      };
    // Unread
    case "THREADS/UNREAD":
      return {
        ...state,
        unreadsStatus: action.unreadType === "all" ? "loading" : null,
        unreadTextStatus: action.unreadType === "all_text" ? "loading" : null,
        unreadsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        unreadsLoadedAll: false,
      };
    case "THREADS/UNREAD.SUCCESS":
      threads = [];
      if (state.unreads && state.unreadsPage + 1 === action.payload.page) {
        threads = state.unreads.concat(Object.values(action.payload.threads));
      } else {
        threads = Object.values(action.payload.threads);
      }

      return {
        ...state,
        unreads: threads,
        unreadsStatus: action.unreadType === "all" ? "success" : null,
        unreadTextStatus: action.unreadType === "all_text" ? "success" : null,
        unreadsPage: action.payload.page,
        unreadsLoadedAll: action.payload.threads?.length === 0,
        unreadsLoadingMoreStatus: action.page > 1 ? "success" : null,
        unreadType: action.unreadType,
      };

    case "THREADS/UNREAD.ERROR":
      return {
        ...state,
        unreads: null,
        unreadsTotal: null,
        unreadsStatus: action.unreadType === "all" ? "error" : null,
        unreadTextStatus: action.unreadType === "all_text" ? "error" : null,
        unreadsLoadingMoreStatus: action.page > 1 ? "error" : null,
        unreadsLoadedAll: false,
        unreadsPage: null,
      };

    // Undelivered
    case "THREADS/UNDELIVERED":
      return {
        ...state,
        undeliveredsStatus: "loading",
        undeliveredsLoadingMoreStatus: action.page > 1 ? "loading" : null,
      };
    case "THREADS/UNDELIVERED.SUCCESS":
      return {
        ...state,
        undelivereds: Object.values(action.payload.threads),
        undeliveredsTotal: action.payload.total,
        undeliveredsStatus: "success",
        undeliveredsLoadingMoreStatus: action.page > 1 ? "success" : null,
      };
    case "THREADS/UNDELIVERED.ERROR":
      return {
        ...state,
        undelivereds: null,
        undeliveredsTotal: null,
        undeliveredsStatus: "error",
        undeliveredsLoadingMoreStatus: action.page > 1 ? "error" : null,
      };

    // Unread and undelivered counts
    case "THREADS/COUNTS":
      return {
        ...state,
        countsStatus: null,
      };
    case "THREADS/COUNTS.SUCCESS": {
      const threadCounts = {
        countsStatus: "success",
        countsAll:
          action.payload.all !== undefined
            ? action.payload.all
            : state.countsAll,
        unreadsStatus: null,
        unreadTextStatus: null,
        unreadsLoadingMoreStatus: null,
        unreadsTotal:
          action.payload.unread >= 0
            ? action.payload.unread
            : state.unreadsTotal,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsLoadingMoreStatus: null,
        undeliveredsTotal:
          action.payload.undelivered || state.undeliveredsTotal,
        undeliveredAll:
          action.payload.undeliveredAll >= 0
            ? action.payload.undeliveredAll
            : state.undeliveredAll,
        undeliveredReadTotal:
          action.payload.undeliveredRead >= 0
            ? action.payload.undeliveredRead
            : state.undeliveredReadTotal,
        undeliveredUnread:
          action.payload.undeliveredUnread >= 0
            ? action.payload.undeliveredUnread
            : state.undeliveredUnread,
        receivedTotal: action.payload.received || state.receivedTotal,
        sentTotal: action.payload.sent || state.sentTotal,
        sentContacts: action.payload.sentContacts || state.sentContacts,
        sentGroups: action.payload.sentGroups || state.sentGroups,
        sentReprocessed:
          action.payload.sentReprocessed || state.sentReprocessed,
        campaignsTotal: action.payload.campaigns || state.campaignsTotal,
        campaignsAmTotal: action.payload.campaignsAm || state.campaignsAmTotal,
        campaignsAutoRepliesTotal:
          action.payload.campaignsAutoReplies ||
          state.campaignsAutoRepliesTotal,
        campaignsBirthdaysTotal:
          action.payload.campaignsBirthdays || state.campaignsBirthdaysTotal,
        campaignsContestsTotal:
          action.payload.campaignsContests || state.campaignsContestsTotal,
        campaignsDcTotal: action.payload.campaignsDc || state.campaignsDcTotal,
        campaignsPollsTotal:
          action.payload.campaignsPolls || state.campaignsPollsTotal,
        favoritesTotal: action.payload.favorites || state.favoritesTotal,
        scheduledTotal: action.payload.scheduled || state.scheduledTotal,
        scheduledAllNums:
          action.payload.scheduledAllNums || state.scheduledAllNums,
        scheduledContactsTotal:
          action.payload.scheduledContacts || state.scheduledContactsTotal,
        scheduledContactsAllNums:
          action.payload.scheduledContactsAllNums ||
          state.scheduledContactsAllNums,
        scheduledGroupsTotal:
          action.payload.scheduledGroups || state.scheduledGroupsTotal,
        scheduledGroupsAllNums:
          action.payload.scheduledGroupsAllNums || state.scheduledGroupsAllNums,
        scheduledGroupsNamed:
          action.payload.scheduledGroupsNamed || state.scheduledGroupsNamed,
        scheduledGroupsNamedAllNums:
          action.payload.scheduledGroupsNamedAllNums ||
          state.scheduledGroupsNamedAllNums,
        scheduledGroupsUnnamed:
          action.payload.scheduledGroupsUnnamed || state.scheduledGroupsUnnamed,
        scheduledGroupsUnnamedAllNums:
          action.payload.scheduledGroupsUnnamedAllNums ||
          state.scheduledGroupsUnnamedAllNums,
        closedTotal: action.payload.closed || state.closedTotal,
        optIn:
          parseInt(action.payload.optIn) +
            parseInt(action.payload.optedOutLikely) +
            parseInt(action.payload.unsubscribedText) || state.optIn,
        optInTexts:
          parseInt(action.payload.optInTexts) +
            parseInt(action.payload.optedOutLikely) || state.optInTexts,
        optInVoice: action.payload.optInVoice || state.optInVoice,
        mcTotal: action.payload.mc || state.mcTotal,
        blockedTotal: action.payload.blocked || state.blockedTotal,
        optInTextsUnsubscribed:
          action.payload.unsubscribedText || state.optInTextsUnsubscribed,
        optInVoiceUnsubscribed:
          action.payload.unsubscribedVoice || state.optInVoiceUnsubscribed,
        optedOutTexts: action.payload.optedOut || state.optedOutTexts,
        optedOutTextsLikely:
          action.payload.optedOutLikely || state.optedOutTextsLikely,
        doNotCallVoice: action.payload.doNotCall || state.doNotCallVoice,
        mcSubscribed: action.payload.mcSubscribed || state.mcSubscribed,
        mcUnSubscribed: action.payload.mcUnsubscribed || state.mcUnSubscribed,
        mcCleaned: action.payload.mcCleaned || state.mcCleaned,
        mcNonSubscribed:
          action.payload.mcNonSubsrcibed || state.mcNonSubscribed,
        reprocessed: action.payload.sentReprocessed || state.reprocessed,
        reprocessedUnseen:
          action.payload.sentReprocessedUnread || state.reprocessedUnseen,
        reprocessedSeen:
          action.payload.sentReprocessedRead || state.reprocessedSeen,
        named: action.payload.named || state.named,
        unnamed: action.payload.unnamed || state.unnamed,
        unreadText: action.payload["unread-text"] || state["unread-text"],
      };
      return {
        ...state,
        ...threadCounts,
        threadCounts,
      };
    }

    case "THREADS/COUNTS.ERROR": {
      return {
        ...state,
        countsStatus: "error",
      };
    }

    // Mark as read
    case "THREADS/MARK_AS_READ":
      return {
        ...state,
        markAsReadStatus: "loading",
        markAsReadLogId: action.logId,
      };
    case "THREADS/MARK_ALL_AS_READ":
      return {
        ...state,
        markAllAsReadStatus: "loading",
      };
    case "THREADS/MARK_AS_READ.SUCCESS":
    case "THREADS/MARK_ALL_AS_READ.SUCCESS":
      const updatedReadStats = updateStats(true, state, action);
      const markAsReadItemId = state.markAsReadItemId;
      let filterThreads;
      if (
        state.filter === "unread" &&
        Boolean((markAsReadItemId || "").length) &&
        Boolean((action.itemKey || "").length) &&
        state.unreads &&
        Boolean(state.unreads?.length)
      ) {
        filterThreads = state?.unreads?.filter(
          (item) => item.item_key !== markAsReadItemId
        );
      }

      return {
        ...state,
        threadItemsNewCount:
          state.threadItemsNewCount > 0 ? state.threadItemsNewCount - 1 : 0,
        threads: updatedReadStats.threads,
        unreads:
          Boolean(markAsReadItemId?.length) && filterThreads
            ? filterThreads
            : state.unreads,
        threadsUpdated: updatedReadStats.threadsUpdated,
        threadsFiltered: updatedReadStats.threadsFiltered,
        threadsSpecial: updatedReadStats.threadsSpecial,
        threadsFilteredUpdated: updatedReadStats.threadsFilteredUpdated,
        threadItems: updatedReadStats.threadItems,
        threadItemsUpdated: updatedReadStats.threadItemsUpdated,
        voiceThreads: updatedReadStats.voiceThreads,
        voiceThreadsUpdated: updatedReadStats.voiceThreadsUpdated,
        voiceThreadsFiltered: updatedReadStats.voiceThreadsFiltered,
        voiceThreadsFilteredUpdated:
          updatedReadStats.voiceThreadsFilteredUpdated,
        voiceThreadItems: updatedReadStats.voiceThreadItems,
        voiceThreadItemsUpdated: updatedReadStats.voiceThreadItemsUpdated,
        unreadsStatus: null,
        unreadTextStatus: null,
        unreadsLoadingMoreStatus: null,
        markAsReadStatus:
          action.type === "THREADS/MARK_AS_READ.SUCCESS"
            ? "success"
            : state.markAsReadStatus,
        markAsReadLogId:
          action.type === "THREADS/MARK_AS_READ.SUCCESS"
            ? null
            : state.markAsReadLogId,
        markAllAsReadStatus:
          action.type === "THREADS/MARK_ALL_AS_READ.SUCCESS"
            ? "success"
            : state.markAllAsReadStatus,
        //unreadIgnored: action.type === 'THREADS/MARK_ALL_AS_READ.SUCCESS' ? true : state.unreadIgnored,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsLoadingMoreStatus: null,
        markAsReadItemId: action.itemKey,
      };
    case "THREADS/MARK_AS_READ.ERROR":
      return {
        ...state,
        markAsReadStatus: "error",
      };

    case "THREADS/MARK_AS_READ.CLEAR":
      return {
        ...state,
        markAsReadStatus: null,
      };

    // Mark as unread
    case "THREADS/MARK_AS_UNREAD":
      return {
        ...state,
        markAsUnreadStatus: "loading",
        markAsUnreadLogId: action.logId,
      };
    case "THREADS/MARK_AS_UNREAD.SUCCESS":
      const updatedUnreadStats = updateStats(false, state, action, true);

      return {
        ...state,
        threads: updatedUnreadStats.threads,
        threadsUpdated: updatedUnreadStats.threadsUpdated,
        threadsFiltered: updatedUnreadStats.threadsFiltered,
        threadsFilteredUpdated: updatedUnreadStats.threadsFilteredUpdated,
        threadItems: updatedUnreadStats.threadItems,
        threadItemsUpdated: updatedUnreadStats.threadItemsUpdated,
        voiceThreads: updatedUnreadStats.voiceThreads,
        voiceThreadsUpdated: updatedUnreadStats.voiceThreadsUpdated,
        voiceThreadsFiltered: updatedUnreadStats.voiceThreadsFiltered,
        voiceThreadsFilteredUpdated:
          updatedUnreadStats.voiceThreadsFilteredUpdated,
        voiceThreadItems: updatedUnreadStats.voiceThreadItems,
        voiceThreadItemsUpdated: updatedUnreadStats.voiceThreadItemsUpdated,
        unreadsStatus: null,
        unreadTextStatus: null,
        unreadsLoadingMoreStatus: null,
        markAsUnreadStatus:
          action.type === "THREADS/MARK_AS_UNREAD.SUCCESS"
            ? "success"
            : state.markAsUnreadStatus,
        markAsUnreadLogId:
          action.type === "THREADS/MARK_AS_UNREAD.SUCCESS"
            ? null
            : state.markAsUnreadLogId,
      };
    case "THREADS/MARK_AS_UNREAD.ERROR":
      return {
        ...state,
        markAsUnreadStatus: "error",
      };

    // Mark all undelivered as read
    case "THREADS/MARK_ALL_UNDELIVERED_AS_READ":
      return {
        ...state,
        markAllUndeliveredAsReadStatus: "loading",
      };
    case "THREADS/MARK_ALL_UNDELIVERED_AS_READ.SUCCESS":
      threads = state.threads;
      threadsUpdated = state.threadsUpdated;
      if (action.payload.logsIds.length && threads) {
        for (let i = 0; i < threads.length; i++) {
          if (
            threads[i].log &&
            action.payload.logsIds.indexOf(threads[i].log.id) !== -1
          ) {
            threads[i].log.read_failed = 1;
            threadsUpdated = state.threadsUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[threads[i].item_key] !==
            "undefined"
          ) {
            threads[i]["undelivered_count"] =
              action.payload.threadCounts[
                threads[i].item_key
              ].undelivered_count;
            threads[i]["unread_count"] =
              action.payload.threadCounts[threads[i].item_key].unread_count;
            threadsUpdated = state.threadsUpdated + 1;
          }
        }
      }

      threadsFiltered = state.threadsFiltered;
      threadsFilteredUpdated = state.threadsFilteredUpdated;
      if (action.payload.logsIds.length && threadsFiltered) {
        for (let i = 0; i < threadsFiltered.length; i++) {
          if (
            threadsFiltered[i].log &&
            action.payload.logsIds.indexOf(threadsFiltered[i].log.id) !== -1
          ) {
            threadsFiltered[i].log.read_failed = 1;
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
          if (
            typeof action.payload.threadCounts[threadsFiltered[i].item_key] !==
            "undefined"
          ) {
            threadsFiltered[i]["undelivered_count"] =
              action.payload.threadCounts[
                threadsFiltered[i].item_key
              ].undelivered_count;
            threadsFiltered[i]["unread_count"] =
              action.payload.threadCounts[
                threadsFiltered[i].item_key
              ].unread_count;
            threadsFilteredUpdated = state.threadsFilteredUpdated + 1;
          }
        }
      }

      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;
      if (action.payload.logsIds.length && threadItems) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].log &&
            action.payload.logsIds.indexOf(threadItems[i].log.id) !== -1
          ) {
            threadItems[i].log.read_failed = 1;
            threadItemsUpdated = state.threadItemsUpdated + 1;
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: threadsUpdated,
        threadsFiltered: threadsFiltered,
        threadsFilteredUpdated: threadsFilteredUpdated,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsLoadingMoreStatus: null,
        markAllUndeliveredAsReadStatus: "success",
      };

    case "THREADS/IGNORE_UNREAD":
      return {
        ...state,
        unreadIgnored: true,
      };

    case "THREADS/UNIGNORE_UNREAD":
      return {
        ...state,
        unreadIgnored: false,
      };

    case "CONTACTS/EDIT_FIELD.SUCCESS":
      threads = state.threads;
      threadsUpdated = state.threadsUpdated;
      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;

      let editedFields = Object.keys(action.contactData);

      if (threads) {
        for (let i = 0; i < threads.length; i++) {
          if (
            threads[i].contact &&
            threads[i].contact.id === action.contactId
          ) {
            for (let j = 0; j < editedFields.length; j++) {
              if (typeof threads[i].contact[editedFields[j]] !== "undefined") {
                threads[i].contact[editedFields[j]] =
                  action.contactData[editedFields[j]];
                threadsUpdated = state.threadsUpdated + 1;
              }
            }
          }
        }
      }

      if (threadItems) {
        for (let i = 0; i < threadItems.length; i++) {
          if (
            threadItems[i].contact &&
            threadItems[i].contact.id === action.contactId
          ) {
            for (let j = 0; j < editedFields.length; j++) {
              if (
                typeof threadItems[i].contact[editedFields[j]] !== "undefined"
              ) {
                threadItems[i].contact[editedFields[j]] =
                  action.contactData[editedFields[j]];
                threadItemsUpdated = state.threadItemsUpdated + 1;
              }
            }
          }
        }
      }

      return {
        ...state,
        threads: threads,
        threadsUpdated: threadsUpdated,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
      };

    case "SEND_MESSAGE_SUCCESS":
      threadItems =
        state.threadItems && Array.isArray(state.threadItems)
          ? [...state.threadItems]
          : [];
      if (
        action.receiverId &&
        window.location?.pathname?.includes(action.receiverId) &&
        action.payload?.new_item &&
        action.payload?.new_item.length
      ) {
        if (hasScheduledMessages(threadItems)) {
          addEditScheduledMessage(
            threadItems,
            action.payload?.new_item[0],
            action.isOnEditScheduledMessage
          );
        } else {
          threadItems.push(action.payload.new_item[0]);
        }
      }
      return {
        ...state,
        undelivereds: null,
        undeliveredsStatus: null,
        undeliveredsLoadingMoreStatus: null,
        undeliveredsTotal: action.payload.undelivered,
        newMessageLogId: action.payload.log_id,
        threadItems,
      };

    case "THREADS/RESET_NEW_MESSAGE_LOG_ID":
      return {
        ...state,
        newMessageLogId: null,
      };

    case "NUMBERS/FILTER":
      return {
        ...state,
        initialLoad: state.initialLoad,
        filter: state.filter,
      };

    case "THREADS/UPDATE_ITEMS_STATUS":
      return {
        ...state,
        updateItemsStatusStatus: "loading",
      };
    case "THREADS/UPDATE_ITEMS_STATUS.SUCCESS":
      const newStatuses = action.payload.scheduledMessageStatus;
      threadItems = state.threadItems;
      threadItemsUpdated = state.threadItemsUpdated;
      if (threadItems) {
        for (let i = threadItems.length - 1; i >= 0; i--) {
          if (
            !threadItems[i].schedule_message ||
            typeof newStatuses["" + threadItems[i].schedule_message.id] ===
              "undefined"
          ) {
            continue;
          }
          threadItems[i].schedule_message.is_processing =
            newStatuses["" + threadItems[i].schedule_message.id].is_processing;
          threadItems[i].schedule_message.sent =
            newStatuses["" + threadItems[i].schedule_message.id].sent;
          threadItemsUpdated = state.threadItemsUpdated + 1;
        }
      }

      return {
        ...state,
        updateItemsStatusStatus: "success",
        updateItemsScheduledStatus: action.payload.scheduledMessageStatus,
        threadItems: threadItems,
        threadItemsUpdated: threadItemsUpdated,
      };
    case "THREADS/UPDATE_ITEMS_STATUS.ERROR":
      return {
        ...state,
        updateItemsStatusStatus: "error",
        updateItemsScheduledStatus: null,
      };

    case "THREADS/UPDATE_ITEMS_STATUS.CLEAR":
      return {
        ...state,
        updateItemsStatusStatus: null,
        updateItemsScheduledStatus: null,
      };

    case "THREADS/RELOAD_ITEM":
    case "THREADS/RELOAD_ITEM.SUCCESS":
    case "THREADS/RELOAD_ITEM.ERROR":
      ({ groupSmsBlastId } = action);
      ({ threadItems, threadItemsUpdated } = state);
      if (threadItems) {
        for (let i = threadItems.length - 1; i >= 0; i--) {
          if (
            threadItems[i].group_sms_blast &&
            threadItems[i].group_sms_blast.id === groupSmsBlastId
          ) {
            threadItems = [...threadItems];

            if (action.type === "THREADS/RELOAD_ITEM") {
              threadItems[i].reloadStatus = "loading";
            } else if (action.type === "THREADS/RELOAD_ITEM.SUCCESS") {
              threadItems[i] = {
                ...threadItems[i],
                ...action.payload.item,
              };
              threadItems[i].reloadStatus = "success";
            } else if (action.type === "THREADS/RELOAD_ITEM.ERROR") {
              threadItems[i].reloadStatus = "error";
            }

            threadItemsUpdated += 1;
            break;
          }
        }
      }
      return {
        ...state,
        threadItems,
        threadItemsUpdated,
      };

    // Reset lists after delete contacts
    case "CONTACTS/BATCH_DELETE.SUCCESS":
    case "CONTACTS/DELETE.SUCCESS":
      return {
        ...state,
        // Fetch threads list
        threads: null,
        threadsStatus: null,
        threadsLoadingMoreStatus: null,
        threadsError: null,
        threadsPage: null,
        threadsLoadedAll: null,
        threadsCompanyId: null,
        threadsUpdated: 0,
        threadsTotal: null,

        // Fetch threads filtered
        threadsFiltered: null,
        threadsFilteredStatus: null,
        threadsFilteredLoadingMoreStatus: null,
        threadsFilteredPage: null,
        threadsFilteredLoadedAll: null,
        threadsFilteredUpdated: 0,

        // Fetch threads list
        threadsSpecial: null,
        threadsSpecialStatus: null,
        threadsSpecialLoadingMoreStatus: null,
        threadsSpecialPage: null,
        threadsSpecialLoadedAll: null,
        threadsSpecialUpdated: 0,
        threadsSpecialTotal: null,

        // Fetch voice threads
        voiceThreads: null,
        voiceThreadsStatus: null,
        voiceThreadsLoadingMoreStatus: null,
        voiceThreadsPage: null,
        voiceThreadsLoadedAll: null,
        voiceThreadsUpdated: null,
        voiceThreadsTotal: null,

        // Fetch voice threads filtered
        voiceThreadsFiltered: null,
        voiceThreadsFilteredStatus: null,
        voiceThreadsFilteredLoadingMoreStatus: null,
        voiceThreadsFilteredPage: null,
        voiceThreadsFilteredLoadedAll: null,
        voiceThreadsFilteredUpdated: 0,
        voiceThreadsFilteredTotal: null,
      };

    case "THREADS/SCHEDULED_VISIBLE":
      return {
        ...state,
        scheduledVisible: action.visible,
      };

    case "THREADS/CLEAR_ITEMS":
      // Intended not changed object to prevent updates
      let newState = state;

      if (
        state.threadItemsThreadType === action.threadType &&
        state.threadItemsInterlocutorId === action.interlocutorId
      ) {
        newState = {
          ...newState,
          threadItems: null,
          threadItemsStatus: null,
          threadItemsError: null,
          threadItemsLoadedAll: null,
          threadItemsCompanyId: null,
          threadItemsThreadType: null,
          threadItemsInterlocutorId: null,
          threadItemsItemKey: null,
          threadItemsOutdated: null,
          threadItemsNewCount: 0,
          threadItemsUpdated: 0,
          threadItemsUpdatedDirection: null,
        };
      }
      if (
        state.voiceThreadItemsThreadType === action.threadType &&
        state.voiceThreadItemsInterlocutorId === action.interlocutorId
      ) {
        newState = {
          ...newState,
          voiceThreadItems: null,
          voiceThreadItemsStatus: null,
          voiceThreadItemsLoadedAll: null,
          voiceThreadItemsThreadType: null,
          voiceThreadItemsInterlocutorId: null,
          voiceThreadItemsItemKey: null,
          voiceThreadItemsNewCount: 0,
          voiceThreadItemsUpdated: 0,
          voiceThreadItemsUpdatedDirection: null,
        };
      }
      return newState;

    // Delete message
    case "MESSAGES/DELETE":
    case "MESSAGES/DELETE.SUCCESS":
    case "MESSAGES/DELETE.ERROR":
      threadItems = state.threadItems;
      voiceThreadItems = state.voiceThreadItems;

      if (threadItems) {
        for (let i = 0; i < threadItems.length; i += 1) {
          if (
            isDeletedItem(
              threadItems[i],
              action.logId,
              action.groupSmsBlastId,
              action.scheduleMessageId
            )
          ) {
            threadItems = [].concat(threadItems);
            if (action.type === "MESSAGES/DELETE") {
              threadItems[i].deleting = true;
            } else if (action.type === "MESSAGES/DELETE.SUCCESS") {
              threadItems[i].deleting = false;
              threadItems[i].deleted = true;
            } else if (action.type === "MESSAGES/DELETE.ERROR") {
              threadItems[i].deleting = false;
              threadItems[i].deletingError = true;
            }
            break;
          }
        }
      }

      if (voiceThreadItems) {
        for (let i = 0; i < voiceThreadItems.length; i += 1) {
          if (
            isDeletedItem(
              voiceThreadItems[i],
              action.logId,
              action.groupSmsBlastId,
              action.scheduleMessageId
            )
          ) {
            voiceThreadItems = [].concat(voiceThreadItems);
            if (action.type === "MESSAGES/DELETE") {
              voiceThreadItems[i].deleting = true;
            } else if (action.type === "MESSAGES/DELETE.SUCCESS") {
              voiceThreadItems[i].deleting = false;
              voiceThreadItems[i].deleted = true;
            } else if (action.type === "MESSAGES/DELETE.ERROR") {
              voiceThreadItems[i].deleting = false;
              voiceThreadItems[i].deletingError = true;
            }
            break;
          }
        }
      }

      return {
        ...state,
        threadItems,
        voiceThreadItems,
      };

    case "THREADS/CLOSE_THREADS": {
      const closingIds = state.closingIds ? [...state.closingIds] : [];
      closingIds.push(...action.closingIds);
      return {
        ...state,
        closeThreadsStatus: "loading",
        closingIds,
        closedSuccessThreads: [],
        closeNeedRouting: false,
      };
    }

    case "THREADS/CLOSE_THREADS.SUCCESS": {
      let closingIds = state.closingIds ? [...state.closingIds] : [];
      const rmClosing = action.closingIds ? action.closingIds : [];
      closingIds = closingIds.filter((el) => !rmClosing.includes(el));

      return {
        ...state,
        closeThreadsStatus: `success-${action.workflow_id}`,
        closedSuccessThreads: [...action.closingIds],
        closeNeedRouting: action.needRouting,
        closingIds,
      };
    }

    case "THREADS/CLOSE_THREADS.FAILURE": {
      let closingIds = state.closingIds ? [...state.closingIds] : [];
      const rmClosing = action.closingIds ? action.closingIds : [];
      closingIds = closingIds.filter((el) => !rmClosing.includes(el));

      return {
        ...state,
        closeThreadsStatus: `failure-${action.workflow_id}`,
        closedSuccessThreads: [],
        closingIds,
      };
    }

    case "THREADS/REOPEN_THREADS": {
      const reopeningIds = state.reopeningIds ? [...state.reopeningIds] : [];
      reopeningIds.push(...action.reopeningIds);
      return {
        ...state,
        reopenThreadsStatus: "loading",
        reopeningIds,
        reopenedSuccessThreads: [],
        reopenNeedRouting: false,
      };
    }

    case "THREADS/REOPEN_THREADS.SUCCESS": {
      let reopeningIds = state.reopeningIds ? [...state.reopeningIds] : [];
      const rmReopening = action.reopeningIds ? action.reopeningIds : [];
      reopeningIds = reopeningIds.filter((el) => !rmReopening.includes(el));

      return {
        ...state,
        reopenThreadsStatus: `success-${action.workflow_id}`,
        reopenedSuccessThreads: [...action.reopeningIds],
        reopenNeedRouting: action.needRouting,
        reopeningIds,
      };
    }

    case "THREADS/REOPEN_THREADS.FAILURE": {
      let reopeningIds = state.reopeningIds ? [...state.reopeningIds] : [];
      const rmReopening = action.reopeningIds ? action.reopeningIds : [];
      reopeningIds = reopeningIds.filter((el) => !rmReopening.includes(el));

      return {
        ...state,
        reopenThreadsStatus: `failure-${action.workflow_id}`,
        reopenedSuccessThreads: [],
        reopeningIds,
      };
    }

    case "THREADS/MARK_AS_READ_THREADS": {
      return {
        ...state,
        markAsReadThreadsStatus: "loading",
      };
    }

    case "THREADS/MARK_AS_READ_THREADS.SUCCESS": {
      let newUnReads = [];
      const isUnreadOpenThread = (unread) => {
        // this function will remove all thread except the opened thread with the help of interlocutorId
        const { interlocutorId } = action.payload;
        const foundOpenUnreadThread = unread.contacts_ids.some(
          (contactId) => contactId === Number(interlocutorId)
        );

        return foundOpenUnreadThread;
      };

      const markAllThreadsRead = (threadsObject) => {
        const updatedThread = threadsObject?.map((thread) => {
          thread.unread_count = 0;
          return thread;
        });

        if (updatedThread) return [...updatedThread];
        return updatedThread;
      };

      const updateUnreadDropdown = () => {
        if (state.unreads) {
          for (let unread of state.unreads) {
            if (
              !action.payload.contactIds.includes(unread.primary_contact_id) &&
              isUnreadOpenThread(unread)
            ) {
              newUnReads.push({ ...unread, unread_count: 0 });
            }
            if (!action.payload.logsIds.includes(unread.log.id)) {
              newUnReads.push({ ...unread, unread_count: unread.unread_count });
            }
          }
        } else {
          newUnReads = state.unreads;
        }
      };

      updateUnreadDropdown();
      return {
        ...state,
        markAsReadThreadsStatus: "success",
        unreadsTotal: action.payload.unread,
        unreads: newUnReads,
        threads: markAllThreadsRead(state.threads),
        specialThreads: markAllThreadsRead(state.specialThreads),
      };
    }

    case "THREADS/MARK_AS_READ_THREADS.FAILURE": {
      return {
        ...state,
        markAsReadThreadsStatus: "failure",
      };
    }

    case "THREADS/CLEAR_REPROCESSED_UNDELIVERED_LABEL": {
      return {
        ...state,
        clearReprocessedUndeliveredStatus: "loading",
      };
    }

    case "THREADS/CLEAR_REPROCESSED_UNDELIVERED_LABEL.SUCCESS": {
      const cloneThreads = [...state.threads];
      const cloneSpecialThreads = [...state.threadsSpecial];
      updateThreadsForClearLabels(cloneThreads, action.data);
      updateThreadsForClearLabels(cloneSpecialThreads, action.data);
      return {
        ...state,
        clearReprocessedUndeliveredStatus: "success",
        threads: cloneThreads,
        threadsSpecial: cloneSpecialThreads,
      };
    }

    case "THREADS/CLEAR_REPROCESSED_UNDELIVERED_LABEL.FAILURE": {
      return {
        ...state,
        clearReprocessedUndeliveredStatus: "failure",
      };
    }

    case "THREADS/MARK_AS_UNREAD_THREADS": {
      return {
        ...state,
        markAsUnreadThreadsStatus: "loading",
      };
    }

    case "THREADS/MARK_AS_UNREAD_THREADS.SUCCESS": {
      return {
        ...state,
        markAsUnreadThreadsStatus: "success",
      };
    }

    case "THREADS/MARK_AS_UNREAD_THREADS.FAILURE": {
      return {
        ...state,
        markAsUnreadThreadsStatus: "failure",
      };
    }

    case "THREADS/ADD_SNACK_DATA": {
      const { entity, data } = action.payload;
      if (entity && entity === "close") {
        const closeSnackbarData = { ...state.closeSnackbarData };
        closeSnackbarData[data.id] = { ...data };
        return { ...state, closeSnackbarData };
      } else if (entity && entity === "reopen") {
        const reopenSnackbarData = { ...state.reopenSnackbarData };
        reopenSnackbarData[data.id] = { ...data };
        return { ...state, reopenSnackbarData };
      } else if (entity && entity === "markAsRead") {
        const markAsReadSnackbarData = { ...state.markAsReadSnackbarData };
        markAsReadSnackbarData[data.id] = { ...data };
        return { ...state, markAsReadSnackbarData };
      } else if (entity && entity === "markAsUnRead") {
        const markAsUnReadSnackbarData = { ...state.markAsUnReadSnackbarData };
        markAsUnReadSnackbarData[data.id] = { ...data };
        return { ...state, markAsUnReadSnackbarData };
      } else if (entity && entity === "sendMessage") {
        const sendMessageSnackbarData = { ...state.sendMessageSnackbarData };
        sendMessageSnackbarData[data.id] = { ...data };
        return { ...state, sendMessageSnackbarData };
      } else if (entity && entity === "callBroadcast") {
        const callBroadcastSnackbarData = {
          ...state.callBroadcastSnackbarData,
        };
        callBroadcastSnackbarData[data.id] = { ...data };
        return { ...state, callBroadcastSnackbarData };
      }
      return { ...state };
    }

    case "THREADS/REMOVE_SNACK_DATA": {
      const { entity, id } = action.payload;
      if (entity && entity === "close") {
        const closeSnackbarData = { ...state.closeSnackbarData };
        delete closeSnackbarData[id];
        return { ...state, closeSnackbarData };
      } else if (entity && entity === "reopen") {
        const reopenSnackbarData = { ...state.reopenSnackbarData };
        delete reopenSnackbarData[id];
        return { ...state, reopenSnackbarData };
      } else if (entity && entity === "markAsRead") {
        const markAsReadSnackbarData = { ...state.markAsReadSnackbarData };
        delete markAsReadSnackbarData[id];
        return { ...state, markAsReadSnackbarData };
      } else if (entity && entity === "markAsUnRead") {
        const markAsUnReadSnackbarData = { ...state.markAsUnReadSnackbarData };
        delete markAsUnReadSnackbarData[id];
        return { ...state, markAsUnReadSnackbarData };
      } else if (entity && entity === "sendMessage") {
        const sendMessageSnackbarData = { ...state.sendMessageSnackbarData };
        delete sendMessageSnackbarData[id];
        return { ...state, sendMessageSnackbarData };
      } else if (entity && entity === "callBroadcast") {
        const callBroadcastSnackbarData = {
          ...state.callBroadcastSnackbarData,
        };
        delete callBroadcastSnackbarData[id];
        return { ...state, callBroadcastSnackbarData };
      }

      return { ...state };
    }

    case "THREADS/REFRESH_GROUP_MSG_STATS":
    case "THREADS/REFRESH_GROUP_MSG_STATS.SUCCESS":
    case "THREADS/REFRESH_GROUP_MSG_STATS.ERROR":
      ({ groupSmsBlastId } = action);
      ({ threadItems, threadItemsUpdated } = state);
      if (threadItems) {
        for (let i = threadItems.length - 1; i >= 0; i--) {
          if (
            threadItems[i].group_sms_blast &&
            threadItems[i].group_sms_blast.id === groupSmsBlastId
          ) {
            threadItems = [...threadItems];

            if (action.type === "THREADS/REFRESH_GROUP_MSG_STATS") {
              threadItems[i].refreshGroupMsgStatsStatus = "loading";
            } else if (
              action.type === "THREADS/REFRESH_GROUP_MSG_STATS.SUCCESS"
            ) {
              threadItems[i] = {
                ...threadItems[i],
                ...action.payload.item,
              };
              threadItems[i].refreshGroupMsgStatsStatus = "success";
            } else if (
              action.type === "THREADS/REFRESH_GROUP_MSG_STATS.ERROR"
            ) {
              threadItems[i].refreshGroupMsgStatsStatus = "error";
            }

            threadItemsUpdated += 1;
            break;
          }
        }
      }
      return {
        ...state,
        threadItems,
        threadItemsUpdated,
      };

    case "THREADS/VOICE_COUNTS":
      return {
        ...state,
        voiceCountsStatus: null,
      };
    case "THREADS/VOICE_COUNTS.SUCCESS": {
      const voiceCounts = {
        closedTotal: getVoiceCount(
          action.payload.closed,
          state.voiceCounts.closedTotal
        ),
        unread: getVoiceCount(action.payload.unread, state.voiceCounts.unread),
        undeliveredUnread: getVoiceCount(
          action.payload.undeliveredUnread,
          state.voiceCounts.undeliveredUnread
        ),
        // undelivered:  action.payload.undelivered || state.voiceCounts.undelivered,
        // undeliveredAll:  action.payload.undeliveredAll || state.voiceCounts.undeliveredAll,
        undeliveredRead: getVoiceCount(
          action.payload.undeliveredRead,
          state.voiceCounts.undeliveredRead
        ),
        all: getVoiceCount(action.payload.all, state.voiceCounts.all),
        received: getVoiceCount(
          action.payload.received,
          state.voiceCounts.received
        ),
        // sent:  action.payload.sent || state.voiceCounts.sent,
        // campaigns:  action.payload.campaigns || state.voiceCounts.campaigns,
        // campaignsAm:  action.payload.campaignsAm || state.voiceCounts.campaignsAm,
        favorites: getVoiceCount(
          action.payload.favorites,
          state.voiceCounts.favorites
        ),
        blockedOptIn: getVoiceCount(
          action.payload.blocked,
          state.voiceCounts.blocked
        ),
        optIn: getVoiceCount(action.payload.optIn, state.voiceCounts.optIn),
        optInVoice: getVoiceCount(
          action.payload.optInVoice,
          state.voiceCounts.optInVoice
        ),
        optedOutLikely: getVoiceCount(
          action.payload.optedOutLikely,
          state.voiceCounts.optedOutLikely
        ),
        doNotCallVoice: getVoiceCount(
          action.payload.doNotCall,
          state.voiceCounts.doNotCallVoice
        ),
        unsubscribedVoice: getVoiceCount(
          action.payload.unsubscribedVoice,
          state.voiceCounts.unsubscribedVoice
        ),
        scheduled: getVoiceCount(
          action.payload.scheduled,
          state.voiceCounts.scheduled
        ),
        scheduledContacts: getVoiceCount(
          action.payload.scheduledContacts,
          state.voiceCounts.scheduledContacts
        ),
        scheduledGroups: getVoiceCount(
          action.payload.scheduledGroups,
          state.voiceCounts.scheduledGroups
        ),
        scheduledGroupsNamed: getVoiceCount(
          action.payload.scheduledGroupsNamed,
          state.voiceCounts.scheduledGroupsNamed
        ),
        scheduledGroupsUnnamed: getVoiceCount(
          action.payload.scheduledGroupsUnnamed,
          state.voiceCounts.scheduledGroupsUnnamed
        ),
        voicemail: getVoiceCount(
          action.payload.voicemail,
          state.voiceCounts.voicemail
        ),
        voicemailUnseen: getVoiceCount(
          action.payload.voicemailUnread,
          state.voiceCounts.voicemailUnread
        ),
        voicemailSeen: getVoiceCount(
          action.payload.voicemailRead,
          state.voiceCounts.voicemailRead
        ),
        missed: getVoiceCount(action.payload.missed, state.voiceCounts.missed),
        missedUnseen: getVoiceCount(
          action.payload.missedUnread,
          state.voiceCounts.missedUnread
        ),
        missedSeen: getVoiceCount(
          action.payload.missedRead,
          state.voiceCounts.missedRead
        ),
        outgoing: getVoiceCount(
          action.payload.outgoing,
          state.voiceCounts.outgoing
        ),
        outgoingCalls: getVoiceCount(
          action.payload.outgoingCalls,
          state.voiceCounts.outgoingCalls
        ),
        outgoingForwarded: getVoiceCount(
          action.payload.outgoingForwarded,
          state.voiceCounts.outgoingForwarded
        ),
        outgoingVoiceBroadcast: getVoiceCount(
          action.payload.outgoingBroadcasts,
          state.voiceCounts.outgoingBroadcasts
        ),
        reprocessed: getVoiceCount(
          action.payload.sentReprocessed,
          state.voiceCounts.reprocessed
        ),
      };
      voiceCounts.undeliveredAll =
        parseInt(voiceCounts.undeliveredUnread) +
        parseInt(voiceCounts.undeliveredRead);
      return {
        ...state,
        voiceCountsStatus: "success",
        voiceCounts,
      };
    }

    case "THREADS/VOICE_COUNTS.ERROR":
      return {
        ...state,
        voiceCountsStatus: "error",
      };

    case "THREADS/SET_HEADER_PROGRESS":
      return {
        ...state,
        headerInProgress: action.val,
      };

    case "THREADS/UPDATE_DRAFTS":
      let draftThreads = [];
      if (action.removeDraft && action.threadId) {
        if (action.threadType === GROUP && state.threadsSpecial?.length) {
          draftThreads = state.threadsSpecial?.filter((item) => {
            return item?.group?.id !== Number(action.threadId);
          });
        }
        if (action.threadType === CONTACT && state.threadsSpecial?.length) {
          draftThreads = state.threadsSpecial.filter((item) => {
            return (
              item.contacts_ids === null ||
              item.contacts_ids[0] !== Number(action.threadId)
            );
          });
        }
      }
      return {
        ...state,
        draftThreadType: action.threadType,
        threadsSpecial: action.removeDraft
          ? draftThreads
          : state.threadsSpecial,
        draftCount: action.removeDraft
          ? draftThreads?.length
          : state.threadsSpecial?.length,
        threadsSpecialLoadedAll: 1,
      };

    case "RESET_THREAD_ITEM_ID":
      return {
        ...state,
        threadItemsItemKey: null,
      };

    case "POPULATE_NEW_DRAFTS":
      return {
        ...state,
        draftCount: action.currentDraftsLength,
      };

    case "RESET_MARK_AS_READ_ID":
      return {
        ...state,
        markAsReadItemId: "",
      };
    case "CLEAR_THREAD_SEARCH_DATA":
      return {
        ...state,
        threadSearchCondition: null,
        searchedQuery: null,
        threadSearchFilter: null,
        threadsFiltered: null,
        highlightedTerms: [],
        currentActiveSearchTerm: null,
        filteredSearchThreads: {
          messages: {
            data: [],
            archived: [],
          },
          groups: {
            data: [],
            archived: [],
          },
          people: {
            data: [],
            archived: [],
          },
          voice: {
            data: [],
            archived: [],
          },
        },
      };
    case "SET_THREAD_SEARCH_FILTER":
      return {
        ...state,
        threadSearchCondition: action.condition,
        threadSearchFilter: action.filter,
        populateQuery: action.populateQuery,
      };
    case "THREADS/SET_HIGHLIGHTED_TERMS":
      return {
        ...state,
        highlightedTerms: action.highlightedTerms,
      };
    case "THREADS/SET_CURRENT_ACTIVE_TERM":
      return {
        ...state,
        currentActiveSearchTerm: action.searchedTerm,
      };

    case "MESSAGES/RESEND_UNDELIVERED_MESSAGE":
      return {
        ...state,
        resendMessageStatus: "loading",
      };

    case "MESSAGES/RESEND_UNDELIVERED_MESSAGE.SUCCESS": {
      threadItems = state.threadItems ? [...state.threadItems] : [];
      const currentThreads = !state.filter
        ? [...state.threads]
        : [...state.threadsSpecial];

      if (threadItems.length) {
        threadItems.forEach((item, index) => {
          if (item.log?.id === action.requestId) {
            threadItems[index] = action.payload.resent_item;
          }
        });
      }
      if (action.payload?.new_item) {
        if (hasScheduledMessages(threadItems)) {
          addEditScheduledMessage(threadItems, action.payload?.new_item);
        } else {
          threadItems.push(action.payload.new_item);
        }
      }
      for (let i = 0; i < currentThreads.length; i += 1) {
        const thread = action.payload.thread;
        const keys = Object.keys(thread);
        if (currentThreads[i].item_key === thread[keys[0]]?.item_key) {
          currentThreads[i] = thread[keys[0]];
          break;
        }
      }

      return {
        ...state,
        resendMessageStatus: "success",
        newMessageLogId: action.payload.new_item?.log?.id,
        threadItems,
        threadsSpecial: state.filter ? currentThreads : state.threadsSpecial,
        threads: !state.filter ? currentThreads : state.threads,
        resendFailedErrorMessage: "",
      };
    }

    case "MESSAGES/RESEND_UNDELIVERED_MESSAGE.ERROR":
      const errorMessage =
        action.error?.status === 403
          ? action.error?.data?.errors
          : "Something went wrong, please try again later";
      return {
        ...state,
        resendMessageStatus: "error",
        resendFailedErrorMessage: errorMessage,
      };

    case "MESSAGES/RESEND_UNDELIVERED_MESSAGE.CLEAR":
      return {
        ...state,
        resendMessageStatus: null,
        resendFailedErrorMessage: "",
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      resetFetchThreadsRequests();
      return initialState;

    case "CONTACTS/DONOTCALL_CONTACTS.SUCCESS": {
      if (action.isUndo) {
        return handleShowFilterItems(state, action, false, "do-not-call");
      }
      let scrollDownChatMessages = false;
      if (state.threadItems && action.eventLog) {
        action.eventLog.length = 1;
        threadItems = insertNewLogEvent(state.threadItems, action.eventLog);
        scrollDownChatMessages = true;
      }
      return {
        ...state,
        threadItems: threadItems ? [...threadItems] : state.threadItems,
        scrollDownChatMessages,
      };
    }

    case "CONTACTS/RESET_DONOTCALL_CONTACTS.SUCCESS": {
      let scrollDownChatMessages = false;
      if (state.threadItems && action.eventLog) {
        action.eventLog.length = 1;
        threadItems = insertNewLogEvent(state.threadItems, action.eventLog);
        scrollDownChatMessages = true;
      }
      return {
        ...handleShowFilterItems(state, action, true, "do-not-call"),
        threadItems: threadItems ? [...threadItems] : state.threadItems,
        scrollDownChatMessages,
      };
    }

    case "CONTACTS/BLOCK_CONTACTS.SUCCESS": {
      let scrollDownChatMessages = false;
      if (state.threadItems && action.eventLog) {
        action.eventLog.length = 1;
        threadItems = insertNewLogEvent(state.threadItems, action.eventLog);
        scrollDownChatMessages = true;
      }
      return {
        ...deleteRestoreBasedOnAction(
          state,
          action?.payload?.contactIds || [],
          action?.groupIds || [],
          action.isUndo,
          "block"
        ),
        threadItems: threadItems ? [...threadItems] : state.threadItems,
        scrollDownChatMessages,
      };
    }

    case "CONTACTS/UNBLOCK_CONTACTS.SUCCESS": {
      let scrollDownChatMessages = false;
      if (state.threadItems && action.eventLog) {
        action.eventLog.length = 1;
        threadItems = insertNewLogEvent(state.threadItems, action.eventLog);
        scrollDownChatMessages = true;
      }
      return {
        ...deleteRestoreBasedOnAction(
          state,
          action?.payload?.contactIds || [],
          action?.groupIds || [],
          action.isUndo,
          "block"
        ),
        threadItems: threadItems ? [...threadItems] : state.threadItems,
        scrollDownChatMessages,
      };
    }

    case "CONTACTS/SUBSCRIBE_CONTACTS.SUCCESS": {
      let scrollDownChatMessages = false;
      if (state.threadItems && action.eventLog) {
        action.eventLog.length = 1;
        threadItems = insertNewLogEvent(state.threadItems, action.eventLog);
        scrollDownChatMessages = true;
      }
      return {
        ...handleShowFilterItems(
          state,
          action,
          true,
          "unsubscribed-text",
          threadItems
        ),
        threadItems: threadItems ? [...threadItems] : state.threadItems,
        scrollDownChatMessages,
      };
    }

    case "CONTACTS/UNSUBSCRIBE_CONTACTS.SUCCESS": {
      let scrollDownChatMessages = false;
      const matcher = history.location.pathname.match(
        /hub\/(\w+)(\/filter\/([\w-]+))?/
      );

      if (matcher?.length === 4 && matcher[3] === "opted-out-likely") {
        return handleShowFilterItems(state, action, true, "opted-out-likely");
      } else if (action.isUndo) {
        return handleShowFilterItems(state, action, false, "unsubscribed-text");
      }
      if (state.threadItems && action.eventLog) {
        action.eventLog.length = 1;
        threadItems = insertNewLogEvent(state.threadItems, action.eventLog);
        scrollDownChatMessages = true;
      }

      return {
        ...state,
        threadItems: threadItems ? [...threadItems] : state.threadItems,
        scrollDownChatMessages,
      };
    }

    case "GROUPS/BULK_EDIT_FIELD.SUCCESS":
    case "CONTACTS/BATCH_EDIT_FIELD.SUCCESS": {
      if (
        action?.contactData?.is_favorite === 0 ||
        action.contactData.is_number_favorite === 0 ||
        action?.groupData?.is_favorite === 0
      ) {
        return handleShowFilterItems(
          state,
          {
            ...action,
            payload: {
              ...action.payload,
              contactIds: action.contactIds ? action.contactIds : [],
              groupIds: action.groupIds ? action.groupIds : [],
            },
          },
          true,
          "favorites"
        );
      }

      return state;
    }

    case "THREADS/INITIAL_LOAD.SUCCESS":
      return {
        ...state,
        initialLoad: false,
      };

    case "THREADS/RESET_THREADS_PAGE":
      return {
        ...state,
        threadsPage: 1,
        unreadThreads: 1,
        threadsSpecialPage: 1,
      };

    case "THREADS/SET_SCROLL_DOWN_CHAT_MESSAGES":
      return { ...state, scrollDownChatMessages: action.scrollDown };

    case "THREADS/SET_THREAD_ITEMS_TYPE_FILTER":
      return {
        ...state,
        threadItemsTypeFilter: action.filter,
        threadItems: null,
      };
    case "THREADS/SET_MESSAGES_TAB_TYPE":
      return { ...state, selectedMessagesTab: action.payload };

    // Add vCards
    case "THREADS/ADD_VCARDS":
      return {
        ...state,
        addVcardsStatus: "loading",
      };

    case "THREADS/ADD_VCARDS.SUCCESS":
      const vCardsUrls = action.payload?.vcards?.map((item) => item.url);
      return {
        ...state,
        addVcardsStatus: "success",
        vCards: vCardsUrls,
        vCardsData: isNotAnEmptyArray(action?.payload?.vcards)
          ? [...state.vCardsData, ...action?.payload?.vcards]
          : state.vCardsData,
      };

    case "THREADS/ADD_VCARDS.ERROR":
      return {
        ...state,
        addVcardsStatus: "error",
      };

    case "THREADS/ADD_VCARDS.CLEAR":
      return {
        ...state,
        addVcardsStatus: null,
        vCards: [],
      };

    case "THREADS/SET_ONE_TO_ONE_CALLING_ID": {
      return {
        ...state,
        voiceOneToOneCallingId: action.callingId,
      };
    }

    case "THREADS/SET_AUTO_CALLING_FROM_NUMBER": {
      return {
        ...state,
        autoCallingFromNumber: action.callingNumber,
      };
    }

    default:
      return state;
  }
}
