import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import queryString from "query-string";
import stringify from "qs-stringify";
import { store } from "../store";
import { deleteRequest, postRequest, putRequest } from "../utils/requests";

export function checkTemplateName(companyId, name) {
  return function (dispatch) {
    dispatch({
      type: "BROADCASTS/CHECK_TEMPLATE_NAME",
      name,
    });

    const url = "broadcasts/check-template-name.json";
    const config = {
      ...AxiosConfig.getAuthConfig(),
      params: { companyId, name },
    };
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "BROADCASTS/CHECK_TEMPLATE_NAME.SUCCESS",
          name,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/CHECK_TEMPLATE_NAME.ERROR",
          name,
          payload: error,
        });
      });
  };
}

export function clearCheckTemplateName() {
  return {
    type: "BROADCASTS/CHECK_TEMPLATE_NAME.CLEAR",
  };
}

export function estimateBroadcastPrice(
  companyId,
  groupId,
  contactId,
  messageType,
  textMessage,
  audioUploadId,
  userCallMeRecordingId,
  voicePause,
  voiceRepeat,
  voiceMachineDetection,
  isCallScreeningGreeting,
  callScreeningGreeting
) {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/ESTIMATE_PRICE" });

    const data = Object.assign(
      {},
      {
        companyId,
        groupId,
        contactId,
        messageType,
        textMessage,
        audioUploadId,
        userCallMeRecordingId,
        voicePause,
        voiceRepeat,
        voiceMachineDetection,
        isCallScreeningGreeting,
        callScreeningGreeting,
      }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "broadcasts/estimate-price.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "BROADCASTS/ESTIMATE_PRICE.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/ESTIMATE_PRICE.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearEstimateBroadcastPrice() {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/ESTIMATE_PRICE.CLEAR" });
  };
}

export function loadTtsPrice(companyId) {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/TTS_PRICE" });

    const url = `${AxiosConfig.getEndpointAddress()}broadcasts/tts-price.json?companyId=${companyId}`;
    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/TTS_PRICE.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/TTS_PRICE.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function addBroadcast(companyId, broadcastData) {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/ADD" });

    const data = Object.assign({}, broadcastData, { companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "broadcasts.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "BROADCASTS/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddBroadcast() {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/ADD.CLEAR" });
  };
}

export function editBroadcast(companyId, scheduleMessageid, broadcastData) {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/EDIT" });

    const data = Object.assign({}, broadcastData, { companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          `broadcasts/${scheduleMessageid}.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "BROADCASTS/EDIT.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/EDIT.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearEditBroadcast() {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/EDIT.CLEAR" });
  };
}

export function fetchLanguages() {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/LANGUAGES" });

    const url = "broadcasts/languages.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/LANGUAGES.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/LANGUAGES.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchTemplates(
  companyId,
  page = 1,
  query = "",
  sourceType = null,
  voiceLanguage = null,
  orderBy = ""
) {
  return function (dispatch) {
    dispatch({
      type: "BROADCASTS/TEMPLATES",
      page,
      query,
      sourceType,
      voiceLanguage,
    });

    const url =
      "broadcasts/templates.json?" +
      queryString.stringify({
        companyId,
        page,
        query,
        sourceType,
        voiceLanguage,
        orderBy,
      });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/TEMPLATES.SUCCESS",
          payload: response.data,
          userTimeZoneOffset: store.getState().users.loggedUser.timezone_offset,
          page,
          query,
          sourceType,
          voiceLanguage,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/TEMPLATES.ERROR",
          payload: error,
          page,
          query,
          sourceType,
          voiceLanguage,
        });
      });
  };
}

export function clearTemplates() {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/TEMPLATES.CLEAR" });
  };
}

export function fetchScheduleMessage(companyId, scheduleMessageId) {
  return function (dispatch) {
    dispatch({
      type: "BROADCASTS/FETCH_SCHEDULE_MESSAGE",
      scheduleMessageId,
    });

    const url = `${AxiosConfig.getEndpointAddress()}broadcasts/view-schedule-message/${scheduleMessageId}.json?companyId=${companyId}`;
    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/FETCH_SCHEDULE_MESSAGE.SUCCESS",
          scheduleMessageId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/FETCH_SCHEDULE_MESSAGE.ERROR",
          scheduleMessageId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearScheduleMessage() {
  return function (dispatch) {
    dispatch({ type: "BROADCASTS/FETCH_SCHEDULE_MESSAGE.CLEAR" });
  };
}

export function fetchHistory(companyId, page = 1, query = "") {
  return function (dispatch) {
    dispatch({
      type: "BROADCASTS/HISTORY",
      page,
      query,
    });

    const url =
      "broadcasts/history.json?" +
      queryString.stringify({
        companyId,
        page,
        query,
      });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/HISTORY.SUCCESS",
          payload: response.data,
          userTimeZoneOffset: store.getState().users.loggedUser.timezone_offset,
          page,
          query,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BROADCASTS/HISTORY.ERROR",
          payload: error,
          page,
          query,
        });
      });
  };
}

export function updateVoiceTemplate(body) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "BROADCASTS/ADD_TEMPLATE",
      "broadcasts/add-template.json",
      body
    );
  };
}

export function clearUpdateVoiceTemplate() {
  return (dispatch) =>
    dispatch({
      type: "BROADCASTS/ADD_TEMPLATE.CLEAR",
    });
}

export function editVoiceTemplate(id, index, body) {
  return (dispatch) => {
    putRequest(
      dispatch,
      "BROADCASTS/EDIT_TEMPLATE",
      `broadcasts/edit-template/${id}.json`,
      body,
      {
        successDispatchData: {
          index,
        },
      }
    );
  };
}

export function clearEditVoiceTemplate() {
  return (dispatch) =>
    dispatch({
      type: "BROADCASTS/EDIT_TEMPLATE.CLEAR",
    });
}

export function deleteVoiceTemplate(id, index, params) {
  return (dispatch) => {
    deleteRequest(
      dispatch,
      "BROADCASTS/DELETE_TEMPLATE",
      `broadcasts/delete-template/${id}.json`,
      {
        axiosConfig: {
          params,
        },
        successDispatchData: {
          index,
        },
      }
    );
  };
}

export const fetchBroadcastsCount = (companyId, filters = []) => {
  return (dispatch) => {
    dispatch({
      type: "BROADCASTS/COUNT",
    });

    const concatenatedFilters = filters.length ? filters?.join(", ") : null;

    const url =
      "broadcasts/counts.json?" +
      queryString.stringify({
        companyId,
        filters: concatenatedFilters,
      });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/COUNT.SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "BROADCASTS/COUNT.ERROR",
          payload: error,
        });
      });
  };
};

export function clearDeleteVoiceTemplate() {
  return (dispatch) =>
    dispatch({
      type: "BROADCASTS/DELETE_TEMPLATE.CLEAR",
    });
}

export const fetchFilteredBroadcasts = (
  companyId,
  page = 1,
  orderBy,
  query = "",
  filters = []
  // filtersAdv = [],
) => {
  return function (dispatch) {
    dispatch({
      type: "BROADCASTS/FETCH_FILTERED",
      companyId,
      query,
      page,
    });

    const concatenatedFilters = filters.length ? filters?.join(", ") : null;

    const url =
      "broadcasts/all.json?" +
      stringify({
        companyId,
        page,
        query,
        filters: concatenatedFilters,
        orderBy,
        // filtersAdv,
      });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BROADCASTS/FETCH_FILTERED.SUCCESS",
          payload: response.data,
          page,
          filters,
          // filtersAdv,
        });
      })
      .catch((error) => {
        dispatch({
          type: "BROADCASTS/FETCH_FILTERED.ERROR",
          payload: error,
          page,
          query,
        });
      });
  };
};

// Advance filter
// export function setBroadcastsAdvancedFilter(filter) {
//   return function (dispatch) {
//     dispatch({
//       type: "BROADCASTS/ADVANCE_FILTER",
//       filter,
//     });
//   };
// }
