import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export function fetchCountries() {
  return function (dispatch) {
    dispatch({ type: "COUNTRIES/FETCH" });

    const url = "countries.json";
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "COUNTRIES/FETCH.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COUNTRIES/FETCH.ERROR",
          payload: error,
        });
      });
  };
}
