import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
// new icons
import { ReactComponent as CloseSvg } from "../../assets/img/icons-new/general/close/close-gray.svg";

import { useComponentWillMount } from "../../utils/hooks/useComponentWillMount";
import ButtonWithSpinner from "../hub/HelperComponents/ButtonWithSpinner";
import PlusMinusCheckbox from "../hub/HelperComponents/PlusMinusCheckbox";

const SimpleConfirmationModal = (props) => {
  const {
    // functions
    shakeModal,
    closeModal,
    onClickConfirm,
    // props
    show,
    title,
    description,
    loadingStatus,
    confirmationButtonText,
    id,
    showCheckbox,
    confirmBtnClassName,
  } = props;
  const [checked, setChecked] = useState(false);

  const onCheckBox = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  useComponentWillMount(() => {
    if (!title && !description) {
      closeModal();
    }
  });

  return (
    <div
      className={`modal fade inbox-modal confirm-group-message simple-confirm-modal  ${
        show ? " show" : ""
      }`}
      style={show ? { display: "block" } : {}}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
    >
      <div
        className={`modal-backdrop fade ${show ? " show" : ""}`}
        onClick={shakeModal}
      />
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        style={{ zIndex: 100 }}
      >
        <div className="modal-content themed-modal">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <CloseSvg />
          </button>
          <div className="modal-body">
            <div className="title mb-5">
              <h4>{title}</h4>
            </div>
            <div className="content caller-info">
              {showCheckbox && (
                <div className="mt-1 mr-3">
                  <PlusMinusCheckbox checked={checked} onChange={onCheckBox} />
                </div>
              )}
              <p className={showCheckbox ? "text-container" : ""}>
                {description}{" "}
              </p>
            </div>
            <div className="modal-footer mt-40">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={closeModal}
              >
                Cancel
              </button>
              <ButtonWithSpinner
                onClick={onClickConfirm}
                disabled={
                  loadingStatus === "loading" || (showCheckbox && !checked)
                }
                loading={loadingStatus === "loading"}
                className={confirmBtnClassName}
              >
                {confirmationButtonText}
              </ButtonWithSpinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleConfirmationModal.propTypes = {
  // functions
  shakeModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  // props
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.any,
  loadingStatus: PropTypes.string.isRequired,
  confirmationButtonText: PropTypes.string.isRequired,
  showCheckbox: PropTypes.bool,
  confirmBtnClassName: PropTypes.string,
};

SimpleConfirmationModal.defaultProps = {
  showCheckbox: false,
  confirmBtnClassName: "",
};

export default SimpleConfirmationModal;
