import React from "react";
import PropTypes from "prop-types";
import NotificationBanner from "../Menus/shared/NotificationBanner";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: 0,
    width: "100%",
    left: 0,
    zIndex: 1000,
    borderRadius: 0,
    height: 27,
    "@media (max-width: 992px)": {
      height: "auto",
      fontSize: 11,
    },
  },
}));

const NotificationBannerOverlayApp = (props) => {
  const { text, buttonText, onClickButton, onClickClose, color } = props;

  const classes = useStyles();

  return (
    <NotificationBanner
      text={text}
      buttonText={buttonText}
      onClickButton={onClickButton}
      onClickClose={onClickClose}
      color={color}
      className={classes.root}
      containerClassName="d-flex justify-content-center align-items-center mx-auto"
      withMargin={false}
    />
  );
};

NotificationBannerOverlayApp.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  onClickButton: PropTypes.func,
  onClickClose: PropTypes.func,
  color: PropTypes.oneOf(["blue", "yellow", "red"]),
};

export default NotificationBannerOverlayApp;
