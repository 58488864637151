import React from "react";
import PropTypes from "prop-types";

const Testimonials = (props) => {
  const { name, title, text, imageUrl } = props;
  return (
    <>
      <div className="banner-carousel-testimonial">
        <img className="avatar" src={imageUrl} alt="" />
        <p>
          {text}
          <div className="name">{name}</div>
          <div className="title"> {title}</div>
        </p>
      </div>
    </>
  );
};

Testimonials.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
export default Testimonials;
