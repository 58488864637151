import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TooltipWhenOverflow from "../../hub/HelperComponents/TooltipWhenOverflow";
import SettingsAvatar from "../../Menus/shared/SettingsAvatar";
import { getImageFileURL } from "../../../helpers";

const CompanyDetails = (props) => {
  const { companyInfo, companyName, avatarSize } = props;
  const [imageSrc, setImageSrc] = useState(null);

  const preview = async (file) => {
    const url = await getImageFileURL(file);
    setImageSrc(url);
  };

  useEffect(() => {
    // Parse image
    if (imageSrc && imageSrc?.name !== companyInfo?.name) {
      preview(companyInfo);
    }
  }, [companyInfo, imageSrc]);

  const getImage = (src) => {
    if (src?.name) {
      if (imageSrc) {
        return imageSrc;
      } else {
        preview(src);
      }
    } else {
      return src;
    }
  };

  return (
    <a className="company-details" href="#modal-choose-organization">
      <span className="d-flex align-items-center">
        <SettingsAvatar
          avatarUrl={getImage(companyInfo)}
          isOrganization
          size={avatarSize}
        />
        <TooltipWhenOverflow
          className={"ml-2"}
          maxWidth={190}
          text={companyName}
        >
          <span>{companyName}</span>
        </TooltipWhenOverflow>
      </span>
      <i className="fa fa-chevron-right arrow-icon"></i>
    </a>
  );
};

CompanyDetails.defaultProps = {
  avatarSize: 20,
};

CompanyDetails.propTypes = {
  companyName: PropTypes.string.isRequired,
  avatarSize: PropTypes.number,
  companyInfo: PropTypes.object,
};

export default CompanyDetails;
