import React, { useEffect } from "react";
import { ReactComponent as HubIcon } from "../assets/img/icons-new/pastorsline/pastorsline-beta/pastorsline-beta-large-white.svg";
import { isReactNativeApp } from "../helpers";
import { getReloadCount, setReloadCountOnLocalStorage } from "../utils/auth";
import { APP_LOADING, DEFAULT_APP_VERSION } from "../utils/constants";
import useUsersStore from "../utils/hooks/ReduxHooks/userStore";
import useMainStore from "../utils/hooks/ReduxHooks/mainStore";

const AppLoading = () => {
  const { users } = useUsersStore();
  const { toggleShowErrorModal, main } = useMainStore();
  const { loggedUser } = users;
  const { showSessionExpiredModal, showErrorModal } = main;
  const reloadCount = getReloadCount();

  useEffect(() => {
    // This useEffect will open the error modal if the user is not logged in after 40 seconds
    let timer;
    timer = setTimeout(() => {
      if (!showSessionExpiredModal && !showErrorModal) {
        setReloadCountOnLocalStorage(2);
        toggleShowErrorModal(true);
      } else {
        clearTimeout(timer);
      }
    }, 40000);

    return () => clearTimeout(timer);
  }, [showSessionExpiredModal, toggleShowErrorModal, showErrorModal]);

  useEffect(() => {
    if (isReactNativeApp() && loggedUser?.default_app_version) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: DEFAULT_APP_VERSION,
          defaultVersion: loggedUser?.default_app_version,
        })
      );
    }
  }, [loggedUser]);

  useEffect(() => {
    if (isReactNativeApp()) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: APP_LOADING,
          value: true,
        })
      );
    }

    return () => {
      if (isReactNativeApp()) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: APP_LOADING,
            value: false,
          })
        );
      }
    };
  }, []);

  return (
    <div className="app-loading-container">
      <div className="app-loading-inner-wrapper">
        <HubIcon width="48" height="40" alt="" />
        <p className="d-block  mt-2">The HUB | PastorsLine 3.0</p>
        <div
          className="clear-icon spinner-border text-white app-loading-spinner"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        {Boolean(reloadCount) && reloadCount === 1 && (
          <p className="d-block mt-3">Taking longer than usual...</p>
        )}
      </div>
    </div>
  );
};

export default AppLoading;
