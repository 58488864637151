import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../modals/CustomModal";

const DeleteMessageConfirmationModal = (props) => {
  const { deleteMessageConfirmed, deleteMessageCancel, loading } = props;
  return (
    <CustomModal
      show={true}
      title={"Deleting Message"}
      dialogClassName="modal-dialog-centered"
      buttons={[
        {
          type: "delete",
          caption: "Delete",
          handler: deleteMessageConfirmed,
          loading,
          disabled: loading,
        },
        {
          type: "cancel",
          caption: "Cancel",
          handler: deleteMessageCancel,
        },
      ]}
      closeModal={deleteMessageCancel}
    >
      <p className="mb-3 text-left" style={{ fontSize: "initial" }}>
        Are you sure you want to delete this message?
      </p>
    </CustomModal>
  );
};

DeleteMessageConfirmationModal.propTypes = {
  deleteMessageConfirmed: PropTypes.func.isRequired,
  deleteMessageCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

DeleteMessageConfirmationModal.defaultProps = {
  loading: false,
};

export default DeleteMessageConfirmationModal;
