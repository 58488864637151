import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";

const options = {
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0,
    },
  },
  scales: {
    yAxes: [
      {
        display: false,
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false, //this will remove only the label
        },
      },
    ],
  },
};

const SimpleEmptyLineChart = (props) => {
  const { color, width, height, dataItems, dataLabels } = props;

  const data = {
    labels: dataLabels,
    datasets: [
      {
        data: dataItems,
        fill: false,
        borderColor: color === "red" ? "#FF4B75" : "#00E38C",
      },
    ],
  };

  return (
    <div style={{ width: width, height: height }}>
      <Line data={data} options={options} width={width} height={height} />
    </div>
  );
};

SimpleEmptyLineChart.propTypes = {
  color: PropTypes.oneOf(["red", "green"]),
  width: PropTypes.number,
  height: PropTypes.number,
  dataLabels: PropTypes.array,
  dataItems: PropTypes.array,
};

SimpleEmptyLineChart.defaultProps = {
  color: "red",
};

export default SimpleEmptyLineChart;
