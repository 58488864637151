import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as messageTemplatesActions from "../../../actions/messageTemplates";

export function useMessageTemplatesStore() {
  const dispatch = useDispatch();
  const _messageTemplates = useSelector((store) => store.messageTemplates);

  const clearInsertMessageTemplate = useCallback(
    async () =>
      await dispatch(messageTemplatesActions.clearInsertMessageTemplate()),
    [dispatch]
  );

  return {
    messageTemplates: _messageTemplates,
    clearInsertMessageTemplate,
  };
}

export default useMessageTemplatesStore;
