import { arrayToUrl, getRequest } from "../../utils/requests";

export function fetchLogs(data, logReportTypes = []) {
  let url;
  if (logReportTypes.length) {
    url = arrayToUrl(`analytics/logs.json?`, logReportTypes, "type");
  } else {
    url = "analytics/logs.json";
  }
  return (dispatch) =>
    getRequest(dispatch, "ANALYTICS/FETCH_LOGS", url, {
      axiosConfig: {
        params: {
          ...data,
        },
      },
      requestDispatchData: data,
      errorDispatchData: data,
      successDispatchData: data,
    });
}

export function setLogsEmbedURL(embedURL) {
  return function (dispatch) {
    dispatch({ type: "LOGS/SET_LOGS_EMBED_URL", embedURL });
  };
}

export function setStatisticsEmbedUrl(embedURL) {
  return function (dispatch) {
    dispatch({ type: "LOGS/SET_STATISTICS_EMBED_URL", embedURL });
  };
}
