import React from "react";
import PropTypes from "prop-types";
import ModalsManager from "../../ModalsManager";
import ChooseSenderNumberModal from "../../modals/ChooseSenderNumberModal";
import { withRouter } from "react-router-dom";
import ConfirmRestrictedPhraseModal from "../../modals/ConfirmRestrictedPhraseModal";
import ConfirmLinkImageWarningModal from "../../modals/ConfirmLinkImageWarningModal";
import ConfirmDifferentSenderModal from "../../modals/ConfirmDifferentSenderModal";
import { connect } from "react-redux";
import ConfirmAlreadyScheduledModal from "../../modals/ConfirmAlreadyScheduledModal";
import AccountIssueModal from "../../modals/AccountIssueModal/AccountIssueModal";
import ContactStatusInfoModal from "../../modals/ContactStatusInfoModal";
import InvalidTimeModal from "../../modals/InvalidTimeModal";
import { GROUP } from "../../../utils/constants";
import ScheduleMessageModal from "../../modals/ScheduleMessageModal";
import ShowUnicodeCharactersModal from "../../modals/ShowUnicodeCharactersModal/ShowUnicodeCharactersModal";

function mapStateToProps(store, ownProps) {
  const currentCompany = store.companies.currentCompany;

  return {
    senderNumber: store.numbers.senderNumber,
    sendErrorData: store.messages.sendErrorData,
    organizationSettingsAvailable:
      currentCompany.is_main_owner || currentCompany.is_admin_plus,
  };
}

const MessageFormModalManager = (props) => {
  const {
    // Parent props
    modal,
    modalClose,
    sendOn,
    recurringRule,
    lastSenderNumber,
    shortCodeNumber,
    isBlocked,
    isUnsubscribed,
    unsubedByUserId,
    contactFullName,
    contactFullNumber,
    handleSendDifferentSender,
    handleSendRestricted,
    handleSendLinkImageWarning,
    handleSwitchToShortcode,
    handleSendAlreadyScheduled,
    invalidTimeModal,
    onCloseInvalidTimeModal,
    handleScheduleMessage,
    messageText,
    messageSignature,
    characters,

    // Redux props
    senderNumber,
    sendErrorData,
    organizationSettingsAvailable,
    // Router
    match,
  } = props;

  const { threadType } = match.params;

  const ConfirmDifferentSenderModalEnh = (props) => (
    <ConfirmDifferentSenderModal
      {...props}
      isGroup={threadType === GROUP}
      senderNumber={senderNumber ? senderNumber.number : null}
      lastSenderNumber={lastSenderNumber}
      handleConfirm={handleSendDifferentSender}
      schedule={sendOn ? true : false}
    />
  );

  const ChooseSenderNumberModalEnh = (props) => (
    <ChooseSenderNumberModal
      {...props}
      toGroup={threadType === GROUP}
      title={"Sending From"}
    />
  );

  const ConfirmRestrictedPhraseModalEnh = (props) => (
    <ConfirmRestrictedPhraseModal
      {...props}
      restrictedPhrases={
        sendErrorData && sendErrorData.restrictedPhrases
          ? sendErrorData.restrictedPhrases
          : []
      }
      handleConfirm={handleSendRestricted}
      schedule={sendOn ? true : false}
    />
  );

  const ConfirmLinkImageWarningModalEnh = (props) => (
    <ConfirmLinkImageWarningModal
      {...props}
      handleConfirm={handleSendLinkImageWarning}
      handleSwitchToShortcode={handleSwitchToShortcode}
      schedule={sendOn ? true : false}
      shortCodeAvailable={shortCodeNumber && shortCodeNumber.length > 0}
      organizationSettingsAvailable={organizationSettingsAvailable}
    />
  );

  const confirmAlreadyScheduledModalEnh = (props) => (
    <ConfirmAlreadyScheduledModal
      {...props}
      handleConfirm={handleSendAlreadyScheduled}
    />
  );

  // to see when this should open
  const AccountIssueModalEnh = (props) => {
    return <AccountIssueModal {...props} withButtons={false} />;
  };

  const ContactStatusInfoModalModalEnh = (props) => (
    <ContactStatusInfoModal
      {...props}
      isBlocked={isBlocked}
      isUnsubscribed={isUnsubscribed}
      unsubedByUserId={unsubedByUserId}
      contactFullName={contactFullName}
      contactFullNumber={contactFullNumber}
    />
  );

  const ShowUnicodeCharactersModalEnh = (props) => (
    <ShowUnicodeCharactersModal
      {...props}
      messageText={messageText}
      characters={characters}
      messageSignature={messageSignature}
    />
  );

  return (
    <>
      <ModalsManager
        modals={{
          "choose-sender-number": ChooseSenderNumberModalEnh,
          "confirm-restricted-phrase": ConfirmRestrictedPhraseModalEnh,
          "confirm-link-image-warning": ConfirmLinkImageWarningModalEnh,
          "confirm-different-sender": ConfirmDifferentSenderModalEnh,
          "confirm-already-scheduled": confirmAlreadyScheduledModalEnh,
          "account-issue": AccountIssueModalEnh,
          "status-info": ContactStatusInfoModalModalEnh,
          "show-unicode-characters": ShowUnicodeCharactersModalEnh,
        }}
        modal={modal}
        close={modalClose}
      />
      {modal === "schedule-message" && (
        <ScheduleMessageModal
          show={modal === "schedule-message"}
          closeModal={modalClose}
          sendOn={sendOn}
          recurringRule={recurringRule}
          onSubmit={handleScheduleMessage}
        />
      )}
      <InvalidTimeModal
        show={invalidTimeModal}
        closeModal={onCloseInvalidTimeModal}
      />
    </>
  );
};

MessageFormModalManager.propTypes = {
  modal: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
  handleSendDifferentSender: PropTypes.func.isRequired,
  isGroupThread: PropTypes.bool.isRequired,
  messageText: PropTypes.string,
  messageSignature: PropTypes.string,
};

MessageFormModalManager.defaultProps = {
  modal: "",
  essageText: "",
  messageSignature: "",
};

export default connect(mapStateToProps)(withRouter(MessageFormModalManager));
