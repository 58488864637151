import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../Helpers/ModalWrapper";
import ButtonWithSpinner from "../../hub/HelperComponents/ButtonWithSpinner";
import { clearPostDashboard, postDashboard } from "../../../actions/dashboard";
import { connect } from "react-redux";
import { transformIdToTitle } from "./modalDashboardHelpers";

function mapStateToProps(store, ownProps) {
  return {
    dashboardData: store.dashboard.dashboardData,
    dashboardPostStatus: store.dashboard.dashboardPostStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postDashboard: (body) => dispatch(postDashboard(body)),
    clearPostDashboard: () => dispatch(clearPostDashboard()),
  };
}

const RemoveWidget = (props) => {
  const {
    show,
    closeModal,
    widgetId,
    // Redux props
    dashboardData,
    dashboardPostStatus,
    // Redux func
    postDashboard,
    clearPostDashboard,
  } = props;

  useEffect(() => {
    if (dashboardPostStatus === "success") {
      clearPostDashboard();
      closeModal();
    }
  }, [clearPostDashboard, closeModal, dashboardPostStatus]);

  return (
    <ModalWrapper
      id="RemoveWidget"
      show={show}
      closeModal={closeModal}
      className="remove-widget-modal"
    >
      <div className="remove-widget-container">
        <h1>Remove Widget</h1>
        <p>Do you want to remove {transformIdToTitle(widgetId)} widget?</p>
        <div className="d-flex justify-content-between">
          <button className="btn btn-outline-primary">Cancel</button>
          <ButtonWithSpinner
            disabled={dashboardPostStatus === "loading"}
            loading={dashboardPostStatus === "loading"}
            onClick={() => {
              const updatedDashboard = (dashboardData ?? []).filter(
                (item) => item.i !== widgetId
              );

              postDashboard({
                dashboard_data: JSON.stringify(updatedDashboard),
              });
            }}
          >
            Remove
          </ButtonWithSpinner>
        </div>
      </div>
    </ModalWrapper>
  );
};

RemoveWidget.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  widgetId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveWidget);
