import React from "react";
import { connect } from "react-redux";
import { formatPhoneNumber } from "../../helpers";
import { GROUP } from "../../utils/constants";
import CustomModal from "./CustomModal";

function mapStateToProps(store, ownProps) {
  let senderName = "";
  let lastSenderName = "";

  if (store.numbers.numbers) {
    for (let i = 0; i < store.numbers.numbers.length; i++) {
      if (store.numbers.numbers[i].number === ownProps.senderNumber) {
        senderName = store.numbers.numbers[i].name
          ? store.numbers.numbers[i].name
          : store.numbers.numbers[i].nickname;
      }
      if (store.numbers.numbers[i].number === ownProps.lastSenderNumber) {
        lastSenderName = store.numbers.numbers[i].name
          ? store.numbers.numbers[i].name
          : store.numbers.numbers[i].nickname;
      }
    }
  }

  return {
    senderName: senderName,
    lastSenderName: lastSenderName,
    dataForModal: store.main.dataForModal,
  };
}

class ConfirmDifferentSenderModal extends React.PureComponent {
  render() {
    const { senderName, lastSenderName, closeModal, dataForModal } = this.props;

    const senderNumber = this.props.senderNumber || dataForModal?.senderNumber;
    const lastSenderNumber =
      this.props.lastSenderNumber || dataForModal?.lastSenderNumber;
    const isGroup = this.props.isGroup || dataForModal?.isGroup;
    const handleConfirm =
      this.props.handleConfirm || dataForModal?.handleConfirm;
    const schedule = this.props.schedule || dataForModal?.schedule;

    return (
      <CustomModal
        {...this.props}
        dialogClassName="modal-dialog-centered"
        title={"Sending as " + senderName}
        buttons={[
          {
            type: "confirm",
            caption: (schedule ? "Schedule" : "Send") + " Anyway",
            handler: handleConfirm,
          },
          {
            type: "cancel",
            caption: "Cancel",
            handler: closeModal,
          },
        ]}
      >
        <p
          className={"mb-3 text-left modal-body"}
          style={{ fontSize: "initial" }}
        >
          You are replying to this {isGroup ? GROUP : "user"} using the{" "}
          {senderName}, &nbsp;
          {formatPhoneNumber(senderNumber)} number, which is different from the{" "}
          {lastSenderName}, &nbsp;
          {formatPhoneNumber(lastSenderNumber)}, last used in this conversation.
        </p>
      </CustomModal>
    );
  }
}

export default connect(mapStateToProps)(ConfirmDifferentSenderModal);
