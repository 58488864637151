import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseWhiteSvg } from "../../../assets/img/icons/close-white.svg";
import { ReactComponent as CloseGreySvg } from "../../../assets/img/icons/close-grey.svg";
import { ReactComponent as RoundedExclamationWhiteSvg } from "../../../assets/img/icons-new/number-status/show-info/show-info-white.svg";
import { ReactComponent as RoundedExclamationGraySvg } from "../../../assets/img/icons-new/number-status/show-info/show-info-gray.svg";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";

const NotificationBanner = (props) => {
  const {
    text,
    buttonText,
    onClickButton,
    withMargin,
    onClickClose,
    color,
    className,
    containerClassName,
    hasExclamationIcon,
  } = props;
  const withWhiteSvg = useMemo(
    () => color === "blue" || color === "red",
    [color]
  );
  const withGreySvg = useMemo(() => color === "yellow", [color]);
  const breakpoint = useBreakpoint();
  const iconsSize = useMemo(
    () => (isMdOrBelowBreakpoint(breakpoint) ? "20px" : "23px"),
    [breakpoint]
  );

  const renderCloseIcon = useMemo(() => {
    if (onClickClose) {
      if (withWhiteSvg) {
        return (
          <CloseWhiteSvg
            width={11}
            height={11}
            className="close-notification-button"
            onClick={onClickClose}
          />
        );
      } else if (withGreySvg) {
        return (
          <CloseGreySvg
            width={11}
            height={11}
            className="close-notification-button"
            onClick={onClickClose}
          />
        );
      }
    }
  }, [onClickClose, withGreySvg, withWhiteSvg]);

  const renderExclamationIcon = useMemo(() => {
    if (hasExclamationIcon) {
      if (withWhiteSvg) {
        return (
          <RoundedExclamationWhiteSvg
            width={iconsSize}
            height={iconsSize}
            className="mr-2"
          />
        );
      } else if (withGreySvg) {
        return (
          <RoundedExclamationGraySvg
            width={iconsSize}
            height={iconsSize}
            className="mr-2"
          />
        );
      }
    }
  }, [hasExclamationIcon, withGreySvg, withWhiteSvg, iconsSize]);

  return (
    <div
      className={`notification-banner-container ${
        withMargin ? "with-margin" : ""
      } ${color} ${className}`}
    >
      {renderExclamationIcon}
      <div className={containerClassName}>
        {text}
        <span
          className="action-button ml-1 cursor-pointer"
          onClick={onClickButton}
        >
          {buttonText}
        </span>
      </div>
      {renderCloseIcon}
    </div>
  );
};

NotificationBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  onClickButton: PropTypes.func,
  withMargin: PropTypes.bool,
  onClickClose: PropTypes.func,
  color: PropTypes.oneOf(["blue", "yellow", "red"]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  hasExclamationIcon: PropTypes.bool,
};

NotificationBanner.defaultProps = {
  withMargin: true,
  color: "blue",
  className: "",
  containerClassName: "d-flex align-items-center",
  hasExclamationIcon: false,
};

export default NotificationBanner;
