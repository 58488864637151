const initialState = {
  idAddModalOpened: false,
  // fetch media resource state
  imageSource: [],
  videoSource: [],
  fetchMediaResourceStatus: "",
  fetchMediaResourcePage: null,
  fetchMediaResourceLoadingMoreStatus: null,
  fetchMediaResourceLoadedAll: null,

  // add media resource state
  addMediaResourceStatus: "",

  // Delete media resources
  deleteMediaResourcesStatus: "",

  // Edit media resource
  editMediaResourceStatus: "",

  // Video thumbnail state
  fetchVideoThumbnailStatus: "",
  videoThumbnails: {},
};

export default function media(state = initialState, action) {
  switch (action.type) {
    case "MEDIA/FETCH_MEDIA_RESOURCES": {
      return {
        ...state,
        fetchMediaResourceLoadingMoreStatus:
          action?.params?.page > 1 ? "loading" : null,
        fetchMediaResourceStatus: "loading",
      };
    }

    case "MEDIA/FETCH_MEDIA_RESOURCES.SUCCESS": {
      let imageMediaSources = [];
      let videoMediaSources = [];
      const sourceInState =
        action?.params?.type === "image"
          ? state.imageSource
          : state.videoSource;
      if (action?.params?.page === 1 || !sourceInState) {
        if (action?.params?.type === "image") {
          imageMediaSources = action.payload?.media_resources || [];
        } else {
          videoMediaSources = action.payload?.media_resources || [];
        }
      } else {
        if (action?.params?.type === "image") {
          imageMediaSources = [
            ...state.imageSource,
            ...action.payload?.media_resources,
          ];
        } else {
          videoMediaSources = [
            ...state.videoSource,
            ...action.payload?.media_resources,
          ];
        }
      }
      return {
        ...state,
        fetchMediaResourceLoadingMoreStatus:
          action?.params?.page > 1 ? "success" : null,
        fetchMediaResourceStatus: "success",
        imageSource:
          action?.params?.type === "image"
            ? imageMediaSources
            : state.imageSource,
        videoSource:
          action?.params?.type === "video"
            ? videoMediaSources
            : state.videoSource,
        fetchMediaResourcePage: action.params?.page,
        fetchMediaResourceLoadedAll:
          action.payload?.media_resources?.length === 0,
      };
    }

    case "MEDIA/FETCH_MEDIA_RESOURCES.ERROR": {
      return {
        ...state,
        fetchMediaResourcePage: null,
        fetchMediaResourceLoadingMoreStatus:
          action?.params?.page > 1 ? "error" : null,
        fetchMediaResourceStatus: "error",
      };
    }

    case "MEDIA/FETCH_MEDIA_RESOURCES.CLEAR": {
      return {
        ...state,
        fetchMediaResourceStatus: "",
      };
    }

    case "MEDIA/ADD_MEDIA_RESOURCES": {
      return {
        ...state,
        addMediaResourceStatus: "loading",
      };
    }

    case "MEDIA/ADD_MEDIA_RESOURCES.SUCCESS": {
      let mediaSource = {};
      let addedThumbnail = {};
      if (
        action?.payload?.media_resources &&
        Array.isArray(action?.payload?.media_resources) &&
        Boolean(action?.payload?.media_resources)
      ) {
        mediaSource = action?.payload?.media_resources[0];
      }
      if (mediaSource?.thumbnail_url) {
        addedThumbnail = Object.assign(addedThumbnail, {
          [mediaSource?.handle]: mediaSource?.thumbnail_url,
        });
      }
      return {
        ...state,
        addMediaResourceStatus: "success",
        videoThumbnails: Object.assign(state.videoThumbnails, addedThumbnail),
      };
    }

    case "MEDIA/ADD_MEDIA_RESOURCES.ERROR": {
      return {
        ...state,
        addMediaResourceStatus: "error",
      };
    }

    case "MEDIA/ADD_MEDIA_RESOURCES.CLEAR": {
      return {
        ...state,
        addMediaResourceStatus: "",
      };
    }

    case "MEDIA/DELETE_MEDIA_RESOURCES": {
      return {
        ...state,
        deleteMediaResourcesStatus: "loading",
      };
    }

    case "MEDIA/DELETE_MEDIA_RESOURCES.SUCCESS": {
      const cloneImageResources = [...state.imageSource];
      const cloneVideoResources = [...state.videoSource];
      const mediaResourceToFilter =
        action.mediaType === "video"
          ? cloneVideoResources
          : cloneImageResources;
      const filteredMediaResouce = mediaResourceToFilter.filter(
        (resource) => !action.ids.includes(resource.id)
      );
      return {
        ...state,
        deleteMediaResourcesStatus: "success",
        imageSource:
          action.mediaType === "image"
            ? filteredMediaResouce
            : state.imageSource,
        videoSource:
          action.mediaType === "video"
            ? filteredMediaResouce
            : state.videoSource,
      };
    }

    case "MEDIA/DELETE_MEDIA_RESOURCES.ERROR": {
      return {
        ...state,
        deleteMediaResourcesStatus: "error",
      };
    }

    case "MEDIA/DELETE_MEDIA_RESOURCES.CLEAR": {
      return {
        ...state,
        deleteMediaResourcesStatus: "",
      };
    }

    case "MEDIA/EDIT_MEDIA_RESOURCES": {
      return {
        ...state,
        editMediaResourceStatus: "loading",
      };
    }
    case "MEDIA/OPEN_ADD_VIDEO_MODAL": {
      return {
        ...state,
        idAddModalOpened: action.data,
      };
    }

    case "MEDIA/EDIT_MEDIA_RESOURCES.SUCCESS": {
      return {
        ...state,
        editMediaResourceStatus: "success",
      };
    }

    case "MEDIA/EDIT_MEDIA_RESOURCES.ERROR": {
      return {
        ...state,
        editMediaResourceStatus: "error",
        videoThumbnails: Object.assign(state.videoThumbnails, action?.handler),
      };
    }

    case "MEDIA/EDIT_MEDIA_RESOURCES.CLEAR": {
      return {
        ...state,
        editMediaResourceStatus: "",
      };
    }

    case "MEDIA/FETCH_VIDEO_THUMBNAIL": {
      return {
        ...state,
        fetchVideoThumbnailStatus: "loading",
      };
    }

    case "MEDIA/FETCH_VIDEO_THUMBNAIL.SUCCESS": {
      return {
        ...state,
        fetchVideoThumbnailStatus: "success",
        videoThumbnails: Object.assign(
          state.videoThumbnails,
          action?.payload?.data
        ),
      };
    }

    case "MEDIA/FETCH_VIDEO_THUMBNAIL.ERROR": {
      return {
        ...state,
        fetchVideoThumbnailStatus: "error",
        videoThumbnails: Object.assign(state.videoThumbnails, action?.handler),
      };
    }

    case "MEDIA/FETCH_VIDEO_THUMBNAIL.CLEAR": {
      return {
        ...state,
        fetchVideoThumbnailStatus: "",
      };
    }

    default:
      return state;
  }
}
