import React from "react";
import styles from "../assets/components/_tooltip.module.scss";

const Tooltip = (props) => {
  return (
    <div className={`${styles.container} ${props.active ? styles.active : ""}`}>
      {props.title}
      <div className={styles.border}></div>
    </div>
  );
};

export default Tooltip;
