import { isCordovaApp } from "../../../helpers";

const isCordova = isCordovaApp();
const RenderForCordova = (props) => {
  const { children } = props;

  if (!isCordova) {
    return null;
  }

  return children;
};

export default RenderForCordova;
