import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ThreadsActionsPopover from "./ThreadsActionsPopover";
import { toggleGroupFromList } from "../../actions/groups";
import ArchiveWorkflow from "../hub/ActionsWorkflows/Archive";
import CloseWorkflow from "../hub/ActionsWorkflows/Close";
import { connect } from "react-redux";
import { history } from "../../store";
import { useActionMenus } from "../../utils/hooks/useActionMenus";
import { getPermissionParsed } from "../../utils/settingsHelpers";

const mapDispatchToProps = (dispatch) => {
  return {
    saveIdsForModal: (selectedThreads) =>
      dispatch(toggleGroupFromList(true, selectedThreads)),
  };
};

const mapStateToProps = (store, ownProps) => {
  return {
    userPermissions: store.users.permissions,
  };
};

const SingleThreadActionsBlockClose = (props) => {
  const {
    // props
    contactData,
    group,
    showVerticalMenu,
    shouldRedirectUnblockAction,

    // Redux props
    userPermissions,

    // Redux func
    saveIdsForModal,
  } = props;

  const [archiveThis, setArchiveThis] = useState(false);
  const [closeThis, setCloseThis] = useState(false);
  const { isOpen, onClickMenu, toggle } = useActionMenus();

  const handleUnblockContact = useCallback(() => {
    const dataForModal = {
      groupIds: [],
      contactIds: contactData?.contacts_ids,
      duplicateContactIds:
        contactData?.contacts_ids.length > 1 ? [contactData.contacts_ids] : [],
    };
    if (shouldRedirectUnblockAction) {
      dataForModal["options"] = {
        redirectUrl: `/hub/people/contact/${contactData?.contacts_ids[0]}/about`,
      };
    }

    history.push(
      `#modal-unblock${
        dataForModal.duplicateContactIds.length > 0 ? "-duplicate" : ""
      }-contact`
    );
    saveIdsForModal(dataForModal);
  }, [contactData, shouldRedirectUnblockAction, saveIdsForModal]);

  const handleArchiveOrClose = useCallback(() => {
    setArchiveThis(true);
  }, []);

  const selectedThreads = contactData ? { [contactData.id]: contactData } : {};
  const selectedGroups = group ? { [group.id]: group } : {};

  return (
    <>
      <div className="lhs-archive-popover">
        <ThreadsActionsPopover
          showVerticalMenu={showVerticalMenu}
          id="lhs-blocked-closed-people-actions-popover"
          isOpen={isOpen}
          toggle={toggle}
          menuItems={
            <>
              {getPermissionParsed(
                userPermissions?.people,
                "block_unblock"
              ) && (
                <button onClick={onClickMenu(handleUnblockContact)}>
                  Unblock
                </button>
              )}
              <button
                className="marked-warning"
                onClick={onClickMenu(handleArchiveOrClose)}
              >
                Archive
              </button>
            </>
          }
        />
      </div>
      {closeThis && (
        <CloseWorkflow
          runWorkflow
          selectedThreads={group ? selectedGroups : selectedThreads}
          onCloseComplete={() => setCloseThis(false)}
          needRouting
          group={!!group}
          countThreads={1}
        />
      )}
      {archiveThis && (
        <ArchiveWorkflow
          confirmationModal
          runWorkflow
          selectedThreads={selectedThreads}
          selectedSubThreads={{}}
          selectedGroups={selectedGroups}
          onArchiveComplete={() => setArchiveThis(false)}
          needRouting
        />
      )}
    </>
  );
};

SingleThreadActionsBlockClose.propTypes = {
  contactData: PropTypes.objectOf(PropTypes.any),
  showVerticalMenu: PropTypes.bool,
  group: PropTypes.objectOf(PropTypes.any),
  shouldRedirectUnblockAction: PropTypes.bool,
};

SingleThreadActionsBlockClose.defaultProps = {
  showVerticalMenu: true,
  shouldRedirectUnblockAction: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleThreadActionsBlockClose);
