import {
  toggleSessionExpired,
  toggleSessionReplacedModal,
} from "./actions/main";
import { SESSION_STARTED } from "./utils/constants";

export default class BroadcastChannelConfig {
  static _socket = null;

  static channelId = null;

  static init(eventsCallback) {
    if (BroadcastChannelConfig._socket) {
      BroadcastChannelConfig._socket.close();
    }

    BroadcastChannelConfig.channelId = `broadcast-channel`;

    if (window.hasOwnProperty("BroadcastChannel")) {
      BroadcastChannelConfig._socket = new BroadcastChannel(
        BroadcastChannelConfig.channelId
      );
    } else {
      console.log("BROADCAST CHANNEL NOT SUPPORTED");
      return;
    }

    BroadcastChannelConfig._bindEvents(eventsCallback);
  }

  static _bindEvents(eventsCallback) {
    if (BroadcastChannelConfig._socket) {
      BroadcastChannelConfig._socket.onmessage = (event) => {
        if (
          event.data?.message === SESSION_STARTED &&
          Number(window.appSharedSession) !==
            Number(event.data?.appSharedSession)
        ) {
          eventsCallback([
            toggleSessionExpired(false),
            toggleSessionReplacedModal(true),
          ]);
        }
      };
    }
  }

  static postBroadcastMessage(message) {
    if (BroadcastChannelConfig._socket) {
      BroadcastChannelConfig._socket.postMessage({
        message,
        appSharedSession: window.appSharedSession,
      });
    }
  }
}
