import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import LaunchIntercom from "../hub/HelperComponents/LaunchIntercom";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import PropTypes from "prop-types";

const AlertOverlay = (props) => {
  const { message, url, urlLabel, withOrganiztionSwitcher } = props;
  const { logout } = useUsersStore();
  return (
    <div className="alert-overlay">
      <div className="message">{message}</div>
      <div className="buttons">
        {url && urlLabel && (
          <NavLink to={url}>
            <span className="btn">{urlLabel}</span>
          </NavLink>
        )}
        &nbsp;
        <LaunchIntercom className={"btn outline"}>Support</LaunchIntercom>
      </div>
      <div
        className={clsx({
          "buttons mt-1": withOrganiztionSwitcher,
        })}
      >
        {withOrganiztionSwitcher && (
          <NavLink to={"#modal-choose-organization"}>
            <span className="btn">Switch Organizaton</span>
          </NavLink>
        )}
        &nbsp;
        <span className="btn danger" onClick={logout}>
          Logout
        </span>
      </div>
    </div>
  );
};
AlertOverlay.propTypes = {
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlLabel: PropTypes.string.isRequired,
  withOrganiztionSwitcher: PropTypes.bool,
};

AlertOverlay.defaultProps = {
  withOrganiztionSwitcher: false,
};

export default AlertOverlay;
