import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as AvatarUserNumberSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-user-number.svg";
import { ReactComponent as AvatarSignatureSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-signature.svg";
import { ReactComponent as AvatarOrganizationSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-organization.svg";
import { ReactComponent as AvatarShortCodeSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-shortcode.svg";
import { ReactComponent as AvatarLocalSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-local.svg";
import { ReactComponent as AvatarLandlineSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-landline.svg";
import { ReactComponent as AvatarTollFreeSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-toll-free.svg";
import { ReactComponent as AvatarMessageTemplateSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-message-template.svg";
import { ReactComponent as AvatarKeywordSvg } from "../../../assets/img/icons-new/settings/avatars/avatar-keyword.svg";
import { default as MuiAvatar } from "@material-ui/core/Avatar";

const SettingsAvatar = (props) => {
  const {
    isMobile,
    isSignature,
    isOrganization,
    isShortCode,
    isLocal,
    isLandline,
    isTollFree,
    isMessageTemplate,
    isKeyword,
    size,
    avatarUrl,
  } = props;

  if (avatarUrl)
    return (
      <MuiAvatar
        src={avatarUrl}
        style={{
          width: size,
          height: size,
        }}
      />
    );
  else {
    if (isMobile) return <AvatarUserNumberSvg width={size} height={size} />;
    if (isSignature) return <AvatarSignatureSvg width={size} height={size} />;
    if (isOrganization)
      return <AvatarOrganizationSvg width={size} height={size} />;
    if (isShortCode) return <AvatarShortCodeSvg width={size} height={size} />;
    if (isLocal) return <AvatarLocalSvg width={size} height={size} />;
    if (isLandline) return <AvatarLandlineSvg width={size} height={size} />;
    if (isTollFree) return <AvatarTollFreeSvg width={size} height={size} />;
    if (isMessageTemplate)
      return <AvatarMessageTemplateSvg width={size} height={size} />;
    if (isKeyword) return <AvatarKeywordSvg width={size} height={size} />;

    return null;
  }
};

SettingsAvatar.propTypes = {
  isMobile: PropTypes.bool,
  isSignature: PropTypes.bool,
  isOrganization: PropTypes.bool,
  isShortCode: PropTypes.bool,
  isLocal: PropTypes.bool,
  isLandline: PropTypes.bool,
  isTollFree: PropTypes.bool,
  isMessageTemplate: PropTypes.bool,
  isKeyword: PropTypes.bool,
  size: PropTypes.number,
  avatarUrl: PropTypes.string,
};

SettingsAvatar.defaultProps = {
  isMobile: false,
  isSignature: false,
  isOrganization: false,
  isShortCode: false,
  isLocal: false,
  isLandline: false,
  isTollFree: false,
  isMessageTemplate: false,
  isKeyword: false,
  size: 70,
  avatarUrl: null,
};

export default SettingsAvatar;
