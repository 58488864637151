const initialState = {
  notifications: [],
  currentNotification: "",
};

export default function reducer(state = initialState, action) {
  let notifications = null;
  switch (action.type) {
    case "NOTIFICATIONS/ADD":
      notifications = [].concat(state.notifications);
      notifications.push({
        added: new Date().getTime(),
        body: action.body,
        title: action.title,
        autoHide: action.autoHide,
        timeout: action.timeout,
      });
      return {
        ...state,
        notifications,
      };

    case "NOTIFICATIONS/REMOVE":
      notifications = [];
      for (let i = 0; i < state.notifications.length; i += 1) {
        if (
          state.notifications[i].body !== action.body ||
          state.notifications[i].title !== action.title
        ) {
          notifications.push(state.notifications[i]);
        }
      }
      return {
        ...state,
        notifications,
      };

    case "NOTIFICATIONS/CURRENT_ACTIVE_NOTFICATION":
      return {
        ...state,
        currentNotification: action.currentNotficaiton,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
