import axios from "axios";
import {
  storeOldRequestInQueueForCFChallenge,
  toggleSessionExpired,
} from "../actions/main";
import AxiosConfig from "../AxiosConfig";
import FcmConfig from "../FcmConfig";
import { getPrimaryUrl, isReactNativeApp } from "../helpers";
import { cookies, store } from "../store";
import {
  ACCESS_TOKEN,
  APP_SHARED_SESSION,
  REFRESH_TOKEN,
  RELOAD_COUNT,
  USER_LOGOUT,
} from "./constants";
import jwtDecode from "jwt-decode";
import { persistor } from "../store";
import PusherConfig from "../pusher/PusherConfig";
import { setShowCfChallengeOverlay } from "../actions/users";

// Error messages
const EXPIRED_TOKEN = "Expired token";
const COMPANY_NOT_AUTHORIZED = "Company not authorized";
const MAINTENANCE_BREAK = "Maintenance break";

let isRefreshing = false;
let failedQueue = [];
let isSessionExpired = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const redirectToLoginPage = (redirectHash) => {
  const hash = redirectHash ? `?${redirectHash}` : "";
  window.location.href = getPrimaryUrl(`users/logout${hash}`);
};

export const clearTokens = () => {
  AxiosConfig.clearAuthToken();
  window.localStorage.clear();
};

export const logoutAuth = (redirectHash) => {
  clearTokens();
  const cookiesNames = Object.keys(cookies.getAll());

  for (let i = 0; i < cookiesNames.length; i++) {
    cookies.remove(cookiesNames[i], { path: "/" });
  }

  redirectToLoginPage(redirectHash);
};

export const setTokens = (accessToken, refreshToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const setAppSharedSession = (appSharedSession) => {
  localStorage.setItem(APP_SHARED_SESSION, appSharedSession);
};

export const setReloadCountOnLocalStorage = (count) => {
  localStorage.setItem(RELOAD_COUNT, count);
};

export const getReloadCount = () => {
  return parseInt(localStorage.getItem(RELOAD_COUNT));
};

const refreshAxios = axios.create({});

const getNewToken = async (originalRequest) => {
  const { companies, users } = store.getState();
  const { currentCompany } = companies;
  const { loggedUser } = users;
  try {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken) {
      store.dispatch(toggleSessionExpired(true));
      if (isReactNativeApp()) {
        window.ReactNativeWebView?.postMessage(USER_LOGOUT);
      }
      return;
    }

    const { data } = await refreshAxios.post(
      `${AxiosConfig.getEndpointAddress()}users/refresh-tokens.json`,
      {
        refresh_token: refreshToken,
      }
    );

    // extend legacy session after getting a new refresh token
    store.dispatch({
      type: "MAIN/EXTEND_LEGACY_SESSION_OPENER",
      show: true,
    });
    // resend the refresh token to Cordova app
    FcmConfig.setRefreshToken(data.refresh_token);
    FcmConfig.setAccessToken(data.access_token);
    FcmConfig.sendParentMessage("user");

    setTokens(data.access_token, data.refresh_token);
    originalRequest.headers["Authorization"] = `Bearer ${data.access_token}`;
    AxiosConfig.setAuthToken(data.access_token);

    processQueue(null, data.access_token);

    return axios(originalRequest);
  } catch (error) {
    store.dispatch(toggleSessionExpired(true));
    if (isReactNativeApp()) {
      window.ReactNativeWebView?.postMessage(USER_LOGOUT);
    }
    processQueue(error, null);
    isSessionExpired = true;
    return Promise.reject(error);
  } finally {
    isRefreshing = false;
    if (!isSessionExpired) {
      // Initialize PusherConfig again after refreshing access token
      PusherConfig.init(currentCompany.id, loggedUser.id, store.dispatch);
      isSessionExpired = false;
    }
  }
};

const storeOldRequestInQueue = (originalRequest) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });
  })
    .then((token) => {
      originalRequest.headers["Authorization"] = "Bearer " + token;
      return axios(originalRequest);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const axiosErrorCallback = async (error) => {
  // Handle 401 unauthorized errors
  // Handle 503 service unavailable errors
  if (
    (error.response && [401, 503, 429].includes(error.response.status)) ||
    (error?.response?.headers?.hasOwnProperty("cf-mitigated") &&
      error?.response?.headers["cf-mitigated"] === "challenge")
  ) {
    if (error.response?.data?.message === EXPIRED_TOKEN) {
      const originalRequest = error.config;

      if (isRefreshing) {
        return storeOldRequestInQueue(originalRequest);
      }

      isRefreshing = true;

      return getNewToken(originalRequest);
    } else if (
      [COMPANY_NOT_AUTHORIZED, MAINTENANCE_BREAK].includes(
        error.response?.data?.message
      )
    ) {
      return logoutAuth();
    } else if (
      error?.response?.headers?.hasOwnProperty("cf-mitigated") &&
      error?.response?.headers["cf-mitigated"] === "challenge"
    ) {
      const originalRequest = error.config;
      store.dispatch(setShowCfChallengeOverlay(true));
      store.dispatch(storeOldRequestInQueueForCFChallenge(originalRequest));
      return;
    }
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

export const purgeStoreForNewLogin = (newAccessToken) => {
  const previousAccessToken = localStorage.getItem("accessToken");

  if (!previousAccessToken || !newAccessToken) return;

  const { sub: previousUserId } = jwtDecode(previousAccessToken) || {};
  const { sub: newUserId } = jwtDecode(newAccessToken) || {};

  if (newUserId !== previousUserId) {
    persistor.purge();
  }
};
