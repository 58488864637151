import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/components_react/SnackBar.scss";
import { history } from "../../store";

export default function Snackbars(props) {
  const {
    message,
    open,
    onClose,
    handleAction,
    actionTitle,
    longText,
    error,
    autoClose,
    onToastInit,
    withViewButton,
    redirectViewUrl,
    id,
    position,
  } = props;

  const toastId = React.useRef(null);
  toast.configure();

  const onAction = (callerAction) => () => {
    callerAction();
    handleAction();
    onClose();
  };

  const onViewAction = (callerAction) => () => {
    callerAction();
    history.push(redirectViewUrl);
  };

  const renderToast = () => {
    const className = longText
      ? "default-snack-container-large"
      : "default-snack-container";
    const type = error ? "error" : "default";
    const dismissToast = () => {
      toast.dismiss(toastId.current);
    };
    const content = () => (
      <div
        className={`snackbar-container ${longText ? "snack-extra-text" : ""}`}
      >
        <span className={error ? "snack-text-error" : "snack-text"}>
          {message}
        </span>
        {!error && (
          <div className="d-flex">
            {withViewButton && (
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onViewAction(dismissToast)}
              >
                View
              </button>
            )}

            {actionTitle && (
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onAction(dismissToast)}
              >
                {actionTitle}
              </button>
            )}
          </div>
        )}
      </div>
    );
    toastId.current = toast(content, {
      className,
      onClose: onClose,
      type,
      autoClose: autoClose,
      // autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      position,
      toastId: id,
      // pauseOnHover: true,
    });

    if (onToastInit) onToastInit(toastId.current);
  };

  useEffect(() => {
    if (open) {
      renderToast();
    }
    // eslint-disable-next-line
  }, [open]);

  return <></>;
}

Snackbars.propTypes = {
  autoClose: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onToastInit: PropTypes.func,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleAction: PropTypes.func,
  actionTitle: PropTypes.string,
  longText: PropTypes.bool,
  error: PropTypes.bool,
  withViewButton: PropTypes.bool,
  redirectViewUrl: PropTypes.string,
  position: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Snackbars.defaultProps = {
  autoClose: 4000,
  onToastInit: undefined,
  position: "bottom-left",
};
