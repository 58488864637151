import React, { Suspense } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import jquery from "jquery";
import clsx from "clsx";
import "bootstrap";
import { clearIsAutoSwitched } from "../actions/companies";
import { toggleSidebar, setTopBarsHeight, showLoading } from "../actions/main";

import { ModalsContext } from "../ModalsContext";
import CallMinimizeBar from "./CallMinimizeBar";
import AccountIssueBar from "./AccountIssue/AccountIssueBar";
// import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";
import ClearAllUndeliveredModal from "./modals/ClearAllUndeliveredModal";
import LayoutModals from "./LayoutModals";
import Notifications from "./Notifications";
import Loading from "./Loading";
import { isMdOrBelowBreakpoint } from "../utils/breakpoints";
import ViewGroupFormPeopleModal from "./modals/ViewGroupFormPeopleModal";
import { getPermissionParsed } from "../utils/settingsHelpers";
import withResponsive from "../withResponsive";
import { EMBEDDED_FILTER_SCREENS_ROUTES } from "../utils/constants";
import { logout } from "../actions/users";
import RenderTopNotificationBannerOverlayApp from "./hub/HelperComponents/RenderTopNotificationBannerOverlayApp";
import AccountIssueOverlay from "./AccountIssue/AccountIssueOverlay";
import { getAccountIssueInfo } from "../utils/accountHelpers";
import { UserMainMenuLookEnums } from "../utils/enums/usersEnums";
import { closeRHSAllowed } from "./hub/ThreadItems/threadItemHelper";
import CallContactModal from "./modals/CallContactModal";
import "../assets/components_react/LayoutDefault.scss";
import "../assets/css/iziModal.min.css";
import "../assets/css/audioplayer.scss";
import "../assets/css/popbox.css";
import "font-awesome/scss/font-awesome.scss";

window.jQuery = window.$ = jquery;
window.Popper = require("popper.js").default;

function mapStateToProps(store) {
  let modal = null;
  let modalParam = null;
  if (
    store.router.location.hash &&
    store.router.location.hash.substr(0, 7) === "#modal-"
  ) {
    let tmp = store.router.location.hash.substr(7).split("/");
    modal = tmp[0];
    modalParam = tmp.length > 1 ? tmp.slice(1).join("/") : null;
  }
  return {
    isLoading: store.main.isLoading,
    modal,
    modalParam,
    callStatus: store.twilio.callStatus,
    loggedUser: store.users.loggedUser,
    credits: store.users.credits,
    intercomAppId: store.settings.settings
      ? store.settings.settings.intercomAppId
      : null,
    isAdmin:
      store.companies.currentCompany &&
      (store.companies.currentCompany.is_main_owner ||
        store.companies.currentCompany.is_admin_plus ||
        store.companies.currentCompany.is_admin_user),
    companyId: store.companies.currentCompany
      ? store.companies.currentCompany.id
      : null,
    company: store.companies.currentCompany,
    isCurrentCompanyAutoSwitched: store.companies.isCurrentAutoSwitched,
    accountIssueBarCloseState: store.companies.accountIssueBarCloseState,
    isSidebarOpen: store.main.isSidebarOpen,
    isLeftPanelScrollingDown: store.main.isLeftPanelScrollingDown,
    isMainPanelScrollingUp: store.main.isMainPanelScrollingUp,
    topBarsHeight: store.main.topBarsHeight,
    userPermissions: store.users.permissions,
    userSettings: store.users.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    setTopBarsHeight: (height) => dispatch(setTopBarsHeight(height)),
    clearIsAutoSwitched: () => dispatch(clearIsAutoSwitched()),
    showLoading: () => dispatch(showLoading()),
    logout: () => dispatch(logout()),
  };
}

class LayoutDefault extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isModalOpen = this.isModalOpen.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    // this.setSidebarIsOpen = this.setSidebarIsOpen.bind(this);
    this.getResponsiveBtn = this.getResponsiveBtn.bind(this);
    this.updateHeight = this.updateHeight.bind(this);

    this.state = {
      openModals: [],
      showAccountIssueOverlay: true,
    };
    // this.updateHeight = this.updateHeight.bind(this);
  }

  componentDidMount() {
    //  this.updateHeight();
    //  window.addEventListener("resize", this.updateHeight);
  }

  componentWillUnmount() {
    //  window.removeEventListener("resize", this.updateHeight);
  }

  componentDidUpdate(prevProps) {
    const { topBarsHeight, isLeftPanelScrollingDown, isMainPanelScrollingUp } =
      this.props;
    // this.updateHeight();

    if (topBarsHeight !== prevProps.topBarsHeight) {
      document.documentElement.style.setProperty(
        "--top-bars-height",
        `${topBarsHeight}px`
      );
    }

    if (isLeftPanelScrollingDown !== prevProps.isLeftPanelScrollingDown) {
      if (isLeftPanelScrollingDown) {
        document.body.classList.add("left-panel-scrolling-down");
      } else {
        document.body.classList.remove("left-panel-scrolling-down");
      }
    }

    if (isMainPanelScrollingUp !== prevProps.isMainPanelScrollingUp) {
      if (isMainPanelScrollingUp) {
        document.body.classList.add("main-panel-scrolling-up");
      } else {
        document.body.classList.remove("main-panel-scrolling-up");
      }
    }
  }

  updateHeight() {
    const { topBarsHeight, setTopBarsHeight } = this.props;
    const { clientHeight } = this.topBars;
    if (topBarsHeight !== clientHeight) {
      setTopBarsHeight(clientHeight);
    }
  }

  openModal(modal) {
    let openModals = this.state.openModals;
    openModals.push(modal);
    this.setState({ openModals: openModals });
  }

  closeModal(modal) {
    let openModals = this.state.openModals;
    if (openModals.indexOf(modal) !== -1) {
      openModals.splice(openModals.indexOf(modal), 1);
      this.setState({ openModals: openModals });
    }
  }

  isModalOpen(modal) {
    return this.state.openModals.indexOf(modal) === -1 ? false : true;
  }

  closeAllModals(modal) {
    this.setState({ openModals: [] });
  }

  checkForMobileDevice = () => {
    const { breakpoint, match } = this.props;
    const { threadType, submenu, interlocutorId, filter } = match.params;
    if (
      isMdOrBelowBreakpoint(breakpoint) &&
      (threadType ||
        submenu ||
        interlocutorId ||
        EMBEDDED_FILTER_SCREENS_ROUTES.includes(filter))
    ) {
      return false;
    }
    return true;
  };

  getResponsiveBtn() {
    return (
      <a
        id="mobile_btn"
        className="mobile_btn pull-left"
        href="#sidebar"
        onClick={(e) => {
          e.preventDefault();
          //this.setSidebarIsOpen(true);
          this.props.toggleSidebar();
        }}
      >
        <i className="fa fa-bars" aria-hidden="true"></i>
      </a>
    );
  }

  toggleAccountIssueOverlay = () => {
    const { showAccountIssueOverlay } = this.state;
    this.setState({
      showAccountIssueOverlay: !showAccountIssueOverlay,
    });
  };

  render() {
    const {
      isLoading,
      isSidebarOpen,
      topBarsHeight,
      toggleSidebar,
      clearIsAutoSwitched,
      showLoading,
      match,
      userSettings,
      breakpoint,
      className,
    } = this.props;
    const { showAccountIssueOverlay } = this.state;

    const { filter, tab, interlocutorId } = match.params;

    const openModal = this.openModal;
    const closeModal = this.closeModal;
    const isModalOpen = this.isModalOpen;
    const closeAllModals = this.closeAllModals;

    // Alert about credits and account suspended
    const accountIssueInfo = !this.props.company
      ? null
      : getAccountIssueInfo(
          this.props.company.is_billing_issue,
          this.props.company.is_suspended,
          this.props.company.number_is_deleted,
          this.props.company.package,
          this.props.credits,
          this.props.company.billing_issue_days,
          this.props.isAdmin,
          null
        );

    // Account about invalid email
    const emailIssueInfo = !this.props.loggedUser
      ? null
      : getAccountIssueInfo(
          null,
          null,
          null,
          null,
          null,
          null,
          this.props.isAdmin,
          this.props.loggedUser.is_invalid_email
        );

    // Check if sidebar is expanded in settings
    const isSidebarExpanded = () => {
      const settingsMainMenuLook =
        closeRHSAllowed.includes(tab) && interlocutorId
          ? userSettings?.main_menu_look_on_messages
          : userSettings?.main_menu_look;
      return (
        settingsMainMenuLook === UserMainMenuLookEnums.ICONS_AND_TEXTS &&
        !isMdOrBelowBreakpoint(breakpoint)
      );
    };

    let sidebarTop = topBarsHeight;
    return (
      <ModalsContext.Provider
        value={{ openModal, closeModal, isModalOpen, closeAllModals }}
      >
        <div className="top-bars" ref={(topBars) => (this.topBars = topBars)}>
          <RenderTopNotificationBannerOverlayApp
            show={
              showAccountIssueOverlay &&
              (this.props.callStatus === "connected" ||
                this.props.callStatus === "connecting")
            }
          >
            <CallMinimizeBar />
          </RenderTopNotificationBannerOverlayApp>

          <RenderTopNotificationBannerOverlayApp
            show={
              this.props.company && accountIssueInfo && showAccountIssueOverlay
            }
          >
            <AccountIssueBar
              {...accountIssueInfo}
              companyId={this.props.company ? this.props.company.id : 0}
              visible={showAccountIssueOverlay}
              onCloseBanner={this.toggleAccountIssueOverlay}
            />
          </RenderTopNotificationBannerOverlayApp>

          <RenderTopNotificationBannerOverlayApp
            show={
              this.props.loggedUser && emailIssueInfo && showAccountIssueOverlay
            }
          >
            <AccountIssueBar
              {...emailIssueInfo}
              companyId={this.props.company ? this.props.company.id : 0}
              visible={showAccountIssueOverlay}
              onCloseBanner={this.toggleAccountIssueOverlay}
            />
          </RenderTopNotificationBannerOverlayApp>
        </div>
        <div
          className={
            this.props.company &&
            accountIssueInfo &&
            !this.props.accountIssueBarCloseState
              ? "modified-tab-content"
              : "main-tab-content"
          }
          style={{ marginTop: topBarsHeight }}
        >
          {this.props.company && this.checkForMobileDevice() && (
            <Sidebar
              style={{ top: `${sidebarTop}px` }}
              isOpen={isSidebarOpen}
              closeSidebar={toggleSidebar}
              expandSidebar={() =>
                window.innerWidth >= 992
                  ? document.body.classList.add("drawer-active")
                  : null
              }
              collapseSidebar={() =>
                window.innerWidth >= 992
                  ? document.body.classList.remove("drawer-active")
                  : null
              }
              showLoading={showLoading}
            />
          )}
          <div
            className={clsx("page-wrapper", className, {
              "page-wrapper-with-expand-sidebar": isSidebarExpanded(),
            })}
            style={{
              minHeight: `calc(100vh - ${topBarsHeight}px)`,
            }}
          >
            <Suspense fallback={<div>Loading...</div>}>
              {this.props.children &&
                React.cloneElement(this.props.children, {
                  getResponsiveBtn: this.getResponsiveBtn,
                })}
            </Suspense>
          </div>
          {this.props.company && accountIssueInfo && filter !== "settings" && (
            <AccountIssueOverlay />
          )}
        </div>

        <Notifications />

        {/* This way is deprecated. Please use LayoutModals, as below. */}
        {this.props.company && (
          <React.Fragment>
            <ClearAllUndeliveredModal
              show={this.props.modal === "clear-all-undelivered"}
              param={this.props.modalParam}
            />
            {getPermissionParsed(
              this.props.userPermissions?.messages_calls,
              "call"
            ) && (
              <CallContactModal
                show={this.props.modal === "call-contact"}
                param={this.props.modalParam}
              />
            )}

            <ViewGroupFormPeopleModal
              show={this.props.modal === "view-group"}
              param={this.props.modalParam}
            />
          </React.Fragment>
        )}

        {/* All new modals should be done via below new modal manager*/}
        <LayoutModals
          modal={this.props.modal}
          param={this.props.modalParam}
          isUserLoaded={this.props.loggedUser && this.props.loggedUser.id > 0}
          isCompanyLoaded={this.props.company && this.props.company.id > 0}
          company={this.props.company}
          isCurrentCompanyAutoSwitched={this.props.isCurrentCompanyAutoSwitched}
          onCloseCurrentCompanyAutoSwitched={clearIsAutoSwitched}
          showLoading={showLoading}
          userPermissions={this.props.userPermissions}
        />

        {isLoading && <Loading />}
      </ModalsContext.Provider>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(withRouter(LayoutDefault)));
