const initialState = {
  greetingsAndVoicemailStatus: "",
  addGreetingsAndVoicemailStatus: "",
  deleteGreetingsAndVoicemailStatus: "",

  // edit greeting and voicemail
  isEdit: false,
  editStatus: "",

  // RHS data
  dataForRHS: {},

  // numbers to assign
  numbers: [],
  numbersStatus: "",
  currentAssignedNumbers: [],

  // Greetings
  greetings: [],

  // Voicemail
  voicemail: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_SETTINGS/FETCH_GREETINGS_AND_VOICEMAIL": {
      return {
        ...state,
        greetingsAndVoicemailStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_GREETINGS_AND_VOICEMAIL.SUCCESS": {
      const greetingsData =
        action.kind === "greeting" ? action.payload.data : state.greetings;
      const voicemailData =
        action.kind === "voicemail" ? action.payload.data : state.voicemail;
      return {
        ...state,
        greetingsAndVoicemailStatus: "success",
        greetings: greetingsData,
        voicemail: voicemailData,
      };
    }

    case "USER_SETTINGS/FETCH_GREETINGS_AND_VOICEMAIL.ERROR": {
      return {
        ...state,
        greetingsAndVoicemailStatus: action.error,
      };
    }

    case "USER_SETTINGS/FETCH_NUMBERS_TO_ASSIGN": {
      return {
        ...state,
        numbersStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_NUMBERS_TO_ASSIGN.SUCCESS": {
      return {
        ...state,
        numbersStatus: "success",
        numbers: action.payload.data,
      };
    }

    case "USER_SETTINGS/FETCH_NUMBERS_TO_ASSIGN.ERROR": {
      return {
        ...state,
        numbersStatus: "error",
      };
    }

    case "USER_SETTINGS/SET_ASSIGNED_NUMBERS": {
      return {
        ...state,
        currentAssignedNumbers: action.data,
      };
    }

    case "USER_SETTINGS/SET_DATA_FOR_RHS": {
      const numbers = action.data.numbers.map((item) => item.number);
      return {
        ...state,
        dataForRHS: action.data,
        currentAssignedNumbers: numbers,
      };
    }

    case "USER_SETTINGS/CREATE_NEW_GREETINGS_AND_VOICEMAIL": {
      return {
        ...state,
        addGreetingsAndVoicemailStatus: "loading",
      };
    }

    case "USER_SETTINGS/CREATE_NEW_GREETINGS_AND_VOICEMAIL.SUCCESS": {
      return {
        ...state,
        addGreetingsAndVoicemailStatus: "success",
      };
    }

    case "USER_SETTINGS/CREATE_NEW_GREETINGS_AND_VOICEMAIL.ERROR": {
      return {
        ...state,
        addGreetingsAndVoicemailStatus: "error",
      };
    }

    case "USER_SETTINGS/DELETE_GREETING_AND_VOICEMAIL": {
      return {
        ...state,
        deleteGreetingsAndVoicemailStatus: "loading",
      };
    }

    case "USER_SETTINGS/DELETE_GREETING_AND_VOICEMAIL.SUCCESS": {
      return {
        ...state,
        deleteGreetingsAndVoicemailStatus: "success",
      };
    }

    case "USER_SETTINGS/DELETE_GREETING_AND_VOICEMAIL.ERROR": {
      return {
        ...state,
        deleteGreetingsAndVoicemailStatus: "error",
      };
    }

    case "USER_SETTINGS/IS_EDIT_GREETING_AND_VOICEMAIL": {
      return {
        ...state,
        isEdit: action.value,
      };
    }

    case "USER_SETTINGS/EDIT_GREETING_AND_VOICEMAIL": {
      return {
        ...state,
        editStatus: "loading",
      };
    }

    case "USER_SETTINGS/EDIT_GREETING_AND_VOICEMAIL.SUCCESS": {
      const kind = action.payload.data?.kind;
      const dataToEdit =
        kind === "greeting" ? state.greetings : state.voicemail;
      if (action.payload.data) {
        dataToEdit.forEach((item, idx) => {
          if (item.id === action.payload.data.id) {
            dataToEdit[idx] = action.payload.data;
          }
        });
      }

      return {
        ...state,
        editStatus: "success",
        greetings: kind === "greeting" ? dataToEdit : state.greetings,
        voicemail: kind === "voicemail" ? dataToEdit : state.voicemail,
        dataForRHS: action.payload.data || state.dataForRHS,
      };
    }

    case "USER_SETTINGS/EDIT_GREETING_AND_VOICEMAIL.ERROR": {
      return {
        ...state,
        editStatus: "error",
      };
    }

    case "USER_SETTINGS/EDIT_GREETING_AND_VOICEMAIL.CLEAR": {
      return {
        ...state,
        editStatus: null,
      };
    }

    case "USER_SETTINGS/CLEAR_DATA": {
      return {
        ...state,
        greetingsAndVoicemailStatus: "",
        addGreetingsAndVoicemailStatus: "",
        deleteGreetingsAndVoicemailStatus: "",
        dataForRHS: {},
        numbersStatus: "",
        currentAssignedNumbers: [],
        isEdit: false,
        editStatus: "",
      };
    }

    default:
      return state;
  }
}
