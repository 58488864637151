import React, { useCallback } from "react";
import PropTypes from "prop-types";
import QuickFilter from "../../../hub/HelperComponents/QuickFilter";
import { isNotAnEmptyArray } from "../../../../utils/settingsHelpers";

const RecipientExcludedMembersView = (props) => {
  const {
    groupData,
    isExcludedFilterActive,
    handleSelectSystemExcluded,
    onClickCloseExludedFilter,
    advFilters,
    onClickAdvanceFilters,
  } = props;

  const checkIfFilterIsActive = useCallback(
    (filter) => {
      const currentAdvFilters =
        advFilters && isNotAnEmptyArray(advFilters)
          ? advFilters?.flat()
          : advFilters;
      return currentAdvFilters?.includes(filter);
    },
    [advFilters]
  );

  if (!groupData || !Boolean(groupData?.members_excluded)) return null;

  if (isExcludedFilterActive) {
    return (
      <>
        <QuickFilter
          onClick={onClickAdvanceFilters("all")}
          text={`All,`}
          isActive={isExcludedFilterActive && !isNotAnEmptyArray(advFilters)}
          className={"mr-1"}
        />

        {groupData?.members_blocked_count > 0 && (
          <QuickFilter
            onClick={onClickAdvanceFilters("blocked")}
            onClearFilter={onClickAdvanceFilters("all")}
            text={`${groupData?.members_blocked_count} Blocked,`}
            isActive={checkIfFilterIsActive("blocked")}
            className={"mr-1"}
          />
        )}

        {groupData?.members_unsubscribed_count > 0 && (
          <QuickFilter
            onClick={onClickAdvanceFilters("unsubscribed")}
            onClearFilter={onClickAdvanceFilters("all")}
            text={`${groupData?.members_unsubscribed_count} Unsubscribed,`}
            isActive={checkIfFilterIsActive("unsubscribed")}
            className={"mr-1"}
          />
        )}

        {groupData?.members_optedout_count > 0 && (
          <QuickFilter
            onClick={onClickAdvanceFilters("opted-out")}
            onClearFilter={onClickAdvanceFilters("all")}
            text={`${groupData?.members_optedout_count} Opted out,`}
            isActive={checkIfFilterIsActive("opted-out")}
            className={"mr-1"}
          />
        )}

        {groupData?.members_failed_count > 0 && (
          <QuickFilter
            onClick={onClickAdvanceFilters("unformatted-number")}
            onClearFilter={onClickAdvanceFilters("all")}
            text={`${groupData?.members_failed_count} Misformatted,`}
            isActive={checkIfFilterIsActive("unformatted-number")}
            className={"mr-1"}
          />
        )}
      </>
    );
  }

  return (
    <QuickFilter
      onClick={handleSelectSystemExcluded}
      onClearFilter={onClickCloseExludedFilter}
      text={`( ${groupData?.members_excluded} System Excluded )`}
      isActive={isExcludedFilterActive}
    />
  );
};

RecipientExcludedMembersView.propTypes = {
  groupData: PropTypes.object,
  isExcludedFilterActive: PropTypes.bool,
  handleSelectSystemExcluded: PropTypes.func,
  onClickAdvanceFilters: PropTypes.func,
  onClickCloseExludedFilter: PropTypes.func,
  advFilters: PropTypes.array,
};

RecipientExcludedMembersView.defaultProps = {
  groupData: {},
  isExcludedFilterActive: false,
  handleSelectSystemExcluded: () => {},
  onClickCloseExludedFilter: () => {},
  onClickAdvanceFilters: () => {},
  advFilters: [],
};

export default RecipientExcludedMembersView;
