import React, { useCallback } from "react";
import PropTypes from "prop-types";
import "../../../assets/components_react/NumberSteps.scss";

// if current step is for example 1.5 only the line will be highlighted
const NumberSteps = (props) => {
  const {
    currentStep,
    onClickStep,
    steps,
    showNumbers,
    type,
    barType,
    ulClassName,
  } = props;

  const arraySteps = Array(steps).fill(0);

  const getClassName = useCallback(
    (step) => {
      if (currentStep === step) return `current ${barType}-step-bar`;
      // if current step is for example 1.5 only the line will be highlighted
      else if (Math.ceil(currentStep) === step)
        return `number-step-progress ${barType}-step-bar`;
      else if (currentStep > step) return `done ${barType}-step-bar`;
      else if (currentStep < step) return `disabled ${barType}-step-bar`;
      return `${barType}-step-bar`;
    },
    [barType, currentStep]
  );

  return (
    <div className="number-steps">
      <ul role="tablist" className={ulClassName}>
        {arraySteps.map((_, index) => {
          const step = index + 1;
          if (index === 0) {
            return (
              <li
                key={index}
                role="tab"
                className={getClassName(step)}
                aria-disabled="false"
                aria-selected="true"
              >
                <span
                  className={`${type}-step`}
                  data-step={`${step}`}
                  onClick={(event) => onClickStep(event, step)}
                >
                  <span className="number">{showNumbers ? step : ""}</span>
                </span>
              </li>
            );
          } else if (index === arraySteps.length - 1) {
            return (
              <li
                key={index}
                role="tab"
                className={getClassName(step)}
                aria-disabled={currentStep < step ? "true" : "false"}
              >
                <span
                  className={`${type}-step`}
                  data-step={step}
                  onClick={
                    currentStep < step
                      ? undefined
                      : (event) => onClickStep(event, step)
                  }
                >
                  <span className="number">{showNumbers ? step : ""}</span>
                </span>
              </li>
            );
          } else {
            return (
              <li
                key={index}
                role="tab"
                className={getClassName(step)}
                aria-disabled={currentStep < step ? "true" : "false"}
              >
                <span
                  className={`${type}-step`}
                  data-step={`${step}`}
                  onClick={
                    currentStep < step
                      ? undefined
                      : (event) => onClickStep(event, step)
                  }
                >
                  <span className="number">{showNumbers ? step : ""}</span>
                </span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

NumberSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onClickStep: PropTypes.func.isRequired,
  steps: PropTypes.number,
  showNumbers: PropTypes.bool,
  type: PropTypes.oneOf(["large", "medium", "small"]),
  barType: PropTypes.oneOf(["large", "small"]),
  ulClassName: PropTypes.string,
};

NumberSteps.defaultProps = {
  steps: 3,
  showNumbers: true,
  type: "large",
  barType: "small",
  ulClassName: "",
};

export default NumberSteps;
