import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as adminSettingsAction from "../../../actions/menus/adminSettings";

export function useAdminSettingsStore() {
  const dispatch = useDispatch();
  const _adminSettings = useSelector((store) => store.adminSettings);

  const fetchCompanyDefaultList = useCallback(
    async (companyId) =>
      await dispatch(adminSettingsAction.fetchCompanyDefaultList(companyId)),
    [dispatch]
  );

  const updateCompanyDefault = useCallback(
    async (body, newData, index) =>
      await dispatch(
        adminSettingsAction.updateCompanyDefault(body, newData, index)
      ),
    [dispatch]
  );

  const updateCompanyDefaultClear = useCallback(
    async () => await dispatch(adminSettingsAction.updateCompanyDefaultClear()),
    [dispatch]
  );

  const fetchCompanySettings = useCallback(
    async (companyId) =>
      await dispatch(adminSettingsAction.fetchCompanySettings(companyId)),
    [dispatch]
  );

  const updateCompanySettings = useCallback(
    async (data, updateField, actionOnFields) =>
      await dispatch(
        adminSettingsAction.updateCompanySettings(
          data,
          updateField,
          actionOnFields
        )
      ),
    [dispatch]
  );

  const updateCompanySettingsClear = useCallback(
    async (field) =>
      await dispatch(adminSettingsAction.updateCompanySettingsClear(field)),
    [dispatch]
  );

  const setSelectedUser = useCallback(
    async (selectedUserRhs) =>
      await dispatch(adminSettingsAction.setSelectedUser(selectedUserRhs)),
    [dispatch]
  );

  const setPaymentInfo = useCallback(
    async (paymentInfo) =>
      await dispatch(adminSettingsAction.setPaymentInfo(paymentInfo)),
    [dispatch]
  );

  return {
    adminSettings: _adminSettings,
    fetchCompanyDefaultList,
    updateCompanyDefault,
    updateCompanyDefaultClear,
    fetchCompanySettings,
    updateCompanySettings,
    updateCompanySettingsClear,
    setSelectedUser,
    setPaymentInfo,
  };
}

export default useAdminSettingsStore;
