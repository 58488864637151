import React from "react";
import PropTypes from "prop-types";
import RecipientsTableViewRow from "./RecipientsTableViewRow";

const RecipientsTableViewBody = (props) => {
  const {
    columnSelectionState,
    filteredRecipients,
    isLoadingMore,
    selectedViewingFilter,
  } = props;
  return (
    <tbody>
      {filteredRecipients &&
        Array.isArray(filteredRecipients) &&
        filteredRecipients?.length > 0 &&
        filteredRecipients.map((recipient, index) => {
          if (recipient == null) return null;
          return (
            <RecipientsTableViewRow
              key={index}
              recipient={recipient}
              columnSelectionState={columnSelectionState}
              selectedViewingFilter={selectedViewingFilter}
            />
          );
        })}
      {isLoadingMore ? (
        <tr>
          <td colSpan="3">
            <p className="text-muted text-center mt-3">Loading...</p>
          </td>
        </tr>
      ) : null}
    </tbody>
  );
};

RecipientsTableViewBody.propTypes = {
  columnSelectionState: PropTypes.objectOf(PropTypes.any),
};

export default RecipientsTableViewBody;
