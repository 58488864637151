import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { plBasicTheme } from "../../../assets/base/theme";
import MembersPreviewModal from "./MembersPreviewModal";
// new icons
import { ReactComponent as CloseSmallIcon } from "../../../assets/img/icons-new/general/close/close-gray.svg";

function mapStateToProps(store) {
  return {
    deletionMembersArchivedPl: store.groups.deletionMembersArchivedPl,
    deletionMembersUnarchivedPl: store.groups.deletionMembersUnarchivedPl,
    deletionMembersNonPl: store.groups.deletionMembersNonPl,
  };
}

const ConfirmationModalStats = ({
  deletionMembersArchivedPl,
  deletionMembersUnarchivedPl,
  deletionMembersNonPl,
  /* from parent */
  show,
  modalBodyContent,
  modalBodyHeader,
  onClose,
  footerText,
  summaryStats,
  fetchMembers,
  selectionInfoView,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [selectMembers, setSelectMembers] = useState(true);
  const [filter, setFilter] = useState("");
  const [membersData, setMembersData] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [previewHeader, setPreviewHeader] = useState(<></>);

  const updateView = useCallback(() => {
    if (show && !isShown) {
      setTimeout(() => {
        setIsShown(true);
        setIsHide(false);
      }, 0);
    }
    if (!show && isShown) {
      setIsHide(true);
      setTimeout(() => {
        setIsShown(false);
      }, 200);
    }
  }, [show, isShown]);

  useEffect(() => {
    updateView();
  }, [show, isShown, isHide, updateView]);

  useEffect(() => {
    if (filter === "archived_pl" && deletionMembersArchivedPl !== membersData) {
      setMembersData(deletionMembersArchivedPl);
    } else if (
      filter === "unarchived_pl" &&
      deletionMembersUnarchivedPl !== membersData
    ) {
      setMembersData(deletionMembersUnarchivedPl);
    } else if (filter === "non_pl" && deletionMembersNonPl !== membersData) {
      setMembersData(deletionMembersNonPl);
    }
  }, [
    deletionMembersArchivedPl,
    deletionMembersUnarchivedPl,
    deletionMembersNonPl,
    filter,
    membersData,
  ]);

  const closeModal = (val) => () => {
    onClose(val, selectMembers ? 1 : 0);
  };

  const handleMembersSelect = () => {
    setSelectMembers(!selectMembers);
  };

  const handleStatClick = (filter) => () => {
    setFilter(filter);
    setOpenPreview(true);
    setPreviewHeader(
      <span>
        Preview of {generateCaption(filter)} within {selectionInfoView}
      </span>
    );
    if (
      filter === "archived_pl" &&
      (!deletionMembersArchivedPl ||
        Object.keys(deletionMembersArchivedPl).length < 1)
    ) {
      fetchMembers(filter);
    } else if (
      filter === "unarchived_pl" &&
      (!deletionMembersUnarchivedPl ||
        Object.keys(deletionMembersUnarchivedPl).length < 1)
    ) {
      fetchMembers(filter);
    } else if (
      filter === "non_pl" &&
      (!deletionMembersNonPl || Object.keys(deletionMembersNonPl).length < 1)
    ) {
      fetchMembers(filter);
    }
  };

  const generateCaption = (elem) => {
    switch (elem) {
      case "archived_pl":
        return `
          ${summaryStats.archived_pl_contacts} Archived PL ${
          summaryStats.archived_pl_contacts <= 1 ? "Person" : "People"
        }
        `;
      case "unarchived_pl":
        return `
          ${summaryStats.unarchived_pl_contacts} Unarchived PL ${
          summaryStats.unarchived_pl_contacts <= 1 ? "Person" : "People"
        }
        `;
      case "non_pl":
        return `
          ${summaryStats.non_pl_contacts} non PL ${
          summaryStats.non_pl_contacts <= 1 ? "Person" : "People"
        }
        `;
      default:
        return null;
    }
  };

  const populateStats = () => {
    return (
      <div className="modal-content-stats">
        <div onClick={handleStatClick("archived_pl")}>
          {generateCaption("archived_pl")}
          <span className="additional-text">
            (to be permanently deleted from the system)
          </span>
        </div>
        <div onClick={handleStatClick("unarchived_pl")}>
          {generateCaption("unarchived_pl")}
          <span className="additional-text">(not to be deleted)</span>
        </div>
        <div onClick={handleStatClick("non_pl")}>
          {generateCaption("non_pl")}
          <span className="additional-text">(not to be deleted)</span>
        </div>
      </div>
    );
  };
  return (
    <div
      className={
        "modal fade inbox-modal confirm-group-message" +
        (isShown && !isHide ? " show" : "")
      }
      style={show || isShown ? { display: "block" } : {}}
      id="SkipContactModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="SkipContactModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-backdrop fade show"
        onClick={(e) => {
          e.preventDefault();
          closeModal(false)();
        }}
      />
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        style={{ zIndex: 100 }}
      >
        <div className="modal-content themed-modal">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={(e) => {
              e.preventDefault();
              closeModal(false)();
            }}
          >
            <CloseSmallIcon />
          </button>
          <div className="modal-body">
            <div className="title sleek">
              <span>{modalBodyHeader}</span>
            </div>
            <div className={`content sleek warning-content`}>
              <p className="themed-text">{modalBodyContent}</p>
              {summaryStats && populateStats()}
            </div>

            <div className="modal-footer mt-40">
              <div className="switch-modal-bottom">
                <MuiThemeProvider theme={plBasicTheme}>
                  <Checkbox
                    checked={selectMembers}
                    onChange={handleMembersSelect}
                  />
                  <span className="switch-modal-bottom-text">{footerText}</span>
                </MuiThemeProvider>
              </div>
              <button
                type="button"
                className={`btn btn-delete-confirmation`}
                onClick={closeModal(true)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {openPreview && (
        <MembersPreviewModal
          modalBodyHeader={previewHeader}
          show={openPreview}
          membersData={membersData}
          closeModal={() => setOpenPreview(false)}
        />
      )}
    </div>
  );
};

export default withRouter(connect(mapStateToProps)(ConfirmationModalStats));
