import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as CloseWhiteSvg } from "../../../assets/img/icons/close-white.svg";
import { ReactComponent as PlayIcon } from "../../../assets/img/icons-new/play-square/play-square-white.svg";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import useMainStore from "../../../utils/hooks/ReduxHooks/mainStore";
import VideoThumbnail from "../../modals/Video/VideoThumbnail";

const NewVideoThumbnail = (props) => {
  const { videos, handleRemoveVideo } = props;

  // Router
  const history = useHistory();

  // Redux store
  const { addDataForModal } = useMainStore();

  const openVideoViewer = useCallback(
    (video) => () => {
      addDataForModal({
        url: video?.file?.url,
        type: video?.result?.mime_type,
      });
      history.push("#modal-video-viewer");
    },
    [addDataForModal, history]
  );

  if (!videos || !isNotAnEmptyArray(videos)) return null;
  return (
    <div className="messages-card__textarea-attachments">
      {videos.map((item, idx) => (
        <div
          className="messages-card__textarea-image position-relative"
          key={idx}
          onClick={openVideoViewer(item)}
        >
          {item?.thumbnail ? (
            <img
              className="thumbnail messages-card__textarea-image"
              height={68}
              width={68}
              alt="thumbnail"
              src={item.thumbnail || ""}
            />
          ) : (
            <div className="thumbnail messages-card__textarea-image video-thumbnail">
              <VideoThumbnail videoUrl={item?.file?.url} />
            </div>
          )}
          <div
            id={idx}
            className="messages-card__textarea-image-close"
            onClick={handleRemoveVideo(idx)}
          >
            <CloseWhiteSvg height={7} width={7} />
          </div>
          <div className="messages-card__textarea-video-overlay">
            <PlayIcon height={33} width={33} />
          </div>
        </div>
      ))}
    </div>
  );
};

NewVideoThumbnail.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.any),
  handleRemoveVideo: PropTypes.func,
};

NewVideoThumbnail.defaultProps = {
  videos: [],
  handleRemoveVideo: () => {},
};

export default NewVideoThumbnail;
