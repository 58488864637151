import React from "react";
import PropTypes from "prop-types";
import DashboardCard from "./DashboardCard";
import { useWithHover } from "../../../utils/hooks/useWithHover";
import { Link } from "react-router-dom";

const TimezoneWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    isActive,
    index,
    onClickTrash,
    timezone,
  } = props;
  const { hoverActive, onMouseLeave, onMouseEnter } = useWithHover();

  return (
    <DashboardCard
      headerContent={<div className="mt-1 font-weight-normal">Time Zone</div>}
      headerColorType="white"
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
      containerClassName="align-items-center"
    >
      <div
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        className="d-flex align-items-end flex-wrap px-3 pb-3 pt-1 large-text text-center font-weight-normal"
      >
        {timezone}
        {hoverActive && (
          <Link
            to="/hub/menus/filter/settings/user-settings/submenu/user-profile"
            className="billing-analytic-link ml-2"
          >
            Edit
          </Link>
        )}
      </div>
    </DashboardCard>
  );
};

TimezoneWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};

TimezoneWidget.defaultProps = {
  isActive: false,
};

export default TimezoneWidget;
