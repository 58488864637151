const colors = {
  primaryColor: {
    light: "#5517c1",
    dark: "#bb86fc",
  },
  iconMidGray: {
    light: "#565656",
    dark: "#afafaf",
  },
  iconColor: {
    light: "#122b28",
    dark: "#fff",
  },
};

export default colors;
