import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Avatar from "../../Avatar";
import ThreadCheckbox from "../../ThreadCheckbox";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";

const ThreadItemAvatar = (props) => {
  const {
    showCheckBox,
    checkBoxActive,
    selectMenuActive,
    isGroup,
    isGroupTag,
    isNewGroup,
    isAdhocGroup,
    isAllPeopleGroup,
    isMultipleContacts,
    isMultiplePrimaryContacts,
    contact,
    toggleCheckboxState,
    noAvatar,
    isAllFlowsInProgress,
    isAnyFlowInProgress,
    isImportedHistory,
    isHideCheckbox,
    toggleSelected,
  } = props;

  const breakpoint = useBreakpoint();

  const showCheckboxOnMobileView = useMemo(() => {
    if (isMdOrBelowBreakpoint(breakpoint) && toggleSelected === 0) {
      return false;
    }
    return true;
  }, [breakpoint, toggleSelected]);

  const onClickedAvatarOnMobileView = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (isMdOrBelowBreakpoint(breakpoint)) {
        toggleCheckboxState(event);
      }
    },
    [breakpoint, toggleCheckboxState]
  );

  const isGroupOrMultipleContact = isGroup || isMultipleContacts || !contact;

  const isNoCheckboxAndMenuActive =
    !showCheckBox && !checkBoxActive && !selectMenuActive;

  const isCheckboxOrMenuActive =
    showCheckBox || checkBoxActive || selectMenuActive;

  return (
    <div className="d-flex align-items-center">
      {!noAvatar &&
        !isHideCheckbox &&
        isCheckboxOrMenuActive &&
        !isAllFlowsInProgress &&
        showCheckboxOnMobileView && (
          <ThreadCheckbox
            active={checkBoxActive}
            onClick={toggleCheckboxState}
            selectMenuActive={selectMenuActive || toggleSelected >= 1}
            withWhiteCheckbox
          />
        )}
      {!noAvatar &&
        (isAnyFlowInProgress ||
          isNoCheckboxAndMenuActive ||
          selectMenuActive ||
          toggleSelected >= 1) && (
          <span onTouchStart={onClickedAvatarOnMobileView}>
            <Avatar
              isGroup={(isGroup && !isGroupTag) || isNewGroup}
              isGroupTag={isGroupTag}
              isAdhocGroup={isAdhocGroup}
              isAllPeopleGroup={isAllPeopleGroup}
              isMultipleContacts={isMultiplePrimaryContacts}
              isImportedHistory={isImportedHistory}
              firstName={isGroupOrMultipleContact ? "" : contact.first_name}
              lastName={isGroupOrMultipleContact ? "" : contact.last_name}
              email={isGroupOrMultipleContact ? "" : contact.email}
              bgColor={isGroupOrMultipleContact ? "" : contact.color}
              selectMenuActive={selectMenuActive || toggleSelected >= 1}
            />
          </span>
        )}
    </div>
  );
};

ThreadItemAvatar.prototype = {
  showCheckBox: PropTypes.bool,
  checkBoxActive: PropTypes.bool,
  selectMenuActive: PropTypes.bool,
  isGroup: PropTypes.bool,
  isGroupTag: PropTypes.bool,
  isNewGroup: PropTypes.bool,
  noAvatar: PropTypes.bool,
  isAdhocGroup: PropTypes.bool,
  isAllPeopleGroup: PropTypes.bool,
  isMultipleContacts: PropTypes.bool,
  isMultiplePrimaryContacts: PropTypes.bool,
  contact: PropTypes.object,
  toggleCheckboxState: PropTypes.func,
  isAllFlowsInProgress: PropTypes.bool,
  isAnyFlowInProgress: PropTypes.bool,
  isImportedHistory: PropTypes.bool,
};

export default ThreadItemAvatar;
