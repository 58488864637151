import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Chip } from "@material-ui/core";
import SpanWithTooltip from "../SpanWithTooltip";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import ActionButton from "../ActionButton/ActionButton";
import { ReactComponent as AllPeopleIcon } from "../../assets/img/icons-new/general/all-people/all-people-gray.svg";
import { ReactComponent as AddIcon } from "../../assets/img/icons-new/general/add-icon/add-gray.svg";

export default function BroadcastTableViewHeader() {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isMobile = useMemo(
    () => isMdOrBelowBreakpoint(breakpoint),
    [breakpoint]
  );

  const addBroadcastIcon = useMemo(() => {
    let sharedProps = {
      toolTipTile: "New Broadcast",
      icon: <AddIcon />,
      url: "#modal-add-broadcast",
    };

    return isMobile ? (
      <div
        className="cursor-pointer"
        onClick={() => history.push("#modal-add-broadcast")}
      >
        <SpanWithTooltip
          className={"hub-thread-group-table-view_header--action-button"}
          title={sharedProps?.toolTipTile}
        >
          {sharedProps?.icon}
        </SpanWithTooltip>
      </div>
    ) : (
      <ActionButton
        text="New Broadcast"
        type="primary"
        onClick={() => history.push("#modal-add-broadcast")}
        icon={sharedProps?.icon}
        buttonClassName="mr-2"
        showToolTip
        toolTipProps={{
          title: sharedProps?.toolTipTile,
          placement: "bottom",
          arrow: true,
        }}
      />
    );
  }, [history, isMobile]);

  return (
    <div className="fixed-header light-tone header-tabs-container ">
      <div
        className={clsx(
          "d-flex align-items-center justify-content-between w-100",
          {
            "px-4": !isMobile,
          }
        )}
      >
        <div className="d-flex align-items-center justify-content-center">
          <SpanWithTooltip
            className={"broadcast-header-icon mr-2"}
            title="Broadcasts"
          >
            <AllPeopleIcon />
          </SpanWithTooltip>
          <h4 className="mt-2">
            Showing All Broadcasts{" "}
            <Chip
              label="Beta"
              classes={{
                root: "send-new-message-beta-chip mb-2",
                label: "send-new-message-beta-chip-label",
              }}
            />
          </h4>
        </div>
        {addBroadcastIcon}
      </div>
    </div>
  );
}
