import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PopoverSelect from "../../hub/HelperComponents/PopoverSelect/PopoverSelect";
import { v1 as uuidv1 } from "uuid";
import { formatSenderNumberObject } from "../../../utils/sendMessageHelpers";

const MessagesCard = (props) => {
  const {
    type,
    className,
    padding,
    label,
    options,
    optionType,
    selected,
    onChangeSelectValue,
    children,
    popoverId,
    withRedLabel,
  } = props;

  const [formattedOptions, setFormattedOptions] = useState([]);
  const [formattedSelected, setFormattedSelected] = useState({});

  useEffect(() => {
    if (optionType === "senderNumbers") {
      const mappedSenderNumbers = options.map((item) =>
        formatSenderNumberObject(item)
      );
      setFormattedOptions(mappedSenderNumbers);
      setFormattedSelected(formatSenderNumberObject(selected));
    } else {
      setFormattedOptions(options);
      setFormattedSelected(selected);
    }
  }, [options, optionType, selected]);

  return (
    <>
      {label !== "" && <span className="messages-card__label">{label}</span>}
      {(type === "div" || type === "filled") && (
        <div
          className={clsx("messages-card", className, {
            "messages-card__filled": type === "filled",
          })}
          style={{ padding: padding }}
        >
          {children}
        </div>
      )}
      {type === "select" && (
        <PopoverSelect
          id={popoverId || `messages-card-from-${uuidv1()}`}
          selectBoxClassName={clsx(
            "messages-card messages-card__popover",
            className
          )}
          text={formattedSelected?.name}
          options={formattedOptions}
          value={formattedSelected?.value}
          onSelect={onChangeSelectValue || (() => {})}
          withRedLabel={withRedLabel}
        />
      )}
    </>
  );
};

MessagesCard.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  padding: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  selectedName: PropTypes.string,
  selectedValue: PropTypes.any,
  withRedLabel: PropTypes.bool,
};

MessagesCard.defaultProps = {
  type: "div",
  className: "muted",
  padding: "10px",
  label: "",
  options: [],
  value: null,
  selectedName: "",
  selectedValue: null,
  withRedLabel: false,
};

export default MessagesCard;
