import React from "react";
import PropTypes from "prop-types";
import DashboardCard from "./DashboardCard";
import ProgressCircular from "../../elements/ProgressCircular";
import { ReactComponent as CircleQuestionMarkSvg } from "../../../assets/img/icons-new/people-tab/number-status/number-status-light-gray.svg";

const CreditsUsedWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    onSizeChange,
    isActive,
    index,
    onClickTrash,
  } = props;

  return (
    <DashboardCard
      headerContent={"Credits Used"}
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      onSizeChange={onSizeChange}
      isActive={isActive}
      index={index}
      containerClassName="align-items-center"
      onClickTrash={onClickTrash}
    >
      <div className="credits-used-widget-container">
        <div className="mb-1">Total Credits Used</div>
        <div className="d-inline-flex flex-column align-items-center">
          <ProgressCircular value={73} />
          <div className="circular-progress-value mb-3">73/100</div>
        </div>

        <div className="mb-1">Total Credits Used for Sending Messages</div>
        <div className="d-inline-flex flex-column align-items-center">
          <ProgressCircular value={10} />
          <div className="circular-progress-value mb-3">10/100</div>
        </div>

        <div className="mb-1">Total Credits Used for Receiving Messages</div>
        <div className="d-inline-flex flex-column align-items-center">
          <ProgressCircular value={50} />
          <div className="circular-progress-value mb-3">50/100</div>
        </div>
        <div>
          <button className="btn btn-outline-primary">Buy More Credits</button>
          <CircleQuestionMarkSvg className="ml-3" />
        </div>
      </div>
    </DashboardCard>
  );
};

CreditsUsedWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};

CreditsUsedWidget.defaultProps = {
  isActive: false,
};

export default CreditsUsedWidget;
