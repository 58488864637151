import { createMuiTheme } from "@material-ui/core/styles";

export const generalTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#5517C1",
    },
  },
  overrides: {
    MuiPopover: {
      root: {
        zIndex: "100500 !important",
      },
    },
    MuiInput: {
      underline: {
        "&&&:before": {
          borderBottom: `1px solid #DBDBDB`,
        },
        "&&&:after": {
          borderBottom: "1px solid #5517C1",
        },
        "&&&.Mui-error:after": {
          borderBottom: "1px solid #FF5800",
        },
      },
    },
    MuiTextField: {},
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          color: "#FF5800",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-error": {
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: "#C9CECD",
        "&$checked": {
          color: "#5517C1",
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "1rem",
      },
    },
  },
});

export const plBasicTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "black",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        marginTop: "15px",
      },
    },
    MuiTextField: {
      root: {
        "& .MuiInput-underline:after": {
          borderBottomColor: "#5517C1",
        },
      },
    },
    MuiSnackbar: {
      root: {
        backgroundColor: "#fff",
        borderRadius: "4px",
        zIndex: 99009,
      },
      anchorOriginBottomLeft: {
        left: "56px",
        "@media (min-width: 600px)": {
          left: "56px",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#fff",
        color: "#202020",
      },
    },
    MuiTableContainer: {
      root: {
        height: "40vh",
      },
    },
    MuiTablePagination: {
      selectRoot: {
        display: "none",
      },
      spacer: {
        display: "none",
      },
    },
    MuiSwitch: {
      root: {
        position: "relative",
        top: 4,
      },
      colorPrimary: {
        "&$checked $thumb": {
          color: "#6C37C9",
        },
        "&$checked + $track": {
          backgroundColor: "#D3D0D0",
        },
      },
      thumb: {
        width: 13,
        height: 13,
        boxShadow: "none",
      },
      track: {
        height: 10,
        width: 30,
        position: "relative",
        top: -1,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "#C9CECD",
        "&$checked": {
          color: "#5517C1",
        },
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: "transparent",
        },
        "&$selected:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: "#C9CECD",
        "&$checked": {
          color: "#5517C1",
        },
      },
    },
  },
});

export const countrySelectTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      icon: {
        opacity: 0.87,
      },
      root: {
        fontSize: "13.5px",
        paddingLeft: "20px",
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
