import React, { useState } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/img/icons-new/play-circle/play-circle-white.svg";
import clsx from "clsx";
import Spinner from "../../hub/HelperComponents/Spinner";

const VideoThumbnail = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const { videoUrl } = props;

  const handleVideoLoaded = () => {
    // Video has finished loading, hide the loading indicator
    setIsLoading(false);
  };

  const handleVideoError = () => {
    setIsLoading(false);
    setIsErrorOccured(true);
  };

  return (
    <>
      <span className="d-flex h-100 w-100 align-items-center justify-content-center">
        {isLoading ? (
          <Spinner
            className="d-flex align-items-center"
            innerClassName="text-dark"
          />
        ) : (
          <PlayIcon className="video-play-circle-icon" height={40} />
        )}
        <video
          width="100%"
          height="100%"
          className={clsx("video-thumbnail", {
            "d-none": isLoading,
            "corrupt-video": isErrorOccured,
          })}
          preload="auto"
          controls={false}
          autoPlay={false}
          onLoadedData={handleVideoLoaded}
          onError={handleVideoError}
        >
          <source src={videoUrl} type={"video/mp4"} />
        </video>
      </span>
    </>
  );
};

export default VideoThumbnail;
