import { arrayToUrl, getRequest, postRequest } from "../utils/requests";

export function fetchGifts() {
  return (dispatch) =>
    getRequest(dispatch, "DASHBOARD/FETCH_GIFTS", "gifts.json");
}

export function fetchDashboardStats(
  companyId,
  numbersFilter,
  previous_cycle_offset = 0
) {
  let url = arrayToUrl(
    `threads/dashboard-stats.json?companyId=${companyId}&`,
    numbersFilter,
    "numbersFilter"
  );
  url += `&previous_cycle_offset=${previous_cycle_offset}`;
  return (dispatch) =>
    getRequest(dispatch, "DASHBOARD/FETCH_DASHBOARD_STATS", url);
}

export function fetchDashboard(requestId) {
  return (dispatch) =>
    getRequest(dispatch, "DASHBOARD/FETCH_DASHBOARD", "users/dashboard.json", {
      requestDispatchData: { requestId },
      successDispatchData: { requestId },
      errorDispatchData: { requestId },
    });
}

export function postDashboard(body) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "DASHBOARD/POST_DASHBOARD",
      "users/dashboard.json",
      body
    );
}

export function clearPostDashboard(body) {
  return (dispatch) =>
    dispatch({
      type: "DASHBOARD/POST_DASHBOARD.CLEAR",
    });
}
