import React from "react";
import { ReactComponent as ScreenIconSvg } from "../../assets/img/icons-new/general/screen/screen-black.svg";

const ThemeMenu = () => {
  return (
    <a className="menuitem" href="#modal-display-preferences">
      <span className="d-flex align-items-center">
        <ScreenIconSvg width={17} height={17} className="mr-2" />
        <span>Preferences</span>
      </span>
      <i className="fa fa-chevron-right arrow-icon"></i>
    </a>
  );
};

export default ThemeMenu;
