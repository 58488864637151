import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Checkbox,
  TablePagination,
  TableFooter,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { formatPhoneNumber, isEmptyObject } from "../../../helpers";
import SpanWithTooltip from "../../SpanWithTooltip";
import IntegrationIcon from "../../IntegrationIcon";
import LookupIcon from "../../LookupIcon";
//new icons
import { ReactComponent as HasDuplicateIcon } from "../../../assets/img/icons-new/group-tab/group-icon/group-inverse-gray.svg";
import { ReactComponent as CloseSmallIcon } from "../../../assets/img/icons-new/general/close/close-gray.svg";
import { ReactComponent as DuplicateIcon } from "../../../assets/img/icons-new/general/duplicate/duplicate-black.svg";
import flagUsSvg from "../../../assets/img/icons-new/general/flags/US-flag.svg";

import { plBasicTheme } from "../../../assets/base/theme";
import PrimaryContactsDialog from "./PrimaryContactsDialog";
import PrimaryContactSelection from "./PrimaryContactSelection";
import TablePaginationActions from "../../hub/HelperComponents/TablePaginationActions";
import PlusMinusCheckbox from "../../hub/HelperComponents/PlusMinusCheckbox";

function mapStateToProps(store) {
  return {
    skipSendingTime: store.groups.skipSendingTime,
    groupData: store.groups.acionGroupData,
    subMembersData: store.groups.subMembersData,
    groupMembersData: store.groups.actionGroupMembersData,
    contactsData: store.contacts.data,
    archivedDuplicatesInGroups: store.groups.archivedDuplicatesInGroups,
    archivedDuplicatesData: store.groups.archivedDuplicatesData,
    unarchivedDuplicatesInGroups: store.groups.unarchivedDuplicatesInGroups,
    unarchivedDuplicatesData: store.groups.unarchivedDuplicatesData,
    infoSnackbarStatus: store.contacts.infoSnackbarStatus,
  };
}

class SimilarContactsModal extends React.Component {
  constructor(props) {
    super(props);

    this.updateView = this.updateView.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectAllClick = this.onSelectAllClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handlePrimaryDialogClose = this.handlePrimaryDialogClose.bind(this);
    this.checkPrimaryAndProceed = this.checkPrimaryAndProceed.bind(this);
    this.handlePrimaryContactToggling =
      this.handlePrimaryContactToggling.bind(this);
    this.populateState = this.populateState.bind(this);
    this.populateStateForGroup = this.populateStateForGroup.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
      redirect: null,
      page: 0,
      setSwitchBox: false,
      membersData: {},
      selectionStatus: "selected",
      showPrimaryDialogModal: false,
      selectedIdsPayload: [],
      nonDuplicateSelectedContactIds: [],
      showPrimarySelectionModal: false,
      newPrimaryContact: {},
      primaryAssigneesData: {},
      oldPrimaryAssigneesData: {},
      selectedPrimaryContactIds: [],
    };
  }

  componentDidMount() {
    if (this.props.isGroup) {
      this.populateStateForGroup();
    } else {
      this.populateState();
    }
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      if (this.props.isGroup) {
        this.populateStateForGroup();
      } else {
        this.populateState();
      }
      this.updateView();
    }
  }

  getUniqueValueFromTwoArrays = (
    allMembersIds = [],
    duplicateMembersIds = []
  ) => {
    return allMembersIds.filter((item) => !duplicateMembersIds.includes(item));
  };

  setDuplicatesFromGroupesMember = (archiveVal, membersData = {}) => {
    const { groupMembersData } = this.props;
    for (const member in groupMembersData) {
      if (
        groupMembersData[member].contacts_ids &&
        groupMembersData[member].contacts_ids.length > 1
      ) {
        const appContactIds = groupMembersData[member].contacts_ids;
        for (let i = 0; i < appContactIds.length; i++) {
          const addContact = { ...this.props.contactsData[appContactIds[i]] };
          if (addContact && addContact.is_archived === archiveVal) {
            if (
              parseInt(addContact.id) === parseInt(member) &&
              !addContact?.inaccessible
            ) {
              addContact.selected = true;
            }
            if (membersData[member] && membersData[member].length > 0) {
              const exists = membersData[member].filter(
                (data) => data.id === addContact.id
              );
              if (exists.length < 1) {
                membersData[member].push(addContact);
              }
            } else {
              membersData[member] = [addContact];
            }
          }
        }
      }
    }
  };

  populateStateForGroup() {
    let groupMembersData = {};
    let duplicatesData = {};
    if (this.props.unarchive) {
      groupMembersData = this.props.unarchivedDuplicatesInGroups;
      duplicatesData = this.props.unarchivedDuplicatesData;
    } else {
      groupMembersData = this.props.archivedDuplicatesInGroups;
      duplicatesData = this.props.archivedDuplicatesData;
    }
    const membersData = {};
    const archiveVal = this.props.unarchive ? 1 : 0;
    for (const member in groupMembersData) {
      if (
        groupMembersData[member].contacts_ids &&
        groupMembersData[member].contacts_ids.length > 1
      ) {
        const appContactIds = groupMembersData[member].contacts_ids;
        for (let i = 0; i < appContactIds.length; i++) {
          const addContact = {
            ...duplicatesData[appContactIds[i]],
          };
          if (addContact && addContact.is_archived === archiveVal) {
            if (
              parseInt(addContact.id) === parseInt(member) &&
              !addContact?.inaccessible
            ) {
              addContact.selected = true;
            }
            if (membersData[member] && membersData[member].length > 0) {
              const exists = membersData[member].filter(
                (data) => data.id === addContact.id
              );
              if (exists.length < 1) {
                membersData[member].push(addContact);
              }
            } else {
              membersData[member] = [addContact];
            }
          }
        }
      }
    }
    this.setState({ membersData });
  }

  populateState() {
    const subMembersData = this.props.subMembersData;
    const groupMembersData = this.props.groupMembersData;
    const membersData = {};
    let nonDuplicateSelectedContactIds = [];
    const archiveVal = this.props.unarchive ? 1 : 0;
    if (this.props.contactDuplicates) {
      const selectedIds = [];
      let focalContact;
      for (const member in subMembersData) {
        const ids = subMembersData[member].map((s) => s.id);
        selectedIds.push(...ids);
        focalContact = subMembersData[member][0];
      }
      const memberIds = groupMembersData
        ? Object.keys(groupMembersData).map((key) => parseInt(key))
        : [];
      selectedIds.push(...memberIds);
      const memberKeys = Object.keys(groupMembersData);
      if (!focalContact && memberKeys.length > 0) {
        focalContact = groupMembersData[memberKeys[0]];
      }
      if (
        focalContact &&
        focalContact.contacts_ids &&
        focalContact.contacts_ids.length > 1
      ) {
        const appContactIds = focalContact.contacts_ids;
        for (let i = 0; i < appContactIds.length; i++) {
          const addContact = {
            ...this.props.contactsData[appContactIds[i]],
          };
          if (addContact && addContact.is_archived === archiveVal) {
            if (
              !addContact?.inaccessible &&
              (parseInt(addContact.id) === parseInt(focalContact.id) ||
                selectedIds.indexOf(addContact.id) !== -1)
            ) {
              addContact.selected = true;
            }
            if (
              membersData[focalContact.id] &&
              membersData[focalContact.id].length > 0
            ) {
              const exists = membersData[focalContact.id].filter(
                (data) => data.id === addContact.id
              );
              if (exists.length < 1) {
                membersData[focalContact.id].push(addContact);
              }
            } else {
              membersData[focalContact.id] = [addContact];
            }
          }
        }
      }
      if (isEmptyObject(membersData) && groupMembersData) {
        this.setDuplicatesFromGroupesMember(archiveVal, membersData);
      }
    } else {
      for (const member in subMembersData) {
        for (const subMember of subMembersData[member]) {
          if (subMember.contacts_ids && subMember.contacts_ids.length > 1) {
            const appContactIds = subMember.contacts_ids;
            for (let i = 0; i < appContactIds.length; i++) {
              const addContact = {
                ...this.props.contactsData[appContactIds[i]],
              };
              if (addContact && addContact.is_archived === archiveVal) {
                const contactIds = subMembersData[member].map((c) => c.id);
                const existInList = contactIds.includes(addContact.id);
                if (
                  !addContact?.inaccessible &&
                  (parseInt(addContact.id) === parseInt(subMember.id) ||
                    existInList)
                ) {
                  addContact.selected = true;
                }
                if (membersData[member] && membersData[member].length > 0) {
                  const exists = membersData[member].filter(
                    (data) => data.id === addContact.id
                  );
                  if (exists.length < 1) {
                    membersData[member].push(addContact);
                  }
                } else {
                  membersData[member] = [addContact];
                }
              }
            }
          }
        }
      }
      this.setDuplicatesFromGroupesMember(archiveVal, membersData);
    }
    if (!isEmptyObject(groupMembersData) && !isEmptyObject(membersData)) {
      nonDuplicateSelectedContactIds = this.getUniqueValueFromTwoArrays(
        Object.keys(groupMembersData),
        Object.keys(membersData)
      );
    }

    if (!isEmptyObject(groupMembersData)) {
      nonDuplicateSelectedContactIds = Object.keys(groupMembersData);
    }

    this.setState({ membersData, nonDuplicateSelectedContactIds });
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  getSelectedIds = (all) => {
    const { membersData, nonDuplicateSelectedContactIds } = this.state;
    const selectedIds = [];
    for (const pageId in membersData) {
      const selectedItems = membersData[pageId].filter((member) => {
        if (all) {
          return member;
        }
        return member.selected;
      });
      selectedIds.push(...selectedItems.map((item) => parseInt(item.id)));
    }
    if (nonDuplicateSelectedContactIds.length) {
      return [...selectedIds, ...nonDuplicateSelectedContactIds];
    }
    return selectedIds;
  };

  closeModal = (val) => () => {
    let selectedIds = [];
    const { selectionStatus, setSwitchBox } = this.state;
    let checkAll = false;

    if (!val) {
      this.props.onClose(val);
      return;
    }

    if (setSwitchBox) {
      if (selectionStatus === "all") {
        selectedIds = this.getSelectedIds(true);
        checkAll = true;
      } else if (selectionStatus === "selected") {
        selectedIds = this.getSelectedIds(false);
      }
    } else {
      selectedIds = this.getSelectedIds(false);
    }
    this.setState(
      {
        selectedIdsPayload: selectedIds,
      },
      () => this.checkPrimaryAndProceed(checkAll)
    );
  };

  checkPrimaryAndProceed = (all) => {
    const primaryAssigneesData = {};
    const oldPrimaryAssigneesData = {};
    const { membersData, selectedIdsPayload } = this.state;
    const key = isEmptyObject(membersData) ? "" : Object.keys(membersData)[0];
    const allMembers = key ? membersData[key] : [];
    const primaryContactIds = allMembers.length
      ? allMembers[0].primary_contact_ids
      : [];
    const selectedPrimaryContactIds = selectedIdsPayload.filter((item) =>
      primaryContactIds.includes(item)
    );
    if (all && !this.props.unarchive) {
      this.props.onClose(true, this.state.selectedIdsPayload);
    } else {
      if (this.props.contactDuplicates) {
        const dataByApp = {};
        const selectedItems = [];
        for (const memberItem of membersData[key]) {
          if (!!memberItem.is_ccb) {
            if (dataByApp["ccb"]) {
              dataByApp["ccb"].push(memberItem);
            } else {
              dataByApp["ccb"] = [memberItem];
            }
          } else if (!!memberItem.is_pco) {
            if (dataByApp["pco"]) {
              dataByApp["pco"].push(memberItem);
            } else {
              dataByApp["pco"] = [memberItem];
            }
          } else if (!!memberItem.is_mc) {
            if (dataByApp["mc"]) {
              dataByApp["mc"].push(memberItem);
            } else {
              dataByApp["mc"] = [memberItem];
            }
          } else if (!!memberItem.is_elvanto) {
            if (dataByApp["el"]) {
              dataByApp["el"].push(memberItem);
            } else {
              dataByApp["el"] = [memberItem];
            }
          } else if (!!memberItem.is_breeze) {
            if (dataByApp["breeze"]) {
              dataByApp["breeze"].push(memberItem);
            } else {
              dataByApp["breeze"] = [memberItem];
            }
          } else if (!!memberItem.is_rock) {
            if (dataByApp["rock"]) {
              dataByApp["rock"].push(memberItem);
            } else {
              dataByApp["rock"] = [memberItem];
            }
            if (dataByApp["rock_group"]) {
              dataByApp["rock_group"].push(memberItem);
            } else {
              dataByApp["rock_group"] = [memberItem];
            }
          }
        }
        for (const app in dataByApp) {
          const selectedInApp = dataByApp[app].filter(
            (contact) => contact.selected
          );
          let nonPrimaries = [];
          if (selectedInApp.length > 0) {
            selectedItems.push(
              ...dataByApp[app].filter((member) => {
                return member.selected && member.is_primary;
              })
            );
            if (this.props.unarchive) {
              for (const id of selectedInApp[0].app_contact_ids) {
                const contact = this.props.contactsData[id];
                if (!contact.is_primary && !contact.is_archived) {
                  nonPrimaries.push({
                    ...contact,
                  });
                } else if (contact.is_primary) {
                  selectedItems.push({
                    ...contact,
                  });
                }
              }
              nonPrimaries.push(
                ...dataByApp[app].filter((member) => {
                  return member.selected && !member.is_primary;
                })
              );
            } else if (selectedItems.length > 0) {
              for (const id of selectedInApp[0].app_contact_ids) {
                const contact = this.props.contactsData[id];
                const existInMembers = membersData[key].filter(
                  (member) => contact.id === member.id
                );
                if (
                  !contact.is_primary &&
                  (existInMembers.length < 1 || !existInMembers[0].selected) &&
                  !contact.is_archived
                ) {
                  nonPrimaries.push({
                    ...contact,
                  });
                }
              }
            }
          }
          if (nonPrimaries.length > 0 && selectedItems.length > 0) {
            nonPrimaries[0].markedForPrimary = true;
            oldPrimaryAssigneesData[app] = selectedItems[0].id;
            primaryAssigneesData[app] = nonPrimaries;
          }
        }
      } else {
        for (const pageId in membersData) {
          const selectedItems = membersData[pageId].filter((member) => {
            return member.selected && member.is_primary;
          });
          if (selectedItems.length > 0 || this.props.unarchive) {
            let nonPrimaries = [];
            if (this.props.unarchive) {
              for (const id of membersData[pageId][0].app_contact_ids) {
                const contact = this.props.contactsData[id];
                if (!contact.is_primary && !contact.is_archived) {
                  nonPrimaries.push({
                    ...contact,
                  });
                } else if (contact.is_primary) {
                  selectedItems.push({
                    ...contact,
                  });
                }
              }
              nonPrimaries.push(
                ...membersData[pageId].filter((member) => {
                  return member.selected && !member.is_primary;
                })
              );
            } else if (
              !this.props.unarchive &&
              selectedPrimaryContactIds.length > 1 &&
              selectedIdsPayload?.length !== allMembers?.length
            ) {
              const contactIds =
                membersData[pageId] && membersData[pageId].length
                  ? membersData[pageId][0].contacts_ids
                  : [];
              for (const id of contactIds) {
                const contact = this.props.contactsData[id];
                if (!contact.is_primary && !contact.is_archived) {
                  nonPrimaries.push({
                    ...contact,
                  });
                }
              }
              nonPrimaries.push(
                ...membersData[pageId].filter((member) => {
                  return member.selected && !member.is_primary;
                })
              );
            } else {
              for (const id of selectedItems[0].app_contact_ids) {
                const contact = this.props.contactsData[id];
                const existInMembers = membersData[pageId].filter(
                  (member) => contact?.id === member?.id
                );
                if (
                  contact &&
                  !contact?.is_primary &&
                  (existInMembers.length < 1 || !existInMembers[0].selected) &&
                  !contact?.is_archived
                ) {
                  nonPrimaries.push({
                    ...contact,
                  });
                }
              }
            }
            if (nonPrimaries.length > 0 && selectedItems.length > 0) {
              nonPrimaries[0].markedForPrimary = true;
              oldPrimaryAssigneesData[pageId] = selectedItems[0].id;
              primaryAssigneesData[pageId] = nonPrimaries;
            }
          }
        }
      }
      const primaryKeys = Object.keys(primaryAssigneesData);
      if (
        primaryKeys.length > 0 ||
        (selectedPrimaryContactIds.length > 1 &&
          selectedIdsPayload?.length !== allMembers?.length &&
          primaryKeys.length > 0)
      ) {
        if (
          this.props.unarchive &&
          primaryKeys.length === 1 &&
          primaryAssigneesData[primaryKeys[0]].length === 1
        ) {
          this.handlePrimaryDialogClose("auto");
        } else {
          this.setState({
            showPrimaryDialogModal: true,
            selectedPrimaryContactIds,
            primaryAssigneesData,
            oldPrimaryAssigneesData,
          });
        }
      } else if (
        !this.props.unarchive &&
        !primaryKeys?.length &&
        selectedIdsPayload?.length !== allMembers?.length &&
        primaryContactIds.length === allMembers?.length
      ) {
        const primaryAssignees = {};
        if (selectedIdsPayload.length) {
          selectedIdsPayload.forEach(
            (item) => (primaryAssignees[item] = "auto")
          );
        }
        this.props.onClose(
          true,
          this.state.selectedIdsPayload,
          primaryAssignees
        );
      } else {
        this.props.onClose(true, this.state.selectedIdsPayload);
      }
    }
  };

  handleSwitchBox = () => {
    this.setState({ setSwitchBox: !this.state.setSwitchBox });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: parseInt(newPage) });
  };

  onSelectAllClick = () => {
    const pagesIndexes = Object.keys(this.state.membersData);
    const { page, membersData } = this.state;
    let selectionStatus = "";

    const alreadySelected = membersData[pagesIndexes[page]].filter(
      (contact) => contact.selected === true
    );
    if (alreadySelected.length === membersData[pagesIndexes[page]].length) {
      const newData = membersData[pagesIndexes[page]].map((contact) => {
        contact.selected = false;
        return contact;
      });
      membersData[pagesIndexes[page]] = newData;
      selectionStatus = "none";
    } else {
      const newData = membersData[pagesIndexes[page]].map((contact) => {
        if (!contact?.inaccessible) {
          contact.selected = true;
        }
        return contact;
      });
      membersData[pagesIndexes[page]] = newData;
      selectionStatus = "all";
    }

    this.setState({
      membersData,
      selectionStatus,
    });
  };

  handleRowClick = (id) => () => {
    const pagesIndexes = Object.keys(this.state.membersData);
    const { page, membersData } = this.state;

    const newData = membersData[pagesIndexes[page]].map((contact) => {
      if (contact.id === id) {
        contact.selected = contact.selected ? false : true;
      }
      return contact;
    });
    membersData[pagesIndexes[page]] = newData;
    this.setState({ membersData, selectionStatus: "none" });
  };

  getPrimaryAssignees = (newState) => {
    const {
      primaryAssigneesData,
      oldPrimaryAssigneesData,
      selectedPrimaryContactIds,
    } = this.state;
    const primaryAssignees = {};
    for (const page in primaryAssigneesData) {
      const oldAssignee = oldPrimaryAssigneesData[page];
      if (newState) {
        const assignee = newState[page].filter(
          (member) => member.markedForPrimary
        );
        if (assignee.length) {
          if (selectedPrimaryContactIds.length > 1) {
            selectedPrimaryContactIds.forEach(
              (item) => (primaryAssignees[item] = assignee[0].id)
            );
          } else {
            primaryAssignees[oldAssignee] = assignee[0].id;
          }
        }
      } else {
        primaryAssignees[oldAssignee] = "auto";
      }
    }
    return primaryAssignees;
  };

  handlePrimaryDialogClose = (val) => {
    if (val === "cancel") {
      this.setState({ showPrimaryDialogModal: false });
    } else if (val === "auto") {
      const ids = this.getPrimaryAssignees();
      this.props.onClose(true, this.state.selectedIdsPayload, ids);
    } else if (val === "manual") {
      this.setState({ showPrimarySelectionModal: true });
    }
  };

  handlePrimaryContactToggling = (val, newState) => {
    if (val) {
      const ids = this.getPrimaryAssignees(newState);
      this.props.onClose(true, this.state.selectedIdsPayload, ids);
    } else {
      this.props.onClose(false, this.state.selectedIdsPayload);
    }
  };

  render() {
    const pagesIndexes = Object.keys(this.state.membersData);
    const { page, membersData } = this.state;
    const selectedContacts =
      membersData && membersData[pagesIndexes[page]]
        ? membersData[pagesIndexes[page]].filter(
            (contact) => contact.selected === true
          )
        : [];
    const tableColumns = [
      { id: "first_name", label: "First Name", minWidth: 100 },
      { id: "last_name", label: "Last Name", minWidth: 100 },
      { id: "phone_number", label: "Phone Number", minWidth: 180 },
      { id: "email", label: "Email", minWidth: 100 },
    ];

    return (
      <div
        className={
          "modal fade inbox-modal confirm-group-message" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="SkipContactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SkipContactModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-backdrop fade show"
          onClick={(e) => {
            if (this.props.infoSnackbarStatus !== "displaying") {
              e.preventDefault();
              this.closeModal(false)();
            }
          }}
        />
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ zIndex: 100 }}
        >
          <div
            className="modal-content similar-contact-modal-content themed-modal"
            style={{ minWidth: "740px" }}
          >
            <button
              type="button"
              className="close-archived"
              aria-label="Close"
              onClick={this.closeModal(false)}
            >
              <CloseSmallIcon />
            </button>
            <div className="modal-body">
              <div className="skip-sending-multiple-content">
                {this.props.modalBodyHeader}
              </div>
              <div className="skip-sending-multilple-sub-content">
                {this.props.modalBodyContent}
              </div>
              <MuiThemeProvider theme={plBasicTheme}>
                <TableContainer component="div">
                  <Table
                    aria-label="duplicate contacts table"
                    height="40vh"
                    stickyHeader
                    className="themed-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selectedContacts.length > 0 &&
                              selectedContacts.length <
                                membersData[pagesIndexes[page]].length
                            }
                            checked={
                              selectedContacts.length > 0 &&
                              selectedContacts.length ===
                                membersData[pagesIndexes[page]].length
                            }
                            onChange={this.onSelectAllClick}
                            inputProps={{ "aria-label": "select all contacts" }}
                          />
                        </TableCell>
                        {tableColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            colSpan={1}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {membersData &&
                        membersData[pagesIndexes[page]] &&
                        membersData[pagesIndexes[page]].map((row) => (
                          <TableRow
                            key={row.id}
                            role="checkbox"
                            aria-checked={
                              selectedContacts &&
                              selectedContacts.length > 0 &&
                              selectedContacts.indexOf(row) !== -1
                            }
                            selected={
                              selectedContacts &&
                              selectedContacts.length > 0 &&
                              selectedContacts.indexOf(row) !== -1
                            }
                            onClick={this.handleRowClick(row.id)}
                          >
                            <TableCell padding="checkbox">
                              <PlusMinusCheckbox
                                checked={
                                  selectedContacts &&
                                  selectedContacts.length > 0 &&
                                  selectedContacts.indexOf(row) !== -1
                                }
                                disabled={selectedContacts?.inaccessible}
                                className="ml-3"
                              />
                            </TableCell>
                            {tableColumns.map((column) => {
                              const value = row[column.id];
                              if (column.id === "phone_number") {
                                return (
                                  <TableCell
                                    key={column.id}
                                    className="themed-table-cell"
                                  >
                                    <div className="number">
                                      <span className="numeric">
                                        {formatPhoneNumber(
                                          "" +
                                            (row.country
                                              ? row.country.phonecode
                                              : "") +
                                            row.phone_number
                                        )}
                                      </span>
                                      {row._isIntegration && (
                                        <SpanWithTooltip
                                          className="icon ccb"
                                          title={this.props.alertTooltip}
                                        >
                                          <IntegrationIcon
                                            {...row}
                                            baseSize={16}
                                          />
                                        </SpanWithTooltip>
                                      )}
                                      {!row._isIntegration && (
                                        <span className="icon">
                                          <IntegrationIcon
                                            {...row}
                                            baseSize={16}
                                          />
                                        </span>
                                      )}
                                      <span className="icon">
                                        {!!row.is_duplicate && (
                                          <DuplicateIcon
                                            width={20}
                                            className=""
                                          />
                                        )}
                                        {!row.is_duplicate && (
                                          <HasDuplicateIcon
                                            width={20}
                                            className=""
                                          />
                                        )}
                                      </span>
                                      <span className="icon">
                                        <LookupIcon
                                          {...row.number_lookup}
                                          baseSize={16}
                                          newLookupIcon
                                        />
                                      </span>
                                      {row.country &&
                                        row.country.iso === "US" && (
                                          <span className="icon">
                                            <img
                                              src={flagUsSvg}
                                              width="16"
                                              alt="US"
                                            />
                                          </span>
                                        )}
                                    </div>
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  key={column.id}
                                  className="themed-table-cell"
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                    {pagesIndexes.length > 1 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            style={{
                              position: "absolute",
                              bottom: 60,
                              width: "100%",
                            }}
                            colSpan={5}
                            page={page}
                            onChangePage={this.handleChangePage}
                            ActionsComponent={TablePaginationActions}
                            labelRowsPerPage=""
                            labelDisplayedRows={() => ""}
                            count={pagesIndexes.length}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </TableContainer>

                <div className="modal-footer mt-gap">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={this.closeModal(false)}
                    // style={{ color: "#5517c1" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.closeModal(true)}
                    // style={{ color: "white" }}
                    disabled={selectedContacts.length === 0}
                  >
                    {this.props.unarchive ? "Yes, unarchive" : "Yes, archive"}
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
        <PrimaryContactsDialog
          show={this.state.showPrimaryDialogModal}
          onClose={this.handlePrimaryDialogClose}
          unarchive={this.props.unarchive}
        />
        <PrimaryContactSelection
          show={this.state.showPrimarySelectionModal}
          onClose={this.handlePrimaryContactToggling}
          primaryAssigneesData={this.state.primaryAssigneesData}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(SimilarContactsModal));
