import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ReactComponent as NewMessageSvg } from "../../../assets/img/icons-new/new-message/new-message-black.svg";

const MessageCircleButton = (props) => {
  const { active, onClick, disabled, className } = props;
  return (
    <span className="flexer-col">
      <span
        className={clsx("calling-features-wrapper", className, {
          active: active,
          disabled: disabled,
        })}
        onClick={onClick}
      >
        <NewMessageSvg height={32} width={32} />
      </span>
      <label className="mt-1">Message</label>
    </span>
  );
};

MessageCircleButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

MessageCircleButton.defaultProps = {
  active: false,
  disabled: false,
  className: "",
  onClick: () => {},
};

export default MessageCircleButton;
