import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  isLgOrBelowBreakpoint,
  isMdOrBelowBreakpoint,
} from "../../utils/breakpoints";
import clsx from "clsx";
import ActionButton from "../ActionButton/ActionButton";

import { ReactComponent as AddIcon } from "../../assets/img/icons-new/general/add-icon/add-gray.svg";
import SidebarBottom from "./SidebarBottom";
import SidebarTop from "./SidebarTop/SidebarTop";
import Scrollbars from "react-custom-scrollbars";

import { isAccountClosed, isAccountNeedRenew } from "../../helpers";
import { useHistory } from "react-router-dom";

import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";
import useMessagesStore from "../../utils/hooks/ReduxHooks/messagesStore";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { useIsSidebarExpanded } from "../../utils/hooks/useIsSidebarExpanded";

import { useParams } from "react-router-dom";
import VerticalThumbDarkTheme from "../elements/VerticalThumbDarkTheme";
import { isReactNativeApp } from "../../helpers";

const Sidebar = (props) => {
  const { style } = props;
  const breakpoint = useBreakpoint();
  const { tab, filter } = useParams();

  const {
    companies: { currentCompany },
  } = useCompaniesStore();

  const {
    messages: { openFilters },
    toggleFilters,
  } = useMessagesStore();

  const history = useHistory();

  const isSidebarExpanded = useIsSidebarExpanded();

  const onRedirect = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  useEffect(() => {
    if (isLgOrBelowBreakpoint(breakpoint)) {
      toggleFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isAddNewPopoverOpen, setIsAddNewPopoverOpen] = useState(false);

  const handleToggleFilters = useCallback(() => {
    if (
      isMdOrBelowBreakpoint(breakpoint) &&
      tab === "menus" &&
      !filter &&
      !openFilters
    ) {
      toggleFilters(true);
    }
  }, [breakpoint, filter, openFilters, tab, toggleFilters]);

  useEffect(() => {
    handleToggleFilters();
  }, [handleToggleFilters]);

  const toggleAddNewPopover = useCallback(() => {
    if (isReactNativeApp()) setIsAddNewPopoverOpen((prev) => !prev);
  }, []);

  const openAddNewModal = useCallback(() => {
    if (!isReactNativeApp()) onRedirect("#modal-add-new");
  }, [onRedirect]);

  const topContent = useMemo(() => {
    const content = (
      <SidebarTop
        isAddNewPopoverOpen={isAddNewPopoverOpen}
        isSidebarExpanded={isSidebarExpanded}
        toggleAddNewPopover={toggleAddNewPopover}
        openAddNewModal={openAddNewModal}
        breakpoint={breakpoint}
      />
    );
    return isMdOrBelowBreakpoint(breakpoint) ? (
      content
    ) : (
      <Scrollbars
        autoHeight
        autoHeightMax={"100%"}
        renderThumbVertical={VerticalThumbDarkTheme}
        renderTrackHorizontal={() => <div className="track-horizontal" />}
      >
        <div className="mb-2">{content}</div>
      </Scrollbars>
    );
  }, [
    breakpoint,
    isAddNewPopoverOpen,
    isSidebarExpanded,
    toggleAddNewPopover,
    openAddNewModal,
  ]);

  const sideBarInnerOverlay = useMemo(() => {
    if (isAccountClosed(currentCompany) || isAccountNeedRenew(currentCompany))
      return <div className="sidebar-inner-overlay" />;

    return null;
  }, [currentCompany]);

  return (
    <React.Fragment>
      <div
        style={style}
        className={clsx("sidebar", {
          "expand-sidebar": isSidebarExpanded,
          //   opened: isOpen,
        })}
        id="sidebar"
      >
        <div className="sidebar-inner">
          {sideBarInnerOverlay}
          <div
            className={clsx("sidebar-menu", {
              "sidebar-menu-desktop": !isMdOrBelowBreakpoint(breakpoint),
            })}
          >
            <div className="sidebar-menu-top">
              {!isMdOrBelowBreakpoint(breakpoint) && (
                <div
                  className={clsx("sidebar-menu-create-new", {
                    minimized: !isSidebarExpanded,
                  })}
                  id="add-menu-popover"
                >
                  <ActionButton
                    text={isSidebarExpanded ? "Create New" : ""}
                    type="primary"
                    icon={<AddIcon />}
                    onClick={
                      isReactNativeApp() ? toggleAddNewPopover : openAddNewModal
                    }
                    buttonClassName={clsx({ "w-100": isSidebarExpanded })}
                  />
                </div>
              )}
              {topContent}
            </div>
            {!isMdOrBelowBreakpoint(breakpoint) && (
              <SidebarBottom isSidebarExpanded={isSidebarExpanded} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
