import React, { useEffect, useCallback } from "react";
import axios from "axios";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
import useMainStore from "../../../utils/hooks/ReduxHooks/mainStore";

const TurnstileChallenge = (props) => {
  // Redux store
  const { users, setShowCfChallengeOverlay } = useUsersStore();
  const { main, storeOldRequestInQueueForCFChallenge } = useMainStore();
  const { cfRequestQueue } = main;
  const { showChallengeOverlay } = users;

  const onVerifyCallBack = useCallback(() => {
    setShowCfChallengeOverlay(false); // Hide the overlay
    axios(cfRequestQueue);
    storeOldRequestInQueueForCFChallenge(null);
  }, [
    cfRequestQueue,
    setShowCfChallengeOverlay,
    storeOldRequestInQueueForCFChallenge,
  ]);

  useEffect(() => {
    if (
      showChallengeOverlay &&
      window.turnstile &&
      process.env?.REACT_APP_CF_TURNSTILE_SITEKEY
    ) {
      window.turnstile.render("#turnstile_widget", {
        sitekey: process.env?.REACT_APP_CF_TURNSTILE_SITEKEY || "",
        callback: onVerifyCallBack,
        "error-callback": (error) =>
          console.log("TURNSTILE ERROR CALLBACK", error),
      });
    }
  }, [setShowCfChallengeOverlay, showChallengeOverlay, onVerifyCallBack]);

  if (showChallengeOverlay) {
    return (
      <div className="react-turnstile-container">
        <div id="turnstile_widget"></div>
      </div>
    );
  }

  return null;
};

export default TurnstileChallenge;
