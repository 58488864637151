import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#DEDDDD",
  },
  top: {
    color: "#6C37C9",
    position: "absolute",
    left: 0,
  },
}));

const ProgressCircular = (props) => {
  const { value, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="static"
        className={classes.bottom}
        size={40}
        thickness={6}
        {...rest}
        value={100}
      />
      <CircularProgress
        variant="static"
        className={classes.top}
        size={40}
        thickness={6}
        {...rest}
        value={value}
      />
    </div>
  );
};

ProgressCircular.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ProgressCircular;
