import React, { useState } from "react";
import ModalWrapper from "../Helpers/ModalWrapper";
import NewVersionAvailableImage from "../../../assets/img/new-version-available.png";
import { isAndroid, isIOs } from "../../../helpers";
import {
  ANDROID_CURRENT_PLAYSTORE_LINK,
  IOS_CURRENT_APPSTORE_LINK,
} from "../../../utils/constants";

const NewVersionAvailableModal = () => {
  const [show, setShow] = useState(true);

  const onCloseModal = () => {
    setShow(false);
  };

  const openLink = () => {
    if (isIOs()) {
      // Open the iOS App Store link
      window.location.href = IOS_CURRENT_APPSTORE_LINK;
    } else if (isAndroid()) {
      // Open the Android Play Store link
      window.location.href = ANDROID_CURRENT_PLAYSTORE_LINK;
    } else {
      return;
    }
    onCloseModal();
  };

  return (
    <ModalWrapper
      show={show}
      closeModal={onCloseModal}
      className="new-version-available-modal-wrapper"
      classNameCloseIcon="close-icon"
    >
      <img className="image" src={NewVersionAvailableImage} alt="US" />
      <button
        className="action-button action-button-primary action-button-large download-button"
        onClick={openLink}
      >
        Download
      </button>
    </ModalWrapper>
  );
};
export default NewVersionAvailableModal;
