const initialState = {
  companyDefaultListStatus: "",
  companyDefaultList: [],

  updateCompanyDefaultListStatus: "",

  companySettings: {},
  companySettingsStatus: "",
  companySettingsError: "",

  updateCompanySettingsQueue: {},
  updateCompanySettingsErrorMsg: "",

  selectedUserRhs: {},
  updateCompanyDefaultListError: null,

  paymentInfo: {},
};

export default function reducer(state = initialState, action) {
  let updateCompanySettingsQueue = null;
  let companySettings = null;
  switch (action.type) {
    case "ADMIN_SETTINGS/FETCH_COMPANY_DEFAULT_LIST": {
      return {
        ...state,
        companyDefaultListStatus: "loading",
      };
    }

    case "ADMIN_SETTINGS/FETCH_COMPANY_DEFAULT_LIST.SUCCESS": {
      return {
        ...state,
        companyDefaultList: action.payload.data,
        companyDefaultListStatus: "success",
      };
    }

    case "ADMIN_SETTINGS/UPDATE_COMPANY_DEFAULT_LIST": {
      return {
        ...state,
        updateCompanyDefaultListStatus: "loading",
      };
    }

    case "ADMIN_SETTINGS/UPDATE_COMPANY_DEFAULT_LIST.SUCCESS": {
      const newCompanyDefaultList = [...state.companyDefaultList];

      newCompanyDefaultList[action.index] = {
        ...newCompanyDefaultList[action.index],
        ...action.newData,
        numbers: action.newData.numbers
          ? newCompanyDefaultList[action.index].numbers.map((number) => {
              if (action.newData.numbers.indexOf(number.number) !== -1) {
                return {
                  ...number,
                  has_custom_set: false,
                };
              }
              return number;
            })
          : undefined,
        users: action.newData.users
          ? newCompanyDefaultList[action.index].users.map((user) => {
              if (action.newData.users.indexOf(`${user.id}`) !== -1) {
                return {
                  ...user,
                  has_custom_set: false,
                };
              }
              return user;
            })
          : undefined,
      };

      return {
        ...state,
        updateCompanyDefaultListStatus: "success",
        companyDefaultList: newCompanyDefaultList,
      };
    }

    case "ADMIN_SETTINGS/UPDATE_COMPANY_DEFAULT_LIST.ERROR": {
      return {
        ...state,
        updateCompanyDefaultListStatus: "error",
        updateCompanyDefaultListError: action.error.data.errors.signature,
      };
    }

    case "ADMIN_SETTINGS/UPDATE_COMPANY_DEFAULT_LIST.CLEAR": {
      return {
        ...state,
        updateCompanyDefaultListStatus: "",
        updateCompanyDefaultListError: null,
      };
    }

    case "ADMIN_SETTINGS/FETCH_COMPANY_SETTINGS": {
      return {
        ...state,
        companySettingsStatus: "loading",
      };
    }
    case "ADMIN_SETTINGS/FETCH_COMPANY_SETTINGS.SUCCESS": {
      return {
        ...state,
        companySettingsStatus: "success",
        companySettings: action.payload.data,
      };
    }
    case "ADMIN_SETTINGS/FETCH_COMPANY_SETTINGS.ERROR": {
      return {
        ...state,
        companySettingsStatus: "error",
        companySettingsError: action.error,
      };
    }
    case "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS": {
      updateCompanySettingsQueue = { ...state.updateCompanySettingsQueue };
      updateCompanySettingsQueue[action.field] = {
        status: "loading",
      };
      return {
        ...state,
        updateCompanySettingsQueue,
        updateCompanySettingsErrorMsg: "",
      };
    }
    case "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS.SUCCESS": {
      updateCompanySettingsQueue = { ...state.updateCompanySettingsQueue };
      companySettings = { ...state.companySettings };
      if (action.actionOnFields === "UPDATE_DATA_SHORT_CODE_NON_US") {
        companySettings["auto_resend_type"] =
          action.data["is_toll_free_resend"];
        companySettings["is_use_shortcode"] = action.data["is_use_shortcode"];
      }

      if (typeof updateCompanySettingsQueue[action.field] !== "undefined") {
        updateCompanySettingsQueue[action.field].status = "success";
      }

      if (
        action.field === "address" ||
        action.field === "country_code_setting" ||
        action.field === "short_org_name" ||
        action.field === "company_name" ||
        action.field === "logo" ||
        action.field === "treasurer_email" ||
        action.field === "has_onboarded"
      ) {
        companySettings = {
          ...companySettings,
          info: { ...companySettings.info, [action.field]: action.updateValue },
        };
      } else if (action.field === "is_toll_free_resend") {
        companySettings = {
          ...companySettings,
          auto_resend_type: action.updateValue,
        };
      } else if (action.field === "remove_logo") {
        companySettings = {
          ...companySettings,
          info: { ...companySettings.info, logo: null },
        };
      } else if (
        ["is_allow_short_code", "is_allow_toll_free"].includes(action.field)
      ) {
        companySettings = {
          ...companySettings,
          [action.field]: action.updateValue,
          auto_resend_type: action.data?.auto_resend_type,
        };
      } else {
        companySettings = {
          ...companySettings,
          [action.field]: action.updateValue,
        };
      }
      return {
        ...state,
        updateCompanySettingsQueue,
        companySettings,
        updateCompanySettingsErrorMsg: "",
      };
    }
    case "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS.ERROR": {
      updateCompanySettingsQueue = { ...state.updateCompanySettingsQueue };
      updateCompanySettingsQueue[action.field] = {
        status: "error",
      };
      return {
        ...state,
        updateCompanySettingsQueue,
        updateCompanySettingsErrorMsg:
          action.error?.data?.message ||
          "Something went wrong. Please try again later.",
      };
    }
    case "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS_QUEUE.CLEAR": {
      updateCompanySettingsQueue = { ...state.updateCompanySettingsQueue };
      updateCompanySettingsQueue[action.field] = undefined;
      return {
        ...state,
        updateCompanySettingsQueue,
        updateCompanySettingsErrorMsg: "",
      };
    }
    case "ADMIN_SETTINGS/SET_SELECTED_USER": {
      return {
        ...state,
        selectedUserRhs: action.selectedUserRhs,
      };
    }

    case "ADMIN_SETTINGS/SET_PAYMENT_INFO": {
      return {
        ...state,
        paymentInfo: action.paymentInfo,
      };
    }

    default:
      return state;
  }
}
