import React from "react";
import PropTypes from "prop-types";
//new icons
import { ReactComponent as BlockedIcon } from "../assets/img/icons-new/number-status/blocked/blocked-gray.svg";
import { ReactComponent as UnsubscribedIcon } from "../assets/img/icons-new/number-status/unsubscribed/unsubscribed-gray.svg";
import { ReactComponent as OptedOutIcon } from "../assets/img/icons-new/number-status/opted-out/opted-out-gray.svg";
import { ReactComponent as DoNotCallIcon } from "../assets/img/icons-new/number-status/do-not-call/do-not-call-gray.svg";
import { ReactComponent as DuplicateIcon } from "../assets/img/icons-new/number-status/duplicate/duplicate-gray.svg";
import { ReactComponent as VoipIcon } from "../assets/img/icons-new/number-status/voip/voip-gray.svg";
import { ReactComponent as LandlineIcon } from "../assets/img/icons-new/number-status/landline/landline-gray.svg";
import { ReactComponent as MobileIcon } from "../assets/img/icons-new/number-status/mobile/mobile-gray.svg";
import { ReactComponent as InvalidIcon } from "../assets/img/icons-new/number-status/invalid/invalid-gray.svg";
import AnchorWithTooltip from "./AnchorWithTooltip";

const NewContactStatusIcons = (props) => {
  const {
    duplicatesCount,
    failedCount,
    blockedCount,
    unsubscribedCount,
    optedoutCount,
    donotcallCount,
    voipCount,
    landlineCount,
    mobileCount,
    withFormatError,
  } = props;
  return (
    <React.Fragment>
      <div className="people-count-detail">
        {blockedCount > 0 && (
          <AnchorWithTooltip className="single" title={"Blocked"}>
            <BlockedIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {unsubscribedCount > 0 && (
          <AnchorWithTooltip className="single" title={"Unsubscribed"}>
            <UnsubscribedIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {optedoutCount > 0 && (
          <AnchorWithTooltip className="single" title={"Opted Out"}>
            <OptedOutIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {donotcallCount > 0 && (
          <AnchorWithTooltip className="single" title={"Do Not Call"}>
            <DoNotCallIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {duplicatesCount > 0 && (
          <AnchorWithTooltip className="single" title={"Duplicates"}>
            <DuplicateIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {voipCount > 0 && (
          <AnchorWithTooltip className="single" title={"VoIP"}>
            <VoipIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {landlineCount > 0 && (
          <AnchorWithTooltip className="single" title={"Landline"}>
            <LandlineIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {mobileCount > 0 && (
          <AnchorWithTooltip className="single" title={"Mobile"}>
            <MobileIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
        {failedCount > 0 && withFormatError && (
          <AnchorWithTooltip className="single" title={"Format Error"}>
            <InvalidIcon className="icon icon-grey ml-1" width={16} />
          </AnchorWithTooltip>
        )}
      </div>
    </React.Fragment>
  );
};
NewContactStatusIcons.propTypes = {
  duplicatesCount: PropTypes.number,
  failedCount: PropTypes.number,
  blockedCount: PropTypes.number,
  unsubscribedCount: PropTypes.number,
  optedoutCount: PropTypes.number,
  donotcallCount: PropTypes.number,
  voipCount: PropTypes.number,
  landlineCount: PropTypes.number,
  mobileCount: PropTypes.number,
  uncheckedCount: PropTypes.number,
  withFormatError: PropTypes.bool,
};

NewContactStatusIcons.defaultProps = {
  duplicatesCount: 0,
  failedCount: 0,
  blockedCount: 0,
  unsubscribedCount: 0,
  optedoutCount: 0,
  donotcallCount: 0,
  voipCount: 0,
  landlineCount: 0,
  mobileCount: 0,
  uncheckedCount: 0,
  withFormatError: false,
};

export default NewContactStatusIcons;
