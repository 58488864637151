import React, { useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ReactComponent as CallIcon } from "../../../assets/img/icons-new/voice-tab/all-voice-message/all-voice-message-white.svg";
import { ReactComponent as ClearSvg } from "../../../assets/img/icons-new/general/delete-icon/delete-icon-black.svg";
import { voiceDialpadsData } from "./voiceDialerHelpers";

const VoiceDialpad = (props) => {
  const {
    showBackSpaceBtn,
    disableCallBtn,
    onClickBackSpace,
    onClickDigit,
    onClickCallBtn,
    onClickDeclineBtn,
    onlyDisplayDeclineBtn,
    withBorderTop,
  } = props;

  const handleDigitClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      const digit = event.currentTarget.dataset.digit;
      onClickDigit(digit);
    },
    [onClickDigit]
  );

  return (
    <div
      className={clsx("new-call-with-number pl-4 pt-4 ", {
        "border-top": withBorderTop,
      })}
    >
      <div className="row dial-pad justify-content-center">
        {voiceDialpadsData.map((item) => (
          <div className="col-4" key={item.digit}>
            <span
              className="single cursor-pointer"
              onClick={handleDigitClick}
              data-digit={item.digit}
            >
              <span className={clsx("number", item.className)}>
                {item.text}
              </span>
              {item.alphabet && (
                <span className="alphabet">{item.alphabet}</span>
              )}
            </span>
          </div>
        ))}
        <div className="col-4"></div>
        {onlyDisplayDeclineBtn ? (
          <div className="col-4">
            <a
              href="#void"
              className="decline mt-4"
              onClick={onClickDeclineBtn}
            >
              <CallIcon />
            </a>
          </div>
        ) : (
          <div className="col-4">
            <span
              className={clsx("dial", {
                "opacity-50 cursor-not-allowed": disableCallBtn,
                "cursor-pointer": !disableCallBtn,
              })}
              onClick={!disableCallBtn ? onClickCallBtn : () => {}}
            >
              <CallIcon />
            </span>
          </div>
        )}
        <div className="col-4">
          {showBackSpaceBtn && (
            <span
              className="clear"
              onClick={onClickBackSpace}
              data-digit={"__backspace"}
            >
              <ClearSvg width={32.396} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

VoiceDialpad.propTypes = {
  showBackSpaceBtn: PropTypes.bool,
  onlyDisplayDeclineBtn: PropTypes.bool,
  disableCallBtn: PropTypes.bool,
  withBorderTop: PropTypes.bool,
  onClickBackSpace: PropTypes.func,
  onClickDigit: PropTypes.func,
  onClickCallBtn: PropTypes.func,
};

VoiceDialpad.defaultProps = {
  showBackSpaceBtn: false,
  disableCallBtn: false,
  onlyDisplayDeclineBtn: false,
  withBorderTop: true,
  onClickBackSpace: () => {},
  onClickDigit: () => {},
  onClickCallBtn: () => {},
};

export default VoiceDialpad;
