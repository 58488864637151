import React from "react";
import PropTypes from "prop-types";
import ModalWrapper from "./Helpers/ModalWrapper";
import { ReactComponent as InfoIcon } from "../../assets/img/icons-new/number-status/show-info/show-info-orange.svg";

const InvalidTimeModal = (props) => {
  const { show, closeModal } = props;

  return (
    <ModalWrapper show={show} closeModal={closeModal}>
      <div className="py-3 px-4">
        <div className="d-flex align-items-center mb-3">
          <InfoIcon width={30} height={30} />
          <p style={{ fontSize: 24 }} className="warning-color ml-2 mb-0">
            Invalid Time
          </p>
        </div>
        <p className="mb-4 font-size-16px text-container">
          You cannot select a time from the past. Please select a future time to
          schedule your message.
        </p>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={closeModal}>
            Okay
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

InvalidTimeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default InvalidTimeModal;
