import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LightTooltip } from "../../helpers";
import TrailingMoreIcon from "./TrailingMoreIcon";

const ActionButton = (props) => {
  const {
    size,
    icon,
    rightIcon,
    type,
    onClick,
    disabled,
    htmlType,
    text,
    iconPosition,
    buttonClassName,
    showToolTip,
    toolTipProps,
    isSideBarView,
    isMoreButton,
    selected,
    id,
  } = props;
  const className = useMemo(() => {
    return clsx(
      "action-button",
      {
        [`action-button-${type}`]: !isSideBarView,
        [`action-button-${type}--sidebar`]: isSideBarView,
        selected: selected,
        "icon-only": !text,
      },
      `action-button-${size}`,
      buttonClassName
    );
  }, [type, isSideBarView, selected, text, size, buttonClassName]);

  const toolTipWrapper = useCallback(
    (component) => {
      if (!showToolTip) return component;
      return (
        <LightTooltip
          title={toolTipProps.title}
          arrow={toolTipProps.arrow}
          placement={toolTipProps.placement}
          enterDelay={toolTipProps.enterDelay}
          leaveDelay={toolTipProps.leaveDelay}
        >
          <span>{component}</span>
        </LightTooltip>
      );
    },
    [toolTipProps, showToolTip]
  );

  return toolTipWrapper(
    <button
      className={className}
      type={htmlType}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {icon && iconPosition === "left" && (
        <span className={`action-button-icon-${iconPosition}`}>{icon}</span>
      )}
      {text}
      {rightIcon && rightIcon}
      {(icon || isMoreButton) && iconPosition === "right" && (
        <span className={`action-button-icon-${iconPosition}`}>
          {isMoreButton ? <TrailingMoreIcon /> : icon}
        </span>
      )}
    </button>
  );
};

ActionButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.node,
  rightIcon: PropTypes.node,
  type: PropTypes.oneOf(["primary", "default", "ghost"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  buttonClassName: PropTypes.string,
  showToolTip: PropTypes.bool,
  toolTipProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    enterDelay: PropTypes.number,
    leaveDelay: PropTypes.number,
  }),
  isSideBarView: PropTypes.bool,
  isMoreButton: PropTypes.bool,
  selected: PropTypes.bool,
  id: PropTypes.string,
};

ActionButton.defaultProps = {
  text: "",
  size: "medium",
  type: "default",
  onClick: () => null,
  disabled: false,
  htmlType: "button",
  iconPosition: "left",
  buttonClassName: "",
  showToolTip: false,
  toolTipProps: {
    title: "",
    arrow: true,
    placement: "bottom",
    enterDelay: 500,
    leaveDelay: 100,
  },
  isSideBarView: false,
  isMoreButton: false,
  selected: false,
  id: null,
};

export default ActionButton;
