import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { contactName } from "../../helpers";

import { deleteContact, deleteContacts } from "../../actions/contacts";
import { addSnackData } from "../../actions/groups";
import { clearSelection } from "../../actions/threads";
// new icons
import { ReactComponent as CloseSmallIcon } from "../../assets/img/icons-new/general/close/close-gray.svg";

function mapStateToProps(store, ownProps) {
  let contactId = parseInt(ownProps.param);
  return {
    currentCompanyId: store.companies.currentCompany.id,
    deleteStatus: store.contacts.deleteStatus,
    contactId: contactId,
    contactToDelete: store.contacts.contactToDelete,
    batchDeleteStatus: store.contacts.batchDeleteStatus,
    contactToDeleteIds: store.contacts.deleteContactIds,
    isDeleteContactBatch: store.contacts.isBatchDelete,
    toggleSelected: store.threads.toggleSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteContact: (companyId, contactId, groupId) =>
      dispatch(deleteContact(companyId, contactId, groupId)),
    deleteContactsBatch: (companyId, contactIds) =>
      dispatch(deleteContacts(companyId, contactIds)),
    setContactSnackData: (data, entity) => dispatch(addSnackData(data, entity)),
    clearSelection: (selection) => dispatch(clearSelection(selection)),
  };
}

class DeleteContactModal extends React.Component {
  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.state = {
      redirect: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { closeModal } = this.props;
    if (nextProps.deleteStatus === `success-${nextProps.contactToDelete.id}`) {
      this.toggleContactSnackbar(true)();
      closeModal();
      if (this.props.toggleSelected !== 0) {
        this.props.clearSelection(true);
      }
    } else if (nextProps.deleteStatus === "error") {
      this.toggleContactSnackbar(true)(true);
      closeModal();
      if (this.props.toggleSelected !== 0) {
        this.props.clearSelection(true);
      }
    } else if (nextProps.batchDeleteStatus === "success") {
      this.toggleDeleteBatchSnackbar(true)();
      closeModal();
    } else if (nextProps.batchDeleteStatus === "error") {
      this.toggleDeleteBatchSnackbar(true)(true);
      closeModal();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.show !== nextProps.show;
  }

  toggleDeleteBatchSnackbar = (val) => (failure) => {
    const { isDeleteContactBatch, contactToDelete, contactToDeleteIds } =
      this.props;
    let msg;
    if (val && !failure && isDeleteContactBatch) {
      msg = `${contactToDeleteIds?.length} ${
        contactToDeleteIds?.length === 1 ? "person" : "people"
      } have been deleted`;
      const data = {
        id: contactToDeleteIds,
        err: failure,
        msg,
      };
      this.props.setContactSnackData(data, "delete");
    } else if (val && failure && isDeleteContactBatch) {
      msg = `Selected people couldn't be deleted`;
      const data = {
        id: contactToDelete.id,
        err: failure,
        msg,
      };
      this.props.setContactSnackData(data, "delete");
    }
  };

  toggleContactSnackbar = (val) => (failure) => {
    const { isDeleteContactBatch, contactToDelete } = this.props;
    let msg;
    let contactNameStr = "...";
    contactNameStr = isDeleteContactBatch
      ? null
      : contactName(
          contactToDelete.first_name,
          contactToDelete.last_name,
          contactToDelete.phone_number
        );
    if (val && !failure) {
      msg = `${contactNameStr} has been deleted`;
      const data = {
        id: contactToDelete.id,
        err: failure,
        msg,
      };
      this.props.setContactSnackData(data, "delete");
    } else if (val && failure) {
      msg = `${contactNameStr} couldn't be deleted`;
      const data = {
        id: contactToDelete.id,
        err: failure,
        msg,
      };
      this.props.setContactSnackData(data, "delete");
    }
  };

  confirm = (event) => {
    event.preventDefault();
    const { contactToDelete, onDeleteClick } = this.props;

    this.props.deleteContact(
      this.props.currentCompanyId,
      contactToDelete.id,
      this.props.groupId
    );
    onDeleteClick?.();
  };

  deleteContactBatch = () => {
    const { contactToDeleteIds, onDeleteClick } = this.props;
    this.props.deleteContactsBatch(
      this.props.currentCompanyId,
      contactToDeleteIds
    );
    onDeleteClick?.();
  };

  render() {
    const {
      show,
      closeModal,
      isDeleteContactBatch,
      contactToDeleteIds,
      contactToDelete,
    } = this.props;
    let contactNameStr = "...";
    contactNameStr = isDeleteContactBatch
      ? null
      : contactName(
          contactToDelete.first_name,
          contactToDelete.last_name,
          contactToDelete.phone_number
        );
    return (
      <>
        <div
          className={`modal fade inbox-modal confirm-group-message${
            show ? " show" : ""
          }`}
          style={{ display: "block" }}
          id="DeleteContactModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="DeleteContactModalLabel"
          aria-hidden={show ? "false" : "true"}
        >
          <div
            className={"modal-backdrop fade" + (this.props.show ? " show" : "")}
            onClick={this.props.shakeModal}
          />
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            style={{ zIndex: 100 }}
          >
            <div className="modal-content themed-modal">
              <button
                type="button"
                className="close-archived"
                aria-label="Close"
                onClick={() => closeModal(true)}
              >
                <CloseSmallIcon />
              </button>
              {isDeleteContactBatch ? (
                <div className="modal-body">
                  <div className="sleek mb-4">
                    <span className="group-header-title">Delete Contacts</span>
                  </div>
                  <div className="content caller-info">
                    <p>
                      Are you sure you want to permanently delete{" "}
                      {contactToDeleteIds?.length === 1 ? "this" : "these"}{" "}
                      selected{" "}
                      {contactToDeleteIds?.length === 1 ? "person" : "people"}?
                    </p>
                  </div>
                  <div className="modal-footer mt-4">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => closeModal(true)}
                      style={{ borderColor: "#FF5800", color: "#FF5800" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-delete-confirmation"
                      onClick={this.deleteContactBatch}
                    >
                      Yes, delete
                    </button>
                  </div>
                </div>
              ) : (
                <div className="modal-body">
                  <div className="sleek mb-4">
                    <span className="group-header-title">
                      Confirm delete of {contactNameStr}
                    </span>
                  </div>
                  <div className="content caller-info">
                    <p>Are you sure you want to delete this person?</p>
                  </div>
                  <div className="modal-footer mt-4">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => closeModal(true)}
                      style={{ borderColor: "#FF5800", color: "#FF5800" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-delete-confirmation"
                      onClick={this.confirm}
                    >
                      Yes, delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteContactModal)
);
