import React from "react";
import PropTypes from "prop-types";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";

const SetupItem = (props) => {
  const { title, description, rightButton, className, showWarning } = props;
  const breakpoint = useBreakpoint();

  return (
    <>
      {showWarning && <div className="warning"></div>}
      <div className="setup-item">
        <div className={`left-column ${className}`}>
          <div className="item-title">{title}</div>
          {description}
        </div>
        <div
          className={`right-column ${
            isMdOrBelowBreakpoint(breakpoint) ? "" : "pt-4"
          }`}
        >
          {rightButton}
        </div>
      </div>
    </>
  );
};

SetupItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  rightButton: PropTypes.object,
  className: PropTypes.string,
  showWarning: PropTypes.bool,
};

export default SetupItem;
