import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SelectCountryOnSearch = (props) => {
  const {
    queryIsNumber,
    countryRef,
    countries,
    onChangeCountryCode,
    className,
  } = props;
  return (
    <div className="search-wrapper-outer d-flex">
      <div
        className={clsx("country-select-wrapper", {
          active: queryIsNumber,
        })}
      >
        <select
          defaultValue="226,1"
          className={className}
          ref={countryRef}
          onChange={onChangeCountryCode}
        >
          {countries &&
            countries.map((country) => (
              <option
                key={country.id}
                value={country.id + "," + country.phonecode}
              >
                ({country.iso}) +{country.phonecode}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

SelectCountryOnSearch.propTypes = {
  queryIsNumber: PropTypes.bool,
  countryRef: PropTypes.node,
  handleCountryChange: PropTypes.func.isRequired,
  onChangeCountryCode: PropTypes.func.isRequired,
  countries: PropTypes.array,
  className: PropTypes.string,
};

SelectCountryOnSearch.defaultProps = {
  queryIsNumber: false,
  className: "",
  countries: [],
};

export default SelectCountryOnSearch;
