import { useState, useEffect } from "react";
import { monthNames } from "../utils/dateAndTimeHelpers";

const TimeAgo = (props) => {
  const [timeoutId, setTimeoutId] = useState(0);

  const date = Date.parse(props.time);
  const diff = (Date.now() - date) / 1000;
  let text = "";
  let timeout = 0;
  if (diff < -86400) {
    let exDate = new Date(props.time);
    exDate.setTime(exDate.getTime() + props.userTimeZoneOffset * 1000);

    if (diff < -432000) {
      text =
        ("0" + (exDate.getUTCMonth() + 1)).substr(-2) +
        "/" +
        ("0" + exDate.getUTCDate()).substr(-2) +
        "/" +
        exDate.getFullYear();
    } else {
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      text = weekdays[exDate.getUTCDay()];
    }
    text +=
      ", " +
      ("0" + exDate.getUTCHours()).substr(-2) +
      ":" +
      ("0" + exDate.getUTCMinutes()).substr(-2);
  } else if (diff < -3600) {
    const value = Math.abs(Math.ceil(diff / 3600 - 0.5));
    text = "In " + value + `${value > 1 ? " hrs" : " hr"}`;
    timeout = 600;
  } else if (diff < 0) {
    const value = Math.abs(Math.ceil(diff / 60 - 0.5));
    text = "In " + value + `${value > 1 ? " mins" : " min"}`;
    timeout = 60;
  } else if (diff < 10) {
    text = "a second ago";
    timeout = 10;
  } else if (diff < 60) {
    text = "few seconds ago";
    timeout = 60;
  } else if (diff < 120) {
    text = "a minute ago";
    timeout = 120;
  } else if (diff < 600) {
    text = "few minutes ago";
    timeout = 600;
  } else if (diff < 3600) {
    text = "an hour ago";
    timeout = 3600;
  } else if (diff < 10800) {
    text = "few hours ago";
    timeout = 10800;
  } else if (diff < 36000) {
    text = "several hours ago";
    timeout = 36000;
  } else {
    let exDate = new Date(props.time);
    exDate.setTime(exDate.getTime() + props.userTimeZoneOffset * 1000);

    if (diff > 432000) {
      const formatteeDate = `${
        monthNames[exDate.getUTCMonth()]
      } ${exDate.getUTCDate()}, ${exDate.getUTCFullYear()}`;

      text = formatteeDate;
    } else {
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      text = weekdays[exDate.getUTCDay()];
    }
    text +=
      ` - ` +
      ("0" + exDate.getUTCHours()).substr(-2) +
      ":" +
      ("0" + exDate.getUTCMinutes()).substr(-2);
  }

  useEffect(() => {
    function clearCallback(timeoutId) {
      clearTimeout(setTimeoutId);
      setTimeoutId(0);
    }

    if (!timeoutId && timeout > 0) {
      setTimeoutId(setTimeout(clearCallback, timeout * 1000));
    }

    return function cleanup() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId, timeout]);

  return text;
};

export default TimeAgo;
