import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getThreadName } from "../../helpers";
// new icons
import { ReactComponent as CloseSmallIcon } from "../../assets/img/icons-new/general/close/close-gray.svg";

const LongNamesModal = ({
  show,
  modalBodyContent,
  modalData,
  hasInsertBtn,
  onClose,
  insertItem,
  hasRemoveBtn,
  removeItem,
  itemData,
  isGroup,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isHide, setIsHide] = useState(false);

  const updateView = useCallback(() => {
    if (show && !isShown) {
      setTimeout(() => {
        setIsShown(true);
        setIsHide(false);
      }, 0);
    }
    if (!show && isShown) {
      setIsHide(true);
      setTimeout(() => {
        setIsShown(false);
      }, 200);
    }
  }, [show, isShown]);

  useEffect(() => {
    updateView();
  }, [show, isShown, isHide, updateView]);

  const handleRemoveItem = useCallback(
    (event) => {
      removeItem(event);
      onClose();
    },
    [onClose, removeItem]
  );

  return (
    <div
      className={
        "modal fade inbox-modal confirm-group-message" +
        (isShown && !isHide ? " show" : "")
      }
      style={show || isShown ? { display: "block" } : {}}
      id="SkipSendingMultipleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="SkipSendingMultipleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-backdrop fade show"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      />
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        style={{ zIndex: 101 }}
      >
        <div className="modal-content pl-2 pt-3 themed-modal">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <CloseSmallIcon />
          </button>
          <div className="modal-body mt-3">
            <>
              <div
                className="skip-sending-confirmation-multiple text-center mb-1"
                style={{ fontSize: "1rem" }}
              >
                {modalBodyContent || getThreadName(modalData)}
              </div>
            </>
          </div>
          {hasInsertBtn && (
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-primary insert-btn"
                onClick={insertItem}
              >
                Insert
              </button>
            </div>
          )}
          {hasRemoveBtn && (
            <div className="modal-footer border-0">
              {isGroup ? (
                <button
                  type="button"
                  data-groupid={itemData.id}
                  className="btn btn-primary insert-btn"
                  onClick={handleRemoveItem}
                >
                  Remove
                </button>
              ) : (
                <button
                  type="button"
                  data-contactid={itemData.id}
                  className="btn btn-primary insert-btn"
                  onClick={handleRemoveItem}
                >
                  Remove
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LongNamesModal.propTypes = {
  modalData: PropTypes.objectOf(PropTypes.any),
  itemData: PropTypes.objectOf(PropTypes.any),
  modalBodyContent: PropTypes.string,
  show: PropTypes.bool,
  hasInsertBtn: PropTypes.bool,
  hasRemoveBtn: PropTypes.bool,
  isGroup: PropTypes.bool,
  onClose: PropTypes.func,
  insertItem: PropTypes.func,
  removeItem: PropTypes.func,
};

export default withRouter(LongNamesModal);
