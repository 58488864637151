import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverBody } from "reactstrap";
//new icons
import { ReactComponent as FNSortingAZActionBar } from "../../../assets/img/icons-new/sort/first-name/first-name-asc-gray.svg";
import { ReactComponent as FNSortingZAActionBar } from "../../../assets/img/icons-new/sort/first-name/first-name-desc-gray.svg";
import { ReactComponent as TNSortingAZActionBar } from "../../../assets/img/icons-new/sort/tag-name/tag-name-asc-gray.svg";
import { ReactComponent as TNSortingZAActionBar } from "../../../assets/img/icons-new/sort/tag-name/tag-name-desc-gray.svg";
import { ReactComponent as LNSortingAZActionBar } from "../../../assets/img/icons-new/sort/last-name/last-name-asc-gray.svg";
import { ReactComponent as LNSortingZAActionBar } from "../../../assets/img/icons-new/sort/last-name/last-name-desc-gray.svg";
import { ReactComponent as GNSortingAZActionBar } from "../../../assets/img/icons-new/sort/group-name/group-name-asc-gray.svg";
import { ReactComponent as GNSortingZAActionBar } from "../../../assets/img/icons-new/sort/group-name/group-name-desc-gray.svg";
import { ReactComponent as NewAsc } from "../../../assets/img/icons-new/sort/new-old/new-asc-gray.svg";
import { ReactComponent as OldDesc } from "../../../assets/img/icons-new/sort/new-old/old-desc-gray.svg";
import { ReactComponent as Check } from "../../../assets/img/icons-new/general/check/check-white.svg";
import { ReactComponent as PeopleFilter } from "../../../assets/img/icons-new/general/filter/filter-gray.svg";
import clsx from "clsx";

export const getSortIcon = (key, isOnMobileMode) => {
  switch (key) {
    case "firstNameAsc":
      return <FNSortingAZActionBar />;
    case "firstNameDesc":
      return <FNSortingZAActionBar />;
    case "lastNameAsc":
      return <LNSortingAZActionBar />;
    case "lastNameDesc":
      return <LNSortingZAActionBar />;
    case "groupNameAsc":
      return <GNSortingAZActionBar />;
    case "groupNameDesc":
      return <GNSortingZAActionBar />;
    case "tagNameAsc":
      return <TNSortingAZActionBar />;
    case "tagNameDesc":
      return <TNSortingZAActionBar />;
    case "newest":
      return <NewAsc />;
    case "oldest":
      return <OldDesc />;

    default:
      return isOnMobileMode ? <FNSortingAZActionBar /> : <PeopleFilter />;
  }
};

const SortingPopover = (props) => {
  const { listItems, handleActiveItem, activeItem, className, target } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const handleAction = (label) => () => {
    handleActiveItem(label);
    toggle();
  };

  const keys = Object.keys(listItems);

  return (
    <Popover
      trigger="legacy"
      placement="bottom"
      target={target}
      className="people-sorting-popover"
      toggle={toggle}
      isOpen={popoverOpen}
    >
      <PopoverBody className={clsx("people-sorting-popover", className)}>
        {keys.map((key) => (
          <button
            key={key}
            className="people-sorting-btn"
            onClick={handleAction(key)}
          >
            {activeItem === key && (
              <span className="sorting-btn-active-icon">
                <Check />
              </span>
            )}
            <div className="people-actions-popover-rightpanel">
              <span className={activeItem === key ? "people-sorting-name" : ""}>
                {listItems[key] ? listItems[key].label : ""}
              </span>
            </div>
            <span className="btn-sort-icon ml-1">{getSortIcon(key)}</span>
          </button>
        ))}
      </PopoverBody>
    </Popover>
  );
};

SortingPopover.propTypes = {
  listItems: PropTypes.objectOf(PropTypes.any).isRequired,
  handleActiveItem: PropTypes.func.isRequired,
  activeItem: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SortingPopover.defaultProps = {
  className: "",
};

export default SortingPopover;
