import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Snackbar from "./Snackbar";
import { connect } from "react-redux";
import Export from "../hub/ActionsWorkflows/Export";
import { toast } from "react-toastify";
import { groupName } from "../../helpers";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { getSnackbarPosition } from "../../utils/settingsHelpers";

function mapDispatchToProps(dispatch) {
  return {
    setExportAll: (id, runWorkflow, toastId) =>
      dispatch({
        type: "GROUPS/SET_EXPORT_ALL",
        runWorkflow,
        id,
        toastId,
      }),
    deleteExportAll: (id) => dispatch({ type: "GROUPS/DELETE_EXPORT_ALL", id }),
  };
}

function mapStateToProps(store, ownProps) {
  return {
    exportAllMembers: store.groups.exportAllMembers[ownProps.id],
    groupData: store.groups.data[ownProps.id],
  };
}

const ExportAllGroupMembers = (props) => {
  const {
    // props
    id,
    // Redux props
    groupData,
    exportAllMembers,
    // Redux func
    setExportAll,
    deleteExportAll,
  } = props;
  const breakpoint = useBreakpoint();
  const onCloseExportWorkflow = useCallback(
    (bool) => {
      deleteExportAll(id);
      toast.dismiss(exportAllMembers.toastId);
    },
    [deleteExportAll, exportAllMembers.toastId, id]
  );

  const onToastInit = useCallback(
    (toastId) => {
      setExportAll(id, true, toastId);
    },
    [id, setExportAll]
  );

  return (
    <>
      <Snackbar
        open={exportAllMembers?.runWorkflow && !exportAllMembers.toastId}
        onClose={() => {}}
        autoClose={false}
        message={
          groupData ? `Exporting ${groupName(groupData)} in progress...` : ""
        }
        onToastInit={onToastInit}
        position={getSnackbarPosition(breakpoint)}
      />
      {exportAllMembers?.runWorkflow && (
        <Export
          statusId={groupData.id}
          runWorkflow={
            exportAllMembers.runWorkflow && !exportAllMembers.toastId
          }
          setExportWorkflow={onCloseExportWorkflow}
          selectedGroups={groupData ? { [groupData.id]: groupData } : {}}
        />
      )}
    </>
  );
};

ExportAllGroupMembers.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportAllGroupMembers);
