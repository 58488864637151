import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

const AnalyticsAndBillingLinks = (props) => {
  const { userPermissions, className } = props;
  return (
    <div className={clsx("billing-analytics-container", className)}>
      {userPermissions?.billings && (
        <>
          <Link
            to="/hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing"
            className="name"
          >
            Billing
          </Link>
          <span className="name ml-2 mr-2">|</span>
        </>
      )}
      <Link
        to="/hub/menus/filter/analytics/logs/submenu/legacy-logs"
        className="name"
      >
        Analytics
      </Link>
    </div>
  );
};

AnalyticsAndBillingLinks.propTypes = {
  className: PropTypes.string,
  userPermissions: PropTypes.object,
};

AnalyticsAndBillingLinks.defaultProps = {
  className: "",
  userPermissions: {},
};

export default AnalyticsAndBillingLinks;
