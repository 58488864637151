import logger from "../logger";
import { history } from "../store";
import { getMappedLocalStorageForLoadingDebugPayload } from "./routerHelpers";

export const logAPIRequest = (namedFunction, url, params) => {
  const path = history.location.pathname;
  logger.push({
    text: `${namedFunction} call backend URL:${url} with params${JSON.stringify(
      params
    )} on ${path}`,
    logs: window.loadingDebug.logs,
    platform: navigator.platform,
    userAgent: navigator.userAgent,
    localStorage: getMappedLocalStorageForLoadingDebugPayload(),
  });
};
