import { useMemo } from "react";

import useUsersStore from "./ReduxHooks/userStore";
import useBreakpoint from "./useBreakpoints";
import { useParams } from "react-router-dom";

import { closeRHSAllowed } from "../../components/hub/ThreadItems/threadItemHelper";
import { UserMainMenuLookEnums } from "../enums/usersEnums";
import { isMdOrBelowBreakpoint } from "../breakpoints";

export const useIsSidebarExpanded = () => {
  const {
    users: { settings: userSettings },
  } = useUsersStore();
  const { tab, interlocutorId } = useParams();
  const breakpoint = useBreakpoint();

  const isSidebarExpanded = useMemo(() => {
    const settingsMainMenuLook =
      closeRHSAllowed.includes(tab) && interlocutorId
        ? userSettings?.main_menu_look_on_messages
        : userSettings?.main_menu_look;
    return (
      settingsMainMenuLook === UserMainMenuLookEnums.ICONS_AND_TEXTS &&
      !isMdOrBelowBreakpoint(breakpoint)
    );
  }, [tab, interlocutorId, breakpoint, userSettings]);

  return isSidebarExpanded;
};
