import React from "react";
import { getBreakPoints } from "./helpers";
import { isMdOrBelowBreakpoint } from "./utils/breakpoints";
/**
 * HOC for responsive (add isMobile param to wrapped Component)
 *
 * @param WrappedComponent
 * @returns {{new(*=): WithResponsive, handleResizeWindow, state, contextType?: React.Context<any>, new<P, S>(props: Readonly<P>): WithResponsive, new<P, S>(props: P, context?: any): WithResponsive, prototype: WithResponsive}}
 */
export default function withResponsive(WrappedComponent) {
  return class WithResponsive extends React.Component {
    constructor(props) {
      super(props);
      this.handleResizeWindow = this.handleResizeWindow.bind(this);
      this.state = {
        isMobile: isMdOrBelowBreakpoint(
          getBreakPoints(document.body.clientWidth)
        ),
        breakpoint: getBreakPoints(document.body.clientWidth),
      };
    }

    componentDidMount() {
      window.addEventListener("resize", this.handleResizeWindow);

      // Prepare view on mount
      this.handleResizeWindow();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResizeWindow);
    }

    handleResizeWindow() {
      const newDeviceSize = getBreakPoints(document.body.clientWidth);
      const newIsMobile = isMdOrBelowBreakpoint(newDeviceSize);
      if (this.state.isMobile !== newIsMobile) {
        this.setState({ isMobile: newIsMobile });
      }
      if (this.state.breakpoint !== newDeviceSize) {
        this.setState({ breakpoint: newDeviceSize });
      }
    }

    render() {
      return (
        <WrappedComponent
          isMobile={this.state.isMobile}
          breakpoint={this.state.breakpoint}
          {...this.props}
        />
      );
    }
  };
}
