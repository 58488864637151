import { ReactComponent as PaletteIcon } from "../../../assets/img/icons-new/palette/palette-black.svg";
import { ReactComponent as BergerMenuIcon } from "../../../assets/img/icons-new/general/berger-menu/berger-menu-black.svg";
import { ReactComponent as MessageIcon } from "../../../assets/img/icons-new/general/message/message-black.svg";
import { ReactComponent as VoiceIcon } from "../../../assets/img/icons-new/general/voice/voice-black.svg";
import { ReactComponent as PeopleIcon } from "../../../assets/img/icons-new/general/people/people-black.svg";
import { ReactComponent as GroupIcon } from "../../../assets/img/icons-new/general/group/group-black.svg";

// Display preference filters types
export const DISPLAY = "display";
export const CUSTOMIZE_MENU = "customizeMenu";
export const MESSAGE_PREFERENCE = "messagePreference";
export const VOICE_PREFERENCE = "voicePreference";
export const PEOPLE_PREFERENCE = "peoplePreference";
export const GROUPS_PREFERENCE = "groupsPreference";
export const SIGNATURE = "signature";

export const displayPreferenceFilter = [
  {
    label: "Display",
    type: DISPLAY,
    icon: PaletteIcon,
  },
  {
    label: "Customize Menu",
    type: CUSTOMIZE_MENU,
    icon: BergerMenuIcon,
  },
  {
    label: "Message Preferences",
    type: MESSAGE_PREFERENCE,
    icon: MessageIcon,
  },
  {
    label: "Voice Preferences",
    type: VOICE_PREFERENCE,
    icon: VoiceIcon,
  },
  {
    label: "People Preferences",
    type: PEOPLE_PREFERENCE,
    icon: PeopleIcon,
  },
  {
    label: "Group Preferences",
    type: GROUPS_PREFERENCE,
    icon: GroupIcon,
  },
  {
    label: "Signatures",
    type: SIGNATURE,
    icon: PaletteIcon,
  },
];

export const displayPreferenceHeaderText = {
  [DISPLAY]: "Preferences / Display",
  [CUSTOMIZE_MENU]: "Preferences / Customize Menu",
  [MESSAGE_PREFERENCE]: "Preferences / Message Preferences",
  [VOICE_PREFERENCE]: "Preferences / Voice Preferences",
  [PEOPLE_PREFERENCE]: "Preferences / People Preferences",
  [GROUPS_PREFERENCE]: "Preferences / Group Preferences",
  [SIGNATURE]: "Preferences / Signatures",
};
