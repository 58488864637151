import PropTypes from "prop-types";

export const sendNewMessageBodyPropTypes = {
  setMessageValue: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  setVideos: PropTypes.func.isRequired,
  setIsVideoProcessing: PropTypes.func.isRequired,
  setIsImagesProcessing: PropTypes.func.isRequired,
  setMessageSignature: PropTypes.func.isRequired,
  handleEditSchedule: PropTypes.func.isRequired,
  setSidebar: PropTypes.func.isRequired,
  setImagesErrorText: PropTypes.func.isRequired,
  setVideoErrorText: PropTypes.func.isRequired,
  setVideoLengthExceedData: PropTypes.func.isRequired,
  setUploadedVideo: PropTypes.func.isRequired,
  setWarningText: PropTypes.func.isRequired,
  closeVideoLengthExceedModal: PropTypes.func.isRequired,
  closeUploadedVideoConfirmationModal: PropTypes.func.isRequired,
  onClickedTrimVideo: PropTypes.func.isRequired,
  setVcards: PropTypes.func.isRequired,
  messageValue: PropTypes.string,
  buttons: PropTypes.array,
  unicodeCharacters: PropTypes.number,
  credits: PropTypes.number,
  characters: PropTypes.number,
  smsCount: PropTypes.number,
  mmsCount: PropTypes.number,
  images: PropTypes.array,
  videos: PropTypes.array,
  isVideoProcessing: PropTypes.bool,
  isImagesProcessing: PropTypes.bool,
  messageSignature: PropTypes.string,
  recipientsCount: PropTypes.number,
  alertNotifier: PropTypes.node,
  disabled: PropTypes.bool,
  uploadedVideo: PropTypes.object,
  videoLengthExceedData: PropTypes.object,
  subscribersCount: PropTypes.number,
  vCards: PropTypes.array,
  groupData: PropTypes.object,
};

export const sendNewMessageBodyDefaultProps = {
  messageValue: "",
  buttons: [],
  unicodeCharacters: 0,
  credits: 0,
  characters: 0,
  smsCount: 0,
  mmsCount: 0,
  images: [],
  videos: [],
  vCards: [],
  isVideoProcessing: false,
  isImagesProcessing: false,
  messageSignature: "",
  recipientsCount: 0,
  alertNotifier: null,
  disabled: false,
  uploadedVideo: null,
  videoLengthExceedData: null,
  subscribersCount: 0,
  groupData: {},
};
