import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import LayoutDefault from "../LayoutDefault";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import DashboardBody from "./DashboardBody/DashboardBody";
import { fetchDashboard, postDashboard } from "../../actions/dashboard";
import { connect } from "react-redux";
import {
  DEFAULT_DASHBOARD_LAYOUT,
  FIRST_LOAD_DASHBOARD,
  FORCE_DEFAULT_LAYOUT_DASHBOARD,
} from "../../utils/constants";
import Spinner from "../hub/HelperComponents/Spinner";
import { useState } from "react";
import { setRedirectedToDashboardWhenNoAssignedNumber } from "../../actions/main";
import { getDefaultDashboardLayout } from "../../utils/dashboardHelpers";
// TODO: ADD THEMED COLORS

function mapStateToProps(store, ownProps) {
  return {
    dashboardData: store.dashboard.dashboardData,
    dashboardStatus: store.dashboard.dashboardStatus,
    loggedUser: store.users.loggedUser,
    dashboardPostStatus: store.dashboard.dashboardPostStatus,
    dashboardRequestId: store.dashboard.dashboardRequestId,
    redirectedToDashboardWhenNoAssignedNumber:
      store.main.redirectedToDashboardWhenNoAssignedNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDashboard: (requestId) => dispatch(fetchDashboard(requestId)),
    postDashboard: (body) => dispatch(postDashboard(body)),
    setRedirectedToDashboardWhenNoAssignedNumber: (isRedirected) =>
      dispatch(setRedirectedToDashboardWhenNoAssignedNumber(isRedirected)),
  };
}

const REQUEST_ID = "no-spinner";

const firstDashboardAdded = Boolean(localStorage.getItem(FIRST_LOAD_DASHBOARD));
const forceDefaultLayoutNumber = 2; // To update when frontend layout is changed
const forceDefaultLayoutStorageNumber = Number(
  localStorage.getItem(FORCE_DEFAULT_LAYOUT_DASHBOARD)
);

const isToForceUpdateFromFrontend =
  firstDashboardAdded &&
  (forceDefaultLayoutStorageNumber <= forceDefaultLayoutNumber ||
    forceDefaultLayoutStorageNumber === 0);

const Dashboard = (props) => {
  const {
    // Redux props
    dashboardData,
    dashboardStatus,
    loggedUser,
    dashboardPostStatus,
    dashboardRequestId,
    redirectedToDashboardWhenNoAssignedNumber,
    // Redux func
    fetchDashboard,
    postDashboard,
    setRedirectedToDashboardWhenNoAssignedNumber,
  } = props;
  //const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [initialLayout, setInitialLayout] = useState([]);

  // we use this flag to ensure that if user has a number unassigned then and
  // first opened route is dashboard, we ensure that redirection will not be done again
  useEffect(() => {
    if (!redirectedToDashboardWhenNoAssignedNumber) {
      setRedirectedToDashboardWhenNoAssignedNumber(true);
    }
  }, [
    redirectedToDashboardWhenNoAssignedNumber,
    setRedirectedToDashboardWhenNoAssignedNumber,
  ]);

  // add gifts widget if there are gifts
  useEffect(() => {
    if (loggedUser.new_gifts > 0) {
      setInitialLayout(() => {
        const newLayout = getDefaultDashboardLayout(
          DEFAULT_DASHBOARD_LAYOUT
        ).map((item) => {
          if (item.x === 0 || item.x === 1) {
            return {
              ...item,
              y: item.y + 2,
            };
          }
          return item;
        });
        newLayout.unshift({
          i: "gift-claim",
          x: 0,
          y: 0,
          w: 2,
          h: 2,
          static: true,
        });
        return newLayout;
      });
    } else {
      setInitialLayout(getDefaultDashboardLayout(DEFAULT_DASHBOARD_LAYOUT));
    }
  }, [loggedUser.new_gifts]);

  // Used to force update the default layout if already updated
  useEffect(() => {
    if (isToForceUpdateFromFrontend) {
      postDashboard({
        dashboard_data: JSON.stringify(
          getDefaultDashboardLayout(DEFAULT_DASHBOARD_LAYOUT)
        ),
      });
      localStorage.setItem(
        FORCE_DEFAULT_LAYOUT_DASHBOARD,
        forceDefaultLayoutNumber + 1
      );
    }
  }, [postDashboard]);

  // Save dashboard first layout if not already there
  useEffect(() => {
    if (!firstDashboardAdded && initialLayout.length > 0) {
      postDashboard({
        dashboard_data: JSON.stringify(initialLayout),
      });
      localStorage.setItem(FIRST_LOAD_DASHBOARD, true);
    }
  }, [initialLayout, postDashboard]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  useEffect(() => {
    if (dashboardPostStatus === "success") {
      fetchDashboard(REQUEST_ID);
    }
  }, [dashboardPostStatus, fetchDashboard]);

  return (
    <LayoutDefault className="inbox">
      {(dashboardStatus === "loading" || initialLayout.length === 0) &&
      !dashboardRequestId ? (
        <Spinner />
      ) : (
        <>
          <DashboardHeader />
          <DashboardBody
            initialLayout={
              !firstDashboardAdded || isToForceUpdateFromFrontend
                ? initialLayout
                : dashboardData
            }
          />
        </>
      )}
    </LayoutDefault>
  );
};

Dashboard.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
