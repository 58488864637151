import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as callsActions from "../../../actions/calls";

export const useCallsStore = () => {
  const dispatch = useDispatch();
  const _calls = useSelector((store) => store.calls);

  const fetchVoicePrice = useCallback(
    (companyId, countryId) =>
      dispatch(callsActions.fetchVoicePrice(companyId, countryId)),
    [dispatch]
  );

  const holdCall = useCallback(
    async (data) => await dispatch(callsActions.holdCall(data)),
    [dispatch]
  );

  const unholdCall = useCallback(
    async (data) => await dispatch(callsActions.unholdCall(data)),
    [dispatch]
  );

  const terminateHoldCall = useCallback(
    async (data) => await dispatch(callsActions.terminateHoldCall(data)),
    [dispatch]
  );

  const clearHoldCallStatus = useCallback(
    async () => await dispatch(callsActions.clearHoldCallStatus()),
    [dispatch]
  );

  return {
    calls: _calls,
    fetchVoicePrice,
    holdCall,
    unholdCall,
    clearHoldCallStatus,
    terminateHoldCall,
  };
};
