import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import TooltipWhenOverflow from "./TooltipWhenOverflow";
import { ReactComponent as ExpandDownArrow } from "../../../assets/img/icons-new/general/expand/expand-black.svg";
import { ReactComponent as ExpandUpArrow } from "../../../assets/img/icons-new/general/expand/expand-up-black.svg";
import { findValueRecursively } from "../../../helpers";
import ThemeContext from "../../../ThemeContext/ThemeContext";
import colors from "../../../assets/themedColors";

const Placeholder = ({ children }) => {
  const { globalTheme } = useContext(ThemeContext);
  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: colors.iconMidGray[`${globalTheme}`],
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }));
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const CustomSelect = (props) => {
  const {
    value,
    onChange,
    options,
    disabled,
    fullWidth,
    underline,
    placeholder,
    label,
    className,
    subHeaders, // non clickable sub headers that organize the options
    withTooltip, // if the option is too long show tooltip for the full text of the option
    icon, // used to display an icon on every select option
    hasNestedOptions, // if true, there are nested options with clickable sub headers
    // selectPopupHeight, // exact hight for displaying the custom scrollbar | it only works for nested options
    customPopoverComponent, // custom component after clicking on select component
    customPopoverScrollbarsProps, // props for custom component wrapper scrollbar
    customPopoverContainerStyle, // props for custom popover div wrapper container
    customPopoverOpen, // this is used as a flag to show or open the custom popover from outside component
    setCustomPopoverOpen, // this is used to set the custom popover on or off from outside component
    onClickSelect, // function when clicking on select (see PollResultsCampaign.js for usage)
    onLoadMore,
  } = props;
  const { globalTheme } = useContext(ThemeContext);

  const useStyles = makeStyles((theme) => ({
    selectParent: {
      "&&&:before": {
        border: "none",
      },
      "&&&:after": {
        border: "none",
      },
      "&:hover:not($disabled):before": {
        borderBottom: "none !important",
      },
    },
    select: {
      "&:focus": {
        background: "transparent",
      },
    },
    selectPopupHeight: {
      height: 226,
    },
    inputBase: {
      fontSize: "16px",
    },
    disabledSelect: {
      color: colors.iconMidGray[`${globalTheme}`],
    },
    svgIcon: {
      color: `${
        globalTheme === "dark"
          ? "rgba(255, 255, 255, 0.9)"
          : "rgba(0, 0, 0, 0.9)"
      }`,
    },
    svgIconDisabled: {
      color: colors.iconMidGray[`${globalTheme}`],
    },
    listSubHeader: {
      color: colors.iconMidGray[`${globalTheme}`],
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "30px",
    },
    menuItem: {
      paddingTop: 3,
      paddingBottom: 3,
    },
    menuItemForSubHeader: {
      paddingLeft: 32,
    },
    subMenuTitle: {
      color: "rgba(0, 0, 0, 0.9)",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "30px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      position: "relative",
    },
    subMenuFirstOption: {
      marginTop: 8,
    },
    unselectedOption: {
      "&.Mui-focusVisible": {
        backgroundColor: "#ffffff",
      },
    },
    label: {
      color: colors.iconMidGray[`${globalTheme}`],
    },
  }));

  const selectRef = React.useRef();

  const [openCustomPopoverInner, setOpenCustomPopoverInner] = useState(false);
  let openCustomPopover;
  let setOpenCustomPopover;

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const loadMoreTimer = useRef();

  if (customPopoverOpen) openCustomPopover = customPopoverOpen;
  else openCustomPopover = openCustomPopoverInner;
  if (setCustomPopoverOpen) setOpenCustomPopover = setCustomPopoverOpen;
  else setOpenCustomPopover = setOpenCustomPopoverInner;

  const [popOverWidth, setPopOverWidth] = useState(0);

  const [maxWidthMenuItem, setMaxWidthMenuItem] = useState("auto");
  const [nestedOptions, setNestedOptions] = useState([]);
  const classes = useStyles();
  const inputRef = useRef();

  const getMaxWidthMenuItem = useCallback(() => {
    if (typeof maxWidthMenuItem === "number" && subHeaders?.length > 0) {
      return maxWidthMenuItem - 48; // 48 means the padding that menu item has 32px left and 16px right
    }

    if (typeof maxWidthMenuItem === "number") {
      return maxWidthMenuItem - 32; // 32 means the padding that menu item has 16px left and 16px right
    }

    return maxWidthMenuItem;
  }, [maxWidthMenuItem, subHeaders]);

  const renderOptionWithIcon = useCallback((option, icon) => {
    if (!!icon) {
      return (
        <span className="d-flex align-items-center">
          {icon} {option}
        </span>
      );
    } else {
      return option;
    }
  }, []);

  const renderOptions = useCallback(
    (optionsToRender) => {
      return optionsToRender?.map((option) => (
        <MenuItem
          disabled={option.disabled}
          value={option.value}
          key={option.value}
          className={`${classes.menuItem} ${
            subHeaders?.length > 0 ? classes.menuItemForSubHeader : ""
          } ${
            hasNestedOptions && option.value === "1" && option.level === 0
              ? `${classes.subMenuFirstOption} ${
                  value !== option.value && classes.unselectedOption
                }`
              : ""
          }`}
          style={{
            paddingLeft:
              !!option.level && option.level !== 0 && option.level * 32, //compute custom padding for nested option
          }}
        >
          {withTooltip ? (
            <TooltipWhenOverflow
              maxWidth={getMaxWidthMenuItem()}
              text={option.name}
            >
              {renderOptionWithIcon(option.name, icon)}
            </TooltipWhenOverflow>
          ) : (
            renderOptionWithIcon(option.name, icon)
          )}
        </MenuItem>
      ));
    },
    [
      classes.menuItem,
      classes.menuItemForSubHeader,
      classes.subMenuFirstOption,
      classes.unselectedOption,
      hasNestedOptions,
      getMaxWidthMenuItem,
      subHeaders,
      withTooltip,
      icon,
      value,
      renderOptionWithIcon,
    ]
  );

  const renderNestedOptions = useCallback(
    (optionsToRender) => {
      let result = [];
      optionsToRender.map((option, index) => {
        if (option.startIndex !== undefined) {
          result = result.concat(
            <ListSubheader
              className={classes.subMenuTitle}
              key={option.name}
              style={{
                paddingLeft:
                  !!option.level && option.level !== 0 ? option.level * 32 : 16,
                marginTop: option.level === 0 && index === 0 ? 8 : 0,
              }}
            >
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  option.isSubMenuOpen = !option.isSubMenuOpen;
                  setNestedOptions([].concat(renderNestedOptions(options)));
                }}
              >
                {option.name}{" "}
                {option.isSubMenuOpen ? <ExpandUpArrow /> : <ExpandDownArrow />}
              </div>
            </ListSubheader>
          );
          if (!!option.isSubMenuOpen) {
            result = result.concat(renderNestedOptions(option.children));
          }
        } else {
          result = result.concat(renderOptions([option]));
        }
        return null;
      });
      return result;
    },
    [classes.subMenuTitle, renderOptions, options]
  );

  const closeAllSubMenus = useCallback(() => {
    options.map((option) => {
      if (option.isSubMenuOpen) {
        option.isSubMenuOpen = false;
      }
      return null;
    });
  }, [options]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange({ target: { value } });
      hasNestedOptions && closeAllSubMenus();
    },
    [hasNestedOptions, onChange, closeAllSubMenus]
  );

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && withTooltip)
        setMaxWidthMenuItem(inputRef.current.offsetWidth);
    }, 500);
  }, [withTooltip]);

  useEffect(() => {
    if (hasNestedOptions && nestedOptions.length === 0) {
      setNestedOptions([].concat(renderNestedOptions(options)));
    }
  }, [
    nestedOptions,
    setNestedOptions,
    renderNestedOptions,
    options,
    hasNestedOptions,
  ]);

  const renderOption = useCallback(
    (value) => {
      if (hasNestedOptions) {
        const option = findValueRecursively(options, "value", value);
        const valueToRender = option.nameToRender || option.name;
        return renderOptionWithIcon(valueToRender, icon);
      } else {
        const option =
          value !== undefined &&
          options?.length > 0 &&
          options.find((option) =>
            typeof option.value === "string"
              ? option.value === value.toString()
              : Number(option.value) === Number(value)
          );
        return renderOptionWithIcon(option?.name, icon);
      }
    },
    [hasNestedOptions, icon, options, renderOptionWithIcon]
  );

  const renderValue = useCallback(
    (value) => {
      if (value !== "") {
        return renderOption(value);
      } else if (placeholder && !label) {
        return <Placeholder>{placeholder}</Placeholder>;
      }
      return null;
    },
    [placeholder, label, renderOption]
  );

  useEffect(() => {
    if (customPopoverComponent && selectRef.current?.offsetWidth > 0) {
      setPopOverWidth(selectRef.current.offsetWidth);
    }
  }, [customPopoverComponent]);

  return (
    <>
      <FormControl
        className={`${classes.formControl} ${className}`}
        fullWidth={fullWidth || withTooltip}
        ref={inputRef}
      >
        {label && (
          <InputLabel className={classes.label} id="multiple-checkbox">
            {label}
          </InputLabel>
        )}
        <Select
          ref={selectRef}
          displayEmpty
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onClick={onClickSelect}
          classes={{
            select: `${classes.select} ${
              disabled ? classes.disabledSelect : ""
            }`,
            icon: disabled ? classes.svgIconDisabled : classes.svgIcon,
          }}
          disabled={disabled}
          className={!underline ? classes.selectParent : undefined}
          inputProps={{
            className: classes.inputBase,
          }}
          renderValue={renderValue}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            getContentAnchorEl: null,
            MenuListProps: hasNestedOptions && { component: Scrollbars },
            classes: hasNestedOptions
              ? { paper: classes.selectPopupHeight }
              : null,
          }}
          open={customPopoverComponent ? false : undefined}
          onOpen={
            customPopoverComponent
              ? () => setOpenCustomPopover(true)
              : undefined
          }
        >
          {/* If there are non clickable sub headers and not nested options */}
          {subHeaders?.length > 0 &&
            !hasNestedOptions &&
            subHeaders.map((subHeader, index) => [
              <ListSubheader
                className={classes.listSubHeader}
                key={subHeader.text}
              >
                {subHeader.text}
              </ListSubheader>,
              ...renderOptions(
                options.slice(subHeader.startIndex, subHeader.endIndex + 1)
              ),
            ])}
          {/* If there are no sub headers and has nested options */}
          {subHeaders?.length === 0 && hasNestedOptions && nestedOptions}
          {/* If there are no sub headers and no nested options */}
          {subHeaders.length === 0 &&
            !hasNestedOptions &&
            renderOptions(options)}
        </Select>
      </FormControl>
      <Popover
        id="custom-select-popover"
        open={openCustomPopover}
        anchorEl={selectRef.current}
        onClose={() => setOpenCustomPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Scrollbars
          autoHide
          hideTracksWhenNotNeeded
          autoHeight
          autoHeightMax={300}
          onScroll={(e) => {
            if (onLoadMore) {
              const { srcElement } = e;
              const { scrollTop, scrollHeight, clientHeight } = srcElement;
              const pad = 100;
              const t = scrollTop + clientHeight + pad;
              const st = scrollTop;
              if (t > scrollHeight && st > lastScrollTop) {
                clearTimeout(loadMoreTimer.current);
                loadMoreTimer.current = setTimeout(() => {
                  onLoadMore();
                }, 100);
              }

              setLastScrollTop(scrollTop);
            }
          }}
          {...customPopoverScrollbarsProps}
        >
          <div
            style={{
              width: popOverWidth,
              padding: "1rem",
              ...customPopoverContainerStyle,
            }}
          >
            {customPopoverComponent}
          </div>
        </Scrollbars>
      </Popover>
    </>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]),
});

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOf(optionPropType, PropTypes.arrayOf(optionPropType))
  ).isRequired,
  fullWidth: PropTypes.bool,
  underline: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  subHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      startIndex: PropTypes.number.isRequired,
      endIndex: PropTypes.number.isRequired,
    })
  ),
  withTooltip: PropTypes.bool,
  hasNestedOptions: PropTypes.bool,
  customPopoverComponent: PropTypes.node,
  customPopoverScrollbarsProps: PropTypes.any,
  customPopoverOpen: PropTypes.bool,
  setCustomPopoverOpen: PropTypes.func,
  onClickSelect: PropTypes.func,
  onLoadMore: PropTypes.func,
};

CustomSelect.defaultProps = {
  disabled: false,
  fullWidth: false,
  underline: false,
  placeholder: "",
  label: "",
  className: "",
  subHeaders: [],
  withTooltip: false,
  hasNestedOptions: false,
  icon: null,
};

export default CustomSelect;
