import { useCallback } from "react";
import {
  calculateBitrates,
  convertBytesToMegabytes,
  getVideoDeletionHandlers,
} from "../videoHelpers";
import { getVideoHandler, videoTranscoding } from "../filestackHelpers";
import useFilestackStore from "./ReduxHooks/filestackStore";

export const useGetBitratedVideoDetails = (
  isSenderNumberSelectedAsShortcodeOrTollFree,
  recursiveCount,
  setRecursiveCount
) => {
  // Redux store
  const { deleteFilestackFiles } = useFilestackStore();

  const isAllowedFileSizeUploaded = useCallback(
    (fileSize) => {
      const videoSizeInMb = convertBytesToMegabytes(fileSize); // Size in mb
      const videoSizeCheck = isSenderNumberSelectedAsShortcodeOrTollFree
        ? fileSize > 600000
        : videoSizeInMb > 1;
      return videoSizeCheck;
    },
    [isSenderNumberSelectedAsShortcodeOrTollFree]
  );

  // Define a useCallback function named getBitratedVideo with async behavior.
  const getBitratedVideo = useCallback(
    async (file, isBitratingVideoAgain) => {
      // Calculate video and audio bitrates based on file duration and a condition.
      const { videoBitrateKbps, audioBitrateKbps } = calculateBitrates(
        file?.result?.duration,
        isSenderNumberSelectedAsShortcodeOrTollFree
      );

      const isEligibleVideo = !isAllowedFileSizeUploaded(
        file?.result?.file_size
      );

      // Retrieve a video handler for the given file URL.
      const videoHandler = getVideoHandler(file?.file?.url);

      let videoParams;

      if (isEligibleVideo) {
        videoParams = `preset:thumbnail`;
      } else if (isBitratingVideoAgain) {
        // Determine the bitrate parameters based on whether video is being bitrated again.
        // When bitrating again, use reduced bitrates and preserve aspect ratio.
        videoParams = `video_bitrate:${videoBitrateKbps / 2},audio_bitrate:${
          audioBitrateKbps / 2
        },upscale:false,aspect_mode:preserve`;
      } else {
        // When not bitrating again, use original bitrates and preserve aspect ratio.
        videoParams = `video_bitrate:${videoBitrateKbps},audio_bitrate:${audioBitrateKbps},upscale:false,aspect_mode:preserve`;
      }

      // Transcode the video with the specified parameters.
      const bitratedVideo = await videoTranscoding(
        {
          handle: videoHandler,
          url: file?.file?.url,
          metadata: { result: file?.result },
        },
        videoParams,
        isEligibleVideo
      );

      if (!isEligibleVideo) {
        // Create deletion handlers for the bitrated video.
        const bitratedVideoHandler = getVideoDeletionHandlers([videoHandler]);

        // Delete the original video files.
        deleteFilestackFiles(bitratedVideoHandler);
      }

      // Check if the bitrated video's file size is allowed.
      const videoSizeCheck = isAllowedFileSizeUploaded(
        bitratedVideo?.result?.file_size
      );

      if (videoSizeCheck && recursiveCount < 3) {
        setRecursiveCount(recursiveCount++);
        // If the video size is allowed, recursively call getBitratedVideo to further process the bitrated video.
        return getBitratedVideo(bitratedVideo, true);
      }

      // Return the bitrated video.
      return bitratedVideo;
    },
    // Define dependencies for this useCallback function.
    [
      deleteFilestackFiles,
      isAllowedFileSizeUploaded,
      isSenderNumberSelectedAsShortcodeOrTollFree,
      recursiveCount,
      setRecursiveCount,
    ]
  );

  return {
    getBitratedVideo,
    isAllowedFileSizeUploaded,
  };
};
