import { TextField } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import Illustration from "../../assets/img/verification/illustration.png";
import TextFieldPassword from "../elements/TextFieldPassword";
import NumberSteps from "../hub/HelperComponents/NumberSteps";
import RadioButtonWithLabel from "../hub/HelperComponents/RadioButtonWithLabel";
import pastorsLineLogo from "../../assets/img/pastorsline-logo.png";
import { ReactComponent as AmericanFlag } from "../../assets/img/icons-new/general/flags/US-flag.svg";
import { ReactComponent as GiftCard } from "../../assets/img/verification/gift-card.svg";
import { ReactComponent as PhoneWithStar } from "../../assets/img/verification/phone-with-star.svg";
import AccountIsInReview from "./AccountIsInReview";
import ChooseOwnNumber from "./ChooseOwnNumber";
import PickDifferentNumberModal from "../modals/AdminSettings/AddNewNumber/PickDifferentNumberModal/PickDifferentNumberModal";
import Spinner from "../hub/HelperComponents/Spinner";

const Welcome = ({ onClickButton }) => {
  return (
    <div className="welcome">
      <div className="mx-auto">
        <img src={Illustration} alt="illustration" />
        <h1 className="title">Welcome, Utsah!</h1>
        <p className="text">
          Thank you for registering!
          <br />
          Your account is now active and you can start setting up your account!
        </p>
        <div className="button-wrapper mx-auto" onClick={onClickButton}>
          <button className="btn btn-primary btn-block">
            Start Setting Up
          </button>
        </div>
      </div>
    </div>
  );
};

const SettingUpDashboard = () => {
  return (
    <div className="welcome">
      <div className="mx-auto d-flex justify-content-center flex-column">
        <img src={Illustration} alt="illustration" />
        <div className="loading-icon">
          <Spinner type="dots" />
        </div>
        <p className="text">Setting up your dashboard</p>
      </div>
    </div>
  );
};
const StepOne = () => {
  return (
    <div className="step-one row">
      <div className="col-7">
        <TextField label="Full Name" fullWidth className="mb-3" />
        <TextField label="Username" fullWidth className="mb-3" />
        <TextField label="Organization Name" fullWidth className="mb-3" />
        <TextFieldPassword label="Password" fullWidth className="mb-3" />
        <TextFieldPassword
          label="Confirm Password"
          fullWidth
          className="mb-3"
        />
      </div>
      <div className="col-5 pl-5 right-column">
        <p className="label">Time Zone Default</p>
        <p className="text">
          <AmericanFlag style={{ width: "25px", height: "25px" }} /> Chicago,
          IL, USA (GMT-5)
        </p>
        <p className="label">Country Default</p>
        <p className="text">
          <AmericanFlag style={{ width: "25px", height: "25px" }} /> USA
        </p>
      </div>
    </div>
  );
};

const StepTwo = () => {
  const [displayInput, setDisplayInput] = useState(false);
  return (
    <div className="step-two">
      <div style={{ width: 95, height: 75 }} className="mx-auto">
        <GiftCard />
      </div>
      <div className="invite-code">
        <p className="text">Do you have an Invite Code?</p>
        <div className="d-flex justify-content-around">
          <RadioButtonWithLabel
            label="Yes!"
            onChange={() => setDisplayInput(true)}
          />
          <RadioButtonWithLabel
            label="No"
            onChange={() => setDisplayInput(false)}
          />
        </div>
        {displayInput && (
          <TextField
            variant="outlined"
            className="mt-5 input-box"
            InputProps={{
              classes: { input: "invite-code-input" },
            }}
          />
        )}
      </div>
    </div>
  );
};

const StepThree = () => {
  const [openChooseOwnNumber, setOpenChooseOwnNumber] = useState(false);
  const [ownLandlineIsSelected, setOwnLandlineIsSelected] = useState(false);
  const [openPickDifferentNumberModal, setOpenPickDifferentNumberModal] =
    useState(false);
  return (
    <div className="step-three">
      {openChooseOwnNumber ? (
        <>
          <ChooseOwnNumber
            setOwnLandlineIsSelected={setOwnLandlineIsSelected}
          />
          {ownLandlineIsSelected && (
            <span className="own-landline-text">
              You will set your existing landline from dashboard!
            </span>
          )}
        </>
      ) : (
        <>
          <div style={{ width: 95, height: 75 }} className="mx-auto">
            <PhoneWithStar /> {/* to be changed with the correct svg*/}
          </div>
          <div className="mt-3 d-flex justify-content-around flex-column align-items-center">
            <div className="text">
              Based on your location, we have selected this number for you!
            </div>
            <div className="phone-number">
              <AmericanFlag style={{ width: "25px", height: "25px" }} /> +1
              (435)-305-0588
            </div>
            <button
              className="btn choose-type-of-number-button"
              onClick={() =>
                setOpenPickDifferentNumberModal((prevState) => !prevState)
              }
            >
              I want a different new custom number
            </button>
            {ownLandlineIsSelected ? (
              <button
                className="btn choose-type-of-number-button"
                onClick={() =>
                  setOpenChooseOwnNumber((prevState) => !prevState)
                }
              >
                Choose my own number
              </button>
            ) : (
              <button
                className="btn choose-type-of-number-button"
                onClick={() =>
                  setOpenChooseOwnNumber((prevState) => !prevState)
                }
              >
                Choose my own Existing Landline or Toll Free Number
              </button>
            )}
          </div>
        </>
      )}
      <PickDifferentNumberModal
        show={openPickDifferentNumberModal}
        closeModal={() => setOpenPickDifferentNumberModal(false)}
      />
    </div>
  );
};

const VerifyYourDetails = ({ step, setStep }) => {
  const getStepTitle = useCallback(() => {
    if (step === 1) return "Verify Your Details";
    if (step === 2) return "Select your Free Trial Method";
    if (step === 3) return "Set Up Your Numbers";
  }, [step]);

  const renderFooterButtons = useCallback(() => {
    if (step === 1) {
      return (
        <div className="button-wrapper">
          <button
            className="btn btn-primary btn-block"
            onClick={() => setStep(step + 1)}
          >
            Confirm
          </button>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="button-wrapper">
          <button
            className="btn btn-primary btn-block"
            onClick={() => setStep(step + 1)}
          >
            Continue
          </button>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="button-wrapper d-flex w-100 justify-content-around step-three-buttons">
          <button
            className="btn btn-outline-primary"
            onClick={() => setStep(step - 1)}
          >
            Back
          </button>
          <div>
            <button
              className="btn skip-button"
              onClick={() => setStep(step + 1)}
            >
              Skip for now
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setStep(step + 1)}
            >
              Okay! Next Step
            </button>
          </div>
        </div>
      );
    }
  }, [setStep, step]);

  return (
    <div className="steps-container">
      <div className="line-steps">
        {step !== 4 && (
          <NumberSteps
            currentStep={step}
            steps={3}
            type="small"
            barType="small"
            showNumbers={false}
          />
        )}
        <p className="title">{getStepTitle()}</p>
      </div>
      {step === 1 && <StepOne />}
      {step === 2 && <StepTwo />}
      {step === 3 && <StepThree />}
      {step === 4 && <SettingUpDashboard />}
      <div className="button-footer">{renderFooterButtons()}</div>
    </div>
  );
};

const Verification = (props) => {
  const [accountIsInReview, setAccountIsInReview] = useState(false);
  const [step, setStep] = useState(1);

  return (
    <div className="verification-container">
      <div className="logo-container">
        <img src={pastorsLineLogo} alt="pastorsline-logo" />
      </div>
      {accountIsInReview && <AccountIsInReview />}
      {step === 0 && <Welcome onClickButton={() => setStep(1)} />}
      {step >= 1 && <VerifyYourDetails step={step} setStep={setStep} />}
      <button
        onClick={() => setAccountIsInReview((prevState) => !prevState)}
        className="btn btn-primary"
      >
        Show Account is in review
      </button>
    </div>
  );
};

Verification.propTypes = {};

export default Verification;
