import React from "react";
import CustomModal from "./CustomModal";

const ConfirmAlreadyScheduledModal = (props) => {
  return (
    <CustomModal
      {...props}
      dialogClassName="modal-dialog-centered"
      title={`Message Already Scheduled`}
      buttons={[
        {
          type: "confirm",

          caption: "Schedule Anyway",

          handler: props.handleConfirm,
        },

        {
          type: "cancel",

          caption: "Cancel",

          handler: props.closeModal,
        },
      ]}
    >
      <p
        className={"mb-3 text-left modal-body"}
        style={{ fontSize: "initial" }}
      >
        You are scheduling the message that is already scheduled for the same
        day. Are you sure you want to schedule once more?
      </p>
    </CustomModal>
  );
};

export default ConfirmAlreadyScheduledModal;
