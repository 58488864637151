import React, { useCallback, useEffect, useState } from "react";

const BonusNotificationCountdown = () => {
  const countdownDate = new Date("12/18/2021").getTime();
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const updateCountdown = useCallback(() => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }
      setState({ days: days, hours: hours, minutes, seconds });
    }
  }, [countdownDate]);

  useEffect(() => {
    updateCountdown();
    setInterval(() => updateCountdown(), 1000);
  }, [updateCountdown]);

  return (
    <div className="countdown-timer">
      {state.seconds !== 0 && (
        <table>
          <tbody>
            <tr>
              <td>{state.days}</td>
              <td className="px-2">:</td>
              <td>{state.hours}</td>
              <td className="px-2">:</td>
              <td>{state.minutes}</td>
              <td className="px-2">:</td>
              <td>{state.seconds}</td>
            </tr>
            <tr className="time-elements">
              <td>Days</td>
              <td></td>
              <td>Hours</td>
              <td></td>
              <td>Min</td>
              <td></td>
              <td>Sec</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BonusNotificationCountdown;
