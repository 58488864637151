import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import queryString from "query-string";
import { fetchUserData } from "./users";
import { refreshCounts } from "../helpers";

export function sendMessage(
  companyId,
  receiverType,
  receiverId,
  senderNumber,
  sendOn,
  facebook,
  message,
  images,
  restrictedConfirmed,
  editScheduleMessageId,
  useSecondary,
  signatureId,
  signatureUserId,
  signatureUserNumber,
  linkImageWarningConfirmed,
  alreadyScheduledConfirmed,
  recurringRule
) {
  return function (dispatch) {
    dispatch({ type: "SEND_MESSAGE", receiverId });

    const data = {
      companyId,
      receiverType,
      receiverId,
      senderNumber,
      sendOn,
      facebook,
      message,
      images,
      restrictedConfirmed,
      editScheduleMessageId,
      useSecondary,
      signatureId,
      signatureUserId,
      signatureUserNumber,
      linkImageWarningConfirmed,
      alreadyScheduledConfirmed,
      recurringRule,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "messages/send.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (response.data.status === 99) {
          const { message, status } = response.data;
          const payload = {
            message: message ? message : "Spam keyword detected",
            status,
          };
          dispatch({
            type: "SEND_MESSAGE_ERROR",
            payload,
          });

          dispatch(fetchUserData());
        }
        dispatch({
          type: "SEND_MESSAGE_SUCCESS",
          payload: response.data,
          isOnEditScheduledMessage: editScheduleMessageId > 0,
          receiverId,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "SEND_MESSAGE_ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearSendMessage() {
  return function (dispatch) {
    dispatch({
      type: "SEND_MESSAGE.CLEAR",
    });
  };
}

export function sendScheduledNow(companyId, scheduleMessageId) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/SEND_SCHEDULED_NOW",
      companyId,
      scheduleMessageId,
    });

    const url =
      AxiosConfig.getEndpointAddress() +
      `messages/send-scheduled-now/${scheduleMessageId}.json?company_id=${companyId}`;
    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "MESSAGES/SEND_SCHEDULED_NOW.SUCCESS",
          companyId,
          scheduleMessageId,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGES/SEND_SCHEDULED_NOW.ERROR",
          companyId,
          scheduleMessageId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function stopScheduledNow(companyId, scheduleMessageId) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/STOP_SCHEDULED_NOW",
      companyId,
      scheduleMessageId,
    });

    const url =
      AxiosConfig.getEndpointAddress() +
      `messages/stop-scheduled-now/${scheduleMessageId}.json`;
    return axios
      .post(url, { companyId }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "MESSAGES/STOP_SCHEDULED_NOW.SUCCESS",
          companyId,
          scheduleMessageId,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGES/STOP_SCHEDULED_NOW.ERROR",
          companyId,
          scheduleMessageId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearStopScheduledNow() {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/STOP_SCHEDULED_NOW.CLEAR",
    });
  };
}

export function deleteMessage(
  companyId,
  logId,
  groupSmsBlastId = null,
  scheduleMessageId = null
) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/DELETE",
      logId,
      groupSmsBlastId,
      scheduleMessageId,
    });

    const url =
      AxiosConfig.getEndpointAddress() +
      "messages/delete.json?" +
      queryString.stringify({
        company_id: companyId,
        log_id: logId,
        group_sms_blast_id: groupSmsBlastId,
        schedule_message_id: scheduleMessageId,
      });
    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "MESSAGES/DELETE.SUCCESS",
          logId,
          groupSmsBlastId,
          scheduleMessageId,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGES/DELETE.ERROR",
          logId,
          groupSmsBlastId,
          scheduleMessageId,
          error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearDeleteMessage() {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/DELETE.CLEAR",
    });
  };
}

export function clearStopSendScheduleNowStatus() {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/STOP_SEND_SCHEDULED_NOW.CLEAR",
    });
  };
}

export function toggleShowFeatures(show) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/TOGGLE_SHOW_FEATURES",
      show,
    });
  };
}
export function toggleFilters(show) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/TOGGLE_FILTERS",
      show,
    });
  };
}

// Actions for Send New Message
export function setBasicInfo(senderNumber, messageContent) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/SET_BASIC_INFO",
      senderNumber,
      messageContent,
    });
  };
}
export function setModalToAdd(isModalToAdd) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGES/SET_MODAL_TO_ADD",
      isModalToAdd,
    });
  };
}
export function addRecipientsForMessage(items, isOnExcludedMembersFilter) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/ADD_FILTERED_RECIPIENTS.SUCCESS",
      payload: items,
      isOnExcludedMembersFilter,
    });
}
export function removeRecipientForMessage(item) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/REMOVE_FILTERED_RECIPIENTS.SUCCESS",
      payload: item,
    });
}

export function clearRecipientGroupsForMessage() {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/GROUPS_FROM_RECIPIENTS.CLEAR",
    });
}

export function addGroupsForMessage(items) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/ADD_GROUPS_TO_RECIPIENTS.SUCCESS",
      payload: items,
    });
}
export function removeGroupsForMessage(item) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/REMOVE_GROUPS_FROM_RECIPIENTS.SUCCESS",
      payload: item,
    });
}
export function setRecipientToBeRemoved(data) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/SET_RECIPIENT_TO_BE_REMOVED.SUCCESS",
      payload: data,
    });
}

export function setExistingGroupId(groupId) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/SET_EXISTING_GROUP_ID.SUCCESS",
      payload: groupId,
    });
}

export function setManuallyAddedContactIds(contactIds) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/SET_MANUALLY_ADDED_CONTACT_IDS",
      payload: contactIds,
    });
}

export function clearManuallyAddedContactIds() {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/SET_MANUALLY_ADDED_CONTACT_IDS_CLEAR",
    });
}

export function clearFilteredRecipients() {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/FILTERED_RECIPIENTS.CLEAR",
    });
}

export function clearSendNowScheduleMessage() {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/SEND_SCHEDULED_NOW.CLEAR",
    });
}

export function toggleSendMessageWizard(isSendMessageWizard) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/TOGGLE_SEND_MESSAGE_WIZARD",
      payload: isSendMessageWizard,
    });
}

export function toggleDoNotShowDupProfileUpdatedInfoModal(doNotShowModal) {
  return (dispatch) =>
    dispatch({
      type: "MESSAGES/TOGGLE_DO_NOT_SHOW_DUP_PROFILE_UPDATED_INFO_MODAL",
      payload: doNotShowModal,
    });
}
