/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */

import { getDefaultDashboardLayout } from "../utils/dashboardHelpers";

const initialState = {
  dashboardStatsStatus: "",
  dashboardStatsData: {},
  billingCycle: {},

  giftsStatus: "",
  giftsData: [],

  dashboardStatus: "",
  dashboardRequestId: "",
  dashboardData: null,

  dashboardPostStatus: "",
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "DASHBOARD/FETCH_DASHBOARD_STATS": {
      return {
        ...state,
        dashboardStatsStatus: "loading",
      };
    }

    case "DASHBOARD/FETCH_DASHBOARD_STATS.SUCCESS": {
      return {
        ...state,
        dashboardStatsStatus: "success",
        dashboardStatsData: action.payload.data,
        billingCycle: action.payload.billing_cycle || {},
      };
    }

    case "DASHBOARD/FETCH_DASHBOARD_STATS.ERROR": {
      return {
        ...state,
        dashboardStatsStatus: "error",
        dashboardStatsData: {},
        billingCycle: {},
      };
    }

    case "DASHBOARD/FETCH_GIFTS": {
      return {
        ...state,
        giftsStatus: "loading",
      };
    }

    case "DASHBOARD/FETCH_GIFTS.SUCCESS": {
      return {
        ...state,
        giftsStatus: "success",
        giftsData: action.payload.data,
      };
    }

    case "DASHBOARD/FETCH_GIFTS.ERROR": {
      return {
        ...state,
        giftsStatus: "error",
        giftsData: [],
      };
    }

    case "DASHBOARD/FETCH_DASHBOARD": {
      return {
        ...state,
        dashboardRequestId: action.requestId,
        dashboardStatus: "loading",
      };
    }

    case "DASHBOARD/FETCH_DASHBOARD.SUCCESS": {
      return {
        ...state,
        dashboardRequestId: action.requestId,
        dashboardStatus: "success",
        dashboardData: Array.isArray(action.payload.dashboard_data)
          ? getDefaultDashboardLayout(action.payload.dashboard_data)
          : action.payload.dashboard_data,
      };
    }

    case "DASHBOARD/FETCH_DASHBOARD.ERROR": {
      return {
        ...state,
        dashboardRequestId: action.requestId,
        dashboardStatus: "error",
        dashboardData: {},
      };
    }

    case "DASHBOARD/POST_DASHBOARD": {
      return {
        ...state,
        dashboardPostStatus: "loading",
      };
    }

    case "DASHBOARD/POST_DASHBOARD.SUCCESS": {
      return {
        ...state,
        dashboardPostStatus: "success",
      };
    }

    case "DASHBOARD/POST_DASHBOARD.ERROR": {
      return {
        ...state,
        dashboardPostStatus: "error",
      };
    }

    case "DASHBOARD/POST_DASHBOARD.CLEAR": {
      return {
        ...state,
        dashboardPostStatus: "",
      };
    }

    default:
      return state;
  }
}
