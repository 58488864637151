import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as DragHandleLineSvg } from "../../../assets/img/icons-new/dashboard/drag-handle/drag-handle-line-gray.svg";
import clsx from "clsx";

const DraggableElement = (props) => {
  const {
    isDragging,
    onBeforeDrag,
    onMouseLeaveDrag,
    className,
    iconWidth,
    iconHeight,
  } = props;

  return (
    <div
      className={clsx(className, {
        "cursor-grabbing": isDragging,
      })}
      onMouseEnter={() => {
        if (!isDragging) {
          onBeforeDrag();
        }
      }}
      onMouseLeave={() => {
        if (!isDragging) {
          onMouseLeaveDrag();
        }
      }}
    >
      <DragHandleLineSvg width={iconWidth} height={iconHeight} />
    </div>
  );
};

DraggableElement.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  onBeforeDrag: PropTypes.func.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DraggableElement.defaultProps = {
  className: "",
  iconWidth: 13,
  iconHeight: 21,
};

export default DraggableElement;
