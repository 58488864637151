import React from "react";

//new icons
import { ReactComponent as CcbSvg } from "../assets/img/icons-new/people-info/ccb/ccb-gray.svg";
import { ReactComponent as PcoSvg } from "../assets/img/icons-new/people-info/pco/pco-gray.svg";
import { ReactComponent as IconDarkSvg } from "../assets/img/icons-new/people-info/pastorsline/pastorsline-gray.svg";
import { ReactComponent as BreezeSvg } from "../assets/img/icons-new/integration/breeze/breeze-dark-gray.svg";
import { ReactComponent as RockSvg } from "../assets/img/icons-new/integration/rockRMS/rockRMS-dark-gray.svg";
import { ReactComponent as McSvg } from "../assets/img/icons-new/people-info/mailchimp/mailchimp-white.svg";
import { ReactComponent as ElvantoSvg } from "../assets/img/icons-new/people-info/elvanto/elvanto-black.svg";
import SpanWithTooltip from "./SpanWithTooltip";

const defaultProps = {
  className: null,
  style: {},
  baseSize: 16,
};

class IntegrationIcon extends React.PureComponent {
  render() {
    const {
      baseSize,
      className,
      style,
      is_ccb,
      is_pco,
      is_mc,
      is_elvanto,
      is_breeze,
      is_rock,
    } = this.props;

    if (!!is_ccb) {
      const styleN = Object.assign({}, style, {
        minWidth: (baseSize * 14.299) / 14.112 + "px",
      });
      return (
        <SpanWithTooltip title="CCB">
          <CcbSvg
            className={className}
            style={styleN}
            width={(baseSize * 14.299) / 14.112}
            height={(baseSize * 14.299) / 14.112}
          />
        </SpanWithTooltip>
      );
    } else if (!!is_pco) {
      return (
        <SpanWithTooltip title="PCO">
          <PcoSvg
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (!!is_mc) {
      return (
        <SpanWithTooltip title="MailChimp">
          <McSvg
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (!!is_elvanto) {
      return (
        <SpanWithTooltip title="Elvanto">
          <ElvantoSvg
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (!!is_breeze) {
      return (
        <SpanWithTooltip title="Breeze">
          <BreezeSvg
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (!!is_rock) {
      return (
        <SpanWithTooltip title="Rock">
          <RockSvg
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else {
      return (
        <SpanWithTooltip title="PastorsLine">
          <IconDarkSvg
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    }
  }
}

IntegrationIcon.defaultProps = defaultProps;

export default IntegrationIcon;
