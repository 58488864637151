import useMainStore from "../../../utils/hooks/ReduxHooks/mainStore";

export const useWithEmbeddedLoading = () => {
  const { main, setEmbeddedScreenLoading } = useMainStore();
  const { isEmbeddedScreenLoading } = main;

  return {
    isLoading: isEmbeddedScreenLoading,
    setIsLoading: setEmbeddedScreenLoading,
  };
};
