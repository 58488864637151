import { useEffect } from "react";
import { getPrimaryUrl } from "../../helpers";

const ResetPassword = (props) => {
  const {
    // Router
    match,
  } = props;

  const { companyId, hash } = match.params;

  useEffect(() => {
    window.location.href = getPrimaryUrl(
      `users/resetPassword/${hash}`,
      companyId
    );
  }, [companyId, hash]);

  return null;
};

export default ResetPassword;
