import React, { useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useGroupsStore from "../../../utils/hooks/ReduxHooks/groupsStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import { getExcludingMembers } from "../../../utils/sendMessageHelpers";
import { isEmptyObject } from "../../../helpers";
import { GROUP } from "../../../utils/constants";

const ExcludingMembers = (props) => {
  const { interlocutorId, threadType, openRecipientSidebar } = props;

  // Redux store
  const { fetchGroupMembersCountStatus, groups } = useGroupsStore();
  const { companies } = useCompaniesStore();
  const { currentCompany } = companies;
  const { dataMembersStatus, data } = groups;

  // Router
  const history = useHistory();

  const excludingMembers = useMemo(() => {
    if (interlocutorId && !isEmptyObject(data)) {
      return getExcludingMembers(data[interlocutorId]);
    }
    return "N/A";
  }, [data, interlocutorId]);

  useEffect(() => {
    if (
      interlocutorId &&
      dataMembersStatus &&
      !dataMembersStatus[interlocutorId] &&
      threadType === GROUP
    ) {
      fetchGroupMembersCountStatus(currentCompany?.id, interlocutorId);
    }
  }, [
    currentCompany,
    dataMembersStatus,
    fetchGroupMembersCountStatus,
    interlocutorId,
    threadType,
  ]);

  const onClickViewAllExcludeMembers = useCallback(() => {
    history.push("#show-excluded-members");
    openRecipientSidebar();
  }, [history, openRecipientSidebar]);

  if (
    !isEmptyObject(dataMembersStatus) &&
    dataMembersStatus[interlocutorId] === "loading"
  )
    return <span className="text-muted">Loading...</span>;

  return (
    <span>
      {excludingMembers}{" "}
      {excludingMembers !== "N/A" && (
        <span className="link-text ml-2" onClick={onClickViewAllExcludeMembers}>
          View all
        </span>
      )}
    </span>
  );
};

ExcludingMembers.propTypes = {
  interlocutorId: PropTypes.string,
  threadType: PropTypes.string,
  openRecipientSidebar: PropTypes.func.isRequired,
};

ExcludingMembers.defaultProps = {
  interlocutorId: "",
  threadType: "",
};

export default ExcludingMembers;
