import React from "react";
import PropTypes from "prop-types";

import { LightTooltip } from "../helpers";
import ButtonWithSpinner from "./hub/HelperComponents/ButtonWithSpinner";

/** 
 Example:

  <ButtonWithTooltip 
    title="close modal"         // tooltip text: any string
    placement="top"             // tooltip placement: top (default), bottom, left, right, also can be used with start-* and end-*
    arrow={boolean}              // default is true
    enterDelay={number}         // default: 500, tooltip transition delay on mouseover
    leaveDelay={number}         // default: 100, tooltip transition delay on mouseout
    disabled={boolean}             // default: false for button disabled
    onClick={anyButtonHandler}  // onClick handler
    className="someButtonClass" // class name

    or any other valid anchor element <button> related property
  > 
    <any child component>
  </ButtonWithTooltip>
 **/

const ButtonWithTooltip = (props) => {
  const {
    title,
    children,
    onClick,
    disabled,
    className,
    placement,
    arrow,
    style,
    enterDelay,
    leaveDelay,
    disableHoverListener,
    loading,
    bootstrapButtonClassName,
    textClassName,
    withBtnClassName,
    isRoundButton,
    ...rest
  } = props;

  return (
    <LightTooltip
      title={title}
      arrow={arrow}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      disableHoverListener={disableHoverListener}
    >
      <span>
        <ButtonWithSpinner
          onClick={disabled ? undefined : onClick}
          loading={loading}
          className={`${className} ${
            disabled ? "opacity-50 cursor-default" : "" // this is because when you setup disabled on the button the tooltip does not hide once you go off the button
          }`}
          style={style}
          bootstrapButtonClassName={bootstrapButtonClassName}
          textClassName={loading ? "ml-2" : textClassName}
          withBtnClassName={withBtnClassName}
          isRoundButton={isRoundButton}
          {...rest}
        >
          {children}
        </ButtonWithSpinner>
      </span>
    </LightTooltip>
  );
};

ButtonWithTooltip.propTypes = {
  children: PropTypes.node,

  // props for Lighttooltip component
  title: PropTypes.string.isRequired,
  textClassName: PropTypes.string,
  bootstrapButtonClassName: PropTypes.string,
  arrow: PropTypes.bool,
  withBtnClassName: PropTypes.bool,
  loading: PropTypes.bool,
  placement: PropTypes.string,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,

  // props for button element
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  disableHoverListener: PropTypes.bool,
  rest: PropTypes.objectOf(PropTypes.any),
};

ButtonWithTooltip.defaultProps = {
  children: <></>,
  arrow: true,
  enterDelay: 500,
  leaveDelay: 100,
  placement: "bottom",
  disabled: false,
  onClick: () => {},
  className: "",
  style: {},
  disableHoverListener: false,
  rest: {},
  loading: false,
  bootstrapButtonClassName: "",
  textClassName: "",
  withBtnClassName: false,
};

export default ButtonWithTooltip;
