import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
import Scrollbars from "react-custom-scrollbars";
// import { useMenusStore } from "../../utils/hooks/store/useMenusStore";
import clsx from "clsx";
import {
  isLgOrBelowBreakpoint,
  // isLgOrBelowBreakpoint,
  isMdOrBelowBreakpoint,
} from "../../../utils/breakpoints";
import SideBarIconWrapper from "../SideBarIconWrapper";
import { ReactComponent as MoreIcon } from "../../../assets/img/icons-new/main-nav-v2/more/more-purple.svg";
import { ReactComponent as ExpandDownArrow } from "../../../assets/img/icons-new/general/expand-new/expand-dark-gray.svg";
import { ReactComponent as ExpandUpArrow } from "../../../assets/img/icons-new/general/expand-new/expand-up-purple.svg";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import { useMenusStore } from "../../../utils/hooks/store/useMenusStore";
import useMessagesStore from "../../../utils/hooks/ReduxHooks/messagesStore";
import { useThreadsStore } from "../../../utils/hooks/store/useThreadsStore";
import SideBarSettingsNotifications from "./SideBarSettingsNotifications";
import SidebarMenus from "./SidebarMenus";
import { CUSTOMIZE_MENU } from "../../modals/DisplayPreference/displayPreferenceFilters";
import { useUnpinnedMenusAndFilters } from "../../../utils/hooks/useUnpinnedMenusAndFilters";
import { SETTINGS_BOTTOM_MENU } from "../../modals/DisplayPreference/CustomizeMenu/customizeMenusData";

const SideBarMoreMenus = (props) => {
  const { isSidebarExpanded, breakpoint } = props;

  // Redux store
  const { setDisplayPreferenceFilter } = useUsersStore();
  const { menus, setRedirectUrl, setIsMoreMenuOpen } = useMenusStore();
  const { toggleFilters } = useMessagesStore();
  const { threads } = useThreadsStore();
  const { selectedMessagesTab } = threads;
  const { isMoreMenuOpen } = menus;

  // custom hook
  const { sidebarTopUnpinnedMenus, sidebarBottomUnpinnedMenus } =
    useUnpinnedMenusAndFilters();

  // Routers
  const history = useHistory();
  const { tab } = useParams();
  const { pathname: url } = useLocation();

  const allUnpinnedMenus = useMemo(
    () => [...sidebarTopUnpinnedMenus, ...sidebarBottomUnpinnedMenus],
    [sidebarBottomUnpinnedMenus, sidebarTopUnpinnedMenus]
  );

  useEffect(() => {
    if (!isSidebarExpanded) {
      setIsMoreMenuOpen(false);
    }
  }, [isSidebarExpanded, setIsMoreMenuOpen]);

  const handleMenuIconClicked = useCallback(() => {
    if (isSidebarExpanded) {
      setIsMoreMenuOpen(!isMoreMenuOpen);
      if (tab !== "menus") {
        setRedirectUrl(url);
      }
      if (isLgOrBelowBreakpoint(breakpoint)) {
        toggleFilters(true);
      }
    }
  }, [
    breakpoint,
    setRedirectUrl,
    tab,
    toggleFilters,
    url,
    isSidebarExpanded,
    setIsMoreMenuOpen,
    isMoreMenuOpen,
  ]);

  const onClickCustomizeMenu = useCallback(() => {
    history.push("#modal-display-preferences");
    setDisplayPreferenceFilter(CUSTOMIZE_MENU);
  }, [history, setDisplayPreferenceFilter]);

  return (
    <span
      className={clsx({
        "sidebar-more-item-container": isSidebarExpanded,
        "w-100": !isMdOrBelowBreakpoint(breakpoint),
      })}
    >
      <li
        className={clsx("sidebar-list-item", {
          "mb-0": isSidebarExpanded,
        })}
        id="more-popover"
      >
        <div>
          <Link
            to={isMdOrBelowBreakpoint(breakpoint) ? "/hub/menus" : "#"}
            className={clsx("", {
              active:
                (tab === "menus" &&
                  allUnpinnedMenus.includes(SETTINGS_BOTTOM_MENU) &&
                  !isSidebarExpanded) ||
                (isMdOrBelowBreakpoint(breakpoint) && url === "/dashboard") ||
                (isMdOrBelowBreakpoint(breakpoint) &&
                  selectedMessagesTab === "all_text"),
              minimized: !isSidebarExpanded,
            })}
            onClick={handleMenuIconClicked}
          >
            <SideBarIconWrapper
              className={clsx("more-icon", {
                "sidebar-expanded-list-wrapper": isSidebarExpanded,
              })}
              id="more-sidebar"
            >
              <div
                className={clsx({
                  "sidebar-expanded-list": isSidebarExpanded,
                })}
              >
                <div className="sidebar-expanded-list-icon-wrapper">
                  <MoreIcon />
                </div>
                {isSidebarExpanded && (
                  <label>
                    {isMoreMenuOpen ? "Less" : "More Menu"}
                    <div className="sidebar-list-item-more-arrow">
                      {isMoreMenuOpen ? (
                        <ExpandDownArrow height={17} width={17} />
                      ) : (
                        <ExpandUpArrow height={17} width={17} />
                      )}
                    </div>
                  </label>
                )}
              </div>
            </SideBarIconWrapper>
          </Link>
        </div>
        {isSidebarExpanded && (
          <div>
            <hr className="sidebar-divider mt-1 mb-0 mr-3 ml-3" />
            <label
              className="sidebar-primary-text cursor-pointer w-100 ml-3 mt-2"
              onClick={onClickCustomizeMenu}
            >
              <i>Customize Menu</i>
            </label>
          </div>
        )}
      </li>

      {isSidebarExpanded && isMoreMenuOpen && (
        <div
          id="collapseMoreMenu"
          className="collapsible-body w-100"
          aria-labelledby="more-popover"
        >
          <SidebarMenus
            isSidebarExpanded={isSidebarExpanded}
            breakpoint={breakpoint}
            renderPinnedMenus={false}
            renderUnPinnedMenus={true}
          />

          {Array.isArray(sidebarBottomUnpinnedMenus) &&
            Boolean(sidebarBottomUnpinnedMenus.length) && (
              <>
                <hr className="sidebar-divider mt-1 mr-3 ml-3 mb-3" />
                <SideBarSettingsNotifications
                  isSidebarExpanded={isSidebarExpanded}
                  renderUnPinnedMenus={true}
                />
              </>
            )}
        </div>
      )}

      {!isSidebarExpanded && !isMdOrBelowBreakpoint(breakpoint) && (
        <UncontrolledPopover
          trigger="hover"
          placement={
            Array.isArray(allUnpinnedMenus) && allUnpinnedMenus.length >= 14
              ? "bottom-end"
              : "left"
          }
          target="more-popover"
          className="sidebar-more-menu-popover side-menu-popover"
          hideArrow={
            Array.isArray(allUnpinnedMenus) && allUnpinnedMenus.length >= 14
          }
        >
          <PopoverHeader>More Menus</PopoverHeader>
          <PopoverBody className="p-0">
            <Scrollbars autoHeight autoHeightMax={"25vh"}>
              <div className="overflow-hidden">
                <SidebarMenus
                  isSidebarExpanded={true}
                  breakpoint={breakpoint}
                  renderPinnedMenus={false}
                  renderUnPinnedMenus={true}
                  listClassName={"sidebar-more-menu-list"}
                  withMenuIcon={false}
                  withTooltip={false}
                />

                {Array.isArray(sidebarBottomUnpinnedMenus) &&
                  Boolean(sidebarBottomUnpinnedMenus.length) && (
                    <>
                      <hr className="mt-1 mb-3" />
                      <SideBarSettingsNotifications
                        isSidebarExpanded={true}
                        renderUnPinnedMenus={true}
                        withMenuIcon={false}
                      />
                    </>
                  )}
              </div>
            </Scrollbars>
            <div>
              <hr className="mt-1 mb-0" />
              <label
                className="popover-primary-text cursor-pointer w-100 ml-3 mt-2 mb-2"
                onClick={onClickCustomizeMenu}
              >
                <i>Customize Menu</i>
              </label>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </span>
  );
};

SideBarMoreMenus.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

export default SideBarMoreMenus;
