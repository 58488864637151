import { createSelectorCreator } from "reselect";
import memoize from "lodash.memoize";
import { stringify } from "flatted";

const safeSerialize = (obj) => stringify(obj);

// Based on https://github.com/reduxjs/reselect#use-memoize-function-from-lodash-for-an-unbounded-cache
const hashFn = (...args) =>
  args.reduce((acc, val) => acc + "-" + safeSerialize(val), "");

export const customSelectorCreator = createSelectorCreator(memoize, hashFn);
