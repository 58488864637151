import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ButtonIconTextCard from "../elements/ButtonIconTextCard";
import { ReactComponent as OutlinedNewNumberIcon } from "../../assets/img/verification/outlined-new-number-icon.svg";
import { ReactComponent as OutlinedLandlineIcon } from "../../assets/img/verification/outlined-landline-icon.svg";
const ChooseOwnNumber = (props) => {
  const { setOwnLandlineIsSelected } = props;
  const [newNumberActive, setNewNumberActive] = useState(false);
  const [newPlusExistingActive, setNewPlusExistingActive] = useState(false);
  const [existingNumberActive, setExistingNumberActive] = useState(false);

  const onClickNewNumber = useCallback(() => {
    setNewNumberActive((prevState) => !prevState);
    setNewPlusExistingActive(false);
    setExistingNumberActive(false);
    setOwnLandlineIsSelected(false);
  }, [setOwnLandlineIsSelected]);

  const onClickNewPlusExisting = useCallback(() => {
    setNewPlusExistingActive((prevState) => !prevState);
    setNewNumberActive(false);
    setExistingNumberActive(false);
    setOwnLandlineIsSelected(false);
  }, [setOwnLandlineIsSelected]);

  const onClickExistingNumber = useCallback(() => {
    setExistingNumberActive((prevState) => !prevState);
    setOwnLandlineIsSelected((prevState) => !prevState);
    setNewNumberActive(false);
    setNewPlusExistingActive(false);
  }, [setOwnLandlineIsSelected]);

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="question">
        How would you like to send text and calls to your contacts?
      </div>
      <div className="d-flex justify-content-center">
        <ButtonIconTextCard
          text={
            <div className="text-option">
              <p>New Number</p>
              <p className="option-description">Pick a Brand new Number</p>
            </div>
          }
          icon={<OutlinedNewNumberIcon />}
          iconHover={<OutlinedNewNumberIcon />}
          className="choose-number-option"
          active={newNumberActive}
          onClick={onClickNewNumber}
        />
        <ButtonIconTextCard
          text={
            <div className="text-option">
              <p>New + Existing</p>
              <p className="option-description">
                Both New Virtual Number and existing Organization Landline
                (Recommended)
              </p>
            </div>
          }
          icon={
            <div className="d-flex align-items-center justify-content-center">
              <OutlinedNewNumberIcon />
              <span className="plus-icon">+</span>
              <OutlinedLandlineIcon />
            </div>
          }
          iconHover={
            <div className="d-flex align-items-center justify-content-center">
              <OutlinedNewNumberIcon />
              <span className="plus-icon">+</span>
              <OutlinedLandlineIcon />
            </div>
          }
          className="choose-number-option"
          active={newPlusExistingActive}
          onClick={onClickNewPlusExisting}
        />
        <ButtonIconTextCard
          text={
            <div className="text-option">
              <p>Existing Number</p>
              <p className="option-description">
                Use already existing Organization Number
              </p>
            </div>
          }
          icon={<OutlinedLandlineIcon />}
          iconHover={<OutlinedLandlineIcon />}
          className="choose-number-option"
          active={existingNumberActive}
          onClick={onClickExistingNumber}
        />
      </div>
    </div>
  );
};

ChooseOwnNumber.propTypes = {
  ChooseOwnNumber: PropTypes.func,
};
export default ChooseOwnNumber;
