import React from "react";
import SpanWithTooltip from "../../../SpanWithTooltip";
import PropTypes from "prop-types";
import { ReactComponent as CircledQuestionMarkGrey } from "../../../../assets/img/icons-new/people-tab/number-status/number-status-mid-gray.svg";
import { getNumberTypesWithIcons } from "../NumberRhsSettings/NumberTypesAndSources";

const secondaryNumberTooltip = (
  <span>
    Secondary Numbers are useful when sending texts to a large number of people.
    Learn More about how Secondary Numbers work (Link for learn more:{" "}
    <a
      className="cursor-pointer"
      style={{ pointerEvents: "auto" }}
      href="https://help.pastorsline.com/en/articles/1419360-using-secondary-numbers"
    >
      click here
    </a>
  </span>
);

const NumberDesignationIcons = (props) => {
  const { number, isChild, isSenderNumSameAsAssigned } = props;

  const numberTypeIcons = getNumberTypesWithIcons(number, true);

  return (
    <>
      {number.assigned && <div className="assigned-number-text">Assigned</div>}
      {isSenderNumSameAsAssigned && (
        <div className="sending-as-text">Sending as</div>
      )}
      {isChild ? (
        <SpanWithTooltip
          title={secondaryNumberTooltip}
          leaveDelay={3000}
          style={{ pointerEvents: "auto" }}
          className="d-block"
        >
          Secondary
          <CircledQuestionMarkGrey width={20} height={20} className="ml-2" />
        </SpanWithTooltip>
      ) : undefined}
      {numberTypeIcons.map((type, index) => (
        <span className="pr-2" key={index}>
          {type}
        </span>
      ))}
    </>
  );
};
NumberDesignationIcons.propTypes = {
  number: PropTypes.object.isRequired,
  isChild: PropTypes.bool.isRequired,
  isSenderNumSameAsAssigned: PropTypes.bool,
};

NumberDesignationIcons.defaultProps = {
  isSenderNumSameAsAssigned: false,
};

export default NumberDesignationIcons;
