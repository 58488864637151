import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

//new icons
import { ReactComponent as Check } from "../assets/img/icons-new/general/check/check-white.svg";

ThreadCheckbox.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  selectMenuActive: PropTypes.bool,
};

ThreadCheckbox.defaultProps = {
  className: "",
  active: false,
  selectMenuActive: false,
};

export default function ThreadCheckbox({
  active,
  onClick,
  className,
  withWhiteCheckbox,
  selectMenuActive,
}) {
  return (
    <div
      className={clsx("thread-checkbox", className, {
        "thread-item-white-checkbox": withWhiteCheckbox,
        "thread-checkbox-active": selectMenuActive,
      })}
      onClick={onClick}
    >
      {active ? (
        <span
          className={clsx("thread-checkbox-check", {
            "thread-item-white-checkbox": withWhiteCheckbox,
          })}
        >
          <Check width="17" height="17" />
        </span>
      ) : (
        <span
          className={clsx("thread-checkbox-uncheck", {
            "thread-item-white-checkbox": withWhiteCheckbox,
          })}
        ></span>
      )}
    </div>
  );
}
