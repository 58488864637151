import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as mediaActions from "../../../actions/media";

export function useMediaStore() {
  const dispatch = useDispatch();
  const _media = useSelector((store) => store.media);

  const fetchMediaResources = useCallback(
    async (params) => await dispatch(mediaActions.fetchMediaResources(params)),
    [dispatch]
  );

  const fetchVideoThumbnail = useCallback(
    async (params) => await dispatch(mediaActions.fetchVideoThumbnail(params)),
    [dispatch]
  );

  const clearFetchMediaResourceStatus = useCallback(
    async () => await dispatch({ type: "MEDIA/FETCH_MEDIA_RESOURCES.CLEAR" }),
    [dispatch]
  );

  const clearDeletehMediaResourcesStatus = useCallback(
    async () => await dispatch({ type: "MEDIA/DELETE_MEDIA_RESOURCES.CLEAR" }),
    [dispatch]
  );

  const clearFetchVideoThumbnailStatus = useCallback(
    async () => await dispatch({ type: "MEDIA/FETCH_VIDEO_THUMBNAIL.CLEAR" }),
    [dispatch]
  );

  const clearAddMediaResourceStatus = useCallback(
    async () => await dispatch({ type: "MEDIA/ADD_MEDIA_RESOURCES.CLEAR" }),
    [dispatch]
  );

  const addMediaResouce = useCallback(
    async (data) => await dispatch(mediaActions.addMediaResouce(data)),
    [dispatch]
  );

  const editMediaResource = useCallback(
    async (data, handler) =>
      await dispatch(mediaActions.editMediaResource(data, handler)),
    [dispatch]
  );

  const deleteMediaResources = useCallback(
    async (data, mediaType) =>
      await dispatch(mediaActions.deleteMediaResources(data, mediaType)),
    [dispatch]
  );

  const openAddVideoModal = useCallback(
    (data) => dispatch(mediaActions.openAddVideoModal(data)),
    [dispatch]
  );

  return {
    media: _media,
    fetchMediaResources,
    addMediaResouce,
    clearFetchMediaResourceStatus,
    clearDeletehMediaResourcesStatus,
    clearFetchVideoThumbnailStatus,
    deleteMediaResources,
    fetchVideoThumbnail,
    editMediaResource,
    openAddVideoModal,
    clearAddMediaResourceStatus,
  };
}

export default useMediaStore;
