import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import { contactName } from "../../../helpers";
import TooltipWhenOverflow from "../HelperComponents/TooltipWhenOverflow";

const ThreadItemDuplicateContactsProfile = (props) => {
  const {
    className,
    duplicateContacts,
    textClassName,
    duplicateProfileCountClassName,
    removeCount,
  } = props;

  const primaryContact = useMemo(() => {
    if (isNotAnEmptyArray(duplicateContacts)) {
      return duplicateContacts[0];
    }
    return null;
  }, [duplicateContacts]);

  const phoneNumber = useMemo(
    () =>
      `${primaryContact?.country?.phonecode || ""}${
        primaryContact?.phone_number
      }`,
    [primaryContact]
  );

  const renderDuplicateContactsCount = useMemo(() => {
    if (isNotAnEmptyArray(duplicateContacts)) {
      if (duplicateContacts.length > 1) {
        return (
          <span
            className={clsx(
              "duplicate-profile-count ml-1",
              duplicateProfileCountClassName
            )}
          >
            +{duplicateContacts.length - removeCount} Profiles
          </span>
        );
      }
    } else {
      <span
        className={clsx(
          "duplicate-profile-count ml-1",
          duplicateProfileCountClassName
        )}
      >
        0 Profile
      </span>;
    }
    return null;
  }, [duplicateContacts, duplicateProfileCountClassName, removeCount]);

  return (
    <div className={className}>
      <p
        className={clsx(
          "message-text d-flex align-items-center justify-content-start",
          textClassName
        )}
      >
        <TooltipWhenOverflow
          text={contactName(
            primaryContact?.first_name,
            primaryContact?.last_name,
            phoneNumber
          )}
          maxWidth={170}
        >
          {contactName(
            primaryContact?.first_name,
            primaryContact?.last_name,
            phoneNumber
          )}
        </TooltipWhenOverflow>
        {renderDuplicateContactsCount}
      </p>
    </div>
  );
};

ThreadItemDuplicateContactsProfile.propTypes = {
  className: PropTypes.string,
  duplicateContacts: PropTypes.array,
  textClassName: PropTypes.string,
  duplicateProfileCountClassName: PropTypes.string,
  removeCount: PropTypes.number,
};

ThreadItemDuplicateContactsProfile.defaultProps = {
  className: "message-info",
  duplicateContacts: [],
  textClassName: "",
  duplicateProfileCountClassName: "duplicate-profile-count",
  removeCount: 1,
};

export default ThreadItemDuplicateContactsProfile;
