import PropTypes from "prop-types";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import { useUnpinnedMenusAndFilters } from "../../../utils/hooks/useUnpinnedMenusAndFilters";

const RenderTopPinnedAndUnPinnedMenus = (props) => {
  const { renderPinnedMenus, renderUnPinnedMenus, menuKey, children } = props;

  const { sidebarTopUnpinnedMenus } = useUnpinnedMenusAndFilters();

  const breakpoint = useBreakpoint();

  if (isMdOrBelowBreakpoint(breakpoint)) return children || null;

  if (
    renderPinnedMenus &&
    !sidebarTopUnpinnedMenus?.includes(menuKey) &&
    children
  ) {
    return children;
  }

  if (
    renderUnPinnedMenus &&
    sidebarTopUnpinnedMenus?.includes(menuKey) &&
    children
  ) {
    return children;
  }

  return null;
};

RenderTopPinnedAndUnPinnedMenus.propTypes = {
  renderPinnedMenus: PropTypes.bool,
  renderUnPinnedMenus: PropTypes.bool,
  menuKey: PropTypes.string,
};

RenderTopPinnedAndUnPinnedMenus.defaultProps = {
  renderPinnedMenus: false,
  renderUnPinnedMenus: false,
  menuKey: "",
};

export default RenderTopPinnedAndUnPinnedMenus;
