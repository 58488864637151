import logger from "../logger";
import { RETRY_RELOAD_CHUNK_ATTEMPTS } from "./constants";
import { mapStackTrace } from "sourcemapped-stacktrace";

export const getDefaultErrorLogObj = (error, mappedStack, additional) => {
  return {
    error: error.message,
    stack: mappedStack,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    url: window.location.href,
    windowSize: `${
      window.innerWidth
        ? window.innerWidth
        : document.body.offsetWidth
        ? document.body.offsetWidth
        : 0
    }x${
      window.innerHeight
        ? window.innerHeight
        : document.body.offsetHeight
        ? document.body.offsetHeight
        : 0
    }`,
    appName: process.env.REACT_APP_LOGGLY_APP_NAME,
    severity: "error",
    ...additional,
  };
};

export default function componentLoader(
  lazyComponent,
  attemptsLeft = RETRY_RELOAD_CHUNK_ATTEMPTS
) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            mapStackTrace(error.stack, (mappedStack) => {
              logger.push(
                getDefaultErrorLogObj(error, mappedStack, {
                  info: "Chunk loading error, lazy import",
                })
              );
            });
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
}
