import { ReactComponent as DashboardIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/dashboard.svg";
import { ReactComponent as BroadcastIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/broadcast.svg";
import { ReactComponent as MessagesIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/messages.svg";
import { ReactComponent as AllTextIcon } from "../../../../assets/img/icons-new/all-text/all-text-small.svg";
import { ReactComponent as VoiceIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/voice.svg";
import { ReactComponent as PeopleIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/people.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/group.svg";
import { ReactComponent as CampaignIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/campaign.svg";
import { ReactComponent as AnalyticsIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/analytics.svg";
import { ReactComponent as TagsIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/tags.svg";
import { ReactComponent as WebWidgetsIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/web-widgets.svg";
import { ReactComponent as ShortCodeIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/shortcode.svg";
import { ReactComponent as TemplatesIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/template.svg";
import { ReactComponent as QrCodeIcon } from "../../../../assets/img/icons-new/customize-menus-icons/top-menus/qr-code.svg";

// Bottom Menus Icons
import { ReactComponent as SettingsIcon } from "../../../../assets/img/icons-new/customize-menus-icons/bottom-menus/settings.svg";
import { ReactComponent as IntegrationsIcon } from "../../../../assets/img/icons-new/customize-menus-icons/bottom-menus/integrations.svg";
import { ReactComponent as GiftsIcon } from "../../../../assets/img/icons-new/customize-menus-icons/bottom-menus/gifts.svg";
import { ReactComponent as NotificationIcon } from "../../../../assets/img/icons-new/customize-menus-icons/bottom-menus/notifications.svg";
import { ReactComponent as EarnMoneyIcon } from "../../../../assets/img/icons-new/dollar/dollar-black-small.svg";

// Top Menus Keys
export const DASHBOARD_TOP_MENU = "dashboard";
export const BROADCAST_TOP_MENU = "broadcast";
export const MESSAGES_TOP_MENU = "messages";
export const TEXT_TOP_MENU = "texts";
export const VOICE_TOP_MENU = "voice";
export const PEOPLE_TOP_MENU = "people";
export const GROUPS_TOP_MENU = "groups";
export const CAMPAIGNS_TOP_MENU = "campaigns";
export const ANALYTICS_TOP_MENU = "analytics";
export const TAGS_TOP_MENU = "tags";
export const WEB_WIDGETS_TOP_MENU = "webWidgets";
export const SHORTCODE_KEYWORDS_TOP_MENU = "shortCodeKeywords";
export const TEMPLATES_TOP_MENU = "templates";
export const QR_CODE_TOP_MENU = "qrCode";

// Bottom Menus Keys
export const SETTINGS_BOTTOM_MENU = "settings";
export const INTEGRATIONS_BOTTOM_MENU = "integrations";
export const GIFTS_BOTTOM_MENU = "gifts";
export const NOTIFICATIONS_BOTTOM_MENU = "notifications";
export const AFFILIATE_BOTTOM_MENU = "affiliate";

export const topMenus = [
  {
    label: "Dashboard",
    icon: DashboardIcon,
    key: DASHBOARD_TOP_MENU,
    route: "/dashboard",
  },
  {
    label: "Broadcast",
    icon: BroadcastIcon,
    key: BROADCAST_TOP_MENU,
    route: "/broadcast",
  },
  {
    label: "Messages",
    icon: MessagesIcon,
    key: MESSAGES_TOP_MENU,
    route: "/hub/messages",
  },
  {
    label: "Text",
    icon: AllTextIcon,
    key: TEXT_TOP_MENU,
    route: "/hub/messages",
  },
  {
    label: "Voice",
    icon: VoiceIcon,
    key: VOICE_TOP_MENU,
    route: "/hub/calls",
  },
  {
    label: "People",
    icon: PeopleIcon,
    key: PEOPLE_TOP_MENU,
    route: "/hub/people",
  },
  {
    label: "Groups",
    icon: GroupIcon,
    key: GROUPS_TOP_MENU,
    route: "/hub/groups",
  },
  {
    label: "Campaigns",
    icon: CampaignIcon,
    key: CAMPAIGNS_TOP_MENU,
    route: "/hub/menus/filter/campaigns",
  },
  {
    label: "Analytics",
    icon: AnalyticsIcon,
    key: ANALYTICS_TOP_MENU,
    route: "/hub/menus/filter/analytics",
  },
  {
    label: "Tags",
    icon: TagsIcon,
    key: TAGS_TOP_MENU,
    route: "/hub/menus/filter/tags",
  },
  {
    label: "Web Widgets",
    icon: WebWidgetsIcon,
    key: WEB_WIDGETS_TOP_MENU,
    route: "/hub/menus/filter/web-widgets",
  },
  {
    label: "ShortCode Keywords",
    icon: ShortCodeIcon,
    key: SHORTCODE_KEYWORDS_TOP_MENU,
    route: "/hub/menus/filter/shortcode-keywords",
  },
  {
    label: "Templates",
    icon: TemplatesIcon,
    key: TEMPLATES_TOP_MENU,
    route: "/hub/menus/filter/templates/message-templates",
  },
  {
    label: "QR Code",
    icon: QrCodeIcon,
    key: QR_CODE_TOP_MENU,
    route: "/hub/menus/filter/QR-code",
  },
];

export const bottomMenus = [
  {
    label: "Settings",
    icon: SettingsIcon,
    key: SETTINGS_BOTTOM_MENU,
    route: "/hub/menus/filter/settings",
  },
  {
    label: "Integrations",
    icon: IntegrationsIcon,
    key: INTEGRATIONS_BOTTOM_MENU,
    route:
      "/hub/menus/filter/settings/admin-settings/submenu/admin-integrations",
  },
  {
    label: "Gifts",
    icon: GiftsIcon,
    key: GIFTS_BOTTOM_MENU,
    route: "#modal-gift-claim",
  },
  {
    label: "Notifications",
    icon: NotificationIcon,
    key: NOTIFICATIONS_BOTTOM_MENU,
    route:
      "/hub/menus/filter/settings/user-settings/submenu/user-notifications",
  },
  {
    label: "Earn Money",
    icon: EarnMoneyIcon,
    key: AFFILIATE_BOTTOM_MENU,
    route: "/hub/menus/filter/settings/user-settings/submenu/user-affilate",
  },
];
