import { useCallback } from "react";
import { insertTextIntoTextField } from "../messagesHelpers";

export const useInsertTextOnClick = ({ ref, setText, setWarningText }) => {
  const onClickText = useCallback(
    (text) => {
      insertTextIntoTextField({
        ref,
        text,
        setText,
        setWarningText,
        errorText:
          "A field cannot be inserted because the message would exceed the limit of 1600 characters.",
      });
    },
    [ref, setText, setWarningText]
  );

  return {
    onClickText,
  };
};
