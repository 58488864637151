import React, { useCallback, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { PickerOverlay } from "filestack-react";
import { prepareImageObject } from "../../../../helpers";
import { useNumbersStore } from "../../../../utils/hooks/store/useNumbersStore";
import useCompaniesStore from "../../../../utils/hooks/ReduxHooks/companiesStore";
import useMediaStore from "../../../../utils/hooks/ReduxHooks/mediaStore";
import { askForAndroidCameraPermission } from "../../../../utils/videoHelpers";
import ImageCustomSource from "./ImageCustomSource/ImageCustomSource";

async function createFileFromUrl(url, type) {
  const response = await fetch(url);

  const data = await response.blob();

  const metadata = {
    type: type || "image/jpg", // it doesn't matter here
  };

  return new File([data], url, metadata);
}

function getMimeType(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = function (e) {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);

      let header = "";

      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      // Check the file signature against known types
      let type = "unknown";

      switch (header) {
        case "89504e47":
          type = "image/png";
          break;
        case "47494638":
          type = "image/gif";
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          type = "image/jpeg";
          break;
        case "25504446":
          type = "application/pdf";
          break;
        default:
          type = "unknown";
          break;
      }

      resolve(type);
    };

    fileReader.onerror = reject;

    fileReader.readAsArrayBuffer(file);
  });
}

const AddImageFeature = (props) => {
  const {
    onImagesError,
    onAddImages,
    subscribersCount,
    images,
    Component,
    componentProps,
    disabledUploadImageOption,
    setIsImagesProcessing,
    className,
  } = props;

  const [openFileStackImage, setOpenFileStackImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filestackDom, setFilestackDom] = useState(null);
  const [filestackActions, setFilestackActions] = useState(null);

  // Redux store
  const { numbers } = useNumbersStore();
  const { companies } = useCompaniesStore();
  const { media, addMediaResouce, clearAddMediaResourceStatus } =
    useMediaStore();
  const { currentCompany } = companies;
  const { addMediaResourceStatus } = media;
  const { senderNumber } = numbers;

  const mmsContactLimit = currentCompany
    ? currentCompany.mms_contact_limit
    : 150;

  useEffect(() => {
    if (addMediaResourceStatus === "success") {
      clearAddMediaResourceStatus();
    }
  }, [addMediaResourceStatus, clearAddMediaResourceStatus]);

  const toggleOpenFileStackImage = useCallback(() => {
    askForAndroidCameraPermission();
    setOpenFileStackImage(!openFileStackImage);
    setLoading(true);
  }, [openFileStackImage]);

  const onCloseFileStackImage = useCallback(() => {
    setOpenFileStackImage(false);
    setLoading(false);
  }, []);

  const onFileStackFileSelected = useCallback((file) => {
    file.name =
      Math.random().toString(25).substr(2, 20) +
      "." +
      file.filename.split(".").pop();
    return file;
  }, []);

  const handleImageSourceMounted = useCallback((element, actions) => {
    setFilestackDom(element);
    setFilestackActions(actions);
  }, []);

  const validateImages = useCallback((images) => {
    if (images.length > 10) {
      return "Maximum # of images reached";
    }
    let allSize = 0;
    for (let i = 0; i < images.length; i++) {
      allSize += images[i].size;
    }

    if (allSize > 4718592) {
      return "Maximum file size of 4.5mb reached";
    }

    return "";
  }, []);

  const successUploadImage = useCallback(
    async (result) => {
      if (result.filesUploaded.length) {
        const allUploadedImages = [...images, ...result?.filesUploaded];
        if (validateImages(allUploadedImages)) {
          onImagesError(validateImages(allUploadedImages));
          setOpenFileStackImage(false);
          return;
        } else {
          setIsImagesProcessing(true);
          const newImages = [];
          const dataForMediaResource = [];
          for (let i = 0; i < result.filesUploaded.length; i += 1) {
            const file = await createFileFromUrl(
              result.filesUploaded[i].url,
              result.filesUploaded[i].mimetype
            );
            const { filename, handle, url, originalPath } =
              result.filesUploaded[i];
            if (!originalPath?.includes("https://cdn.filestackcontent.com/")) {
              dataForMediaResource.push({
                type: "image",
                handle,
                filename,
                url,
              });
            }
            const type = await getMimeType(file);
            setIsImagesProcessing(false);
            if (type === result.filesUploaded[i].mimetype) {
              newImages.push(prepareImageObject(result.filesUploaded[i]));
            } else {
              onImagesError(
                `Mismatch: Your image’s extension is (.${
                  result.filesUploaded[i].originalFile.name.split(".")[1]
                }) but its MIME type shows as (${type}). Please rectify & try again.`
              );
              return;
            }
          }
          const newStateImages = [...images, ...newImages];
          setOpenFileStackImage(false);
          onImagesError("");
          onAddImages(newStateImages);
          if (Boolean(dataForMediaResource?.length)) {
            addMediaResouce({ data: dataForMediaResource });
          }
        }
      } else {
        setOpenFileStackImage(false);
      }
    },
    [
      addMediaResouce,
      images,
      onAddImages,
      onImagesError,
      validateImages,
      setIsImagesProcessing,
    ]
  );

  const customSource = {
    label: "Image Library",
    name: "myCustomSource",
    icon: '<svg height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="18" cy="18" fill="#eee" r="18"/><path d="m9.9 18c0-1.71 1.39-3.1 3.1-3.1h4v-1.9h-4c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9h-4c-1.71 0-3.1-1.39-3.1-3.1zm4.1 1h8v-2h-8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" fill="#000" fill-opacity=".7"/></g></svg>',
    mounted: (element, actions) => handleImageSourceMounted(element, actions),
    unmounted: (element) => {},
  };

  let allSize = 0;
  for (let i = 0; i < images.length; i++) {
    allSize += images[i].size;
  }

  const isShortCode = senderNumber && senderNumber.src === 5;

  let filestackImgAvailable = true;
  let filestackImgNotAvailableMsg = "";
  if (
    subscribersCount > mmsContactLimit &&
    !isShortCode &&
    senderNumber.src !== 6
  ) {
    filestackImgAvailable = false;
    filestackImgNotAvailableMsg = `Not Available > ${mmsContactLimit} members via long code`;
  } else if (images.length >= 10) {
    filestackImgAvailable = false;
    filestackImgNotAvailableMsg = "Max 10 Files";
  } else if (allSize >= 4718592) {
    filestackImgAvailable = false;
    filestackImgNotAvailableMsg = "Max 4.5MB";
  } else if (disabledUploadImageOption) {
    filestackImgAvailable = false;
    filestackImgNotAvailableMsg =
      "Unfortunately, it is not possible to send both images and videos as attachments at the same time. Please choose one or the other.";
  }

  const fileStackImgOptions = {
    accept: ["image/jpeg", "image/png", "image/gif"],
    fromSources: ["local_file_system", customSource, "imagesearch"],
    customText: { "My Device": "Upload from Device" },
    maxSize: 4718592 - allSize,
    maxFiles: 10 - images.length,
    storeTo: {
      location: "s3",
    },
    onClose: onCloseFileStackImage,
    onFileSelected: onFileStackFileSelected,
    onOpen: () => setLoading(false),
  };

  return (
    <>
      <Component
        onClick={
          filestackImgAvailable && !disabledUploadImageOption
            ? toggleOpenFileStackImage
            : undefined
        }
        className={clsx(className, {
          "disabled-feature":
            !filestackImgAvailable || disabledUploadImageOption,
          active: openFileStackImage,
        })}
        tooltipTitle={filestackImgNotAvailableMsg}
        loading={loading}
        {...componentProps}
      />
      {openFileStackImage && (
        <PickerOverlay
          apikey={process.env.REACT_APP_FILESTACK_APIKEY}
          pickerOptions={fileStackImgOptions}
          onSuccess={successUploadImage}
          preload={true}
          componentDisplayMode={{
            type: "immediate",
          }}
        />
      )}

      {filestackDom &&
        createPortal(
          <ImageCustomSource
            actions={filestackActions}
            element={filestackDom}
          />,
          filestackDom
        )}
    </>
  );
};

AddImageFeature.propTypes = {
  onImagesError: PropTypes.func.isRequired,
  onAddImages: PropTypes.func.isRequired,
  setIsImagesProcessing: PropTypes.func.isRequired,
  subscribersCount: PropTypes.number.isRequired,
  Component: PropTypes.any,
  componentProps: PropTypes.object,
  disabledUploadImageOption: PropTypes.bool,
};

AddImageFeature.defaultProps = {
  componentProps: {},
  disabledUploadImageOption: false,
};

export default AddImageFeature;
