import { useMemo } from "react";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import {
  VISUAL_VIEWPORT_HEIGHT_FOR_RESOLUTION_SCREEN,
  isMdOrBelowBreakpoint,
} from "../../../utils/breakpoints";
import { isReactNativeApp } from "../../../helpers";

const RenderForSmallDesktopResolution = (props) => {
  const { children } = props;
  const { visualViewportHeight } = useWindowSize();
  const breakpoint = useBreakpoint();

  const isSmallResolutionOnDesktop = useMemo(
    () =>
      // If the visual viewport height is less than 470px, we show the vertical resolution screen only on desktop
      visualViewportHeight < VISUAL_VIEWPORT_HEIGHT_FOR_RESOLUTION_SCREEN &&
      !isMdOrBelowBreakpoint(breakpoint) &&
      !isReactNativeApp(),
    [breakpoint, visualViewportHeight]
  );

  if (isSmallResolutionOnDesktop) {
    return children;
  }

  return null;
};

export default RenderForSmallDesktopResolution;
