import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { extendLegacySession } from "../../actions/main";
import { getPrimaryUrl } from "../../helpers";

function mapStateToProps(store, ownProps) {
  return {
    showLegacyExtendSession: store.main.showLegacyExtendSession,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    extendLegacySession: (show) => dispatch(extendLegacySession(show)),
  };
}

const LegacyExtendSession = (props) => {
  const {
    // Redux props
    showLegacyExtendSession,
    // Redux func
    extendLegacySession,
  } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (showLegacyExtendSession) {
      setIsLoading(true);
    }
  }, [showLegacyExtendSession]);

  useEffect(() => {
    if (!isLoading) {
      extendLegacySession(false);
    }
  }, [extendLegacySession, isLoading]);

  if (!showLegacyExtendSession) return null;

  return (
    <iframe
      src={getPrimaryUrl("users/extendSession")}
      title="extend session"
      width={0}
      height={0}
      onLoad={() => setIsLoading(false)}
    ></iframe>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegacyExtendSession);
