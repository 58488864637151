import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as TrashRedSvg } from "../../../assets/img/icons-new/general/trash/trash-with-lines-red.svg";
import { useWithHover } from "../../../utils/hooks/useWithHover";
import DraggableElement from "./DraggableElement";
import Scrollbars from "react-custom-scrollbars";

const DashboardCard = (props) => {
  const {
    headerColorType,
    headerContent,
    headerContentClassName,
    headerRef,
    children,
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    onClickTrash,
    isActive,
    containerClassName,
    cardLink,
    cardLinkText,
    externalLink,
  } = props;

  const { hoverActive, onMouseLeave, onMouseEnter } = useWithHover();

  const renderHeaderContent = useMemo(() => {
    if (cardLink) {
      return (
        <div className="d-flex align-items-center">
          {headerContent}
          {hoverActive && (
            <Link className="ml-2" to={cardLink}>
              {cardLinkText}
            </Link>
          )}
        </div>
      );
    } else if (externalLink) {
      return (
        <div className="d-flex align-items-center">
          {headerContent}
          {hoverActive && (
            <a className="ml-2" href={externalLink} target="__blank">
              {cardLinkText}
            </a>
          )}
        </div>
      );
    }
    return headerContent;
  }, [cardLink, cardLinkText, externalLink, headerContent, hoverActive]);

  return (
    <div
      className={`dashboard-card-container d-flex flex-column ${
        isActive || hoverActive ? "dashboard-card-is-dragging" : ""
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        ref={headerRef}
        className={`dashboard-card-header dashboard-card-header-${headerColorType} ${headerContentClassName}`}
      >
        <DraggableElement
          className="dashboard-drag-icon"
          isDragging={isDragging}
          onBeforeDrag={onBeforeDrag}
          onMouseLeaveDrag={onMouseLeaveDrag}
        />
        {hoverActive && !isDragging && (
          <TrashRedSvg
            className="cursor-pointer dashboard-card-trash-icon"
            onClick={onClickTrash}
          />
        )}
        {renderHeaderContent}
      </div>
      <Scrollbars
        hideTracksWhenNotNeeded
        style={{ borderBottomRightRadius: 17, borderBottomLeftRadius: 17 }}
      >
        <div
          className={`dashboard-card-content flex-grow-1 d-flex ${containerClassName}`}
        >
          {children}
        </div>
      </Scrollbars>
    </div>
  );
};

DashboardCard.propTypes = {
  headerColorType: PropTypes.oneOf(["white", "gray", "purple"]),
  headerContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  headerRef: PropTypes.any,
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  headerContentClassName: PropTypes.string,
  onClickTrash: PropTypes.func,
  isActive: PropTypes.bool,
  containerClassName: PropTypes.string,
  cardLink: PropTypes.string,
  cardLinkText: PropTypes.string,
  externalLink: PropTypes.string,
};

DashboardCard.defaultProps = {
  headerColorType: "gray",
  headerContentClassName: "",
  isActive: false,
  containerClassName: "align-items-start",
  cardLink: "",
  cardLinkText: "",
  externalLink: "",
};

export default DashboardCard;
