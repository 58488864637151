import React from "react";
// import PropTypes from "prop-types";
import Spinner from "../../hub/HelperComponents/Spinner";

const LegacySpinner = (props) => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center">
        <Spinner className="mt-0" />
        <div className="menu-empty-title mt-1">Loading legacy page...</div>
      </div>
    </div>
  );
};

LegacySpinner.propTypes = {};

export default LegacySpinner;
