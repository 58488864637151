import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import DashboardCard from "./DashboardCard";
import ActiveIntegrations from "../../elements/ActiveIntegrations";
import { getPermissionParsed } from "../../../utils/settingsHelpers";
import { useWithHover } from "../../../utils/hooks/useWithHover";

const RenderListItem = ({ url, hasPermissions, text }) => {
  const { hoverActive, onMouseLeave, onMouseEnter } = useWithHover();

  if (url && hasPermissions) {
    return (
      <div
        className="d-flex align-items-center gap-3"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span className="my-organization-summary-container__link">{text}</span>
        <Link
          className={clsx({
            invisible: !hoverActive,
          })}
          to={url}
        >
          View
        </Link>
      </div>
    );
  }
  return <span>{text}</span>;
};

const MyOrganizationSummaryWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    isActive,
    index,
    userCount,
    contactsCount,
    ownerName,
    onClickTrash,
    userPermissions,
    companyName,
  } = props;

  return (
    <DashboardCard
      headerContent={"My Organization Summary"}
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
      cardLink={
        "/hub/menus/filter/settings/user-settings/submenu/user-organizations"
      }
      cardLinkText={"Manage"}
    >
      <div className="my-organization-summary-container">
        <table className="table">
          <tr>
            <td>
              <RenderListItem
                hasPermissions={true}
                text={"Organization"}
                url={
                  "/hub/menus/filter/settings/user-settings/submenu/user-organizations"
                }
              />
            </td>
            <td className="text-capitalize">{companyName}</td>
          </tr>
          <tr>
            <td>
              <RenderListItem
                hasPermissions={userPermissions?.admin_settings_integrations}
                text={"Integrations"}
                url={
                  "/hub/menus/filter/settings/admin-settings/submenu/admin-integrations/active-apps"
                }
              />
            </td>
            <td>
              <ActiveIntegrations iconWrapperClassName="mr-2" />
            </td>
          </tr>
          <tr>
            <td>
              <RenderListItem
                hasPermissions={userPermissions?.admin_settings_users}
                text={"Total Users"}
                url={
                  "/hub/menus/filter/settings/admin-settings/submenu/admin-users/active-users"
                }
              />
            </td>
            <td>{userCount}</td>
          </tr>
          <tr>
            <td>
              <RenderListItem
                hasPermissions={getPermissionParsed(
                  userPermissions?.people,
                  "list"
                )}
                text={"All People"}
                url={"/hub/people"}
              />
            </td>
            <td>{contactsCount}</td>
          </tr>
          <tr>
            <td>
              <RenderListItem
                hasPermissions={true}
                text={"Owner"}
                url={
                  "/hub/menus/filter/settings/user-settings/submenu/user-organizations"
                }
              />
            </td>
            <td>{ownerName}</td>
          </tr>
        </table>
      </div>
    </DashboardCard>
  );
};

MyOrganizationSummaryWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  userCount: PropTypes.number.isRequired,
  contactsCount: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  onClickTrash: PropTypes.func,
};

MyOrganizationSummaryWidget.defaultProps = {
  isActive: false,
};

export default MyOrganizationSummaryWidget;
