import React, { useState } from "react";
import RegisterDevicesBannerImage from "../../assets/img/register/register-banner-devices.png";
import Testimonials from "./Testimonials";
import { testimonials } from "../../utils/testimonials";

const BannerCarousel = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabChangeHandler = (index) => {
    setSelectedTabIndex(index);
  };

  return (
    <div className="banner-carousel">
      {CAROUSEL_ITEMS.map((item, index) => {
        return (
          <div
            className={`banner-carousel__image-wrapper ${
              index === selectedTabIndex ? "displayed" : ""
            }`}
          >
            {item}
          </div>
        );
      })}
      <div className="banner-carousel__buttons-wrapper">
        {CAROUSEL_ITEMS.map((_, index) => {
          return (
            <buttons
              className={`banner-carousel__button animate ${
                index === selectedTabIndex ? "clicked" : ""
              }`}
              onClick={() => {
                tabChangeHandler(index);
              }}
            ></buttons>
          );
        })}
      </div>
      <p>The Communication Tool for Pastors and Ministry Leaders!</p>
    </div>
  );
};

const CAROUSEL_ITEMS = [
  ...testimonials.map((testimonial) => (
    <Testimonials
      name={testimonial.name}
      title={testimonial.title}
      text={testimonial.text}
      imageUrl={testimonial.imageUrl}
    />
  )),
  <img src={RegisterDevicesBannerImage} alt="devices" />,
];

export default BannerCarousel;
