import React from "react";
// import PropTypes from "prop-types";
import Snackbar from "../elements/Snackbar";
import ExportAllGroupMembers from "../elements/ExportAllGroupMembers";
import { connect } from "react-redux";
import {
  archiveContacts,
  blockContacts,
  doNotCallContacts,
  markContactsAsFavorite,
  markContactsAsUnFavorite,
  removeSnackData as removeSnackDataContacts,
  resetDoNotCallContacts,
  subscribeContacts,
  unarchiveContacts,
  unblockContacts,
  unsubscribeContacts,
} from "../../actions/contacts";
import {
  archiveGroups,
  patchMembers,
  removeSnackData as removeSnackDataGroups,
  unarchiveGroups,
} from "../../actions/groups";
import {
  closeThreads,
  markAsUnreadThreads,
  removeSnackData as removeSnackDataThreads,
  reopenThreads,
} from "../../actions/threads";
import { removeInfoSnackBar } from "../../actions/main";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { getSnackbarPosition } from "../../utils/settingsHelpers";

function mapStateToProps(store, ownProps) {
  return {
    archivalSnackbarData: store.contacts.archivalSnackbarData,
    unarchivalSnackbarData: store.contacts.unarchivalSnackbarData,

    archivalGroupSnackbarData: store.groups.archivalGroupSnackbarData,
    unarchivalGroupSnackbarData: store.groups.unarchivalSnackbarData,

    deletionSnackbarData: store.groups.deletionSnackbarData,

    closeSnackbarData: store.threads.closeSnackbarData,
    reopenSnackbarData: store.threads.reopenSnackbarData,

    unsubscribeSnackbarData: store.contacts.unsubscribeSnackbarData,
    subscribeSnackbarData: store.contacts.subscribeSnackbarData,

    exportContactsSnackbarData: store.contacts.exportContactsSnackbarData,

    doNotCallContactsSnackbarData: store.contacts.doNotCallContactsSnackbarData,
    allowCallsContactsSnackbarData:
      store.contacts.allowCallsContactsSnackbarData,

    markAsFavoriteContactsSnackbarData:
      store.contacts.markAsFavoriteContactsSnackbarData,
    markAsUnFavoriteContactsSnackbarData:
      store.contacts.markAsUnFavoriteContactsSnackbarData,

    blockContactsSnackbarData: store.contacts.blockContactsSnackbarData,
    unblockContactsSnackbarData: store.contacts.unblockContactsSnackbarData,

    editGroupsTagsCampaignsSnackbarData:
      store.contacts.editGroupsTagsCampaignsSnackbarData,
    addTagSnackbarData: store.contacts.addTagSnackbarData,

    markAsReadSnackbarData: store.threads.markAsReadSnackbarData,
    markAsUnReadSnackbarData: store.threads.markAsUnReadSnackbarData,

    sendMessageSnackbarData: store.threads.sendMessageSnackbarData,
    callBroadcastSnackbarData: store.threads.callBroadcastSnackbarData,

    markAsFavoriteSnackbarData: store.groups.markAsFavoriteSnackbarData,
    markAsUnFavoriteSnackbarData: store.groups.markAsUnFavoriteSnackbarData,

    syncNowGroupsSnackbarData: store.groups.syncNowGroupsSnackbarData,
    copyToGroupSnackbarData: store.groups.copyToGroupSnackbarData,

    exportAllMembersGroupIds: store.groups.exportAllMembersGroupIds,

    infoSnackbarData: store.main.infoSnackbarData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUndo: (
      companyId,
      contactIds,
      groupIds,
      primaryAssignees,
      containDuplicates,
      workflowId,
      needRouting,
      isUndo,
      allParams
    ) =>
      dispatch(
        unarchiveContacts(
          companyId,
          contactIds,
          groupIds,
          primaryAssignees,
          containDuplicates,
          workflowId,
          needRouting,
          isUndo,
          allParams
        )
      ),
    onUndoUnarchive: (
      companyId,
      contactIds,
      groupIds,
      primaryAssignees,
      containDuplicates,
      workflowId,
      needRouting,
      isUndo,
      allParams
    ) =>
      dispatch(
        archiveContacts(
          companyId,
          contactIds,
          groupIds,
          primaryAssignees,
          containDuplicates,
          workflowId,
          needRouting,
          isUndo,
          allParams
        )
      ),
    onUndoGroup: (
      company_id,
      group_id,
      new_primary_contact_id,
      containDuplicates,
      unarchive_contacts,
      workflow_id,
      needRouting,
      contact_id,
      isUndo
    ) =>
      dispatch(
        unarchiveGroups(
          company_id,
          group_id,
          new_primary_contact_id,
          containDuplicates,
          unarchive_contacts,
          workflow_id,
          needRouting,
          contact_id,
          isUndo
        )
      ),
    onUndoUnarchiveGroup: (
      company_id,
      group_id,
      new_primary_contact_id,
      containDuplicates,
      unarchive_contacts,
      workflow_id,
      needRouting,
      contact_id,
      isUndo
    ) =>
      dispatch(
        archiveGroups(
          company_id,
          group_id,
          new_primary_contact_id,
          containDuplicates,
          unarchive_contacts,
          workflow_id,
          needRouting,
          contact_id,
          isUndo
        )
      ),
    onReopenThreads: (
      companyId,
      contactIds,
      groupIds,
      workflowId,
      needRouting
    ) =>
      dispatch(
        reopenThreads(companyId, contactIds, groupIds, workflowId, needRouting)
      ),
    onCloseThreads: (
      companyId,
      contactIds,
      groupIds,
      workflowId,
      needRouting
    ) =>
      dispatch(
        closeThreads(companyId, contactIds, groupIds, workflowId, needRouting)
      ),
    onSubscribeContacts: (companyId, contactIds, groupIds, allParams) =>
      dispatch(subscribeContacts(companyId, contactIds, groupIds, allParams)),
    onUnsubscribeContacts: (
      companyId,
      contactIds,
      groupIds,
      isUndo,
      allParams
    ) =>
      dispatch(
        unsubscribeContacts(companyId, contactIds, groupIds, isUndo, allParams)
      ),
    resetDoNotCallContacts: (companyId, contactIds, groupIds, allParams) =>
      dispatch(
        resetDoNotCallContacts(companyId, contactIds, groupIds, allParams)
      ),
    doNotCallContacts: (companyId, contactIds, groupIds, isUndo, allParams) =>
      dispatch(
        doNotCallContacts(companyId, contactIds, groupIds, isUndo, allParams)
      ),
    unblockContacts: (companyId, contactIds, groupIds, isUndo, allParams) =>
      dispatch(
        unblockContacts(companyId, contactIds, groupIds, isUndo, allParams)
      ),
    blockContacts: (companyId, contactIds, groupIds, isUndo, allParams) =>
      dispatch(
        blockContacts(companyId, contactIds, groupIds, isUndo, allParams)
      ),
    undoCopyToGroup: (
      companyId,
      toCopyGroupId,
      removeContactIds,
      clearAdhoc,
      removeGroupIds,
      allParams
    ) =>
      dispatch(
        patchMembers(
          companyId,
          toCopyGroupId,
          [],
          [],
          removeContactIds,
          clearAdhoc,
          removeGroupIds,
          allParams
        )
      ),
    markContactsAsFavorite: (
      companyId,
      contactIds,
      groupId,
      isUndo,
      allParams
    ) =>
      dispatch(
        markContactsAsFavorite(
          companyId,
          contactIds,
          groupId,
          isUndo,
          allParams
        )
      ),
    markContactsAsUnfavorite: (
      companyId,
      contactIds,
      groupId,
      isUndo,
      allParams
    ) =>
      dispatch(
        markContactsAsUnFavorite(
          companyId,
          contactIds,
          groupId,
          isUndo,
          allParams
        )
      ),
    onMarkAsUnreadThreads: (companyId, contactIds, groupIds, isUndo) =>
      dispatch(markAsUnreadThreads(companyId, contactIds, groupIds, isUndo)),
    removeSnackDataContacts: (id, entity) =>
      dispatch(removeSnackDataContacts(id, entity)),
    removeSnackDataGroups: (id, entity) =>
      dispatch(removeSnackDataGroups(id, entity)),
    removeSnackDataThreads: (id, entity) =>
      dispatch(removeSnackDataThreads(id, entity)),
    removeInfoSnackBar: (id) => dispatch(removeInfoSnackBar(id)),
  };
}

const SnackbarCollection = (props) => {
  const breakpoint = useBreakpoint();
  const renderExtraSnackbar = (extraSnackData, action, handleClose) => {
    return extraSnackData.map((snackdata, i) => {
      let undoAction = null;
      if (snackdata.undoPayload) {
        const data = snackdata.undoPayload;
        if (action?.startsWith("archive")) {
          undoAction = () =>
            props.onUndo(
              data.companyId,
              data.contactIds,
              [],
              data.primaryAssignees,
              data.containDuplicates,
              "undo",
              false
            );
        } else if (action.startsWith("unarchive")) {
          undoAction = () =>
            props.onUndoUnarchive(
              data.companyId,
              data.contactIds,
              [],
              data.primaryAssignees,
              data.containDuplicates,
              "undo",
              false
            );
        }
      }
      return (
        <Snackbar
          id={`${snackdata.id}-${i}`}
          open
          onClose={handleClose}
          message={snackdata.msg}
          handleAction={undoAction} // for all actions undo to be defined
          actionTitle={!undoAction ? "" : "Undo"}
          position={getSnackbarPosition(breakpoint)}
        />
      );
    });
  };

  const removeSnackDataByAction = (id, action) => {
    if (
      action === "archive-contact" ||
      action === "unarchive-contact" ||
      action === "unsubscribe" ||
      action === "subscribe" ||
      action === "export" ||
      action === "doNotCall" ||
      action === "allowCalls" ||
      action === "markAsFavorite" ||
      action === "markAsUnFavorite" ||
      action === "block" ||
      action === "unblock" ||
      action === "editGroupsTagsCampaigns" ||
      action === "addTag"
    ) {
      props.removeSnackDataContacts(id, action);
    } else if (
      action === "archive-group" ||
      action === "unarchive-group" ||
      action === "delete" ||
      action === "groupMarkAsFavorite" ||
      action === "groupMarkAsUnFavorite" ||
      action === "syncNowGroups" ||
      action === "copyToGroup"
    ) {
      props.removeSnackDataGroups(id, action);
    } else if (
      action === "close" ||
      action === "reopen" ||
      action === "markAsRead" ||
      action === "markAsUnRead" ||
      action === "sendMessage" ||
      action === "callBroadcast"
    ) {
      props.removeSnackDataThreads(id, action);
    } else if (action === "infoSnackbar") {
      props.removeInfoSnackBar(id);
    }
  };

  const getHandleActionFunction = (action, undoPayload, handleUndo) => {
    // for all actions undo to be defined
    return () => {
      if ((action === "close" || action === "reopen") && undoPayload) {
        if (undoPayload.group) {
          handleUndo(
            undoPayload.companyId,
            undoPayload.contactIds,
            undoPayload.groupIds,
            undoPayload.workflowId,
            undoPayload.needRouting
          );
        } else {
          handleUndo(
            undoPayload.companyId,
            undoPayload.contactIds,
            undoPayload.groupIds,
            undoPayload.workflowId,
            undoPayload.needRouting
          );
        }
      } else if (
        undoPayload &&
        (action === "block" ||
          action === "unblock" ||
          action === "markAsRead" ||
          action === "subscribe" ||
          action === "allowCalls" ||
          action === "markAsFavorite" ||
          action === "markAsUnFavorite")
      ) {
        handleUndo(
          undoPayload.companyId,
          undoPayload.contactIds,
          undoPayload.groupIds,
          true,
          undoPayload.all
        );
      } else if (action === "doNotCall" || action === "unsubscribe") {
        handleUndo(
          undoPayload.companyId,
          undoPayload.contactIds,
          undoPayload.groupIds,
          undoPayload.all
        );
      } else if (undoPayload && action === "copyToGroup") {
        handleUndo(
          undoPayload.companyId,
          undoPayload.toCopyGroupId,
          undoPayload.contactIds,
          true,
          undoPayload.groupIds,
          undoPayload.all
        );
      } else if (
        undoPayload &&
        (action === "archive-contact" || action === "unarchive-contact")
      ) {
        handleUndo(
          undoPayload.companyId,
          undoPayload.contactIds,
          undoPayload.groupIds,
          undoPayload.primaryAssignees,
          undoPayload.containDuplicates,
          undoPayload.workflowId,
          undoPayload.needRouting,
          true,
          undoPayload.all
        );
      } else if (
        undoPayload &&
        (action === "archive-group" || action === "unarchive-group")
      ) {
        handleUndo(
          undoPayload.companyId,
          undoPayload.groupIds,
          {},
          false,
          0,
          undoPayload.workflowId,
          undoPayload.needRouting,
          [],
          true
        );
      }
    };
  };

  const renderSnackbar = (data, handleUndo, action) =>
    data &&
    Object.keys(data).map((key) => {
      const { id, err, msg, undoPayload, extraSnackData } = data[key];
      const handleClose = () => {
        if (undoPayload && undoPayload.onComplete) {
          undoPayload.onComplete();
        }

        removeSnackDataByAction(id, action);
      };
      return (
        <React.Fragment key={key}>
          <Snackbar
            id={id}
            open
            onClose={handleClose}
            message={msg}
            handleAction={getHandleActionFunction(
              action,
              undoPayload,
              handleUndo
            )}
            actionTitle={err || !handleUndo ? "" : "Undo"}
            error={err}
            withViewButton={!!undoPayload?.viewButton}
            redirectViewUrl={undoPayload?.viewButton?.redirectUrl}
            position={getSnackbarPosition(breakpoint)}
          />
          {extraSnackData &&
            renderExtraSnackbar(extraSnackData, action, handleClose)}
        </React.Fragment>
      );
    });
  return (
    <>
      {renderSnackbar(
        props.archivalSnackbarData,
        props.onUndo,
        "archive-contact"
      )}
      {renderSnackbar(
        props.unarchivalSnackbarData,
        props.onUndoUnarchive,
        "unarchive-contact"
      )}
      {renderSnackbar(
        props.archivalGroupSnackbarData,
        props.onUndoGroup,
        "archive-group"
      )}
      {renderSnackbar(
        props.unarchivalGroupSnackbarData,
        props.onUndoUnarchiveGroup,
        "unarchive-group"
      )}
      {renderSnackbar(props.deletionSnackbarData, null, "delete")}
      {renderSnackbar(props.closeSnackbarData, props.onReopenThreads, "close")}
      {renderSnackbar(props.reopenSnackbarData, props.onCloseThreads, "reopen")}
      {renderSnackbar(
        props.unsubscribeSnackbarData,
        props.onSubscribeContacts,
        "unsubscribe"
      )}
      {renderSnackbar(
        props.subscribeSnackbarData,
        props.onUnsubscribeContacts,
        "subscribe"
      )}
      {renderSnackbar(props.exportContactsSnackbarData, undefined, "export")}
      {renderSnackbar(
        props.doNotCallContactsSnackbarData,
        props.resetDoNotCallContacts,
        "doNotCall"
      )}
      {renderSnackbar(
        props.allowCallsContactsSnackbarData,
        props.doNotCallContacts,
        "allowCalls"
      )}
      {renderSnackbar(
        props.markAsReadSnackbarData,
        props.onMarkAsUnreadThreads,
        "markAsRead"
      )}
      {renderSnackbar(
        props.markAsUnReadSnackbarData,
        undefined,
        "markAsUnRead"
      )}
      {renderSnackbar(
        props.markAsFavoriteContactsSnackbarData,
        props.markContactsAsUnfavorite,
        "markAsFavorite"
      )}
      {renderSnackbar(
        props.markAsFavoriteSnackbarData,
        undefined,
        "groupMarkAsFavorite"
      )}
      {renderSnackbar(
        props.markAsUnFavoriteContactsSnackbarData,
        props.markContactsAsFavorite,
        "markAsUnFavorite"
      )}
      {renderSnackbar(
        props.markAsUnFavoriteSnackbarData,
        undefined,
        "groupMarkAsUnFavorite"
      )}
      {renderSnackbar(
        props.syncNowGroupsSnackbarData,
        undefined,
        "syncNowGroups"
      )}
      {renderSnackbar(
        props.copyToGroupSnackbarData,
        props.undoCopyToGroup,
        "copyToGroup"
      )}
      {renderSnackbar(
        props.blockContactsSnackbarData,
        props.unblockContacts,
        "block"
      )}
      {renderSnackbar(
        props.unblockContactsSnackbarData,
        props.blockContacts,
        "unblock"
      )}
      {renderSnackbar(props.sendMessageSnackbarData, undefined, "sendMessage")}
      {renderSnackbar(
        props.callBroadcastSnackbarData,
        undefined,
        "callBroadcast"
      )}
      {renderSnackbar(
        props.editGroupsTagsCampaignsSnackbarData,
        undefined,
        "editGroupsTagsCampaigns"
      )}
      {renderSnackbar(
        props.editGroupsTagsCampaignsSnackbarData,
        undefined,
        "editGroupsTagsCampaigns"
      )}
      {renderSnackbar(props.infoSnackbarData, null, "infoSnackbar")}
      {props.exportAllMembersGroupIds.map((id) => (
        <ExportAllGroupMembers id={id} />
      ))}
    </>
  );
};

SnackbarCollection.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarCollection);
