import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { history } from "../../store";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { switchToV2 } from "../../utils/settingsHelpers";
import BackButton from "../BackButton/BackButton";
import RenderForCordova from "../hub/HelperComponents/RenderForCordova";

const NotSupportedResolution = (props) => {
  const { routeParams, isOnEmbededScreen } = props;

  const breakpoint = useBreakpoint();

  const onClickedBackBtn = useCallback(() => {
    const { tab, filter, submenu } = routeParams;
    if (tab === "menus") {
      if (filter && !submenu) {
        history.push("/hub/menus");
      } else if (filter && submenu) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  }, [routeParams]);

  return (
    <div className="overlay-non-desktop">
      {isMdOrBelowBreakpoint(breakpoint) && (
        <div className="resolution-back-btn-container">
          <BackButton onClick={onClickedBackBtn} />
        </div>
      )}
      <div>
        <h4 className="chat-empty-title text-center">
          Unsupported Resolution (window size)
        </h4>
        <p className="chat-empty">
          {isOnEmbededScreen && (
            <>
              At the moment, this screen only supports resolutions bigger than
              992 px.
              <br />
              <br />
            </>
          )}
          For now, please increase your window size until this message goes
          away.
          <RenderForCordova>
            <div className="mt-2">
              <button className="btn btn-primary" onClick={switchToV2}>
                Switch Back to V2
              </button>
            </div>
          </RenderForCordova>
        </p>
      </div>
    </div>
  );
};

NotSupportedResolution.propTypes = {
  routeParams: PropTypes.object,
  isOnEmbededScreen: PropTypes.bool,
};

NotSupportedResolution.defaultProps = {
  routeParams: {},
  isOnEmbededScreen: false,
};

export default NotSupportedResolution;
