import React from "react";
import { connect } from "react-redux";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { removeNotification } from "../actions/notifications";

function mapStateToProps(store) {
  return {
    notifications: store.notifications.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeNotification: (body, title) =>
      dispatch(removeNotification(body, title)),
  };
}

class Notifications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateTimer = null;

    this.update = this.update.bind(this);

    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.notifications !== prevProps.notifications) {
      this.update();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.updateTimer);
  }

  update() {
    clearTimeout(this.updateTimer);

    const { notifications, removeNotification } = this.props;
    const stateNotifications = [];
    let nextUpdate = null;
    let removeSet = false;
    const time = new Date().getTime();
    for (let i = 0; i < notifications.length; i += 1) {
      const notification = { ...notifications[i] };
      notification.isOpen = true;
      if (notification.autoHide) {
        const expire = notification.added + notification.timeout;

        if (time > expire) {
          notification.isOpen = false;
          nextUpdate = 500;
        }
        if (time > expire + 500) {
          removeSet = true;
          removeNotification(notification.body, notification.title);
        }

        if (!removeSet) {
          const checkUpdate = Math.max(
            0,
            notification.added + notification.timeout - time
          );
          nextUpdate = Math.max(
            0,
            nextUpdate ? Math.min(nextUpdate, checkUpdate) : checkUpdate
          );
          nextUpdate = Math.max(1000, nextUpdate);
        }
      }

      stateNotifications.push(notification);
    }

    this.setState(
      {
        notifications: stateNotifications,
      },
      nextUpdate === null || removeSet
        ? null
        : () => {
            this.updateTimer = setTimeout(this.update, nextUpdate);
          }
    );
  }

  render() {
    const { notifications } = this.state;

    if (!notifications || notifications.length === 0) {
      return null;
    }

    return (
      <div className="notifications">
        {notifications.map((notification) => (
          <Toast key={notification.added} isOpen={notification.isOpen}>
            {notification.title && notification.title.length > 0 && (
              <ToastHeader>
                <div dangerouslySetInnerHTML={{ __html: notification.title }} />
              </ToastHeader>
            )}
            <ToastBody>
              <div dangerouslySetInnerHTML={{ __html: notification.body }} />
            </ToastBody>
          </Toast>
        ))}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
