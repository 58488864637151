import React from "react";
import PropTypes from "prop-types";
import { filterUnicodeCharacters } from "../../../utils/threadsHelpers";

const HighlightUnicode = ({ text }) => {
  const highlightText = (text) => {
    // Use the filterUnicodeCharacters function to get an array of unicode characters
    const unicodeCharacters = filterUnicodeCharacters(text);

    const highlightedText = [];
    let remainingText = text;

    unicodeCharacters.forEach((unicodeChar, index) => {
      const parts = remainingText.split(unicodeChar);

      // Push the first part (before the unicode character)
      highlightedText.push(parts[0]);

      // Highlight the unicode character
      highlightedText.push(
        <span key={index} style={{ color: "#eb6033" }}>
          {unicodeChar}
        </span>
      );

      // Update remainingText to the text after the current unicode character
      remainingText = parts.slice(1).join(unicodeChar);
    });

    // Push any remaining part of the text that comes after the last unicode character
    highlightedText.push(remainingText);

    return highlightedText;
  };

  return <div>{highlightText(text)}</div>;
};

HighlightUnicode.propTypes = {
  text: PropTypes.string,
};

HighlightUnicode.defaultProps = {
  text: "",
};

export default HighlightUnicode;
