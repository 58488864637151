/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import main from "./main";
import users from "./users";
import settings from "./settings";
import companies from "./companies";
import countries from "./countries";
import numbers from "./numbers";
import threads from "./threads";
import messages from "./messages";
import contacts from "./contacts";
import calls from "./calls";
import twilio from "./twilio";
import groups from "./groups";
import messageTemplates from "./messageTemplates";
import audioUploads from "./audioUploads";
import audioRecordings from "./audioRecordings";
import broadcasts from "./broadcasts";
import shortLinks from "./shortLinks";
import notifications from "./notifications";
import menus from "./menus";
import menusSettings from "./menus/settings";
import userSettings from "./menus/userSettings";
import adminSettings from "./menus/adminSettings";
import utilities from "./utilities";
import greetingsAndVoicemail from "./menus/greetingsAndVoicemail";
import smartSync from "./smartSync";
import campaigns from "./campaigns";
import dashboard from "./dashboard";
import analytics from "./menus/analytics";
import version from "./version";
import filestack from "./filestack";
import media from "./media";
import affiliate from "./affiliate";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    main,
    users,
    settings,
    companies,
    countries,
    numbers,
    threads,
    messages,
    contacts,
    calls,
    twilio,
    groups,
    messageTemplates,
    audioUploads,
    audioRecordings,
    broadcasts,
    shortLinks,
    notifications,
    menus,
    menusSettings,
    userSettings,
    adminSettings,
    utilities,
    greetingsAndVoicemail,
    smartSync,
    campaigns,
    analytics,
    dashboard,
    version,
    filestack,
    media,
    affiliate,
  });
