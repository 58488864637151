import React from "react";

const HorizontalScrollbarThumb = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#808080",
        height: "6px",
        borderRadius: "3px",
        ...props,
      }}
    />
  );
};

export default HorizontalScrollbarThumb;
