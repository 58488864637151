import React from "react";
import CustomModal from "./CustomModal";
import { getPrimaryUrl } from "../../helpers";
import { connect } from "react-redux";

function mapStateToProps(store, ownProps) {
  return {
    currentCompany: store.companies.currentCompany,
  };
}

export default connect(mapStateToProps)(
  class ConfirmLinkImageWarningModal extends React.PureComponent {
    render() {
      const {
        schedule,
        handleConfirm,
        handleSwitchToShortcode,
        shortCodeAvailable,
        organizationSettingsAvailable,
        // Redux props
        currentCompany,
      } = this.props;

      const buttons = [];
      buttons.push({
        type: "confirm",
        caption: "Switch to Toll Free",
        handler: () => handleSwitchToShortcode(true),
        className: "text-nowrap",
      });
      if (shortCodeAvailable && currentCompany.country_code_setting === "US") {
        buttons.push({
          type: "confirm",
          caption: "Switch to Shortcode",
          handler: () => handleSwitchToShortcode(false),
          className: "text-nowrap",
        });
      } else if (organizationSettingsAvailable) {
        buttons.push({
          type: "confirm",
          caption: "Organization Settings",
          handler: () => {
            const win = window.open(
              getPrimaryUrl("users/org_setting"),
              "_blank"
            );
            win.focus();
          },
        });
      }
      buttons.push({
        type: "cancel",
        caption: `Continue ${schedule ? "Schedule" : "Send"}`, //(schedule ? 'Schedule' : 'Send') + ' Anyway',
        handler: handleConfirm,
        className: "text-nowrap",
      });

      return (
        <CustomModal
          {...this.props}
          title={"Link or Image detected"}
          buttons={buttons}
          footerClassName={"image-warning-footer-wrapper"}
        >
          <p
            className={"mb-3 text-left text-container"}
            style={{ fontSize: "initial" }}
          >
            The major carriers (AT&T, Verizon, T-Mobile, and Sprint) and some of
            the minor ones have aggressively increased their message filtering
            towards group SMS/Text messages sent using local numbers and
            containing ** URLs, pictures or "special spam-like words" **.
          </p>
          <p
            className={"mb-3 text-left text-container"}
            style={{ fontSize: "initial" }}
          >
            Your message shows as delivered but you or your subscribers didn't
            actually receive it.
          </p>
          <p
            className={"mb-3 text-left text-container"}
            style={{ fontSize: "initial" }}
          >
            We highly recommend using our{" "}
            {currentCompany.country_code_setting === "US"
              ? "short code, 77411, or"
              : ""}{" "}
            the verified toll free number in these cases.
          </p>
          {!shortCodeAvailable && organizationSettingsAvailable && (
            <p
              className={"mb-3 text-left text-container"}
              style={{ fontSize: "initial" }}
            >
              The short code in your organization is currently disabled. Go to
              Organization Settings page to switch it on.
            </p>
          )}
          {!shortCodeAvailable && !organizationSettingsAvailable && (
            <p
              className={"mb-3 text-left text-container"}
              style={{ fontSize: "initial" }}
            >
              The short code in your organization is currently disabled. Contact
              your admin to switch it on.
            </p>
          )}
          <p
            className={"text-left text-container"}
            style={{ fontSize: "initial" }}
          >
            {/* eslint-disable react/jsx-no-target-blank */}
            <a
              target={"_blank"}
              rel="noopener"
              href={
                "https://help.pastorsline.com/en/articles/1331403-how-does-carrier-filtering-work-plus-current-fix"
              }
            >
              LEARN MORE
            </a>
            {/* eslint-enable react/jsx-no-target-blank */}
          </p>
        </CustomModal>
      );
    }
  }
);
