import React from "react";
import { ReactComponent as SelectDropDownSVG } from "../../assets/img/icons-new/general/arrow/select-drop-down-gray.svg";

const TrailingMoreIcon = () => {
  return (
    <span className="action-button-trailing-more-icon">
      <SelectDropDownSVG />
    </span>
  );
};

export default TrailingMoreIcon;
