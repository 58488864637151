import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

const MultiSelectOverlayLoader = (props) => {
  const { children, isOnRhs } = props;

  return (
    <div
      className={`archival-header-overlay ${
        isOnRhs ? "archival-header-rhs" : ""
      }`}
    >
      <Loader />
      <span className="archival-progress-text">{children}</span>
    </div>
  );
};

MultiSelectOverlayLoader.propTypes = {
  isOnRhs: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

MultiSelectOverlayLoader.defaultProps = {
  isOnRhs: false,
};

export default MultiSelectOverlayLoader;
