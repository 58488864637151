import React from "react";
import PropTypes from "prop-types";

const NumberChip = (props) => {
  const { numberText, subtitle, selected, onClick } = props;

  return (
    <div
      className={`number-chip ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className={`number-chip-text ${selected ? "selected" : ""}`}>
        {numberText}
      </div>
      <div className={`number-chip-subtitle ${selected ? "selected" : ""}`}>
        {subtitle}
      </div>
    </div>
  );
};

NumberChip.propTypes = {
  numberText: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

NumberChip.defaultProps = {
  selected: false,
};

export default NumberChip;
