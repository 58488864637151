import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999999,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        color: "#fff",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="clear-icon spinner-border"
          role="status"
          style={{ width: "17px", height: "17px", borderWidth: "1.5px" }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
