import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as messageAction from "../../../actions/messages";

export function useMessagesStore() {
  const dispatch = useDispatch();
  const _messages = useSelector((store) => store.messages);

  const toggleFilters = useCallback(
    (bool) => dispatch({ type: "MESSAGES/TOGGLE_FILTERS", bool }),
    [dispatch]
  );

  const toggleSignatureRemoved = useCallback(
    async (bool) => dispatch({ type: "TOGGLE_SIGNATURE_REMOVED", bool }),
    [dispatch]
  );

  const toggleShowFeatures = useCallback(
    async (show) => await dispatch(messageAction.toggleShowFeatures(show)),
    [dispatch]
  );

  const setBasicInfo = useCallback(
    (number, message) => dispatch(messageAction.setBasicInfo(number, message)),
    [dispatch]
  );

  const addRecipientsForMessage = useCallback(
    (items, isOnExcludedMembersFilter) =>
      dispatch(
        messageAction.addRecipientsForMessage(items, isOnExcludedMembersFilter)
      ),
    [dispatch]
  );

  const removeRecipientForMessage = useCallback(
    (recipient) => dispatch(messageAction.removeRecipientForMessage(recipient)),
    [dispatch]
  );

  const addGroupsForMessage = useCallback(
    (items) => dispatch(messageAction.addGroupsForMessage(items)),
    [dispatch]
  );

  const removeGroupsForMessage = useCallback(
    (recipient) => dispatch(messageAction.removeGroupsForMessage(recipient)),
    [dispatch]
  );

  const setModalToAdd = useCallback(
    (setModal) => dispatch(messageAction.setModalToAdd(setModal)),
    [dispatch]
  );

  const setRecipientToBeRemoved = useCallback(
    (isRemove) => dispatch(messageAction.setRecipientToBeRemoved(isRemove)),
    [dispatch]
  );

  const setExistingGroupId = useCallback(
    (groupId) => dispatch(messageAction.setExistingGroupId(groupId)),
    [dispatch]
  );

  const sendMessage = useCallback(
    (
      companyId,
      receiverType,
      receiverId,
      senderNumber,
      sendOn,
      facebook,
      message,
      images,
      restrictedConfirmed,
      editScheduleMessageId,
      useSecondary,
      signatureId,
      signatureUserId,
      signatureUserNumber,
      linkImageWarningConfirmed,
      alreadyScheduledConfirmed,
      recurringRule
    ) =>
      dispatch(
        messageAction.sendMessage(
          companyId,
          receiverType,
          receiverId,
          senderNumber,
          sendOn,
          facebook,
          message,
          images,
          restrictedConfirmed,
          editScheduleMessageId,
          useSecondary,
          signatureId,
          signatureUserId,
          signatureUserNumber,
          linkImageWarningConfirmed,
          alreadyScheduledConfirmed,
          recurringRule
        )
      ),
    [dispatch]
  );

  const clearSendMessage = useCallback(
    () => dispatch(messageAction.clearSendMessage()),
    [dispatch]
  );

  const clearFilteredRecipients = useCallback(
    () => dispatch({ type: "MESSAGES/FILTERED_RECIPIENTS.CLEAR" }),
    [dispatch]
  );

  const clearRecipientGroupsForMessage = useCallback(
    () => dispatch(messageAction.clearRecipientGroupsForMessage()),
    [dispatch]
  );

  const setManuallyAddedContactIds = useCallback(
    (contactIds) =>
      dispatch(messageAction.setManuallyAddedContactIds(contactIds)),
    [dispatch]
  );

  const clearManuallyAddedContactIds = useCallback(
    () => dispatch(messageAction.clearManuallyAddedContactIds()),
    [dispatch]
  );

  const deleteMessage = useCallback(
    async (companyId, logId, groupSmsBlastId, scheduleMessageId) =>
      await dispatch(
        messageAction.deleteMessage(
          companyId,
          logId,
          groupSmsBlastId,
          scheduleMessageId
        )
      ),
    [dispatch]
  );

  const clearDeleteMessage = useCallback(
    () => dispatch(messageAction.clearDeleteMessage()),
    [dispatch]
  );

  const sendScheduledNow = useCallback(
    async (companyId, scheduleMessageId) =>
      await dispatch(
        messageAction.sendScheduledNow(companyId, scheduleMessageId)
      ),
    [dispatch]
  );

  const clearSendNowScheduleMessage = useCallback(
    () => dispatch(messageAction.clearSendNowScheduleMessage()),
    [dispatch]
  );

  const stopScheduledNow = useCallback(
    async (companyId, scheduleMessageId) =>
      await dispatch(
        messageAction.stopScheduledNow(companyId, scheduleMessageId)
      ),
    [dispatch]
  );

  const clearStopSendScheduleNowStatus = useCallback(
    () => dispatch(messageAction.clearStopSendScheduleNowStatus()),
    [dispatch]
  );

  const toggleSendMessageWizard = useCallback(
    (isSendMessageWizard) =>
      dispatch(messageAction.toggleSendMessageWizard(isSendMessageWizard)),
    [dispatch]
  );

  const toggleDoNotShowDupProfileUpdatedInfoModal = useCallback(
    (doNotShowModal) =>
      dispatch(
        messageAction.toggleDoNotShowDupProfileUpdatedInfoModal(doNotShowModal)
      ),
    [dispatch]
  );

  return {
    messages: _messages,
    toggleFilters,
    toggleShowFeatures,
    toggleSignatureRemoved,
    setBasicInfo,
    addRecipientsForMessage,
    removeRecipientForMessage,
    addGroupsForMessage,
    removeGroupsForMessage,
    setModalToAdd,
    setRecipientToBeRemoved,
    setExistingGroupId,
    sendMessage,
    clearSendMessage,
    clearFilteredRecipients,
    clearRecipientGroupsForMessage,
    setManuallyAddedContactIds,
    clearManuallyAddedContactIds,
    deleteMessage,
    clearDeleteMessage,
    sendScheduledNow,
    clearSendNowScheduleMessage,
    stopScheduledNow,
    clearStopSendScheduleNowStatus,
    toggleSendMessageWizard,
    toggleDoNotShowDupProfileUpdatedInfoModal,
  };
}

export default useMessagesStore;
