import PropTypes from "prop-types";

export const WEB_RECORD = "web-record";
export const MOBILE_RECORD = "mobile-record";
export const UPLOAD_MP3 = "upload-mp3";
export const TEXT_TO_VOICE = "text-to-voice";
export const LIBRARY = "library";
export const HISTORY = "history";

export const messageSourceType = PropTypes.oneOf([
  WEB_RECORD,
  MOBILE_RECORD,
  UPLOAD_MP3,
  TEXT_TO_VOICE,
  LIBRARY,
  HISTORY,
]);
