import { postRequest } from "../utils/requests";

export function deleteFilestackFiles(data) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "FILE_STACK/DELETE_FILE_STACK_FILES",
      "filestack/bulk-delete.json",
      data
    );
  };
}
