export const voiceDialpadsData = [
  {
    text: "1",
    digit: 1,
    alphabet: "",
  },
  {
    text: "2",
    digit: 2,
    alphabet: "abc",
  },
  {
    text: "3",
    digit: 3,
    alphabet: "def",
  },
  {
    text: "4",
    digit: 4,
    alphabet: "ghi",
  },
  {
    text: "5",
    digit: 5,
    alphabet: "jkl",
  },
  {
    text: "6",
    digit: 6,
    alphabet: "mno",
  },
  {
    text: "7",
    digit: 7,
    alphabet: "pqrs",
  },
  {
    text: "8",
    digit: 8,
    alphabet: "tuv",
  },
  {
    text: "9",
    digit: 9,
    alphabet: "wxyz",
  },
  {
    text: "*",
    digit: "*",
    alphabet: "",
    className: "star-digit",
  },
  {
    text: "0",
    digit: 0,
    alphabet: "+",
  },
  {
    text: "#",
    digit: "#",
    alphabet: "",
  },
];
