import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useThreadsStore } from "../../../utils/hooks/store/useThreadsStore";

const BasicMessageCreatorSwitcher = (props) => {
  const { className } = props;
  // Router
  const history = useHistory();

  // Redux store
  const { threads, markInitialLoadSuccess } = useThreadsStore();
  const { initialLoad } = threads;

  const redirectToBasicMessageCreator = () => {
    if (initialLoad) {
      markInitialLoadSuccess();
    }
    history.push("/hub/messages");
    history.push("/hub/messages#modal-new-message-receiver");
  };

  return (
    <h5
      onClick={redirectToBasicMessageCreator}
      className={clsx("link-text mt-1 mr-2 basic-message-switcher", className)}
    >
      <u>Switch to Quick Message Instead</u>
    </h5>
  );
};

BasicMessageCreatorSwitcher.propTypes = {
  className: PropTypes.string,
};

BasicMessageCreatorSwitcher.defaultProps = {
  className: "",
};

export default BasicMessageCreatorSwitcher;
