import { fetchFilteredBroadcasts } from "../../actions/broadcasts";

export const onGroupSmsSent = () => {
  const callback = (config, dispatch, data) => {
    if (window?.location?.pathname?.includes("/broadcast")) {
      dispatch(fetchFilteredBroadcasts(config.companyId, 1, "", "", []));
    }
  };

  return {
    event: "group-sms-sent",
    callback,
  };
};
