import { customSelectorCreator } from "./reselectCustom";
import { groupDuplicatesByApp } from "../helpers";

const subscribersCountSelector = (data) => data.subscribersCount;
const smsThrottlesSelector = (data) => data.smsThrottles;

export const getSmsThrottleInfoSelector = customSelectorCreator(
  subscribersCountSelector,
  smsThrottlesSelector,
  (subscribersCount, smsThrottles) => {
    let throttleWarning = false;
    let throttleWarningSecs = null;
    for (let i = 0; i < smsThrottles.length; i += 1) {
      if (
        subscribersCount >= smsThrottles[i].fromNum &&
        subscribersCount <= smsThrottles[i].toNum
      ) {
        throttleWarning = smsThrottles[i].is_show_secondary_warning === 1;
        throttleWarningSecs = smsThrottles[i].sec;
        break;
      }
    }

    return { throttleWarning, throttleWarningSecs };
  }
);

const getContactsData = (state) => {
  return state.contacts.data;
};

const getMembersIds = (state, props) => {
  if (typeof state.groups.members[props.groupId] === "undefined") {
    return [];
  }
  return state.groups.members[props.groupId];
};

const getFilteredMembersIds = (state, props) => {
  return state.groups.filteredMembers;
};

export const getMembersGroupedByAppAndNum = customSelectorCreator(
  [getMembersIds, getContactsData],
  groupDuplicatesByApp
);

export const getFilteredMembersGroupedByAppAndNum = customSelectorCreator(
  [getFilteredMembersIds, getContactsData],
  groupDuplicatesByApp
);
