import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import useWindowSize from "../../utils/hooks/useWindowSize";

const ReadMoreReadLess = (props) => {
  const {
    className,
    maxNoLines,
    lineHeight,
    text,
    children,
    buttonClassName,
    extraComponent,
    isFixedHeight,
    textClassName,
  } = props;

  const [collapsableBtn, setCollapsableBtn] = useState(false);
  const [msgExpanded, setMsgExpanded] = useState(false);
  const [isBoxTruncated, setIsBoxTruncated] = useState(false);

  const messageBoxRef = useRef();

  const size = useWindowSize();

  useEffect(() => {
    if (messageBoxRef.current && text) {
      const el = messageBoxRef.current;
      const divHeight = el.offsetHeight;
      const lines = divHeight / lineHeight;
      if (lines > maxNoLines) {
        setCollapsableBtn(true);
        setIsBoxTruncated(true);
      }
    }
  }, [lineHeight, maxNoLines, text, size]);

  const handleCollapse = useCallback(() => {
    if (!msgExpanded) {
      setMsgExpanded(true);
      setIsBoxTruncated(false);
    } else if (msgExpanded) {
      setMsgExpanded(false);
      setIsBoxTruncated(true);
    }
  }, [msgExpanded]);

  const readMoreOrLessButton = useMemo(() => {
    return (
      <button
        className={`read-more-btn pl-0 ${buttonClassName}`}
        onClick={handleCollapse}
      >
        {msgExpanded ? "Read Less" : "Read More"}
      </button>
    );
  }, [buttonClassName, handleCollapse, msgExpanded]);

  const showButtonInTextParagraph = msgExpanded && isFixedHeight;

  return (
    <div className={className}>
      <div
        className={`${isBoxTruncated ? "overflow-hidden" : ""}`}
        style={
          isBoxTruncated || isFixedHeight
            ? { height: maxNoLines * lineHeight }
            : {}
        }
      >
        <p ref={messageBoxRef} className={textClassName}>
          {children}
          {collapsableBtn && showButtonInTextParagraph && (
            <>
              <br />
              {readMoreOrLessButton}
            </>
          )}
          {!collapsableBtn ? extraComponent : null}
        </p>
      </div>
      {isBoxTruncated && <span>... </span>}
      {collapsableBtn && !showButtonInTextParagraph && readMoreOrLessButton}
      {collapsableBtn ? extraComponent : null}
    </div>
  );
};

ReadMoreReadLess.propTypes = {
  className: PropTypes.string,
  maxNoLines: PropTypes.number,
  lineHeight: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonClassName: PropTypes.string,
  extraComponent: PropTypes.node,
  isFixedHeight: PropTypes.bool,
  textClassName: PropTypes.string,
};

ReadMoreReadLess.defaultProps = {
  className: "",
  maxNoLines: 9,
  lineHeight: 19,
  buttonClassName: "",
  isFixedHeight: false,
  textClassName: "",
};

export default ReadMoreReadLess;
