import axios from "axios";
import { getLastExtension } from "./messagesHelpers";

// This is an async function that handles video transcoding using Filestack API.
export const videoTranscoding = async (
  file,
  param = "",
  withMetadata = false
) => {
  try {
    // Build the URL to fetch the transcoding status using the Filestack handle.
    // const url = `https://cdn.filestackcontent.com/video_convert/${file.handle}`;
    const url = `https://cdn.filestackcontent.com/video_convert=${param}/${file.handle}`;

    // Make a GET request to the Filestack API to fetch the transcoding status.
    const { data } = await axios.get(url);

    // Check if the transcoding is still pending.
    if (data?.status === "pending") {
      // Add a one-second delay before calling the function again.
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return videoTranscoding(file, param, withMetadata);
    } else if (data?.status === "completed") {
      // If the transcoding is completed, process the data and return the result.

      // Create an empty object to store the video transcoding details.
      const videoObj = { isVideo: true };

      // Check if there is actual data and it's of type "object."
      // If so, merge the data into the videoObj using Object.assign.
      if (data?.data && typeof data?.data === "object") {
        if (param.includes("preset")) {
          const metadata = withMetadata ? file?.metadata : data?.metadata;
          Object.assign(
            videoObj,
            { thumbnail: data?.data?.url, file: { url: file?.url } },
            metadata
          );
        } else {
          Object.assign(
            videoObj,
            {
              thumbnail: data?.data?.thumb,
              file: { ...file, url: data?.data?.url },
            },
            data?.metadata
          );
        }
      }

      return videoObj;
    }
  } catch (error) {
    // If there's an error in the process, throw a new Error with the error message.
    throw new Error(error?.message);
  }
};

export const getFileTypeFromUrl = async (url) => {
  try {
    const metadataUrl = `${url}/metadata?mimetype=true`;
    const { data } = await axios.get(metadataUrl);
    return data?.mimetype || "";
  } catch (error) {
    // If there's an error in the process, throw a new Error with the error message.
    throw new Error(error?.message);
  }
};

export const getVideoFileInfo = async (handler) => {
  try {
    const videoInfoUrl = `https://cdn.filestackcontent.com/video_info/${handler}`;
    const { data } = await axios.get(videoInfoUrl);
    return data;
  } catch (error) {
    // If there's an error in the process, throw a new Error with the error message.
    throw new Error(error?.message);
  }
};

export const getVideoHandler = (url = "") => {
  const breakdownUrl = new URL(url);
  const handler = breakdownUrl.pathname?.substring(1);
  return handler;
};

export const checkUrlContentType = (url) => {
  if (getLastExtension(url) === "vcf") return "vcard";
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve("image");
    img.onerror = () => resolve("video");
  });
};

export const checkSenderUrlContentType = async (url) => {
  if (getLastExtension(url) === "vcf") return "vcard";
  try {
    const response = await fetch(url, { method: "HEAD" });
    const contentType = response.headers.get("Content-Type");
    if (contentType.includes("image")) {
      return "image";
    } else if (contentType.includes("video")) {
      return "video";
    } else if (contentType.includes("text/vcard")) {
      return "vcard";
    } else {
      return "unknown";
    }
  } catch (error) {
    return "error";
  }
};
