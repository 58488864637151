import React, { useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ReactComponent as TrashCan } from "../../../../assets/img/icons-new/general/trash/trash-with-lines-red.svg";
import SpanWithTooltip from "../../../SpanWithTooltip";

const RecipientsRemoveButton = (props) => {
  const { onClick, isAddedByGroup, isExcludedMember } = props;

  const getDisabledBtnTooltip = useMemo(() => {
    if (isExcludedMember) {
      return "This person cannot be removed because it was excluded from the group.";
    } else if (isAddedByGroup) {
      return "This person cannot be removed because it was added as part of a group.";
    }
    return "";
  }, [isAddedByGroup, isExcludedMember]);

  return (
    <SpanWithTooltip title={getDisabledBtnTooltip}>
      <button
        className={clsx(
          "d-flex align-items-center rounded-lg px-3 chat-sidebar__remove-button",
          {
            "opacity-50 cursor-not-allowed": isAddedByGroup || isExcludedMember,
          }
        )}
        onClick={onClick}
        disabled={isAddedByGroup || isExcludedMember}
      >
        <TrashCan height={16} width={16} className="mr-1" />
        <small>Remove</small>
      </button>
    </SpanWithTooltip>
  );
};

RecipientsRemoveButton.propTypes = {
  onClick: PropTypes.func,
  isAddedByGroup: PropTypes.bool,
  isExcludedMember: PropTypes.bool,
};

RecipientsRemoveButton.defaultProps = {
  onClick: () => null,
  isAddedByGroup: false,
  isExcludedMember: false,
};

export default RecipientsRemoveButton;
