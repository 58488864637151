import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import SpanWithTooltip from "./SpanWithTooltip";
import { ReactComponent as ArchivedIcon } from "../assets/img/icons-new/number-status/archive/archive-icon-gray.svg";
import { ReactComponent as BlockedIcon } from "../assets/img/icons-new/number-status/blocked/blocked-gray.svg";
import { ReactComponent as UnsubscribedIcon } from "../assets/img/icons-new/number-status/unsubscribed/unsubscribed-gray.svg";
import { ReactComponent as OptedOutIconGray } from "../assets/img/icons-new/number-status/opted-out/opted-out-light-gray.svg";
import { ReactComponent as OptedOutIconWhite } from "../assets/img/icons-new/number-status/opted-out/opted-out-white.svg";
import { ReactComponent as DoNotCallSvg } from "../assets/img/icons-new/number-status/do-not-call/do-not-call-gray.svg";

const defaultProps = {
  className: "",
  containerClassName: "justify-content-start ml-2",
  style: {},
  baseSize: 16,
  doNotCallSize: 16,
  withoutLookup: false,
  withLabelAndOrangeStatusIcon: false,
  withArchiveIcon: false,
  isUnread: false,
};

class ContactStatusIcon extends React.PureComponent {
  render() {
    const {
      status,
      unsubByUser,
      unsubByUserId,
      doNotCall,
      doNotCallSize,
      className,
      style,
      baseSize,
      containerClassName,
      isUnread,
      withArchiveIcon,
      withLabelAndOrangeStatusIcon,
    } = this.props;
    let statusIcon;
    const OptedOutIcon = isUnread ? OptedOutIconWhite : OptedOutIconGray;

    if (withArchiveIcon) {
      statusIcon = (
        <SpanWithTooltip title="Archived">
          <span
            className={`d-flex align-align-items-start ${containerClassName}`}
            onMouseEnter={
              this.props.toggleTooltip && this.props?.toggleTooltip(true)
            }
            onMouseLeave={
              this.props.toggleTooltip && this.props?.toggleTooltip(false)
            }
          >
            <ArchivedIcon
              className={className}
              style={style}
              width={baseSize}
              height={baseSize}
            />
          </span>
        </SpanWithTooltip>
      );
    } else if (status === 1) {
      statusIcon = (
        <SpanWithTooltip title="Blocked">
          <span
            className={`d-flex align-align-items-start ${containerClassName}`}
            onMouseEnter={
              this.props.toggleTooltip && this.props?.toggleTooltip(true)
            }
            onMouseLeave={
              this.props.toggleTooltip && this.props?.toggleTooltip(false)
            }
          >
            <BlockedIcon
              className={className}
              style={style}
              width={baseSize}
              height={baseSize}
            />
          </span>
        </SpanWithTooltip>
      );
    } else if (status === 3 && (unsubByUser || unsubByUserId > 0)) {
      statusIcon = (
        <span
          className={clsx("d-flex align-items-center", containerClassName, {
            "thread-item-orange-label-icon": withLabelAndOrangeStatusIcon,
          })}
        >
          <SpanWithTooltip
            title={
              withLabelAndOrangeStatusIcon
                ? "This contact has been Unsubscribed!"
                : "Unsubscribed"
            }
          >
            <span
              onMouseEnter={
                this.props.toggleTooltip && this.props?.toggleTooltip(true)
              }
              onMouseLeave={
                this.props.toggleTooltip && this.props?.toggleTooltip(false)
              }
            >
              <UnsubscribedIcon width={baseSize} height={baseSize} />{" "}
            </span>
          </SpanWithTooltip>
          {withLabelAndOrangeStatusIcon && <span>&nbsp;UNSUBSCRIBED</span>}
        </span>
      );
    } else if (status === 3 && (!unsubByUser || unsubByUserId <= 0)) {
      statusIcon = (
        <span
          className={clsx("d-flex align-items-center", containerClassName, {
            "thread-item-orange-label-icon": withLabelAndOrangeStatusIcon,
          })}
        >
          <SpanWithTooltip
            arrow
            title={
              withLabelAndOrangeStatusIcon
                ? "This contact has been Opted Out!"
                : "Opted out"
            }
          >
            <span
              onMouseEnter={
                this.props.toggleTooltip && this.props?.toggleTooltip(true)
              }
              onMouseLeave={
                this.props.toggleTooltip && this.props?.toggleTooltip(false)
              }
            >
              <OptedOutIcon style={style} width={baseSize} height={baseSize} />{" "}
            </span>
          </SpanWithTooltip>
          {withLabelAndOrangeStatusIcon && <span>&nbsp;OPTED-OUT</span>}
        </span>
      );
    } else if (doNotCall > 0) {
      statusIcon = (
        <SpanWithTooltip title="Do Not Call">
          <span
            className={`d-flex align-align-items-start  ${containerClassName}`}
          >
            <DoNotCallSvg
              width={doNotCallSize ? doNotCallSize : baseSize}
              height={doNotCallSize ? doNotCallSize : baseSize}
            />
          </span>
        </SpanWithTooltip>
      );
    } else if (statusIcon === null && doNotCall === 0) return null;
    else return null;

    return (
      <div className="d-flex align-items-center justify-content-center">
        {statusIcon}
      </div>
    );
  }
}

ContactStatusIcon.propTypes = {
  status: PropTypes.number,
  unsubByUser: PropTypes.bool,
  unsubByUserId: PropTypes.number,
  doNotCall: PropTypes.number,
  doNotCallSize: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  baseSize: PropTypes.number,
  isUnread: PropTypes.bool,
  withArchiveIcon: PropTypes.bool,
  withLabelAndOrangeStatusIcon: PropTypes.bool,
};

ContactStatusIcon.defaultProps = defaultProps;

export default ContactStatusIcon;
