import { groupName, isEmptyObject } from "../helpers";
import { CONTACT, GROUP } from "./constants";
import { isNotAnEmptyArray } from "./settingsHelpers";

export const formatMessageTitle = () => {
  const now = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[now.getMonth()];
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const year = now.getFullYear();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const formattedTime = `${formattedHours}:${
    minutes < 10 ? "0" + minutes : minutes
  }${amOrPm}`;

  return `Message-${month}${day}-${year}-${formattedTime}`;
};

export const maxMessageLength = 1600;

export const formatSenderNumberObject = (number) => {
  return {
    ...number,
    name: number?.number_formatted,
    value: number?.number,
  };
};

export const getRecipientContactDetails = (
  contactData = [],
  threadType,
  groupData = {}
) => {
  let subscribersCount = 1;
  if (
    threadType === GROUP &&
    groupData &&
    groupData.members_msg_receivers > 0
  ) {
    subscribersCount = groupData.members_msg_receivers;
  }

  let isBlocked = false;
  let isUnsubscribed = false;
  let unsubedByUserId = null;
  let isDeleted =
    threadType === CONTACT && contactData && contactData.length > 0;

  if (threadType === CONTACT && contactData) {
    for (let i = 0; i < contactData.length; i++) {
      if (
        !contactData[i].is_contact_deleted &&
        !contactData[i].is_contact_mark_deleted
      ) {
        isDeleted = false;
      }
      if (contactData[i].status === 1) {
        isBlocked = true;
      }
      if (contactData[i].status === 3) {
        isUnsubscribed = true;
        unsubedByUserId = contactData[i].unsub_by_user_id;
      }
    }
  }
  return {
    subscribersCount,
    isDeleted,
    isBlocked,
    isUnsubscribed,
    unsubedByUserId,
  };
};

const getExcludingMemberLabel = (key, count) => {
  if (key === "members_blocked_count") return `${count} Blocked`;
  if (key === "members_unsubscribed_count") return `${count} Unsubscribed`;
  if (key === "members_optedout_count") return `${count} Opted Outs`;
  if (key === "members_failed_count") return `${count} Misformatted`;
  return "";
};

export const getExcludingMembers = (obj = {}) => {
  if (!obj || isEmptyObject(obj)) return "N/A";
  const keysToCheck = [
    "members_blocked_count",
    "members_unsubscribed_count",
    "members_optedout_count",
    "members_failed_count",
  ];
  const excludingMembers = [];
  keysToCheck.forEach((key) => {
    if (obj[key] > 0) {
      const mappLabel = getExcludingMemberLabel(key, obj[key]);
      excludingMembers.push(mappLabel);
    }
  });
  return isNotAnEmptyArray(excludingMembers)
    ? excludingMembers.join(", ")
    : "N/A";
};

export const getContactStatusNew = (contacts = []) => {
  let blockedCount = 0;
  let unsubscribedCount = 0;
  let optedoutCount = 0;
  let donotcallCount = 0;
  let duplicatesCount = 0;
  let voipCount = 0;
  let landlineCount = 0;
  let mobileCount = 0;
  let failedCount = 0;

  for (let i = 0; i < contacts.length; i++) {
    // ommit groups
    if (typeof contacts[i].group_name !== "undefined") {
      continue;
    }
    const contact = contacts[i];
    if (contact.status === 3 && contact.unsub_by_user_id > 0) {
      unsubscribedCount++;
    }
    if (contact.status === 3 && contact.unsub_by_user_id === 0) {
      optedoutCount++;
    }
    if (contact.status === 1) {
      blockedCount++;
    }
    if (contact.is_duplicate === 1) {
      duplicatesCount++;
    }
    if (contact.number_lookup) {
      if (contact.number_lookup.type === "voip") {
        voipCount++;
      } else if (contact.number_lookup?.type === "landline") {
        landlineCount++;
      } else if (contact.number_lookup?.type === "mobile") {
        mobileCount++;
      } else if (contact.number_lookup?.type === "zeroNumber") {
        donotcallCount++;
      } else if (contact.number_lookup?.type == null) {
        failedCount++;
      }
    }
  }

  return {
    blockedCount,
    unsubscribedCount,
    optedoutCount,
    donotcallCount,
    duplicatesCount,
    voipCount,
    landlineCount,
    mobileCount,
    failedCount,
  };
};

export const getGroupAndContactToBeRemoved = (
  recipient = {},
  currentRecipients = [],
  currentRecipientGroup = []
) => {
  if (
    recipient &&
    !isEmptyObject(recipient) &&
    recipient.hasOwnProperty("group_name")
  ) {
    // the following loop filter the other groups that are not the selected group
    const filteredOtherRecipientGroup = currentRecipientGroup.filter(
      (group) => group?.id !== recipient.id
    );

    // get the ids of the other groups
    const otherRecipientGroupIds = filteredOtherRecipientGroup.map(
      (group) => group?.id || ""
    );

    // filter the selected recipients that are not part of the selected group
    const selectedRecipients = currentRecipients.filter((rec) => {
      return rec.contact_groups.some(
        (group) => group?.group?.id === recipient.id
      );
    });

    // filter the recipients that are part of the other groups
    const otherGroupRecipients = currentRecipients.filter((rec) => {
      return rec.contact_groups.some((group) =>
        otherRecipientGroupIds.includes(group?.group?.id)
      );
    });

    // get the ids of the other groups
    const otherRecipientIds = otherGroupRecipients.map(
      (contact) => contact?.id || ""
    );

    // filter the selected recipients that are not part of the other groups
    const filteredOtherRecipientContacts = selectedRecipients.filter(
      (contact) => !otherRecipientIds.includes(contact.id)
    );

    return {
      contacts: filteredOtherRecipientContacts,
      group: recipient,
    };
  }
  return {
    contacts: [recipient],
    group: null,
  };
};

export const getRemoveRecipientConfirmationModalTitleAndDesc = (
  group = {},
  existingGroupId
) => {
  if (existingGroupId) {
    return {
      title: "Remove Group",
      desc: "Are you sure you want to remove this group from the broadcast? It will also remove all recipients that are part of this group.",
    };
  } else if (group && !isEmptyObject(group)) {
    return {
      title: "Remove Group",
      desc: `Are you sure you want to remove <b>(${groupName(
        group
      )})</b> added group? It will also remove recipient(s) that are part of this group.`,
    };
  }
  return {
    title: "Remove Recipient",
    desc: `Are you sure you want to remove selected recipient from the broadcast?`,
  };
};

export const removeDuplicatesFromArray = (arr = []) => {
  const uniqueArray = [...new Set(arr)];
  return uniqueArray;
};

export const isAllAvailableResultsAlreadySelected = (
  currentResults = [],
  usedContactsIds = [],
  usedGroupIds = []
) => {
  if (
    currentResults &&
    isNotAnEmptyArray(currentResults) &&
    ((usedContactsIds && isNotAnEmptyArray(usedContactsIds)) ||
      (usedGroupIds && isNotAnEmptyArray(usedGroupIds)))
  ) {
    const allResults = currentResults.map((result) => {
      if (result?.item_key?.substr(0, 6) === "group-") {
        return result?.group?.id;
      }
      return isNotAnEmptyArray(result?.contacts_ids) && result?.contacts_ids[0];
    });
    const allUsedIds = [...usedContactsIds, ...usedGroupIds];
    return allResults.every((result) => allUsedIds.includes(result));
  }
  return false;
};

export const getAddAndRemoveContactsAndGroupsIds = (
  currentContactIds = [],
  currentGroupIds = [],
  prevContactIds = [],
  prevGroupIds = []
) => {
  const removedContactIds = prevContactIds.filter(
    (id) => !currentContactIds.includes(id)
  );
  const removedGroupIds = prevGroupIds.filter(
    (id) => !currentGroupIds.includes(id)
  );
  const addedContactIds = currentContactIds.filter(
    (id) => !prevContactIds.includes(id)
  );
  const addedGroupIds = currentGroupIds.filter(
    (id) => !prevGroupIds.includes(id)
  );
  return {
    addedContactIds,
    addedGroupIds,
    removedContactIds,
    removedGroupIds,
  };
};
