import { LogglyTracker } from "loggly-jslogger";

const logger =
  process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN &&
  process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN !== "__LOGGLY_CUSTOMER_TOKEN__"
    ? new LogglyTracker()
    : [];
logger.push({
  logglyKey: process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
  tag: process.env.REACT_APP_LOGGLY_TAG,
});

export default logger;
