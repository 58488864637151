import React, { useState } from "react";
// import PropTypes from "prop-types";
import ModalWrapper from "./Helpers/ModalWrapper";
import { logout } from "../../actions/users";
import { connect } from "react-redux";

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

const SessionExpired = (props) => {
  const {
    // Redux props
    logout,
  } = props;

  const [clicked, setClicked] = useState(false);

  return (
    <ModalWrapper
      id="SessionExpired"
      show
      className="session-expired-modal"
      hasCloseBtn={false}
      closeModal={() => {}}
    >
      <div className="session-expired-container themed-modal">
        <h1>Session Expired</h1>
        <p>Please log in to PastorsLine again.</p>

        <div className="d-flex justify-content-end">
          <button
            disabled={clicked}
            className="btn btn-primary"
            onClick={() => {
              logout();
              setClicked(true);
            }}
          >
            OK
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

SessionExpired.propTypes = {};

export default connect(undefined, mapDispatchToProps)(SessionExpired);
