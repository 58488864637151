import {
  clearArchiveContactPathname,
  clearUnarchiveContactPathname,
  fetchContacts,
  fetchCounts,
  fetchFilteredContacts,
} from "../../actions/contacts";
import { addInfoSnackBar } from "../../actions/main";
import { changePeopleSortKeys } from "../../helpers";
import { store } from "../../store";
import { isNotAnEmptyArray } from "../../utils/settingsHelpers";

export const onGetNumberLookupPrice = () => {
  const callback = (config, dispatch, data) => {
    const { contacts } = store.getState();
    if (contacts?.numberLookupRequestId === data?.request_id) {
      dispatch({
        type: "CONTACTS/LOOKUP_PRICE.SUCCESS",
        payload: data,
      });
    }
  };

  return {
    event: "number-lookup-price",
    callback,
  };
};

/**
 * Function to handle the bulk contact archive finished event.
 * This function is responsible for updating the UI and fetching necessary data
 * after the bulk contact archive process is completed.
 */
export const bulkContactArchiveFinished = () => {
  // Define the callback function to be executed when the event occurs
  const callback = (config, dispatch, data) => {
    // Retrieve contacts and users data from the application state
    const { contacts, users } = store.getState();

    // Check if the archived contacts page is currently being viewed
    if (
      contacts?.archivedContactsPathname === window.location?.pathname &&
      window?.location?.pathname?.includes("/hub/people")
    ) {
      // Initialize filter variable
      let filter = null;
      // Retrieve sorting preferences from user settings
      const sortBy = changePeopleSortKeys(
        users?.settings?.people_default_order || ""
      );

      // Check if the current URL contains a filter parameter
      if (window?.location?.pathname?.includes("filter/")) {
        // Split the URL to extract the filter parameter
        const splitFilter = window?.location?.pathname?.split("filter/");
        // Check if a valid filter parameter is found
        if (isNotAnEmptyArray(splitFilter) && splitFilter.length > 1) {
          // Assign the filter parameter to the filter variable
          filter = splitFilter[1];
        }
      }

      // Check if a filter is applied
      if (filter) {
        // Fetch filtered contacts based on the provided filter
        dispatch(
          fetchFilteredContacts(
            config.companyId,
            null,
            null,
            null,
            filter,
            1,
            sortBy
          )
        );
      } else {
        // Fetch all contacts if no filter is applied
        dispatch(fetchContacts(config.companyId, 1, sortBy));
      }
      // Fetch updated counts of contacts
      dispatch(fetchCounts(config?.companyId));
    }

    // Dispatch a notification informing that the bulk archiving process is finished
    dispatch(addInfoSnackBar({ msg: `Archiving process has finished` }));
    // Clear the archived contacts pathname from the state
    dispatch(clearArchiveContactPathname());
  };

  // Return an object containing the event name and the callback function
  return {
    event: "bulk-contact-archive-finished",
    callback,
  };
};

/**
 * Function to handle the bulk contact unarchive finished event.
 * This function is responsible for updating the UI and fetching necessary data
 * after the bulk contact unarchive process is completed.
 */
export const bulkContactUnArchiveFinished = () => {
  // Define the callback function to be executed when the event occurs
  const callback = (config, dispatch, data) => {
    // Retrieve contacts and users data from the application state
    const { contacts, users } = store.getState();

    // Check if the unarchived contacts page is currently being viewed
    if (
      contacts?.unarchivedContactsPathname === window.location?.pathname &&
      window?.location?.pathname?.includes("/hub/people")
    ) {
      // Initialize filter variable
      let filter = null;
      // Retrieve sorting preferences from user settings
      const sortBy = changePeopleSortKeys(
        users?.settings?.people_default_order || ""
      );

      // Check if the current URL contains a filter parameter
      if (window?.location?.pathname?.includes("filter/")) {
        // Split the URL to extract the filter parameter
        const splitFilter = window?.location?.pathname?.split("filter/");
        // Check if a valid filter parameter is found
        if (isNotAnEmptyArray(splitFilter) && splitFilter.length > 1) {
          // Assign the filter parameter to the filter variable
          filter = splitFilter[1];
        }
      }

      // Check if a filter is applied
      if (filter) {
        // Fetch filtered contacts based on the provided filter
        dispatch(
          fetchFilteredContacts(
            config.companyId,
            null,
            null,
            null,
            filter,
            1,
            sortBy
          )
        );
      } else {
        // Fetch all contacts if no filter is applied
        dispatch(fetchContacts(config.companyId, 1, sortBy));
      }
      // Fetch updated counts of contacts
      dispatch(fetchCounts(config?.companyId));
    }

    // Dispatch a notification informing that the bulk archiving process is finished
    dispatch(addInfoSnackBar({ msg: `Un-archiving process has finished` }));
    // Clear the archived contacts pathname from the state
    dispatch(clearUnarchiveContactPathname());
  };

  // Return an object containing the event name and the callback function
  return {
    event: "bulk-contact-unarchive-finished",
    callback,
  };
};
