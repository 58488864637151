import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SendNewMessagePreviewBox from "./SendNewMessagePreviewBox";
import clsx from "clsx";
import { ReactComponent as ImageDarkerGreySvg } from "../../../assets/img/icons-new/general/image/image-darker-grey.svg";
import { ReactComponent as PlayIcon } from "../../../assets/img/icons-new/play-square/play-square-white.svg";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import VCardThumbnail from "../../hub/HubThreadMessageForm/VCardThumbnail";
import VideoThumbnail from "../../modals/Video/VideoThumbnail";

const SendNewMessagePreview = (props) => {
  const { number, message, className, images, videos, vCards } = props;

  const mediaPreview = useMemo(() => {
    if (images?.length === 1) {
      return (
        <img
          src={images[0].thumbUrl}
          alt="thumbnail-preview"
          height={96}
          width={96}
          className="mb-2 rounded-lg"
        />
      );
    } else if (images?.length >= 2) {
      return (
        <>
          {images?.length > 2 && (
            <div className="d-flex align-items-center">
              <ImageDarkerGreySvg height={15} width={15} />
              <span className="text-muted ml-1 mb-">
                {images?.length} Photos
              </span>
            </div>
          )}
          <div
            className={clsx("mb-2", {
              "send-message-preview__img-stack": images?.length > 2,
              "d-flex": images?.length === 2,
            })}
            style={{
              height: 86 + images?.length * 3,
            }}
          >
            {images.map((img, index) => (
              <img
                src={img.thumbUrl}
                alt="thumbnail-preview"
                height={images?.length > 2 ? 86 : 81}
                width={images?.length > 2 ? 86 : 81}
                className={clsx("rounded-lg", {
                  "mr-1": images?.length === 2,
                })}
                style={
                  images.length > 2
                    ? {
                        marginTop: index * 3 + "px",
                        marginLeft: index * 3 + "px",
                      }
                    : null
                }
              />
            ))}
          </div>
        </>
      );
    } else if (videos?.length > 0) {
      return (
        <div className="send-message-preview__video-thumb mb-2">
          {videos[0]?.thumbnail ? (
            <img
              src={videos[0].thumbnail}
              alt="thumbnail-preview"
              height={96}
              width={96}
              className="rounded-lg"
            />
          ) : (
            <VideoThumbnail videoUrl={videos[0] ? videos[0]?.file?.url : ""} />
          )}

          <div className="messages-card__textarea-video-overlay">
            <PlayIcon height={45} width={45} />
          </div>
        </div>
      );
    }
  }, [images, videos]);

  return (
    <div className={clsx("flexer-col send-message-preview__wrap", className)}>
      <span className="messages-card__label text-center mb-3">Preview:</span>
      <div className="send-message-preview">
        <div className="send-message-preview__number">{number}</div>
        <div className="position-relative overflow-auto w-100 h-100">
          {(isNotAnEmptyArray(images) ||
            isNotAnEmptyArray(videos) ||
            isNotAnEmptyArray(vCards)) && (
            <div className="d-flex align-items-start flex-column justify-content-center">
              {mediaPreview}{" "}
              {isNotAnEmptyArray(vCards) && (
                <div className="d-flex align-items-center justify-content-start flex-wrap">
                  {vCards.map((url, idx) => (
                    <VCardThumbnail
                      className={"border-0 h-auto w-auto ml-1 mb-1"}
                      key={idx}
                      url={url}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          {message && message !== "" && (
            <SendNewMessagePreviewBox message={message} />
          )}
        </div>
      </div>
      <span className="messages-card__label text-center mt-3">
        Preview may vary based on the receiving phone's Operating Software
      </span>
    </div>
  );
};

SendNewMessagePreview.propTypes = {
  number: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default SendNewMessagePreview;
