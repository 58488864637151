import React from "react";
import PropTypes from "prop-types";

export default function Loader(props) {
  const { className } = props;

  return (
    <div
      className={`clear-icon spinner-border text-primary ${className}`}
      role="status"
      style={{
        width: "17px",
        height: "17px",
        borderWidth: "1.5px",
        zIndex: 9999,
        opacity: 1,
      }}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
};
