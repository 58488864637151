import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as broadcastsActions from "../../../actions/broadcasts";

export function useBroadcastStore() {
  const dispatch = useDispatch();
  const _broadcasts = useSelector((store) => store.broadcasts);

  const fetchFilteredBroadcasts = useCallback(
    async (companyId, page, orderBy, searchQuery, filters) =>
      await dispatch(
        broadcastsActions.fetchFilteredBroadcasts(
          companyId,
          page,
          orderBy,
          searchQuery,
          filters
          // filtersAdv,
        )
      ),
    [dispatch]
  );

  const fetchBroadcastsCount = useCallback(
    async (id, filters) =>
      await dispatch(broadcastsActions.fetchBroadcastsCount(id, filters)),
    [dispatch]

    // const setBroadcastsAdvancedFilterMutation = useCallback(
    //   async (filter) =>
    //     await dispatch(broadcastsActions.setBroadcastsAdvancedFilter(filter)),
    //   [dispatch]
    // );
  );

  return {
    broadcasts: _broadcasts,
    fetchFilteredBroadcasts,
    fetchBroadcastsCount,
  };
}

export default useBroadcastStore;
