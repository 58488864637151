import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/requests";

// My profile
export function fetchUserProfile(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_MY_PROFILE",
      "users/profile.json",
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function postUserProfile(data, requestId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/POST_MY_PROFILE",
      "users/profile.json",
      data,
      {
        requestDispatchData: { requestId },
        successDispatchData: { requestId },
        errorDispatchData: { requestId },
      }
    );
}

export function clearPostUserProfile(requestId) {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/POST_MY_PROFILE.CLEAR",
      requestId,
    });
  };
}

export function changePassword(data) {
  return (dispatch) =>
    putRequest(
      dispatch,
      "USER_SETTINGS/CHANGE_PASSWORD",
      "users/password.json",
      data
    );
}

export function clearChangePassword() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/CHANGE_PASSWORD.CLEAR",
    });
  };
}

export function fetchProfileAvatar(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_MY_PROFILE_AVATAR",
      "users/avatar.json"
    );
}

export function updateAvatar(body) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/UPDATE_AVATAR",
      "users/avatar.json",
      body,
      {
        axiosConfig: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      }
    );
}

export function resendUpdateEmail() {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/RESEND_UPDATE_EMAIL",
      "users/email/resend.json",
      {}
    );
}

export function clearResendUpdateEmail() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/RESEND_UPDATE_EMAIL.CLEAR",
    });
  };
}

export function cancelEmailVerificationEmail() {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/CANCEL_UPDATE_EMAIL",
      "users/email/cancel.json",
      {}
    );
}

export function clearCancelEmailVerificationEmail() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/CANCEL_UPDATE_EMAIL.CLEAR",
    });
  };
}

export function updateEmail(body) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/UPDATE_EMAIL",
      "users/email.json",
      body,
      {
        successDispatchData: {
          email: body.email,
        },
      }
    );
}

export function clearUpdateEmail() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/UPDATE_EMAIL.CLEAR",
    });
  };
}

// My signatures
export function fetchNumberSignatures(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_NUMBER_SIGNATURES_LIST",
      "signatures.json",
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function clearFetchNumberSignatures() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/FETCH_NUMBER_SIGNATURES_LIST.CLEAR",
    });
  };
}

export function addNumberSignature(body) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/ADD_NUMBER_SIGNATURE",
      "signatures.json",
      body
    );
}

export function clearAddNumberSignature() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/ADD_NUMBER_SIGNATURE.CLEAR",
    });
  };
}

export function setDefaultSignature(body, successDispatchData) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "USER_SETTINGS/SET_DEFAULT_SIGNATURE",
      `signatures/set-as-default.json`,
      body,
      {
        requestDispatchData: successDispatchData,
        successDispatchData,
      }
    );
}

export function clearSetDefaultSignature() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/SET_DEFAULT_SIGNATURE.CLEAR",
    });
  };
}

export function editNumberSignature(body, signatureId, successDispatchData) {
  return (dispatch) =>
    putRequest(
      dispatch,
      "USER_SETTINGS/EDIT_NUMBER_SIGNATURE",
      `signatures/${signatureId}.json`,
      body,
      {
        successDispatchData: {
          indexes: successDispatchData,
          body,
        },
      }
    );
}

export function clearEditNumberSignature() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/EDIT_NUMBER_SIGNATURE.CLEAR",
    });
  };
}

export function deleteDefaultSignature(
  companyId,
  signatureId,
  successDispatchData
) {
  return (dispatch) =>
    deleteRequest(
      dispatch,
      "USER_SETTINGS/DELETE_NUMBER_SIGNATURE",
      `signatures/delete/${signatureId}.json`,
      {
        axiosConfig: {
          params: {
            company_id: companyId,
          },
        },
        successDispatchData,
      }
    );
}

export function clearDeleteDefaultSignature() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/DELETE_NUMBER_SIGNATURE.CLEAR",
    });
  };
}

export function startPhoneVerification() {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USER_SETTINGS/START_PHONE_VERIFICATION",
      "users/phone_verification/start.json"
    );
  };
}

export function checkPhoneVerificationCode(code) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE",
      "users/phone_verification/check.json",
      { token: code }
    );
  };
}

export function clearPhoneVerificationCode() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE.CLEAR",
    });
  };
}

export function updateNotificationsMode(data) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE",
      "users/notification_mode.json",
      data,
      {
        successDispatchData: {
          notifications_mode: data.mode,
          currentCompanyId: data.company_id,
        },
      }
    );
  };
}
export function clearUpdateNotificationsMode() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE.CLEAR",
    });
  };
}
export function fetchUserNotificationSettings(companyId) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_USER_NOTIFICATION_SETTINGS",
      "users/notification_settings.json",
      {
        axiosConfig: { params: { companyId } },
      }
    );
  };
}
export function clearFetchUserNotificationSettings() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/FETCH_USER_NOTIFICATION_SETTINGS.CLEAR",
    });
  };
}
export function updateUserNotificationSettings(
  data,
  updatedUserNotficationSettings
) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USER_SETTINGS/UPDATE_USER_NOTIFICATION_SETTINGS",
      "users/update_notification_settings.json",
      data,
      {
        successDispatchData: {
          notificationSettings: data,
          ...updatedUserNotficationSettings,
        },
      }
    );
  };
}

export function updateUserNotificationSettingsClear() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/UPDATE_USER_NOTIFICATION_SETTINGS.CLEAR",
    });
  };
}

export function fetchOtherCompanyCredits(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_OTHER_COMPANY_CREDITS",
      "users/credits.json",
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function fetchOtherCompanyNumbers(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_OTHER_COMPANY_NUMBERS",
      "numbers.json",
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function clearFetchOtherCompanyNumberStatus() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/FETCH_OTHER_COMPANY_NUMBERS.CLEAR",
    });
  };
}

export function restoreDefaultNotifications(data) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USER_SETTINGS/RESTORE_DEFAULT_NOTIFICATIONS",
      "users/reset_notification_settings.json",
      data,
      {
        successDispatchData: {
          currentCompanyId: data.company_id,
        },
      }
    );
  };
}
export function clearRestoreDefaultNotifications() {
  return (dispatch) => {
    dispatch({
      type: "USER_SETTINGS/RESTORE_DEFAULT_NOTIFICATIONS.CLEAR",
    });
  };
}
