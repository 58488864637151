import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export function fetchMessageTemplates(
  companyId,
  selectTemplateId,
  query = "",
  page = 1,
  orderBy = ""
) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGE_TEMPLATES/FETCH",
      selectTemplateId,
      query,
      page,
    });

    const url = "message-templates.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        query,
        page,
        orderBy,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "MESSAGE_TEMPLATES/FETCH.SUCCESS",
          selectTemplateId,
          query,
          page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGE_TEMPLATES/FETCH.ERROR",
          selectTemplateId,
          query,
          page,
          payload: error,
        });
      });
  };
}

export function clearMessageTemplates() {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/FETCH.CLEAR" });
  };
}

export function fetchMessageTemplate(companyId, templateId) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGE_TEMPLATES/FETCH_SINGLE",
      templateId,
    });

    const url = `message-templates/${templateId}.json?companyId=${companyId}`;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "MESSAGE_TEMPLATES/FETCH_SINGLE.SUCCESS",
          templateId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGE_TEMPLATES/FETCH_SINGLE.ERROR",
          templateId,
          payload: error,
        });
      });
  };
}

export function addMessageTemplate(companyId, templateData) {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/ADD" });

    const data = Object.assign({}, templateData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "message-templates.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "MESSAGE_TEMPLATES/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGE_TEMPLATES/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddMessageTemplate() {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/ADD.CLEAR" });
  };
}

export function editMessageTemplate(companyId, templateId, templateData) {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/EDIT", templateId });

    const data = Object.assign({}, templateData, { company_id: companyId });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() +
          `message-templates/${templateId}.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "MESSAGE_TEMPLATES/EDIT.SUCCESS",
          templateId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGE_TEMPLATES/EDIT.ERROR",
          templateId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearEditMessageTemplate() {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/EDIT.CLEAR" });
  };
}

export function deleteMessageTemplate(companyId, templateId) {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/DELETE", templateId });

    const url = `message-templates/${templateId}.json?companyId=${companyId}`;
    return axios
      .delete(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "MESSAGE_TEMPLATES/DELETE.SUCCESS",
          templateId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MESSAGE_TEMPLATES/DELETE.ERROR",
          templateId,
          error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearDeleteMessageTemplate() {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/DELETE.CLEAR" });
  };
}

export function insertMessageTemplate(insertTo, messageTemplate) {
  return function (dispatch) {
    dispatch({
      type: "MESSAGE_TEMPLATES/INSERT",
      insertTo,
      messageTemplate,
    });
  };
}

export function clearInsertMessageTemplate() {
  return function (dispatch) {
    dispatch({ type: "MESSAGE_TEMPLATES/INSERT.CLEAR" });
  };
}
