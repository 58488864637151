import React from "react";
import { connect } from "react-redux";

function mapStateToProps(store, ownProps) {
  return {
    groupMembersData: store.groups.actionGroupMembersData,
    groupData: store.groups.acionGroupData,
    subMembersData: store.groups.subMembersData,
  };
}

class SkipSendingMultipleModal extends React.Component {
  constructor(props) {
    super(props);

    this.updateView = this.updateView.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
    };
  }

  componentDidMount() {
    if (
      !this.props.subMembersData &&
      Object.keys(this.props.groupMembersData).length < 1
    ) {
      this.closeModal();
    } else this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  closeModal = (val) => () => {
    this.props.onClose(val);
  };

  render() {
    return (
      <div
        className={
          "modal fade inbox-modal confirm-group-message" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="SkipSendingMultipleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SkipSendingMultipleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-backdrop fade show"
          onClick={this.closeModal("cancel")}
        />
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          style={{ zIndex: 101 }}
        >
          <div className="modal-content themed-modal">
            <div className="modal-body">
              <>
                <div className="skip-sending-content">
                  <div>Reassign new Primary Person</div>
                </div>
                <div className="skip-sending-confirmation-multiple">
                  {this.props.unarchive
                    ? "You are about to unarchive a Person. Do you want to reassign a new Primary Person for this matter?"
                    : "You are about to delete a Primary Person. A new Primary Person must be assigned to proceed."}
                </div>
              </>
              <div
                className="multiple-contacts-action-btn"
                onClick={this.closeModal("manual")}
              >
                Let me choose
              </div>
              <div
                className="multiple-contacts-action-btn"
                onClick={this.closeModal("auto")}
              >
                Assign new Primary Person automatically
              </div>
              <div
                className="multiple-contacts-action-btn-bottom"
                onClick={this.closeModal("cancel")}
              >
                Never mind
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SkipSendingMultipleModal);
