import { TextField } from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import clsx from "clsx";
import ButtonWithSpinner from "../../components/hub/HelperComponents/ButtonWithSpinner";
import PlusMinusCheckbox from "../../components/hub/HelperComponents/PlusMinusCheckbox";
import pastorslineLogo from "../../assets/img/pastorsline-logo.png";
import { ReactComponent as ShowInfo } from "../../assets/img/icons-new/number-status/show-info/show-info-orange.svg";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import { getReferralId } from "../../helpers";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import OnboardingLayout from "../Login/OnboardingLayout";
import AxiosConfig from "../../AxiosConfig";

const Register = (props) => {
  const [scammerCheck, setScammerCheck] = useState(false);

  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    termsAgreed: false,
    scammerCheck: false,
  });

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState(null);

  const breakpoint = useBreakpoint();

  const history = useHistory();
  const { users, registerUser, clearRegisterUserStatusAndError } =
    useUsersStore();
  const { addInfoSnackBar } = useMainStore();

  useEffect(() => {
    if (AxiosConfig.getAuthToken()) {
      history.push("/dashboard");
    }
  }, [history]);

  const onSubmit = useCallback(() => {
    registerUser({
      first_name: formFields.first_name,
      last_name: formFields.last_name,
      email: formFields.email,
      referral_id: getReferralId(),
    });
  }, [formFields, registerUser]);

  const onClickYesConfirmedEmail = useCallback(() => {
    setErrorEmail(false);
    setErrorTextEmail(null);
    setScammerCheck(false);
    onSubmit();
  }, [onSubmit]);

  const toggleSnackBar = useCallback(
    (msg, error = false) => {
      const info = { msg };
      error && (info.err = true);
      addInfoSnackBar(info);
    },
    [addInfoSnackBar]
  );

  useEffect(() => {
    if (users.registerUserStatus === "success") {
      toggleSnackBar("Registration successful. Please verify your email.");
      clearRegisterUserStatusAndError();
      history.push("/verify-your-email");
    } else if (users.registerUserStatus === "error") {
      if (
        (users?.registerUserError || "").toLowerCase() ===
        "email already exists."
      ) {
        setErrorEmail(true);
        setErrorTextEmail(
          <div>Email Already in Use! Please Login or Use another Email</div>
        );
      } else if (
        (users?.registerUserError || "").toLowerCase()?.includes("email")
      ) {
        setErrorEmail(true);
        setScammerCheck(true);
        setErrorTextEmail(
          <div>
            {users?.registerUserError || "Something went wrong."} Is this your
            valid email?{" "}
            <span
              className="color-primary cursor-pointer"
              onClick={onClickYesConfirmedEmail}
            >
              Yes
            </span>
          </div>
        );
      } else {
        toggleSnackBar(
          users?.registerUserError || "Something went wrong",
          true
        );
      }
      clearRegisterUserStatusAndError();
    }
  }, [
    clearRegisterUserStatusAndError,
    history,
    onClickYesConfirmedEmail,
    toggleSnackBar,
    users,
  ]);

  const setField = useCallback(
    (field) => (event) => {
      let value = event ? event?.target?.value : null;
      if (field === "termsAgreed") value = !formFields.termsAgreed;
      if (field === "scammerCheck") value = true;
      setFormFields((prev) => ({ ...prev, [field]: value }));
    },
    [formFields]
  );

  const onEmailChange = useCallback(
    ({ target: { value } }) => {
      setFormFields((prev) => ({ ...prev, email: value }));
      if (errorEmail) {
        setErrorEmail(false);
        setErrorTextEmail(null);
        setScammerCheck(false);
      }
    },
    [errorEmail]
  );

  if (AxiosConfig.getAuthToken()) {
    return null;
  }

  return (
    <OnboardingLayout>
      <div>
        <div className="d-flex justify-content-center logo">
          <img src={pastorslineLogo} alt="pastorsline-logo" />
        </div>
        <div className="form-container">
          <h1 className="header-text">Register</h1>
          <TextField
            label="First Name"
            fullWidth
            className={clsx({
              "mb-3": isMdOrBelowBreakpoint(breakpoint),
              "mb-4": !isMdOrBelowBreakpoint(breakpoint),
            })}
            key="first-name"
            required
            value={formFields.first_name}
            onChange={setField("first_name")}
          />
          <TextField
            label="Last Name"
            fullWidth
            className={clsx({
              "mb-3": isMdOrBelowBreakpoint(breakpoint),
              "mb-4": !isMdOrBelowBreakpoint(breakpoint),
            })}
            key="last-name"
            required
            value={formFields.last_name}
            onChange={setField("last_name")}
          />
          <TextField
            label="Email"
            fullWidth
            className={isMdOrBelowBreakpoint(breakpoint) ? "mb-3" : "mb-4"}
            key="email"
            error={errorEmail}
            helperText={errorTextEmail}
            required
            value={formFields.email}
            onChange={onEmailChange}
            InputProps={{
              endAdornment: errorEmail && <ShowInfo />,
            }}
          />

          <div className="mb-4 captcha-container">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setField("scammerCheck")}
            />
          </div>
          <div className="d-flex align-items-start mb-4">
            <PlusMinusCheckbox
              color={"primary"}
              checked={formFields.termsAgreed}
              onChange={setField("termsAgreed")}
            />
            <div className="ml-2">
              <span className="agreement-text">
                By clicking this button I agree to PastorsLine.com
              </span>
              <br />
              <a
                href="https://my.pastorsline.com/users/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              {` & `}
              <a
                href="https://my.pastorsline.com/users/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <ButtonWithSpinner
            className="btn-block mb-3 form-container-button"
            disabled={
              !formFields.termsAgreed ||
              !formFields.first_name ||
              !formFields.last_name ||
              !formFields.email ||
              errorEmail ||
              scammerCheck ||
              users.registerUserStatus === "loading"
            }
            onClick={onSubmit}
            loading={users.registerUserStatus === "loading"}
          >
            Register
          </ButtonWithSpinner>
          <p className="already-text">
            Already have a PastorsLine.com account?{" "}
            <button
              className="font-weight-bold onboarding-links"
              onClick={() => history.push("/login")}
            >
              Login
            </button>
          </p>
        </div>
      </div>
    </OnboardingLayout>
  );
};

Register.propTypes = {};

export default Register;
