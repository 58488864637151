import React from "react";
import PropTypes from "prop-types";
import DashboardCard from "./DashboardCard";
import AnalyticsAndBillingLinks from "../../hub/HelperComponents/AnalyticsAndBillingLinks";

const CreditsWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    isActive,
    index,
    onClickTrash,
    userPermissions,
    credits,
  } = props;

  return (
    <DashboardCard
      headerContent={<div className="mt-1 font-weight-normal">Credits</div>}
      headerColorType="white"
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
      containerClassName="align-items-center"
    >
      <div className="d-flex align-items-center flex-wrap px-3 pb-3 pt-1 large-text text-center font-weight-normal">
        {credits}
        <AnalyticsAndBillingLinks
          className={"billing-analytic-link ml-2"}
          userPermissions={userPermissions}
        />
      </div>
    </DashboardCard>
  );
};

CreditsWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};

CreditsWidget.defaultProps = {
  isActive: false,
};

export default CreditsWidget;
