import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as shortLinkActions from "../../../actions/shortLinks";

export function useShortLinkStore() {
  const dispatch = useDispatch();
  const _shortLinks = useSelector((store) => store.shortLinks);

  const addVideoShortLink = useCallback(
    async (companyId, formData) =>
      await dispatch(shortLinkActions.addVideoShortLink(companyId, formData)),
    [dispatch]
  );

  const clearAddVideoShortLink = useCallback(
    async () => await dispatch(shortLinkActions.clearAddVideoShortLink()),
    [dispatch]
  );

  const clearInsertShortLink = useCallback(
    async () => await dispatch(shortLinkActions.clearInsertShortLink()),
    [dispatch]
  );

  return {
    shortLinks: _shortLinks,
    addVideoShortLink,
    clearAddVideoShortLink,
    clearInsertShortLink,
  };
}

export default useShortLinkStore;
