import React from "react";
import PropTypes from "prop-types";

import ContactStatusIcon from "../../ContactStatusIcon";

//new icons
import { ReactComponent as PeopleGroupSvg } from "../../../assets/img/icons-new/group-tab/group-icon/group-icon-black.svg";
import { ReactComponent as FavoriteFilledIconSvg } from "../../../assets/img/icons-new/message-tab/favorite/favorite-black.svg";
import { ReactComponent as ShowInfoBlueIcon } from "../../../assets/img/icons-new/number-status/show-info/show-info-blue.svg";

import SpanWithTooltip from "../../SpanWithTooltip";
import { connect } from "react-redux";
import { getPermissionParsed } from "../../../utils/settingsHelpers";

function mapStateToProps(store) {
  return {
    userPermissions: store.users.permissions,
  };
}

const ThreadItemStatusIcons = (props) => {
  const {
    withContactDetails,
    contacts,
    isMultipleContacts,
    handleIconTooltip,
    contactsIds,
    favorite,
    isUnread,
    group,
    withArchiveIcon,
    withLabelAndOrangeStatusIcon,

    // Redux props
    userPermissions,
  } = props;

  return (
    <>
      {
        // Is favorite
        favorite && (
          <SpanWithTooltip
            placement="top"
            title="Favorite"
            onMouseEnter={handleIconTooltip(true)}
            onMouseLeave={handleIconTooltip(false)}
            style={{ alignItems: "center" }}
          >
            <FavoriteFilledIconSvg
              className={"icon-grey-dark"}
              width={16}
              height={16}
            />
          </SpanWithTooltip>
        )
      }
      {group?.sync_hold_contacts_amount > 0 &&
        getPermissionParsed(userPermissions.smart_syncing, "view") && (
          <SpanWithTooltip title="Has members with conflicts" placement="top">
            <ShowInfoBlueIcon width={16} height={16} />
          </SpanWithTooltip>
        )}

      {/* if contacts ids are found
          and it has duplicate contacts
      */}
      {contactsIds &&
        contactsIds.length > 1 &&
        ((!isMultipleContacts && !withContactDetails) ||
          (isMultipleContacts &&
            (contacts[0]?.has_duplicate === 1 ||
              contacts[0]?.is_duplicate === 1))) && (
          <SpanWithTooltip
            placement="top"
            title="Primary Profile"
            onMouseEnter={handleIconTooltip(true)}
            onMouseLeave={handleIconTooltip(false)}
          >
            <PeopleGroupSvg
              className={"icon-grey-dark"}
              width={16}
              height={16}
            />
          </SpanWithTooltip>
        )}

      {/* contact status icons in title like, blocked, unsubscribed etc */}
      {contacts[0] && (
        <>
          <ContactStatusIcon
            status={contacts[0].status}
            unsubByUser={contacts[0].unsub_by_user}
            unsubByUserId={contacts[0].unsub_by_user_id}
            doNotCall={contacts[0].do_not_call}
            doNotCallSize={16}
            className={"icon-grey-dark"}
            width={16}
            toggleTooltip={(tooltip) => handleIconTooltip(tooltip)}
            isUnread={isUnread}
            withArchiveIcon={withArchiveIcon && contacts[0]?.is_archived === 1}
            withLabelAndOrangeStatusIcon={withLabelAndOrangeStatusIcon}
          />
        </>
      )}
    </>
  );
};

ThreadItemStatusIcons.prototype = {
  withContactDetails: PropTypes.bool,
  contacts: PropTypes.array,
  isMultipleContacts: PropTypes.bool,
  handleIconTooltip: PropTypes.func,
  contactsIds: PropTypes.array,
  favorite: PropTypes.bool,
  isUnread: PropTypes.bool,
  withArchiveIcon: PropTypes.bool,
  withLabelAndOrangeStatusIcon: PropTypes.bool,
};

ThreadItemStatusIcons.defaultProps = {
  withArchiveIcon: false,
  withLabelAndOrangeStatusIcon: false,
};

export default connect(mapStateToProps)(ThreadItemStatusIcons);
