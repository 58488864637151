import React from "react";
import PropTypes from "prop-types";
import RockIntegration from "../../../../assets/img/integrations/rock/rockrms.png";
import useCompaniesStore from "../../../../utils/hooks/ReduxHooks/companiesStore";
import { ROCK } from "../../../../utils/constants";

const IntegrationIcon = (props) => {
  const { size, type, className } = props;
  // REdux store
  const { companies } = useCompaniesStore();
  const { currentCompany } = companies;

  if (type === "planning-center" && currentCompany?.pco_status) {
    return (
      <div style={{ width: size, height: size }} className={className}>
        <img
          alt="planning-center"
          src="/static/img/planning-center/planning-center.png"
          className="mw-100 mh-100"
        />
      </div>
    );
  }

  if (type === "elvanto" && currentCompany?.elvanto_status) {
    return (
      <div style={{ width: size, height: size }} className={className}>
        <img
          alt="elvanto"
          src="/static/img/elvanto/elvanto.png"
          className="mw-100 mh-100"
        />
      </div>
    );
  }

  if (type === "mailchimp" && currentCompany?.mc_status) {
    return (
      <div style={{ width: size, height: size }} className={className}>
        <img
          alt="mailchimp"
          src="/static/img/mailchimp/mailchimp.png"
          className="mw-100 mh-100"
        />
      </div>
    );
  }

  if (type === "breeze" && currentCompany?.breeze_status) {
    return (
      <div style={{ width: size, height: size }} className={className}>
        <img
          alt="breeze"
          src="/static/img/breeze/breeze.png"
          className="mw-100 mh-100"
        />
      </div>
    );
  }

  if (type === "ccb" && currentCompany?.is_ccb_status) {
    return (
      <div style={{ width: size, height: size }} className={className}>
        <img
          alt="ccb"
          src="/static/img/ccb/ccb.png"
          className="mw-100 mh-100"
        />
      </div>
    );
  }

  if (type?.startsWith(ROCK) && currentCompany?.is_rock_enabled) {
    return (
      <div style={{ width: size, height: size }} className={className}>
        <img alt={ROCK} src={RockIntegration} className="mw-100 mh-100" />
      </div>
    );
  }

  return null;
};

IntegrationIcon.propTypes = {
  size: PropTypes.number,
  type: PropTypes.oneOf([
    "mailchimp",
    "breeze",
    "ccb",
    "elvanto",
    "planning-center",
  ]).isRequired,
};

IntegrationIcon.defaultProps = {
  size: 48,
};

export default IntegrationIcon;
