import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ReactComponent as CounterArrowUp } from "../../../assets/img/icons-new/counter-arrow/counter-arrow-up-black.svg";
import { ReactComponent as CounterArrowDown } from "../../../assets/img/icons-new/counter-arrow/counter-arrow-down-black.svg";

const CounterUpAndDown = (props) => {
  const { initialCount, minCount, maxCount, field, onClickCallBack } = props;

  const count = useMemo(
    () =>
      typeof initialCount === "string" ? parseInt(initialCount) : initialCount,
    [initialCount]
  );

  // Function to handle increment
  const handleClickUp = () => {
    if (count < maxCount) {
      onClickCallBack(count + 1, field);
    }
  };

  // Function to handle decrement
  const handleClickDown = () => {
    if (count > minCount) {
      onClickCallBack(count - 1, field);
    }
  };

  return (
    <div className="counter-up-down-container mb-1">
      <span
        className={clsx("mr-1 arrow-container", {
          disabled: count === maxCount,
        })}
        onClick={handleClickUp}
      >
        <CounterArrowUp />
      </span>
      <span
        className={clsx("mr-1 arrow-container", {
          disabled: count === minCount,
        })}
        onClick={handleClickDown}
      >
        <CounterArrowDown />
      </span>
    </div>
  );
};

CounterUpAndDown.propTypes = {
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
};

CounterUpAndDown.propTypes = {
  onClickUp: () => {},
  onClickDown: () => {},
};

export default CounterUpAndDown;
