import { ReactComponent as UserSettings } from "../../../assets/img/icons-new/sidebar-dropright/settings-tab/user-settings-dark-black.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/img/icons-new/general/phone/phone-black.svg";
import { ReactComponent as SettingIcon } from "../../../assets/img/icons/menuIcons/settings-icon.svg";

export const droprightSettingsMenuList = [
  {
    title: "Users",
    icon: UserSettings,
    isAdminSettings: true,
    url: "/admin-settings/submenu/admin-users/active-users",
  },
  {
    title: "Numbers (Admin)",
    icon: PhoneIcon,
    isAdminSettings: true,
    url: "/admin-settings/submenu/admin-numbers",
  },
  {
    title: "Settings",
    icon: SettingIcon,
    isAdminSettings: false,
    url: null,
  },
];
