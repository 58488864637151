import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/users";

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

const IframeLegacySessionExpiredModal = (props) => {
  const {
    // Redux function
    logout,
  } = props;
  const [clicked, setClicked] = useState(false);

  const onClickedOk = useCallback(() => {
    logout();
    setClicked(true);
  }, [logout]);

  return (
    <div className="legacy-redirect-modal-container">
      <div className="legacy-reidrect-wrapper themed-modal">
        <h1>Session Expired</h1>
        <p>
          You are logged out from Legacy. Please log in to PastorsLine again.
        </p>
        <div className="d-flex justify-content-end">
          <button
            disabled={clicked}
            className="btn btn-primary"
            onClick={onClickedOk}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(IframeLegacySessionExpiredModal);
