export const getIsUnverifiedTollFree = (number) => {
  if (!number) return false;
  const { is_toll_free, is_toll_free_registered } = number;
  return is_toll_free && !is_toll_free_registered;
};

/**
 * Checks if a sender number is a toll-free or short code number.
 * @param {Object} senderNumber - The sender number object with a 'type' property.
 * @returns {boolean} - True if the sender number is toll-free or short code, otherwise false.
 */
export const isTollFreeOrShortCodeNumberSelectedAsSenderNumber = (
  senderNumber
) => {
  // Check if senderNumber is falsy (null, undefined, empty string, etc.) and return false
  if (!senderNumber) return false;

  // Define an array of valid sender number types
  const validSenderTypes = ["toll-free", "shortcode", "backup-toll-free"];

  // Check if senderNumber type is in the validSenderTypes array and return the result
  return validSenderTypes.includes(senderNumber?.type);
};

export const isOnlyShortCodeOrTollFreeNumActiveWithAssignedNum = (
  numbersExtended = [],
  currentNumData = {}
) => {
  if (
    numbersExtended &&
    Array.isArray(numbersExtended) &&
    Boolean(numbersExtended.length)
  ) {
    const filteredNumbers = numbersExtended.filter(
      (number) => number.number !== currentNumData.number
    );
    if (filteredNumbers?.length === 0) return false;
    return filteredNumbers?.every(
      (number) =>
        number.status === "active" &&
        ["shortcode", "toll-free", "backup-toll-free"].includes(number.type)
    );
  }
  return false;
};

export const getCountryIdByCountryIsoCode = (countries = [], isoCode = "") => {
  if (!isoCode || !countries || !Array.isArray(countries)) return null;
  const country = countries.find(
    (country) => country.iso?.toLowerCase() === isoCode?.toLowerCase()
  );
  return country?.id || null;
};

export const getCountryPhoneCodeByCountryId = (countries = [], id = "") => {
  if (!id || !countries || !Array.isArray(countries)) return null;
  const country = countries.find((country) => country.id === id);
  return country?.phonecode || null;
};

export const combineCountryCodeAndPhoneNumber = (
  phoneCode = "",
  phoneNumber = ""
) => {
  if (!phoneCode || !phoneNumber) return "";
  if (phoneCode === "1" && phoneNumber.startsWith("1")) {
    return phoneNumber;
  }
  return `${phoneCode}${phoneNumber}`;
};

export const hasSrcOneOrTwoNumbers = (numbers = []) => {
  if (!numbers || !Array.isArray(numbers)) return false;
  return numbers.some((number) => number.src === 1 || number.src === 2);
};
