import React, { useEffect, useState, useCallback } from "react";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { ReactComponent as ShowInfo } from "../../assets/img/icons-new/number-status/show-info/show-info-orange.svg";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import { useHistory } from "react-router-dom";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import OnboardingLayout from "../Login/OnboardingLayout";
import pastorslineLogo from "../../assets/img/pastorsline-logo.png";
import ButtonWithSpinner from "../../components/hub/HelperComponents/ButtonWithSpinner";
import AxiosConfig from "../../AxiosConfig";

const ForgotPassword = () => {
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const { users, clearForgoPasswordStatusAndError, forgotPassword } =
    useUsersStore();
  const { addInfoSnackBar } = useMainStore();
  const [formFields, setFormFields] = useState({
    email: "",
  });
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState();
  const [successText, setSuccessText] = useState();

  useEffect(() => {
    if (AxiosConfig.getAuthToken()) {
      history.push("/dashboard");
    }
  }, [history]);

  const toggleSnackBar = useCallback(
    (msg, error = false) => {
      const info = { msg };
      error && (info.err = true);
      addInfoSnackBar(info);
    },
    [addInfoSnackBar]
  );

  const onSend = () => {
    forgotPassword({
      email: formFields.email,
    });
  };

  useEffect(() => {
    if (
      users.forgotPasswordStatus === "success" &&
      users.forgotPasswordData?.message
    ) {
      setSuccessText(users.forgotPasswordData?.message);
      clearForgoPasswordStatusAndError();
    } else if (
      users.forgotPasswordStatus === "error" &&
      users.forgotPasswordError
    ) {
      if (users.forgotPasswordError) {
        setErrorEmail(true);
        setErrorTextEmail(<div>{users.forgotPasswordError}</div>);
      }

      clearForgoPasswordStatusAndError();
      setSuccessText(null);
    }
  }, [history, users, toggleSnackBar, clearForgoPasswordStatusAndError]);

  const onEmailChange = useCallback(
    ({ target: { value } }) => {
      setFormFields((prev) => ({ ...prev, email: value }));
      if (errorEmail) {
        setErrorEmail(false);
        setErrorTextEmail();
      }
    },
    [errorEmail]
  );

  if (AxiosConfig.getAuthToken()) {
    return null;
  }

  return (
    <OnboardingLayout>
      <div className="w-100 flexer-col">
        <div className="d-flex justify-content-center logo">
          <img src={pastorslineLogo} alt="pastorsline-logo" />
        </div>
        <div className="form-container">
          <h1 className="header-text">Forgot Password</h1>
          <TextField
            label="Email Address"
            fullWidth
            className={clsx({
              "mb-3": isMdOrBelowBreakpoint(breakpoint),
              "mb-4": !isMdOrBelowBreakpoint(breakpoint),
            })}
            key="email"
            error={errorEmail}
            helperText={errorTextEmail}
            required
            value={formFields.email}
            onChange={onEmailChange}
            InputProps={{
              endAdornment: errorEmail && <ShowInfo />,
            }}
          />
          {successText && <p className="success-txt">{successText}</p>}
          <ButtonWithSpinner
            className="btn-block mb-3 form-container-button"
            disabled={
              !formFields.email ||
              errorEmail ||
              users.forgotPasswordStatus === "loading"
            }
            onClick={onSend}
            loading={users.forgotPasswordStatus === "loading"}
          >
            Send
          </ButtonWithSpinner>
          <p className="already-text">
            Not registered?
            <button
              className="font-weight-bold onboarding-links"
              onClick={() => history.push("register")}
            >
              Sign up now!
            </button>
          </p>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default ForgotPassword;
