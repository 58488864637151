import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PickerOverlay } from "filestack-react";
import clsx from "clsx";
import Feature from "./Feature";
import { ReactComponent as AttachmentGraySvg } from "../../../../assets/img/icons-new/general/attachment/attachment-gray.svg";
import {
  addAttachmentsShortLink,
  clearAddAttachmentsShortLink,
} from "../../../../actions/shortLinks";
import { connect } from "react-redux";
import {
  injectTextInfoTextField,
  restoreCursorPosition,
} from "../../../../utils/messagesHelpers";
import { askForAndroidCameraPermission } from "../../../../utils/videoHelpers";
import { fetchCompanies } from "../../../../actions/companies";

function mapStateToProps(store, ownProps) {
  return {
    currentCompanyId: store.companies.currentCompany.id,
    currentCompany: store.companies.currentCompany,
    addAttachmentsStatus: store.shortLinks.addAttachmentsStatus,
    addAttachmentsUrls: store.shortLinks.addAttachmentsUrls,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAttachmentsShortLink: (companyId, formData) =>
      dispatch(addAttachmentsShortLink(companyId, formData)),
    fetchCompanies: (isResetCurrentCompany) =>
      dispatch(fetchCompanies(isResetCurrentCompany)),
    clearAddAttachmentsShortLink: () =>
      dispatch(clearAddAttachmentsShortLink()),
  };
}

const AddAttachmentFeature = (props) => {
  const {
    characters,
    textAreaRef,
    onMessageTextChange,
    onWarningTextChange,
    text,
    className,

    // Redux props
    currentCompanyId,
    currentCompany,
    addAttachmentsStatus,
    addAttachmentsUrls,

    // Redux func
    addAttachmentsShortLink,
    clearAddAttachmentsShortLink,
    fetchCompanies,
  } = props;

  const [fileStackAttachOpened, setFileStackAttachOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fileStackAttachOpened) {
      fetchCompanies(true);
    }
  }, [fetchCompanies, fileStackAttachOpened]);

  useEffect(() => {
    if (addAttachmentsStatus === "success") {
      let newValue = "";
      let restorePosition = null;
      try {
        ({ newValue, restorePosition } = injectTextInfoTextField(
          textAreaRef.current,
          addAttachmentsUrls.join(" ")
        ));
      } catch (e) {
        textAreaRef.current.focus();
        if (e.message === "too-long") {
          const beggining =
            addAttachmentsUrls.length > 1
              ? "Attachments links"
              : "The attachment link";
          onWarningTextChange(
            `${beggining} cannot be inserted because the message would exceed the limit of 1600 characters.`
          );
        }
      }

      clearAddAttachmentsShortLink();
      onMessageTextChange(newValue);
      setFileStackAttachOpened(false);
      if (restorePosition) {
        restoreCursorPosition(textAreaRef.current, restorePosition);
      }
    }
  }, [
    addAttachmentsStatus,
    addAttachmentsUrls,
    clearAddAttachmentsShortLink,
    onMessageTextChange,
    onWarningTextChange,
    textAreaRef,
  ]);

  const toggleFileStackAttachOpened = useCallback(() => {
    askForAndroidCameraPermission();
    setFileStackAttachOpened(!fileStackAttachOpened);
    setLoading(true);
  }, [fileStackAttachOpened]);

  const onCloseFileStackAttachment = useCallback(() => {
    setFileStackAttachOpened(false);
    setLoading(false);
  }, []);

  const successUploadAttach = useCallback(
    (result) => {
      if (result.filesUploaded.length) {
        const formData = {
          data: [],
        };
        for (let i = 0; i < result.filesUploaded.length; i += 1) {
          const isUseRebrandly = currentCompany.is_use_rebrandly;

          if (isUseRebrandly === 0) {
            onMessageTextChange(
              (val) => `${val} ${result.filesUploaded[i].url}`
            );
          } else {
            formData.data.push({
              shortname: "Auto created link to attachment",
              url: result.filesUploaded[i].url,
              link_type: 2,
            });
            addAttachmentsShortLink(currentCompanyId, formData);
          }
        }
      }
    },
    [
      addAttachmentsShortLink,
      currentCompanyId,
      currentCompany,
      onMessageTextChange,
    ]
  );

  let fileStackAttachAvailable = true;
  if (1600 - characters < 20) {
    fileStackAttachAvailable = false;
  } else if (addAttachmentsStatus === "loading") {
    fileStackAttachAvailable = false;
  }

  const filestackAttachOptions = {
    maxSize: 104857600, // 100MB
    fromSources: ["local_file_system", "url"],
    customText: { "My Device": "Upload from Device" },
    maxFiles: 10,
    storeTo: {
      location: "s3",
    },
    onClose: onCloseFileStackAttachment,
    onOpen: () => setLoading(false),
  };

  return (
    <>
      <Feature
        svg={<AttachmentGraySvg className="cursor-pointer" />}
        text={text}
        onClick={
          fileStackAttachAvailable ? toggleFileStackAttachOpened : undefined
        }
        className={clsx(className, {
          "disabled-feature": !fileStackAttachAvailable,
        })}
        loading={loading}
      />
      {fileStackAttachOpened && (
        <PickerOverlay
          apikey={process.env.REACT_APP_FILESTACK_APIKEY}
          pickerOptions={filestackAttachOptions}
          onSuccess={successUploadAttach}
          preload={true}
          componentDisplayMode={{
            type: "immediate",
          }}
        />
      )}
    </>
  );
};

AddAttachmentFeature.propTypes = {
  characters: PropTypes.number.isRequired,
  textAreaRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onMessageTextChange: PropTypes.func.isRequired,
  onWarningTextChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAttachmentFeature);
