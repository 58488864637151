import { useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const RenderTopNotificationBannerOverlayForRouter = (props) => {
  const topBannerDiv = document.getElementById("top-banner-container");
  const mainAppDiv = document.getElementById("root");
  const { show, children } = props;

  useEffect(() => {
    if (show) {
      mainAppDiv.classList.add("main-app-container-router");
      topBannerDiv.classList.add("top-banner-router");
    }
    return () => {
      mainAppDiv.classList.remove("main-app-container-router");
      topBannerDiv.classList.remove("top-banner-router");
    };
  }, [mainAppDiv, show, topBannerDiv]);

  if (!show) return null;

  return createPortal(children, topBannerDiv);
};

RenderTopNotificationBannerOverlayForRouter.propTypes = {
  show: PropTypes.bool,
};

RenderTopNotificationBannerOverlayForRouter.defaultProps = {
  show: false,
};

export default RenderTopNotificationBannerOverlayForRouter;
