import React from "react";
import PropTypes from "prop-types";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import PlusMinusCheckbox from "../HelperComponents/PlusMinusCheckbox";

const ColumnSelectionDropDown = (props) => {
  const { selectionFields, onChange, target } = props;

  return (
    <UncontrolledPopover
      trigger="legacy"
      placement="bottom-end"
      target={target}
      container={"themed-root"}
      className="thread-other-actions-popover"
    >
      <PopoverBody>
        {selectionFields.map((selectionField) => (
          <div
            className="d-flex align-items-center mt-1 mb-1"
            key={selectionField.nameStateProp}
          >
            <PlusMinusCheckbox
              onChange={() => onChange(selectionField.nameStateProp)}
              checked={selectionField.stateProp}
              disabled={selectionField?.disabled || false}
            />
            <span className="ml-2">{selectionField.text}</span>
          </div>
        ))}
      </PopoverBody>
    </UncontrolledPopover>
  );
};

ColumnSelectionDropDown.propTypes = {
  selectionFields: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      stateProp: PropTypes.bool.isRequired,
      nameStateProp: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  target: PropTypes.string,
};

ColumnSelectionDropDown.defaultProps = {
  target: "dropdown-campaign",
};

export default ColumnSelectionDropDown;
