import { ALL_MERGE_FIELDS } from "./enums/threadEnums";
import { isNotAnEmptyArray } from "./settingsHelpers";

export const injectTextInfoTextField = (field, text, smartInsert = true) => {
  if (!text || text.length === 0) {
    return null;
  }
  let newValue = field.value;
  const { maxLength } = field;
  let restorePosition = 0;

  // MOZILLA and others (without old IE)
  if (field.selectionStart || field.selectionStart === 0) {
    let startPos = field.selectionStart;
    let endPos = field.selectionEnd;

    let insertText = text;
    if (smartInsert) {
      if (startPos === endPos) {
        const preChar =
          startPos > 0 ? field.value.substr(startPos - 1, 1) : null;
        const postChar =
          endPos < field.value.length ? field.value.substr(endPos, 1) : null;

        if (
          preChar !== null &&
          preChar !== "(" &&
          preChar !== "[" &&
          preChar !== "<" &&
          preChar !== " "
        ) {
          insertText = ` ${insertText}`;
        }
        if (
          postChar !== ")" &&
          postChar !== "]" &&
          postChar !== ">" &&
          postChar !== " "
        ) {
          insertText = `${insertText} `;
        }
      }
    }

    newValue =
      field.value.substring(0, startPos) +
      insertText +
      field.value.substring(endPos, field.value.length);
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      throw new Error("too-long");
    }
    restorePosition = startPos + insertText.length;
  } else {
    newValue = field.value + (field.value.length ? " " : "") + text;
    if (typeof maxLength !== "undefined" && newValue.length > maxLength) {
      throw new Error("too-long");
    }
  }

  return { newValue, restorePosition };
};

export const restoreCursorPosition = (field, position) => {
  field.setSelectionRange(position, position);
  field.focus();
};

export const insertTextIntoTextField = ({
  ref,
  text,
  setText,
  setWarningText,
  errorText,
}) => {
  try {
    const { newValue, restorePosition } = injectTextInfoTextField(ref, text);
    setText(newValue);
    if (restorePosition) {
      restoreCursorPosition(ref, restorePosition);
    }
  } catch (e) {
    ref.focus();
    if (e.message === "too-long" && setWarningText) {
      setWarningText(errorText);
    }
  }
};

// This function takes an array of image URLs and returns an array of objects,
// where each object contains information about an image.

/**
 * Maps image URLs to an array of objects with image information.
 *
 * @param {Array} images - An array of image URLs.
 * @returns {Array} An array of objects containing image information.
 */
export const mapImageUrlsToObject = (images = []) => {
  // Using the Array.map() function to transform each image URL into an object
  // that contains various properties related to the image.
  return images.map((image, id) =>
    // Using Object.assign() to create a new object with specified properties.
    Object.assign(
      {},
      {
        thumbUrl: image, // The URL for a thumbnail version of the image.
        size: 0, // The size of the image (initialized to 0, can be updated later).
        url: image, // The original URL of the image.
        uploadId: String(id), // A unique identifier for the image, converted to a string.
      }
    )
  );
};

/**
 * Retrieves an array of image URLs from an array of image objects.
 *
 * @param {Array} images - An array of image objects containing 'url' properties.
 * @returns {Array|null} An array of image URLs or null if the input array is empty.
 */
export const getImagesUrls = (images = []) => {
  // Check if the input array 'images' is empty.
  if (!images.length) return null;

  // Using the Array.map() function to extract the 'url' property from each image object,
  // resulting in an array of image URLs.
  return images.map((image) => image.url);
};

export const getLastExtension = (url = "") => {
  // Extract the last segment after the last '/'
  const lastSegment = url.split("/").pop();

  // Find the last '.' and get the substring after it
  const lastDotIndex = lastSegment.lastIndexOf(".");
  return lastDotIndex !== -1 ? lastSegment.substring(lastDotIndex + 1) : null;
};

/**
 * Sorts and array of objects in recipients table in send message wizard
 *
 * @param {Array} data - An array of objects containing first_name and last_name.
 * @param {Array} sortBy - A string for sorting.
 * @returns {Array|null} A sorted array.
 */
export const sortRecipientTable = (data, sortBy) => {
  const sortedArray = [...data];

  const getSortableValue = (item, key) => {
    // If the key is not present, default to 'group_name' or 'name'
    return item[key] || item["group_name"] || item["name"];
  };

  switch (sortBy) {
    case "firstNameAsc":
      sortedArray.sort((a, b) =>
        getSortableValue(a, "first_name")?.localeCompare(
          getSortableValue(b, "first_name")
        )
      );
      break;

    case "firstNameDesc":
      sortedArray.sort((a, b) =>
        getSortableValue(b, "first_name")?.localeCompare(
          getSortableValue(a, "first_name")
        )
      );
      break;

    case "lastNameAsc":
      sortedArray.sort((a, b) =>
        getSortableValue(a, "last_name")?.localeCompare(
          getSortableValue(b, "last_name")
        )
      );
      break;

    case "lastNameDesc":
      sortedArray.sort((a, b) =>
        getSortableValue(b, "last_name")?.localeCompare(
          getSortableValue(a, "last_name")
        )
      );
      break;

    default:
      // Default to ascending order by first_name
      sortedArray.sort((a, b) =>
        getSortableValue(a, "first_name")?.localeCompare(
          getSortableValue(b, "first_name")
        )
      );
      break;
  }

  return sortedArray;
};

export const getVcardDetailsByURL = (url = "", vcards = []) => {
  const filteredVcard = vcards.find((vcard) => vcard.url === url);
  return filteredVcard;
};

export const countGroupsAndPeople = (data = []) => {
  if (!data || !isNotAnEmptyArray(data)) return "0 People";
  let groupCount = 0;
  let peopleCount = 0;
  let tagCount = 0;

  data.forEach((item) => {
    // Assume that if an item has a `group_name`, it's a group
    if (item.group_name) {
      if (item?.group_name?.endsWith("-tag")) {
        tagCount++;
      } else {
        groupCount++;
      }
    } else {
      peopleCount++;
    }
  });

  const counts = [
    groupCount > 0 ? `${groupCount} Group${groupCount > 1 ? "s" : ""}` : "",
    tagCount > 0 ? `${tagCount} Tag${tagCount > 1 ? "s" : ""}` : "",
    peopleCount > 0
      ? `${peopleCount} ${peopleCount > 1 ? "People" : "Person"}`
      : "",
  ].filter(Boolean); // Remove empty strings

  if (counts.length === 0) {
    return "0 People";
  }

  if (counts.length === 1) {
    return counts[0];
  }

  const last = counts.pop(); // Remove the last element to handle "and"
  return `${counts.join(", ")} and ${last}`;
};

export const setSkinTone = (skinTone) => {
  localStorage.setItem("skin-tone", skinTone);
};

export const getSkinTone = () => {
  return localStorage.getItem("skin-tone");
};

export const detectLinksAndEmails = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  var mailRegex =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  text = text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });
  text = text.replace(mailRegex, function (mail) {
    return '<a href="mailto:' + mail + '">' + mail + "</a>";
  });
  return text;
};

export const removeMergeFieldBracketsFromString = (input = "") => {
  let result = input;
  if (input) {
    ALL_MERGE_FIELDS.forEach(({ text }) => {
      const textWithBrackets = `[${text}]`; // Add brackets around the text
      result = result.replace(
        new RegExp(`\\${textWithBrackets}`, "g"),
        `-${text}-`
      );
    });
  }
  return result;
};
