import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfirmationModal from "../../modals/ActionsModals/ConfirmationModal";
import ConfirmationModalStats from "../../modals/ActionsModals/ConfirmationModalStats";
import {
  deleteGroups,
  addSnackData as addGroupSnackData,
  handleGroupItemsActions,
  fetchMembersForDeletion,
  fetchMembersData,
} from "../../../actions/groups";
import { clearSelection as clearSelectionAction } from "../../../actions/threads";
import Avatar from "../../Avatar";
import { groupName } from "../../../helpers";

function mapStateToProps(store) {
  return {
    companyId: store.companies.currentCompany.id,
    rhsItemsSelected: store.contacts.rhsItemsSelected,
    deleteGroupsStatus: store.groups.deleteGroupsStatus,
    deletedSuccessGroups: store.groups.deletedSuccessGroups,
    deletedSuccessGroupMembers: store.groups.deletedSuccessGroupMembers,
    deletionMembersStats: store.groups.deletionMembersStats,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSelection: () => dispatch(clearSelectionAction(true)),
    onGroupItemsActions: (time, selectedThreads, groupData, subMembersData) =>
      dispatch(
        handleGroupItemsActions(
          time,
          selectedThreads,
          groupData,
          subMembersData
        )
      ),
    onDeleteGroups: (
      companyId,
      groupIds,
      contactsAlso,
      workflowId,
      needRouting
    ) =>
      dispatch(
        deleteGroups(companyId, groupIds, contactsAlso, workflowId, needRouting)
      ),
    setGroupSnackData: (data, entity) =>
      dispatch(addGroupSnackData(data, entity)),
    fetchMembersStats: (companyId, groupIds, workflowId) =>
      dispatch(fetchMembersForDeletion(companyId, groupIds, workflowId)),
    fetchContactsData: (companyId, groupId, workflowId, filter) =>
      dispatch(fetchMembersData(companyId, groupId, workflowId, filter)),
  };
}

const Delete = ({
  companyId,
  clearSelection,
  onGroupItemsActions,
  onDeleteGroups,
  deleteGroupsStatus,
  deletedSuccessGroups,
  deletedSuccessGroupMembers,
  setGroupSnackData,
  deletionMembersStats,
  fetchMembersStats,
  fetchContactsData,
  /* Props from parent */
  runWorkflow,
  onDeleteComplete,
  needRouting,
  selectedGroups,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openConfirmationStats, setOpenConfirmationStats] = useState(false);
  const [confirmationModalHeader, setConfirmationModalHeader] = useState("");
  const [selectionInfoView, setSelectionInfoView] = useState(<></>);
  const [confirmationModalContent, setConfirmationModalContent] = useState(
    <></>
  );
  const [singleGroupData, setSingleGroupData] = useState({});
  const [workflowId, setWorkflowId] = useState();
  const [undoPayload, setUndoPayload] = useState();

  useEffect(() => {
    if (runWorkflow) {
      startWorkflow();
    }
    // eslint-disable-next-line
  }, [runWorkflow])

  useEffect(() => {
    if (deleteGroupsStatus === `success-${workflowId}`) {
      toggleGroupUndoSnackbar(true)();
    } else if (deleteGroupsStatus === `fetching-data-${workflowId}`) {
      displayStats();
    } else if (deleteGroupsStatus === `failure-${workflowId}`) {
      toggleGroupUndoSnackbar(true)(true);
    }
    // eslint-disable-next-line
  }, [deleteGroupsStatus])

  /* See how to set global data */
  const setGlobalState = (empty) => {
    let totalGroups = 0;
    if (empty) {
      onGroupItemsActions("", {}, {}, {});
      return;
    }

    const groupKeys = Object.keys(selectedGroups);
    totalGroups = groupKeys.length;
    setSingleGroupData(selectedGroups[groupKeys[0]]);

    return {
      totalGroups,
    };
  };

  const startWorkflow = () => {
    setGlobalState();
    const groupKeys = Object.keys(selectedGroups);
    const focalGroupData = selectedGroups[groupKeys[0]];
    const genericTitle = (
      <>
        {groupKeys.length === 1 ? (
          <>
            <span>: </span>
            <span style={{ position: "relative", top: "9px" }}>
              <Avatar
                isGroup
                isAdhocGroup={focalGroupData && focalGroupData.addhoc_id}
                isAllPeopleGroup={
                  focalGroupData && focalGroupData.my_group_status > 0
                }
                isMultipleContacts={false}
                firstName={""}
                lastName={""}
                email={""}
                bgColor={""}
              />
            </span>
            <span>{groupName(selectedGroups[groupKeys[0]])}</span>
          </>
        ) : (
          <span>s</span>
        )}
      </>
    );
    let headerContent = (
      <span>
        Delete Group
        {genericTitle}
      </span>
    );
    const bodyContent = `Are you sure you want to permanently delete ${
      groupKeys.length > 1 ? "the selected" : "this"
    } ${groupKeys.length > 1 ? "groups?" : "group?"}`;
    setOpenConfirmationModal(true);
    setConfirmationModalHeader(headerContent);
    setConfirmationModalContent(bodyContent);
    setSelectionInfoView(genericTitle);
  };

  const proceedDelete = (val, duplicates, deleteContacts = 0) => {
    setOpenConfirmationModal(false);

    if (val) {
      const groupIds = Object.keys(selectedGroups);
      const workflowId = `${singleGroupData.id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      setWorkflowId(workflowId);
      if (deleteContacts) {
        fetchMembersStats(companyId, groupIds, workflowId);
      } else {
        const undoData = {
          companyId,
          workflowId,
          needRouting,
          contactsAlso: deleteContacts,
        };
        setUndoPayload(undoData);
        onDeleteGroups(
          companyId,
          groupIds,
          deleteContacts,
          workflowId,
          needRouting
        );
      }
    } else {
      onDeleteComplete(true);
    }
  };

  const displayStats = () => {
    setOpenConfirmationStats(true);
  };

  const finalizeDelete = (val, deleteContacts) => {
    setOpenConfirmationStats(false);
    if (val) {
      /* Logic to archive contact with selectedList */
      const groupIds = Object.keys(selectedGroups);
      const undoData = {
        companyId,
        workflowId,
        needRouting,
        contactsAlso: deleteContacts,
      };
      setUndoPayload(undoData);
      onDeleteGroups(
        companyId,
        groupIds,
        deleteContacts,
        workflowId,
        needRouting
      );
      setGlobalState(true);
      return;
    }
    setGlobalState(true);
    onDeleteComplete();
    clearSelection();
  };

  const toggleGroupUndoSnackbar = (val) => (failure) => {
    let msg;
    let msgMembers;
    const groupIds = Object.keys(selectedGroups);
    if (failure) {
      if (groupIds.length === 1) {
        msg = `${groupName(singleGroupData)} couldn't be deleted`;
      } else {
        msg = `Error: Selected groups couldn't be deleted`;
      }
    } else {
      if (deletedSuccessGroups.length > 1) {
        msg = `${deletedSuccessGroups.length} groups have been deleted.`;
      } else if (deletedSuccessGroups.length === 1 && singleGroupData) {
        const label = groupName(singleGroupData);
        msg = `${label} has been deleted`;
      } else {
        msg = `${deletedSuccessGroups.length} groups have been deleted`;
      }

      if (deletedSuccessGroupMembers.length > 1) {
        msgMembers = `${deletedSuccessGroupMembers.length} People have been deleted.`;
      } else if (deletedSuccessGroupMembers.length === 1) {
        msgMembers = `${deletedSuccessGroupMembers.length} Person has been deleted`;
      }
    }
    if (val) {
      const data = {
        id: workflowId,
        err: failure,
        msg,
        undoPayload, // isn't required, might change later
      };
      if (deletedSuccessGroupMembers.length >= 1) {
        data.extraSnackData = [
          {
            msg: msgMembers,
            id: workflowId,
            undoPayload: null,
          },
        ];
      }
      setGroupSnackData(data, "delete");
    }

    if (onDeleteComplete) {
      onDeleteComplete();
      clearSelection();
    }
  };

  const handleMembersRequest = (filter) => {
    const groupIds = Object.keys(selectedGroups);
    fetchContactsData(companyId, groupIds, workflowId, filter);
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          show={openConfirmationModal}
          modalBodyContent={confirmationModalContent}
          onClose={proceedDelete} // for other actions we need to check in callback which action to proceed
          modalBodyHeader={confirmationModalHeader}
          isGroup={true}
          deletion
          footerText="Also delete all archived People in this Group"
        />
      )}
      {openConfirmationStats && (
        <ConfirmationModalStats
          selectionInfoView={selectionInfoView}
          show={openConfirmationStats}
          modalBodyContent={confirmationModalContent}
          modalBodyHeader={confirmationModalHeader}
          footerText="Also delete all archived People in this Group"
          summaryStats={deletionMembersStats}
          onClose={finalizeDelete}
          fetchMembers={handleMembersRequest}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
