import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export const getRequest = (
  dispatch,
  prefixAction,
  url,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...AxiosConfig.getAuthConfig().headers,
      ...axiosConfig?.headers,
    },
  };

  return axios
    .get(AxiosConfig.getEndpointAddress() + url, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const postRequest = (
  dispatch,
  prefixAction,
  url,
  data,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    thenFunction: () => {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
    thenFunction,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    thenFunction: () => {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...AxiosConfig.getAuthConfig().headers,
      ...axiosConfig?.headers,
    },
  };

  return axios
    .post(AxiosConfig.getEndpointAddress() + url, data, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .then(thenFunction)
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const putRequest = (
  dispatch,
  prefixAction,
  url,
  data,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...AxiosConfig.getAuthConfig().headers,
      ...axiosConfig?.headers,
    },
  };

  return axios
    .put(AxiosConfig.getEndpointAddress() + url, data, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const deleteRequest = (
  dispatch,
  prefixAction,
  url,
  options = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
  }
) => {
  const {
    requestDispatchData,
    axiosConfig,
    successDispatchData,
    errorDispatchData,
  } = {
    requestDispatchData: {},
    successDispatchData: {},
    errorDispatchData: {},
    axiosConfig: {},
    ...options,
  };

  dispatch({
    type: prefixAction,
    ...requestDispatchData,
  });

  let config = {
    ...axiosConfig,
    headers: {
      ...AxiosConfig.getAuthConfig().headers,
      ...axiosConfig?.headers,
    },
  };

  return axios
    .delete(AxiosConfig.getEndpointAddress() + url, config)
    .then((response) => {
      dispatch({
        type: `${prefixAction}.SUCCESS`,
        payload: response.data,
        ...successDispatchData,
      });
    })
    .catch(function (error) {
      dispatch({
        type: `${prefixAction}.ERROR`,
        error: error.response,
        ...errorDispatchData,
      });
    });
};

export const arrayToUrl = (url, array, queryArrayName) => {
  if (!array) return url;
  let newUrl = url;
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (i === array.length - 1) {
      newUrl = `${newUrl}${queryArrayName}[]=${element}`;
    } else {
      newUrl = `${newUrl}${queryArrayName}[]=${element}&`;
    }
  }

  return newUrl;
};

export const addQueryParamsToURL = (path, obj) => {
  const queryParams = Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return `${path}${queryParams.length > 0 ? `?${queryParams}` : ""}`;
};
