import { useMemo } from "react";

export const useIsAdminOrOwner = (user) => {
  const isAdminOrOwner = useMemo(
    () => user?.is_admin_user || user?.is_admin_plus || user?.is_main_owner,
    [user]
  );

  return isAdminOrOwner;
};
