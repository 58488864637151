import { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getPrimaryUrl } from "../../helpers";
import { setCurrentCompany } from "../../actions/companies";
import { history } from "../../store";
import { useSetParamCompAndRedirection } from "../../utils/hooks/useSetParamCompAndRedirection";

function mapStateToProps(store) {
  return {
    loggedUser: store.users.loggedUser,
    currentCompany: store.companies.currentCompany,
    currentCompanyStatus: store.companies.currentCompanyStatus,
    companiesStatus: store.companies.companiesStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentCompany: (id) => dispatch(setCurrentCompany(id)),
  };
}

const SmartSyncGroup = (props) => {
  const {
    // Redux props
    loggedUser,
    currentCompany,
    currentCompanyStatus,
    companiesStatus,

    // Redux functions
    setCurrentCompany,

    // Router
    match,
  } = props;

  const { companyId, groupId } = match.params;

  const redeirectUserByDefaultApp = useCallback(() => {
    if (loggedUser?.default_app_version === "v3") {
      history.push(`/#modal-smart-merge-group/${groupId}`);
    } else if (loggedUser?.default_app_version === "v2") {
      window.location.href = getPrimaryUrl(
        `groups/merge_contacts/${groupId}`,
        companyId
      );
    }
  }, [companyId, groupId, loggedUser]);

  useSetParamCompAndRedirection({
    companiesStatus,
    companyId,
    setCurrentCompany,
    redeirectUserByDefaultApp,
    currentCompanyStatus,
    currentCompany,
  });

  return null;
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SmartSyncGroup)
);
