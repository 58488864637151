import { useCallback, useState } from "react";

export const useActionMenus = (dataForModal) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onClickMenu = useCallback(
    (func) => (event) => {
      func(event);
      toggle();
    },
    [toggle]
  );

  return {
    isOpen,
    toggle,
    onClickMenu,
  };
};
