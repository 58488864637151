const initialState = {
  settingsStatus: null,
  settings: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Clear data after logout
    case "USER_LOGOUT":
      return initialState;

    case "SETTINGS/SETTINGS":
      return {
        ...state,
        settingsStatus: "loading",
      };
    case "SETTINGS/SETTINGS.SUCCESS":
      const appTimeDate = new Date(action.payload.settings.appTime);
      const browserTimeDate = new Date();

      let settings = action.payload.settings;
      settings._appTimeDiffMs =
        browserTimeDate - appTimeDate - action.responseTime / 2;

      return {
        ...state,
        settingsStatus: "success",
        settings: settings,
      };
    case "SETTINGS/SETTINGS.ERROR":
      return {
        ...state,
        settingsStatus: "error",
      };

    default:
      return state;
  }
}
