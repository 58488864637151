import React, { useMemo } from "react";
import { ReactComponent as SyncSvg } from "../../assets/img/icons-new/group-tab/integrations/sync/sync-grey.svg";
import PropTypes from "prop-types";
import SpanWithTooltip from "../SpanWithTooltip";
import {
  getScheduleFromRRuleText,
  getScheduleMessageSummary,
} from "../../utils/recurringMessagesHelpers";

const RecurringIconWithTooltip = (props) => {
  const { scheduleMessage } = props;

  const getSummaryToDisplay = useMemo(() => {
    const { recurring_rule } = scheduleMessage || {};
    let display = "";
    if (recurring_rule) {
      const schedule = getScheduleFromRRuleText(recurring_rule);

      display = getScheduleMessageSummary({
        ...schedule,
        isMessageSummaryDefault: false,
        isMessageSummaryExpanded: true,
      });
    }

    return display;
  }, [scheduleMessage]);

  if (!scheduleMessage?.recurring_rule) return null;

  return (
    <SpanWithTooltip title={getSummaryToDisplay} arrow={true}>
      <SyncSvg className="mb-1" height={14} width={14} />
    </SpanWithTooltip>
  );
};

RecurringIconWithTooltip.propTypes = {
  scheduleMessage: PropTypes.object,
};

RecurringIconWithTooltip.defaultProps = {
  scheduleMessage: {},
};

export default RecurringIconWithTooltip;
