import React, { Suspense } from "react";
import { connect } from "react-redux";
import { addDataForModal } from "../actions/main";
import { history } from "../store";

function mapDispatchToProps(dispatch) {
  return {
    addDataForModal: (dataForModal) => dispatch(addDataForModal(dataForModal)),
  };
}

function mapStateToProps(store) {
  return {
    dataForModal: store.main.dataForModal,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class ModalsManager extends React.PureComponent {
    constructor(props) {
      super(props);
      this.close = this.close.bind(this);
      this.closeReplace = this.closeReplace.bind(this);
      this.shake = this.shake.bind(this);
      this.timers = [];
      this.state = {
        modals: {},
      };
    }

    componentDidMount() {
      this.update(this.props.modal);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      this.update(this.props.modal, prevProps.modal);
    }

    componentWillUnmount() {
      for (let i = 0; i < this.timers.length; i++) {
        clearTimeout(this.timers[i]);
      }
    }

    update(currentModal, prevModal) {
      if (currentModal !== prevModal) {
        let modals = { ...this.state.modals };
        if (currentModal) {
          modals[currentModal] = "showing";
          this.timers.push(setTimeout(() => this.show(currentModal), 100));
          this.setState({ modals: modals });
        } else if (prevModal) {
          modals[prevModal] = "hiding";
          this.timers.push(setTimeout(() => this.hide(prevModal), 150));
          this.setState({ modals: modals });
        }
      }
    }

    show(modal) {
      let modals = { ...this.state.modals };
      const modalsKeys = Object.keys(modals);
      for (let i = 0; i < modalsKeys.length; i++) {
        modals[modalsKeys[i]] = false;
      }
      modals[modal] = true;
      this.setState({ modals: modals });
    }

    hide(modal) {
      let modals = { ...this.state.modals };
      modals[modal] = false;
      this.setState({ modals: modals });
    }

    close(e) {
      e && e.preventDefault();
      e && e.stopPropagation();
      if (this.props.close) {
        this.props.close();
      } else {
        history.goBack();
      }

      // Timeout for clearing data for modal without showing changes on the screen
      setTimeout(() => {
        // Clear data for modal on close
        if (Object.keys(this.props.dataForModal).length > 0) {
          this.props.addDataForModal({});
        }
      }, 1);
    }

    closeReplace(replacePath) {
      if (this.props.closeReplace) {
        this.props.closeReplace(replacePath);
      } else {
        history.replace(replacePath);
      }
    }

    shake(e) {
      const modalContent =
        e.currentTarget.parentNode.querySelector(".modal-content");
      if (modalContent) {
        modalContent.classList.add("shake");
        setTimeout(() => {
          modalContent.classList.remove("shake");
        }, 1000);
      }
    }

    render() {
      const { param, modal, modals, showLoading, ...rest } = this.props;
      const props = {
        ...rest,
        closeModal: this.close,
        closeModalReplace: this.closeReplace,
        shakeModal: this.shake,
        showLoading: showLoading,
        param: param,
      };
      const modalsKeys = Object.keys(modals);
      for (let i = 0; i < modalsKeys.length; i++) {
        if (
          typeof this.state.modals[modalsKeys[i]] !== "undefined" &&
          this.state.modals[modalsKeys[i]]
        ) {
          const ModalComponent = modals[modalsKeys[i]];
          return (
            <div>
              <Suspense fallback={<div className="d-none">Loading...</div>}>
                <ModalComponent
                  {...props}
                  modalUrlName={modalsKeys[i]}
                  show={this.state.modals[modalsKeys[i]] === true}
                />
              </Suspense>
            </div>
          );
        }
      }
      return null;
    }
  }
);
