const initialState = {
  // TImezones
  timezonesStatus: "",
  timezones: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "UTILITIES/TIMEZONES": {
      return {
        ...state,
        timezonesStatus: "loading",
      };
    }

    case "UTILITIES/TIMEZONES.SUCCESS": {
      return {
        ...state,
        timezones: action.payload.data,
        timezonesStatus: "success",
      };
    }

    case "UTILITIES/PHONE_CODES": {
      return {
        ...state,
        phoneCodesStatus: "loading",
      };
    }
    case "UTILITIES/PHONE_CODES.SUCCESS": {
      return {
        ...state,
        phoneCodes: action.payload.data,
        phoneCodesStatus: "success",
      };
    }

    default:
      return state;
  }
}
