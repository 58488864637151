import AxiosConfig from "../AxiosConfig";
import { isEmptyObject } from "../helpers";
import { videoTranscoding } from "../utils/filestackHelpers";
import {
  getRequest,
  postRequest,
  addQueryParamsToURL,
} from "../utils/requests";
import axios from "axios";

export function fetchMediaResources(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "MEDIA/FETCH_MEDIA_RESOURCES",
      addQueryParamsToURL(`media/resources.json`, params),
      {
        requestDispatchData: { params },
        successDispatchData: { params },
        errorDispatchData: { params },
      }
    );
}

export function addMediaResouce(data) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "MEDIA/ADD_MEDIA_RESOURCES",
      "media/resources.json",
      data
    );
}

export function deleteMediaResources(data, mediaType = "") {
  return (dispatch) =>
    postRequest(
      dispatch,
      "MEDIA/DELETE_MEDIA_RESOURCES",
      "media/resources/delete.json",
      data,
      {
        successDispatchData: { ids: data?.ids, mediaType },
      }
    );
}

export function openAddVideoModal(data) {
  return (dispatch) => {
    dispatch({
      type: "MEDIA/OPEN_ADD_VIDEO_MODAL",
      data,
    });
  };
}

export function editMediaResource(handler) {
  return (dispatch) => {
    dispatch({
      type: "MEDIA/EDIT_MEDIA_RESOURCES",
    });

    videoTranscoding({ handle: handler, url: "" }, "preset:thumbnail")
      .then((video) => {
        const data = { thumbnail_url: video?.thumbnail || "" };
        const url = `media/resources/${handler}`;

        return axios.post(
          AxiosConfig.getEndpointAddress() + url,
          data,
          AxiosConfig.getAuthConfig()
        );
      })
      .then((response) => {
        const data = {};
        if (
          response?.data?.media_resource &&
          !isEmptyObject(response?.data?.media_resource)
        ) {
          data[handler] = response?.data?.media_resource?.thumbnail_url || "";
        }
        dispatch({
          type: "MEDIA/EDIT_MEDIA_RESOURCES.SUCCESS",
        });
        dispatch({
          type: "MEDIA/FETCH_VIDEO_THUMBNAIL.SUCCESS",
          payload: { data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "MEDIA/EDIT_MEDIA_RESOURCES.ERROR",
          error,
          handler: { [handler]: "error" },
        });
      });
  };
}

export function fetchVideoThumbnail(params) {
  return function (dispatch) {
    dispatch({
      type: "MEDIA/FETCH_VIDEO_THUMBNAIL",
      params,
    });

    const url = addQueryParamsToURL(`media/resources/thumbnails.json`, params);
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        const { handles } = params;
        const { data } = response;
        const handler =
          handles && Array.isArray(handles) && Boolean(handles?.length)
            ? handles[0]
            : "";
        const thumbnail = data?.data && data?.data[handler];
        if (thumbnail) {
          dispatch({
            type: "MEDIA/FETCH_VIDEO_THUMBNAIL.SUCCESS",
            params,
            payload: response.data,
          });
        } else {
          dispatch(editMediaResource(handler));
        }
      })
      .catch((error) => {
        const { response } = error;
        const { handles } = params;
        const handler =
          handles && Array.isArray(handles) && Boolean(handles?.length)
            ? handles[0]
            : "";
        if (
          response?.data?.code === 404 &&
          response?.data?.message === "Not Found"
        ) {
          dispatch(editMediaResource(handler));
        } else {
          dispatch({
            type: "MEDIA/FETCH_VIDEO_THUMBNAIL.ERROR",
            params,
            handler: { [handler]: "error" },
          });
        }
      });
  };
}
