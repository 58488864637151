import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import TableInfinityScroll from "../hub/HubThreadViewComponents/TableInfinityScroll";
import TableHeader from "../hub/HubThreadViewComponents/TableHeader";
import BroadcastTableBody from "./BroadcastTableBody";
import { useWithTableWidth } from "../../utils/hooks/useWithTableWidth";
import {
  arraysEqual,
  changePeopleSortKeys,
  isEmptyObject,
  joinKeyValueAndJoinResultOf,
  mapSortTypeToApiType,
} from "../../helpers";
import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";
import useBroadcastStore from "../../utils/hooks/ReduxHooks/broadcastStore";
import BroadcastFilterPanel from "./BroadcastFilterPanel";
import ScheduledMessageSummaryModal from "./ScheduledMessageSummaryModal";
import useGroupsStore from "../../utils/hooks/ReduxHooks/groupsStore";
import useMessagesStore from "../../utils/hooks/ReduxHooks/messagesStore";
import DeleteMessageConfirmationModal from "../hub/HubThreadView/ChatMessages/DeleteMessageConfirmationModal";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import SendScheduleMessageConfirmationModal from "../hub/HubThreadView/ChatMessages/SendScheduleMessageConfirmationModal";
import { isNotAnEmptyArray } from "../../utils/settingsHelpers";
import StopNowScheduleMessageConfirmationModal from "../hub/HubThreadView/ChatMessages/StopNowScheduleMessageConfirmationModal";

const defaultColumnSelectionState = {
  showBroadcastName: true,
  showSendOn: true,
  showCreatedAt: true,
  showGroups: true,
  showRecipients: true,
  showSuccessfull: true,
  showFailed: true,
  showCredits: true,
  showOptedOut: false,
  showDoNotCall: false,
  showUnformatted: false,
  showDuplicates: false,
  showLandlines: false,
  showFrom: false,
  showLinkClicks: false,
};

const BroadcastTableView = () => {
  const [orderBy, setOrderBy] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [scrollToTop, setScrollToTop] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoadingForSort, setIsLoadingForSort] = useState(false);
  const [scrollLeftPosition, setScrollLeftPosition] = useState(0);
  const [broadcastDataForEdit, setBroadcastDataForEdit] = useState({});
  const [deleteMessageData, setDeleteMessageData] = useState(null);
  const [sendScheduledNowData, setSendScheduledNowData] = useState(null);
  const [stopScheduledNowData, setStopScheduledNowData] = useState(null);
  const [
    dataForScheduledMessageSummaryModal,
    setDataForScheduledMessageSummaryModal,
  ] = useState({});
  const [columnSelectionState, setColumnSelectionState] = useState(
    defaultColumnSelectionState
  );

  // Router
  const history = useHistory();

  // Redux store
  const { companies } = useCompaniesStore();
  const { groups, fetchGroupData, fetchMembers } = useGroupsStore();
  const { addInfoSnackBar } = useMainStore();
  const {
    messages,
    setExistingGroupId,
    addGroupsForMessage,
    deleteMessage,
    clearDeleteMessage,
    sendScheduledNow,
    clearSendNowScheduleMessage,
    stopScheduledNow,
    clearStopSendScheduleNowStatus,
  } = useMessagesStore();
  const { broadcasts, fetchFilteredBroadcasts, fetchBroadcastsCount } =
    useBroadcastStore();
  const {
    fetchFilteredBroadcastsStatus,
    fetchBroadcastsStatus,
    filteredBroadcastFilters,
    broadcastPage,
    broadcastsData,
  } = broadcasts;
  const { data, dataStatus } = groups;
  const { currentCompany } = companies;
  const {
    deleteMessageScheduleMessageId,
    deleteMessageStatus,
    sendScheduledNowStatus,
    stopScheduledNowStatus,
  } = messages;

  const { tableRef, scrollWidth, offsetWidth } = useWithTableWidth(
    fetchFilteredBroadcastsStatus === "success"
  );

  const setGroupDataForEditBroadcastAndRedirectToCompose = useCallback(
    (groupData) => {
      setExistingGroupId(groupData?.id);
      addGroupsForMessage([groupData]);
      fetchMembers(
        currentCompany?.id,
        groupData?.id,
        20,
        1,
        null,
        changePeopleSortKeys("firstNameAsc")
      );
      history.push("/send-new-message", {
        dataForEditScheduleMsg: broadcastDataForEdit,
      });
    },
    [
      addGroupsForMessage,
      currentCompany,
      fetchMembers,
      history,
      setExistingGroupId,
      broadcastDataForEdit,
    ]
  );

  useEffect(() => {
    if (selectedFilters && isNotAnEmptyArray(selectedFilters)) {
      if (
        selectedFilters?.includes("broadcast-text") &&
        selectedFilters?.includes("broadcast-voice")
      ) {
        setColumnSelectionState((prevState) => ({
          ...prevState,
          showOptedOut: true,
          showDoNotCall: true,
        }));
      } else if (selectedFilters?.includes("broadcast-text")) {
        setColumnSelectionState((prevState) => ({
          ...prevState,
          showOptedOut: true,
        }));
      } else if (selectedFilters?.includes("broadcast-voice")) {
        setColumnSelectionState((prevState) => ({
          ...prevState,
          showDoNotCall: true,
        }));
      }
    } else {
      setColumnSelectionState(defaultColumnSelectionState);
    }
  }, [selectedFilters]);

  useEffect(() => {
    // Clear stop send schedule now status
    if (stopScheduledNowStatus === "success") {
      clearStopSendScheduleNowStatus();
      setStopScheduledNowData(null);
      fetchFilteredBroadcasts(currentCompany?.id, 1, "", "", []);
    }
  }, [
    clearStopSendScheduleNowStatus,
    stopScheduledNowStatus,
    fetchFilteredBroadcasts,
    currentCompany,
  ]);

  useEffect(() => {
    // Clear send now schedule message status
    if (sendScheduledNowStatus === "success") {
      clearSendNowScheduleMessage();
      setSendScheduledNowData(null);
      fetchFilteredBroadcasts(currentCompany?.id, 1, "", "", []);
    }
  }, [
    clearSendNowScheduleMessage,
    sendScheduledNowStatus,
    fetchFilteredBroadcasts,
    currentCompany,
  ]);

  useEffect(() => {
    // Clear delete message status
    if (
      deleteMessageStatus === "success" &&
      deleteMessageScheduleMessageId === deleteMessageData?.scheduleMessageId
    ) {
      clearDeleteMessage();
      addInfoSnackBar({
        msg: `Schedule message has been deleted successfully!`,
      });
      setDeleteMessageData(null);
      fetchBroadcastsCount(currentCompany?.id, []);
    }
  }, [
    addInfoSnackBar,
    clearDeleteMessage,
    deleteMessageData,
    deleteMessageScheduleMessageId,
    deleteMessageStatus,
    fetchBroadcastsCount,
    currentCompany,
  ]);

  useEffect(() => {
    // Fetch initial broadcasts and couunts
    fetchFilteredBroadcasts(currentCompany?.id, 1, "", "", []);
    fetchBroadcastsCount(currentCompany?.id, []);
    clearDeleteMessage();
    clearStopSendScheduleNowStatus();
    clearSendNowScheduleMessage();
  }, [
    currentCompany,
    fetchFilteredBroadcasts,
    fetchBroadcastsCount,
    clearDeleteMessage,
    clearStopSendScheduleNowStatus,
    clearSendNowScheduleMessage,
  ]);

  useEffect(() => {
    if (!isEmptyObject(broadcastDataForEdit)) {
      if (
        broadcastDataForEdit?.group?.id &&
        !isEmptyObject(broadcastDataForEdit?.schedule_message)
      ) {
        if (broadcastDataForEdit?.voice_message) {
          history.push(
            `/hub/messages/filter/scheduled-groups/group/${broadcastDataForEdit?.group?.id}#modal-edit-voice-broadcast/${broadcastDataForEdit?.schedule_message?.id}`
          );
        } else {
          if (
            !isEmptyObject(data) &&
            data[broadcastDataForEdit?.group?.id] &&
            !isEmptyObject(dataStatus) &&
            dataStatus[broadcastDataForEdit?.group?.id] === "success"
          ) {
            setGroupDataForEditBroadcastAndRedirectToCompose(
              data[broadcastDataForEdit?.group?.id]
            );
            return;
          } else {
            fetchGroupData(currentCompany?.id, broadcastDataForEdit?.group?.id);
          }
        }
      }
    }
  }, [
    broadcastDataForEdit,
    currentCompany,
    data,
    dataStatus,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(data),
    fetchGroupData,
    history,
    setGroupDataForEditBroadcastAndRedirectToCompose,
  ]);

  useEffect(() => {
    if (
      (fetchFilteredBroadcastsStatus === "success" ||
        fetchBroadcastsStatus === "success") &&
      isLoadingForSort
    ) {
      setIsLoadingForSort(false);
    }
  }, [fetchBroadcastsStatus, fetchFilteredBroadcastsStatus, isLoadingForSort]);

  useEffect(() => {
    if (scrollToTop) {
      setTimeout(() => setScrollToTop(false), [1000]);
    }
  }, [scrollToTop]);

  useEffect(() => {
    if (!arraysEqual(filteredBroadcastFilters, selectedFilters)) {
      setScrollToTop(true);
      const delayDebounceFn = setTimeout(() => {
        fetchFilteredBroadcasts(
          currentCompany?.id,
          1,
          joinKeyValueAndJoinResultOf(orderBy),
          searchValue.trim(),
          selectedFilters.length ? selectedFilters : []
        );

        fetchBroadcastsCount(
          currentCompany?.id,
          selectedFilters.length ? selectedFilters : []
        );
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    currentCompany,
    fetchBroadcastsCount,
    fetchFilteredBroadcasts,
    filteredBroadcastFilters,
    orderBy,
    searchValue,
    selectedFilters,
  ]);

  const loadMore = useCallback(() => {
    if (
      fetchBroadcastsStatus !== "loading" &&
      fetchFilteredBroadcastsStatus !== "loading" &&
      !isLoadingForSort
    ) {
      fetchFilteredBroadcasts(
        currentCompany?.id,
        broadcastPage + 1,
        joinKeyValueAndJoinResultOf(orderBy),
        searchValue.trim(),
        selectedFilters
      );
    }
  }, [
    broadcastPage,
    currentCompany,
    fetchBroadcastsStatus,
    fetchFilteredBroadcasts,
    fetchFilteredBroadcastsStatus,
    isLoadingForSort,
    orderBy,
    searchValue,
    selectedFilters,
  ]);

  const onChangeFilterSelection = useCallback((type) => {
    setColumnSelectionState((oldState) => ({
      ...oldState,
      [type]: !oldState[type],
    }));
  }, []);

  const getColumnSelectionFields = useCallback(
    (isForTableHeader) => {
      return [
        {
          text: "Broadcast Name",
          stateProp: columnSelectionState.showBroadcastName,
          nameStateProp: isForTableHeader ? undefined : "showFirstName",
          sortName: !isForTableHeader ? undefined : "broadcast_name",
        },
        {
          text: "Group",
          stateProp: columnSelectionState.showGroups,
          nameStateProp: isForTableHeader ? undefined : "showLastName",
          sortName: !isForTableHeader ? undefined : "group_name",
        },
        {
          text: "Created at",
          stateProp: columnSelectionState.showCreatedAt,
          nameStateProp: isForTableHeader ? undefined : "created",
          sortName: !isForTableHeader ? undefined : "created",
        },
        {
          text: "Send On",
          stateProp: columnSelectionState.showSendOn,
          nameStateProp: isForTableHeader ? undefined : "status",
          sortName: !isForTableHeader ? undefined : "send_on",
        },
        {
          text: "Recipients",
          stateProp: columnSelectionState.showRecipients,
          nameStateProp: isForTableHeader ? undefined : "recipients",
          sortName: !isForTableHeader ? undefined : "recipients_total",
        },
        {
          text: "Successfull",
          stateProp: columnSelectionState.showSuccessfull,
          nameStateProp: isForTableHeader ? undefined : "successfull",
          sortName: !isForTableHeader ? undefined : "recipients_success",
        },
        {
          text: "Failed",
          stateProp: columnSelectionState.showFailed,
          nameStateProp: isForTableHeader ? undefined : "failed",
          sortName: !isForTableHeader ? undefined : "recipients_failed",
        },
        {
          text: "Credits",
          stateProp: columnSelectionState.showCredits,
          nameStateProp: isForTableHeader ? undefined : "credits",
          sortName: !isForTableHeader ? undefined : "credits",
        },
        {
          text: "Opted Out",
          stateProp: columnSelectionState.showOptedOut,
          nameStateProp: "showOptedOut",
          showSortArrows: false,
        },
        {
          text: "Do Not Call",
          stateProp: columnSelectionState.showDoNotCall,
          nameStateProp: "showDoNotCall",
          showSortArrows: false,
        },
        {
          text: "Unformatted",
          stateProp: columnSelectionState.showUnformatted,
          nameStateProp: "showUnformatted",
          showSortArrows: false,
        },
        {
          text: "Duplicates",
          stateProp: columnSelectionState.showDuplicates,
          nameStateProp: "showDuplicates",
          showSortArrows: true,
          sortName: !isForTableHeader ? undefined : "duplicates",
        },
        {
          text: "Landlines",
          stateProp: columnSelectionState.showLandlines,
          nameStateProp: "showLandlines",
          showSortArrows: true,
          sortName: !isForTableHeader ? undefined : "landlines",
        },
        {
          text: "From",
          stateProp: columnSelectionState.showFrom,
          nameStateProp: "showFrom",
          showSortArrows: true,
          sortName: !isForTableHeader ? undefined : "from_number",
        },
        {
          text: "Link Clicks",
          stateProp: columnSelectionState.showLinkClicks,
          nameStateProp: "showLinkClicks",
          showSortArrows: false,
        },
      ];
    },
    [columnSelectionState]
  );

  const searchFetchFunction = useCallback(() => {
    if (searchValue) {
      setScrollToTop(true);
      fetchFilteredBroadcasts(
        currentCompany?.id,
        1,
        joinKeyValueAndJoinResultOf(orderBy),
        searchValue?.trim(),
        selectedFilters
      );
    }
  }, [
    currentCompany,
    fetchFilteredBroadcasts,
    orderBy,
    searchValue,
    selectedFilters,
  ]);

  const fetchOnCancel = useCallback(() => {
    setScrollToTop(true);
    fetchFilteredBroadcasts(
      currentCompany?.id,
      1,
      joinKeyValueAndJoinResultOf(orderBy),
      "",
      selectedFilters
    );
  }, [currentCompany, fetchFilteredBroadcasts, orderBy, selectedFilters]);

  const onSort = useCallback(
    (columnToSort, sortType) => {
      const orderType = mapSortTypeToApiType(sortType);

      setOrderBy({ [columnToSort]: orderType });
      setIsLoadingForSort(true);

      fetchFilteredBroadcasts(
        currentCompany?.id,
        1,
        joinKeyValueAndJoinResultOf({ [columnToSort]: orderType }),
        searchValue?.trim(),
        selectedFilters
      );
    },
    [currentCompany, fetchFilteredBroadcasts, searchValue, selectedFilters]
  );

  const onChangeSearchValue = useCallback((event) => {
    setSearchValue(event.target.value || "");
  }, []);

  const handleSelectChange = useCallback(
    (event) => {
      const { value } = event?.target;
      if (selectedFilters?.includes("broadcast-text")) {
        setSelectedFilters(["broadcast-text", value]);
      } else {
        setSelectedFilters([value]);
      }
    },
    [selectedFilters]
  );

  const handleCloseScheduledMessageSummaryModal = useCallback(() => {
    setDataForScheduledMessageSummaryModal({});
  }, []);

  const selectionFields = [
    {
      text: "Broadcast Name",
      stateProp: columnSelectionState.showBroadcastName,
      nameStateProp: "showBroadcastName",
      sortName: "broadcast_name",
    },
    {
      text: "Group",
      stateProp: columnSelectionState.showGroups,
      nameStateProp: "showGroups",
      sortName: "group",
    },
    {
      text: "Created at",
      stateProp: columnSelectionState.showCreatedAt,
      nameStateProp: "showCreatedAt",
      sortName: "created",
    },
    {
      text: "Send On",
      stateProp: columnSelectionState.showSendOn,
      nameStateProp: "showSendOn",
      showSortArrows: false,
    },
    {
      text: "Recipients",
      stateProp: columnSelectionState.showRecipients,
      nameStateProp: "showRecipients",
      showSortArrows: false,
    },
    {
      text: "Successfull",
      stateProp: columnSelectionState.showSuccessfull,
      nameStateProp: "showSuccessfull",
      showSortArrows: false,
    },
    {
      text: "Failed",
      stateProp: columnSelectionState.showFailed,
      nameStateProp: "showFailed",
      showSortArrows: false,
    },
    {
      text: "Credits",
      stateProp: columnSelectionState.showCredits,
      nameStateProp: "showCredits",
      showSortArrows: false,
    },
    {
      text: "Opted Out",
      stateProp: columnSelectionState.showOptedOut,
      nameStateProp: "showOptedOut",
      showSortArrows: false,
    },
    {
      text: "Do Not Call",
      stateProp: columnSelectionState.showDoNotCall,
      nameStateProp: "showDoNotCall",
      showSortArrows: false,
    },
    {
      text: "Unformatted",
      stateProp: columnSelectionState.showUnformatted,
      nameStateProp: "showUnformatted",
      showSortArrows: false,
    },
    {
      text: "Duplicates",
      stateProp: columnSelectionState.showDuplicates,
      nameStateProp: "showDuplicates",
      showSortArrows: false,
    },
    {
      text: "Landlines",
      stateProp: columnSelectionState.showLandlines,
      nameStateProp: "showLandlines",
      showSortArrows: false,
    },
    {
      text: "From",
      stateProp: columnSelectionState.showFrom,
      nameStateProp: "showFrom",
      showSortArrows: false,
    },
    {
      text: "Link Clicks",
      stateProp: columnSelectionState.showLinkClicks,
      nameStateProp: "showLinkClicks",
      showSortArrows: false,
    },
  ];

  const handleEditScheduleMessage = useCallback(
    (member) => () => {
      setBroadcastDataForEdit(member);
    },
    []
  );

  const deleteMessageConfirmed = useCallback(() => {
    const { logId, groupSmsBlastId, scheduleMessageId } = deleteMessageData;
    deleteMessage(
      currentCompany?.id,
      logId,
      groupSmsBlastId,
      scheduleMessageId
    );
  }, [currentCompany, deleteMessage, deleteMessageData]);

  const deleteMessageCancel = useCallback(() => {
    setDeleteMessageData(null);
  }, [setDeleteMessageData]);

  const handleDeleteScheduleMessage = useCallback(
    (memberData) => () => {
      setDeleteMessageData({
        logId: null,
        groupSmsBlastId: memberData?.group?.id,
        scheduleMessageId: memberData?.schedule_message?.id,
      });
      setDataForScheduledMessageSummaryModal({});
    },
    []
  );

  const sendScheduledNowConfirmed = useCallback(() => {
    const id = sendScheduledNowData?.schedule_message?.id || null;
    sendScheduledNow(currentCompany?.id, id);
  }, [currentCompany, sendScheduledNow, sendScheduledNowData]);

  const sendScheduledNowCancel = useCallback(() => {
    setSendScheduledNowData(null);
  }, [setSendScheduledNowData]);

  const handleSendNowSceduleMessage = useCallback(
    (memberData) => () => {
      setSendScheduledNowData(memberData);
      setDataForScheduledMessageSummaryModal({});
    },
    []
  );

  const handleStopNowScheduleMessage = useCallback(
    (memberData) => () => {
      setStopScheduledNowData(memberData);
    },
    []
  );

  const stopScheduledNowConfirmed = useCallback(() => {
    const id = stopScheduledNowData?.schedule_message?.id || null;
    stopScheduledNow(currentCompany?.id, id);
  }, [currentCompany, stopScheduledNow, stopScheduledNowData]);

  const stopScheduledNowCancel = useCallback(() => {
    setStopScheduledNowData(null);
  }, [setStopScheduledNowData]);

  const onChangeColumSelection = useCallback((type) => {
    setColumnSelectionState((oldState) => ({
      ...oldState,
      [type]: !oldState[type],
    }));
  }, []);

  const defaultSortToColumnToSort = (defaultSort) => {
    if (!defaultSort) return "";
    if (defaultSort.startsWith("showCreatedAt")) return "created";
    return "";
  };

  const defaultSortToColumnSortType = (defaultSort) => {
    switch (defaultSort) {
      case "showCreatedAtAsc":
        return "up";
      case "showCreatedAtDesc":
        return "down";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="container-flex-column-100">
          <BroadcastFilterPanel
            searchValue={searchValue}
            columnSelectionState={selectionFields}
            onChangeFilterSelection={onChangeFilterSelection}
            onChangeSearchValue={onChangeSearchValue}
            fetchOnCancel={fetchOnCancel}
            selectedFilters={selectedFilters}
            handleSelectChange={handleSelectChange}
            setSelectedFilters={setSelectedFilters}
          />
          <TableInfinityScroll
            tableRef={tableRef}
            loadMore={loadMore}
            page={broadcastPage}
            searchValue={searchValue}
            companyId={currentCompany?.id}
            fetchFunction={searchFetchFunction}
            scrollToTop={isLoadingForSort || scrollToTop}
            setScrollLeftPosition={setScrollLeftPosition}
            style={{
              position: "absolute",
              height: "-webkit-fill-available",
            }}
          >
            {fetchBroadcastsStatus === "loading" && !isLoadingForSort ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            ) : (
              <table className="table">
                <TableHeader
                  hideCheckBoxCell
                  onSort={onSort}
                  cells={getColumnSelectionFields(true)}
                  onChangeColumSelection={onChangeColumSelection}
                  outsideColumnToSort={defaultSortToColumnToSort(
                    "showCreatedAt"
                  )}
                  outsideSortType={defaultSortToColumnSortType(
                    "showCreatedAtDesc"
                  )}
                />
                <BroadcastTableBody
                  isLoading={
                    isLoadingForSort ||
                    (searchValue !== "" &&
                      fetchFilteredBroadcastsStatus === "loading")
                  }
                  columnSelectionState={columnSelectionState}
                  broadcasts={broadcastsData}
                  isLoadingMore={fetchFilteredBroadcastsStatus === "loading"}
                  menuRightPosition={
                    scrollWidth - offsetWidth - scrollLeftPosition
                  }
                  setDataForScheduledMessageSummaryModal={
                    setDataForScheduledMessageSummaryModal
                  }
                  handleEditScheduleMessage={handleEditScheduleMessage}
                  handleDeleteScheduleMessage={handleDeleteScheduleMessage}
                  handleSendNowSceduleMessage={handleSendNowSceduleMessage}
                  handleStopNowScheduleMessage={handleStopNowScheduleMessage}
                />
              </table>
            )}
          </TableInfinityScroll>
        </div>
      </div>
      {!isEmptyObject(dataForScheduledMessageSummaryModal) && (
        <ScheduledMessageSummaryModal
          show={!isEmptyObject(dataForScheduledMessageSummaryModal)}
          closeModal={handleCloseScheduledMessageSummaryModal}
          data={dataForScheduledMessageSummaryModal}
          handleEditScheduleMessage={handleEditScheduleMessage}
          handleDeleteScheduleMessage={handleDeleteScheduleMessage}
          handleSendNowSceduleMessage={handleSendNowSceduleMessage}
        />
      )}
      {deleteMessageData && (
        <DeleteMessageConfirmationModal
          deleteMessageConfirmed={deleteMessageConfirmed}
          deleteMessageCancel={deleteMessageCancel}
          loading={deleteMessageStatus === "loading"}
        />
      )}

      {sendScheduledNowData && (
        <SendScheduleMessageConfirmationModal
          sendScheduledNowConfirmed={sendScheduledNowConfirmed}
          sendScheduledNowCancel={sendScheduledNowCancel}
          loading={sendScheduledNowStatus === "loading"}
        />
      )}

      {stopScheduledNowData && (
        <StopNowScheduleMessageConfirmationModal
          stopScheduledNowConfirmed={stopScheduledNowConfirmed}
          stopScheduledNowCancel={stopScheduledNowCancel}
          loading={stopScheduledNowStatus === "loading"}
        />
      )}
    </>
  );
};

export default BroadcastTableView;
