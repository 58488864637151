const initialState = {
  buildNumber: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "VERSION/SET_BUILD_NUMBER": {
      return { ...state, buildNumber: action.payload };
    }
    default: {
      return state;
    }
  }
}
