export const areaCodes = {
  1: {
    226: [
      205, 251, 256, 334, 659, 938, 907, 480, 520, 602, 623, 928, 327, 479, 501,
      870, 209, 213, 310, 323, 341, 369, 408, 415, 424, 442, 510, 530, 559, 562,
      619, 626, 627, 628, 650, 657, 661, 669, 707, 714, 747, 760, 764, 805, 818,
      831, 858, 909, 916, 925, 935, 949, 951, 303, 719, 720, 970, 203, 475, 860,
      959, 302, 202, 239, 305, 321, 352, 386, 407, 561, 689, 727, 754, 772, 786,
      813, 850, 863, 904, 927, 941, 954, 229, 404, 470, 478, 678, 706, 762, 770,
      912, 808, 208, 217, 224, 309, 312, 331, 447, 464, 618, 630, 708, 730, 773,
      779, 815, 847, 872, 219, 260, 317, 574, 765, 812, 930, 319, 515, 563, 641,
      712, 316, 620, 785, 913, 270, 364, 502, 606, 859, 225, 318, 337, 504, 985,
      207, 227, 240, 301, 410, 443, 667, 339, 351, 413, 508, 617, 774, 781, 857,
      978, 231, 248, 269, 278, 313, 517, 586, 616, 679, 734, 810, 906, 947, 989,
      218, 320, 507, 612, 651, 763, 952, 228, 601, 662, 769, 314, 417, 557, 573,
      636, 660, 816, 975, 406, 308, 402, 531, 702, 725, 775, 603, 201, 551, 609,
      732, 848, 856, 862, 908, 973, 505, 575, 212, 315, 347, 516, 518, 585, 607,
      631, 646, 716, 718, 845, 914, 917, 929, 252, 336, 704, 828, 910, 919, 980,
      984, 701, 216, 220, 234, 283, 330, 380, 419, 440, 513, 567, 614, 740, 937,
      405, 539, 580, 918, 458, 503, 541, 971, 215, 267, 272, 412, 484, 570, 582,
      610, 717, 724, 814, 878, 401, 803, 843, 854, 864, 605, 423, 615, 629, 731,
      865, 901, 931, 210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512,
      682, 713, 737, 806, 817, 830, 832, 903, 915, 936, 940, 956, 972, 979, 385,
      435, 801, 802, 276, 434, 540, 571, 703, 757, 804, 206, 253, 360, 425, 509,
      564, 304, 681, 262, 274, 414, 534, 608, 715, 920, 307,
    ],
    240: [
      403, 587, 780, 825, 236, 250, 604, 672, 778, 204, 431, 506, 709, 782, 902,
      548, 249, 289, 343, 365, 387, 416, 437, 519, 613, 647, 705, 742, 807, 905,
      782, 902, 418, 438, 450, 514, 579, 581, 819, 873, 306, 639, 867,
    ],
    173: [787, 939],
  },
};
