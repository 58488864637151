import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Scrollbars from "react-custom-scrollbars";
import VerticalThumbDarkTheme from "../../elements/VerticalThumbDarkTheme";
import HorizontalScrollbarThumb from "../../elements/HorizontalScrollbarThumb";

const TableInfinityScroll = (props) => {
  const {
    children,
    loadMore,
    page,
    searchValue,
    fetchFunction,
    className,
    scrollToTop,
    tableRef,
    setScrollLeftPosition,
    style,
  } = props;

  const [milestoneScrollHeight, setMilestoneScrollHeight] = useState(0);

  const prevSearchValue = useRef("");
  const prevPage = useRef();

  const tableWrapperRefInner = useRef();

  const loadMoreTimer = useRef();
  const scrollLeftTimeout = useRef();

  const onScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft } =
      tableWrapperRefInner.current.view;

    clearTimeout(scrollLeftTimeout.current);

    scrollLeftTimeout.current = setTimeout(
      () => setScrollLeftPosition(scrollLeft),
      150
    );
    const pad = 100;
    const t = scrollTop + clientHeight + pad;
    if (t > scrollHeight && scrollHeight > milestoneScrollHeight) {
      clearTimeout(loadMoreTimer.current);
      setMilestoneScrollHeight(scrollHeight);
      if (loadMore != null) {
        loadMoreTimer.current = setTimeout(loadMore, 100);
      }
    }
  }, [
    loadMore,
    milestoneScrollHeight,
    setScrollLeftPosition,
    tableWrapperRefInner,
  ]);

  const handleKeyPress = (k) => {
    const currentScroll = window.$(".scroll-table").scrollLeft();
    if (k.key === "ArrowLeft") {
      window.$(".scroll-table").scrollLeft(currentScroll - 20);
    } else if (k.key === "ArrowRight") {
      window.$(".scroll-table").scrollLeft(currentScroll + 20);
    }
  };

  useEffect(() => {
    const keyPressCallback = (k) => handleKeyPress(k);
    document.addEventListener("keydown", keyPressCallback);

    return () => document.removeEventListener("keydown", keyPressCallback);
  }, []);

  useEffect(() => {
    let intervalId;
    let customGroupsPage = page;

    if (searchValue !== prevSearchValue.current) {
      prevPage.current = 0;
      customGroupsPage = null;
    }

    if (
      searchValue !== prevSearchValue.current &&
      prevPage.current !== customGroupsPage
    ) {
      prevPage.current = customGroupsPage ? customGroupsPage : 1;
      prevSearchValue.current = searchValue;

      intervalId = setTimeout(() => {
        fetchFunction(prevPage.current, searchValue);
      }, 400);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchFunction, page, searchValue]);

  const handleScrollToTop = useCallback(
    (params) => {
      tableWrapperRefInner.current.view.scrollTo(0, 0);
      setMilestoneScrollHeight(0);
    },
    [tableWrapperRefInner]
  );

  useEffect(() => {
    if (scrollToTop) {
      handleScrollToTop();
    }
  }, [handleScrollToTop, scrollToTop]);

  return (
    <div className={`scroll-table flex-grow-1 ${className}`}>
      <Scrollbars
        onScroll={onScroll}
        className="custom-scrollbars"
        hideTracksWhenNotNeeded
        style={{ height: "100%", ...style }}
        ref={tableWrapperRefInner}
        renderThumbVertical={VerticalThumbDarkTheme}
        renderThumbHorizontal={HorizontalScrollbarThumb}
      >
        <div className="h-100" ref={tableRef}>
          {children}
        </div>
      </Scrollbars>
    </div>
  );
};

TableInfinityScroll.propTypes = {
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func,
  page: PropTypes.number,
  searchValue: PropTypes.string,
  fetchFunction: PropTypes.func,
  className: PropTypes.string,
  scrollToTop: PropTypes.bool,
  tableRef: PropTypes.any,
  setScrollLeftPosition: PropTypes.func,
  style: PropTypes.object,
};

TableInfinityScroll.defaultProps = {
  className: "",
  style: {},
  setScrollLeftPosition: () => {},
  fetchFunction: () => {},
};

export default TableInfinityScroll;
