import React, { useCallback } from "react";
import PropTypes from "prop-types";

const TableHeaderCell = (props) => {
  const {
    onClickSort,
    text,
    style,
    showSortArrows,
    className,
    isFlex,
    sortType,
  } = props;

  const onClickCell = useCallback(() => {
    if (!showSortArrows) return;
    if (sortType === "default") {
      onClickSort("down");
    } else if (sortType === "down") {
      onClickSort("up");
    } else if (sortType === "up") {
      onClickSort("default");
    }
  }, [onClickSort, showSortArrows, sortType]);

  return (
    <th onClick={onClickCell} style={style} className={className}>
      <div
        className={`${
          isFlex ? "d-flex flex-nowrap" : ""
        } align-items-center text-nowrap`}
      >
        {showSortArrows && (
          <div className="position-relative" style={{ width: "1.28571em" }}>
            <i
              className="fa fa-fw fa-sort-up"
              style={{
                color: sortType === "up" ? "#707070" : "#C9CECD",
                position: "absolute",
                top: 3,
              }}
            />
            <i
              className="fa fa-fw fa-sort-down"
              style={{
                color: sortType === "down" ? "#707070" : "#C9CECD",
              }}
            />
          </div>
        )}
        <span>{text}</span>
      </div>
    </th>
  );
};

TableHeaderCell.propTypes = {
  onClickSort: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  showSortArrows: PropTypes.bool,
  className: PropTypes.string,
  isFlex: PropTypes.bool,
  sortType: PropTypes.oneOf(["default", "up", "down"]),
};

TableHeaderCell.defaultProps = {
  style: {},
  showSortArrows: true,
  className: "",
  isFlex: true,
};

export default TableHeaderCell;
