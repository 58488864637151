import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  thumb: {
    width: 15,
    height: 15,
  },
  track: {
    width: 40,
    height: 20,
    backgroundColor: "#D9D9D9",
    border: "1px solid #C9CECD",
    borderRadius: 10,
  },
  root: {
    height: "auto",
    padding: 0,
    width: 40,
  },
  switchBase: {
    padding: 0,
    top: "2px",
    left: "2px",
    color: "#8E8E8E",
  },
  colorSecondary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#5517c1",
    },
    "&&&.Mui-disabled": {
      background: "#BDBDBD",
      color: "#BDBDBD",
    },
    "&.Mui-checked": {
      background: "#5517c1",
      color: "#5517c1",
    },
  },
}));

const CustomSwitch = (props) => {
  const { checked, onChange, disabled } = props;

  const classes = useStyles();

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      classes={{
        root: classes.root,
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
        colorSecondary: classes.colorSecondary,
      }}
    />
  );
};

CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CustomSwitch;
