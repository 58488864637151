import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { fetchCompanies } from "../../actions/companies";
import { fetchUserData } from "../../actions/users";
import AppLoading from "../../components/AppLoading";
import NotFoundPage from "../NotFoundPage";
import AdminSettingBillingAddons from "./AdminSettingBillingAddons";
import AdminSettingIntegrationFreeApp from "./AdminSettingIntegrationFreeApp";
import AdminSettingIntegrations from "./AdminSettingIntegrations";
import AdminSettingsBillingSubscriptionChoices from "./AdminSettingsBillingSubscriptionChoices";
import AnalyticsBroadcastStats from "./AnalyticsBroadcastStats";
import AnalyticsGroupMsgStats from "./AnalyticsGroupMsgStats";
import MessagesContact from "./MessagesContact";
import MessagesUndelivered from "./MessagesUndelivered";
import NumbersHostedBridge from "./NumbersHostedBridge";
import ResetPassword from "./ResetPassword";
import NotificationSettings from "./NotificationSettings";
import SettingsBilling from "./SettingsBilling";
import PeopleSettings from "./PeopleSettings";
import SmartSyncGroup from "./SmartSyncGroup";
import ContactMessages from "./ContactMessages";
import GroupSettings from "./GroupSettings";
import GroupIntegrations from "./GroupIntegrations";
import AddHostedNumber from "./AddHostedNumber";
import AutomationCampaignView from "./AutomationCampaignView";
import AutomationCampaignEdit from "./AutomationCampaignEdit";

function mapStateToProps(store) {
  return {
    loggedUserStatus: store.users.loggedUserStatus,
    loggedUser: store.users.loggedUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserData: () => dispatch(fetchUserData()),
    fetchCompanies: () => dispatch(fetchCompanies()),
  };
}

const EmailLink = (props) => {
  const {
    // Redux props
    loggedUserStatus,
    loggedUser,
    // Redux func
    fetchUserData,
    fetchCompanies,
    // Router
    match,
  } = props;

  const { companyId } = match.params;

  useEffect(() => {
    fetchUserData();
    fetchCompanies();
  }, [fetchCompanies, fetchUserData]);

  if (loggedUserStatus === "loading") return <AppLoading />;

  if (loggedUser && Boolean(loggedUser.roles[companyId]) === false)
    return <NotFoundPage />;

  return (
    <>
      <Switch>
        {/* Settings route */}
        <Route
          exact
          path="/email-link/:companyId/settings/billing"
          component={SettingsBilling}
        />
        <Route
          exact
          path="/email-link/:companyId/settings/billing/addons"
          component={AdminSettingBillingAddons}
        />
        <Route
          exact
          path="/email-link/:companyId/settings/billing/subscription-choices"
          component={AdminSettingsBillingSubscriptionChoices}
        />
        <Route
          exact
          path="/email-link/:companyId/settings/integrations"
          component={AdminSettingIntegrations}
        />
        <Route
          exact
          path="/email-link/:companyId/settings/integrations/free"
          component={AdminSettingIntegrationFreeApp}
        />
        <Route
          exact
          path="/email-link/:companyId/settings/notifications"
          component={NotificationSettings}
        />

        {/* Messages route */}
        <Route
          exact
          path="/email-link/:companyId/messages/undelivered"
          component={MessagesUndelivered}
        />
        <Route
          exact
          path="/email-link/:companyId/messages/contact/:contactId"
          component={MessagesContact}
        />

        {/* Numbers route */}
        <Route
          exact
          path="/email-link/:companyId/numbers/hosted-bridge/:userNumberId"
          component={NumbersHostedBridge}
        />
        <Route
          exact
          path="/email-link/:companyId/numbers/add-hosted"
          component={AddHostedNumber}
        />

        {/* People routes */}
        <Route
          exact
          path="/email-link/:companyId/people"
          component={PeopleSettings}
        />
        <Route
          exact
          path="/email-link/:companyId/people/all/:contactId/messages"
          component={ContactMessages}
        />

        {/* Groups route */}
        <Route
          exact
          path="/email-link/:companyId/groups"
          component={GroupSettings}
        />
        <Route
          exact
          path="/email-link/:companyId/groups/all/:groupId/integrations"
          component={GroupIntegrations}
        />

        {/* Campaigns route */}
        <Route
          exact
          path="/email-link/:companyId/campaigns/automation/:campaignId"
          component={AutomationCampaignView}
        />
        <Route
          exact
          path="/email-link/:companyId/campaigns/automation/:campaignId/edit"
          component={AutomationCampaignEdit}
        />

        {/* Analytics route */}
        <Route
          exact
          path="/email-link/:companyId/analytics/group-msg-stats/:id"
          component={AnalyticsGroupMsgStats}
        />
        <Route
          exact
          path="/email-link/:companyId/analytics/broadcast-stats/:id"
          component={AnalyticsBroadcastStats}
        />

        {/* Other route */}
        <Route
          exact
          path="/email-link/:companyId/smart-sync/group/:groupId"
          component={SmartSyncGroup}
        />
        <Route
          exact
          path="/email-link/:companyId/account/reset-password/:hash"
          component={ResetPassword}
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailLink)
);
