import { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

export const useWithTableWidth = (isDataLoaded) => {
  const { width, height, ref: tableRef } = useResizeDetector();
  const [scrollWidth, setScrollWidth] = useState(0);
  const [offsetWidth, setOffsetWidth] = useState(0);

  useEffect(() => {
    let timeout;
    if (tableRef.current && isDataLoaded) {
      timeout = setTimeout(() => {
        setScrollWidth(tableRef.current?.scrollWidth);
        setOffsetWidth(tableRef.current?.offsetWidth);
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [width, height, isDataLoaded, tableRef]);

  return {
    tableRef,
    scrollWidth,
    offsetWidth,
  };
};
