/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/09/2018.
 */
import { DISPLAY } from "../components/modals/DisplayPreference/displayPreferenceFilters";
import { isEmptyObject } from "../helpers";

const initialState = {
  v1LoginStatus: null,
  v3LoginStatus: null,
  v3LoginErrorMessage: null,
  v3LoginData: null,
  v3LoginError: null,
  v3RedirectsTo: null,
  forgotPasswordError: null,
  forgotPasswordData: null,
  forgotPasswordStatus: null,
  loginStatus: null,
  loginError: null,
  loggedUser: null,
  loggedUserStatus: null,
  loggedUserError: null,
  currentCompany: null,
  credits: null,
  creditsStatus: null,
  creditsError: null,
  detailsFullView: true,
  settings: null,
  creditsSummary: null,
  // Fetch user Settings
  fetchSettingsStatus: null,
  fetchSettingsError: null,

  // update settings
  updateUserSettingsStatus: "",
  updateUserSettingsRequestId: "",

  // register user
  registerUserStatus: null,
  registeredUserEmail: null,
  registerUserError: null,

  // Logout state
  logoutStatus: null,
  redirectingToLegacy: false,

  //Biometric state
  optInBiometric: null,

  // Show start legacy session
  showLegacyStartSession: true,

  // CF challanege overlay
  showChallengeOverlay: null,

  // Customized menu
  customizeMenusAndFiltersStatus: null,

  // DISPLAY PREFERENCE FILTER
  displayPreferenceActiveFilter: DISPLAY,
};

export default function reducer(state = initialState, action) {
  let loggedUser = null;

  switch (action.type) {
    case "USERS/V1_LOGIN":
      return {
        ...state,
        v1LoginStatus: "loading",
      };
    case "USERS/V1_LOGIN.SUCCESS":
      return {
        ...state,
        v1LoginStatus: "success",
      };
    case "USERS/V1_LOGIN.ERROR":
      return {
        ...state,
        v1LoginStatus: "error",
      };

    case "USERS/V3_LOGIN":
      return {
        ...state,
        v3LoginStatus: "loading",
        v3RedirectsTo: null,
        v3LoginData: null,
        v3LoginError: null,
        v3LoginErrorMessage: null,
      };
    case "USERS/V3_LOGIN.SUCCESS": {
      if (action.payload?.redirect_to) {
        return {
          ...state,
          v3LoginStatus: action?.payload?.error_message ? "error" : "success",
          v3RedirectsTo: action.payload.redirect_to,
          v3LoginErrorMessage: action?.payload?.error_message || null,
          v3LoginData: action.payload,
        };
      }
      return {
        ...state,
        v3LoginStatus: action?.payload?.error_message ? "error" : "success",
        logoutStatus: null,
        v3LoginData: action.payload,
        v3RedirectsTo: null,
        v3LoginErrorMessage: action?.payload?.error_message || null,
      };
    }
    case "USERS/V3_LOGIN.ERROR":
      return {
        ...state,
        v3LoginStatus: "error",
        v3LoginError: action?.error?.data?.message || null,
        v3RedirectsTo: null,
      };
    case "USERS/V3LOGIN.CLEAR": {
      return {
        ...state,
        v3LoginStatus: null,
        v3LoginData: null,
        v3LoginError: null,
        v3RedirectsTo: null,
        v3LoginErrorMessage: null,
      };
    }

    case "USERS/FORGOT_PASSWORD":
      return {
        ...state,
        forgotPasswordStatus: "loading",
      };
    case "USERS/FORGOT_PASSWORD.SUCCESS":
      return {
        ...state,
        forgotPasswordStatus: "success",
        forgotPasswordData: action.payload,
      };
    case "USERS/FORGOT_PASSWORD.ERROR":
      return {
        ...state,
        forgotPasswordStatus: "error",
        forgotPasswordError:
          action?.error?.data?.message ||
          "Something went wrong! Please try again.",
      };
    case "USERS/FORGOT_PASSWORD.CLEAR": {
      return {
        ...state,
        forgotPasswordData: null,
        forgotPasswordError: null,
        forgotPasswordStatus: null,
      };
    }

    case "USER_LOGOUT":
      return {
        ...initialState,
        logoutStatus: "success",
        redirectingToLegacy: action.redirectingToLegacy,
      };

    case "USER_LOGOUT.CLEAR":
      return {
        ...initialState,
        logoutStatus: null,
        redirectingToLegacy: false,
      };

    case "FETCH_USER_DATA":
      return {
        ...state,
        loggedUserStatus: "loading",
        loggedUserError: null,
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        loggedUserStatus: "success",
        loggedUserError: null,
        loggedUser: action.payload.user,
      };
    case "FETCH_USER_DATA_ERROR":
      return {
        ...state,
        loggedUserStatus: "error",
        loggedUserError: action.payload,
        loggedUser: null,
      };

    case "FETCH_CREDITS":
      return {
        ...state,
        creditsStatus: "loading",
        creditsError: null,
      };
    case "FETCH_CREDITS_SUCCESS":
      return {
        ...state,
        creditsStatus: "success",
        creditsError: null,
        credits: action.payload.credits,
        creditsSummary: action.payload,
      };
    case "FETCH_CREDITS_ERROR":
      return {
        ...state,
        creditsStatus: "error",
        creditsError: action.payload,
        credits: null,
        creditsSummary: null,
      };

    case "USERS/CLEAR_SKIP_STEP5.SUCCESS":
      loggedUser = state.loggedUser;
      loggedUser.skip_step5 = false;
      return {
        ...state,
        loggedUser,
      };

    case "rhs/TOGGLE_FILTERS":
      return {
        ...state,
        detailsFullView:
          action.viewValue !== undefined
            ? action.viewValue
            : !state.detailsFullView,
      };
    case "USER_SETTINGS/POST_MY_PROFILE.SUCCESS":
      let updatedData = {};
      if (
        action.payload?.data?.timezone &&
        typeof action.payload?.data?.timezone === "object" &&
        !isEmptyObject(action.payload?.data?.timezone)
      ) {
        updatedData = {
          ...state.loggedUser,
          ...action.payload?.data,
          ...action.payload?.data?.timezone,
        };
      } else {
        updatedData = { ...state.loggedUser, ...action.payload.data };
      }

      return {
        ...state,
        loggedUser: updatedData,
      };
    case "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE.SUCCESS":
      return {
        ...state,
        loggedUser: { ...state.loggedUser, is_phone_verified: 1 },
      };
    case "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE.SUCCESS": {
      let notifications_mode = { ...state.loggedUser.notifications_mode };
      notifications_mode[action.currentCompanyId] = action.notifications_mode;
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          notifications_mode,
        },
      };
    }
    case "USER_SETTINGS/UPDATE_AVATAR.SUCCESS": {
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          avatar: action.payload.data,
        },
      };
    }

    case "USERS/FETCH_SETTINGS": {
      return {
        ...state,
        fetchSettingsStatus: "loading",
        fetchSettingsError: null,
      };
    }
    case "USERS/FETCH_SETTINGS.SUCCESS": {
      return {
        ...state,
        settings: action.payload.data,
        fetchSettingsStatus: "success",
        fetchSettingsError: null,
      };
    }
    case "USERS/FETCH_SETTINGS.ERROR": {
      return {
        ...state,
        settings: null,
        fetchSettingsStatus: "error",
        fetchSettingsError: action.payload,
      };
    }
    case "USERS/UPDATE_SETTINGS": {
      return {
        ...state,
        updateUserSettingsStatus: "loading",
        updateUserSettingsRequestId: action.requestId,
      };
    }
    case "USERS/UPDATE_SETTINGS.SUCCESS": {
      return {
        ...state,
        settings: { ...state.settings, ...action.payload.data },
        updateUserSettingsStatus: "success",
        updateUserSettingsRequestId: action.requestId,
      };
    }
    case "USERS/UPDATE_SETTINGS.ERROR": {
      return {
        ...state,
        updateUserSettingsStatus: "error",
      };
    }
    case "USERS/UPDATE_SETTINGS.CLEAR": {
      return {
        ...state,
        updateUserSettingsStatus: "",
        updateUserSettingsRequestId: "",
      };
    }
    case "USERS/FETCH_PERMISSIONS": {
      return { ...state, permissionsStatus: "loading" };
    }
    case "USERS/FETCH_PERMISSIONS.SUCCESS": {
      return {
        ...state,
        permissions: {
          ...action.payload.data,
        },
        permissionsStatus: "success",
      };
    }
    case "USERS/FETCH_PERMISSIONS.ERROR": {
      return { ...state, permissions: null, permissionsStatus: "error" };
    }
    case "USERS/REGISTER": {
      return {
        ...state,
        registerUserStatus: "loading",
        registerUserError: null,
      };
    }
    case "USERS/REGISTER.SUCCESS": {
      return {
        ...state,
        registerUserStatus: "success",
        registeredUserEmail: action.email,
      };
    }
    case "USERS/REGISTER.ERROR": {
      return {
        ...state,
        registerUserStatus: "error",
        registerUserError: action.error.data.message,
      };
    }
    case "USERS/REGISTER.CLEAR": {
      return {
        ...state,
        registerUserStatus: null,
        registerUserError: null,
      };
    }
    case "USERS/REGISTERED_EMAIL.CLEAR": {
      return {
        ...state,
        registeredUserEmail: null,
      };
    }
    case "USERS/UPDATE_MAIN_MENU_LOOK": {
      return {
        ...state,
        settings: { ...state.settings, main_menu_look: action.mainMenuLook },
      };
    }
    case "USERS/UPDATE_BIOMETRIC_STATE": {
      return {
        ...state,
        optInBiometric: action.status.optInBiometric,
      };
    }

    case "USERS/START_LEGACY_SESSION": {
      return {
        ...state,
        showLegacyStartSession: action.show,
      };
    }

    case "USERS/UPDATE_MAIN_MENU_LOOK_ON_MESSAGES": {
      return {
        ...state,
        settings: {
          ...state.settings,
          main_menu_look_on_messages: action.mainMenuLookOnMessages,
        },
      };
    }

    case "USERS/SHOW_CF_CHALLENGE_OVERLAY": {
      return {
        ...state,
        showChallengeOverlay: action.show,
      };
    }

    case "USERS/SET_DISPLAY_PREFERENCE_FILTER": {
      return {
        ...state,
        displayPreferenceActiveFilter: action.filter,
      };
    }

    // Customize menus and filters
    case "USERS/CUSTOMIZE_MENUS_AND_FILTERS": {
      return {
        ...state,
        customizeMenusAndFiltersStatus: "loading",
      };
    }

    case "USERS/CUSTOMIZE_MENUS_AND_FILTERS.SUCCESS": {
      return {
        ...state,
        customizeMenusAndFiltersStatus: "success",
        loggedUser: { ...state.loggedUser, ...action.payload.data },
      };
    }

    case "USERS/CUSTOMIZE_MENUS_AND_FILTERS.ERROR": {
      return {
        ...state,
        customizeMenusAndFiltersStatus: "error",
      };
    }

    case "USERS/CUSTOMIZE_MENUS_AND_FILTERS.CLEAR": {
      return {
        ...state,
        customizeMenusAndFiltersStatus: null,
      };
    }

    default:
      return state;
  }
}
