import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableHeaderCell from "./TableHeaderCell";
import clsx from "clsx";

const TableHeader = (props) => {
  const {
    cells,
    onSort,
    sitemap,
    hideCheckBoxCell,
    className,
    firstCell,
    additionalEndCells,
    outsideColumnToSort,
    outsideSortType,
  } = props;

  const [sortType, setSortType] = useState("default");
  const [columnToSort, setColumnToSort] = useState("");

  useEffect(() => {
    if (outsideColumnToSort !== undefined && outsideSortType !== undefined) {
      setSortType(outsideSortType);
      setColumnToSort(outsideColumnToSort);
    }
  }, [outsideColumnToSort, outsideSortType]);
  const onClickSort = useCallback(
    (columnToSort, sortType, doNotCallSort = false) => {
      if (onSort) {
        setSortType(sortType);
        setColumnToSort(columnToSort);
        onSort(columnToSort, sortType);
      }
    },
    [onSort]
  );
  return (
    <thead className={className}>
      <tr>
        {firstCell && !hideCheckBoxCell && <th>{firstCell}</th>}
        {!hideCheckBoxCell && !firstCell && <th style={{ width: 1 }} />}
        {cells.map((cell, index) => {
          if (cell.stateProp) {
            return (
              <TableHeaderCell
                key={cell.text}
                className={clsx({
                  "pl-0": !hideCheckBoxCell && index === 0,
                  "pl-5": !sitemap && hideCheckBoxCell && index === 0,
                  "px-3 py-1 sitemap-widget-table__title": sitemap,
                })}
                text={cell.text}
                onClickSort={(sortType) => onClickSort(cell.sortName, sortType)}
                showSortArrows={cell.showSortArrows}
                outSideSortType={cell.outSideSortType}
                sortType={cell.sortName === columnToSort ? sortType : "default"}
              />
            );
          }

          return null;
        })}
        {additionalEndCells && additionalEndCells}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      stateProp: PropTypes.bool.isRequired,
      sortName: PropTypes.string,
      showSortArrows: PropTypes.bool,
    })
  ).isRequired,
  onSort: PropTypes.func,
  hideCheckBoxCell: PropTypes.bool,
  className: PropTypes.string,
  firstCell: PropTypes.node,
  additionalEndCells: PropTypes.arrayOf(PropTypes.node),
  outsideColumnToSort: PropTypes.string,
  outsideSortType: PropTypes.string,
};

TableHeader.defaultProps = {
  onSort: undefined,
  hideCheckBoxCell: false,
  className: "",
  additionalEndCells: undefined,
  outsideColumnToSort: "",
  outsideSortType: "",
};

export default TableHeader;
