import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as campaignsActions from "../../../actions/campaigns";

export function useCampaignsStore() {
  const dispatch = useDispatch();
  const _campaigns = useSelector((store) => store.campaigns);

  const setCampaignsEmbedURL = useCallback(
    async (embedURL) =>
      await dispatch(campaignsActions.setCampaignsEmbedURL(embedURL)),
    [dispatch]
  );

  return {
    campaigns: _campaigns,
    setCampaignsEmbedURL,
  };
}

export default useCampaignsStore;
