import React from "react";
import CustomModal from "./CustomModal";

import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";

const ConfirmRestrictedPhraseModal = (props) => {
  const { main } = useMainStore();
  const { dataForModal } = main;

  const { closeModal } = props;
  const restrictedPhrases =
    props.restrictedPhrases || dataForModal.restrictedPhrases;
  const handleConfirm = props.handleConfirm || dataForModal.handleConfirm;
  const schedule = props.schedule || dataForModal.schedule;

  return (
    <CustomModal
      {...props}
      title="Confirm"
      buttons={[
        {
          type: "confirm",
          caption: `${schedule ? "Schedule" : "Send"} Anyway`,
          handler: handleConfirm,
        },
        {
          type: "cancel",
          caption: "Cancel",
          handler: closeModal,
        },
      ]}
    >
      <p
        className="mb-3 text-left text-container"
        style={{ fontSize: "initial" }}
      >
        AT&T and Verizon have increased their message filtering aggressively
        towards messages containing certain trigger words or phrases that cause
        your message to be blocked.
      </p>
      <p
        className="mb-3 text-left text-container"
        style={{ fontSize: "initial" }}
      >
        Here are the known phrase(s) you should consider removing from your
        message to avoid going against carrier guidelines.
      </p>
      <ul className="mb-3 text-left">
        {restrictedPhrases &&
          restrictedPhrases.map((phrase) => <li key={phrase}>{phrase}</li>)}
      </ul>
      <p
        className="mb-3 text-left text-container"
        style={{ fontSize: "initial" }}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.pastorsline.com/analytics/message-delivery-carrier-violation"
        >
          LEARN MORE
        </a>
      </p>
      <p className="text-left text-dark" style={{ fontSize: "initial" }}>
        By sending this message, I understand that this message can be flagged
        as going against carrier guidelines.
      </p>
    </CustomModal>
  );
};

export default ConfirmRestrictedPhraseModal;
