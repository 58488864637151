import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../modals/Helpers/ModalWrapper";
import ButtonWithSpinner from "../hub/HelperComponents/ButtonWithSpinner";
import {
  formatDateTime5,
  formatPhoneNumber,
  truncateString,
} from "../../helpers";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import {
  getDateFormat,
  getScheduleFromRRuleText,
  getScheduleFromSendOn,
  getScheduleMessageSummary,
} from "../../utils/recurringMessagesHelpers";
import TimeAgo from "../TimeAgo";

const ScheduledMessageSummaryModal = (props) => {
  const {
    show,
    closeModal,
    data,
    handleEditScheduleMessage,
    handleDeleteScheduleMessage,
    handleSendNowSceduleMessage,
  } = props;

  // Redux store
  const {
    users: {
      loggedUser: { timezone_offset },
    },
  } = useUsersStore();

  const getSummaryToDisplay = useMemo(() => {
    const { recurring_rule } = data?.schedule_message || {};
    let display = "";
    if (recurring_rule) {
      const schedule = getScheduleFromRRuleText(recurring_rule);

      display = getScheduleMessageSummary({
        ...schedule,
        isMessageSummaryDefault: false,
        isMessageSummaryExpanded: true,
      });
    }

    return display;
  }, [data]);

  const recurringMessageUpcomingDate = useMemo(() => {
    if (!data?.schedule_message?.recurring_rule) return "";
    const schedule = getScheduleFromSendOn(data?.schedule_message.send_on_utc);
    return getDateFormat(schedule.startDate);
  }, [data]);

  return (
    <>
      <ModalWrapper
        id="ScheduledMessageSummaryModal"
        show={show}
        shakeModal={closeModal}
        closeModal={closeModal}
        className="scheduled-message-summary-modal"
      >
        <div className="add-new-user-container">
          <h1 className="mb-3">Schedule Message</h1>
          <div className="add-new-user-section-title mb-4">Summary</div>
          <div className="mt-2 mb-4 themed-static-text">
            <div className="row mb-1">
              <div className="col-6">Broadcast Name:</div>
              <div className="col-6 text-break">
                {truncateString(data?.broadcast_name || "-", 120)}
              </div>
            </div>
            {data?.from_number && (
              <div className="row mb-1">
                <div className="col-6">From:</div>
                <div className="col-6">
                  {formatPhoneNumber(data?.from_number) ?? "-"}
                </div>
              </div>
            )}
            <div className="row flex-nowrap mb-1">
              <div className="col-6">Type:</div>
              <div className="col-6 text-break">{data.type}</div>
            </div>
            <div className="row mb-1">
              <div className="col-6">Group:</div>
              <div className="col-6 text-break">
                {truncateString(data?.group?.group_name ?? "-", 120)}
              </div>
            </div>
            {data?.group?.group_type && (
              <div className="row mb-1">
                <div className="col-6">Group Type:</div>
                <div className="col-6">{data?.group?.group_type ?? "-"}</div>
              </div>
            )}
            <div className="row mb-1">
              <div className="col-6">Status:</div>
              <div className="col-6">{data?.status}</div>
            </div>
            {data?.send_on && (
              <div className="row mb-1">
                <div className="col-6">Schedule Message Date:</div>
                <div className="col-6">
                  {formatDateTime5(data?.send_on, timezone_offset)}
                </div>
              </div>
            )}
            {data?.schedule_message?.recurring_rule && (
              <div className="row mb-1">
                <div className="col-6">Recurring:</div>
                <div className="col-6">{getSummaryToDisplay}</div>
              </div>
            )}

            {data?.isStopped && data?.schedule_message?.is_stop_datetime && (
              <div className="row mb-1">
                <div className="col-6">Stopped at:</div>
                <div className="col-6">
                  <TimeAgo
                    time={data?.schedule_message?.is_stop_datetime}
                    userTimeZoneOffset={timezone_offset}
                  />
                </div>
              </div>
            )}

            {recurringMessageUpcomingDate && (
              <div className="row mb-1">
                <div className="col-6">Upcoming:</div>
                <div className="col-6">{recurringMessageUpcomingDate}</div>
              </div>
            )}
            <div className="row mb-1">
              <div className="col-6">Recipients:</div>
              <div className="col-6">{data?.recipients_total}</div>
            </div>
            {Boolean(data?.recipients_success) && (
              <div className="row mb-1">
                <div className="col-6">Recipients Successfull:</div>
                <div className="col-6">{data?.recipients_success}</div>
              </div>
            )}
            {Boolean(data?.recipients_failed) && (
              <div className="row mb-1">
                <div className="col-6">Recipients Failed:</div>
                <div className="col-6">{data?.recipients_failed}</div>
              </div>
            )}
            <div className="row mb-1">
              <div className="col-6">Credits:</div>
              <div className="col-6">{data?.credits ?? "-"}</div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            {data?.isShowDeleteScheduleMessage && (
              <h4
                onClick={handleDeleteScheduleMessage(data)}
                className="cursor-pointer orange-text mt-2"
              >
                <u>Delete</u>
              </h4>
            )}
            {data?.isShowSendNowScheduleMessage && (
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleSendNowSceduleMessage(data)}
              >
                Send Now
              </button>
            )}
            {data?.isShowEditScheduleMessage && (
              <ButtonWithSpinner
                className={"align-self-end"}
                onClick={handleEditScheduleMessage(data)}
              >
                Edit
              </ButtonWithSpinner>
            )}
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

ScheduledMessageSummaryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object,
  handleEditScheduleMessage: PropTypes.func.isRequired,
  handleDeleteScheduleMessage: PropTypes.func.isRequired,
  handleSendNowSceduleMessage: PropTypes.func.isRequired,
};

ScheduledMessageSummaryModal.defaultPropTypes = {
  data: {},
};

export default ScheduledMessageSummaryModal;
