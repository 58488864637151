import React, { useState, useEffect } from "react";
import ThemeContext, { initialThemeState } from "./ThemeContext";
import { GLOBAL_THEME } from "../utils/constants";
import { connect } from "react-redux";

function mapStateToProps(store) {
  return {
    theme: store.users?.settings?.theme || GLOBAL_THEME,
  };
}

const ThemeProvider = ({
  children,
  // Redux props
  theme,
}) => {
  const [globalTheme, setGlobalTheme] = useState(initialThemeState.globalTheme);

  useEffect(() => {
    setGlobalTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        globalTheme,
        setGlobalTheme,
      }}
    >
      <div id="themed-root" className={`theme--${globalTheme}`}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export default connect(mapStateToProps)(ThemeProvider);
