import React, { useCallback } from "react";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import ModalWrapper from "./Helpers/ModalWrapper";
import { setReloadCountOnLocalStorage } from "../../utils/auth";

const ErrorModal = () => {
  const { toggleShowErrorModal } = useMainStore();
  const { logoutAction, logout } = useUsersStore();

  const onClickedReload = useCallback(() => {
    setReloadCountOnLocalStorage(0);
    toggleShowErrorModal(false);
    logoutAction();
    window.location.reload();
  }, [logoutAction, toggleShowErrorModal]);

  const onClickedLogout = useCallback(() => {
    toggleShowErrorModal(false);
    setReloadCountOnLocalStorage(0);
    logout();
  }, [logout, toggleShowErrorModal]);

  return (
    <ModalWrapper
      id="ErrorModal"
      show
      className="server-error-modal"
      hasCloseBtn={false}
      closeModal={() => {}}
    >
      <div className="session-expired-container themed-modal">
        <h1>Server Error</h1>
        <p>Something went wrong. Please reload app again.</p>
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn btn-outline-primary" onClick={onClickedLogout}>
            Logout
          </button>
          <button className="btn btn-primary" onClick={onClickedReload}>
            Reload
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ErrorModal;
