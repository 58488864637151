import React, { useCallback, useMemo } from "react";
import { ReactComponent as AlertSvgWhite } from "../../../../assets/img/icons-new/number-status/show-info/show-info-white.svg";
import { ReactComponent as PaymentIssueSvg } from "../../../../assets/img/icons-new/general/payment/payment-issue-black.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useUsersStore from "../../../../utils/hooks/ReduxHooks/userStore";
import useCompaniesStore from "../../../../utils/hooks/ReduxHooks/companiesStore";
import { useIsAdminOrOwner } from "../../../../utils/hooks/useIsAdminOrOwner";
import { useWithMobileMode } from "../../../../utils/hooks/useWithMobileMode";
import { IssueKeyEnums } from "../../../../utils/enums/accountEnums";
import { isAccountIssueModalIssueKey } from "../../../../utils/accountHelpers";

const AdminOrOwnerShortMessage = ({ shortMessage, setCurrentModal }) => {
  const {
    users: { creditsSummary },
  } = useUsersStore();
  const isMobile = useWithMobileMode(true);
  const history = useHistory();

  const onClickTakeAction = useCallback(() => {
    setCurrentModal("account-issue");
  }, [setCurrentModal]);

  const onCreditOptionsClick = useCallback(
    (type) => () => {
      let url = "/hub/menus/filter/settings/admin-settings/submenu/";
      url +=
        type === "purchase"
          ? "admin-billing/billing"
          : "admin-users/active-users";
      history.push(url);
    },
    [history]
  );
  return isMobile ? (
    <span>
      {shortMessage}{" "}
      <a href="#void" onClick={onClickTakeAction}>
        <u>Take Action</u>
      </a>
    </span>
  ) : (
    <span>
      {shortMessage} You can either{" "}
      <a href="#void" onClick={onCreditOptionsClick("purchase")}>
        <u>Purchase More Credits</u>
      </a>{" "}
      or{" "}
      <a href="#void" onClick={onCreditOptionsClick("user-credit")}>
        <u>View/Adjust Other Users' Credits</u>
      </a>{" "}
      ({creditsSummary?.users_assigned_credits || 0} Assigned to Users)
    </span>
  );
};

const AlertNotifierAccountIssue = (props) => {
  const { accountIssueInfo, setCurrentModal } = props;

  const {
    companies: { currentCompany },
  } = useCompaniesStore();
  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);

  const newAccountIssueInfo = useMemo(() => {
    if (!accountIssueInfo) return null;
    let _accountIssueInfo = { ...accountIssueInfo };
    const noCredits =
      [IssueKeyEnums.NoCredits].indexOf(accountIssueInfo.issueKey) !== -1;
    if (noCredits) {
      if (isAdminOrOwner) {
        _accountIssueInfo.shortMessage = (
          <AdminOrOwnerShortMessage
            setCurrentModal={setCurrentModal}
            shortMessage={accountIssueInfo.shortMessage}
          />
        );
      } else {
        _accountIssueInfo.shortMessage += " Please contact your admin ";
      }
    }

    return _accountIssueInfo;
  }, [accountIssueInfo, isAdminOrOwner, setCurrentModal]);

  if (!isAccountIssueModalIssueKey(accountIssueInfo.issueKey)) return null;
  return (
    <div
      className={
        "alert top-alert align-items-center " + newAccountIssueInfo.alertClass
      }
    >
      {newAccountIssueInfo.icon === "payment" && (
        <PaymentIssueSvg className={"icon-black"} width={25.833} height={18} />
      )}
      {newAccountIssueInfo.icon === "warning" && (
        <AlertSvgWhite width={20} height={20} />
      )}
      <div className="ml-2">{newAccountIssueInfo.shortMessage}</div>
    </div>
  );
};

AlertNotifierAccountIssue.propTypes = {
  accountIssueInfo: PropTypes.object.isRequired,
  setCurrentModal: PropTypes.func.isRequired,
};
export default AlertNotifierAccountIssue;
