import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import EmojiPicker, { SkinTones } from "emoji-picker-react";
import { Popover } from "@material-ui/core";
import SpanWithTooltip from "../../SpanWithTooltip";
import { ReactComponent as SmileIconGraySvg } from "../../../assets/img/icons-new/general/smile-icon/smile-icon-gray.svg";
import { ReactComponent as ImageDarkerGreySvg } from "../../../assets/img/icons-new/general/image/image-darker-grey.svg";
import { ReactComponent as VideoDarkerGreySvg } from "../../../assets/img/icons-new/general/video/video-darker-grey.svg";
import { ReactComponent as SignatureGraySvg } from "../../../assets/img/icons-new/general/signature/signature-gray.svg";
import { ReactComponent as AddIcon } from "../../../assets/img/icons-new/general/add-icon/add-gray.svg";
import { ReactComponent as LinkLighterDarkGreySvg } from "../../../assets/img/icons-new/general/link/link-lighter-darker-grey.svg";
import { ReactComponent as TemplatesDarkerGreySvg } from "../../../assets/img/icons-new/general/templates/templates-darker-grey.svg";
import { ReactComponent as MergeFieldsGraySvg } from "../../../assets/img/icons-new/general/merge-fields/merge-fields-gray.svg";
import { ReactComponent as VcardDarkerGreySvg } from "../../../assets/img/icons-new/general/card/card-darker-gray.svg";
import Feature from "../../hub/HubThreadMessageForm/Features/Feature";
import AddImageFeature from "../../hub/HubThreadMessageForm/Features/AddImageFeature";
import AddVideoFeature from "../../hub/HubThreadMessageForm/Features/AddVideoFeature";
import AddAttachmentFeature from "../../hub/HubThreadMessageForm/Features/AddAttachmentFeature";
import MergeFields from "../../hub/HelperComponents/MergeFields";
import { useInsertTextOnClick } from "../../../utils/hooks/useInsertTextOnClick";
import { history } from "../../../store";
import useShortLinkStore from "../../../utils/hooks/ReduxHooks/shortLinkStore";
import useMessageTemplatesStore from "../../../utils/hooks/ReduxHooks/messageTemplatesStore";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import { checkUrlContentType } from "../../../utils/filestackHelpers";
import {
  mapImageUrlsToObject,
  setSkinTone,
  getSkinTone,
} from "../../../utils/messagesHelpers";

const MessagesFeature = (props) => {
  const {
    message,
    images,
    videos,
    setImages,
    setVideos,
    isVideoProcessing,
    setIsVideoProcessing,
    isImagesProcessing,
    setIsImagesProcessing,
    textAreaRef,
    onChange,
    characters,
    setImagesErrorText,
    setVideoErrorText,
    setVideoLengthExceedData,
    setUploadedVideo,
    subscribersCount,
    toggleMergeFields,
    handleCloseMergeFieldsModal,
    showMergeFields,
    vCards,
  } = props;

  // Redux props
  const { shortLinks, clearInsertShortLink } = useShortLinkStore();
  const { messageTemplates, clearInsertMessageTemplate } =
    useMessageTemplatesStore();
  const [insertToShortLink, insertShortLink] = [
    shortLinks.insertTo,
    shortLinks.insertShortLink,
  ];
  const [insertToMessageTemplates, insertMessageTemplate] = [
    messageTemplates.insertTo,
    messageTemplates.insertMessageTemplate,
  ];

  // Local component state
  const [emojiAnchor, setEmojiAnchor] = useState(null);
  const [moreAnchor, setMoreAnchor] = useState(null);

  // Breakpoint
  const breakpoint = useBreakpoint();

  const handleVideoLengthExceedData = useCallback(
    (file) => {
      setVideoLengthExceedData(file);
    },
    [setVideoLengthExceedData]
  );

  const handleUploadedVideoFileData = useCallback(
    (file) => {
      setUploadedVideo(file);
    },
    [setUploadedVideo]
  );

  const onSelectEmoji = useCallback(
    (emojiObject) => {
      setEmojiAnchor(null);
      onChange({ target: { value: `${message}${emojiObject.emoji}` } });

      // timeout in order to not conflict with something else when focusing
      setTimeout(() => {
        textAreaRef.current.focus();
      }, 50);
    },
    [message, textAreaRef, onChange]
  );

  const openEmojiPopOver = useCallback((e) => {
    setEmojiAnchor(e.currentTarget);
  }, []);

  const closeEmojiPopOver = useCallback(() => {
    setEmojiAnchor(null);
  }, []);

  const openMorePopOver = useCallback((e) => {
    setMoreAnchor(e.currentTarget);
  }, []);

  const closeMorePopOver = useCallback(() => {
    setMoreAnchor(null);
  }, []);

  const onClickChooseSignature = useCallback(() => {
    history.push("#modal-choose-outgoing-signature");
    closeMorePopOver();
  }, [closeMorePopOver]);

  const onSetMessageTextAndCloseMore = useCallback(
    (text) => {
      onChange({ target: { value: text } });
      closeMorePopOver();
    },
    [onChange, closeMorePopOver]
  );

  const onClickAddUrl = useCallback(() => {
    history.push("#modal-short-links/hub-thread-view");
    closeMorePopOver();
  }, [closeMorePopOver]);

  const onClickAddTemplate = useCallback(() => {
    history.push("#modal-message-templates/hub-thread-view");
    closeMorePopOver();
  }, [closeMorePopOver]);

  const handleToggleMergeFields = useCallback(() => {
    toggleMergeFields();
    closeMorePopOver();
  }, [toggleMergeFields, closeMorePopOver]);

  const onClickAddVcard = useCallback(() => {
    history.push("#modal-add-vcard", { vCards });
    closeMorePopOver();
  }, [closeMorePopOver, vCards]);

  const { onClickText } = useInsertTextOnClick({
    ref: textAreaRef.current,
    setText: onSetMessageTextAndCloseMore,
  });

  const checkMediaUrlTypes = useCallback(
    (data) => {
      try {
        data.forEach(async (el) => {
          const res = await checkUrlContentType(el);
          if (res === "video") {
            setVideos((prev) => [...prev, { file: { url: el } }]);
          }
          if (res === "image") {
            setImages(mapImageUrlsToObject(data));
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    [setImages, setVideos]
  );

  useEffect(() => {
    if (insertToShortLink === "hub-thread-view" && insertShortLink) {
      onChange({
        target: { value: `${message} ${insertShortLink.short_url} ` },
      });
      textAreaRef.current.focus();
      clearInsertShortLink();
    }
  }, [
    clearInsertShortLink,
    insertShortLink,
    insertToShortLink,
    onChange,
    textAreaRef,
    message,
  ]);

  useEffect(() => {
    if (
      insertToMessageTemplates === "hub-thread-view" &&
      insertMessageTemplate
    ) {
      onChange({
        target: {
          value: `${message} ${insertMessageTemplate.message_template} `,
        },
      });
      if (
        insertMessageTemplate?.images &&
        Array.isArray(insertMessageTemplate?.images) &&
        Boolean(insertMessageTemplate?.images)
      ) {
        checkMediaUrlTypes(insertMessageTemplate?.images);
      }
      textAreaRef.current.focus();
      clearInsertMessageTemplate();
    }
  }, [
    clearInsertMessageTemplate,
    insertMessageTemplate,
    insertToMessageTemplates,
    onChange,
    textAreaRef,
    message,
    checkMediaUrlTypes,
  ]);

  return (
    <>
      <div className="messages-feature flexer-row justify-content-start">
        <Popover
          id="emoji"
          open={Boolean(emojiAnchor)}
          anchorEl={emojiAnchor}
          onClose={closeEmojiPopOver}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <EmojiPicker
            onEmojiClick={onSelectEmoji}
            disableAutoFocus={true}
            defaultSkinTone={getSkinTone() || SkinTones.NEUTRAL}
            groupNames={{ smileys_people: "PEOPLE" }}
            native
            onSkinToneChange={(skinTone) => setSkinTone(skinTone)}
          />
        </Popover>

        <SpanWithTooltip
          className="d-flex align-items-center cursor-pointer whitespace-nowrap"
          enterDelay={500}
          title="Add Emoji"
        >
          <SmileIconGraySvg
            height={18}
            width={18}
            className="smile-icon mr-2"
            onClick={openEmojiPopOver}
          />
        </SpanWithTooltip>

        <AddImageFeature
          className="px-1 flexer-row border rounded-lg mr-2 whitespace-nowrap"
          onImagesError={setImagesErrorText}
          images={images}
          disabledUploadImageOption={
            Boolean(videos.length) || isVideoProcessing || isImagesProcessing
          }
          onAddImages={setImages}
          subscribersCount={subscribersCount}
          Component={Feature}
          componentProps={{
            svg: <ImageDarkerGreySvg height={16} width={16} />,
            text: <small>Add Image</small>,
          }}
          setIsImagesProcessing={setIsImagesProcessing}
        />

        <AddVideoFeature
          className="px-1 flexer-row border rounded-lg mr-2 whitespace-nowrap"
          onVideoError={setVideoErrorText}
          videos={videos}
          images={images}
          subscribersCount={subscribersCount}
          Component={Feature}
          setIsVideoProcessing={setIsVideoProcessing}
          componentProps={{
            svg: <VideoDarkerGreySvg height={16} width={16} />,
            text: <small>Add Video</small>,
          }}
          isVideoProcessing={isVideoProcessing}
          handleVideoLengthExceedData={handleVideoLengthExceedData}
          handleUploadedVideoFileData={handleUploadedVideoFileData}
          onAddVideos={setVideos}
        />

        <Feature
          svg={<LinkLighterDarkGreySvg />}
          text={<small>Add URL</small>}
          onClick={onClickAddUrl}
          className="px-1 flexer-row border rounded-lg mr-2 d-none d-sm-block whitespace-nowrap"
          c
        />

        <Feature
          className="px-1 flexer-row border rounded-lg mr-2 d-none d-sm-block whitespace-nowrap"
          svg={<SignatureGraySvg height={16} width={16} />}
          text={<small>Set Signature</small>}
          onClick={onClickChooseSignature}
        />

        <Popover
          id="more"
          open={Boolean(moreAnchor)}
          anchorEl={moreAnchor}
          onClose={closeMorePopOver}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className="p-3">
            <Feature
              svg={<SignatureGraySvg />}
              text="Set Signature"
              onClick={onClickChooseSignature}
              className="cursor-pointer mb-2 d-sm-none"
            />

            <AddAttachmentFeature
              characters={characters}
              textAreaRef={textAreaRef}
              onMessageTextChange={onSetMessageTextAndCloseMore}
              onWarningTextChange={() => {}}
              text="Add Attachment"
              className="cursor-pointer mb-2"
            />

            <Feature
              svg={
                <VcardDarkerGreySvg
                  style={{ fill: "transparent" }}
                  height={20}
                  width={20}
                />
              }
              text="Add Vcard"
              onClick={vCards?.length >= 5 ? undefined : onClickAddVcard}
              className={clsx("cursor-pointer mb-2", {
                "disabled-feature": vCards?.length >= 5,
              })}
              tooltipTitle={
                vCards?.length >= 5 ? "You can add up to 5 vCards" : ""
              }
              isBetaFeature={true}
            />

            {isMdOrBelowBreakpoint(breakpoint) && (
              <Feature
                svg={<LinkLighterDarkGreySvg />}
                text="Add URL"
                onClick={onClickAddUrl}
                className="cursor-pointer mb-2 mt-1"
              />
            )}

            <Feature
              svg={<TemplatesDarkerGreySvg />}
              text="Add Template"
              onClick={onClickAddTemplate}
              className="cursor-pointer mb-2 mt-1"
            />

            <Feature
              svg={<MergeFieldsGraySvg />}
              text="Add Merge Fields"
              onClick={handleToggleMergeFields}
              subscribersCount={subscribersCount}
              className="cursor-pointer"
            />
          </div>
        </Popover>

        <Feature
          className="px-1 flexer-row border rounded-lg mr-2"
          svg={<AddIcon height={16} width={16} />}
          text={<small>More</small>}
          more={true}
          onClick={openMorePopOver}
        />
      </div>
      <MergeFields
        onClickMergeField={onClickText}
        show={showMergeFields}
        closeModal={handleCloseMergeFieldsModal}
      />
    </>
  );
};

MessagesFeature.propTypes = {};

export default MessagesFeature;
