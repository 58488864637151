import React from "react";
import Highlighter from "react-highlight-words";
import {
  MOBILE_RECORD,
  TEXT_TO_VOICE,
  UPLOAD_MP3,
  WEB_RECORD,
} from "../proptypes";
import { isReactNativeApp, relaceAllStringOccurence } from "../helpers";
import { ReactComponent as CallIcon } from "../assets/img/icons-new/other/capabilities/call-gray.svg";
import { ReactComponent as OnlyOutgoingTextIcon } from "../assets/img/icons-new/other/capabilities/only-outgoing-text-gray.svg";
import { ReactComponent as InternationalCallIcon } from "../assets/img/icons-new/other/capabilities/international-call-gray.svg";
import { ReactComponent as MmsIconSvg } from "../assets/img/icons-new/other/mms/mms-mid-gray.svg";
import { ReactComponent as BxMessageAltDetailSvg } from "../assets/img/icons-new/message-tab/all-messages/all-messages-gray.svg";
import { settingsSubMenus } from "../components/Menus/SubMenus/allSubMenus";
import { ACCESS_TOKEN, REFRESH_TOKEN, ROCK } from "./constants";
import { isMdOrBelowBreakpoint } from "./breakpoints";
import { store } from "../store";

export const mapTemplateSignature = (signature, templates) => {
  if (!signature || !templates) return "";
  if (Object.keys(templates).length === 0) return "";
  let newSignature = signature;

  for (const template in templates) {
    if (Object.hasOwnProperty.call(templates, template)) {
      const toReplaceWith = templates[template];
      newSignature = relaceAllStringOccurence(
        newSignature,
        template,
        toReplaceWith
      );
    }
  }

  return newSignature;
};

export const getHighlighter = (title, query) => {
  return (
    <Highlighter
      highlightClassName="bg-yellow"
      searchWords={query ? [query] : []}
      textToHighlight={title}
    />
  );
};
export const updateCompaniesCurrentAndSelectedCompanyState = (
  state,
  companyId,
  data = {}
) => {
  let currentCompany = { ...state.currentCompany };
  let companies = [...state.companies];
  let selectedCompany = { ...state.selectedCompany };
  const currentIndex = companies.findIndex(
    (company) => company.id === companyId
  );

  companies[currentIndex] = { ...companies[currentIndex], ...data };

  if (currentCompany.id === companyId) {
    currentCompany = { ...currentCompany, ...data };
  }
  if (selectedCompany?.id === companyId) {
    selectedCompany = { ...selectedCompany, ...data };
  }
  return { companies, currentCompany, selectedCompany };
};

export const getCustomSelectHeadersAndOptions = (categories) => {
  const subHeaders = [];
  const options = [];
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    if ((category?.options || []).length > 0) {
      options.push(...category.options);
      if (i === 0) {
        subHeaders.push({
          text: category.title,
          startIndex: 0,
          endIndex: category.options.length - 1,
        });
      } else {
        let lastIndex;
        if (typeof subHeaders[i - 1]?.endIndex === "undefined") {
          lastIndex = options.length - 2;
        } else {
          lastIndex = subHeaders[i - 1]?.endIndex;
        }
        subHeaders.push({
          text: category.title,
          startIndex: lastIndex + 1,
          endIndex: lastIndex + category.options.length,
        });
      }
    }
  }
  return { options, subHeaders };
};

export const getUserRoleName = (role) => {
  let roleName = "";
  if (role === 1) {
    roleName = "User";
  } else if (role === 2) {
    roleName = "Admin";
  }
  return roleName;
};

export const getUserRole = (company, userId) => {
  let roleName = "";
  if (company.role === 1) {
    roleName = "User";
  } else if (company.role === 2) {
    roleName = "Admin";
    if (company.invited_owner) {
      roleName = "Admin+";
    }
    if (company.id === userId) {
      roleName = "Owner";
    }
  }
  return roleName;
};

export const notificationTypes = {
  // smart: { mode: "smart", displayText: "Smart Notifications" },
  // focus: { mode: "focus", displayText: "Focus Mode" },
  custom: { mode: "custom", displayText: "All Notifications" },
  off: { mode: "off", displayText: "All Notifications Turned Off" },
};

export const getNotificationOptions = () => {
  return Object.keys(notificationTypes).map((key) => ({
    name: notificationTypes[key].displayText,
    value: notificationTypes[key].mode,
  }));
};

export const getNotificationsSnackBarMessage = (activeNotification) => {
  if (activeNotification === notificationTypes.off.mode) {
    return notificationTypes.off.displayText;
  } else {
    return `${notificationTypes[activeNotification].displayText} Turned On`;
  }
};

export const getIntegrationTitle = (type) => {
  if (type === "mailchimp") return "MailChimp";
  if (type === "breeze") return "Breeze";
  if (type === "ccb") return "Church Community Builder (CCB)";
  if (type === "planning-center") return "Planning Center";
  if (type === "elvanto") return "Tithely ChMS (Elvanto)";
  if (type?.startsWith(ROCK)) return "Rock RMS";
};

export const checkIfObjEmptyForSearch = (obj) => {
  let isEmpty = true;
  Object.keys(obj).forEach((item) => {
    if (obj[item].length) {
      isEmpty = false;
    }
  });
  return isEmpty;
};

export const getVoiceSectionTitleBy = (currentStep, sourceType) => {
  if (currentStep === 2 && sourceType === WEB_RECORD) {
    return "Voice Recording";
  }
  if (currentStep === 2 && sourceType === TEXT_TO_VOICE) {
    return "Type Message";
  }
  if (currentStep === 2 && sourceType === MOBILE_RECORD) {
    return "Mobile Call Recording";
  }
  if (currentStep === 2 && sourceType === UPLOAD_MP3) {
    return "Uploading MP3 ";
  }
  return "";
};

export const getKeyForSignatureMergeFields = (userId, userNumber) => {
  return `${userId}-${userNumber}`;
};

export const getSourceTypeText = (sourceType) => {
  switch (sourceType) {
    case "web-record":
      return "Web Record";
    case "mobile-record":
      return "Mobile Record";
    case "upload-mp3":
      return "Upload mp3";
    case "text-to-voice":
      return "Text to Voice";
    default:
      return sourceType;
  }
};

export const getAssignedNumbers = (numbers = []) => {
  return numbers.filter((item) => item.assigned);
};

export const getKindFromParams = (param) => {
  if (!param) return "";
  if (param === "user-greetings") return "greeting";
  if (param === "user-voicemail") return "voicemail";
};

const getAssignedGreetingsNumbers = (numbers, currentAssignedNumbers) => {
  const assignedNumbers = [];
  numbers.forEach((item) =>
    item.numbers.forEach((el) => {
      if (currentAssignedNumbers.includes(el.number)) {
        assignedNumbers.push(
          `${el.number_formatted || ""} (${el.nickname || ""})`
        );
      }
    })
  );
  return assignedNumbers;
};

export const getAssignedGreetingsAndNumbers = (
  greetings = [],
  currentAssignedNumbers = []
) => {
  const filterAssignedGreetings = greetings.filter((item) => {
    return item.numbers.some((el) =>
      currentAssignedNumbers.includes(el.number)
    );
  });
  const assignedNumbers = getAssignedGreetingsNumbers(
    filterAssignedGreetings,
    currentAssignedNumbers
  );
  return { assignedGreetings: filterAssignedGreetings, assignedNumbers };
};

export const getNumberCapabilities = (options = {}) => {
  const { isVerifiedNumber } = options;
  const mmsString = "mms"; // cannot use 'mms' as string directly in the below object
  const iconSize = 18;
  return {
    "incoming-call": {
      icon: <CallIcon width={iconSize} height={iconSize} className="mr-1" />,
      text: `Can make${
        isVerifiedNumber ? "" : "/receive"
      } calls to domestic numbers`,
    },
    "incoming-text": {
      icon: (
        <BxMessageAltDetailSvg
          width={iconSize}
          height={iconSize}
          className="mr-1"
        />
      ),
      text: "Can send/receive messages to domestic numbers",
    },
    "outgoing-text": {
      icon: (
        <OnlyOutgoingTextIcon
          width={iconSize}
          height={iconSize}
          className="mr-1"
        />
      ),
      text: "Can send international messages",
    },
    [mmsString]: {
      icon: <MmsIconSvg width={iconSize} height={iconSize} className="mr-1" />,
      text: "This number supports MMS",
    },
    "outgoing-call": {
      icon: (
        <InternationalCallIcon
          width={iconSize}
          height={iconSize}
          className="mr-1"
        />
      ),
      text: "Can make international calls",
    },
  };
};

export const getNumberType = (number, isAssignedNumber = false) => {
  if (isAssignedNumber) {
    return "Assigned Default Number";
  }
  if (number.is_toll_free) {
    return "Toll-free";
  }
  if (number.is_high_throughput_number) {
    return "High Throughput Number";
  }
  if (number.src >= 1 && number.src <= 4) {
    return "Local Regular Number";
  }
  if (number.src === 5) {
    return "Short Code";
  }
  if (number.src === 6) {
    return "High Throughput Verified Toll-Free";
  }
  return "";
};

export const getNumberByCategories = (numbers) => {
  const numbersVirtual = [];
  const numbersPersonal = [];
  if (numbers) {
    for (let i = 0; i < numbers.length; i += 1) {
      if (!numbers[i].voice) {
        continue;
      }
      if (numbers[i].src === 4) {
        numbersPersonal.push(numbers[i]);
      } else {
        numbersVirtual.push(numbers[i]);
      }
    }
  }

  return {
    numbersVirtual,
    numbersPersonal,
  };
};

export const getShortCodeNumber = (numbers) => {
  const shortCode = numbers.find((number) => number.type === "shortcode");
  return shortCode?.number;
};

export const getUserRoleType = (company) => {
  if (
    company?.is_owner ||
    company?.is_main_owner ||
    company?.invited_owner ||
    company?.is_admin_plus
  )
    return "Owner";
  if (company?.is_admin_user) return "Admin";
  if (company?.contact_management === 0) return "User";
  return "Restricted User";
};

export const getPermissionParsed = (permissionObj, type) => {
  if (permissionObj === true) return true;
  if (permissionObj && permissionObj[type] === true) return true;
  return false;
};

export const deepCloneObject = (obj = {}) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getAdminSettingsMenu = () => {
  return settingsSubMenus.find((menu) => menu.id === "adminSettings");
};

export const formatURLWithTokens = (url) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  return `${url}?refreshToken=${refreshToken}&accessToken=${accessToken}`;
};

export const getMenuHeaderTextForSmallDevices = (text = "") => {
  let headerText;
  if (text.includes("/")) {
    const splitText = text.split("/");
    headerText = splitText[splitText.length - 1];
  } else {
    headerText = text;
  }
  return headerText;
};

export const getEmptyTableColspan = (tableColumns = {}) => {
  return Object.values(tableColumns).filter(Boolean).length + 1;
};

export const calculateTotalCredits = (logs = []) => {
  return logs.reduce((accumulator, object) => {
    return accumulator + object.credit;
  }, 0);
};

export const getSnackbarPosition = (breakpoint = "") => {
  if (isMdOrBelowBreakpoint(breakpoint)) {
    return "bottom-center";
  }
  return "bottom-left";
};

export const getInitialRedirectRoute = () => {
  return isReactNativeApp() ? "/hub/messages/filter/received" : "/dashboard";
};

export const isValidNumberExtension = (value) => {
  const reg = new RegExp(/[^w*#0-9]/g);
  return reg.test(value);
};
/**
 * Check if all other signatures are not selected. If so, return true.
 * @param signatures
 * @param curremtSignatureType
 * @param id
 * @returns {*}
 */
export const isAllOtherSignaturesNotSelected = (
  signatures,
  curremtSignatureType,
  id
) => {
  const filteredSignature = signatures.filter(
    (item) => item.type === curremtSignatureType && id !== item.id
  );
  const isOtherSignaturesNotSelected = filteredSignature.every(
    (item) => item.is_default === 0
  );
  return isOtherSignaturesNotSelected;
};

/**
 * Check if signature is default
 * @param signature
 * @param selectedNumberSignature
 * @returns {number|number} -1 if not default, 0 if default
 */
export const checkForDefaultSignature = (
  signature,
  selectedNumberSignature
) => {
  if (signature.is_default === 0) return -1;
  if (
    selectedNumberSignature &&
    Array.isArray(selectedNumberSignature.signatures)
  ) {
    const otherSignatureNotSelected = isAllOtherSignaturesNotSelected(
      selectedNumberSignature.signatures,
      signature.type,
      signature.id
    );
    return otherSignatureNotSelected ? 0 : -1;
  }
};

export const switchToV2 = () => {
  window.location.href = `${process.env.REACT_APP_PRIMARY_APP_URL}users/v2BetaTesting`;
};

export const getDisplayLabel = (isOnMobileMode, isLoading) => {
  if (isLoading) {
    return "Updating...";
  } else if (isOnMobileMode) {
    return "View Desktop Mode";
  }
  return "View Mobile Mode";
};

export const changeSortKeysForMobileView = (key) => {
  const SoftKeysMapping = {
    firstNameAsc: {
      columnToSort: "first_name",
      sortType: "up",
    },
    firstNameDesc: {
      columnToSort: "first_name",
      sortType: "down",
    },
    lastNameAsc: {
      columnToSort: "last_name",
      sortType: "up",
    },
    lastNameDesc: {
      columnToSort: "last_name",
      sortType: "down",
    },
  };
  return SoftKeysMapping[key];
};

export const getCountryPhoneCodeFromCountryId = (country_id) => {
  const { countries } = store.getState().countries;
  return (
    countries.find((country) => country.id === country_id)?.phonecode || ""
  );
};

export const getUserTypeByRole = (data = {}) => {
  if (data?.is_main_owner || data?.is_admin_plus) return "Owner";
  if (data?.is_admin_user) return "Admin";
  if (data?.contact_management === 0) return "User";
  else {
    return "Restricted User";
  }
};

export const isNotAnEmptyArray = (arr) => {
  return arr && Array.isArray(arr) && Boolean(arr.length);
};

export const convertCentsToDollars = (cents) => {
  if (isNaN(cents)) return 0;
  return cents / 100;
};

export const replaceFirstDashWithComma = (str = "") => {
  // Find the index of the first dash
  const firstDashIndex = str.indexOf("-");

  // If a dash is found, replace it with a comma
  if (firstDashIndex !== -1) {
    return str.slice(0, firstDashIndex) + "," + str.slice(firstDashIndex + 1);
  }

  // If no dash is found, return the original string
  return str;
};

export const removeHash = (inputString = "") => {
  return inputString.replace(/#/g, "");
};

export const removeWord = (str = "", word = "") => {
  // Create a regular expression to match the word, case insensitive, and globally
  const regex = new RegExp(word, "gi");
  return str.replace(regex, "");
};

export const convertStringsToLowerCase = (arr = []) => {
  if (!isNotAnEmptyArray(arr)) return [];
  const cloneArray = [...arr];
  return cloneArray.map((item) => {
    if (typeof item === "string") {
      return item.toLowerCase();
    } else if (!item) {
      return "";
    }
    return item;
  });
};
