export const getFiltersMenusCollapsibleFilters = (filtersMenus) => {
  return filtersMenus
    .filter((menu) => !!menu?.items)
    .map(({ id, items, defaultHidden }) => {
      const menuWithGroupCollapse = items.filter(({ items }) => !!items);
      const menuWithoutGroupCollapse = items.filter(({ items }) => !items);
      return {
        id: id,
        parent_id: `${id}Header`,
        defaultHidden,
        items: menuWithoutGroupCollapse.map(({ key }) => key),
        groups: menuWithGroupCollapse.map((group) => {
          const innerMenuWithGroupCollapse = group.items.filter(
            ({ items }) => !!items
          );
          const innerMenuWithoutGroupCollapse = group.items.filter(
            ({ items }) => !items
          );
          return {
            id: group.id,
            parent_id: `${group.id}Header`,
            items: innerMenuWithoutGroupCollapse.map(({ key }) => key),
            groups: innerMenuWithGroupCollapse.map((group2) => {
              return {
                id: group2.id,
                parent_id: `${group2.id}Header`,
                items: group2.items.map(({ key }) => key),
              };
            }),
          };
        }),
      };
    });
};

export const parseUnpinnedMenusAndFilter = (unpinnedMenus = "") => {
  if (typeof unpinnedMenus === "object") return unpinnedMenus;

  if (typeof unpinnedMenus === "string") {
    // Replace single quotes with double quotes and add double quotes around property names
    const jsonString =
      typeof unpinnedMenus === "string"
        ? unpinnedMenus.replace(/(\w+):/g, '"$1":')
        : {};

    // Parse the JSON string into an object
    const obj = JSON.parse(jsonString);
    return obj;
  }
  return unpinnedMenus;
};

export const objectToString = (obj = {}) => {
  // Convert object to JSON string
  let jsonString = JSON.stringify(obj);

  return jsonString;
};

export const parseUnpinnedMenusAndFiltersForApi = (
  updatedUnpinnedObject,
  objectKey
) => {
  const objectForApi = { [objectKey]: updatedUnpinnedObject };
  return objectToString(objectForApi);
};
