import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as numbersAction from "../../../actions/numbers";

export function useNumbersStore() {
  const _numbers = useSelector((store) => store.numbers);
  const dispatch = useDispatch();

  const setShortCodeTollFreeCheckoutEmbedUrl = useCallback(
    async (embedURL) =>
      await dispatch(
        numbersAction.setShortCodeTollFreeCheckoutEmbedUrl(embedURL)
      ),
    [dispatch]
  );

  const fetchReleaseShortCodeOrTollFreeNumberInfo = useCallback(
    async (params) =>
      await dispatch(
        numbersAction.fetchReleaseShortCodeOrTollFreeNumberInfo(params)
      ),
    [dispatch]
  );

  const fetchNumbersExtended = useCallback(
    async (params) =>
      await dispatch(numbersAction.fetchNumbersExtended(params)),
    [dispatch]
  );

  const fetchNumbers = useCallback(
    async (companyId) => await dispatch(numbersAction.fetchNumbers(companyId)),
    [dispatch]
  );

  const setSignatureToInsert = useCallback(
    async (signatureToInsert) =>
      await dispatch(numbersAction.setSignatureToInsert(signatureToInsert)),
    [dispatch]
  );

  const setSenderNumber = useCallback(
    async (number) => await dispatch(numbersAction.setSenderNumber(number)),
    [dispatch]
  );

  const fetchSignatureMergeFields = useCallback(
    async (companyId, userId, signatureUserNumber) =>
      await dispatch(
        numbersAction.fetchSignatureMergeFields(
          companyId,
          userId,
          signatureUserNumber
        )
      ),
    [dispatch]
  );

  const fetchSignatures = useCallback(
    async (companyId, numbers) =>
      await dispatch(numbersAction.fetchSignatures(companyId, numbers)),
    [dispatch]
  );

  const editNumber = useCallback(
    async (params, index) =>
      await dispatch(numbersAction.editNumber(params, index)),
    [dispatch]
  );

  const clearFetchSignatureMergeFieldStatus = useCallback(
    () => dispatch(numbersAction.clearFetchSignatureMergeFieldStatus()),
    [dispatch]
  );

  const clearEditNumberStatus = useCallback(
    () => dispatch(numbersAction.clearEditNumberStatus()),
    [dispatch]
  );

  return {
    numbers: _numbers,
    setShortCodeTollFreeCheckoutEmbedUrl,
    fetchReleaseShortCodeOrTollFreeNumberInfo,
    fetchNumbersExtended,
    fetchNumbers,
    setSignatureToInsert,
    setSenderNumber,
    fetchSignatureMergeFields,
    clearFetchSignatureMergeFieldStatus,
    fetchSignatures,
    editNumber,
    clearEditNumberStatus,
  };
}
