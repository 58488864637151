import React from "react";
import ErrorPage from "../pages/ErrorPage";
import logger from "../logger";
import { connect } from "react-redux";
import { getDefaultErrorLogObj } from "../utils/componentLoader";
import { mapStackTrace } from "sourcemapped-stacktrace";

function mapStateToProps(store) {
  return {
    loggedUserId: store.users?.loggedUser?.id,
    currentCompanyId: store.companies.currentCompany?.id,
  };
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    const { loggedUserId, currentCompanyId } = this.props;

    mapStackTrace(error.stack, (mapStackTrace) => {
      logger.push(
        getDefaultErrorLogObj(error, mapStackTrace, {
          info,
          userId: loggedUserId,
          companyId: currentCompanyId,
        })
      );
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default connect(mapStateToProps)(ErrorBoundary);
