import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfirmationModal from "../../modals/ActionsModals/ConfirmationModal";
import SimilarContactsModal from "../../modals/ActionsModals/SimilarContactsModal";
import { unarchiveContacts, addSnackData } from "../../../actions/contacts";
import {
  handleGroupItemsActions,
  addSnackData as addGroupSnackData,
  unarchiveGroups,
} from "../../../actions/groups";
import Avatar from "../../Avatar";
import {
  doGroupMembersContainDuplicates,
  doSelectedThreadsContainDuplicates,
  getGroupIcon,
  getNameBy,
  groupName,
} from "../../../helpers";

function mapStateToProps(store) {
  return {
    companyId: store.companies.currentCompany.id,
    rhsItemsSelected: store.contacts.rhsItemsSelected,
    unarchiveMembersStatus: store.contacts.unarchiveMembersStatus,
    unarchivedSuccessContacts: store.contacts.unarchivedSuccessContacts,
    failedToUnarchiveNumber: store.contacts.failedToUnarchiveNumber,
    unarchivedContactsPathname: store.contacts.unarchivedContactsPathname,
    unarchiveGroupsStatus: store.groups.unarchiveGroupsStatus,
    unarchivedContactsInGroups: store.groups.unarchivedContactsInGroups,
    unarchivedSuccessGroups: store.groups.unarchivedSuccessGroups,
    contactsData: store.contacts.data,
    groupsData: store.groups.data,
    groupMembers: store.groups.members,
    isGroupAllMembersSelected: store.groups.isGroupAllMembersSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUnarchiveContacts: (
      companyId,
      contactIds,
      groupIds,
      primaryAssignees,
      containDuplicates,
      workflowId,
      needRouting,
      isUndo,
      allParams
    ) =>
      dispatch(
        unarchiveContacts(
          companyId,
          contactIds,
          groupIds,
          primaryAssignees,
          containDuplicates,
          workflowId,
          needRouting,
          isUndo,
          allParams
        )
      ),
    onGroupItemsActions: (time, selectedThreads, groupData, subMembersData) =>
      dispatch(
        handleGroupItemsActions(
          time,
          selectedThreads,
          groupData,
          subMembersData
        )
      ),
    setSnackData: (data, entity) => dispatch(addSnackData(data, entity)),
    onUnarchiveGroups: (
      companyId,
      groupIds,
      primaryAssignees,
      contactDuplicates,
      contactsAlso,
      workflowId,
      needRouting,
      contactIds
    ) =>
      dispatch(
        unarchiveGroups(
          companyId,
          groupIds,
          primaryAssignees,
          contactDuplicates,
          contactsAlso,
          workflowId,
          needRouting,
          contactIds
        )
      ),
    setGroupSnackData: (data, entity) =>
      dispatch(addGroupSnackData(data, entity)),
  };
}

const Unarchive = ({
  // Redux props
  companyId,
  unarchiveMembersStatus,
  unarchivedSuccessContacts,
  unarchiveGroupsStatus,
  failedToUnarchiveNumber,
  unarchivedSuccessGroups,
  unarchivedContactsInGroups,
  groupMembers,
  contactsData,
  groupsData,
  // Redux func
  onUnarchiveContacts,
  onGroupItemsActions,
  setSnackData,
  onUnarchiveGroups,
  setGroupSnackData,
  // Props from parent
  runWorkflow,
  selectedThreads,
  selectedSubThreads,
  onUnarchiveComplete,
  needRouting,
  confirmationModal,
  group,
  selectedGroups,
  numberOfSelectedPeople,
  allParams,
  isGroupAllMembersSelected,
  unarchivedContactsPathname,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalHeader, setConfirmationModalHeader] = useState("");
  const [confirmationModalContent, setConfirmationModalContent] = useState("");
  const [needSimilarContactsModal, setNeedSimilarContactsModal] =
    useState(false);
  const [similarContactsModal, setSimilarContactsModal] = useState(false);
  const [singleContactData, setSingleContactData] = useState({});
  const [singleGroupData, setSingleGroupData] = useState({});
  const [workflowId, setWorkflowId] = useState();
  const [undoPayload, setUndoPayload] = useState();

  useEffect(() => {
    if (runWorkflow) {
      startWorkflow();
    }
    // eslint-disable-next-line
  }, [runWorkflow]);

  useEffect(() => {
    if (unarchiveMembersStatus === `success-${workflowId}`) {
      toggleSnackbar(true)();
    } else if (unarchiveMembersStatus === `failure-${workflowId}`) {
      toggleSnackbar(true)(true);
    }

    if (unarchiveGroupsStatus === `success-${workflowId}`) {
      toggleGroupSnackbar(true)();
    } else if (unarchiveGroupsStatus === `failure-${workflowId}`) {
      toggleGroupSnackbar(true)(true);
    }

    if (unarchiveGroupsStatus === `progress-${workflowId}`) {
      setSimilarContactsModal(true);
    }
    // eslint-disable-next-line
  }, [unarchiveMembersStatus, unarchiveGroupsStatus]);

  const handleContactState = () => {
    let multiContact = false;
    let totalContacts = 0;

    for (const item in selectedThreads) {
      if (
        selectedThreads[item].contacts_ids &&
        selectedThreads[item].contacts_ids.length > 1
      ) {
        multiContact = true;
      }
      if (selectedThreads[item]) {
        setSingleContactData(selectedThreads[item]);
      }
      totalContacts++;
    }
    const subThreadsKeys = selectedSubThreads
      ? Object.keys(selectedSubThreads)
      : [];
    if (multiContact || subThreadsKeys.length >= 1) {
      for (const key of subThreadsKeys) {
        totalContacts += selectedSubThreads[key].length;
      }
      if (
        Object.keys(singleContactData).length < 1 &&
        selectedSubThreads &&
        selectedSubThreads[subThreadsKeys[0]]
      ) {
        setSingleContactData(selectedSubThreads[subThreadsKeys[0]][0]);
      }
      onGroupItemsActions("", selectedThreads, {}, selectedSubThreads);
      multiContact = true;
    } else {
      onGroupItemsActions("", selectedThreads, {});
    }

    return {
      multiContact,
      totalContacts: numberOfSelectedPeople
        ? numberOfSelectedPeople
        : totalContacts,
    };
  };

  const setGlobalState = (empty) => {
    let multiContact = false;
    let totalContacts = 0;
    if (empty) {
      onGroupItemsActions("", {}, {}, {});
      return;
    }
    if (group) {
      const groupKeys = Object.keys(selectedGroups);
      totalContacts = groupKeys.length;
      setSingleGroupData(selectedGroups[groupKeys[0]]);
      /* Population of contacts, see for duplicate contacts */
      // for (const key of groupKeys) {
      // }
    } else {
      const aux = handleContactState();
      multiContact = aux.multiContact;
      totalContacts = aux.totalContacts;
    }

    return {
      multiContact,
      totalContacts,
    };
  };

  const handleStartWorkflowForGroups = () => {
    const groupKeys = Object.keys(selectedGroups);
    const focalGroupData = selectedGroups[groupKeys[0]];
    const doContainDuplicates = doGroupMembersContainDuplicates(
      groupKeys,
      groupMembers,
      contactsData
    );

    let headerContent = (
      <span>
        Unarchive Group
        {groupKeys.length === 1 ? (
          <>
            <span>: </span>
            <span style={{ position: "relative", top: "9px" }}>
              <Avatar
                isGroup
                isAdhocGroup={focalGroupData && focalGroupData.addhoc_id}
                isAllPeopleGroup={
                  focalGroupData && focalGroupData.my_group_status > 0
                }
                isMultipleContacts={false}
                firstName={""}
                lastName={""}
                email={""}
                bgColor={""}
              />
            </span>
            <span>{groupName(selectedGroups[groupKeys[0]])}</span>
            <span>{getGroupIcon(selectedGroups[groupKeys[0]])}</span>
          </>
        ) : (
          <span>s</span>
        )}
      </span>
    );
    const bodyContent = `Are you sure you want to unarchive ${
      groupKeys.length > 1 ? ` ${groupKeys.length} ` : "this"
    } ${groupKeys.length > 1 ? "Groups?" : "Group?"}`;
    setNeedSimilarContactsModal(doContainDuplicates);
    setOpenConfirmationModal(true);
    setConfirmationModalHeader(headerContent);
    setConfirmationModalContent(bodyContent);
  };

  const handleStartWorkflowForContacts = (multiContact, totalContacts) => {
    if (multiContact && totalContacts === 1) {
      setSimilarContactsModal(multiContact);
    } else if (confirmationModal && (!multiContact || totalContacts >= 1)) {
      setOpenConfirmationModal(true);
      setConfirmationModalHeader(
        `Unarchive ${totalContacts > 1 ? "People" : "Person"}`
      );
      setConfirmationModalContent(getConfirmationModalContent(totalContacts));
    } else if (multiContact && confirmationModal) {
      setNeedSimilarContactsModal(false);
      setOpenConfirmationModal(false);
    } else if (!confirmationModal) {
      proceedArchive(true, multiContact);
    }
  };

  const startWorkflow = () => {
    const { multiContact, totalContacts } = setGlobalState();
    if (group && selectedGroups) {
      handleStartWorkflowForGroups();
    } else {
      handleStartWorkflowForContacts(multiContact, totalContacts);
    }
  };

  const getConfirmationModalContent = useCallback(
    (totalContacts) => {
      if (totalContacts > 1) {
        const peopleCount = isGroupAllMembersSelected ? "All" : totalContacts;
        return `Are you sure you want to unarchive ${peopleCount} People?`;
      } else {
        return `Are you sure you want to unarchive ${getNameBy(
          Object.keys(selectedThreads),
          [],
          contactsData,
          groupsData
        )}?`;
      }
    },
    [contactsData, groupsData, selectedThreads, isGroupAllMembersSelected]
  );

  const isSelectedThreadItSelfDuplicate = useCallback((selectedThreads) => {
    for (let threadKey of Object.keys(selectedThreads)) {
      if (selectedThreads[threadKey]?.is_duplicate) {
        return true;
      }
    }
    return false;
  }, []);

  const proceedArchiveForGroup = (val, unarchiveContacts) => {
    if (val) {
      const groupIds = Object.keys(selectedGroups);
      const workflowId = `${singleGroupData.id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      setWorkflowId(workflowId);
      const undoData = {
        companyId,
        groupIds,
        contactIds: [],
        primaryAssignees: {},
        containDuplicates: false,
        workflowId,
        needRouting,
        contactsAlso: unarchiveContacts,
      };
      setUndoPayload(undoData);
      onUnarchiveGroups(
        companyId,
        groupIds,
        {},
        false,
        unarchiveContacts,
        workflowId,
        needRouting
      );
      setGlobalState(true);
    } else {
      onUnarchiveComplete();
    }
  };

  const proceedArchiveForContact = (val, duplicates) => {
    if (val && (similarContactsModal || duplicates)) {
      setSimilarContactsModal(true);
      setNeedSimilarContactsModal(duplicates);
    } else if (val) {
      const contactIds =
        allParams && allParams?.enabled
          ? []
          : Object.keys(selectedThreads).map((id) => parseInt(id));
      const containDuplicates =
        isSelectedThreadItSelfDuplicate(selectedThreads);
      const workflowId = `${singleContactData.id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      const undoData = {
        companyId,
        contactIds,
        groupIds: [],
        primaryAssignees: {},
        containDuplicates,
        workflowId,
        needRouting,
      };
      setUndoPayload(undoData);
      setWorkflowId(workflowId);
      onUnarchiveContacts(
        companyId,
        contactIds,
        [],
        {},
        containDuplicates,
        workflowId,
        needRouting,
        false,
        allParams
      );
      setGlobalState(true);
    } else {
      onUnarchiveComplete();
    }
  };

  const proceedArchive = (val, duplicates, unarchiveContacts = 0) => {
    setOpenConfirmationModal(false);
    setConfirmationModalHeader("");
    setConfirmationModalContent("");
    setSimilarContactsModal(false);

    if (group) {
      proceedArchiveForGroup(val, unarchiveContacts);
    } else {
      if (!duplicates)
        proceedArchiveForContact(
          val,
          isGroupAllMembersSelected
            ? false
            : doSelectedThreadsContainDuplicates(selectedThreads)
        );
    }
  };

  const finalizeArchiveForGroup = (primaryAssignees, selectedList) => {
    const workflowId = `${singleContactData.id}-${
      Math.floor(Math.random() * 50) + 1
    }`;
    setWorkflowId(workflowId);
    const groupIds = Object.keys(selectedGroups);
    const undoData = {
      companyId,
      groupIds,
      contactIds: selectedList,
      primaryAssignees,
      containDuplicates: true,
      workflowId,
      needRouting,
      contactsAlso: 1,
    };
    setUndoPayload(undoData);
    onUnarchiveGroups(
      companyId,
      groupIds,
      primaryAssignees,
      true,
      1,
      workflowId,
      needRouting,
      selectedList
    );
    setGlobalState(true);
  };

  const finalizeArchiveForContacts = (primaryAssignees, selectedList) => {
    const workflowId = `${singleContactData.id}-${
      Math.floor(Math.random() * 50) + 1
    }`;
    setWorkflowId(workflowId);
    const undoData = {
      companyId,
      contactIds: selectedList,
      groupIds: [],
      primaryAssignees: {},
      containDuplicates: true,
      workflowId,
      needRouting,
    };
    setUndoPayload(undoData);
    onUnarchiveContacts(
      companyId,
      selectedList,
      [],
      primaryAssignees,
      true,
      workflowId,
      needRouting,
      false,
      allParams
    );
    setGlobalState(true);
    return;
  };

  const finalizeArchive = (val, selectedList, primaryAssignees) => {
    setSimilarContactsModal(false);
    if (val) {
      if (group) {
        return finalizeArchiveForGroup(primaryAssignees, selectedList);
      } else {
        return finalizeArchiveForContacts(primaryAssignees, selectedList);
      }
    }
    setGlobalState(true);
    onUnarchiveComplete();
  };

  const toggleSnackbar = (val) => (failure) => {
    let msg;
    if (unarchivedContactsPathname) {
      msg =
        "Un-archiving contacts is in progress. Please wait for the process to complete.";
    } else if (failure) {
      const label =
        singleContactData.first_name || singleContactData.last_name
          ? `${singleContactData.first_name} ${singleContactData.last_name}`
          : singleContactData.phone_number;
      msg = `${label} couldn't be unarchived`;
    } else {
      if (unarchivedSuccessContacts?.length > 1 && failedToUnarchiveNumber) {
        msg = `You unarchived ${unarchivedSuccessContacts.length} People 
          ${failedToUnarchiveNumber} contacts needs to be manually unarchived`;
      } else if (unarchivedSuccessContacts.length > 1) {
        msg = `You unarchived ${unarchivedSuccessContacts.length} People.`;
      } else if (unarchivedSuccessContacts.length === 1 && singleContactData) {
        const label =
          singleContactData.first_name || singleContactData.last_name
            ? `${singleContactData.first_name} ${singleContactData.last_name}`
            : singleContactData.phone_number;
        msg = `You unarchived  ${label}`;
      } else {
        msg = `You unarchived ${unarchivedSuccessContacts.length} People.`;
      }
    }
    if (val) {
      let data = {};
      if (unarchivedContactsPathname) {
        data = {
          id: workflowId,
          msg,
          err: false,
        };
      } else {
        data = {
          id: workflowId,
          msg,
          err: failure,
          undoPayload,
        };
      }
      setSnackData(data, "unarchive-contact");
    }
    if (onUnarchiveComplete) {
      onUnarchiveComplete();
    }
  };

  const toggleGroupSnackbar = (val) => (failure) => {
    let msg;
    let msgMembers;
    const groupIds = Object.keys(selectedGroups);
    if (failure) {
      if (groupIds.length === 1) {
        msg = `${groupName(singleGroupData)} couldn't be unarchived`;
      } else {
        msg = `Error: Selected groups couldn't be unarchived`;
      }
    } else {
      if (unarchivedSuccessGroups.length > 1) {
        msg = `You unarchived ${unarchivedSuccessGroups.length} Groups.`;
      } else if (unarchivedSuccessGroups.length === 1 && singleGroupData) {
        msg = `You unarchived ${groupName(singleGroupData)}.`;
      } else {
        msg = `You unarchived ${unarchivedSuccessGroups.length} Groups.`;
      }
      if (unarchivedContactsInGroups.length > 1) {
        msgMembers = `You unarchived ${unarchivedContactsInGroups.length} People.`;
      } else if (unarchivedContactsInGroups.length === 1) {
        msgMembers = `You unarchived ${unarchivedContactsInGroups.length} Person.`;
      }
    }
    if (val) {
      const data = {
        id: workflowId,
        err: failure,
        msg,
        undoPayload,
      };
      if (unarchivedContactsInGroups.length >= 1) {
        data.extraSnackData = [
          {
            msg: msgMembers,
            id: workflowId,
            undoPayload: {
              companyId,
              contactIds: unarchivedContactsInGroups,
              primaryAssignees: data.undoPayload.primaryAssignees,
              containDuplicates: data.undoPayload.containDuplicates,
            },
          },
        ];
      }
      setGroupSnackData(data, "unarchive-group");
    }

    if (onUnarchiveComplete) {
      onUnarchiveComplete();
    }
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          show={openConfirmationModal}
          modalBodyContent={confirmationModalContent}
          onClose={proceedArchive} // for other actions we need to check in callback which action to proceed
          modalBodyHeader={confirmationModalHeader}
          isGroup={group}
          footerText="Also unarchive all People in this Group"
          unarchive
        />
      )}
      {similarContactsModal && (
        <SimilarContactsModal
          show={similarContactsModal}
          modalBodyContent={
            "We found multiple contacts using that number. Select the ones you want to unarchive."
          }
          modalBodyHeader={"Unarchive People"}
          onClose={finalizeArchive}
          sameActionPrefix="Unarchive"
          unarchive
          contactDuplicates={needSimilarContactsModal}
          isGroup={group}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Unarchive);
