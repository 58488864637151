import React from "react";
import PropTypes from "prop-types";
import CustomSwitch from "./CustomSwitch";
import { makeStyles } from "@material-ui/core";
import { useContext } from "react";
import ThemeContext from "../../../ThemeContext/ThemeContext";
import colors from "../../../assets/themedColors";

const SwitchWithLabel = (props) => {
  const { checked, onChange, label, icon, className, disabled } = props;
  const { globalTheme } = useContext(ThemeContext);

  const useStyles = makeStyles((theme) => ({
    label: {
      fontSize: 14,
      color: colors.iconMidGray[`${globalTheme}`],
      marginLeft: 10,
    },
  }));
  const classes = useStyles();

  return (
    <div className={`d-flex ${className}`}>
      <CustomSwitch checked={checked} onChange={onChange} disabled={disabled} />
      <span className={classes.label} label="true">
        {label}
      </span>
      {icon}
    </div>
  );
};

SwitchWithLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

SwitchWithLabel.defaultProps = {
  className: "",
  disabled: false,
};

export default SwitchWithLabel;
