import React from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../Helpers/ModalWrapper";
import BonusNotificationCountdown from "../../sidebar/BonusNotification/BonusNotificationCountdown";
import upgradePlanImage from "../../../assets/img/upgrade-plan-image.png";

const UpgradePlanModal = (props) => {
  const { show, closeModal } = props;
  return (
    <ModalWrapper
      show={show}
      closeModal={closeModal}
      className="upgrade-plan-wrapper"
    >
      <div className="upgrade-plan-container">
        <div className="upgrade-plan-image">
          <img src={upgradePlanImage} alt="upgrade-plan" />
        </div>
        <div className="upgrade-plan-text">
          <div className="title">X2 CREDITS!</div>
          <BonusNotificationCountdown />
          <div className="countdown-promo pt-1 pb-3">
            Remaining Until Promo is valid
          </div>
          <div className="pb-3">
            Upgrade now to get <b>x2 credits</b> for your first month! You have
            3 Days left for this offer!
          </div>
          <button className="btn btn-primary upgrade-plan-button">
            Upgrade Plan
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};
UpgradePlanModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
export default UpgradePlanModal;
