import React, { useCallback, useState, useMemo } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { PickerOverlay } from "filestack-react";
import { useNumbersStore } from "../../../../utils/hooks/store/useNumbersStore";
import useCompaniesStore from "../../../../utils/hooks/ReduxHooks/companiesStore";
import {
  getVideoFileInfo,
  getVideoHandler,
} from "../../../../utils/filestackHelpers";
import {
  askForAndroidCameraPermission,
  getThumbnailByHandler,
  isLargeFileUploaded,
  parseUploadedVideoData,
} from "../../../../utils/videoHelpers";
import {
  MAX_VIDEO_SIZE,
  acceptedVideoTypes,
} from "../../../modals/Video/videoConstants";
import { isTollFreeOrShortCodeNumberSelectedAsSenderNumber } from "../../../../utils/numbersHelpers";
import VideoCustomSource from "./VideoCustomSource/VideoCustomSource";
import useMediaStore from "../../../../utils/hooks/ReduxHooks/mediaStore";

const AddVideoFeature = (props) => {
  const {
    onVideoError,
    subscribersCount,
    videos,
    images,
    Component,
    componentProps,
    setIsVideoProcessing,
    isVideoProcessing,
    handleVideoLengthExceedData,
    handleUploadedVideoFileData,
    onAddVideos,
    className,
  } = props;
  const [openFileStackVideo, setOpenFileStackVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filestackDom, setFilestackDom] = useState(null);
  const [filestackActions, setFilestackActions] = useState(null);

  // Redux store
  const { numbers } = useNumbersStore();
  const { companies } = useCompaniesStore();
  const { media } = useMediaStore();
  const { videoSource } = media;
  const { senderNumber } = numbers;
  const { currentCompany } = companies;
  const mmsContactLimit = currentCompany.mms_contact_limit || 150;

  const isSenderNumberSelectedAsShortcodeOrTollFree = useMemo(
    () => isTollFreeOrShortCodeNumberSelectedAsSenderNumber(senderNumber),
    [senderNumber]
  );

  const handleVideoSourceMounted = useCallback((element, actions) => {
    setFilestackDom(element);
    setFilestackActions(actions);
  }, []);

  const successUploadImage = useCallback(
    async (result) => {
      try {
        if (result.filesUploaded.length) {
          setOpenFileStackVideo(false);
          const newVideos = [];
          for (let i = 0; i < result.filesUploaded.length; i++) {
            setIsVideoProcessing(true);
            const uploadedFile = result.filesUploaded[i];
            const videoIno = await getVideoFileInfo(uploadedFile.handle);
            const file = parseUploadedVideoData(videoIno, uploadedFile);

            const videoDuration = file?.result?.duration; // Duration in seconds

            const largeFileUploaded = isLargeFileUploaded(
              file?.result?.file_size,
              isSenderNumberSelectedAsShortcodeOrTollFree
            );
            setIsVideoProcessing(false);

            if (
              uploadedFile?.originalPath?.includes(
                "https://cdn.filestackcontent.com/"
              )
            ) {
              const uploadedVideoHandler = getVideoHandler(
                uploadedFile?.originalPath
              );
              const thumbnail = getThumbnailByHandler(
                videoSource,
                uploadedVideoHandler
              );
              const videoObj = Object.assign(
                {},
                uploadedFile,
                { thumbnail },
                { file: { url: uploadedFile?.originalPath } }
              );
              // If the video is uploaded from the video library
              newVideos.push(videoObj);
            } else if (largeFileUploaded && videoDuration > 25) {
              // 25 seconds is the max duration for a video we allowed to send
              handleVideoLengthExceedData(file);
            } else {
              handleUploadedVideoFileData(file);
            }
          }
          if (Boolean(newVideos.length)) {
            onAddVideos(newVideos);
          }
          setOpenFileStackVideo(false);
          onVideoError("");
        } else {
          setOpenFileStackVideo(false);
          setIsVideoProcessing(false);
        }
      } catch (error) {
        setIsVideoProcessing(false);
        onVideoError(error?.message || "Something went wrong");
      }
    },
    [
      handleVideoLengthExceedData,
      isSenderNumberSelectedAsShortcodeOrTollFree,
      onVideoError,
      setIsVideoProcessing,
      handleUploadedVideoFileData,
      onAddVideos,
      videoSource,
    ]
  );

  const toggleOpenFileStackVideo = useCallback(() => {
    askForAndroidCameraPermission();
    setOpenFileStackVideo(!openFileStackVideo);
    setLoading(true);
  }, [openFileStackVideo]);

  const onCloseFileStackVideo = useCallback(() => {
    setOpenFileStackVideo(false);
    setLoading(false);
  }, []);

  const customSource = {
    label: "Video Library",
    name: "videoCustomSource",
    icon: '<svg height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="18" cy="18" fill="#eee" r="18"/><path d="m9.9 18c0-1.71 1.39-3.1 3.1-3.1h4v-1.9h-4c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9h-4c-1.71 0-3.1-1.39-3.1-3.1zm4.1 1h8v-2h-8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" fill="#000" fill-opacity=".7"/></g></svg>',
    mounted: (element, actions) => handleVideoSourceMounted(element, actions),
    unmounted: (element) => {},
  };

  let allSize = 0;
  for (let i = 0; i < videos.length; i++) {
    allSize += videos[i]?.file?.size;
  }

  const isShortCode = senderNumber && senderNumber.src === 5;

  let fileStackVideosAvailable = true;
  let filestackVidNotAvailableMsg =
    "Live video up to 1 MB and/or 25 seconds; Attach as URL up to 500 MB;";

  if (
    subscribersCount > mmsContactLimit &&
    !isShortCode &&
    senderNumber.src !== 6
  ) {
    fileStackVideosAvailable = false;
    filestackVidNotAvailableMsg = `Not Available > ${mmsContactLimit} members via long code`;
  } else if (allSize >= MAX_VIDEO_SIZE) {
    fileStackVideosAvailable = false;
    filestackVidNotAvailableMsg = "Max 500MB";
  } else if (Boolean(images?.length)) {
    fileStackVideosAvailable = false;
    filestackVidNotAvailableMsg =
      "Unfortunately, it is not possible to send both images and videos as attachments at the same time. Please choose one or the other.";
  }

  const fileStackVideoOptions = {
    accept: acceptedVideoTypes,
    fromSources: ["video", customSource, "local_file_system"],
    customText: { "My Device": "Upload from Device" },
    maxSize: MAX_VIDEO_SIZE,
    maxFiles: 1,
    storeTo: {
      location: "s3",
    },
    onClose: onCloseFileStackVideo,
    onOpen: () => setLoading(false),
  };

  return (
    <>
      <Component
        onClick={
          fileStackVideosAvailable &&
          !isVideoProcessing &&
          !Boolean(videos?.length) &&
          !Boolean(images?.length)
            ? toggleOpenFileStackVideo
            : undefined
        }
        className={clsx(className, {
          "disabled-feature":
            !fileStackVideosAvailable ||
            isVideoProcessing ||
            Boolean(videos?.length) ||
            Boolean(images?.length),
          active: openFileStackVideo,
        })}
        tooltipTitle={
          <>
            <span>{filestackVidNotAvailableMsg}</span>
            {fileStackVideosAvailable && (
              <a
                href="https://help.pastorsline.com/en/articles/8379491-v3-adding-a-video-to-an-sms-message"
                target="_BLANK"
                rel="noreferrer"
              >
                {` more info here`}
              </a>
            )}
          </>
        }
        interactive={true}
        loading={loading}
        {...componentProps}
      />
      {openFileStackVideo && (
        <PickerOverlay
          apikey={process.env.REACT_APP_FILESTACK_APIKEY}
          pickerOptions={fileStackVideoOptions}
          onSuccess={successUploadImage}
          preload={true}
          componentDisplayMode={{
            type: "immediate",
          }}
        />
      )}

      {filestackDom &&
        createPortal(
          <VideoCustomSource
            actions={filestackActions}
            element={filestackDom}
          />,
          filestackDom
        )}
    </>
  );
};

AddVideoFeature.propTypes = {
  onVideoError: PropTypes.func.isRequired,
  setIsVideoProcessing: PropTypes.func.isRequired,
  handleVideoLengthExceedData: PropTypes.func.isRequired,
  videos: PropTypes.array,
  subscribersCount: PropTypes.number.isRequired,
  Component: PropTypes.any,
  componentProps: PropTypes.object,
  isVideoProcessing: PropTypes.bool,
};

AddVideoFeature.defaultProps = {
  componentProps: {},
  videos: [],
  images: [],
  isVideoProcessing: false,
};

export default AddVideoFeature;
