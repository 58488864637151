import { isNotAnEmptyArray } from "../utils/settingsHelpers";

const initialState = {
  // Create affiliate
  createAffiliateStatus: null,

  // fetch affiliate
  fetchAffiliateStatus: null,
  userAffiliate: {},

  // fetch affiliate sso link
  fetchAffiliateSsoLinkStatus: null,
  affiliateSsoLink: {},

  // fetch affiliate
  fetchAffiliateCommissionStatus: null,
  userAffiliateCommission: [],

  affiliateErrorMessage: null,

  // Referrals
  fetchReferralsStatus: null,
  fetchReferralsMoreStatus: null,
  referrals: [],
  fetchReferralsPage: 1,

  // Payouts
  fetchPayoutsStatus: null,
  payouts: [],
};

export default function affiliate(state = initialState, action) {
  switch (action.type) {
    case "AFFILIATE/CREATE_AFFILIATE":
      return {
        ...state,
        createAffiliateStatus: "loading",
      };

    case "AFFILIATE/CREATE_AFFILIATE.SUCCESS":
      return {
        ...state,
        createAffiliateStatus: "success",
      };

    case "AFFILIATE/CREATE_AFFILIATE.ERROR":
      return {
        ...state,
        createAffiliateStatus: "error",
        affiliateErrorMessage:
          action?.error?.data?.errors ||
          "Something went wrong, please try again later.",
      };

    case "AFFILIATE/CREATE_AFFILIATE.CLEAR":
      return {
        ...state,
        createAffiliateStatus: null,
        affiliateErrorMessage: null,
      };

    case "AFFILIATE/FETCH_AFFILIATE":
      return {
        ...state,
        fetchAffiliateStatus: "loading",
        affiliateErrorMessage: null,
      };

    case "AFFILIATE/FETCH_AFFILIATE.SUCCESS":
      return {
        ...state,
        fetchAffiliateStatus: "success",
        userAffiliate: action.payload?.affiliate || {},
      };

    case "AFFILIATE/FETCH_AFFILIATE.ERROR":
      return {
        ...state,
        fetchAffiliateStatus: "error",
        affiliateErrorMessage:
          action?.error?.data?.errors ||
          "Something went wrong, please try again later.",
      };

    case "AFFILIATE/FETCH_AFFILIATE_SSO_LINK":
      return {
        ...state,
        fetchAffiliateSsoLinkStatus: "loading",
        affiliateErrorMessage: null,
      };

    case "AFFILIATE/FETCH_AFFILIATE_SSO_LINK.SUCCESS":
      return {
        ...state,
        fetchAffiliateSsoLinkStatus: "success",
        affiliateSsoLink: action.payload?.sso || {},
      };

    case "AFFILIATE/FETCH_AFFILIATE_SSO_LINK.ERROR":
      return {
        ...state,
        fetchAffiliateSsoLinkStatus: "error",
        affiliateErrorMessage:
          action?.error?.data?.errors ||
          "Something went wrong, please try again later.",
      };

    case "AFFILIATE/FETCH_AFFILIATE.CLEAR":
      return {
        ...state,
        fetchAffiliateStatus: null,
        affiliateErrorMessage: null,
      };

    case "AFFILIATE/FETCH_AFFILIATE_COMMISSION_LIST":
      return {
        ...state,
        fetchAffiliateCommissionStatus: "loading",
        affiliateErrorMessage: null,
      };

    case "AFFILIATE/FETCH_AFFILIATE_COMMISSION_LIST.SUCCESS":
      return {
        ...state,
        fetchAffiliateCommissionStatus: "success",
        userAffiliateCommission: action?.payload?.data || [],
      };

    case "AFFILIATE/FETCH_AFFILIATE_COMMISSION_LIST.ERROR":
      return {
        ...state,
        fetchAffiliateCommissionStatus: "error",
        affiliateErrorMessage:
          action?.error?.data?.errors ||
          "Something went wrong, please try again later.",
      };

    case "AFFILIATE/FETCH_REFERRALS_LIST":
      return {
        ...state,
        fetchReferralsStatus: "loading",
        affiliateErrorMessage: null,
        fetchReferralsPage: action.page,
        fetchReferralsMoreStatus: action.page > 1 ? "loading" : "",
      };

    case "AFFILIATE/FETCH_REFERRALS_LIST.SUCCESS":
      return {
        ...state,
        fetchReferralsStatus: "success",
        referrals:
          action.page > 1
            ? [...state.referrals, ...action.payload?.data]
            : action?.payload?.data || [],
        fetchReferralsMoreStatus:
          action.page > 1 && !isNotAnEmptyArray(action?.payload?.data)
            ? "success"
            : "",
      };

    case "AFFILIATE/FETCH_REFERRALS_LIST.ERROR":
      return {
        ...state,
        fetchReferralsStatus: "error",
        fetchReferralsMoreStatus: action.page > 1 ? "error" : "",
      };

    case "AFFILIATE/FETCH_PAYOUTS_LIST":
      return {
        ...state,
        fetchPayoutsStatus: "loading",
      };

    case "AFFILIATE/FETCH_PAYOUTS_LIST.SUCCESS":
      return {
        ...state,
        fetchPayoutsStatus: "success",
        payouts: action?.payload?.data || [],
      };

    case "AFFILIATE/FETCH_PAYOUTS_LIST.ERROR":
      return {
        ...state,
        fetchPayoutsStatus: "error",
        affiliateErrorMessage:
          action?.error?.data?.errors ||
          "Something went wrong, please try again later.",
      };

    default:
      return state;
  }
}
