import React from "react";
import PropTypes from "prop-types";
//new icons
import { ReactComponent as KeyboardArrowLeft } from "../../../assets/img/icons-new/general/back/back-black.svg";
import { ReactComponent as KeyboardArrowRight } from "../../../assets/img/icons-new/general/arrow/right-arrow-black.svg";

const TablePaginationActions = (props) => {
  const {
    count,
    page,
    onChangePage,
    className,
    middleText,
    style,
    currentPageClassName,
  } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className={`pagination-bottom ${className}`} style={style}>
      <button
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="pagination-btns"
      >
        <KeyboardArrowLeft />
      </button>
      <div className="pagination-bottom-count">
        <span className={`mr-1 ${currentPageClassName}`}>{page + 1}</span>
        {middleText}
        <span className="ml-1">{count}</span>
      </div>
      <button
        onClick={handleNextButtonClick}
        disabled={page >= count - 1}
        aria-label="next page"
        className="pagination-btns"
      >
        <KeyboardArrowRight />
      </button>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  className: PropTypes.string,
  middleText: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  currentPageClassName: PropTypes.string,
};

TablePaginationActions.defaultProps = {
  className: "",
  middleText: "/",
  currentPageClassName: "",
};

export default TablePaginationActions;
