import React from "react";
import PropTypes from "prop-types";

//new icons
import { ReactComponent as CcbSvg } from "../../../assets/img/icons-new/people-info/ccb/ccb-gray.svg";
import { ReactComponent as PcoSvg } from "../../../assets/img/icons-new/people-info/pco/pco-gray.svg";
import { ReactComponent as BreezeSvg } from "../../../assets/img/icons-new/integration/breeze/breeze-dark-gray.svg";
import { ReactComponent as RockSvg } from "../../../assets/img/icons-new/integration/rockRMS/rockRMS-dark-gray.svg";
import { ReactComponent as McSvg } from "../../../assets/img/icons-new/people-info/mailchimp/mailchimp-white.svg";
import { ReactComponent as ElvantoSvg } from "../../../assets/img/icons-new/people-info/elvanto/elvanto-black.svg";
import SpanWithTooltip from "../../SpanWithTooltip";
import { getIntegrationIcon } from "./threadItemHelper";

const ThreadItemIntegrationIcons = (props) => {
  const {
    withContactDetails,
    withIntegrationIcon,
    group,
    isGroup,
    isMultipleContacts,
    contact,
    tab,
    handleIconTooltip,
  } = props;

  let integrationIconName = getIntegrationIcon(
    withContactDetails,
    withIntegrationIcon,
    isGroup,
    group,
    contact,
    isMultipleContacts
  );

  let integrationIcon;
  switch (integrationIconName) {
    case "CCB":
      integrationIcon = (
        <CcbSvg className={"icon-grey"} iconname={"CCB"} width={16} />
      );
      break;
    case "PCO":
      integrationIcon = (
        <PcoSvg className={"icon-grey"} iconname={"PCO"} width={16} />
      );
      break;
    case "MAILCHIMP":
      integrationIcon = (
        <McSvg className={"icon-grey"} iconname={"Mailchimp"} width={16} />
      );
      break;
    case "ELVANTO":
      integrationIcon = (
        <ElvantoSvg className={"icon-grey"} iconname={"Elvanto"} width={16} />
      );
      break;
    case "BREEZE":
      integrationIcon = (
        <BreezeSvg className={"icon-grey"} iconname={"Breeze"} width={8} />
      );
      break;
    case "ROCK":
      integrationIcon = (
        <RockSvg
          className={"icon-grey mb-1"}
          iconname={"RockRMS"}
          width={18}
          height={18}
        />
      );
      break;
    default:
      integrationIcon = null;
  }
  if (!integrationIconName) return <></>;
  return (
    <>
      {tab === "people" ||
      tab === "groups" ||
      tab === "messages" ||
      tab === "calls" ? (
        <SpanWithTooltip
          placement="top"
          title={integrationIcon.props.iconname}
          onMouseEnter={handleIconTooltip(true)}
          onMouseLeave={handleIconTooltip(false)}
          className="icon"
        >
          {integrationIcon}
        </SpanWithTooltip>
      ) : (
        <>{integrationIcon}</>
      )}
    </>
  );
};

ThreadItemIntegrationIcons.prototype = {
  withContactDetails: PropTypes.bool,
  withIntegrationIcon: PropTypes.bool,
  group: PropTypes.object,
  isGroup: PropTypes.bool,
  isMultipleContacts: PropTypes.bool,
  contact: PropTypes.object,
  tab: PropTypes.string,
  handleIconTooltip: PropTypes.func,
};

export default ThreadItemIntegrationIcons;
