import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { fetchGifts } from "../../../actions/dashboard";
// import PropTypes from "prop-types";
import { ReactComponent as GiftSvg } from "../../../assets/img/icons-new/dashboard/gift/gift-light-purple.svg";
import LegacyGiftClaimingModal from "../../modals/Gifts/LegacyGiftClaimingModal";
import ReadMoreReadLess from "../../elements/ReadMoreReadLess";

function mapStateToProps(store, ownProps) {
  return {
    giftsStatus: store.dashboard.giftsStatus,
    giftsData: store.dashboard.giftsData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGifts: () => dispatch(fetchGifts()),
  };
}

const GiftStaticWidget = (props) => {
  const {
    // Redux props
    giftsStatus,
    giftsData,
    // Redux func
    fetchGifts,
  } = props;

  const [legacyGiftClaimingModalIsOpen, setLegacyGiftClaimingModalIsOpen] =
    useState(false);

  const [firstMount, setFirstMount] = useState(false);

  useEffect(() => {
    if (giftsStatus !== "success" && !firstMount) {
      fetchGifts();
      setFirstMount(true);
    }
  }, [fetchGifts, firstMount, giftsStatus]);

  const giftMessage = useMemo(() => {
    return giftsData.map((gift, index) => (
      <Fragment key={gift.id}>
        {gift.note} <strong>{gift.credits} Credits</strong> from {gift.from} !
        {index !== giftsData.length - 1 && <br />}
      </Fragment>
    ));
  }, [giftsData]);

  return (
    <>
      <div className="gift-static-widget-container d-flex align-items-center">
        <div className="w-100">
          <div className="d-flex justify-content-center">
            <GiftSvg />
          </div>
          <div className="text-center mt-3 gift-static-widget-container-text">
            Congratulations!
            <br />
            <ReadMoreReadLess
              lineHeight={22}
              text={giftMessage}
              maxNoLines={2}
              isFixedHeight={false}
            >
              {giftMessage}
            </ReadMoreReadLess>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-primary"
              onClick={() => setLegacyGiftClaimingModalIsOpen(true)}
            >
              Claim Credits
            </button>
          </div>
        </div>
      </div>
      {legacyGiftClaimingModalIsOpen && (
        <LegacyGiftClaimingModal
          show={legacyGiftClaimingModalIsOpen}
          closeModal={() => {
            fetchGifts();
            setLegacyGiftClaimingModalIsOpen(false);
          }}
        />
      )}
    </>
  );
};

GiftStaticWidget.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(GiftStaticWidget);
