export const STANDARD_VIDEO_BITRATE = 256; // kilobits per secex
export const STANDARD_AUDIO_BITRATE = 32; // kilobits per sec

export const acceptedVideoTypes = [
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt",
  "video/H261",
  "video/H263",
  "video/H263-1998",
  "video/H263-2000",
  "video/H264",
];
export const MAX_VIDEO_SIZE = 524288000; // 500MB in bytes - 26000 additional bytes because of filestack

export const MMS_ATTACHMENT_SIZE_LIMIT_EXCEED_WARNING_TEXT =
  "The attached video size is too large. The MMS attachment limit for the Short code or toll-free number is 600 KB. Please use local number or reattach the video after selecting the Short code or Toll-free number.";

export const SHORT_MMS_ATTACHMENT_SIZE_LIMIT_EXCEED_WARNING_TOOLTIP =
  "The attached video size is too large. Please use local number or reattach the video after selecting the Short code or Toll-free number.";
