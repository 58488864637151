import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import ReactHtmlParser from "react-html-parser";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import MessagesCard from "./MessagesCard";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as OpenPanelIcon } from "../../../assets/img/icons-new/general/open-panel/open-panel-purple.svg";
import ViewAllGroupTableSearch from "../../hub/HubThreadGroupsComponents/HubThreadGroupsTableView/ViewAllGroupTableSearch";
import TableInfinityScroll from "../../hub/HubThreadViewComponents/TableInfinityScroll";
import TableHeader from "../../hub/HubThreadViewComponents/TableHeader";
import RecipientsTableViewBody from "./RecipientsTableView/RecipientsTableViewBody";
import SortingActions from "../../hub/SortingActions";

import useGroupsStore from "../../../utils/hooks/ReduxHooks/groupsStore";
import useMessagesStore from "../../../utils/hooks/ReduxHooks/messagesStore";
import { useContactsStore } from "../../../utils/hooks/ReduxHooks/contactsStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import { useWithTableWidth } from "../../../utils/hooks/useWithTableWidth";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import { GROUP } from "../../../utils/constants";
import { changePeopleSortKeys, isEmptyObject } from "../../../helpers";
import SimpleConfirmationModal from "../../modals/SimpleConfirmationModal";
import { getRemoveRecipientConfirmationModalTitleAndDesc } from "../../../utils/sendMessageHelpers";
import Spinner from "../../hub/HelperComponents/Spinner";
import RenderRecipientSidebarDesc from "./RecipientsTableView/RenderRecipientSidebarDesc";
import RecipientExcludedMembersView from "./RecipientsTableView/RecipientExcludedMembersView";

const soringList = {
  firstNameAsc: {
    label: "First Name",
    shortLabel: "FN",
  },
  firstNameDesc: {
    label: "First Name",
    shortLabel: "FN",
  },
  lastNameAsc: {
    label: "Last Name",
    shortLabel: "LN",
  },
  lastNameDesc: {
    label: "Last Name",
    shortLabel: "LN",
  },
  newest: {
    label: "Newest",
    shortLabel: "New",
  },
  oldest: {
    label: "Oldest",
    shortLabel: "Old",
  },
};

const RecipientsSideForm = (props) => {
  const {
    sidebar,
    handleToggleRecipients,
    recipientsCount,
    setRecipientsCount,
    mainGroupId,
    setMainGroupId,
    threadType,
    groupData,
    advFilters,
    setAdvFilters,
  } = props;

  // Redux store
  const {
    groups,
    fetchMembers,
    patchMembers,
    clearPatchGroupsStatus,
    clearAddGroup,
    clearGroupIsNewGroup,
    setCurrentMemberFilter,
  } = useGroupsStore();
  const { contacts } = useContactsStore();
  const {
    messages,
    addRecipientsForMessage,
    setRecipientToBeRemoved,
    removeRecipientForMessage,
    removeGroupsForMessage,
    setExistingGroupId,
    clearManuallyAddedContactIds,
    clearFilteredRecipients,
    clearRecipientGroupsForMessage,
  } = useMessagesStore();
  const { companies } = useCompaniesStore();
  const {
    filteredRecipients,
    recipientGroups,
    recipientToBeRemovedData,
    sendStatus,
    existingGroupId,
    manuallyAddedContactIds,
    exludedRecipients,
  } = messages;
  const {
    members,
    addId,
    patchMembersStatus,
    membersStatus,
    membersLoadedAll,
    membersPage,
    membersLoadingMoreStatus,
    data,
    dataMembersStatus,
    currentMemberFilters,
  } = groups;
  const contactsData = contacts.data;
  const companyId = companies.currentCompany.id;

  // Router
  const location = useLocation();
  const history = useHistory();

  const isLoadingInitialMembers = useMemo(() => {
    if (mainGroupId && sidebar) {
      return (
        membersStatus[mainGroupId] === "loading" &&
        membersLoadingMoreStatus[mainGroupId] !== "loading"
      );
    }
    return false;
  }, [mainGroupId, membersStatus, membersLoadingMoreStatus, sidebar]);

  const removeRecipientConfirmationModalData = useMemo(
    () =>
      getRemoveRecipientConfirmationModalTitleAndDesc(
        recipientToBeRemovedData?.group,
        existingGroupId
      ),
    [recipientToBeRemovedData, existingGroupId]
  );

  const renderMemberCounts = useMemo(() => {
    if (filteredRecipients?.length === 1 && !groupData) {
      return 1;
    }
    return groupData?.members_count;
  }, [filteredRecipients, groupData]);

  const [query, setQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [columnSelectionState, setColumnSelectionState] = useState({
    sortBy: true,
    addedBy: true,
    action: true,
  });
  const [selectedViewingFilter, setSelectedViewingFilter] = useState({});
  const [sortingFilter, setSortingFilter] = useState("firstNameAsc");
  const [sortedRecipients, setSortedRecipients] = useState(filteredRecipients);

  const hasAdvanceFilters = useMemo(
    () => isNotAnEmptyArray(advFilters),
    [advFilters]
  );

  // Refs
  const searchTimer = useRef();
  const searchRef = useRef();
  const countryRef = useRef();

  const { tableRef } = useWithTableWidth();

  const getFilteredRecipients = useMemo(() => {
    if (selectedViewingFilter?.value === "all-excluded") {
      return exludedRecipients;
    }
    return sortedRecipients;
  }, [exludedRecipients, selectedViewingFilter, sortedRecipients]);

  const isExcludedFilterActive = useMemo(
    () => selectedViewingFilter?.value === "all-excluded",
    [selectedViewingFilter]
  );

  const filteredRecipientTags = useMemo(() => {
    if (isNotAnEmptyArray(recipientGroups)) {
      return recipientGroups.filter((group) =>
        group?.group_name?.endsWith("-tag")
      );
    }
    return [];
  }, [recipientGroups]);

  const filterdRecipientGroups = useMemo(() => {
    if (isNotAnEmptyArray(recipientGroups)) {
      return recipientGroups.filter(
        (group) => !group?.group_name?.endsWith("-tag")
      );
    }
    return [];
  }, [recipientGroups]);

  const viewingFilters = useMemo(() => {
    const defaultOptions = [
      {
        value: "all-recipients",
        name: `All Recipients (${renderMemberCounts})`,
      },
      {
        value: "added-groups",
        name: `Added Groups (${filterdRecipientGroups.length})`,
      },
    ];

    if (isNotAnEmptyArray(filteredRecipientTags)) {
      defaultOptions.push({
        value: "added-tags",
        name: `Added Tags (${filteredRecipientTags.length})`,
      });
    }

    if (groupData?.members_excluded > 0) {
      defaultOptions.push({
        value: "all-excluded",
        name: `All Excluded (${groupData?.members_excluded || 0})`,
      });
    }

    return defaultOptions;
  }, [
    renderMemberCounts,
    filterdRecipientGroups,
    filteredRecipientTags,
    groupData?.members_excluded,
  ]);

  const handleSelectSystemExcluded = useCallback(() => {
    setSelectedViewingFilter({
      value: "all-excluded",
      name: `All Excluded (${groupData?.members_excluded || 0})`,
    });
  }, [groupData]);

  useEffect(() => {
    // Set default viewing filter
    setSelectedViewingFilter(viewingFilters[0]);
  }, [viewingFilters]);

  useEffect(() => {
    // Check if the hash is for showing excluded members and set the excluded member filter
    if (location?.hash === "#show-excluded-members") {
      handleSelectSystemExcluded();
      history.push(location.pathname);
    }
  }, [handleSelectSystemExcluded, history, location]);

  const toggleSearch = useCallback((val) => {
    setShowSearch(val);
  }, []);

  const queryChanged = useCallback(() => {
    let query = searchRef.current.value;
    let result = [];
    if (selectedViewingFilter?.value === "added-groups") {
      result = filterdRecipientGroups.filter((name) =>
        name.group_name.toLowerCase().includes(query.toLowerCase())
      );
    } else if (selectedViewingFilter?.value === "added-tags") {
      result = filteredRecipientTags.filter((name) =>
        name.group_name?.toLowerCase()?.includes(query?.toLowerCase())
      );
    } else {
      const names = filteredRecipients.map((rec) => ({
        ...rec,
        full_name: `${rec.first_name} ${rec.last_name}`,
      }));
      result = names.filter((name) =>
        name.full_name.toLowerCase().includes(query.toLowerCase())
      );
    }
    setSortedRecipients(result);
  }, [
    filteredRecipients,
    filterdRecipientGroups,
    setSortedRecipients,
    selectedViewingFilter,
    filteredRecipientTags,
  ]);

  const handleQueryChange = useCallback(
    (event) => {
      clearTimeout(searchTimer.current);
      setQuery(event.target.value);
      searchTimer.current = setTimeout(queryChanged, 500);
    },
    [queryChanged]
  );

  const onClickedCancelSearch = useCallback(() => {
    toggleSearch(false);
    searchRef.current.value = "";
    setQuery("");
    queryChanged();
  }, [toggleSearch, setQuery, queryChanged]);

  const onChangeColumSelection = useCallback((type) => {
    setColumnSelectionState((oldState) => ({
      ...oldState,
      [type]: !oldState[type],
    }));
  }, []);

  const onChangeSortingFilter = useCallback(
    (label) => {
      setSortingFilter(label);
      const filters =
        selectedViewingFilter?.value === "all-excluded" && !hasAdvanceFilters
          ? "excluded"
          : null;
      if (!hasAdvanceFilters) {
        fetchMembers(
          companyId,
          mainGroupId,
          20,
          1,
          filters,
          changePeopleSortKeys(label)
        );
      }
    },
    [
      companyId,
      fetchMembers,
      mainGroupId,
      selectedViewingFilter,
      hasAdvanceFilters,
    ]
  );

  const getColumnSelectionFields = useMemo(() => {
    let secondCellText = "";
    if (selectedViewingFilter?.value === "added-groups") {
      secondCellText = "Recipient's Info";
    } else if (selectedViewingFilter?.value === "all-excluded") {
      secondCellText = "Reason for Exclusion";
    } else {
      secondCellText = "Added By";
    }
    return [
      {
        text: (
          <div className="flexer-row gap-2">
            <span>Sort By:</span>
            {["all-excluded", "all-recipients"].includes(
              selectedViewingFilter?.value
            ) && (
              <SortingActions
                listItems={soringList}
                handleActiveItem={onChangeSortingFilter}
                activeItem={sortingFilter}
              />
            )}
          </div>
        ),
        stateProp: columnSelectionState.sortBy,
        nameStateProp: "sortBy",
        showSortArrows: false,
      },
      {
        text: secondCellText,
        stateProp: columnSelectionState.addedBy,
        nameStateProp: "addedBy",
        showSortArrows: true,
      },
      {
        text: "Action",
        stateProp: columnSelectionState.action,
        nameStateProp: "action",
        showSortArrows: false,
      },
    ];
  }, [
    columnSelectionState,
    sortingFilter,
    onChangeSortingFilter,
    selectedViewingFilter,
  ]);

  const onChangeViewingFilter = useCallback(
    (value) => {
      const selectValueFind = viewingFilters.find((num) => num.value === value);
      if (selectValueFind) {
        setSelectedViewingFilter(selectValueFind);
      }
      if (value === "all-recipients") {
        setSortedRecipients(filteredRecipients);
      } else if (value === "added-groups") {
        setSortedRecipients(filterdRecipientGroups);
      } else if (value === "added-tags") {
        setSortedRecipients(filteredRecipientTags);
      } else {
        // This will be for 'added-tags' and 'all-excluded' once logic is ready
        setSortedRecipients(filteredRecipients);
      }
    },
    [
      filterdRecipientGroups,
      filteredRecipientTags,
      filteredRecipients,
      viewingFilters,
    ]
  );

  const loadMore = useCallback(() => {
    if (
      !membersLoadedAll[mainGroupId] &&
      membersStatus[mainGroupId] !== "loading" &&
      membersStatus[mainGroupId] !== "error"
    ) {
      const filters =
        selectedViewingFilter?.value === "all-excluded" && !hasAdvanceFilters
          ? "excluded"
          : null;
      const advanceFilters = hasAdvanceFilters ? advFilters : null;
      fetchMembers(
        companyId,
        mainGroupId,
        20,
        membersPage[mainGroupId] + 1,
        filters,
        changePeopleSortKeys(sortingFilter),
        advanceFilters
      );
    }
  }, [
    companyId,
    fetchMembers,
    membersLoadedAll,
    membersPage,
    membersStatus,
    mainGroupId,
    selectedViewingFilter,
    sortingFilter,
    advFilters,
    hasAdvanceFilters,
  ]);

  const populateMembersData = useCallback(
    (membersStatus, members, contactsData, recipientGroups) => {
      let membersData = [];
      if (
        membersStatus === "success" &&
        typeof members !== "undefined" &&
        members &&
        contactsData
      ) {
        const isOnExcludedMembersFilter =
          selectedViewingFilter?.value === "all-excluded";
        const membersIds = members;
        membersData = [];
        for (let i = 0; i < membersIds.length; i++) {
          if (typeof contactsData[membersIds[i]] !== "undefined") {
            let groupMemberData = contactsData[membersIds[i]];
            if (groupMemberData && groupMemberData?.has_duplicate === 1) {
              const duplicateMembersIds =
                groupMemberData?.app_contact_ids &&
                groupMemberData?.app_contact_ids?.filter(
                  (id) => id !== membersIds[i]
                );
              if (
                duplicateMembersIds &&
                Boolean(duplicateMembersIds?.length) &&
                groupMemberData
              ) {
                const duplicateMembersData = duplicateMembersIds.map((id) => {
                  return contactsData[id];
                });
                groupMemberData.duplicateMembersData = duplicateMembersData;
              }
            }

            const groupsInRecipients = groupMemberData.contact_groups.filter(
              (rec) => {
                return recipientGroups.some(
                  (group) => group.id === rec.group.id
                );
              }
            );

            const isManuallyContactIdsExistInMemberGroup =
              groupMemberData.contact_groups &&
              Array.isArray(groupMemberData.contact_groups) &&
              groupMemberData.contact_groups?.some((memberGroup) =>
                manuallyAddedContactIds?.includes(memberGroup?.group?.id)
              );
            const isManuallyAdded =
              manuallyAddedContactIds?.includes(groupMemberData.id) ||
              isManuallyContactIdsExistInMemberGroup;

            const filteredGroups = groupsInRecipients.filter(
              (group) => !group.group?.group_name?.endsWith("-tag")
            );
            const filteredTags = groupsInRecipients.filter((group) =>
              group.group?.group_name?.endsWith("-tag")
            );

            const groupText =
              filteredGroups?.length === 1
                ? `${filteredGroups?.length} Group`
                : `${filteredGroups?.length || 1} Groups`;

            const tagsText = isNotAnEmptyArray(filteredTags)
              ? `${filteredTags?.length} Tag(s)`
              : "";

            let addedByText = "";
            if (isManuallyAdded) {
              addedByText = "Manually";
            } else {
              addedByText = groupText;
              groupMemberData.added_by_tags_count = tagsText;
              groupMemberData.added_by_tags = filteredTags;
            }

            groupMemberData.added_by = addedByText;
            groupMemberData.added_by_groups = filteredGroups;
            membersData.push(groupMemberData);
          }
        }
        addRecipientsForMessage(membersData, isOnExcludedMembersFilter);
      }
    },
    [addRecipientsForMessage, manuallyAddedContactIds, selectedViewingFilter]
  );

  useEffect(() => {
    // Populate members data
    if (!sendStatus && mainGroupId > 0) {
      populateMembersData(
        membersStatus[mainGroupId],
        members[mainGroupId],
        contactsData,
        recipientGroups
      );
    }
  }, [
    populateMembersData,
    membersStatus,
    members,
    mainGroupId,
    contactsData,
    recipientGroups,
    sendStatus,
  ]);

  useEffect(() => {
    // Set recipients count based on the selected filter
    if (
      threadType === GROUP &&
      !isEmptyObject(dataMembersStatus) &&
      !isEmptyObject(data) &&
      dataMembersStatus[mainGroupId] === "success"
    ) {
      const currentGroup = data[mainGroupId] || groupData || {};
      setRecipientsCount(currentGroup?.members_msg_receivers || 0);
    } else {
      setRecipientsCount(filteredRecipients?.length || 0);
    }
    onChangeViewingFilter(selectedViewingFilter?.value);
  }, [
    dataMembersStatus,
    filteredRecipients,
    groupData,
    data,
    mainGroupId,
    setRecipientsCount,
    threadType,
    onChangeViewingFilter,
    selectedViewingFilter,
  ]);

  useEffect(() => {
    // Fetch members based on the selected filter
    if (mainGroupId && mainGroupId > 0 && sidebar) {
      if (
        selectedViewingFilter?.value === "all-excluded" &&
        (!currentMemberFilters || hasAdvanceFilters)
      ) {
        const excludedFilter = hasAdvanceFilters ? null : "excluded";
        const advanceFilters = hasAdvanceFilters ? advFilters : null;
        fetchMembers(
          companyId,
          mainGroupId,
          20,
          1,
          excludedFilter,
          changePeopleSortKeys(sortingFilter),
          advanceFilters
        );
      } else if (
        selectedViewingFilter?.value === "all-recipients" &&
        currentMemberFilters
      ) {
        fetchMembers(
          companyId,
          mainGroupId,
          20,
          1,
          null,
          changePeopleSortKeys(sortingFilter)
        );
      }
    }
  }, [
    companyId,
    fetchMembers,
    mainGroupId,
    selectedViewingFilter?.value,
    sidebar,
    currentMemberFilters,
    sortingFilter,
    advFilters,
    hasAdvanceFilters,
  ]);

  const clearGroupIdIfRemoveAllRecipients = useCallback(() => {
    const filteredGroups = recipientGroups.filter(
      (group) => group.id !== recipientToBeRemovedData?.group?.id
    );

    if (
      filteredRecipients?.length ===
        recipientToBeRemovedData?.contacts?.length &&
      filteredGroups?.length === 0
    ) {
      setMainGroupId(null);
      setExistingGroupId(null);
      clearAddGroup();
      clearGroupIsNewGroup();
      clearManuallyAddedContactIds();
    }
  }, [
    clearAddGroup,
    filteredRecipients,
    recipientGroups,
    recipientToBeRemovedData,
    setExistingGroupId,
    setMainGroupId,
    clearManuallyAddedContactIds,
    clearGroupIsNewGroup,
  ]);

  useEffect(() => {
    // fetch updated members after removing the member
    if (
      patchMembersStatus === "success" &&
      mainGroupId !== null &&
      !isEmptyObject(recipientToBeRemovedData)
    ) {
      fetchMembers(
        companyId,
        mainGroupId,
        20,
        1,
        null,
        changePeopleSortKeys(sortingFilter)
      );
      setRecipientToBeRemoved({});
      clearPatchGroupsStatus();
      if (!isEmptyObject(recipientToBeRemovedData.group)) {
        removeGroupsForMessage(recipientToBeRemovedData.group);
      }
      if (
        recipientToBeRemovedData?.contacts &&
        isNotAnEmptyArray(recipientToBeRemovedData?.contacts)
      ) {
        removeRecipientForMessage(recipientToBeRemovedData?.contacts);
      }

      clearGroupIdIfRemoveAllRecipients();
    }
  }, [
    clearGroupIdIfRemoveAllRecipients,
    clearPatchGroupsStatus,
    companyId,
    fetchMembers,
    mainGroupId,
    patchMembersStatus,
    recipientToBeRemovedData,
    removeGroupsForMessage,
    removeRecipientForMessage,
    setRecipientToBeRemoved,
    sortingFilter,
  ]);

  const onClickCloseExludedFilter = useCallback(() => {
    setSelectedViewingFilter({
      value: "all-recipients",
      name: `All Recipients (${renderMemberCounts})`,
    });
  }, [renderMemberCounts]);

  const onClickAdvanceFilters = useCallback(
    (filter = "") =>
      () => {
        if (filter === "all") {
          setAdvFilters([]);
          setCurrentMemberFilter(null);
        } else {
          const advanceFilters = ["and", ["excluded", filter]];
          setAdvFilters(advanceFilters);
        }
      },
    [setAdvFilters, setCurrentMemberFilter]
  );

  const handleCloseRemoveRecipientConfirmationModal = useCallback(() => {
    setRecipientToBeRemoved({});
  }, [setRecipientToBeRemoved]);

  const clearAllDetails = useCallback(() => {
    clearRecipientGroupsForMessage();
    setRecipientToBeRemoved({});
    clearFilteredRecipients();
    setMainGroupId(null);
    setExistingGroupId(null);
    clearAddGroup();
    clearGroupIsNewGroup();
    clearManuallyAddedContactIds();
    if (sidebar) {
      handleToggleRecipients();
    }
  }, [
    clearAddGroup,
    clearGroupIsNewGroup,
    clearFilteredRecipients,
    clearManuallyAddedContactIds,
    clearRecipientGroupsForMessage,
    setExistingGroupId,
    setMainGroupId,
    setRecipientToBeRemoved,
    handleToggleRecipients,
    sidebar,
  ]);

  const handleRemoveRecipient = useCallback(() => {
    const idToUse = existingGroupId || addId;
    const removeContactIds =
      recipientToBeRemovedData.contacts &&
      Array.isArray(recipientToBeRemovedData.contacts)
        ? recipientToBeRemovedData.contacts.map((rec) => rec.id)
        : [];
    if (
      (existingGroupId && !isEmptyObject(recipientToBeRemovedData.group)) ||
      (isNotAnEmptyArray(recipientToBeRemovedData.contacts) &&
        isEmptyObject(recipientToBeRemovedData.group) &&
        filteredRecipients?.length === 1 &&
        groupData == null)
    ) {
      clearAllDetails();
    } else if (isNotAnEmptyArray(removeContactIds) && idToUse) {
      patchMembers(
        companyId,
        idToUse,
        [],
        [],
        removeContactIds,
        null,
        null,
        null,
        null,
        true
      );
    } else if (
      !isNotAnEmptyArray(recipientToBeRemovedData.contacts) &&
      !isEmptyObject(recipientToBeRemovedData.group)
    ) {
      removeGroupsForMessage(recipientToBeRemovedData.group);
      setRecipientToBeRemoved({});
      clearGroupIdIfRemoveAllRecipients();
    }
  }, [
    addId,
    clearAllDetails,
    clearGroupIdIfRemoveAllRecipients,
    companyId,
    existingGroupId,
    filteredRecipients,
    groupData,
    patchMembers,
    recipientToBeRemovedData,
    removeGroupsForMessage,
    setRecipientToBeRemoved,
  ]);

  return (
    <>
      <div
        className={`message-view chat-profile-view chat-sidebar chat-sidebar__recipients ${
          sidebar ? "" : "minimized-right-panel"
        }`}
        id="chat_sidebar"
      >
        <div className="chat-window video-window right-panel">
          <div className="tab-content chat-contents position-relative d-flex flex-column">
            <div className="d-flex align-items-center p-2 border-bottom">
              <ButtonWithTooltip
                onClick={handleToggleRecipients}
                placement="top"
                title="Minimize"
                className="bg-transparent"
                style={{
                  outline: "none",
                  border: "none",
                }}
              >
                <OpenPanelIcon width="24" className="themed-color" />
              </ButtonWithTooltip>

              <span className="mx-3 messages-card__label mb-0">Viewing:</span>
              <MessagesCard
                popoverId={"recipients-side-form-viewing"}
                type="select"
                options={viewingFilters}
                selected={selectedViewingFilter}
                onChangeSelectValue={onChangeViewingFilter}
                withRedLabel={selectedViewingFilter?.value === "all-excluded"}
              />
            </div>
            <div className="chat-sidebar__body d-flex flex-wrap border-bottom">
              <div className="w-100 w-sm-50">
                <p className="mb-0 chat-sidebar__recipients-title">
                  {isExcludedFilterActive ? (
                    <strong>
                      {groupData?.members_excluded} Excluded Contacts
                    </strong>
                  ) : (
                    <strong>{recipientsCount} Text Likely Recipients</strong>
                  )}
                </p>
                <p className="messages-card__label m-0">
                  {isExcludedFilterActive
                    ? `System Excluded:`
                    : `${renderMemberCounts} total profiles`}{" "}
                  {/* Excluded members view */}
                  <RecipientExcludedMembersView
                    groupData={groupData}
                    isExcludedFilterActive={isExcludedFilterActive}
                    handleSelectSystemExcluded={handleSelectSystemExcluded}
                    onClickCloseExludedFilter={onClickCloseExludedFilter}
                    advFilters={advFilters}
                    onClickAdvanceFilters={onClickAdvanceFilters}
                  />
                </p>
                <RenderRecipientSidebarDesc
                  currentFilter={selectedViewingFilter?.value || ""}
                />
              </div>
              <div className="d-flex align-items-end justify-content-end w-100 w-sm-50">
                <ViewAllGroupTableSearch
                  showSearch={showSearch}
                  searchRef={searchRef}
                  countryRef={countryRef}
                  query={query}
                  onChangeQuery={handleQueryChange}
                  onClickedCancelSearch={onClickedCancelSearch}
                  toggleSearch={toggleSearch}
                  handleCountryCodeChange={() => {}}
                  setNumberPasted={() => {}}
                />
              </div>
            </div>
            {isLoadingInitialMembers ? (
              <div className="d-flex align-items-center justify-content-center w-100">
                <Spinner />
              </div>
            ) : (
              <TableInfinityScroll
                tableRef={tableRef}
                orderBy={{}}
                page={membersPage}
                loadMore={loadMore}
              >
                <table className="table">
                  <TableHeader
                    hideCheckBoxCell
                    cells={getColumnSelectionFields}
                    onChangeColumSelection={onChangeColumSelection}
                  />
                  <RecipientsTableViewBody
                    columnSelectionState={columnSelectionState}
                    filteredRecipients={getFilteredRecipients}
                    mainGroupId={mainGroupId}
                    setMainGroupId={setMainGroupId}
                    isLoadingMore={
                      membersLoadingMoreStatus[mainGroupId] === "loading"
                    }
                    selectedViewingFilter={selectedViewingFilter}
                  />
                </table>
              </TableInfinityScroll>
            )}
          </div>
        </div>
      </div>
      {!isEmptyObject(recipientToBeRemovedData) && (
        <SimpleConfirmationModal
          shakeModal={() => {}}
          show={!isEmptyObject(recipientToBeRemovedData)}
          closeModal={handleCloseRemoveRecipientConfirmationModal}
          onClickConfirm={handleRemoveRecipient}
          title={removeRecipientConfirmationModalData.title}
          description={ReactHtmlParser(
            removeRecipientConfirmationModalData.desc || ""
          )}
          loadingStatus={patchMembersStatus}
          confirmationButtonText="Yes, Remove"
          confirmBtnClassName={"button-delete"}
        />
      )}
    </>
  );
};

export default RecipientsSideForm;
