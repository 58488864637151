import {
  fetchNumbers,
  fetchSignatures,
  fetchSignatureMergeFields,
} from "../../actions/numbers";
import { UNASSIGNED_NUMBER } from "../../utils/constants";
import { store } from "../../store";

export const onReloadNumbers = () => {
  const callback = (config, dispatch, data) => {
    dispatch(fetchNumbers(config.companyId));
  };

  return {
    event: "numbers-reload",
    timeout: 750,
    callback,
  };
};

export const onReloadSignatureMergeFields = () => {
  const callback = (config, dispatch, data) => {
    const { companyId } = config;
    const { numbers, users } = store.getState();

    const signatureUserNumber = numbers?.senderNumber?.number;
    const loggedUserId = users?.loggedUser?.id;

    dispatch(
      fetchSignatureMergeFields(companyId, loggedUserId, signatureUserNumber)
    );
  };
  return {
    event: "merge-fields-reload",
    timeout: 750,
    callback,
  };
};

export const onReloadSignatures = () => {
  const callback = (config, dispatch, data) => {
    const { companyId } = config;
    const { numbers } = store.getState();
    const signatureNumbers = numbers?.numbers;
    dispatch(
      fetchSignatures(companyId, [
        ...signatureNumbers.map((number) => number.number),
        UNASSIGNED_NUMBER,
      ])
    );
  };
  return {
    event: "signatures-reload",
    timeout: 750,
    callback,
  };
};
