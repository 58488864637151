import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotifierWrapper from "./NotifierWrapper";

//new icons
import { ReactComponent as AlertSvg } from "../../../../assets/img/icons-new/general/alert/alert-white.svg";
import { ReactComponent as AlertSvgBlack } from "../../../../assets/img/icons-new/number-status/show-info/show-info-black.svg";
import { ReactComponent as AlertSvgWhite } from "../../../../assets/img/icons-new/number-status/show-info/show-info-white.svg";
import { ReactComponent as ShowInfo } from "../../../../assets/img/icons-new/number-status/show-info/show-info-black.svg";

import { hasNumberAssigned } from "../../../../helpers";
import { useIsAdminOrOwner } from "../../../../utils/hooks/useIsAdminOrOwner";
import { ASSIGNED_NUMBER_ALERT_LINK } from "../../../../utils/constants";
import AlertNotifierAccountIssue from "./AlertNotifierAccountIssue";
import { useAccountIssueInfo } from "../../../../utils/hooks/useAccountIssueInfo";

function mapStateToProps(store, ownProps) {
  return {
    sendError: store.messages.sendError,
    sendErrorStatus: store.messages.sendErrorStatus,
    currentCompany: store.companies.currentCompany,
    senderNumber: store.numbers.senderNumber,
    numbers: store.numbers.numbers,
  };
}

const getNoShorteningLinksAlertText = (senderNumber) => {
  if (senderNumber?.src === 6) return "shared verified toll free number";
  if (senderNumber?.src === 5) return "short code";
  if (senderNumber?.is_high_throughput_number)
    return "verified toll free number";
  return "";
};

const AlertNotifier = (props) => {
  const {
    warningText,
    imagesErrorText,
    videoErrorText,
    throttleWarning,
    showSharedNumberAlert,
    showNoShorteningLinksWarning,
    showSendError,
    setCurrentModal,
    // Redux props
    sendError,
    sendErrorStatus,
    currentCompany,
    senderNumber,
    numbers,
  } = props;

  const withAssignedNumber = useMemo(
    () => hasNumberAssigned(numbers),
    [numbers]
  );
  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);
  const accountIssueInfo = useAccountIssueInfo();

  const alertsData = {
    shortCodeAlert: {
      message: `You are attempting to send to ${
        throttleWarning.subscribersCount
      } people throttled at 1 msg / ${throttleWarning.throttleWarningSecs}
      secs. To avoid carrier violation and process the message faster,
      consider using our
      ${currentCompany.country_code_setting === "US" ? "short code or" : ""}
      verified toll free number.`,
      link: "https://help.pastorsline.com/en/articles/3715588-how-to-send-a-message-from-the-short-code",
      linkText: "Learn more",
    },
    noShorteningLinksAlert: {
      message: `When using the
           ${getNoShorteningLinksAlertText(senderNumber)},
           URL Links would not be automatically shortened.`,
    },
    sharedNumberAlert: {
      message: `You can test most of the features with this shared test number.
      Unfortunately, you will not be able to receive texts or calls.`,
      link: "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing",
      linkText: "Click here",
      textAfterLink: "to upgrade",
    },
  };

  const showAlert = useCallback(
    (
      message,
      icon,
      className,
      linkText,
      link,
      hasAlert,
      textClassName,
      textAfterLink
    ) => {
      return (
        <NotifierWrapper
          alertMessage={message}
          Icon={icon}
          className={className}
          linkText={linkText}
          hasAlert={hasAlert}
          link={link}
          textClassName={textClassName}
          textAfterLink={textAfterLink}
        />
      );
    },
    []
  );

  if (!withAssignedNumber) {
    return showAlert(
      `You have Not Set Up An Assigned Number.${
        !isAdminOrOwner ? " Contact an Admin" : ""
      }`,
      ShowInfo,
      "warning align-items-center",
      isAdminOrOwner ? "Go To Admin Settings" : null,
      isAdminOrOwner ? ASSIGNED_NUMBER_ALERT_LINK : null,
      !withAssignedNumber,
      null
    );
  }

  if (showSendError) {
    let alertColorClass = "danger";
    if (sendErrorStatus === 4) {
      alertColorClass = "info";
    }

    return showAlert(
      sendError,
      AlertSvgWhite,
      alertColorClass,
      null,
      null,
      showSendError,
      null
    );
  }

  if (showNoShorteningLinksWarning) {
    return showAlert(
      alertsData["noShorteningLinksAlert"].message,
      AlertSvgWhite,
      "danger",
      null,
      null,
      showNoShorteningLinksWarning,
      "font-italic"
    );
  }

  if (accountIssueInfo) {
    return (
      <AlertNotifierAccountIssue
        accountIssueInfo={accountIssueInfo}
        setCurrentModal={setCurrentModal}
      />
    );
  }

  if (showSharedNumberAlert)
    return showAlert(
      alertsData["sharedNumberAlert"].message,
      AlertSvg,
      "info",
      alertsData["sharedNumberAlert"].linkText,
      alertsData["sharedNumberAlert"].link,
      showSharedNumberAlert,
      null,
      alertsData["sharedNumberAlert"].textAfterLink
    );

  if (throttleWarning?.show)
    return showAlert(
      alertsData["shortCodeAlert"].message,
      AlertSvgBlack,
      "warning",
      alertsData["shortCodeAlert"].linkText,
      alertsData["shortCodeAlert"].link,
      throttleWarning?.show,
      null,
      null
    );

  if (imagesErrorText)
    return showAlert(
      imagesErrorText,
      AlertSvgWhite,
      "danger d-flex",
      null,
      null,
      imagesErrorText,
      null,
      null
    );

  if (videoErrorText)
    return showAlert(
      videoErrorText,
      AlertSvgWhite,
      "danger d-flex",
      null,
      null,
      videoErrorText,
      null,
      null
    );

  if (warningText)
    return showAlert(
      warningText,
      AlertSvg,
      "warning",
      null,
      null,
      warningText,
      null,
      null
    );

  return null;
};

AlertNotifier.propTypes = {
  warningText: PropTypes.string,
  imagesErrorText: PropTypes.string,
  videoErrorText: PropTypes.string,
  throttleWarning: PropTypes.objectOf(PropTypes.any),
  showSharedNumberAlert: PropTypes.bool,
  accountIssueInfo: PropTypes.objectOf(PropTypes.any),
  showNoShorteningLinksWarning: PropTypes.bool,
  showSendError: PropTypes.bool,
  setCurrentModal: PropTypes.func.isRequired,
};

AlertNotifier.defaultProps = {
  throttleWarning: {},
};

export default connect(mapStateToProps)(AlertNotifier);
