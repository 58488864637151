import React from "react";
import PropTypes from "prop-types";

const DashboardHeaderPopoverContent = (props) => {
  const { onClickMenu, onClickCustomizeWidgets } = props;
  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={onClickMenu(onClickCustomizeWidgets)}
      >
        <span>Customize Widgets</span>
      </div>
    </div>
  );
};

DashboardHeaderPopoverContent.propTypes = {
  onClickMenu: PropTypes.func.isRequired,
  onClickCustomizeWidgets: PropTypes.func.isRequired,
};

export default DashboardHeaderPopoverContent;
