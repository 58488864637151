import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../Helpers/ModalWrapper";
import { getPrimaryUrl } from "../../../helpers";
import Iframe from "../../Iframe/Iframe";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";

const LegacyGiftClaimingModal = (props) => {
  const { show, closeModal } = props;

  // Redux stores
  const { fetchUserData, fetchCredits } = useUsersStore();
  const { companies } = useCompaniesStore();
  const { currentCompany } = companies;

  const onCloseModal = useCallback(() => {
    closeModal();
    fetchUserData();
    fetchCredits(currentCompany?.id);
  }, [closeModal, fetchUserData, currentCompany, fetchCredits]);

  const renderIframe = useMemo(() => {
    return (
      <Iframe
        src={getPrimaryUrl("users/get_gift_modal?_embed_mode")}
        title={"Claim Your Gift"}
        className={"legacy-gift-claiming-iframe"}
      />
    );
  }, []);

  return (
    <ModalWrapper
      show={show}
      closeModal={onCloseModal}
      className="legacy-gift-claiming-wrapper"
    >
      <div className="modal-header">
        <h1 className="custom-modal-title">Claim Your Gift</h1>
      </div>
      <div className="modal-body">{renderIframe}</div>
    </ModalWrapper>
  );
};
LegacyGiftClaimingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
export default LegacyGiftClaimingModal;
