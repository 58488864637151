import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Radio } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
}));

const CustomRadio = (props) => {
  const { checked, onChange, noPadding, disabled, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Radio
      {...rest}
      className={clsx(className, {
        [classes.noPadding]: noPadding,
      })}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

CustomRadio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  noPadding: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomRadio.defaultProps = {
  noPadding: true,
  disabled: false,
};

export default CustomRadio;
