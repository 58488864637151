import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as filestackActions from "../../../actions/filestack";

export function useFilestackStore() {
  const dispatch = useDispatch();
  const _filestack = useSelector((store) => store.filestack);

  const deleteFilestackFiles = useCallback(
    async (data) => await dispatch(filestackActions.deleteFilestackFiles(data)),
    [dispatch]
  );

  return {
    filestack: _filestack,
    deleteFilestackFiles,
  };
}

export default useFilestackStore;
