import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import stringify from "qs-stringify";

import { refreshCounts } from "../helpers";
import { grouprhsItemsSelected, groupRHSSelectionCleared } from "./contacts";
import {
  getRequest,
  postRequest,
  addQueryParamsToURL,
} from "../utils/requests";
import { fetchCounts as fetchPeopleCounts } from "./contacts";
import { HOLD_CONTACTS_FILTERS } from "../utils/constants";

const CancelToken = axios.CancelToken;

// We stores requestIds to not overwrite response from earlier request, from previous selected company
let fetchGroupsRequestIds = [];

export function resetFetchGroupsRequests() {
  fetchGroupsRequestIds = [];
}

function removeFetchGroupRequestId(requestId) {
  const index = fetchGroupsRequestIds.indexOf(requestId);
  if (index >= 0) {
    fetchGroupsRequestIds.splice(index, 1);
  }
}

export function fetchGroups(companyId, page, orderBy, includeAdhocGroups = 0) {
  return function (dispatch) {
    const requestId = `${new Date().getTime()}-${String(Math.random()).substr(
      2,
      3
    )}`;
    fetchGroupsRequestIds.push(requestId);
    dispatch({
      type: "GROUPS/FETCH",
      page: page,
    });

    const url =
      "groups.json?companyId=" +
      companyId +
      "&page=" +
      page +
      "&orderBy=" +
      orderBy +
      "&filter=named" +
      `&include_adhoc=${includeAdhocGroups}`;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        if (fetchGroupsRequestIds.indexOf(requestId) >= 0) {
          removeFetchGroupRequestId(requestId);
          dispatch({
            type: "GROUPS/FETCH.SUCCESS",
            page: page,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        if (fetchGroupsRequestIds.indexOf(requestId) >= 0) {
          removeFetchGroupRequestId(requestId);
          dispatch({
            type: "GROUPS/FETCH.ERROR",
            page: page,
            payload: error,
          });
        }
      });
  };
}

export function fetchGroupsTags(companyId, page, orderBy, query = "") {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_TAGS",
      page: page,
    });

    const url =
      "groups/tags.json?companyId=" +
      companyId +
      "&query=" +
      query +
      "&page=" +
      page +
      "&orderBy=" +
      orderBy;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/FETCH_TAGS.SUCCESS",
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_TAGS.ERROR",
          page: page,
          payload: error,
        });
      });
  };
}

export function fetchFilteredGroups(
  companyId,
  query,
  filter,
  page,
  orderBy,
  includeAdhocGroups = 0
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_FILTERED",
      query: query,
      page: page,
    });
    let config = null;
    const includeAdhocFilterCondition =
      filter && filter?.includes("archived") ? 1 : 0;
    const params = {
      companyId: companyId,
      query: query,
      filter: filter,
      page: page,
      orderBy,
      include_adhoc: includeAdhocFilterCondition || includeAdhocGroups,
    };
    if (filter === "regular-ccb") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filter: "ccb-regular",
        },
      });
    } else if (filter === "type-unnamed") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filter: "unnamed",
        },
      });
    } else if (filter === "no-integration-regular-second") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filter: "no-integration-regular",
        },
      });
    } else {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), { params });
    }

    return axios
      .get(AxiosConfig.getEndpointAddress() + "groups.json", config)
      .then((response) => {
        dispatch({
          type: "GROUPS/FETCH_FILTERED.SUCCESS",
          query: query,
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_FILTERED.ERROR",
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function fetchGroupData(companyId, groupId) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_DATA",
      groupId: groupId,
    });

    const url = "groups/" + groupId + ".json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/FETCH_DATA.SUCCESS",
          groupId: groupId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_DATA.ERROR",
          groupId: groupId,
          payload: error,
        });
      });
  };
}

export function clearFetchGroupData(groupId) {
  return function (dispatch) {
    dispatch({ type: "GROUPS/FETCH_DATA.CLEAR", groupId });
  };
}

export function fetchMembers(
  companyId,
  groupId,
  limit,
  page,
  filters = "",
  orderBy = "",
  filtersAdv = []
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/MEMBERS",
      groupId: groupId,
      page: page,
    });
    const params = stringify({
      companyId: companyId,
      limit: limit,
      page: page,
      filters: filters,
      orderBy,
      filtersAdv,
    });
    const url = `groups/${groupId}/members.json?${params}`;

    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/MEMBERS.SUCCESS",
          groupId: groupId,
          limit: limit,
          page: page,
          payload: response.data,
          filters,
          filtersAdv: filtersAdv && Boolean(filtersAdv.length),
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/MEMBERS.ERROR",
          groupId: groupId,
          page: page,
          payload: error,
        });
      });
  };
}

export function fetchFilteredMembers(
  companyId,
  groupId,
  query,
  limit,
  page,
  filter,
  orderBy,
  filtersAdv = [],
  requestForMobileMode = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FILTERED_MEMBERS",
      groupId: groupId,
      query: query,
      page: page,
    });

    const url =
      "groups/" +
      groupId +
      `/members.json?` +
      stringify({
        companyId: companyId,
        query: query,
        limit: limit,
        page: page,
        filter: filter,
        orderBy,
        filtersAdv,
      });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/FILTERED_MEMBERS.SUCCESS",
          groupId: groupId,
          query: query,
          page: page,
          payload: response.data,
          orderBy,
          filtersAdv,
          requestForMobileMode,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FILTERED_MEMBERS.ERROR",
          groupId: groupId,
          query: query,
          page: page,
          payload: error,
        });
      });
  };
}

export function clearFetchFilteredMembers() {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FILTERED_MEMBERS.CLEAR",
    });
  };
}

export function patchMembers(
  companyId,
  groupId,
  addContactIds,
  addGroupIds,
  removeContactIds,
  clearAdhoc = true,
  removeGroupIds,
  allParams,
  cleargroupRHSSelectionAfterPatchedMember,
  withRefreshGroupData = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/PATCH_MEMBERS",
      groupId: groupId,
    });

    const url = "groups/" + groupId + "/members.json";
    const data = Object.assign(
      {},
      {
        companyId,
        addContactIds,
        addGroupIds,
        removeContactIds,
        clearAdhoc,
        removeGroupIds,
        ...(allParams && { all: allParams }),
      }
    );

    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (!cleargroupRHSSelectionAfterPatchedMember) {
          dispatch(groupRHSSelectionCleared(false));
        }
        dispatch(fetchPeopleCounts(companyId, HOLD_CONTACTS_FILTERS));
        dispatch({
          type: "GROUPS/PATCH_MEMBERS.SUCCESS",
          groupId: groupId,
          payload: response.data,
        });
        if (cleargroupRHSSelectionAfterPatchedMember) {
          dispatch(groupRHSSelectionCleared(false));
        }
      })
      .then(() => {
        if (withRefreshGroupData) {
          dispatch(fetchGroupMembersCountStatus(companyId, groupId));
        }
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/PATCH_MEMBERS.ERROR",
          groupId: groupId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchGroupsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/PATCH_MEMBERS.CLEAR" });
  };
}

export function patchGroup(
  companyId,
  groupId,
  patchData,
  requestId,
  upload = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/PATCH",
      groupId: groupId,
      patchData: patchData,
      requestId: requestId,
    });

    const url = "groups/" + groupId + ".json";

    const data = upload
      ? new FormData()
      : Object.assign({}, patchData, { company_id: companyId });
    const config = AxiosConfig.getAuthConfig();
    if (upload) {
      data.append("company_id", companyId);
      for (var field in patchData) {
        data.append(field, patchData[field]);
      }
    }

    return axios
      .post(AxiosConfig.getEndpointAddress() + url, data, config)
      .then((response) => {
        dispatch({
          type: "GROUPS/PATCH.SUCCESS",
          groupId: groupId,
          patchData: patchData,
          requestId: requestId,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/PATCH.ERROR",
          groupId: groupId,
          patchData: patchData,
          requestId: requestId,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchGroup(requestId) {
  return function (dispatch) {
    dispatch({ type: "GROUPS/PATCH.CLEAR", requestId: requestId });
  };
}

export function resetGroupPatchQueue() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/RESET_GROUP_PATCH_QUEUE" });
  };
}

export function addGroup(companyId, groupData, membersData, allParams) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/ADD",
      groupData: groupData,
    });

    const url = "groups.json";
    const data = Object.assign({}, groupData, {
      company_id: companyId,
    });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        /*  Adding members to newly created group */
        if (membersData) {
          dispatch(
            patchMembers(
              companyId,
              response.data.group.id,
              membersData.contactIds || [],
              membersData.groupIds || [],
              null,
              null,
              null,
              allParams,
              true
            )
          );

          // setTimeout(() => dispatch(clearPatchGroupsStatus()), 2000);
        }

        dispatch({
          type: "GROUPS/ADD.SUCCESS",
          groupData: groupData,
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/ADD.ERROR",
          groupData: groupData,
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function createAdhocGroup(
  companyId,
  groupData = [],
  membersData,
  isGroup = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/ADD",
    });

    const url = "groups/adhoc.json";
    const filteredIds = groupData.map(
      (member) =>
        member?.id ||
        member?.group?.id ||
        member?.group_sms_blast?.id ||
        member?.contact?.id
    );
    const contactIds = filteredIds.filter(Boolean);
    const data = Object.assign(
      {},
      isGroup === true
        ? {
            company_id: companyId,
            group_ids: contactIds,
          }
        : {
            company_id: companyId,
            contact_ids: contactIds,
          }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        /*  Adding members to newly created group */
        if (membersData) {
          dispatch(
            patchMembers(
              companyId,
              response.data.group.id,
              membersData.contactIds || [],
              membersData.groupIds || [],
              null,
              null,
              null,
              null,
              true
            )
          );
        }

        dispatch({
          type: "GROUPS/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearAddGroup() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/ADD.CLEAR" });
  };
}

export function clearAddGroupStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/ADD_STATUS.CLEAR" });
  };
}

export function clearGroupIsNewGroup() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_GROUP_IS_NEW_GROUP" });
  };
}

export function fetchExtAppsData(companyId) {
  return function (dispatch) {
    dispatch({ type: "GROUPS/EXT_APPS_DATA" });

    const url = "groups/external-apps-data.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "GROUPS/EXT_APPS_DATA.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/EXT_APPS_DATA.ERROR",
          payload: error,
        });
      });
  };
}

export function checkKeywords(
  companyId,
  keywords,
  ignoreGroupId = null,
  ignoreAutoresponderMessageId = null,
  ignoreContestId = null,
  ignorePollId = null,
  uid = null
) {
  const args = {
    companyId: companyId,
    keywords: keywords,
    ignoreGroupId: ignoreGroupId,
    ignoreAutoresponderMessageId: ignoreAutoresponderMessageId,
    ignoreContestId: ignoreContestId,
    ignorePollId: ignorePollId,
  };

  return function (dispatch) {
    dispatch(
      Object.assign({}, args, {
        type: "GROUPS/CHECK_KEYWORDS",
        uid: uid,
      })
    );

    const url = "groups/check-keywords.json";
    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        Object.assign({}, AxiosConfig.getAuthConfig(), { params: args })
      )
      .then((response) => {
        dispatch(
          Object.assign({}, args, {
            type: "GROUPS/CHECK_KEYWORDS.SUCCESS",
            payload: response.data,
          })
        );
      })
      .catch(function (error) {
        dispatch(
          Object.assign({}, args, {
            type: "GROUPS/CHECK_KEYWORDS.ERROR",
            payload: error.response ? error.response.data : null,
            error: error,
          })
        );
      });
  };
}

export function clearCheckKeywords() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CHECK_KEYWORDS.CLEAR" });
  };
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FILTER",
      filter: filter,
    });
  };
}

export function fetchGroupAmCampaigns(
  companyId,
  groupId,
  query = "",
  page = null
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/AM_CAMPAIGNS",
      groupId,
      query,
      page,
    });

    const url = "groups/" + groupId + "/am-campaigns.json";
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        companyId,
        query,
        page,
      },
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, config)
      .then((response) => {
        dispatch({
          type: "GROUPS/AM_CAMPAIGNS.SUCCESS",
          groupId,
          query,
          page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/AM_CAMPAIGNS.ERROR",
          groupId,
          query,
          page,
          payload: error,
        });
      });
  };
}

export function clearGroupAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/AM_CAMPAIGNS.CLEAR" });
  };
}

export function patchGroupAmCampaigns(companyId, groupId, campaignId) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/PATCH_AM_CAMPAIGNS",
      groupId,
      campaignId,
    });

    const url = "groups/" + groupId + "/am-campaigns.json";
    const data = Object.assign(
      {},
      {
        companyId,
        groupId,
        campaignId,
      }
    );
    return axios
      .put(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/PATCH_AM_CAMPAIGNS.SUCCESS",
          groupId,
          campaignId,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/PATCH_AM_CAMPAIGNS.ERROR",
          groupId,
          campaignId,
          error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearPatchGroupAmCampaigns() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/PATCH_AM_CAMPAIGNS.CLEAR" });
  };
}

export function toggleGroupFromList(value, selectedThreads) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/CREATE_GROUP_LIST",
      value,
      selectedThreads,
    });
  };
}

export function handleGroupItemsActions(
  time,
  membersData,
  groupData,
  subMembersData,
  typeOfAction
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/RHS_GROUP_MEMBERS_ACTION",
      time,
      membersData,
      groupData,
      subMembersData,
      typeOfAction,
    });
  };
}

export function archiveThenDeleteGroup(companyId, groupId) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/ARCHIVE_THEN_DELETE",
    });

    const archiveGroupData = {
      company_id: companyId,
      group_id: [groupId],
      warning_duplicates: 1,
      archive_contacts: 0,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `groups/bulk-archive.json`,
        archiveGroupData,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        const deleteGroupUrl = `groups/delete/${groupId}.json?company_id=${companyId}&delete_contacts=${0}`;
        return axios
          .delete(
            AxiosConfig.getEndpointAddress() + deleteGroupUrl,
            AxiosConfig.getAuthConfig()
          )
          .then((deleteGroupResponse) => {
            dispatch({
              type: "GROUPS/ARCHIVE_THEN_DELETE.SUCCESS",
              groupId,
              deleteGroupResponse: deleteGroupResponse?.data,
            });
          });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/ARCHIVE_THEN_DELETE.ERROR",
          error,
          payload: error.response ? error.response?.data : null,
        });
      });
  };
}

export function archiveGroups(
  company_id,
  group_id = [],
  new_primary_contact_id = {},
  containDuplicates = false,
  archive_contacts = 0,
  workflow_id,
  needRouting = false,
  contact_id = [],
  isUndo = false,
  isGroupTag,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/ARCHIVE_GROUPS",
      archivingIds: [...group_id],
    });
    const data = {
      company_id,
      group_id,
      // new_primary_contact_id,
      warning_duplicates: 1,
      archive_contacts,
      all: allParams,
      // contact_id, to-do add similar contact ids
    };
    if (containDuplicates) {
      data.warning_duplicates = 0;
      if (new_primary_contact_id) {
        data.new_primary_contact_id = new_primary_contact_id;
        data.contact_id = contact_id;
      }
    }
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `groups/bulk-archive.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        const data = response.data;
        if (
          data &&
          data.archived_groups_ids.length > 0 &&
          data.skipped_duplicates_per_number.length < 1
        ) {
          dispatch({
            type: "GROUPS/ARCHIVE_GROUPS.SUCCESS",
            payload: data,
            groupIds: data.archived_groups_ids,
            contactIds: data.archived_contacts_ids,
            primaryAssignees: [],
            workflow_id,
            needRouting,
            archivingIds: [...group_id],
            isUndo,
            allParams,
          });
        } else if (
          data.skipped_duplicates_per_number.length >= 1 &&
          data.contacts
        ) {
          const duplicates = {};
          for (const dup of data.skipped_duplicates_per_number) {
            const selected = dup.selected_to_archive;
            for (const id of selected) {
              duplicates[id] = data.contacts[id];
            }
          }
          dispatch({
            type: "GROUPS/ARCHIVE_GROUPS.PROGRESS",
            payload: data,
            workflow_id,
            duplicates,
            contactsData: data.contacts,
            archivingIds: [...group_id],
          });
        } else {
          const payload =
            data.skipped_no_new_primary_per_number.length > 0
              ? "Archive fail "
              : "Couldn't archive";
          dispatch({
            type: "GROUPS/ARCHIVE_GROUPS.FAILURE",
            payload,
            workflow_id,
            archivingIds: [...group_id],
          });
        }
      })
      .then(() => {
        !isGroupTag && dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/ARCHIVE_GROUPS.FAILURE",
          payload: error,
          workflow_id,
          archivingIds: [...group_id],
        });
      });
  };
}

export function unarchiveGroups(
  company_id,
  group_id = [],
  new_primary_contact_id = {},
  containDuplicates = false,
  unarchive_contacts = 0,
  workflow_id,
  needRouting = false,
  contact_id = [],
  isUndo
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/UNARCHIVE_GROUPS",
      unarchivingIds: [...group_id],
    });
    const data = {
      company_id,
      group_id,
      // new_primary_contact_id,
      warning_duplicates: containDuplicates ? 1 : 0,
      unarchive_contacts,
      // contact_id, to-do add similar contact ids
    };
    if (containDuplicates) {
      data.warning_duplicates = 0;
      if (new_primary_contact_id) {
        // Might need it in future
        data.new_primary_contact_id = new_primary_contact_id;
        data.contact_id = contact_id;
      }
    }
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `groups/bulk-unarchive.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        const data = response.data;
        if (
          data &&
          data.unarchived_groups_ids.length > 0 &&
          data.skipped_duplicates_per_number.length < 1
        ) {
          dispatch({
            type: "GROUPS/UNARCHIVE_GROUPS.SUCCESS",
            payload: data,
            groupIds: data.unarchived_groups_ids,
            contactIds: data.unarchived_contacts_ids,
            primaryAssignees: [], // to send newly assigned primary assignees
            workflow_id,
            needRouting,
            unarchivingIds: [...group_id],
            isUndo,
          });
        } else if (
          data.skipped_duplicates_per_number.length >= 1 &&
          data.contacts
        ) {
          const duplicates = {};
          for (const dup of data.skipped_duplicates_per_number) {
            const selected = dup.selected_to_unarchive;
            for (const id of selected) {
              duplicates[id] = data.contacts[id];
            }
          }
          dispatch({
            type: "GROUPS/UNARCHIVE_GROUPS.PROGRESS",
            payload: data,
            workflow_id,
            duplicates,
            contactsData: data.contacts,
            unarchivingIds: [...group_id],
          });
        } else {
          const payload =
            data.skipped_no_new_primary_per_number.length > 0
              ? "Archive fail "
              : "Couldn't archive";
          dispatch({
            type: "GROUPS/UNARCHIVE_GROUPS.FAILURE",
            payload,
            workflow_id,
            unarchivingIds: [...group_id],
          });
        }
      })
      .then(() => {
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/UNARCHIVE_GROUPS.FAILURE",
          payload: error,
          workflow_id,
          unarchivingIds: [...group_id],
        });
      });
  };
}

export function addSnackData(data, entity) {
  return (dispatch) => {
    dispatch({
      type: "GROUPS/ADD_SNACK_DATA",
      payload: {
        data,
        entity,
      },
    });
  };
}

export function removeSnackData(id, entity) {
  return (dispatch) => {
    dispatch({
      type: "GROUPS/REMOVE_SNACK_DATA",
      payload: {
        id,
        entity,
      },
    });
  };
}

export function fetchMembersForDeletion(
  company_id,
  group_id = [],
  workflow_id
) {
  return function (dispatch) {
    let url = `groups/bulk-pre-delete-stats.json?company_id=${company_id}`;
    for (const id of group_id) {
      url += `&group_id[]=${id}`;
    }
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        if (response.data) {
          const membersStats = {
            archived_pl_contacts: 0,
            unarchived_pl_contacts: 0,
            non_pl_contacts: 0,
          };
          for (const stat of response.data.stats) {
            membersStats.archived_pl_contacts += stat.archived_pl_contacts;
            membersStats.unarchived_pl_contacts += stat.unarchived_pl_contacts;
            membersStats.non_pl_contacts += stat.non_pl_contacts;
          }

          dispatch({
            type: "GROUPS/DELETE_GROUPS.FETCH_DATA",
            membersStats,
            workflow_id,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/DELETE_GROUPS.FAILURE",
          payload: error,
          workflow_id,
          deletingIds: [...group_id],
        });
      });
  };
}

export function fetchMembersData(
  company_id,
  group_id = [],
  workflow_id,
  filter
) {
  return async (dispatch) => {
    try {
      let archivedPl = {};
      let unarchivedPl = {};
      let nonPl = {};
      for (const id of group_id) {
        let url = `groups/${id}/members.json?companyId=${company_id}&page=1&limit=20&filter=${filter}`;
        const { data } = await axios.get(
          AxiosConfig.getEndpointAddress() + url,
          AxiosConfig.getAuthConfig()
        );
        const contacts = { ...data.contacts };
        for (const id in contacts) {
          if (data.contacts_ids.indexOf(parseInt(id)) === -1) {
            delete contacts[id];
          }
        }
        switch (filter) {
          case "archived_pl": {
            archivedPl = { ...archivedPl, ...contacts };
            unarchivedPl = null;
            nonPl = null;
            break;
          }
          case "unarchived_pl": {
            unarchivedPl = { ...unarchivedPl, ...contacts };
            archivedPl = null;
            nonPl = null;
            break;
          }
          case "non_pl": {
            nonPl = { ...nonPl, ...contacts };
            archivedPl = null;
            unarchivedPl = null;
            break;
          }
          default:
            break;
        }
      }

      dispatch({
        type: "GROUPS/DELETE_GROUPS.FETCH_MEMEBERS_DATA",
        workflow_id,
        archivedPl,
        unarchivedPl,
        nonPl,
      });
    } catch (err) {
      dispatch({
        type: "GROUPS/DELETE_GROUPS.FAILURE",
        payload: err,
        workflow_id,
        deletingIds: [...group_id],
      });
    }
  };
}

export function deleteGroups(
  company_id,
  group_id = [],
  delete_contacts = 0,
  workflow_id,
  needRouting = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/DELETE_GROUPS",
      deletingIds: [...group_id],
    });
    const data = {
      company_id,
      group_id,
      delete_contacts,
    };

    return axios
      .post(
        AxiosConfig.getEndpointAddress() + `groups/bulk-delete.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GROUPS/DELETE_GROUPS.SUCCESS",
            payload: response.data,
            workflow_id,
            needRouting,
            groupIds: response.data.deleted_groups_ids,
            contactIds: response.data.deleted_contacts_ids,
          });
        } else {
          dispatch({
            type: "GROUPS/DELETE_GROUPS.FAILURE",
            payload: "Couldn't delete",
            workflow_id,
            deletingIds: [...group_id],
          });
        }
      })
      .then(() => {
        dispatch(refreshCounts(company_id));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/DELETE_GROUPS.FAILURE",
          payload: error,
          workflow_id,
          deletingIds: [...group_id],
        });
      });
  };
}

let fetchCountsCancel;

export function cancelFetchCountsGroups() {
  fetchCountsCancel && fetchCountsCancel("GROUPS/COUNTS/CANCELED");
}

export function fetchCounts(companyId, all) {
  return function (dispatch) {
    dispatch({ type: "GROUPS/COUNTS" });

    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params: {
        company_id: companyId,
        all: all ? 1 : 0,
      },
      cancelToken: new CancelToken((c) => {
        fetchCountsCancel = c;
      }),
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "groups/stats.json", config)
      .then((response) => {
        dispatch({
          type: "GROUPS/COUNTS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/COUNTS.ERROR",
          payload: error,
        });
      });
  };
}

export function editGroupsField(
  companyId,
  groupIds,
  groupData,
  requestId,
  allParams
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/BULK_EDIT_FIELD",
      groupIds,
      groupData: groupData,
      requestId: requestId,
    });

    const data = Object.assign({}, groupData, {
      company_id: companyId,
      group_id: groupIds,
      all: allParams,
    });
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "groups/bulk-patch.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/BULK_EDIT_FIELD.SUCCESS",
          requestId: requestId,
          groupIds: groupIds,
          groupData,
          data: response.data,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/BULK_EDIT_FIELD.ERROR",
          requestId: requestId,
          error: error,
        });
      });
  };
}

export function syncGroups(companyId, groupIds) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/BULK_SYNC",
      groupIds,
    });

    const data = Object.assign(
      {},
      {
        company_id: companyId,
        group_id: groupIds,
      }
    );
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + "groups/bulk-sync.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/BULK_SYNC.SUCCESS",
          syncQueuedGroupIds: response.data.queued_group_ids,
        });
      })
      .then(() => {
        dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/BULK_SYNC.ERROR",
          error: error,
        });
      });
  };
}

export function clearBulkEditFieldStatus(requestId) {
  return function (dispatch) {
    dispatch({ type: "GROUPS/BULK_EDIT_FIELD.CLEAR", requestId });
  };
}

export function setGroupMembersFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/MEMBERS_FILTER",
      filter: filter,
    });
  };
}

export function setGroupMembersAdvancedFilter(filter) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/MEMBERS_ADVANCE_FILTER",
      filter: filter,
    });
  };
}

export function deleteGroup(
  companyId,
  groupId,
  deleteContacts = 0,
  isGroupTag = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/DELETE",
    });

    const url = `groups/delete/${groupId}.json?company_id=${companyId}&delete_contacts=${deleteContacts}`;

    return axios
      .delete(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/DELETE.SUCCESS",
          groupId,
          payload: response.data,
        });
      })
      .then(() => {
        !isGroupTag && dispatch(refreshCounts(companyId));
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/DELETE.ERROR",
          error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearDeleteGroup() {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/DELETE.CLEAR",
    });
  };
}

export function fetchGroupsForCopyTo(
  companyId,
  contactIds = [],
  groupIds = [],
  page = 1,
  query = "",
  orderBy = null
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/FETCH_COPY_TO",
      page,
    });

    let url = `groups/index-for-copy-to.json?companyId=${companyId}`;
    for (const id of contactIds) {
      url += `&contact_id[]=${id}`;
    }
    for (const id of groupIds) {
      url += `&group_id[]=${id}`;
    }

    url += `&page=${page}`;

    if (query) {
      url += `&query=${query}`;
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }

    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GROUPS/FETCH_COPY_TO.SUCCESS",
            groups: response.data.groups,
            total: response.data.total,
            page,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/FETCH_COPY_TO.FAILURE",
          payload: error,
          page,
        });
      });
  };
}

export function markGroupAllMembersSelected() {
  return function (dispatch) {
    dispatch({
      type: "GROUP/ALL_GROUP_MEMBERS.SELECT",
    });
  };
}

export function unmarkGroupAllMembersSelected() {
  return function (dispatch) {
    dispatch(grouprhsItemsSelected(0));
    dispatch({
      type: "GROUP/ALL_GROUP_MEMBERS.UNSELECT",
    });
  };
}

export function setUpdatedMembersCounts(propCounter, isAdding, count) {
  return function (dispatch) {
    dispatch({
      type: "GROUP/UPDATE_MEMBER_COUNT.SET",
      propCounter,
      isAdding,
      count,
    });
  };
}

export function bulkAddTag(body) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "GROUPS/BULK_ADD_TAG",
      "groups/bulk-add-tag.json",
      body
    );
  };
}

export function resetTagsAlreadyExist() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/RESET_TAGS_ALREADY_EXIST" });
  };
}

export function fetchShareableGroups(params) {
  return function (dispatch) {
    getRequest(
      dispatch,
      "GROUPS/FETCH_SHAREABLE_GROUPS",
      `groups/shareable.json?companyId=${params.companyId}`,
      params
    );
  };
}
export function resetSavedTags() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/RESET_SAVED_TAGS" });
  };
}

export function checkTagsExist(params) {
  return function (dispatch) {
    postRequest(
      dispatch,
      "GROUPS/CHECK_TAGS_EXIST",
      "groups/check-tags.json",
      params
    );
  };
}

export function fetchGroupDetails(companyId, groupId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/DETAILS",
      `groups/${groupId}/changes-log.json`,
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function setGroupSearch(query) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/SEARCH",
      payload: query,
    });
  };
}

export function fetchNeverSyncedGroups(
  companyId,
  query,
  filter,
  page,
  orderBy
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/NEVER_SYNCED",
      page: page,
    });
    let config = null;
    const params = {
      companyId: companyId,
      page: page,
      orderBy: orderBy,
      filters: filter,
      query: query,
    };

    if (filter === "breeze-never-synced") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filters: "breeze",
        },
      });
    } else if (filter === "ccb-never-synced") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filters: "ccb",
        },
      });
    } else if (filter === "elvanto-never-synced") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filters: "elvanto",
        },
      });
    } else if (filter === "mc-never-synced") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filters: "mc",
        },
      });
    } else if (filter === "pco-never-synced") {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), {
        params: {
          ...params,
          filters: "pco",
        },
      });
    } else {
      config = Object.assign({}, AxiosConfig.getAuthConfig(), { params });
    }

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + "groups/never-synced.json",
        config
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/NEVER_SYNCED.SUCCESS",
          page: page,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/NEVER_SYNCED.ERROR",
          page: page,
          payload: error,
        });
      });
  };
}

export function refreshNeverSyncedGroups(companyId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "GROUPS/REFRESH_NEVER_SYNCED",
      "groups/refresh-never-synced.json",
      {
        companyId,
      }
    );
}

export function setRefreshNeverSyncedStatus(status) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/SET_REFRESH_NEVER_SYNCED_STATUS",
      payload: status,
    });
  };
}

export function markAsReadGroupUnresolvedConflicts(companyId, groupId) {
  return (dispatch) =>
    postRequest(
      dispatch,
      "GROUPS/MARK_AS_READ_GROUP_UNRESOLVED_CONFLICT",
      `groups/mark-hold-contacts-as-read/${groupId}/.json`,
      {
        companyId,
      },
      {
        successDispatchData: {
          groupId,
        },
      }
    );
}

export function fetchCCBCampuses(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_CCB_CAMPUSES",
      addQueryParamsToURL("ccb/campuses.json", params),
      {
        requestDispatchData: {
          page: params.page,
        },
        errorDispatchData: {
          page: params.page,
        },
        successDispatchData: {
          page: params.page,
        },
      }
    );
}

export function clearFetchCCBCampusesStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_CCB_CAMPUSES_STATUS" });
  };
}
export function fetchCCBGroups(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_CCB_GROUPS",
      addQueryParamsToURL("ccb/groups.json", params),
      {
        successDispatchData: params,
        requestDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchCCBGroupsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_CCB_GROUPS_STATUS" });
  };
}
export function fetchCCBSavedSearches(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_CCB_SAVED_SEARCHES",
      addQueryParamsToURL("ccb/saved-searches.json", params),
      {
        requestDispatchData: {
          page: params.page,
          resync: params.resync || 0,
        },
        errorDispatchData: {
          page: params.page,
          resync: params.resync || 0,
        },
        successDispatchData: {
          page: params.page,
          resync: params.resync || 0,
        },
      }
    );
}
export function clearFetchCCBSavedSearchesStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_CCB_SAVED_SEARCHES_STATUS" });
  };
}
export function fetchCCBProcessQueues(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_CCB_PROCESS_QUEUES",
      addQueryParamsToURL("ccb/process-queues.json", params),
      {
        requestDispatchData: {
          page: params.page,
          resync: params.resync || 0,
        },
        errorDispatchData: {
          page: params.page,
          resync: params.resync || 0,
        },
        successDispatchData: {
          page: params.page,
          resync: params.resync || 0,
        },
      }
    );
}
export function clearFetchCCBProcessQueuesStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_CCB_PROCESS_QUEUES_STATUS" });
  };
}
export function fetchPcoLists(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_PCO_LISTS",
      addQueryParamsToURL("pco/lists.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchPcoListsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_PCO_LISTS_STATUS" });
  };
}
export function fetchPcoWorflows(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_PCO_WORKFLOWS",
      addQueryParamsToURL("pco/workflows.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}

export function clearFetchPcoWorkflowsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_PCO_WORKFLOWS_STATUS" });
  };
}
export function fetchElvantoGroups(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_ELVANTO_GROUPS",
      addQueryParamsToURL("elvanto/groups.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}

export function clearFetchElvantoGroupsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_ELVANTO_GROUPS_STATUS" });
  };
}

export function fetchRockrmsGroups(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_ROCKRMS_GROUPS",
      addQueryParamsToURL("rock-rms/groups.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchRockrmsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_ROCKRMS_GROUPS_STATUS" });
  };
}

export function fetchMailChimpLists(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_MAILCHIMP_LISTS",
      addQueryParamsToURL("mail-chimp/lists.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchMailChimpListsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_MAILCHIMP_LISTS_STATUS" });
  };
}

export function fetchMailChimpGroups(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_MAILCHIMP_GROUPS",
      addQueryParamsToURL("mail-chimp/groups.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchMailChimpGroupsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_MAILCHIMP_GROUPS_STATUS" });
  };
}
export function fetchBreezeTags(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_BREEZE_TAGS",
      addQueryParamsToURL("breeze/tags.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchBreezeTagsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_BREEZE_TAGS_STATUS" });
  };
}
export function fetchBreezeEvents(params) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_BREEZE_EVENTS",
      addQueryParamsToURL("breeze/events.json", params),
      {
        requestDispatchData: params,
        successDispatchData: params,
        errorDispatchData: params,
      }
    );
}
export function clearFetchBreezeEventsStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/CLEAR_FETCH_BREEZE_EVENTS_STATUS" });
  };
}

export function setGroupsInitialFetchFlag(fetched = true) {
  return (dispatch) =>
    dispatch({ type: "GROUPS/FETCH.INITIAL_FETCH_FLAG", fetched: fetched });
}

export function fetchGroupMembersCountStatus(companyId, groupId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_DATA_MEMBERS_STATUSES",
      `groups/members-stats/${groupId}/.json?companyId=${companyId}`,
      {
        requestDispatchData: {
          groupId,
        },
        successDispatchData: {
          groupId,
        },
        errorDispatchData: {
          groupId,
        },
      }
    );
}

export function fetchTagsToApply(companyId, page, query, orderBy) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "GROUPS/FETCH_TAGS_TO_APPLY",
      addQueryParamsToURL(`groups/index-for-apply-tags.json`, {
        companyId,
        page,
        query,
        orderBy,
      })
    );
}

export function setPrefillIntegrationGroup(group) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/SET_PREFILL_INTEGRATION_GROUP",
      group,
    });
  };
}

export function selectAllGroups() {
  return function (dispatch) {
    dispatch({
      type: "GROUP/SELECT_ALL_GROUPS",
    });
  };
}

export function unselectAllGroups() {
  return function (dispatch) {
    dispatch({
      type: "GROUP/UNSELECT_ALL_GROUPS",
    });
  };
}

export function toggleGroupView() {
  return function (dispatch) {
    dispatch({
      type: "GROUP/TOGGLE_GROUP_VIEW_EXPAND",
    });
  };
}

export function createAdhocGroupNew(
  companyId,
  groupIds = [],
  contactIds = [],
  withRefreshCounts = false
) {
  return function (dispatch) {
    dispatch({
      type: "GROUPS/ADD",
    });

    const url = "groups/adhoc.json";
    const data = {
      company_id: companyId,
      group_ids: groupIds,
      contact_ids: contactIds,
    };
    return axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "GROUPS/ADD.SUCCESS",
          payload: response.data,
        });
      })
      .then(() => {
        if (withRefreshCounts) {
          dispatch(refreshCounts(companyId));
        }
      })
      .catch(function (error) {
        dispatch({
          type: "GROUPS/ADD.ERROR",
          error: error,
          payload: error.response ? error.response.data : null,
        });
      });
  };
}

export function clearShareableGroupStatus() {
  return function (dispatch) {
    dispatch({ type: "GROUPS/FETCH_SHAREABLE_GROUPS.CLEAR" });
  };
}

export function setCurrentMemberFilter(filter) {
  return (dispatch) =>
    dispatch({ type: "GROUPS/SET_CURRENT_MEMBER_FITERS", filter });
}
