import React from "react";
import { getPermissionParsed } from "../../utils/settingsHelpers";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";

import { ReactComponent as CampaignFlashIconGray } from "../../assets/img/icons-new/main-nav/campaigns/campaigns-gray.svg";
import { ReactComponent as KeywordAutoreplyIcon } from "../../assets/img/icons-new/campaign-type/automated-message/automated-message-black.svg";
import { ReactComponent as AddGroupsIcon } from "../../assets/img/icons-new/main-submenu/add-group-black.svg";
import { ReactComponent as AddContactIcon } from "../../assets/img/icons-new/main-submenu/add-person-black.svg";
import { ReactComponent as AddBroadcast } from "../../assets/img/icons-new/main-submenu/broadcast-black.svg";
import { ReactComponent as ExportIcon } from "../../assets/img/icons-new/main-submenu/export-black.svg";
import { ReactComponent as CsvExportIcon } from "../../assets/img/icons-new/general/csv/csv-green.svg";
import { ReactComponent as NewCall } from "../../assets/img/icons-new/main-submenu/new-call-black.svg";
import { ReactComponent as AddNewMessageIcon } from "../../assets/img/icons-new/main-submenu/new-message-black.svg";
import { ReactComponent as SyncIcon } from "../../assets/img/icons-new/group-tab/integrations/import-sync/import-sync-black.svg";

import RenderForRN from "../hub/HelperComponents/RenderForRN";
import {
  hasAtleastOneActiveIntegration,
  isCordovaApp,
  isReactNativeApp,
} from "../../helpers";
import ActiveIntegrations from "../elements/ActiveIntegrations";
import useBreakpoint from "../../utils/hooks/useBreakpoints";

import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";

import { useHistory, Link } from "react-router-dom";

import PropTypes from "prop-types";

const SidebarAddNewPopover = (props) => {
  const { isAddNewPopoverOpen, toggleAddNewPopover } = props;

  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isCordova = isCordovaApp();
  const {
    users: { permissions: userPermissions },
  } = useUsersStore();

  const {
    companies: { currentCompany },
  } = useCompaniesStore();

  const navigateUserToAddNewGroupModal = () => {
    history.push("#modal-new-group", {
      activeIntegrationByDefault: true,
    });
    toggleAddNewPopover();
  };

  if (!currentCompany) return null;

  return (
    <>
      <Popover
        isOpen={isAddNewPopoverOpen}
        toggle={toggleAddNewPopover}
        trigger="legacy"
        placement={isMdOrBelowBreakpoint(breakpoint) ? "auto-end" : "left"}
        target="add-menu-popover"
        className="side-menu-popover add-new-popover"
      >
        <PopoverHeader>Add New</PopoverHeader>
        <PopoverBody>
          {getPermissionParsed(userPermissions?.messages_calls, "send_msg") && (
            <a onClick={toggleAddNewPopover} href="#modal-new-message-receiver">
              <span className="menu-icon">
                <AddNewMessageIcon height="28" width="28" />
              </span>
              <span className="svg-caption">Text</span>
            </a>
          )}
          {getPermissionParsed(userPermissions?.people, "add") && (
            <a onClick={toggleAddNewPopover} href="#modal-add-person">
              <span className="menu-icon">
                <AddContactIcon height="28" width="28" />
              </span>
              <span className="svg-caption">Person</span>
            </a>
          )}
          {isCordova && getPermissionParsed(userPermissions?.groups, "add") && (
            <a href="#modal-new-group">
              <span className="menu-icon">
                <AddGroupsIcon height="28" width="28" />
              </span>
              <span className="svg-caption">Group</span>
            </a>
          )}
          {!isCordova &&
            getPermissionParsed(userPermissions?.groups, "add") && (
              <a onClick={toggleAddNewPopover} href={`#modal-new-group`}>
                <span className="menu-icon">
                  <AddGroupsIcon height="28" width="28" />
                </span>
                <span className="svg-caption">Group</span>
              </a>
            )}
          {(getPermissionParsed(
            userPermissions?.messages_calls,
            "send_broadcast"
          ) ||
            getPermissionParsed(userPermissions?.messages_calls, "call")) && (
            <a onClick={toggleAddNewPopover} href="#modal-new-call">
              <span className="menu-icon">
                <AddBroadcast height="28" width="28" />
              </span>
              <span className="svg-caption">Broadcast</span>
            </a>
          )}
          {getPermissionParsed(userPermissions?.messages_calls, "call") && (
            <a onClick={toggleAddNewPopover} href="#modal-new-call">
              <span className="menu-icon">
                <NewCall height="28" width="28" />
              </span>
              <span className="svg-caption">Call</span>
            </a>
          )}
          {getPermissionParsed(userPermissions?.people, "import") && (
            <RenderForRN show={false}>
              <Link
                to={"/hub/import-contacts"}
                className="d-flex align-items-center justify-content-start pl-0 pr-0"
                onClick={toggleAddNewPopover}
              >
                <div>
                  <span className="menu-icon">
                    <ExportIcon height="28" width="28" />
                  </span>
                  <span className="svg-caption mr-1">Import (Upload)</span>
                </div>
                <CsvExportIcon height="23" width="23" />
              </Link>
            </RenderForRN>
          )}
          {hasAtleastOneActiveIntegration(currentCompany) && (
            <div>
              <span
                onClick={navigateUserToAddNewGroupModal}
                className="sync-icon-wrapper"
              >
                <div>
                  <span className="menu-icon">
                    <SyncIcon />
                  </span>
                  <span className="svg-caption">Import (Sync)</span>
                </div>
                <ActiveIntegrations className={"ml-3 mb-1"} />
              </span>
            </div>
          )}
          {userPermissions?.campaigns && (
            <RenderForRN show={false}>
              <Link
                className={`mb-1 pt-0 pl-0 pr-0 ${
                  !isReactNativeApp() ? "mt-2" : ""
                }`}
                to="/hub/menus/filter/campaigns"
                onClick={toggleAddNewPopover}
              >
                <span className="menu-icon pl-0">
                  <CampaignFlashIconGray height="35" width="35" />
                </span>
                <span className="svg-caption">Campaign (V2)</span>
              </Link>
            </RenderForRN>
          )}
          {getPermissionParsed(userPermissions?.keywords, "view") && (
            <RenderForRN show={false}>
              <Link
                className={`mb-1 pt-0 pl-0 pr-0 ${
                  isReactNativeApp() ? "mt-2" : ""
                }`}
                to="/hub/auto-reply"
                onClick={toggleAddNewPopover}
              >
                <span className="menu-icon">
                  <KeywordAutoreplyIcon height="28" width="28" />
                </span>
                <span className="svg-caption">Keyword/Autoreply (V2)</span>
              </Link>
            </RenderForRN>
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};

SidebarAddNewPopover.propTypes = {
  isAddNewPopoverOpen: PropTypes.bool.isRequired,
  toggleAddNewPopover: PropTypes.func.isRequired,
};

export default SidebarAddNewPopover;
