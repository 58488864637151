import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  clearSelection as clearSelectionAction,
  reopenThreads,
  addSnackData,
  fetchClosedThreads,
} from "../../../actions/threads";
import { getPluralFromNumber, groupName } from "../../../helpers";

function mapStateToProps(store) {
  return {
    companyId: store.companies.currentCompany.id,
    reopenThreadsStatus: store.threads.reopenThreadsStatus,
    reopenedSuccessThreads: store.threads.reopenedSuccessThreads,
    numbersFilter: store.numbers.numbersFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSelection: () => dispatch(clearSelectionAction(true)),
    onReopenThreads: (
      companyId,
      contactIds,
      groupIds,
      workflowId,
      needRouting
    ) =>
      dispatch(
        reopenThreads(companyId, contactIds, groupIds, workflowId, needRouting)
      ),
    setSnackData: (data, entity) => dispatch(addSnackData(data, entity)),
    fetchClosedThreads: (companyId, numbersFilter, page, filter, type) =>
      dispatch(
        fetchClosedThreads(companyId, numbersFilter, page, filter, type)
      ),
  };
}

const Reopen = ({
  companyId,
  reopenThreadsStatus,
  reopenedSuccessThreads,
  clearSelection,
  onReopenThreads,
  setSnackData,
  /* Props from parent */
  runWorkflow,
  selectedThreads,
  selectedGroups,
  selectedContacts,
  onReopenComplete,
  needRouting,
  group,
  numbersFilter,
  fetchClosedThreads,
  type,
  countThreads,
}) => {
  const [singleThreadData, setSingleThreadData] = useState({});
  const [workflowId, setWorkflowId] = useState();
  const [undoPayload, setUndoPayload] = useState();

  useEffect(() => {
    if (runWorkflow) {
      proceedClose();
    }
    // eslint-disable-next-line
  }, [runWorkflow]);

  useEffect(() => {
    if (reopenThreadsStatus === `success-${workflowId}`) {
      toggleSnackbar(true)();
      fetchClosedThreads(companyId, numbersFilter, 1, "closed", type);
    } else if (reopenThreadsStatus === `failure-${workflowId}`) {
      toggleSnackbar(true)(true);
    }

    // eslint-disable-next-line
  }, [reopenThreadsStatus]);

  const proceedClose = () => {
    const { groupIds, contactIds, workflowId } = parseData();
    onReopenThreads(companyId, contactIds, groupIds, workflowId, needRouting);
  };

  const parseData = () => {
    if (selectedThreads) {
      const threadKeys = Object.keys(selectedThreads).map((id) => parseInt(id));
      const workflowId = `${selectedThreads[threadKeys[0]].id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      const contactIds = [];
      const groupIds = [];
      const undoPayload = {
        companyId,
        groupIds,
        contactIds,
        workflowId,
        needRouting,
      };
      if (group) {
        groupIds.push(...threadKeys);
      } else {
        contactIds.push(...threadKeys);
      }
      undoPayload.groupIds = groupIds;
      undoPayload.contactIds = contactIds;
      if (onReopenComplete) {
        undoPayload.onComplete = onReopenComplete;
      }
      setWorkflowId(workflowId);
      setSingleThreadData(selectedThreads[threadKeys[0]]);
      setUndoPayload(undoPayload);
      return { groupIds, contactIds, workflowId };
    } else if (selectedGroups || selectedContacts) {
      let workflowId = ``;
      const contactIds = [];
      const groupIds = [];
      const undoPayload = {
        companyId,
        groupIds,
        contactIds,
        workflowId,
        needRouting,
      };
      if (selectedGroups && Object.keys(selectedGroups).length > 0) {
        const threadKeys = Object.keys(selectedGroups).map((id) =>
          parseInt(id)
        );
        workflowId = `${selectedGroups[threadKeys[0]].id}-${
          Math.floor(Math.random() * 50) + 1
        }`;
        groupIds.push(...threadKeys);
        setSingleThreadData(selectedGroups[threadKeys[0]]);
      }
      if (selectedContacts && Object.keys(selectedContacts).length > 0) {
        const threadKeys = Object.keys(selectedContacts).map((id) =>
          parseInt(id)
        );
        if (!workflowId) {
          workflowId = `${selectedContacts[threadKeys[0]].id}-${
            Math.floor(Math.random() * 50) + 1
          }`;
        }
        contactIds.push(...threadKeys);
        setSingleThreadData(selectedContacts[threadKeys[0]]);
      }
      undoPayload.groupIds = groupIds;
      undoPayload.contactIds = contactIds;
      undoPayload.workflowId = workflowId;
      if (onReopenComplete) {
        undoPayload.onComplete = onReopenComplete;
      }
      setWorkflowId(workflowId);
      setUndoPayload(undoPayload);
      return {
        groupIds,
        contactIds,
        workflowId,
      };
    }
  };

  const toggleSnackbar = (val) => (failure) => {
    let msg;
    if (failure) {
      let label;
      if (group) {
        label = groupName(singleThreadData);
      } else {
        label =
          singleThreadData.first_name || singleThreadData.last_name
            ? `${singleThreadData.first_name} ${singleThreadData.last_name}`
            : singleThreadData.phone_number;
      }
      msg = `Error: Conversation with ${label} couldn't be reopened`;
    } else {
      msg = `You reopened ${getPluralFromNumber(countThreads, "conversation")}`;
    }
    if (val) {
      const data = {
        id: workflowId,
        err: failure,
        msg,
        undoPayload,
      };
      setSnackData(data, "reopen");
    }
    clearSelection();
  };

  return <></>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Reopen);
