import { ReactComponent as VoipIcon } from "../../../../assets/img/icons-new/number-status/voip/voip-gray.svg";
import { ReactComponent as LocalIcon } from "../../../../assets/img/icons-new/number-status/local/local-gray.svg";
import { ReactComponent as TollFreeIcon } from "../../../../assets/img/icons-new/number-status/toll-free/toll-free-gray.svg";
import { ReactComponent as ShortcodeIcon } from "../../../../assets/img/icons-new/number-status/shortcode/shortcode-gray.svg";
import { ReactComponent as LandlineIcon } from "../../../../assets/img/icons-new/number-status/landline/landline-gray.svg";
import { ReactComponent as HostedLandlineIcon } from "../../../../assets/img/icons-new/number-status/hosted-landline/hosted-landline-gray.svg";
import { ReactComponent as HostedTollFreeIcon } from "../../../../assets/img/icons-new/number-status/hosted-toll-free/hosted-toll-free-gray.svg";
import { ReactComponent as CallerIdIcon } from "../../../../assets/img/icons-new/number-status/caller-id/caller-id-gray.svg";
import { ReactComponent as SharedNumberIcon } from "../../../../assets/img/icons-new/number-status/shared-number/shared-number-gray.svg";
import { ReactComponent as HTVTollFreeIcon } from "../../../../assets/img/icons-new/number-status/htv-toll-free/htv-toll-free-gray.svg";
import SpanWithTooltip from "../../../SpanWithTooltip";

const mappedNumberTypes = {
  voip: "VOIP",
  local: "Local",
  "toll-free": "Toll-Free",
  "backup-toll-free": "Toll-Free",
  shortcode: "Shortcode",
  landline: "Landline, Hosted Landline",
  "hosted-toll-free": "Hosted Toll-Free",
};

const mappedNumberSources = { 4: "Caller ID", 5: "Shared", 6: "Shared" };

export const mappedHTVTollFreeNumber = "HTV Toll Free";

export const getMappedNumberType = (numberType) =>
  mappedNumberTypes[numberType];

export const getMappedNumberSource = (numberSource) =>
  mappedNumberSources[numberSource];

export const getNumberTypesWithIcons = (number, withTooltip) => {
  let icons = [];
  const numberType = getMappedNumberType(number.type);
  const numberSource = getMappedNumberSource(number.src);

  switch (number.type) {
    case "voip":
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberType}>
            <VoipIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            <VoipIcon className="number-type-icon" />
            <span className="pl-2">{numberType}</span>
          </>
        )
      );
      break;
    case "local":
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberType}>
            <LocalIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            {" "}
            <LocalIcon className="number-type-icon" />
            <span className="pl-2">{numberType}</span>
          </>
        )
      );
      break;
    case "toll-free":
    case "backup-toll-free":
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberType}>
            <TollFreeIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            <TollFreeIcon className="number-type-icon" />
            <span className="pl-2">{numberType}</span>
          </>
        )
      );
      break;
    case "shortcode":
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberType}>
            <ShortcodeIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            <ShortcodeIcon className="number-type-icon" />
            <span className="pl-2">{numberType}</span>
          </>
        )
      );
      break;
    case "landline":
      const typeNames = numberType.split(", ");

      if (withTooltip) {
        icons = [
          ...icons,
          <>
            <SpanWithTooltip title={typeNames[0]} className="pr-2">
              <LandlineIcon className="number-type-icon" />
            </SpanWithTooltip>
            <SpanWithTooltip title={typeNames[1]}>
              <HostedLandlineIcon className="number-type-icon" />
            </SpanWithTooltip>
          </>,
        ];
      } else {
        icons = [
          ...icons,
          <>
            <LandlineIcon className="number-type-icon" />
            <span className="pl-2">{typeNames[0]}</span>
          </>,
          <>
            <HostedLandlineIcon className="number-type-icon" />
            <span className="pl-2">{typeNames[1]}</span>
          </>,
        ];
      }
      break;
    case "hosted-toll-free":
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberType}>
            <HostedTollFreeIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            <HostedTollFreeIcon className="number-type-icon" />
            <span className="pl-2">{numberType}</span>
          </>
        )
      );
      break;
    default:
      break;
  }
  switch (number.src) {
    case 4:
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberSource}>
            <CallerIdIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            <CallerIdIcon className="number-type-icon" />
            <span className="pl-2">{numberSource}</span>
          </>
        )
      );
      break;
    case 5:
    case 6:
      icons.push(
        withTooltip ? (
          <SpanWithTooltip title={numberSource}>
            <SharedNumberIcon className="number-type-icon" />
          </SpanWithTooltip>
        ) : (
          <>
            <SharedNumberIcon className="number-type-icon" />
            <span className="pl-2">{numberSource}</span>
          </>
        )
      );
      break;
    default:
      break;
  }

  if (number.is_high_throughput_number) {
    icons.push(
      withTooltip ? (
        <SpanWithTooltip title={mappedHTVTollFreeNumber}>
          <HTVTollFreeIcon className="number-type-icon" />
        </SpanWithTooltip>
      ) : (
        <>
          <HTVTollFreeIcon className="number-type-icon mb-1" />
          <span className="pl-2">{mappedHTVTollFreeNumber}</span>
        </>
      )
    );
  }
  return icons;
};
