import {
  GROUP_TEXT_SIGNATURE,
  STANDARD_TEXT_SIGNATURE,
} from "../../utils/constants";

const initialState = {
  // My Profile
  profileStatus: "",
  profileData: {},
  profileError: "",

  postProfileStatus: {},
  postProfileError: "",

  changePasswordStatus: "",
  changePasswordError: "",

  avatarUrl: "",
  myProfileAvatarStatus: "",

  updateAvatarStatus: "",

  resendUpdateEmailStatus: "",
  cancelUpdateEmailStatus: "",

  updateEmailStatus: "",
  updateEmailError: "",

  // My Signatures
  numberSignatures: [],
  numberSignaturesStatus: "",

  addNumberSignatureStatus: "",

  setDefaultSignatureStatus: "",

  editNumberSignatureStatus: "",

  deleteNumberSignatureStatus: "",

  // Notifications
  startVerificationStatus: "",
  checkVerificationCodeStatus: "",
  userNotificationSettings: {},
  userNotificationSettingsStatus: "",
  updateNotificationSettingsStatus: "",
  updateNotificationSettingsError: "",

  updatedUserNotficationSettings: {},

  // OTHER COMPANY CREDITS
  otherCompanyCredits: null,
  otherCompanyCreditsStatus: "",

  // other company numbes
  otherCompanyNumbers: [],
  otherCompanyNumbersStatus: "",

  // Restore notifications to default
  restoreDefaultNotificationsStatus: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // My profile
    case "USER_SETTINGS/FETCH_MY_PROFILE": {
      return {
        ...state,
        profileStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_MY_PROFILE.SUCCESS": {
      return {
        ...state,
        profileData: action.payload.data,
        profileStatus: "success",
      };
    }

    case "USER_SETTINGS/FETCH_MY_PROFILE.ERROR": {
      return {
        ...state,
        profileError: action.error,
      };
    }

    case "USER_SETTINGS/POST_MY_PROFILE": {
      return {
        ...state,
        postProfileStatus: {
          [action.requestId]: "loading",
        },
      };
    }

    case "USER_SETTINGS/POST_MY_PROFILE.SUCCESS": {
      return {
        ...state,
        postProfileStatus: {
          [action.requestId]: "success",
        },
        profileData: { ...state.profileData, ...action.payload.data },
      };
    }

    case "USER_SETTINGS/POST_MY_PROFILE.ERROR": {
      return {
        ...state,
        postProfileStatus: {
          [action.requestId]: "error",
        },
        postProfileError: action.error.data.message,
      };
    }

    case "USER_SETTINGS/POST_MY_PROFILE.CLEAR": {
      const newPostProfileStatus = { ...state.postProfileStatus };
      newPostProfileStatus[action.requestId] = undefined;

      return {
        ...state,
        postProfileStatus: newPostProfileStatus,
      };
    }

    case "USER_SETTINGS/CHANGE_PASSWORD": {
      return {
        ...state,
        changePasswordStatus: "loading",
      };
    }

    case "USER_SETTINGS/CHANGE_PASSWORD.SUCCESS": {
      return {
        ...state,
        changePasswordStatus: "success",
      };
    }

    case "USER_SETTINGS/CHANGE_PASSWORD.ERROR": {
      return {
        ...state,
        changePasswordStatus: "error",
        changePasswordError: action.error.data.message,
      };
    }

    case "USER_SETTINGS/CHANGE_PASSWORD.CLEAR": {
      return {
        ...state,
        changePasswordStatus: "",
      };
    }

    case "USER_SETTINGS/FETCH_MY_PROFILE_AVATAR": {
      return {
        ...state,
        myProfileAvatarStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_MY_PROFILE_AVATAR.SUCCESS": {
      return {
        ...state,
        avatarUrl: action.payload.data,
        myProfileAvatarStatus: "success",
      };
    }

    case "USER_SETTINGS/UPDATE_AVATAR": {
      return {
        ...state,
        updateAvatarStatus: "loading",
      };
    }

    case "USER_SETTINGS/UPDATE_AVATAR.SUCCESS": {
      return {
        ...state,
        avatarUrl: action.payload.data,
        updateAvatarStatus: "success",
      };
    }

    case "USER_SETTINGS/UPDATE_AVATAR.CLEAR": {
      return {
        ...state,
        updateAvatarStatus: "",
      };
    }

    case "USER_SETTINGS/RESEND_UPDATE_EMAIL": {
      return {
        ...state,
        resendUpdateEmailStatus: "loading",
      };
    }

    case "USER_SETTINGS/RESEND_UPDATE_EMAIL.SUCCESS": {
      return {
        ...state,
        resendUpdateEmailStatus: "success",
      };
    }
    case "USER_SETTINGS/RESEND_UPDATE_EMAIL.CLEAR": {
      return {
        ...state,
        resendUpdateEmailStatus: "",
      };
    }

    case "USER_SETTINGS/CANCEL_UPDATE_EMAIL": {
      return {
        ...state,
        cancelUpdateEmailStatus: "loading",
      };
    }

    case "USER_SETTINGS/CANCEL_UPDATE_EMAIL.SUCCESS": {
      return {
        ...state,
        cancelUpdateEmailStatus: "success",
        profileData: { ...state.profileData, requested_email: null },
      };
    }
    case "USER_SETTINGS/CANCEL_UPDATE_EMAIL.CLEAR": {
      return {
        ...state,
        cancelUpdateEmailStatus: "",
      };
    }

    case "USER_SETTINGS/UPDATE_EMAIL": {
      return {
        ...state,
        updateEmailStatus: "loading",
      };
    }

    case "USER_SETTINGS/UPDATE_EMAIL.SUCCESS": {
      return {
        ...state,
        updateEmailStatus: "success",
        profileData: { ...state.profileData, requested_email: action.email },
      };
    }

    case "USER_SETTINGS/UPDATE_EMAIL.ERROR": {
      return {
        ...state,
        updateEmailStatus: "error",
        updateEmailError: action.error.data.message,
      };
    }

    case "USER_SETTINGS/UPDATE_EMAIL.CLEAR": {
      return {
        ...state,
        updateEmailStatus: "",
      };
    }

    // My Signatures
    case "USER_SETTINGS/FETCH_NUMBER_SIGNATURES_LIST": {
      return {
        ...state,
        numberSignaturesStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_NUMBER_SIGNATURES_LIST.SUCCESS": {
      return {
        ...state,
        numberSignatures: action.payload.data,
        numberSignaturesStatus: "success",
      };
    }

    case "USER_SETTINGS/FETCH_NUMBER_SIGNATURES_LIST.ERROR": {
      return {
        ...state,
        numberSignaturesStatus: "error",
      };
    }

    case "USER_SETTINGS/FETCH_NUMBER_SIGNATURES_LIST.CLEAR": {
      return {
        ...state,
        numberSignaturesStatus: "",
      };
    }

    case "USER_SETTINGS/ADD_NUMBER_SIGNATURE": {
      return {
        ...state,
        addNumberSignatureStatus: "loading",
      };
    }

    case "USER_SETTINGS/ADD_NUMBER_SIGNATURE.SUCCESS": {
      return {
        ...state,
        addNumberSignatureStatus: "success",
      };
    }

    case "USER_SETTINGS/ADD_NUMBER_SIGNATURE.ERROR": {
      return {
        ...state,
        addNumberSignatureStatus: "error",
      };
    }

    case "USER_SETTINGS/ADD_NUMBER_SIGNATURE.CLEAR": {
      return {
        ...state,
        addNumberSignatureStatus: "",
      };
    }

    case "USER_SETTINGS/SET_DEFAULT_SIGNATURE": {
      return {
        ...state,
        setDefaultSignatureStatus: "loading",
      };
    }

    case "USER_SETTINGS/SET_DEFAULT_SIGNATURE.SUCCESS": {
      const newSignatures = [...state.numberSignatures];

      /// set as default for special numbers (shortcode, verified toll free and toll free number)
      if (
        action.selectedNumberSignature.src === 5 ||
        action.selectedNumberSignature.src === 6 ||
        action.selectedNumberSignature.is_toll_free ||
        action.selectedNumberSignature.is_high_throughput_number
      ) {
        newSignatures[action.selectedNumberSignature.index].signatures.forEach(
          (signature) => {
            signature.is_default = 0;
          }
        );
        newSignatures[action.selectedNumberSignature.index].signatures[
          action.selectedIndex
        ].is_default = 1;
      } else {
        // set as default for standard type signature
        if (action.selectedSignature.type === STANDARD_TEXT_SIGNATURE) {
          newSignatures[
            action.selectedNumberSignature.index
          ].signatures.forEach((signature) => {
            if (signature.type === STANDARD_TEXT_SIGNATURE) {
              signature.is_default = 0;
            }
          });
          newSignatures[action.selectedNumberSignature.index].signatures[
            action.selectedIndex
          ].is_default = 1;
        }

        // set as default for group type signature
        if (action.selectedSignature.type === GROUP_TEXT_SIGNATURE) {
          newSignatures[
            action.selectedNumberSignature.index
          ].signatures.forEach((signature) => {
            if (signature.type === GROUP_TEXT_SIGNATURE) {
              signature.is_default = 0;
            }
          });
          newSignatures[action.selectedNumberSignature.index].signatures[
            action.selectedIndex
          ].is_default = 1;
        }
      }

      return {
        ...state,
        setDefaultSignatureStatus: "success",
        numberSignatures: newSignatures,
      };
    }

    case "USER_SETTINGS/SET_DEFAULT_SIGNATURE.ERROR": {
      return {
        ...state,
        setDefaultSignatureStatus: "error",
      };
    }

    case "USER_SETTINGS/SET_DEFAULT_SIGNATURE.CLEAR": {
      return {
        ...state,
        setDefaultSignatureStatus: "",
      };
    }

    case "USER_SETTINGS/EDIT_NUMBER_SIGNATURE": {
      return {
        ...state,
        editNumberSignatureStatus: "loading",
      };
    }

    case "USER_SETTINGS/EDIT_NUMBER_SIGNATURE.SUCCESS": {
      const newNumberSignatures = [...state.numberSignatures];

      newNumberSignatures[action.indexes.numberSignaturesIndex].signatures[
        action.indexes.signatureIndex
      ] = {
        ...newNumberSignatures[action.indexes.numberSignaturesIndex].signatures[
          action.indexes.signatureIndex
        ],
        ...action.body,
      };

      return {
        ...state,
        numberSignatures: newNumberSignatures,
        editNumberSignatureStatus: "success",
      };
    }

    case "USER_SETTINGS/EDIT_NUMBER_SIGNATURE.CLEAR": {
      return {
        ...state,
        editNumberSignatureStatus: "",
      };
    }

    case "USER_SETTINGS/DELETE_NUMBER_SIGNATURE": {
      return {
        ...state,
        deleteNumberSignatureStatus: "loading",
      };
    }

    case "USER_SETTINGS/DELETE_NUMBER_SIGNATURE.SUCCESS": {
      return {
        ...state,
        deleteNumberSignatureStatus: "success",
      };
    }

    case "USER_SETTINGS/DELETE_NUMBER_SIGNATURE.CLEAR": {
      return {
        ...state,
        deleteNumberSignatureStatus: "",
      };
    }

    case "USER_SETTINGS/START_PHONE_VERIFICATION": {
      return {
        ...state,
        startVerificationStatus: "loading",
      };
    }
    case "USER_SETTINGS/START_PHONE_VERIFICATION.SUCCESS": {
      return {
        ...state,
        startVerificationStatus: "success",
      };
    }
    case "USER_SETTINGS/START_PHONE_VERIFICATION.ERROR": {
      return {
        ...state,
        startVerificationStatus: "error",
      };
    }
    case "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE": {
      return {
        ...state,
        checkVerificationCodeStatus: "loading",
      };
    }
    case "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE.SUCCESS": {
      return {
        ...state,
        checkVerificationCodeStatus: "success",
        profileData: { ...state.profileData, is_phone_verified: 1 },
      };
    }
    case "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE.ERROR": {
      return {
        ...state,
        checkVerificationCodeStatus: "error",
      };
    }
    case "USER_SETTINGS/CHECK_PHONE_VERIFICATION_CODE.CLEAR": {
      return {
        ...state,
        checkVerificationCodeStatus: null,
      };
    }

    case "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE": {
      return {
        ...state,
        updateNotificationsModeStatus: "loading",
      };
    }
    case "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE.SUCCESS": {
      return {
        ...state,
        updateNotificationsModeStatus: "success",
        profileData: {
          ...state.profileData,
          notification_mode: action.notifications_mode,
        },
        userNotificationSettings: {
          ...state.userNotificationSettings,
          mode: action.notifications_mode,
        },
      };
    }
    case "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE.ERROR": {
      return {
        ...state,
        updateNotificationsModeStatus: "error",
        updateNotificationsError: action.error.data.message,
      };
    }
    case "USER_SETTINGS/UPDATE_NOTIFICATIONS_MODE.CLEAR": {
      return {
        ...state,
        updateNotificationsModeStatus: null,
      };
    }
    case "USER_SETTINGS/FETCH_USER_NOTIFICATION_SETTINGS": {
      return {
        ...state,
        userNotificationSettingsStatus: "loading",
      };
    }
    case "USER_SETTINGS/FETCH_USER_NOTIFICATION_SETTINGS.SUCCESS": {
      return {
        ...state,
        userNotificationSettingsStatus: "success",
        userNotificationSettings: action.payload.data,
      };
    }
    case "USER_SETTINGS/FETCH_USER_NOTIFICATION_SETTINGS.ERROR": {
      return {
        ...state,
        userNotificationSettingsStatus: "error",
      };
    }
    case "USER_SETTINGS/FETCH_USER_NOTIFICATION_SETTINGS.CLEAR": {
      return {
        ...state,
        userNotificationSettingsStatus: null,
      };
    }
    case "USER_SETTINGS/UPDATE_USER_NOTIFICATION_SETTINGS": {
      return {
        ...state,
        updateNotificationSettingsStatus: "loading",
      };
    }
    case "USER_SETTINGS/UPDATE_USER_NOTIFICATION_SETTINGS.SUCCESS": {
      const updatedNotificationSettings = {
        ...state.userNotificationSettings,
        ...action.notificationSettings,
      };
      return {
        ...state,
        updateNotificationSettingsStatus: "success",
        userNotificationSettings: updatedNotificationSettings,
        updatedUserNotficationSettings: action.updatedUserNotficationSettings
          ? {
              ...state.updatedUserNotficationSettings,
              ...action.updatedUserNotficationSettings,
            }
          : {},
      };
    }
    case "USER_SETTINGS/UPDATE_USER_NOTIFICATION_SETTINGS.ERROR": {
      return {
        ...state,
        updateNotificationSettingsStatus: "error",
        updateNotificationSettingsError: action.error.data.message,
        isMainSwitchToggle: false,
        updatedUserNotficationSettings: {},
      };
    }
    case "USER_SETTINGS/UPDATE_USER_NOTIFICATION_SETTINGS.CLEAR": {
      return {
        ...state,
        updateNotificationSettingsStatus: null,
        updateNotificationSettingsError: null,
        updatedUserNotficationSettings: {},
      };
    }

    // Other company credits
    case "USER_SETTINGS/FETCH_OTHER_COMPANY_CREDITS": {
      return {
        ...state,
        otherCompanyCreditsStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_OTHER_COMPANY_CREDITS.SUCCESS": {
      return {
        ...state,
        otherCompanyCreditsStatus: "success",
        otherCompanyCredits: action.payload?.credits || 0,
      };
    }

    case "USER_SETTINGS/FETCH_OTHER_COMPANY_CREDITS.ERROR": {
      return {
        ...state,
        otherCompanyCreditsStatus: "error",
      };
    }

    // Other company numbers
    case "USER_SETTINGS/FETCH_OTHER_COMPANY_NUMBERS": {
      return {
        ...state,
        otherCompanyNumbersStatus: "loading",
      };
    }

    case "USER_SETTINGS/FETCH_OTHER_COMPANY_NUMBERS.SUCCESS": {
      return {
        ...state,
        otherCompanyNumbersStatus: "success",
        otherCompanyNumbers: action.payload?.numbers || [],
      };
    }

    case "USER_SETTINGS/FETCH_OTHER_COMPANY_NUMBERS.ERROR": {
      return {
        ...state,
        otherCompanyNumbersStatus: "error",
      };
    }

    case "USER_SETTINGS/FETCH_OTHER_COMPANY_NUMBERS.CLEAR": {
      return {
        ...state,
        otherCompanyNumbersStatus: "",
      };
    }

    case "USER_SETTINGS/RESTORE_DEFAULT_NOTIFICATIONS": {
      return {
        ...state,
        restoreDefaultNotificationsStatus: "loading",
      };
    }
    case "USER_SETTINGS/RESTORE_DEFAULT_NOTIFICATIONS.SUCCESS": {
      return {
        ...state,
        restoreDefaultNotificationsStatus: "success",
      };
    }
    case "USER_SETTINGS/RESTORE_DEFAULT_NOTIFICATIONS.ERROR": {
      return {
        ...state,
        restoreDefaultNotificationsStatus: "error",
      };
    }
    case "USER_SETTINGS/RESTORE_DEFAULT_NOTIFICATIONS.CLEAR": {
      return {
        ...state,
        restoreDefaultNotificationsStatus: null,
      };
    }

    default:
      return state;
  }
}
