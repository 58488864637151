import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ReactComponent as CcbSvg } from "../../../assets/img/icons-new/people-info/ccb/ccb-gray.svg";
import { ReactComponent as PcoSvg } from "../../../assets/img/icons-new/people-info/pco/pco-gray.svg";
import { ReactComponent as BreezeSvg } from "../../../assets/img/icons-new/integration/breeze/breeze-dark-gray.svg";
import { ReactComponent as RockSvg } from "../../../assets/img/icons-new/integration/rockRMS/rockRMS-dark-gray.svg";
import { ReactComponent as McSvg } from "../../../assets/img/icons-new/people-info/mailchimp/mailchimp-white.svg";
import { ReactComponent as ElvantoSvg } from "../../../assets/img/icons-new/people-info/elvanto/elvanto-black.svg";
import { ReactComponent as PLSvg } from "../../../assets/img/icons-new/people-info/pastorsline/pastorsline-gray.svg";
import SpanWithTooltip from "../../SpanWithTooltip";

const ThreadItemMultipleContactsIntegrationIcons = (props) => {
  const { integrationIconName, handleIconTooltip, iconClassName } = props;

  let integrationIcon;
  switch (integrationIconName) {
    case "CCB":
      integrationIcon = (
        <CcbSvg className={iconClassName} iconname={"CCB"} width={16} />
      );
      break;
    case "PCO":
      integrationIcon = (
        <PcoSvg className={iconClassName} iconname={"PCO"} width={16} />
      );
      break;
    case "MAILCHIMP":
      integrationIcon = (
        <McSvg className={iconClassName} iconname={"Mailchimp"} width={16} />
      );
      break;
    case "ELVANTO":
      integrationIcon = (
        <ElvantoSvg className={iconClassName} iconname={"Elvanto"} width={16} />
      );
      break;
    case "BREEZE":
      integrationIcon = (
        <BreezeSvg className={iconClassName} iconname={"Breeze"} width={8} />
      );
      break;
    case "ROCK":
      integrationIcon = (
        <RockSvg
          className={clsx(iconClassName, "mb-1")}
          iconname={"RockRMS"}
          width={18}
          height={18}
        />
      );
      break;
    case "PL":
      integrationIcon = (
        <PLSvg className={iconClassName} iconname={"PastorsLine"} width={18} />
      );
      break;
    default:
      integrationIcon = null;
  }
  if (!integrationIconName) return <></>;

  return (
    <>
      {handleIconTooltip ? (
        <SpanWithTooltip
          placement="top"
          title={integrationIcon.props.iconname}
          onMouseEnter={handleIconTooltip(true)}
          onMouseLeave={handleIconTooltip(false)}
          className="icon"
        >
          {integrationIcon}
        </SpanWithTooltip>
      ) : (
        <span className="ml-1">{integrationIcon}</span>
      )}
    </>
  );
};

ThreadItemMultipleContactsIntegrationIcons.prototype = {
  integrationIconName: PropTypes.string,
  handleIconTooltip: PropTypes.func,
  iconClassName: PropTypes.string,
};

ThreadItemMultipleContactsIntegrationIcons.defaultProps = {
  iconClassName: "icon-grey",
  integrationIconName: "",
};

export default ThreadItemMultipleContactsIntegrationIcons;
