import React, { Fragment, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Alert } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { ReactComponent as CloseWhiteSvg } from "../../assets/img/icons/close-white.svg";
import { ReactComponent as AlertInfoSvg } from "../../assets/img/icons-new/number-status/invalid/invalid-white.svg";
import { ReactComponent as PaymentIssueSvg } from "../../assets/img/icons-new/general/payment/payment-issue-white.svg";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { IssueKeyEnums } from "../../utils/enums/accountEnums";
import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";

const AccountIssueBar = (props) => {
  const {
    onCloseBanner,
    visible,
    issueKey,
    alertClass,
    icon,
    shortMessage,
    message,
    dismissible,
    url,
    hasRefreshButton,
    fontSize,
  } = props;
  const breakpoint = useBreakpoint();

  // Redux store
  const { companies, fetchCompanies } = useCompaniesStore();
  const { companiesStatus } = companies;

  const classNameMobile =
    "top-alert top-banner-wrapper d-lg-none " + alertClass;

  const className =
    "top-alert top-banner-wrapper d-none d-lg-flex align-items-center justify-content-center " +
    alertClass;

  const mobileModal =
    issueKey !== IssueKeyEnums.Hard &&
    issueKey !== IssueKeyEnums.Inactive &&
    issueKey !== IssueKeyEnums.InvalidEmail;

  const constProps = {
    closeClassName: "d-none",
    isOpen: visible,
    toggle: onCloseBanner,
  };

  const onClickClose = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onCloseBanner();
    },
    [onCloseBanner]
  );

  const onClickRefreshData = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (companiesStatus === "loading") return null;
      fetchCompanies(true);
    },
    [fetchCompanies, companiesStatus]
  );

  const renderRefreshButton = useMemo(() => {
    if (hasRefreshButton) {
      return (
        <span>
          {isMdOrBelowBreakpoint(breakpoint) ? (
            <span
              onClick={onClickRefreshData}
              className={clsx({
                "top-bar-link-text": companiesStatus !== "loading",
              })}
            >
              {companiesStatus === "loading" ? "Loading..." : "Refresh data"}
            </span>
          ) : (
            <>
              <b>Purchased?</b>{" "}
              <span
                onClick={onClickRefreshData}
                className={clsx({
                  "top-bar-link-text": companiesStatus !== "loading",
                })}
              >
                {companiesStatus === "loading" ? "Loading..." : "Click here"}
              </span>{" "}
              to Refresh data
            </>
          )}
        </span>
      );
    }
    return null;
  }, [companiesStatus, hasRefreshButton, onClickRefreshData, breakpoint]);

  const renderMobileContent = useMemo(() => {
    return (
      <Fragment>
        {icon === "payment" && <PaymentIssueSvg width={25.833} height={18} />}
        {icon === "warning" && <AlertInfoSvg width={16} height={16} />}
        <div className="ml-2">
          {ReactHtmlParser(shortMessage)}{" "}
          <Link
            to="/hub/menus/filter/settings/admin-settings/submenu/admin-billing/pay-billing"
            className="account-issue-pay-now"
            style={{ color: "#eb6033" }}
          >
            Pay now.
          </Link>{" "}
          {renderRefreshButton}
        </div>
        {dismissible && (
          <span onClick={onClickClose} className="account-issue-bar-close-icon">
            <CloseWhiteSvg
              width={11}
              height={11}
              className="close-notification-button"
            />
          </span>
        )}
      </Fragment>
    );
  }, [dismissible, icon, onClickClose, shortMessage, renderRefreshButton]);

  const renderContentForDesktop = useMemo(() => {
    return (
      <Fragment>
        {icon === "payment" && <PaymentIssueSvg width={25.833} height={18} />}
        {icon === "warning" && <AlertInfoSvg width={16} height={16} />}
        <div className="ml-2" style={{ fontSize }}>
          {ReactHtmlParser(message)}{" "}
          <Link
            to="/hub/menus/filter/settings/admin-settings/submenu/admin-billing/pay-billing"
            className="account-issue-pay-now"
            style={{ color: "#eb6033" }}
          >
            Pay now.
          </Link>{" "}
          {renderRefreshButton}
        </div>
        {dismissible && (
          <span onClick={onClickClose} className="account-issue-bar-close-icon">
            <CloseWhiteSvg
              width={11}
              height={11}
              className="close-notification-button"
            />
          </span>
        )}
      </Fragment>
    );
  }, [dismissible, icon, message, onClickClose, renderRefreshButton, fontSize]);

  if (!visible) return null;

  if (mobileModal && isMdOrBelowBreakpoint(breakpoint)) {
    return (
      <Alert
        {...constProps}
        tag="a"
        href="#modal-account-issue"
        className={classNameMobile}
      >
        {renderMobileContent}
      </Alert>
    );
  }

  return (
    <Fragment>
      {!mobileModal && (
        <Alert {...constProps} className={classNameMobile}>
          {renderMobileContent}
        </Alert>
      )}
      {url && (
        <NavLink to={url}>
          <Alert className={className}>{renderContentForDesktop}</Alert>
        </NavLink>
      )}
      {!url && <Alert className={className}>{renderContentForDesktop}</Alert>}
    </Fragment>
  );
};

AccountIssueBar.propTypes = {
  onCloseBanner: PropTypes.func,
  visible: PropTypes.bool,
  issueKey: PropTypes.string,
  alertClass: PropTypes.string,
  shortMessage: PropTypes.string,
  message: PropTypes.string,
  dismissible: PropTypes.bool,
  hasRefreshButton: PropTypes.bool,
  url: PropTypes.string,
  fontSize: PropTypes.string,
};

AccountIssueBar.defaultProps = {
  onCloseBanner: () => {},
  visible: false,
  issueKey: "",
  alertClass: "",
  shortMessage: "",
  message: "",
  dismissible: false,
  hasRefreshButton: false,
  url: "",
  fontSize: "auto",
};

export default AccountIssueBar;
