import * as threadsActions from "./threads";
import * as userActions from "./users";
import * as mainActions from "./main";
import * as numberActions from "./numbers";
import * as groupActions from "./groups";
import * as messagesActions from "./messages";
import * as contactActions from "./contacts";

const PusherActions = {
  threadsActions,
  userActions,
  mainActions,
  numberActions,
  groupActions,
  messagesActions,
  contactActions,
};

export default PusherActions;
