import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import NotifierWrapper from "./NotifierWrapper";
import { ReactComponent as ShowInfo } from "../../../../assets/img/icons-new/number-status/show-info/show-info-gray.svg";
import useAdminSettingsStore from "../../../../utils/hooks/ReduxHooks/adminSettingsStore";
import useCompaniesStore from "../../../../utils/hooks/ReduxHooks/companiesStore";
import useBreakpoint from "../../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../../utils/breakpoints";
import { GROUP } from "../../../../utils/constants";

const MessageDeliveryAlertNotifier = (props) => {
  const { threadType } = props;

  // Redux store
  const { adminSettings, fetchCompanySettings } = useAdminSettingsStore();
  const { companies } = useCompaniesStore();
  const { companySettings, companySettingsStatus } = adminSettings;
  const { currentCompany } = companies;

  // Breakpoints
  const breakpoint = useBreakpoint();

  const getAlertMessage = useMemo(() => {
    if (isMdOrBelowBreakpoint(breakpoint)) {
      return "Reprocessing is on";
    }
    return "Advanced options for optimized message delivery are turned on. Additional costs may apply";
  }, [breakpoint]);

  useEffect(() => {
    if (!companySettingsStatus) {
      fetchCompanySettings(currentCompany?.id);
    }
  }, [currentCompany, fetchCompanySettings, companySettingsStatus]);

  if (!companySettings?.auto_resend_type || threadType !== GROUP) return null;

  return (
    <NotifierWrapper
      alertMessage={getAlertMessage}
      Icon={ShowInfo}
      className={"message-delivery-alert-container"}
      linkText={"See details"}
      hasAlert={true}
      link={"#modal-resend-messages-number-info"}
      textClassName={"message-deleivery-alert-text"}
      linkClassName={"message-delivery-alert-link"}
      closeIconClassName={"ml-3 message-delivery-alert-close-icon"}
      iconClassName={"message-delivery-alert-icon"}
    />
  );
};

MessageDeliveryAlertNotifier.propTypes = {
  threadType: PropTypes.string,
};

MessageDeliveryAlertNotifier.defaultProps = {
  threadType: "",
};

export default MessageDeliveryAlertNotifier;
