import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import CustomSelect from "../../../../hub/HelperComponents/CustomSelect";
import { Grid, TextField } from "@material-ui/core";
import NumberChip from "./NumberChip";
import TablePaginationActions from "../../../../hub/HelperComponents/TablePaginationActions";

//new icon
import { ReactComponent as RefreshSvg } from "../../../../../assets/img/icons-new/settings/refresh/refresh-purple.svg";

const options = [
  {
    value: 1,
    name: "test",
  },
  {
    value: 2,
    name: "test long text long",
  },
];

const numberData = [
  [
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2103", subtitle: "Voice SMS MMS" },
  ],
  [
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
    { numberText: "(985) 444-2555", subtitle: "Voice SMS MMS" },
  ],
];

const NumberBody = (props) => {
  // closeModal
  const { onChoose, isTollFreeNumber } = props;

  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});

  const onChangePage = useCallback((_, pageChanged) => {
    setPage(pageChanged);
  }, []);

  const selectItem = useCallback(
    (index) => {
      setSelectedItem({
        page,
        index,
      });
    },
    [page]
  );

  return (
    <div className="number-body-container">
      <div
        className={`d-flex ${
          isTollFreeNumber
            ? "justify-content-center"
            : "justify-content-between"
        }`}
      >
        {!isTollFreeNumber && (
          <>
            <div>
              <p className="mb-n1 top-label">Country</p>
              <CustomSelect options={options} value={1} />
            </div>
            <div>
              <p className="mb-n1 top-label">Search By</p>
              <CustomSelect options={options} value={1} />
            </div>
          </>
        )}
        <div>
          <p className="mb-n1 top-label">Search By Digits/Phrases</p>
          <TextField value="Hello" />
        </div>
        <div className={isTollFreeNumber ? "ml-4" : ""}>
          <p className="mb-n1 top-label">Match to</p>
          <CustomSelect options={options} value={1} />
        </div>
      </div>
      <div className="search-container d-flex justify-content-center">
        <button type="button" className="btn btn-outline-primary">
          Search
        </button>
      </div>
      <div className="number-chip-container">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          {numberData[page]?.map((element, index) => (
            <Grid item xs={3}>
              <NumberChip
                numberText={element.numberText}
                subtitle={element.subtitle}
                selected={
                  selectedItem.page === page && selectedItem.index === index
                }
                onClick={() => selectItem(index)}
              />
            </Grid>
          ))}
        </Grid>
        <TablePaginationActions
          count={numberData.length}
          page={page}
          className="mt-3"
          onChangePage={onChangePage}
        />
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div className="refresh-button d-flex align-items-center">
          <RefreshSvg className="mr-1" />
          <span>Refresh</span>
        </div>

        <button
          type="button"
          className="btn btn-primary"
          onClick={onChoose}
          style={{
            visibility:
              selectedItem.page !== undefined &&
              selectedItem.index !== undefined
                ? "inherit"
                : "hidden",
          }}
        >
          Choose
        </button>
      </div>
    </div>
  );
};

NumberBody.propTypes = {
  closeModal: PropTypes.func,
  onChoose: PropTypes.func,
  isTollFreeNumber: PropTypes.bool,
};

NumberBody.defaultProps = {
  isTollFreeNumber: false,
};

export default NumberBody;
