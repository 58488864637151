import React from "react";
import { ReactComponent as PaymentIssueSvg } from "../../../assets/img/icons-new/general/payment/payment-issue-black.svg";
import { ReactComponent as ShowInfoSvg } from "../../../assets/img/icons-new/number-status/show-info/show-info-blue.svg";
import PropTypes from "prop-types";
import { IssueKeyEnums } from "../../../utils/enums/accountEnums";

const icon = (
  <span className={"icon"}>
    <PaymentIssueSvg className={"icon-black"} width={25} height={18} />
  </span>
);

const AccountIssueBody = (props) => {
  const { message, issueKey, billingPackage, billingIssueDays } = props;

  return (
    <>
      {issueKey === IssueKeyEnums.Soft && (
        <>
          {icon}
          <p
            className="locked-info-modal-container"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </>
      )}

      {issueKey === IssueKeyEnums.Medium && (
        <>
          {icon}
          <p className="locked-info-modal-container">
            {billingPackage <= 35 && (
              <React.Fragment>
                Continue testing our platform by extending your trial period.
                Use the invite code <strong>GRACE</strong> when choosing a plan
                and pay only $1 for 14 more days.
              </React.Fragment>
            )}
            {billingPackage > 35 && (
              <React.Fragment>
                It looks like your payment failed again. <br />
                Sending messages is disabled. Your account will lose access to
                your number(s) and all your credits in {billingIssueDays} days
                if this issue isn’t rectified asap.
              </React.Fragment>
            )}
          </p>
        </>
      )}
      {issueKey === IssueKeyEnums.NoCredits && (
        <div className="d-flex">
          <span className="mr-2">
            <ShowInfoSvg width={20} height={20} />
          </span>
          <p>{message}</p>
        </div>
      )}
    </>
  );
};
AccountIssueBody.propTypes = {
  message: PropTypes.string,
  issueKey: PropTypes.string,
  billingPackage: PropTypes.number,
  billingIssueDays: PropTypes.number,
};

AccountIssueBody.defaultProps = {
  message: "",
  billingIssueDays: 0,
};
export default AccountIssueBody;
