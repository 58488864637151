import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SelectCountryOnSearch from "../../HelperComponents/SelectCountryOnSearch";
import SearchBox from "../../HelperComponents/SearchBox";
import SpanWithTooltip from "../../../SpanWithTooltip";
import { ReactComponent as SearchIcon } from "../../../../assets/img/icons-new/general/search/search-gray.svg";

function mapStateToProps(store) {
  return {
    countries: store.countries.countries,
    groupSearch: store.groups.groupSearch,
  };
}

const ViewAllGroupTableSearch = (props) => {
  const {
    // Component props
    showSearch,
    searchRef,
    countryRef,
    queryIsNumber,
    query,
    handleCountryCodeChange,
    onChangeQuery,
    setNumberPasted,
    onClickedCancelSearch,
    toggleSearch,
    loadingStatus,

    // Redux props
    countries,
    groupSearch,
  } = props;

  const [searchQuery, setSearchQuery] = useState("");

  const openSearch = useCallback(() => {
    toggleSearch(true);
  }, [toggleSearch]);

  const handleQueryPaste = useCallback(() => {
    setNumberPasted(true);
  }, [setNumberPasted]);

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  useEffect(() => {
    setSearchQuery(groupSearch);
  }, [groupSearch]);

  if (showSearch) {
    return (
      <div className="d-flex align-items-center">
        <SelectCountryOnSearch
          countries={countries}
          countryRef={countryRef}
          queryIsNumber={queryIsNumber}
          onChangeCountryCode={handleCountryCodeChange}
          className={"inverse-color"}
        />
        <SearchBox
          placeholder="Search Group"
          value={searchQuery}
          onChange={onChangeQuery}
          loading={loadingStatus === "loading"}
          searchRef={searchRef}
          focusSearchBox={true}
          onPaste={handleQueryPaste}
        />
        <span className="cursor-pointer ml-2" onClick={onClickedCancelSearch}>
          Cancel
        </span>
      </div>
    );
  }
  return (
    <SpanWithTooltip
      className={"cursor-pointer"}
      title={"Search Goup"}
      onClick={openSearch}
    >
      <SearchIcon />
    </SpanWithTooltip>
  );
};

ViewAllGroupTableSearch.propTypes = {
  showSearch: PropTypes.bool,
  queryIsNumber: PropTypes.bool,
  handleCountryCodeChange: PropTypes.func.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  setNumberPasted: PropTypes.func.isRequired,
  onClickedCancelSearch: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  searchRef: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  countryRef: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  query: PropTypes.string,
};

ViewAllGroupTableSearch.defaultProps = {
  showSearch: false,
  queryIsNumber: false,
  query: "",
};

export default connect(mapStateToProps, null)(ViewAllGroupTableSearch);
