import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DashboardCard from "./DashboardCard";
import { ReactComponent as CircleQuestionMarkSvg } from "../../../assets/img/icons-new/people-tab/number-status/number-status-light-gray.svg";
import { fetchDashboardStats } from "../../../actions/dashboard";
import Spinner from "../../hub/HelperComponents/Spinner";
import { fetchCounts, fetchVoiceCounts } from "../../../actions/threads";
import PopoverSelect from "../../hub/HelperComponents/PopoverSelect/PopoverSelect";
import { capitalizeFirstLetter } from "../../../helpers";
import MessagesCallsWidgetStats from "./MessagesCallsWidgetStats";
import { MONTHS } from "../../../utils/enums/dashboardEnums";
import ExampleReport from "../../../assets/img/example-report.png";
import { getPrimaryUrl } from "../../../helpers";

const MESSAGES = "messages";
const CALLS = "calls";
const CREDITS = "credits";
const ALL = "all";

const messageCallOption = [
  {
    name: "Credits",
    value: CREDITS,
  },
  {
    name: "Calls",
    value: CALLS,
  },
  {
    name: "Messages",
    value: MESSAGES,
  },
  {
    name: "All",
    value: ALL,
  },
];

const billingCycleOption = [
  {
    name: "Current Billing Cycle",
    value: 0,
  },
  {
    name: "Previous Billing Cycle",
    value: 1,
  },
];

const cycleOption = [
  {
    name: "By Billing Cycle",
    value: 0,
  },
  {
    name: "By Monthly Cycle",
    value: 1,
  },
];

const thisYear = new Date().getFullYear();
const thisMonth = new Date().getMonth() + 1;
let yearOption = [];
for (let i = thisYear; i >= thisYear - 10; i--) {
  yearOption.push({
    name: i,
    value: i,
  });
}

function mapStateToProps(store, ownProps) {
  return {
    dashboardStatsData: store.dashboard.dashboardStatsData,
    dashboardStatsStatus: store.dashboard.dashboardStatsStatus,
    billingCycle: store.dashboard.billingCycle,
    currentCompanyId: store.companies.currentCompany.id,
    numbersFilter: store.numbers.numbersFilter,
    unreadsTotal: store.threads.unreadsTotal,
    countsStatus: store.threads.countsStatus,
    voiceCountsStatus: store.threads.voiceCountsStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDashboardStats: (companyId, numbersFilter, previous_cycle_offset) =>
      dispatch(
        fetchDashboardStats(companyId, numbersFilter, previous_cycle_offset)
      ),
    fetchCounts: (companyId, numbersFilter, fields) =>
      dispatch(fetchCounts(companyId, numbersFilter, fields)),
    fetchVoiceCounts: (companyId, numbersFilter, fields) =>
      dispatch(fetchVoiceCounts(companyId, numbersFilter, fields)),
  };
}

const MessagesCallsWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    onSizeChange,
    isActive,
    index,
    onClickTrash,

    // Redux props
    dashboardStatsData,
    dashboardStatsStatus,
    currentCompanyId,
    numbersFilter,
    unreadsTotal,
    countsStatus,
    voiceCountsStatus,
    billingCycle,

    // Redux func
    fetchDashboardStats,
    fetchCounts,
    fetchVoiceCounts,
  } = props;

  const [currentModule, setCurrentModule] = useState(CREDITS);
  const [currentBillingCycle, setCurrentBillingCycle] = useState(
    billingCycleOption[0].value
  );
  const [currentCycle, setCurrentCycle] = useState(cycleOption[0]);
  const [currentYear, setCurrentYear] = useState(yearOption[0].value);

  useEffect(() => {
    // Fetch voice counts on mount
    if (voiceCountsStatus == null) {
      fetchVoiceCounts(currentCompanyId, numbersFilter, [
        "received",
        "outgoing",
        "missedUnread",
        "undeliveredUnread",
        "voicemailUnread",
      ]);
    }
  }, [currentCompanyId, fetchVoiceCounts, numbersFilter, voiceCountsStatus]);

  useEffect(() => {
    // Fetch threads counts on mount
    if (countsStatus == null) {
      fetchCounts(currentCompanyId, numbersFilter, [
        "received",
        "sent",
        "unread",
        "undeliveredUnread",
      ]);
    }
  }, [countsStatus, currentCompanyId, fetchCounts, numbersFilter]);

  useEffect(() => {
    fetchDashboardStats(currentCompanyId, numbersFilter, currentBillingCycle);
  }, [
    currentBillingCycle,
    currentCompanyId,
    fetchDashboardStats,
    numbersFilter,
  ]);

  const onChangeCurrentModule = useCallback((value) => {
    setCurrentModule(value);
  }, []);

  const onChangeBillingCycle = useCallback((value) => {
    setCurrentBillingCycle(value);
  }, []);

  const onChangeCycle = useCallback((value) => {
    const cycleToSet = cycleOption.filter((item) => item.value === value);
    setCurrentCycle(cycleToSet[0]);
  }, []);

  const onChangeYear = useCallback((value) => {
    setCurrentYear(value);
  }, []);

  const billingCycleText = useMemo(() => {
    const text = billingCycleOption.find(
      (item) => item.value === currentBillingCycle
    )?.name;
    const period =
      (billingCycle?.start || "N/A") + "- " + (billingCycle?.end || "N/A");
    return { text, period };
  }, [billingCycle?.end, billingCycle?.start, currentBillingCycle]);

  const showingCurrentModuleText = useMemo(() => {
    let text = `Showing ${capitalizeFirstLetter(currentModule)}`;
    if (currentModule === "all") text += " (Scroll down to view all options)";
    return text;
  }, [currentModule]);

  const data = Object.entries(dashboardStatsData);

  const voiceOut = data.map(([_, item]) => item["voice-out"]);
  const voiceIn = data.map(([_, item]) => item["voice-in"]);
  const outText = data.map(([_, item]) => item["text-out"]);
  const inText = data.map(([_, item]) => item["text-in"]);
  const allInCredits = data.map(([_, item]) => item["all-in-credits"]);
  const allOutCredits = data.map(([_, item]) => item["all-out-credits"]);

  const labels = data.map(([_, item]) => item["date_formatted"]);

  const bilingCycleElement = useMemo(() => {
    return (
      <>
        <div className={"mb-3"}>
          Following:{" "}
          <a
            href="#modal-filter-numbers"
            className="color-primary font-weight-500"
          >
            {numbersFilter?.length || 0} numbers
          </a>
          <CircleQuestionMarkSvg width={14} height={14} className="ml-2" />
        </div>
        {(currentModule === MESSAGES || currentModule === ALL) && (
          <>
            {unreadsTotal > 0 && (
              <div className="mb-3">
                <span className="warning-color">
                  You have {unreadsTotal} Unread Messages
                </span>
                <Link
                  to="/hub/messages/filter/unread"
                  className="font-weight-500 ml-2"
                >
                  View All
                </Link>
              </div>
            )}
            <MessagesCallsWidgetStats
              labels={labels}
              incoming={inText}
              outgoing={outText}
              type="Texts"
            />
          </>
        )}
        {(currentModule === CALLS || currentModule === ALL) && (
          <MessagesCallsWidgetStats
            labels={labels}
            incoming={voiceIn}
            outgoing={voiceOut}
            type="Calls"
          />
        )}
        {(currentModule === CREDITS || currentModule === ALL) && (
          <MessagesCallsWidgetStats
            labels={labels}
            incoming={allInCredits}
            outgoing={allOutCredits}
            type="Credits"
          />
        )}
        {/* <ThreadItem item_key="contact-" /> */}
      </>
    );
  }, [
    allInCredits,
    allOutCredits,
    currentModule,
    inText,
    labels,
    numbersFilter,
    outText,
    unreadsTotal,
    voiceIn,
    voiceOut,
  ]);

  const monthlyCycleElement = useMemo(() => {
    return (
      <div className="flexer-row gap-4 flex-wrap">
        <div className="messages-calls-widget-container__grid">
          {MONTHS.map((item) => (
            <div className="flexer-row gap-2">
              <span>{item.name}</span>
              {thisYear > currentYear || thisMonth >= item.value ? (
                <a
                  href={getPrimaryUrl(
                    `reports/simpleMonthlySummary/${currentYear}-${
                      item.value < 10 ? "0" + item.value : item.value
                    }`
                  )}
                  className="font-weight-500"
                >
                  Download
                </a>
              ) : (
                <span>N/A</span>
              )}
            </div>
          ))}
        </div>
        <div className="flexer-col gap-2">
          <p className="mb-0 messages-calls-widget-container__text">
            The .csv file will automatically download.
          </p>
          <img
            src={ExampleReport}
            alt="Sample.csv"
            className="messages-calls-widget-container__image"
            height={120}
            width={280}
          />
          <p className="mb-0">Sample.csv shown</p>
        </div>
      </div>
    );
  }, [currentYear]);

  return (
    <DashboardCard
      headerContent={"Messages and Calls Summary"}
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      onSizeChange={onSizeChange}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
    >
      {dashboardStatsStatus === "loading" ||
      countsStatus === "loading" ||
      voiceCountsStatus === "loading" ? (
        <div className="d-flex justify-content-center w-100">
          <Spinner />
        </div>
      ) : (
        <div className="messages-calls-widget-container">
          <div className="mb-3 d-flex align-items-center justify-content-start flex-wrap">
            <PopoverSelect
              id={"message-calls-widget-cycle"}
              selectBoxClassName={
                "dashboard-card-detail-box-container cursor-pointer mr-3 mb-2"
              }
              text={currentCycle.name}
              options={cycleOption}
              value={currentCycle.value}
              onSelect={onChangeCycle}
            />
            {currentCycle.value === 0 ? (
              <>
                <PopoverSelect
                  id={"message-calls-widget-billing-cycle"}
                  selectBoxClassName={
                    "dashboard-card-detail-box-container cursor-pointer mr-3 mb-2"
                  }
                  text={
                    <>
                      <b>{billingCycleText.text}</b> {billingCycleText.period}
                    </>
                  }
                  options={billingCycleOption}
                  value={currentBillingCycle}
                  onSelect={onChangeBillingCycle}
                />
                <PopoverSelect
                  id={"message-calls-widget-current-module"}
                  selectBoxClassName={
                    "dashboard-card-detail-box-container cursor-pointer mb-2"
                  }
                  text={showingCurrentModuleText}
                  options={messageCallOption}
                  value={currentModule}
                  onSelect={onChangeCurrentModule}
                />
              </>
            ) : (
              <>
                <PopoverSelect
                  id={"message-calls-widget-year"}
                  selectBoxClassName={
                    "dashboard-card-detail-box-container cursor-pointer mr-3 mb-2"
                  }
                  text={currentYear}
                  options={yearOption}
                  value={currentYear}
                  onSelect={onChangeYear}
                />
              </>
            )}
          </div>
          {currentCycle.value === 0 ? bilingCycleElement : monthlyCycleElement}
        </div>
      )}
    </DashboardCard>
  );
};

MessagesCallsWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};

MessagesCallsWidget.defaultProps = {
  isActive: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesCallsWidget);
