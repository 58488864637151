import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import TooltipWhenOverflow from "../hub/HelperComponents/TooltipWhenOverflow";
import ThreadsActionBroadcast from "./ThreadsActionBrodcast";
import useAnalyticsStore from "../../utils/hooks/ReduxHooks/analyticsStore";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import RecurringIconWithTooltip from "./RecurringIconWithTooltip";
import BroadcastStatusTag from "./BroadcastStatusTag";
import TimeAgo from "../TimeAgo";
import { formatDateAndTimeNew } from "../../utils/dateAndTimeHelpers";
import { ANALYTICS_LOGS_LEGACY_ROUTE } from "../Menus/routes";

const BroadcastTableRow = (props) => {
  const {
    columnSelectionState,
    member = {},
    menuRightPosition,
    setDataForScheduledMessageSummaryModal,
    handleEditScheduleMessage,
    handleDeleteScheduleMessage,
    handleSendNowSceduleMessage,
    handleStopNowScheduleMessage,
  } = props;
  const history = useHistory();
  const [showActions, setShowActions] = useState(false);

  // Memo
  const isStopped = useMemo(() => {
    let stopped = null;
    if (member?.schedule_message && member?.schedule_message?.is_stop_now) {
      stopped = true;
    }
    return stopped;
  }, [member]);

  const isSending = useMemo(() => {
    let sending = null;
    if (
      member?.schedule_message &&
      member?.["sent-status"] === "scheduled" &&
      !isStopped
    ) {
      if (
        member?.schedule_message.is_send_now ||
        member?.schedule_message.is_processing
      ) {
        sending = true;
      }
    }
    return sending;
  }, [isStopped, member]);

  const isShowDeleteScheduleMessage = useMemo(
    () => !member?.schedule_message?.sent && !isSending && !isStopped,
    [isSending, member, isStopped]
  );
  const isShowEditScheduleMessage = useMemo(
    () =>
      !member?.schedule_message?.is_send_now &&
      !member?.schedule_message?.is_processing &&
      !member?.schedule_message?.is_stop_now,
    [member]
  );
  const isShowSendNowScheduleMessage = useMemo(
    () => !isStopped && !isSending && !member?.schedule_message?.sent,
    [isSending, isStopped, member]
  );

  // Redux store
  const { setLogsEmbedURL } = useAnalyticsStore();
  const {
    users: {
      loggedUser: { timezone_offset },
    },
  } = useUsersStore();

  const changeThreadActions = useCallback(
    (bool) => () => {
      setShowActions(bool);
    },
    []
  );

  const onClickedViewReport = useCallback(() => {
    if (member?.type === "text" && member?.group_sms_blast_id) {
      const sentStatisticsLogUrl = `logs/sentstatistics/${member?.group_sms_blast_id}`;
      setLogsEmbedURL(sentStatisticsLogUrl);
    }
    if (member?.type === "voice" && member?.group_sms_blast_id) {
      const broadcastSentStatisticsUrl = `logs/broadcastsentstatistics/${member?.group_sms_blast_id}`;
      setLogsEmbedURL(broadcastSentStatisticsUrl);
    }
    history.push(
      `/hub/menus/filter/analytics/logs/submenu/${ANALYTICS_LOGS_LEGACY_ROUTE}`,
      {
        isComingFromBroadcast: true,
      }
    );
  }, [member, setLogsEmbedURL, history]);

  const getBroadcastStatusTagData = useMemo(() => {
    const data = {
      label: "",
      className: "",
    };
    if (isSending) {
      data.label = "Sending...";
      data.className = "status-bg-light-green";
    } else if (isStopped) {
      data.label = "Stopped";
      data.className = "status-bg-red pl-3";
    } else if (member?.["sent-status"] === "scheduled") {
      data.label = member?.["sent-status"];
      data.className = "status-bg-light-blue";
    } else {
      data.label = member?.["sent-status"];
      data.className = "pl-2";
    }
    return data;
  }, [isStopped, member, isSending]);

  const handleViewScheduleMessage = useCallback(() => {
    const memberData = {
      ...member,
      isShowEditScheduleMessage,
      isShowDeleteScheduleMessage,
      isShowSendNowScheduleMessage,
      isStopped,
      status: getBroadcastStatusTagData.label,
    };
    setDataForScheduledMessageSummaryModal(memberData);
  }, [
    isShowDeleteScheduleMessage,
    isShowEditScheduleMessage,
    isShowSendNowScheduleMessage,
    member,
    setDataForScheduledMessageSummaryModal,
    isStopped,
    getBroadcastStatusTagData,
  ]);

  if (!member || typeof member?.id === "undefined") {
    return null;
  }

  return (
    <tr
      key={member?.id}
      className={`group-table-row broadcast-table`}
      onMouseEnter={changeThreadActions(true)}
      onMouseLeave={changeThreadActions(false)}
    >
      {columnSelectionState.showBroadcastName && (
        <td className="pl-5 pr-0 w-25">
          <div className="d-flex align-items-center">
            <div className="flexer-col-start">
              <TooltipWhenOverflow maxWidth={350} text={member?.broadcast_name}>
                {member?.broadcast_name}
              </TooltipWhenOverflow>
              <span className="type-pills">{member.type}</span>
            </div>
            {showActions && (
              <div
                className="group-table-menu-row row-menus"
                style={{ right: `calc(${menuRightPosition}px + 35px)` }}
                onClick={(e) => e.stopPropagation()}
              >
                {member?.["sent-status"] === "scheduled" && isSending ? (
                  <span
                    onClick={handleStopNowScheduleMessage(member)}
                    className="text-danger cursor-pointer"
                  >
                    Stop Now
                  </span>
                ) : (
                  <ThreadsActionBroadcast
                    handleViewReport={onClickedViewReport}
                    isScheduledBroadast={
                      member?.["sent-status"] === "scheduled" || isStopped
                    }
                    handleViewScheduleMessage={handleViewScheduleMessage}
                    handleEditScheduleMessage={handleEditScheduleMessage(
                      member
                    )}
                    handleDeleteScheduleMessage={handleDeleteScheduleMessage(
                      member
                    )}
                    isShowDeleteScheduleMessage={isShowDeleteScheduleMessage}
                    isShowEditScheduleMessage={isShowEditScheduleMessage}
                    isShowSendNowScheduleMessage={isShowSendNowScheduleMessage}
                    isShowReportActionWithScheduleMessage={
                      isStopped &&
                      !isShowDeleteScheduleMessage &&
                      !isShowEditScheduleMessage &&
                      !isShowSendNowScheduleMessage
                    }
                    handleSendNowSceduleMessage={handleSendNowSceduleMessage(
                      member
                    )}
                  />
                )}
              </div>
            )}
          </div>
        </td>
      )}
      {columnSelectionState.showGroups && (
        <td className="w-25 pr-0">
          <div className="flexer-col-start">
            <span
              className="group-name"
              onClick={() =>
                history.push(`/hub/groups/group/${member?.group?.id}/about`)
              }
            >
              <TooltipWhenOverflow
                maxWidth={"auto"}
                text={member?.group?.group_name ?? "-"}
              >
                {member?.group?.group_name ?? "-"}
              </TooltipWhenOverflow>
            </span>
            {member?.group?.group_type && (
              <span className="type-pills">
                {member?.group?.group_type === "adhoc"
                  ? "broadcast group"
                  : member?.group?.group_type}
              </span>
            )}
          </div>
        </td>
      )}

      {columnSelectionState.showCreatedAt && (
        <td className={"text-nowrap text-capitalize"}>
          <div className="flexer-col-start">
            <span className="ellipsis-text">
              {formatDateAndTimeNew(
                member?.schedule_message?.created || member?.send_on,
                timezone_offset,
                true
              )}{" "}
            </span>
          </div>
        </td>
      )}

      {columnSelectionState.showSendOn && (
        <td className={"text-nowrap text-capitalize"}>
          <div className="d-flex align-items-start flex-column">
            <BroadcastStatusTag
              label={getBroadcastStatusTagData.label}
              className={getBroadcastStatusTagData.className}
            />
            {member?.send_on && (
              <span className="send-on-date mt-1">
                {isStopped && member?.schedule_message?.is_stop_datetime ? (
                  <span className="pl-2">
                    <TimeAgo
                      time={member?.schedule_message?.is_stop_datetime}
                      userTimeZoneOffset={timezone_offset}
                    />
                  </span>
                ) : (
                  <>
                    {formatDateAndTimeNew(
                      member?.send_on,
                      timezone_offset,
                      true
                    )}{" "}
                    <RecurringIconWithTooltip
                      scheduleMessage={member?.schedule_message}
                    />
                  </>
                )}
              </span>
            )}
          </div>
        </td>
      )}

      {columnSelectionState.showRecipients && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.recipients_total || ""}>
            {member?.recipients_total}
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showSuccessfull && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.recipients_success || ""}>
            <span className="text-successfull">
              {member?.recipients_success}
            </span>
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showFailed && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.recipients_failed || ""}>
            <span className="text-failed">{member?.recipients_failed}</span>
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showCredits && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.credits || ""}>
            {member?.credits ?? "-"}
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showOptedOut && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.opted_out || ""}>
            {member?.opted_out}
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showDoNotCall && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.do_not_call || ""}>
            {member?.do_not_call}
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showUnformatted && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.unformatted || ""}>
            {member?.unformatted ?? "-"}
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showDuplicates && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.duplicates || ""}>
            {member?.duplicates ?? "-"}
          </TooltipWhenOverflow>
        </td>
      )}

      {columnSelectionState.showLandlines && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.landlines || ""}>
            {member?.landlines ?? "-"}
          </TooltipWhenOverflow>
        </td>
      )}
      {columnSelectionState.showFrom && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.from_number || ""}>
            {member?.from_number ?? "-"}
          </TooltipWhenOverflow>
        </td>
      )}
      {columnSelectionState.showLinkClicks && (
        <td className="text-nowrap">
          <TooltipWhenOverflow text={member?.clicks || ""}>
            {member?.clicks ?? "-"}
          </TooltipWhenOverflow>
        </td>
      )}
    </tr>
  );
};

BroadcastTableRow.propTypes = {
  member: PropTypes.objectOf(PropTypes.any).isRequired,
  columnSelectionState: PropTypes.objectOf(PropTypes.any).isRequired,
  menuRightPosition: PropTypes.number.isRequired,
  setDataForScheduledMessageSummaryModal: PropTypes.func.isRequired,
  handleEditScheduleMessage: PropTypes.func.isRequired,
  handleStopNowScheduleMessage: PropTypes.func.isRequired,
};

export default BroadcastTableRow;
