import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Radio,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { plBasicTheme } from "../../../assets/base/theme";
import { formatPhoneNumber } from "../../../helpers";
import IntegrationIcon from "../../IntegrationIcon";
//new icons
import { ReactComponent as KeyboardArrowLeft } from "../../../assets/img/icons-new/general/back/back-black.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/icons-new/general/close/close-gray.svg";
import { ReactComponent as KeyboardArrowRight } from "../../../assets/img/icons-new/general/arrow/right-arrow-black.svg";

function mapStateToProps(store) {
  return {
    skipSendingTime: store.groups.skipSendingTime,
    groupData: store.groups.acionGroupData,
  };
}

class PrimaryContactsSelection extends React.Component {
  constructor(props) {
    super(props);

    this.updateView = this.updateView.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.populateData = this.populateData.bind(this);
    this.state = {
      isShown: false,
      isHide: false,
      redirect: null,
      page: 0,
      membersData: {},
      selectedPrimary: null,
    };
  }

  componentDidMount() {
    this.updateView();
    this.populateData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
      this.populateData();
    }
  }

  populateData() {
    const membersData = this.props.primaryAssigneesData;

    this.setState({ membersData });
  }

  updateView() {
    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  closeModal = (val) => () => {
    if (val) {
      const { membersData } = this.state;
      this.props.onClose(val, membersData);
      return;
    }
    this.props.onClose(val);
  };

  TablePaginationActions = (props) => {
    const { count, page, onChangePage } = props;

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    return (
      <div className="pagination-bottom">
        <button
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          className="pagination-btns"
        >
          <KeyboardArrowLeft />
        </button>
        <div className="pagination-bottom-count">
          {page + 1}/{count}
        </div>
        <button
          onClick={handleNextButtonClick}
          disabled={page >= count - 1}
          aria-label="next page"
          className="pagination-btns"
        >
          <KeyboardArrowRight />
        </button>
      </div>
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: parseInt(newPage) });
  };

  handleRowClick = (row, page) => () => {
    const { membersData } = { ...this.state };
    for (const member of membersData[page]) {
      if (member.markedForPrimary && member.id !== row.id) {
        member.markedForPrimary = false;
      } else if (!member.markedForPrimary && member.id === row.id) {
        member.markedForPrimary = true;
      }
    }
    this.setState({ membersData });
  };

  render() {
    const pagesIndexes = Object.keys(this.state.membersData);
    const { page, membersData } = this.state;
    const selectedContacts =
      membersData && membersData[pagesIndexes[page]]
        ? membersData[pagesIndexes[page]].filter(
            (contact) => contact.selected === true
          )
        : [];
    const tableColumns = [
      { id: "first_name", label: "First Name", minWidth: 100 },
      { id: "last_name", label: "Last Name", minWidth: 100 },
      { id: "email", label: "Email", minWidth: 100 },
    ];
    const getAppName = (record) => {
      const { is_ccb, is_pco, is_mc, is_elvanto } = record;
      if (!!is_ccb) {
        return "CCB app";
      } else if (!!is_pco) {
        return "PCO app";
      } else if (!!is_mc) {
        return "MC app";
      } else if (!!is_elvanto) {
        return "ELVANTO app";
      }
      return "";
    };

    const reference = membersData[pagesIndexes[page]]
      ? membersData[pagesIndexes[page]][0]
      : {};

    return (
      <div
        className={
          "modal fade inbox-modal confirm-group-message" +
          (this.state.isShown && !this.state.isHide ? " show" : "")
        }
        style={
          this.props.show || this.state.isShown ? { display: "block" } : {}
        }
        id="SkipContactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SkipContactModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-backdrop fade show"
          onClick={(e) => {
            this.closeModal(false);
          }}
        />
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ zIndex: 100 }}
        >
          <div
            className="modal-content themed-modal"
            style={{ minWidth: "740px", paddingLeft: 25 }}
          >
            <button
              type="button"
              className="close-archived"
              aria-label="Close"
              onClick={this.closeModal(false)}
            >
              <CloseIcon />
            </button>
            <div className="modal-body">
              <div className="skip-sending-multiple-content">
                Select new PastorsLine Primary Person for{" "}
                {getAppName(reference)}
              </div>
              <div className="modal-purpose-description">
                <div>
                  There is now a Primary Person for the number{" "}
                  {formatPhoneNumber(
                    "" +
                      (reference.country ? reference.country.phonecode : "") +
                      reference.phone_number
                  )}
                  <IntegrationIcon {...reference} baseSize={16} />
                </div>
                <div>Please select a new Primary Person.</div>
              </div>
              <MuiThemeProvider theme={plBasicTheme}>
                <TableContainer component="div">
                  <Table
                    aria-label="duplicate contacts table"
                    height="40vh"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" />

                        {tableColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            colSpan={1}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {membersData &&
                        membersData[pagesIndexes[page]] &&
                        membersData[pagesIndexes[page]].map((row) => (
                          <TableRow
                            key={row.id}
                            role="checkbox"
                            aria-checked={
                              selectedContacts &&
                              selectedContacts.length > 0 &&
                              selectedContacts.indexOf(row) !== -1
                            }
                            selected={
                              selectedContacts &&
                              selectedContacts.length > 0 &&
                              selectedContacts.indexOf(row) !== -1
                            }
                            onClick={this.handleRowClick(
                              row,
                              pagesIndexes[page]
                            )}
                          >
                            <TableCell padding="checkbox">
                              <Radio
                                checked={
                                  row.markedForPrimary
                                    ? row.markedForPrimary
                                    : false
                                }
                              />
                            </TableCell>
                            {tableColumns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  className="themed-table-cell"
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                    {pagesIndexes.length > 1 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            style={{
                              position: "absolute",
                              bottom: 60,
                              width: "100%",
                            }}
                            colSpan={5}
                            page={page}
                            onChangePage={this.handleChangePage}
                            ActionsComponent={this.TablePaginationActions}
                            labelRowsPerPage=""
                            labelDisplayedRows={() => ""}
                            count={pagesIndexes.length}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </TableContainer>

                <div className="modal-footer mt-gap">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.closeModal(true)}
                    style={{
                      padding: "0.375rem 0.75rem",
                      color: "white",
                      fontSize: "1rem",
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(PrimaryContactsSelection));
