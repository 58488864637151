import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as threadActions from "../../../actions/threads";

export const useThreadsStore = () => {
  const dispatch = useDispatch();
  const _threads = useSelector((store) => store.threads);

  const setThreadItemsTypeFilter = useCallback(
    (filter) => dispatch(threadActions.setThreadItemsTypeFilter(filter)),
    [dispatch]
  );
  const setScrollDownChatMessagesRedux = useCallback(
    (scrollDown) =>
      dispatch(threadActions.setScrollDownChatMessagesRedux(scrollDown)),
    [dispatch]
  );

  const fetchUnread = useCallback(
    (companyId, numbersFilter, limit, page, type) =>
      dispatch(
        threadActions.fetchUnread(companyId, numbersFilter, limit, page, type)
      ),
    [dispatch]
  );

  const showUnread = useCallback(
    () => dispatch(threadActions.showUnread()),
    [dispatch]
  );

  const markAsReadThreads = useCallback(
    (company_id, contact_id, group_id, allParams, interlocutorId) =>
      dispatch(
        threadActions.markAsReadThreads(
          company_id,
          contact_id,
          group_id,
          allParams,
          interlocutorId
        )
      ),
    [dispatch]
  );
  const setMessagesTab = useCallback(
    (payload) => dispatch(threadActions.setMessagesTab(payload)),
    [dispatch]
  );

  const dispatchAddSnackData = useCallback(
    (data, entity) => dispatch(threadActions.addSnackData(data, entity)),
    [dispatch]
  );

  const addVCards = useCallback(
    (data) => dispatch(threadActions.addVCards(data)),
    [dispatch]
  );

  const clearAddVcardStatus = useCallback(
    () => dispatch(threadActions.clearAddVcardStatus()),
    [dispatch]
  );

  const setFilter = useCallback(
    async (filter) => await dispatch(threadActions.setFilter(filter)),
    [dispatch]
  );

  const setScheduledVisible = useCallback(
    async (visible) =>
      await dispatch(threadActions.setScheduledVisible(visible)),
    [dispatch]
  );

  const markInitialLoadSuccess = useCallback(
    async () => await dispatch(threadActions.markInitialLoadSuccess()),
    [dispatch]
  );

  const clearSearchReceivers = useCallback(
    () => dispatch(threadActions.clearSearchReceivers()),
    [dispatch]
  );

  const searchReceivers = useCallback(
    (
      companyId,
      query,
      phoneCountryId,
      onlyContacts,
      noLogs,
      noGroupDuplicates,
      currentGroupId,
      withArchived,
      withBlocked
    ) =>
      dispatch(
        threadActions.searchReceivers(
          companyId,
          query,
          phoneCountryId,
          onlyContacts,
          noLogs,
          noGroupDuplicates,
          currentGroupId,
          withArchived,
          withBlocked
        )
      ),
    [dispatch]
  );

  const toggleSideForm = useCallback(
    (selection, memberId) =>
      dispatch(threadActions.toggleSideForm(selection, memberId)),
    [dispatch]
  );

  const setOneToOneCallingId = useCallback(
    (callingId) => dispatch(threadActions.setOneToOneCallingId(callingId)),
    [dispatch]
  );

  const setAutoCallingFromNumber = useCallback(
    (callingNumber) =>
      dispatch(threadActions.setAutoCallingFromNumber(callingNumber)),
    [dispatch]
  );

  return {
    threads: _threads,
    setThreadItemsTypeFilter,
    setScrollDownChatMessagesRedux,
    fetchUnread,
    showUnread,
    markAsReadThreads,
    setMessagesTab,
    dispatchAddSnackData,
    addVCards,
    clearAddVcardStatus,
    setFilter,
    setScheduledVisible,
    markInitialLoadSuccess,
    clearSearchReceivers,
    searchReceivers,
    toggleSideForm,
    setOneToOneCallingId,
    setAutoCallingFromNumber,
  };
};
