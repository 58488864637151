import React, { useMemo } from "react";
import AlertOverlay from "./AlertOverlay";
import useCompaniesStore from "../../utils/hooks/ReduxHooks/companiesStore";
import { getAccountIssueOverlayInfo } from "../../utils/accountHelpers";

const AccountIssueOverlay = () => {
  const {
    companies: { currentCompany },
  } = useCompaniesStore();
  const currentIssue = useMemo(() => {
    return getAccountIssueOverlayInfo(
      currentCompany.is_billing_issue,
      currentCompany.is_suspended,
      currentCompany.number_is_deleted,
      currentCompany.package
    );
  }, [
    currentCompany.is_billing_issue,
    currentCompany.is_suspended,
    currentCompany.number_is_deleted,
    currentCompany.package,
  ]);
  if (!currentIssue.message) return null;
  return (
    <AlertOverlay
      message={currentIssue.message}
      url={currentIssue.url}
      urlLabel={currentIssue.urlLabel}
      withOrganiztionSwitcher={currentIssue?.withOrganiztionSwitcher}
    />
  );
};

export default AccountIssueOverlay;
