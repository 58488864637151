import React from "react";
import PropTypes from "prop-types";

import { LightTooltip } from "../helpers";

/** 
 Example:

  <SpanWithTooltip 
    title="close modal"         // tooltip text: any string
    placement="top"             // tooltip placement: top (default), bottom, left, right, also can be used with start-* and end-*
    arrow={boolean}              // default is true
    enterDelay={number}         // default: 500, tooltip transition delay on mouseover
    leaveDelay={number}         // default: 100, tooltip transition delay on mouseout
    onClick={anyButtonHandler}  // onClick handler
    className="someButtonClass" // class name

    or any other valid anchor element <span> related property
  > 
    <any child component>
  </SpanWithTooltip>
 **/

const SpanWithTooltip = (props) => {
  const {
    title,
    children,
    disabled,
    className,
    placement,
    arrow,
    style,
    enterDelay,
    leaveDelay,
    ...rest
  } = props;

  return (
    <LightTooltip
      title={title}
      arrow={arrow}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      interactive={rest.interactive}
    >
      <span
        disabled={disabled}
        style={disabled ? { pointerEvents: "none", ...style } : { ...style }}
        className={className}
        {...rest}
      >
        {children}
      </span>
    </LightTooltip>
  );
};

SpanWithTooltip.propTypes = {
  children: PropTypes.node,

  // props for Light tooltip component
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  arrow: PropTypes.bool,
  placement: PropTypes.string,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,

  // props for <span> element
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  rest: PropTypes.objectOf(PropTypes.any),
};

SpanWithTooltip.defaultProps = {
  children: <></>,
  arrow: true,
  enterDelay: 500,
  leaveDelay: 100,
  placement: "bottom",
  className: "",
  style: {},
};

export default SpanWithTooltip;
