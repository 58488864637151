import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { isReactNativeApp } from "../../../helpers";
import { OPEN_INTERCOM } from "../../../utils/constants";

const LaunchIntercom = (props) => {
  const { children, className } = props;

  const handleIntercomLaunch = useCallback(() => {
    if (isReactNativeApp()) {
      window.ReactNativeWebView?.postMessage(OPEN_INTERCOM);
    }
  }, []);

  return (
    <a
      href="#void"
      onClick={handleIntercomLaunch}
      className={`${className} btn-intercom-launcher`}
    >
      {children}
    </a>
  );
};

LaunchIntercom.propTypes = {
  className: PropTypes.string,
};

LaunchIntercom.defaultProps = {
  className: "",
};

export default LaunchIntercom;
