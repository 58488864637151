import { cookies } from "../store";
import { updateCompaniesCurrentAndSelectedCompanyState } from "../utils/settingsHelpers";

const initialState = {
  currentCompany: null,
  currentCompanyStatus: null,
  isCurrentAutoSwitched: null,
  companies: [],
  companiesStatus: null,
  companiesError: null,
  accountIssueBarCloseState: null,
  users: [],
  usersStatus: null,
  skipSetupProfile: null,
  selectedCompany: {},

  // Transfer Ownership
  transferOwnerShipStatus: null,
  transferOwnerShipError: null,
  cancelTransferOwnershipStatus: null,
  resendTransferOwnershipStatus: null,

  // Add Company
  addCompanyStatus: null,

  // Check email state
  checkEmailStatus: null,
  firstName: "",
  lastName: "",
  isAccountExists: null,
  isInCompany: null,

  newlyAddedCompany: null,

  // Leave Company
  leaveCompanyStatus: null,

  updateCompanyStatus: null,
  skipSetupProfileStatus: null,

  // User companies
  companyUsersStatus: null,
  companyUsers: [],

  // Delete company
  deleteCompanyStatus: null,
};

const getCurrentCompany = (companies, currentCompany) => {
  if (Array.isArray(companies) && Boolean(companies?.length)) {
    for (let i = 0; i < companies.length; i++) {
      if (companies[i].id === currentCompany?.id) {
        return companies[i];
      }
    }
  }
  return null;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGOUT":
      return initialState;

    case "FETCH_COMPANIES":
      return {
        ...state,
        companiesStatus: "loading",
        companiesError: null,
      };
    case "FETCH_COMPANIES_SUCCESS":
      return {
        ...state,
        companiesStatus: "success",
        companiesError: null,
        companies: action.payload.companies,
        currentCompany: action?.isResetCurrentCompany
          ? getCurrentCompany(action.payload.companies, state.currentCompany)
          : state.currentCompany,
        skipSetupProfile: action?.isResetCurrentCompany
          ? !!getCurrentCompany(action.payload.companies, state.currentCompany)
              ?.skip_setup_profile
          : !!state.currentCompany?.skip_setup_profile,
      };
    case "FETCH_COMPANIES_ERROR":
      return {
        ...state,
        companiesStatus: "error",
        companiesError: action.payload,
        companies: [],
      };

    case "SET_CURRENT_COMPANY":
      if (state.companies) {
        for (let i = 0; i < state.companies.length; i++) {
          if (state.companies[i].id === action.payload.companyId) {
            return {
              ...state,
              currentCompany: state.companies[i],
              currentCompanyStatus: "success",
              isCurrentAutoSwitched: action.payload.isAutoSwitched,
              accountIssueBarCloseState: cookies.get(
                "account_issue_closed__" + state.companies[i].id
              ),
              users: null,
              usersStatus: null,
            };
          }
        }
      }
      return {
        ...state,
        currentCompany: null,
        currentCompanyStatus: "error",
        isCurrentAutoSwitched: null,
        accountIssueBarCloseState: null,
        users: null,
        usersStatus: null,
      };

    case "UPDATE_CURRENT_COMPANY_AFTER_FETCH": {
      const currentCompany = state.companies?.find(
        (company) => company.id === action.currentCompanyId
      );

      return {
        ...state,
        currentCompany: currentCompany ? currentCompany : state.currentCompany,
      };
    }

    case "COMPANIES/CLEAR_AUTO_SWITCHED_FLAG":
      return {
        ...state,
        isCurrentAutoSwitched: false,
      };

    case "COMPANIES/CLOSE_ACCOUNT_ISSUE_BAR":
      return {
        ...state,
        accountIssueBarCloseState: state.currentCompany
          ? cookies.get("account_issue_closed__" + state.currentCompany.id)
          : null,
      };

    case "COMPANIES/FETCH_USERS":
      return {
        ...state,
        usersStatus: "loading",
      };
    case "COMPANIES/FETCH_USERS.SUCCESS":
      return {
        ...state,
        users: action.payload.userCompanies,
        usersStatus: "success",
      };
    case "COMPANIES/FETCH_USERS.ERROR":
      return {
        ...state,
        usersConfirmed: [],
        usersInvited: [],
        usersStatus: "error",
      };

    case "COMPANIES/SET_TYPE_OF_FETCHED_USERS": {
      return {
        ...state,
        typeOfFetchedUsers: action.payload,
      };
    }
    case "COMPANIES/FETCH_USER_INFO": {
      return {
        ...state,
        userInfoStatus: "loading",
      };
    }
    case "COMPANIES/FETCH_USER_INFO.SUCCESS": {
      return {
        ...state,
        selectedUser: action.payload.data,
        userInfoStatus: "success",
      };
    }
    case "COMPANIES/FETCH_USER_INFO.ERROR": {
      return {
        ...state,
        selectedUser: null,
        userInfoStatus: "error",
      };
    }
    case "COMPANIES/USER_INFO.CLEAR": {
      return {
        ...state,
        selectedUser: null,
        userInfoStatus: "",
      };
    }
    case "COMPANIES/UPDATE_USER_INFO": {
      return {
        ...state,
        updateUserInfoStatus: "loading",
      };
    }
    case "COMPANIES/UPDATE_USER_INFO.SUCCESS": {
      const updatedUser = { ...state.selectedUser, ...action.payload.data };
      const updatedUsers = state.users.map((user) =>
        user.id === updatedUser.id ? { ...user, ...action.payload.data } : user
      );
      return {
        ...state,
        selectedUser: updatedUser,
        users: updatedUsers,
        updateUserInfoStatus: "success",
      };
    }
    case "COMPANIES/UPDATE_USER_INFO.ERROR": {
      return {
        ...state,
        updateUserInfoStatus: "error",
      };
    }
    case "COMPANIES/CLEAR_UPDATE_USER_INFO_STATUS": {
      return { ...state, updateUserInfoStatus: "" };
    }
    case "COMPANIES/INVITE_NEW_USER": {
      return {
        ...state,
        inviteNewUserStatus: "loading",
      };
    }
    case "COMPANIES/INVITE_NEW_USER.SUCCESS": {
      return {
        ...state,
        inviteNewUserStatus: "success",
      };
    }
    case "COMPANIES/INVITE_NEW_USER.ERROR": {
      return {
        ...state,
        inviteNewUserStatus: "error",
      };
    }
    case "COMPANIES/CLEAR_INVITE_NEW_USER_STATUS": {
      return {
        ...state,
        inviteNewUserStatus: "",
      };
    }
    case "COMPANIES/DELETE_USER": {
      return {
        ...state,
        deleteUserStatus: "loading",
      };
    }
    case "COMPANIES/DELETE_USER.SUCCESS": {
      return {
        ...state,
        deleteUserStatus: "success",
      };
    }
    case "COMPANIES/DELETE_USER.ERROR": {
      return {
        ...state,
        deleteUserStatus: "error",
      };
    }
    case "COMPANIES/CLEAR_DELETE_USER_STATUS": {
      return {
        ...state,
        deleteUserStatus: "",
      };
    }
    case "COMPANIES/FETCH_DELETED_USERS": {
      return {
        ...state,
        fetchDeletedUsersStatus: "loading",
      };
    }
    case "COMPANIES/FETCH_DELETED_USERS.SUCCESS": {
      return {
        ...state,
        deletedUsers: action.payload.data,
        fetchDeletedUsersStatus: "success",
      };
    }
    case "COMPANIES/FETCH_DELETED_USERS.ERROR": {
      return {
        ...state,
        deletedUsers: [],
        fetchDeletedUsersStatus: "error",
      };
    }
    case "COMPANIES/RESEND_INVITATION": {
      return {
        ...state,
        resendInvitationStatus: "loading",
      };
    }
    case "COMPANIES/RESEND_INVITATION.SUCCESS": {
      return {
        ...state,
        resendInvitationStatus: "success",
      };
    }
    case "COMPANIES/RESEND_INVITATION.ERROR": {
      return {
        ...state,
        resendInvitationStatus: "error",
      };
    }
    case "COMPANIES/CLEAR_RESEND_INVITATION_STATUS": {
      return { ...state, resendInvitationStatus: "" };
    }
    case "COMPANIES/REMOVE_DELETED_USER": {
      const remainingUsers = state.users.filter(
        (user) => user.email !== action.payload.data
      );
      return { ...state, users: remainingUsers };
    }
    case "COMPANIES/GET_COMPANY_PACKAGE": {
      return {
        ...state,
        companyPackageStatus: "loading",
      };
    }
    case "COMPANIES/GET_COMPANY_PACKAGE.SUCCESS": {
      return {
        ...state,
        companyPackage: action.payload.data,
        companyPackageStatus: "success",
      };
    }
    case "COMPANIES/GET_COMPANY_PACKAGE.ERROR": {
      return {
        ...state,
        companyPackage: null,
        companyPackageStatus: "error",
      };
    }
    case "COMPANIES/TRANSFER_OWNERSHIP":
      return {
        ...state,
        transferOwnerShipStatus: "loading",
      };
    case "COMPANIES/TRANSFER_OWNERSHIP.SUCCESS":
      return {
        ...state,
        transferOwnerShipStatus: "success",
        ...updateCompaniesCurrentAndSelectedCompanyState(
          state,
          action.companyId,
          {
            transfer_ownership_user_email: action.transfer_email,
          }
        ),
      };
    case "COMPANIES/TRANSFER_OWNERSHIP.ERROR":
      return {
        ...state,
        transferOwnerShipStatus: "error",
        transferOwnerShipError: action.error.data.message,
      };
    case "COMPANIES/TRANSFER_OWNERSHIP.CLEAR":
      return {
        ...state,
        transferOwnerShipStatus: null,
        transferOwnerShipError: null,
      };
    case "COMPANIES/CANCEL_TRANSFER_OWNERSHIP":
      return {
        ...state,
        cancelTransferOwnershipStatus: "loading",
      };
    case "COMPANIES/CANCEL_TRANSFER_OWNERSHIP.SUCCESS":
      return {
        ...state,
        cancelTransferOwnershipStatus: "success",
        ...updateCompaniesCurrentAndSelectedCompanyState(
          state,
          action.companyId,
          {
            transfer_ownership_user_email: null,
          }
        ),
      };
    case "COMPANIES/CANCEL_TRANSFER_OWNERSHIP.ERROR":
      return {
        ...state,
        cancelTransferOwnershipStatus: "error",
      };
    case "COMPANIES/CANCEL_TRANSFER_OWNERSHIP.CLEAR":
      return {
        ...state,
        cancelTransferOwnershipStatus: null,
      };
    case "COMPANIES/RESEND_TRANSFER_OWNERSHIP":
      return {
        ...state,
        resendTransferOwnershipStatus: "loading",
      };
    case "COMPANIES/RESEND_TRANSFER_OWNERSHIP.SUCCESS":
      return {
        ...state,
        resendTransferOwnershipStatus: "success",
      };
    case "COMPANIES/RESEND_TRANSFER_OWNERSHIP.ERROR":
      return {
        ...state,
        resendTransferOwnershipStatus: "error",
      };
    case "COMPANIES/RESEND_TRANSFER_OWNERSHIP.CLEAR":
      return {
        ...state,
        resendTransferOwnershipStatus: null,
      };
    case "COMPANIES/SET_SELECTED_COMPANY":
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case "COMPANIES/SET_SELECTED_COMPANY.CLEAR":
      return {
        ...state,
        selectedCompany: {},
      };
    case "COMPANIES/ADD":
      return {
        ...state,
        addCompanyStatus: "loading",
      };
    case "COMPANIES/ADD.SUCCESS":
      return {
        ...state,
        addCompanyStatus: "success",
        companies: [...state.companies, action.payload.company],
        newlyAddedCompany: action.payload.company,
      };
    case "COMPANIES/ADD.ERROR":
      return {
        ...state,
        addCompanyStatus: "error",
        newlyAddedCompany: null,
      };
    case "COMPANIES/ADD.CLEAR":
      return {
        ...state,
        addCompanyStatus: null,
        newlyAddedCompany: null,
      };
    case "COMPANIES/CHECK_EMAIL": {
      return {
        ...state,
        checkEmailStatus: "loading",
      };
    }
    case "COMPANIES/CHECK_EMAIL.SUCCESS": {
      return {
        ...state,
        checkEmailStatus: "success",
        firstName: action.payload.data.first_name || "",
        lastName: action.payload.data.last_name || "",
        isInCompany: action.payload.data.is_in_company,
        isAccountExists: action.payload.data.is_account_exists,
      };
    }
    case "COMPANIES/CHECK_EMAIL.ERROR": {
      return {
        ...state,
        checkEmailStatus: "error",
      };
    }
    case "COMPANIES/CHECK_EMAIL.CLEAR": {
      return {
        ...state,
        checkEmailStatus: null,
        firstName: "",
        lastName: "",
        isAccountExists: null,
        isInCompany: null,
      };
    }
    case "COMPANIES/LEAVE_COMPANY": {
      return {
        ...state,
        leaveCompanyStatus: "loading",
      };
    }
    case "COMPANIES/LEAVE_COMPANY.SUCCESS": {
      return {
        ...state,
        leaveCompanyStatus: "success",
      };
    }
    case "COMPANIES/LEAVE_COMPANY.ERROR": {
      return {
        ...state,
        leaveCompanyStatus: "error",
      };
    }
    case "COMPANIES/LEAVE_COMPANY.CLEAR": {
      return {
        ...state,
        leaveCompanyStatus: null,
      };
    }
    case "COMPANIES/DELETE_COMPANY": {
      return {
        ...state,
        deleteCompanyStatus: "loading",
      };
    }
    case "COMPANIES/DELETE_COMPANY.SUCCESS": {
      return {
        ...state,
        deleteCompanyStatus: "success",
      };
    }
    case "COMPANIES/DELETE_COMPANY.ERROR": {
      return {
        ...state,
        deleteCompanyStatus: "error",
      };
    }
    case "COMPANIES/DELETE_COMPANY.CLEAR": {
      return {
        ...state,
        deleteCompanyStatus: null,
      };
    }
    case "COMPANIES/FETCH_EXITED_COMPANIES": {
      return {
        ...state,
        fetchExitedCompaniesStatus: "loading",
      };
    }
    case "COMPANIES/FETCH_EXITED_COMPANIES.SUCCESS": {
      return {
        ...state,
        exitedCompanies: action.payload.data,
        fetchExitedCompaniesStatus: "success",
      };
    }
    case "COMPANIES/FETCH_EXITED_COMPANIES.ERROR": {
      return {
        ...state,
        fetchExitedCompaniesStatus: "error",
      };
    }

    case "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS.SUCCESS": {
      let currentCompany = { ...(state.currentCompany || {}) };
      if (action.field === "remove_logo") {
        currentCompany = { ...currentCompany, logo: null };
      }
      Object.keys(currentCompany).includes(action.field) &&
        (currentCompany = {
          ...currentCompany,
          [action.field]: action.updateValue,
        });

      return {
        ...state,
        currentCompany,
      };
    }
    case "COMPANIES/UPDATE_COMPANY": {
      return {
        ...state,
        updateCompanyStatus: "loading",
      };
    }
    case "COMPANIES/UPDATE_COMPANY.SUCCESS": {
      let currentCompany = { ...(state.currentCompany || {}) };
      currentCompany.has_sent_test_sms = action.has_sent_test_sms;
      return {
        ...state,
        updateCompanyStatus: "success",
        currentCompany,
      };
    }
    case "COMPANIES/UPDATE_COMPANY.ERROR": {
      return {
        ...state,
        updateCompanyStatus: "error",
      };
    }
    case "COMPANIES/UPDATE_COMPANY.CLEAR": {
      return {
        ...state,
        updateCompanyStatus: null,
      };
    }
    case "COMPANIES/SKIP_SETUP_PROFILE": {
      return {
        ...state,
        skipSetupProfileStatus: "loading",
      };
    }
    case "COMPANIES/SKIP_SETUP_PROFILE.SUCCESS": {
      return {
        ...state,
        skipSetupProfileStatus: "success",
        skipSetupProfile: true,
      };
    }
    case "COMPANIES/SKIP_SETUP_PROFILE.ERROR": {
      return {
        ...state,
        skipSetupProfileStatus: "error",
      };
    }
    case "COMPANIES/SKIP_SETUP_PROFILE.CLEAR": {
      return {
        ...state,
        skipSetupProfileStatus: null,
      };
    }
    case "COMPANIES/FETCH_COMPANY_USERS": {
      return {
        ...state,
        companyUsersStatus: "loading",
      };
    }
    case "COMPANIES/FETCH_COMPANY_USERS.SUCCESS": {
      return {
        ...state,
        companyUsersStatus: "success",
        companyUsers: action.payload?.userCompanies,
      };
    }
    case "COMPANIES/FETCH_COMPANY_USERS.ERROR": {
      return {
        ...state,
        companyUsersStatus: "error",
      };
    }

    case "COMPANIES/FETCH_COMPANY_USERS.CLEAR": {
      return {
        ...state,
        companyUsersStatus: null,
      };
    }
    default:
      return state;
  }
}
