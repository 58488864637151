import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ReactComponent as CloseCircledPurple } from "../../../assets/img/icons-new/general/close-circled/close-circled-purple.svg";

const QuickFilter = (props) => {
  const { isActive, text, onClick, onClearFilter, className } = props;

  return (
    <span
      className={clsx("quick-filter", className, { active: isActive })}
      onClick={!isActive ? onClick : null}
    >
      <span className="underlined-text">{text}</span>{" "}
      {isActive && onClearFilter && (
        <CloseCircledPurple onClick={onClearFilter} />
      )}
    </span>
  );
};

QuickFilter.propTypes = {
  isActive: PropTypes.bool,
  withCloseIcon: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  onClearFilter: PropTypes.func,
};

QuickFilter.defaultProps = {
  isActive: false,
  withCloseIcon: true,
  text: "",
  onClick: () => {},
};

export default QuickFilter;
