import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const Spinner = (props) => {
  const { className, innerClassName, type, text, size, fontSize } = props;

  return (
    <div
      className={clsx("d-flex justify-content-center", className, {
        "mt-5": !className,
      })}
    >
      {type === "normal" && (
        <div className={innerClassName}>
          <div
            className={clsx("spinner-border", {
              "spinner-border-sm": size === "small",
            })}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          {text && (
            <div className="spinner-text" style={{ fontSize: fontSize }}>
              {text}
            </div>
          )}
        </div>
      )}
      {type === "dots" && (
        <div>
          <div class="sk-fading-circle">
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
          </div>
          {text && (
            <div className="spinner-text" style={{ fontSize: fontSize }}>
              {text}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  type: PropTypes.oneOf(["normal", "dots"]),
  size: PropTypes.oneOf(["small", ""]),
  text: PropTypes.string,
  fontSize: PropTypes.number,
};

Spinner.defaultProps = {
  type: "normal",
  text: "",
  size: "",
  fontSize: 16,
  innerClassName: "",
  className: "",
};

export default Spinner;
