import { formatDateTime6, updatePaginationCount } from "../helpers";

const initialState = {
  // Check template name
  checkTemplateNameStatus: null,
  checkTemplateNameExists: null,

  // Estimate price
  estimatePriceStatus: null,
  estimatePriceSubscribers: null,
  estimatePriceCredits: null,

  // Tts price
  ttsPriceStatus: null,
  ttsPriceCreditsPerBlock: null,

  // Add Broadcast
  addStatus: null,
  addId: null,
  addErrors: null,
  addTemplateStatus: null,
  addTemplateId: null,
  addTemplateErrors: null,

  // Edit Broadcast
  editStatus: null,
  editId: null,
  editErrors: null,

  // Languages
  languagesStatus: null,
  languages: null,

  // Templates
  templatesStatus: null,
  templatesMoreStatus: null,
  templates: null,
  templatesPage: null,
  templatesLoadedAll: null,
  templatesPagination: {},

  // Add Template
  addVoiceTemplateStatus: null,

  // Edit Template
  editVoiceTemplateStatus: null,

  // Delete Template
  deleteVoiceTemplateStatus: null,

  // Schedule Message
  scheduleMessageStatus: null,
  scheduleMessageId: null,
  scheduleMessageData: null,

  // History
  historyStatus: null,
  history: null,
  historyPage: null,
  historyLoadedAll: null,

  // broadcast counts
  broadcastsCountData: {},
  broadcastCountStatus: "",

  // broadcast props
  fetchFilteredBroadcastsStatus: "",
  fetchBroadcastsStatus: "",
  broadcastsData: [],
  broadcastPage: null,
  filteredBroadcastFilters: [],
};

export default function reducer(state = initialState, action) {
  let templates = null;
  let newTemplates = null;
  let broadcasts = null;

  switch (action.type) {
    // Check template name
    case "BROADCASTS/CHECK_TEMPLATE_NAME":
      return {
        ...state,
        checkTemplateNameStatus: "loading",
        checkTemplateNameExists: null,
      };

    case "BROADCASTS/CHECK_TEMPLATE_NAME.SUCCESS":
      return {
        ...state,
        checkTemplateNameStatus: "success",
        checkTemplateNameExists: action.payload.exists,
      };

    case "BROADCASTS/CHECK_TEMPLATE_NAME.ERROR":
      return {
        ...state,
        checkTemplateNameStatus: "error",
        checkTemplateNameExists: null,
      };

    case "BROADCASTS/CHECK_TEMPLATE_NAME.CLEAR":
      return {
        ...state,
        checkTemplateNameStatus: null,
        checkTemplateNameExists: null,
      };

    // Estimate price
    case "BROADCASTS/ESTIMATE_PRICE":
      return {
        ...state,
        estimatePriceStatus: "loading",
      };

    case "BROADCASTS/ESTIMATE_PRICE.SUCCESS":
      return {
        ...state,
        estimatePriceStatus: "success",
        estimatePriceSubscribers: action.payload.subscribers,
        estimatePriceCredits: action.payload.credits,
      };

    case "BROADCASTS/ESTIMATE_PRICE.ERROR":
      return {
        ...state,
        estimatePriceStatus: "error",
        estimatePriceSubscribers: null,
        estimatePriceCredits: null,
      };

    case "BROADCASTS/ESTIMATE_PRICE.CLEAR":
      return {
        ...state,
        estimatePriceStatus: null,
        estimatePriceSubscribers: null,
        estimatePriceCredits: null,
      };

    // Tts price
    case "BROADCASTS/TTS_PRICE":
      return {
        ...state,
        ttsPriceStatus: "loading",
      };

    case "BROADCASTS/TTS_PRICE.SUCCESS":
      return {
        ...state,
        ttsPriceStatus: "success",
        ttsPriceCreditsPerBlock: action.payload.creditsPerBlock,
      };

    case "BROADCASTS/TTS_PRICE.ERROR":
      return {
        ...state,
        ttsPriceStatus: "error",
        ttsPriceCreditsPerBlock: null,
      };

    // Add Broadcast
    case "BROADCASTS/ADD":
      return {
        ...state,
        addStatus: "loading",
        addErrors: null,
        addTemplateStatus: "loading",
        addTemplateErrors: null,
      };

    case "BROADCASTS/ADD.SUCCESS":
    case "BROADCASTS/ADD.ERROR":
      if (action.payload) {
        return {
          ...state,
          addStatus: action.payload.broadcastStatus,
          addId: action.payload.broadcast ? action.payload.broadcast.id : null,
          addErrors: action.payload.broadcastErrors,
          addTemplateStatus: action.payload.broadcastTemplateStatus,
          addTemplateId: action.payload.broadcastTemplate
            ? action.payload.broadcastTemplate.id
            : null,
          addTemplateErrors: action.payload.broadcastTemplateErrors,
        };
      }
      return {
        ...state,
        addStatus: "error",
        addId: null,
        addErrors: { _: { _: "An error occurred." } },
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateErrors: null,
      };

    case "BROADCASTS/ADD.CLEAR":
      return {
        ...state,
        addStatus: null,
        addId: null,
        addErrors: null,
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateErrors: null,
      };

    // Edit Broadcast
    case "BROADCASTS/EDIT":
      return {
        ...state,
        editStatus: "loading",
        editId: action.scheduleMessageid,
        editErrors: null,
        addTemplateStatus: "loading",
        addTemplateErrors: null,
      };

    case "BROADCASTS/EDIT.SUCCESS":
    case "BROADCASTS/EDIT.ERROR":
      return {
        ...state,
        editStatus: action.payload.broadcastStatus,
        editId: action.payload.broadcast ? action.payload.broadcast.id : null,
        editErrors: action.payload.broadcastErrors,
        addTemplateStatus: action.payload.broadcastTemplateStatus,
        addTemplateId: action.payload.broadcastTemplate
          ? action.payload.broadcastTemplate.id
          : null,
        addTemplateErrors: action.payload.broadcastTemplateErrors,
      };

    case "BROADCASTS/EDIT.CLEAR":
      return {
        ...state,
        editStatus: null,
        editId: null,
        editErrors: null,
        addTemplateStatus: null,
        addTemplateId: null,
        addTemplateErrors: null,
      };

    // Languages
    case "BROADCASTS/LANGUAGES":
      return {
        ...state,
        languagesStatus: "loading",
      };

    case "BROADCASTS/LANGUAGES.SUCCESS":
      return {
        ...state,
        languagesStatus: "success",
        languages: action.payload.languages,
      };

    case "BROADCASTS/LANGUAGES.ERROR":
      return {
        ...state,
        languagesStatus: "error",
        languages: [],
      };

    // Templates
    case "BROADCASTS/TEMPLATES":
      return {
        ...state,
        templatesStatus: "loading",
        templatesMoreStatus: action.page > 1 ? "loading" : "",
      };

    case "BROADCASTS/TEMPLATES.SUCCESS":
      templates = [];
      newTemplates = action.payload.broadcast_templates;
      for (let i = 0; i < newTemplates.length; i += 1) {
        newTemplates[i].created_formatted = formatDateTime6(
          newTemplates[i].created,
          action.userTimeZoneOffset
        );
      }

      if (action.page === 1) {
        templates = newTemplates;
      } else {
        templates = state.templates ? [].concat(state.templates) : [];
        templates = templates.concat(newTemplates);
      }
      return {
        ...state,
        templatesPagination: action.payload.pagination,
        templatesStatus: "success",
        templatesMoreStatus: action.page > 1 ? "success" : "",
        templates: templates,
        templatesPage: action.page,
        templatesLoadedAll: action.payload.broadcast_templates.length === 0,
      };

    case "BROADCASTS/TEMPLATES.ERROR":
      return {
        ...state,
        templatesStatus: "error",
        templatesMoreStatus: action.page > 1 ? "error" : "",
        templates: null,
        templatesPage: null,
        templatesLoadedAll: null,
      };

    // Schedule Message
    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE":
      return {
        ...state,
        scheduleMessageStatus: "loading",
        scheduleMessageId: action.scheduleMessageId,
      };

    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE.SUCCESS":
      return {
        ...state,
        scheduleMessageStatus: "success",
        scheduleMessageId: action.scheduleMessageId,
        scheduleMessageData: action.payload.scheduleMessage,
      };

    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE.ERROR":
      return {
        ...state,
        scheduleMessageStatus: "error",
        scheduleMessageId: action.scheduleMessageId,
        scheduleMessageData: null,
      };

    case "BROADCASTS/FETCH_SCHEDULE_MESSAGE.CLEAR":
      return {
        ...state,
        scheduleMessageStatus: null,
        scheduleMessageId: null,
        scheduleMessageData: null,
      };

    // Templates
    case "BROADCASTS/HISTORY":
      return {
        ...state,
        historyStatus: "loading",
      };

    case "BROADCASTS/HISTORY.SUCCESS":
      templates = [];
      newTemplates = action.payload.broadcasts;
      for (let i = 0; i < newTemplates.length; i += 1) {
        newTemplates[i].send_on_formatted = formatDateTime6(
          newTemplates[i].send_on,
          action.userTimeZoneOffset
        );
      }

      if (action.page === 1) {
        templates = newTemplates;
      } else {
        templates = state.history ? [].concat(state.history) : [];
        templates = templates.concat(newTemplates);
      }
      return {
        ...state,
        historyStatus: "success",
        history: templates,
        historyPage: action.page,
        historyLoadedAll: action.payload.broadcasts.length === 0,
      };

    case "BROADCASTS/HISTORY.ERROR":
      return {
        ...state,
        historyStatus: "error",
        history: null,
        historyPage: null,
        historyLoadedAll: null,
      };

    case "BROADCASTS/ADD_TEMPLATE":
      return {
        ...state,
        addVoiceTemplateStatus: "loading",
      };

    case "BROADCASTS/ADD_TEMPLATE.SUCCESS": {
      const newTemplates = [...state.templates];

      const getAudioUrl = () => {
        if (action.payload.broadcastTemplate.audio_upload) {
          return action.payload.broadcastTemplate.audio_upload.file;
        }
        if (action.payload.broadcastTemplate.user_call_me_recording) {
          return action.payload.broadcastTemplate.user_call_me_recording
            .recordingUrl;
        }
        return "";
      };

      newTemplates.unshift({
        ...action.payload.broadcastTemplate,
        audio_url: getAudioUrl(),
      });
      return {
        ...state,
        templates: newTemplates,
        addVoiceTemplateStatus: "success",
        templatesPagination: updatePaginationCount(
          state.templatesPagination,
          true
        ),
      };
    }

    case "BROADCASTS/ADD_TEMPLATE.ERROR":
      return {
        ...state,
        addVoiceTemplateStatus: "error",
      };

    case "BROADCASTS/ADD_TEMPLATE.CLEAR":
      return {
        ...state,
        addVoiceTemplateStatus: null,
      };

    case "BROADCASTS/EDIT_TEMPLATE": {
      return {
        ...state,
        editVoiceTemplateStatus: "loading",
      };
    }

    case "BROADCASTS/EDIT_TEMPLATE.SUCCESS": {
      const newTemplates = [...state.templates];

      newTemplates[action.index] = action.payload.broadcastTemplate;

      return {
        ...state,
        templates: newTemplates,
        editVoiceTemplateStatus: "success",
      };
    }

    case "BROADCASTS/EDIT_TEMPLATE.ERROR":
      return {
        ...state,
        editVoiceTemplateStatus: "error",
      };

    case "BROADCASTS/EDIT_TEMPLATE.CLEAR":
      return { ...state, editVoiceTemplateStatus: null };

    case "BROADCASTS/DELETE_TEMPLATE":
      return {
        ...state,
        deleteVoiceTemplateStatus: "loading",
      };

    case "BROADCASTS/DELETE_TEMPLATE.SUCCESS": {
      const newTemplates = [...state.templates];
      newTemplates.splice(action.index, 1);

      return {
        ...state,
        templates: newTemplates,
        deleteVoiceTemplateStatus: "success",
        templatesPagination: updatePaginationCount(
          state.templatesPagination,
          false
        ),
      };
    }

    case "BROADCASTS/DELETE_TEMPLATE.ERROR":
      return {
        ...state,
        deleteVoiceTemplateStatus: "error",
      };

    case "BROADCASTS/FETCH_FILTERED":
      return {
        ...state,
        fetchFilteredBroadcastsStatus: "loading",
        fetchBroadcastsStatus: action?.page === 1 ? "loading" : "",
      };

    case "BROADCASTS/FETCH_FILTERED.SUCCESS":
      if (action.page === 1 || !state.broadcastsData) {
        broadcasts = action.payload.broadcasts;
      } else {
        broadcasts = state.broadcastsData.concat(action.payload.broadcasts);
      }
      return {
        ...state,
        fetchFilteredBroadcastsStatus: "success",
        fetchBroadcastsStatus: "success",
        broadcastsData: broadcasts,
        broadcastPage: action.page,
        filteredBroadcastFilters: action.filters,
      };

    case "BROADCASTS/FETCH_FILTERED.ERROR":
      return {
        ...state,
        fetchFilteredBroadcastsStatus: "error",
        fetchBroadcastsStatus: "error",
      };
    case "BROADCASTS/COUNT":
      return {
        ...state,
        broadcastCountStatus: "loading",
      };
    case "BROADCASTS/COUNT.SUCCESS":
      return {
        ...state,
        broadcastCountStatus: "success",
        broadcastsCountData: action.payload,
      };
    case "BROADCASTS/COUNT.ERROR":
      return {
        ...state,
        broadcastCountStatus: "error",
      };

    case "MESSAGES/DELETE.SUCCESS": {
      const cloneCurrentBroadcastData = state.broadcastsData;
      const filteredBroadcastData = cloneCurrentBroadcastData.filter(
        (item) =>
          item?.schedule_message &&
          item?.schedule_message?.id !== action.scheduleMessageId
      );
      return {
        ...state,
        broadcastsData: filteredBroadcastData,
      };
    }

    // Advance filter
    // case "BROADCASTS/ADVANCE_FILTER": {
    //   return {
    //     ...state,
    //     filteredBroadcastAdvFilters: action.filter,
    //   };
    // }

    case "BROADCASTS/DELETE_TEMPLATE.CLEAR":
      return { ...state, deleteVoiceTemplateStatus: null };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
