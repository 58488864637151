import { isReactNativeApp } from "../helpers";

export const getResponsiveLayouts = (layout) => {
  return {
    lg: layout,
    md: layout,
    sm: layout,
    xs: layout,
    xxs: layout,
  };
};

export const getDefaultDashboardLayout = (dashboardLayout = []) => {
  const filterDashboardLayoutForRN = dashboardLayout.filter(
    (item) => !["sitemap", "get-started"].includes(item.i)
  );
  if (isReactNativeApp()) {
    const updateYvalue = filterDashboardLayoutForRN.map(({ y, ...rest }) => ({
      y: 0,
      ...rest,
    }));
    return updateYvalue;
  }
  return dashboardLayout;
};
