import React, { useEffect, useState } from "react";
import ChromeIcon from "../../../assets/img/icons-new/browsers/chrome-icon.png";
import { Chip } from "@material-ui/core";
import {
  CHROME_EXTENSION_ID,
  CHROME_EXTENSION_LINK,
} from "../../../utils/extensionHelpers";

const InstallChromeExtensionLink = () => {
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    try {
      (window.chrome ?? window.browser).runtime
        .sendMessage(CHROME_EXTENSION_ID, {
          type: "isInstalled",
        })
        .then((response) => {
          // response is an object of { installed: boolean, version: string }
          // when installed is true, it will also return the version of the extension
          // it's not necessary but maybe we can use it in the future.
          // if installed is false, it means the extension is not installed handle it accordingly.
          if (response?.installed) setIsInstalled(true);
        });
    } catch (error) {
      setIsInstalled(false);
      // extension is not installed handle it accordingly
    }
  }, []);

  if (isInstalled) return null;

  return (
    <a
      href={CHROME_EXTENSION_LINK}
      className="admin-user-menus-wrapper extension-link-text"
      target="__blank"
    >
      <img
        src={ChromeIcon}
        width={19}
        height={19}
        alt="chrome-icon"
        className="mr-1 mb-1"
      />
      Install Extension{" "}
      <Chip
        label="NEW"
        color="succes"
        size="small"
        classes={{
          root: "sidebar-menu-new-chip",
          label: "sidebar-menu-new-chip-label",
        }}
      />
    </a>
  );
};

export default InstallChromeExtensionLink;
