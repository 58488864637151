import React, { useEffect } from "react";
import background from "../../assets/img/register/register-banner.svg";
import { ReactComponent as EmailIcon } from "../../assets/img/verification/verify-email.svg";
import pastorsLineLogo from "../../assets/img/pastorsline-logo.png";
import { ReactComponent as PastorslineLogoWhite } from "../../assets/img/pastorsline-logo-white.svg";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const VerifyYourEmail = (props) => {
  const { users, clearRegisteredUserEmail } = useUsersStore();
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  const [email] = useState(users.registeredUserEmail);

  useEffect(() => {
    if (!email) {
      history.push("/register");
    } else {
      clearRegisteredUserEmail();
    }
  }, [clearRegisteredUserEmail, email, history]);
  return (
    <div className="verify-your-email-container">
      <img src={background} alt="background" className="background" />
      <div className="logo-container">
        <PastorslineLogoWhite />
      </div>
      <div className="white-container">
        <div className="logo-container">
          {" "}
          <img src={pastorsLineLogo} alt="pastorsline-logo" />
        </div>
        <div className="email-icon">
          <EmailIcon />
        </div>
        <h1 className="title">Verify Your Email</h1>
        <div className="text-container">
          <p className="text">
            A verification email has been sent to{" "}
            <span style={{ color: "#202020" }}>{email}</span>
            .<br />
            Click on the link to activate your account
          </p>
          <p className="text">
            Didn’t receive verification email?
            <br /> Check your span/junk folder or resend your email.
          </p>
          {/* <div className="link-container">
            <a href="#void">Resend Email</a>
            <a href="#void">Edit Email</a>
            <a href="#void">Contact Support</a>
          </div> */}
        </div>
      </div>
      <div className="copyright">PastorsLine © {currentYear}</div>
    </div>
  );
};

export default VerifyYourEmail;
