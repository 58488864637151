import { useEffect } from "react";
import AxiosConfig from "../../AxiosConfig";
import { cookies } from "../../store";

export const useSetParamCompAndRedirection = ({
  companiesStatus,
  companyId,
  setCurrentCompany,
  redeirectUserByDefaultApp,
  currentCompanyStatus,
  currentCompany,
}) => {
  useEffect(() => {
    const companyCookieName = "company_id_" + AxiosConfig.getCookieNameDomain();
    const companyCookie = parseInt(cookies.get(companyCookieName));
    if (companiesStatus === "success") {
      if (companyCookie && Number(companyCookie) !== Number(companyId)) {
        setCurrentCompany(Number(companyId));
      } else {
        redeirectUserByDefaultApp();
      }
    }
  }, [
    companiesStatus,
    companyId,
    redeirectUserByDefaultApp,
    setCurrentCompany,
  ]);

  useEffect(() => {
    if (
      currentCompanyStatus === "success" &&
      companiesStatus === "success" &&
      Number(currentCompany?.id) === Number(companyId)
    ) {
      redeirectUserByDefaultApp();
    }
  }, [
    companyId,
    currentCompany,
    currentCompanyStatus,
    redeirectUserByDefaultApp,
    companiesStatus,
  ]);
};
