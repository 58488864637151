import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as BackIconGray } from "../../assets/img/icons-new/general/back/back-black.svg";
import { ReactComponent as BackIconPurple } from "../../assets/img/icons-new/general/back/back-purple.svg";
import { ReactComponent as BackIconWhite } from "../../assets/img/icons-new/general/back/back-white.svg";
import ThemeContext from "../../ThemeContext/ThemeContext";
import { GLOBAL_THEME } from "../../utils/constants";

const BackButton = (props) => {
  const { onClick, className, link, type, label, labelClassName } = props;
  const { globalTheme } = useContext(ThemeContext);

  const getBackIcon = useMemo(() => {
    if (globalTheme === GLOBAL_THEME.DARK || type === "white")
      return <BackIconWhite />;
    if (globalTheme === GLOBAL_THEME.LIGHT || type === "gray")
      return <BackIconGray />;
    if (type === "purple") return <BackIconPurple />;
    return null;
  }, [globalTheme, type]);

  const renderIcon = useCallback(() => {
    return (
      <>
        {getBackIcon}
        {label && <span className={labelClassName}>{label}</span>}
      </>
    );
  }, [getBackIcon, label, labelClassName]);

  if (onClick) {
    return (
      <span onClick={onClick} className={`mobile-back-button ${className}`}>
        {renderIcon()}
      </span>
    );
  }
  if (link) {
    return (
      <Link to={link} className={`mobile-back-button ${className}`}>
        {renderIcon()}
      </Link>
    );
  }
  return null;
};

BackButton.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
};

BackButton.defaultProps = {
  className: "",
  link: "",
  label: "",
  labelClassName: "",
  type: "gray",
};

export default BackButton;
