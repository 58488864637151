import { updatePaginationCount } from "../helpers";

const initialState = {
  // Data
  dataStatus: {},
  data: {},

  // Index
  indexStatus: null,
  indexMoreStatus: null,
  indexErrors: null,
  index: [],
  indexPage: null,
  indexLoadedAll: null,
  indexQuery: null,
  indexSelectedId: null,
  pagination: {},

  // Add
  addStatus: null,
  addErrors: null,
  addId: null,

  // Edit
  editStatus: null,
  editErrors: null,
  editId: null,

  // Delete
  deleteStatus: null,
  deleteId: null,

  // Insert
  insertTo: null,
  insertMessageTemplate: null,
};

export default function reducer(state = initialState, action) {
  let dataStatus = null;
  let data = null;
  let index = null;
  let messageTemplates = null;

  switch (action.type) {
    // Index
    case "MESSAGE_TEMPLATES/FETCH":
      return {
        ...state,
        indexStatus: "loading",
        indexMoreStatus: action.page && action.page > 1 ? "loading" : "",
      };

    case "MESSAGE_TEMPLATES/FETCH.SUCCESS":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      data = state.data ? state.data : {};
      index = action.page === 1 ? [] : state.index ? state.index : [];
      messageTemplates = action.payload.message_templates;

      for (let i = 0; i < messageTemplates.length; i += 1) {
        dataStatus[messageTemplates[i].id] = "success";
        data[messageTemplates[i].id] = messageTemplates[i];
        index.push(messageTemplates[i].id);
      }

      return {
        ...state,
        pagination: action.payload.pagination,
        dataStatus,
        data,
        indexStatus: "success",
        indexMoreStatus: action.page && action.page > 1 ? "success" : "",
        indexErrors: null,
        index,
        indexPage: action.page,
        indexLoadedAll: messageTemplates.length === 0,
        indexQuery: action.query,
        indexSelectedId: action.selectTemplateId,
      };

    case "MESSAGE_TEMPLATES/FETCH.ERROR":
      return {
        ...state,
        indexStatus: "error",
        indexMoreStatus: action.page && action.page > 1 ? "error" : "",
        indexErrors: null,
        index: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
        indexSelectedId: null,
      };

    case "MESSAGE_TEMPLATES/FETCH.CLEAR":
      return {
        ...state,
        indexStatus: null,
        indexErrors: null,
        index: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
        indexSelectedId: null,
      };

    // View
    case "MESSAGE_TEMPLATES/FETCH_SINGLE":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      dataStatus[action.templateId] = "loading";

      return {
        ...state,
        dataStatus,
      };

    case "MESSAGE_TEMPLATES/FETCH_SINGLE.SUCCESS":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      data = state.data ? state.data : {};

      dataStatus[action.templateId] = "success";
      data[action.templateId] = action.payload.message_template;

      return {
        ...state,
        dataStatus,
        data,
      };

    case "MESSAGE_TEMPLATES/FETCH_SINGLE.ERROR":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      dataStatus[action.templateId] = "error";

      return {
        ...state,
        dataStatus,
      };

    // Add
    case "MESSAGE_TEMPLATES/ADD":
      return {
        ...state,
        addStatus: "loading",
        addErrors: null,
        addId: null,
      };

    case "MESSAGE_TEMPLATES/ADD.SUCCESS":
      dataStatus = state.dataStatus;
      data = { ...state.data };
      index = [...state.index];

      dataStatus[action.payload.message_template.id] = "success";
      data[action.payload.message_template.id] =
        action.payload.message_template;
      index.unshift(action.payload.message_template.id);

      return {
        ...state,
        dataStatus,
        data,
        addStatus: "success",
        addErrors: null,
        addId: action.payload.message_template.id,
        index,
        pagination: updatePaginationCount(state.pagination, true),

        // reset index
        indexStatus: null,
        indexErrors: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
      };

    case "MESSAGE_TEMPLATES/ADD.ERROR":
      return {
        ...state,
        addStatus: "error",
        addErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
        addId: null,
      };

    case "MESSAGE_TEMPLATES/ADD.CLEAR":
      return {
        ...state,
        addStatus: null,
        addErrors: null,
        addId: null,
      };

    // Edit
    case "MESSAGE_TEMPLATES/EDIT":
      return {
        ...state,
        editStatus: "loading",
        editErrors: null,
        editId: action.templateId,
      };

    case "MESSAGE_TEMPLATES/EDIT.SUCCESS":
      dataStatus = state.dataStatus;
      data = state.data;
      dataStatus[action.payload.message_template.id] = "success";
      data[action.payload.message_template.id] =
        action.payload.message_template;

      return {
        ...state,
        dataStatus,
        data,
        editStatus: "success",
        editErrors: null,
        editId: action.templateId,
      };

    case "MESSAGE_TEMPLATES/EDIT.ERROR":
      return {
        ...state,
        editStatus: "error",
        editErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
        editId: action.templateId,
      };

    case "MESSAGE_TEMPLATES/EDIT.CLEAR":
      return {
        ...state,
        editStatus: null,
        editErrors: null,
        editId: null,
      };

    // Delete
    case "MESSAGE_TEMPLATES/DELETE":
      return {
        ...state,
        deleteStatus: "loading",
        deleteId: action.templateId,
      };

    case "MESSAGE_TEMPLATES/DELETE.SUCCESS":
      dataStatus = state.dataStatus;
      data = { ...state.data };
      index = [...state.index];

      if (typeof dataStatus[action.templateId] !== "undefined") {
        delete dataStatus[action.templateId];
      }
      if (typeof data[action.templateId] !== "undefined") {
        delete data[action.templateId];
      }
      if (index && index.indexOf(action.templateId) >= 0) {
        index.splice(index.indexOf(action.templateId), 1);
      }

      return {
        ...state,
        dataStatus,
        data,
        index,
        deleteStatus: "success",
        deleteId: action.templateId,
        pagination: updatePaginationCount(state.pagination, false),
      };

    case "MESSAGE_TEMPLATES/DELETE.ERROR":
      return {
        ...state,
        deleteStatus: "error",
        deleteId: action.templateId,
      };

    case "MESSAGE_TEMPLATES/DELETE.CLEAR":
      return {
        ...state,
        deleteStatus: null,
        deleteId: null,
      };

    // Insert
    case "MESSAGE_TEMPLATES/INSERT":
      return {
        ...state,
        insertTo: action.insertTo,
        insertMessageTemplate: action.messageTemplate,
      };

    case "MESSAGE_TEMPLATES/INSERT.CLEAR":
      return {
        ...state,
        insertTo: null,
        insertMessageTemplate: null,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
