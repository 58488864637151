export const breakpoints = {
  468: "xs",
  768: "sm",
  992: "md",
  1200: "lg",
  1500: "xl",
};

// this is a threshold value where the menu is rendered fully, anything below will make the UI unreadable and it’s not supported
export const VISUAL_VIEWPORT_HEIGHT_FOR_RESOLUTION_SCREEN = 470;

export const isSmOrXsBreakpoint = (currentBreakpoint) => {
  return ["sm", "xs"].includes(currentBreakpoint);
};

export const isLgOrBelowBreakpoint = (currentBreakpoint) => {
  return ["sm", "xs", "md", "lg"].includes(currentBreakpoint);
};

export const isMdOrBelowBreakpoint = (currentBreakpoint) => {
  if (!currentBreakpoint && window.innerWidth <= 992) return true;
  return ["sm", "xs", "md"].includes(currentBreakpoint);
};

export const isLgBreakpoint = (currentBreakpoint) => {
  return currentBreakpoint === "lg";
};

export const isXsBreakpoint = (currentBreakpoint) => {
  return currentBreakpoint === "xs";
};

export const isSmBreakpoint = (currentBreakpoint) => {
  return currentBreakpoint === "sm";
};

export const isMdBreakpoint = (currentBreakpoint) => {
  return currentBreakpoint === "md";
};
