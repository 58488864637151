import React from "react";
import PropTypes from "prop-types";
import ArchiveWorkflow from "../ActionsWorkflows/Archive";
import UnarchiveWorkflow from "../ActionsWorkflows/Unarchive";
import DeleteWorkflow from "../ActionsWorkflows/Delete";
import CloseWorkflow from "../ActionsWorkflows/Close";
import ReopenWorkflow from "../ActionsWorkflows/Reopen";

const ThreadItemActionWorkFlow = (props) => {
  const {
    tab,
    dataPayload,
    archiveThis,
    onArchiveToggle,
    unarchiveThis,
    onUnarchiveToggle,
    deleteThis,
    onDeleteToggle,
    closeThis,
    onCloseToggle,
    reopenThis,
    onReopenToggle,
    group,
  } = props;

  return (
    <div>
      {archiveThis && (
        <ArchiveWorkflow
          runWorkflow
          selectedThreads={
            tab === "people" ? { [dataPayload.id]: dataPayload } : {}
          }
          selectedSubThreads={{}}
          selectedGroups={
            tab === "groups" ? { [dataPayload.id]: dataPayload } : {}
          }
          onArchiveComplete={onArchiveToggle(false)}
          needRouting={false}
          group={tab === "groups"}
        />
      )}
      {unarchiveThis && (
        <UnarchiveWorkflow
          runWorkflow
          selectedThreads={
            tab === "people" ? { [dataPayload.id]: dataPayload } : {}
          }
          selectedSubThreads={{}}
          selectedGroups={
            tab === "groups" ? { [dataPayload.id]: dataPayload } : {}
          }
          onUnarchiveComplete={onUnarchiveToggle(false)}
          needRouting={false}
          group={tab === "groups"}
        />
      )}

      {deleteThis && (
        <DeleteWorkflow
          runWorkflow
          selectedGroups={{ [dataPayload.id]: dataPayload }}
          onDeleteComplete={onDeleteToggle(false)}
          needRouting
        />
      )}
      {closeThis && (
        <CloseWorkflow
          runWorkflow
          selectedThreads={{ [dataPayload.id]: dataPayload }}
          onCloseComplete={onCloseToggle(false)}
          needRouting
          group={!!group}
          countThreads={1}
        />
      )}
      {reopenThis && (
        <ReopenWorkflow
          runWorkflow
          selectedThreads={{ [dataPayload.id]: dataPayload }}
          onReopenComplete={onReopenToggle(false)}
          needRouting
          group={!!group}
          countThreads={1}
        />
      )}
    </div>
  );
};

ThreadItemActionWorkFlow.propTypes = {
  dataPayload: PropTypes.object.isRequired,
  tab: PropTypes.string,
  archiveThis: PropTypes.bool,
  onArchiveToggle: PropTypes.func,
  unarchiveThis: PropTypes.bool,
  onUnarchiveToggle: PropTypes.func,
  deleteThis: PropTypes.bool,
  onDeleteToggle: PropTypes.func,
  closeThis: PropTypes.bool,
  onCloseToggle: PropTypes.func,
  reopenThis: PropTypes.bool,
  onReopenToggle: PropTypes.func,
  group: PropTypes.object,
};

export default ThreadItemActionWorkFlow;
