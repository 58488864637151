import { fetchCompanies } from "../../actions/companies";
import { setRefreshNeverSyncedStatus } from "../../actions/groups";

export const onRefreshIntegration = () => {
  const callback = (config, dispatch, data) => {
    dispatch(setRefreshNeverSyncedStatus("success"));
  };

  return {
    event: "refresh-integration",
    callback,
    timeout: 5000,
  };
};

export const onReloadCompanies = () => {
  const callback = (config, dispatch, data) => {
    dispatch(fetchCompanies(true));
  };

  return {
    event: "reload-companies",
    callback,
    timeout: 750,
  };
};
