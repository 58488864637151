/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/08/2018.
 */
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import * as Sentry from "@sentry/react";
import AxiosConfig from "./AxiosConfig";
import PusherConfig from "./pusher/PusherConfig";
import TwilioConfig from "./TwilioConfig";
import { Route, Switch } from "react-router-dom";
import {
  v1Login,
  fetchUserData,
  fetchCredits,
  fetchUserSettings,
  fetchUserPermissions,
  logoutAction,
  clearLogoutStatus,
  updateBiometricOptInState,
} from "./actions/users";
import { fetchSettings } from "./actions/settings";
import { fetchCompanies, setCurrentCompany } from "./actions/companies";
import { fetchCountries } from "./actions/countries";
import {
  fetchNumbers,
  setNumbersFilter,
  setSenderNumber,
  fetchSignatures,
  fetchSignatureMergeFields,
} from "./actions/numbers";
import {
  setFilter as setThreadsFilter,
  setVoiceFilter,
} from "./actions/threads";
import { fetchTwilioToken } from "./actions/calls";
import { setFilter as setContactsFilter } from "./actions/contacts";
import { setFilter as setGroupsFilter } from "./actions/groups";
import NotFoundPage from "./pages/NotFoundPage";
import HubPage from "./pages/HubPage";
import ChooseOrganizationPage from "./pages/ChooseOrganizationPage";
import AppLoading from "./components/AppLoading";
import FcmConfig from "./FcmConfig";
import { isCordovaApp, isReactNativeApp, isEmptyObject } from "./helpers";
import Dashboard from "./components/Dashboard/Dashboard";
import SendNewMessage from "./components/SendNewMessage/SendNewMessage";
import TwilioInitializer from "./components/TwilioInitializer";
import { history, persistor } from "./store";
import {
  ACCESS_TOKEN,
  APP_LOADING,
  APP_SHARED_SESSION,
  REFRESH_TOKEN,
  SESSION_STARTED,
  USER_LOGOUT,
  UNASSIGNED_NUMBER,
  OPT_OUT_BIOMETRIC,
} from "./utils/constants";
import {
  formatURLWithTokens,
  getPermissionParsed,
} from "./utils/settingsHelpers";
import LegacyExtendSession from "./components/LegacyExtendSession/LegacyExtendSession";
import { cookies } from "./store";
import Intercom from "react-intercom";
import withResponsive from "./withResponsive";
import RenderForRN from "./components/hub/HelperComponents/RenderForRN";
import { isMdOrBelowBreakpoint } from "./utils/breakpoints";
import RenderForSmallDesktopResolution from "./components/hub/HelperComponents/RenderForSmallDesktopResolution";
import NotSupportedResolution from "./components/elements/NotSupportedResolution";
import { setBuildNumber } from "./actions/version";
import logger from "./logger";
import { getIntercomAppId } from "./utils/authHelpers";
import BroadcastChannel from "./BroadcastChannelConfig";
import { getMappedLocalStorageForLoadingDebugPayload } from "./utils/routerHelpers";
import { toggleShowErrorModal } from "./actions/main";
import { getReloadCount, setReloadCountOnLocalStorage } from "./utils/auth";
import LegacyStartSession from "./components/LegacyStartSession/LegacyStartSession";
import NewVersionAvailableModal from "./components/modals/Dashboard/NewVersionAvailableModal";
import BroadcastPage from "./pages/BroadcastPage";
import NewCallContactDrawer from "./components/modals/NewCallContactDrawer";
import { fetchCompanySettings } from "./actions/menus/adminSettings";
import UnsupportedMicrophonePermissionsModal from "./components/modals/UnsupportedMicrophonePermissionsModal";
import { isUserOrRestrictedUser } from "./utils/hooks/useIsRestrictedUser";
import IncomingCallMinimizeBar from "./components/IncomingCallMinimizeBar";

const queryString = require("query-string");
window.loadingDebug = {
  stuckTimer: null,
  logs: [],
  isLoadingDebugDispatched: false,
};
window.loadingDebug.stuckTimer = setTimeout(() => {
  logger.push({
    text: "loading app stuck",
    logs: window.loadingDebug.logs,
    platform: navigator.platform,
    userAgent: navigator.userAgent,
    localStorage: getMappedLocalStorageForLoadingDebugPayload(),
  });
  window.loadingDebug.isLoadingDebugDispatched = true;
}, 10000);

function mapStateToProps(store) {
  return {
    location: store.router.location,
    loggedUserStatus: store.users.loggedUserStatus,
    loggedUser: store.users.loggedUser,
    v1LoginStatus: store.users.v1LoginStatus,
    logoutStatus: store.users.logoutStatus,
    settingsStatus: store.settings.settingsStatus,
    companiesStatus: store.companies.companiesStatus,
    companies: store.companies.companies,
    currentCompany: store.companies.currentCompany,
    currentCompanyStatus: store.companies.currentCompanyStatus,
    countriesStatus: store.countries.status,
    numbersStatus: store.numbers.numbersStatus,
    numbers: store.numbers.numbers,
    numbersFilter: store.numbers.numbersFilter,
    numbersFilterValue: store.numbers.numbersFilterValue,
    signatureUserNumber: store.numbers.senderNumber,
    creditsStatus: store.users.creditsStatus,
    countsStatus: store.threads.countsStatus,
    twilioToken: store.calls.twilioToken,
    twilioTokenStatus: store.calls.twilioTokenStatus,

    fetchUserSettingsStatus: store.users.fetchSettingsStatus,
    fetchUserSettingsError: store.users.fetchSettingsError,
    companySettingsStatus: store.adminSettings.companySettingsStatus,
    companySettings: store.adminSettings.companySettings,
    permissionsStatus: store.users.permissionsStatus,
    v3LoginStatus: store.users.v3LoginStatus,

    settings: store.users.settings,
    intercomAppId: store.settings.settings
      ? store.settings.settings.intercomAppId
      : null,

    buildNumber: store.version.buildNumber,
    showSessionExpiredModal: store.main.showSessionExpiredModal,
    redirectingToLegacy: store.users.redirectingToLegacy,
    userPermissions: store.users.permissions,
    voiceOneToOneCallingId: store.threads.voiceOneToOneCallingId,
    unsupportedMicrophoneModalData: store.main.unsupportedMicrophoneModalData,
    incomingCallStatus: store.twilio.incomingCallStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    v1Login: () => dispatch(v1Login()),
    fetchUserData: () => dispatch(fetchUserData()),
    fetchSettings: () => dispatch(fetchSettings()),
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchCountries: () => dispatch(fetchCountries()),
    setCurrentCompany: (id) => dispatch(setCurrentCompany(id)),
    fetchCredits: (companyId) => dispatch(fetchCredits(companyId)),
    fetchNumbers: (companyId) => dispatch(fetchNumbers(companyId)),
    fetchCompanySettings: (companyId) =>
      dispatch(fetchCompanySettings(companyId)),
    setNumbersFilter: (companyId, numbers, all, save, isResetCurrentCompany) =>
      dispatch(
        setNumbersFilter(companyId, numbers, all, save, isResetCurrentCompany)
      ),
    setSenderNumber: (number) => dispatch(setSenderNumber(number)),
    handlePusherEvent: (dispatchRess) => {
      for (let i = 0; i < dispatchRess.length; i++) {
        dispatch(dispatchRess[i]);
      }
    },
    fetchSignatures: (companyId, numbers) =>
      dispatch(fetchSignatures(companyId, numbers)),
    fetchSignatureMergeFields: (companyId, userId, signatureUserNumber) =>
      dispatch(
        fetchSignatureMergeFields(companyId, userId, signatureUserNumber)
      ),
    dispatchProxy: (dispatchRes) => dispatch(dispatchRes),
    fetchTwilioToken: (companyId) => dispatch(fetchTwilioToken(companyId)),
    setThreadsFilter: (filter) => dispatch(setThreadsFilter(filter)),
    setContactsFilter: (filter) => dispatch(setContactsFilter(filter)),
    setVoiceFilter: (filter) => dispatch(setVoiceFilter(filter)),
    setGroupsFilter: (filter) => dispatch(setGroupsFilter(filter)),

    fetchUserSettings: () => dispatch(fetchUserSettings()),
    fetchUserPermissions: (params) => dispatch(fetchUserPermissions(params)),
    setBuildNumber: (buildNumber) => dispatch(setBuildNumber(buildNumber)),
    toggleShowErrorModal: (show) => dispatch(toggleShowErrorModal(show)),
    pusherConfigDispatchEvent: (dispatchRess) => dispatch(dispatchRess),
    logoutAction: () => dispatch(logoutAction()),
    clearLogoutStatus: () => dispatch(clearLogoutStatus()),
    updateBiometricOptInState: (status) =>
      dispatch(updateBiometricOptInState(status)),
  };
}

const checkUrlByRegex = (currentUrl, regex) => {
  const match = currentUrl.match(regex);
  return match && currentUrl === match[0];
};

const checkUrlBothCases = (currentUrl, url, checkFilter) => {
  const condition = currentUrl === url || currentUrl === `${url}/`;
  if (checkFilter) {
    const regex = new RegExp(`${url}/filter/[\\w-]+`);
    return condition || checkUrlByRegex(currentUrl, regex);
  }
  return condition;
};

const showIntercomBubble = (url) => {
  return (
    checkUrlBothCases(url, "/hub/messages", true) ||
    checkUrlBothCases(url, "/hub/calls", true) ||
    checkUrlBothCases(url, "/hub/people", true) ||
    checkUrlBothCases(url, "/hub/groups", true) ||
    checkUrlBothCases(url, "/hub/menus") ||
    checkUrlBothCases(url, "/hub/menus/filter/tags") ||
    checkUrlBothCases(url, "/hub/menus/filter/analytics") ||
    checkUrlBothCases(url, "/hub/menus/filter/templates") ||
    checkUrlBothCases(url, "/hub/menus/filter/settings") ||
    checkUrlBothCases(url, "/dashboard") ||
    checkUrlBothCases(url, "/send-new-message")
  );
};

class RouterClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appReady: false,
      companyPage: false,
      otherLoaded: false,
      logout: false,
    };
  }

  componentDidMount() {
    const { handlePusherEvent } = this.props;
    if (!this.state.appReady) {
      this.loadApp();
      this.handleBuildNumber();
      // Broadcast channel
      BroadcastChannel.init(handlePusherEvent);
    }
    if (this.props.loggedUser) this.props.fetchUserData();
    if (isReactNativeApp())
      window.addEventListener(
        OPT_OUT_BIOMETRIC,
        this.handleOptOutBiometricEvent
      );
  }

  componentDidUpdate(prevProps) {
    const { redirectingToLegacy, clearLogoutStatus, breakpoint } = this.props;

    if (this.props.logoutStatus === "success") {
      if (redirectingToLegacy) {
        FcmConfig.sendParentMessage(USER_LOGOUT);
        if (isReactNativeApp()) {
          window.ReactNativeWebView?.postMessage(USER_LOGOUT);
        }
        window.localStorage.setItem("archive-warning-hide", 0);
        this.setState({ appReady: false, logout: true });
      }
      persistor.purge();
      clearLogoutStatus();
      return;
    }

    if (this.state.logout) {
      return;
    }
    if (!this.state.appReady) {
      this.loadApp(prevProps);
      return;
    }

    if (
      (this.props.currentCompany && !prevProps.currentCompany) ||
      (this.props.currentCompany &&
        prevProps.currentCompany &&
        this.props.currentCompany.id !== prevProps.currentCompany.id)
    ) {
      PusherConfig.init(
        this.props.currentCompany.id,
        this.props.loggedUser.id,
        this.props.pusherConfigDispatchEvent
      );
      window.loadingDebug?.logs?.push({
        text: "INSIDE COMPONENT DID UPDATE IN ROUTER LINE NO 210",
        appReady: this.state.appReady,
        loggedUser: this.props.loggedUser,
        fetchUserSettingsStatus: this.props.fetchUserSettingsStatus,
        cookies: cookies.getAll(),
      });
      this.setState({ appReady: false });
      this.props.fetchCredits(this.props.currentCompany.id);
      this.props.fetchNumbers(this.props.currentCompany.id);
      this.props.fetchTwilioToken(this.props.currentCompany.id);
      this.props.fetchUserPermissions({
        companyId: this.props.currentCompany.id,
      });
      if (!isUserOrRestrictedUser(this.props.currentCompany)) {
        this.props.fetchCompanySettings(this.props.currentCompany.id);
      }
    }

    // Init Twilio Client
    if (
      this.props.currentCompany &&
      this.props.twilioToken &&
      !TwilioConfig.isInitialized() &&
      !isCordovaApp()
    ) {
      TwilioConfig.init(
        this.props.twilioToken,
        this.props.currentCompany,
        this.props.loggedUser,
        this.props.dispatchProxy
      );
    }

    if (
      this.props.numbersStatus !== prevProps.numbersStatus &&
      this.props.numbersStatus === "success" &&
      this.props.currentCompany
    ) {
      this.loadNumbersFilter();
      this.loadSenderNumber();
    }

    // Open on boarding modal if get has_onboarded flag  as false
    if (
      this.state.appReady &&
      !isMdOrBelowBreakpoint(breakpoint) &&
      !isEmptyObject(this.props?.companySettings) &&
      !Boolean(this.props?.companySettings?.info?.has_onboarded) &&
      window?.location?.hash !== "#modal-on-boarding"
    ) {
      history.push("#modal-on-boarding");
    }
  }

  componentWillUnmount() {
    if (isReactNativeApp())
      window.removeEventListener(
        OPT_OUT_BIOMETRIC,
        this.handleOptOutBiometricEvent
      );
  }

  handleOptOutBiometricEvent = (event) => {
    if (isReactNativeApp()) {
      const { updateBiometricOptInState } = this.props;
      const eventData = event.detail;
      updateBiometricOptInState({ optInBiometric: eventData.status });
    }
  };

  handleBuildNumber() {
    if (
      !process.env.REACT_APP_BUILD_NUMBER ||
      this.props.buildNumber === process.env.REACT_APP_BUILD_NUMBER
    )
      return;
    this.props.setBuildNumber(process.env.REACT_APP_BUILD_NUMBER);
  }

  isErrorOccuredWhileLoadingApp = () => {
    const {
      companiesStatus,
      countriesStatus,
      creditsStatus,
      numbersStatus,
      fetchUserSettingsStatus,
      permissionsStatus,
      showSessionExpiredModal,
    } = this.props;
    const { appReady } = this.state;
    // check if there is an error in any of the statuses
    if (
      !appReady &&
      [
        companiesStatus,
        countriesStatus,
        creditsStatus,
        numbersStatus,
        fetchUserSettingsStatus,
        permissionsStatus,
      ].includes("error") &&
      !showSessionExpiredModal
    ) {
      return true;
    }
    return false;
  };

  loadApp(prevProps) {
    const { logoutAction, toggleShowErrorModal } = this.props;
    // check if there is an error in any of the statuses
    if (this.isErrorOccuredWhileLoadingApp()) {
      const reloadCount = getReloadCount();
      if (reloadCount === 0) {
        logoutAction();
        setReloadCountOnLocalStorage(1);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return;
      } else if (reloadCount >= 1) {
        toggleShowErrorModal(true);
        setReloadCountOnLocalStorage(2);
        return;
      }
      return;
    }

    // Fetch user app settings
    if (!this.props.fetchUserSettingsStatus && !this.props.settings) {
      this.props.fetchUserSettings();
    }

    // User logged - fetch user data

    if (
      AxiosConfig.getAuthToken() &&
      !this.props.loggedUser &&
      this.props.loggedUserStatus !== "loading" &&
      this.props.loggedUserStatus !== "error"
    ) {
      this.props.fetchUserData();
    }

    if (
      this.props.currentCompanyStatus === "success" &&
      !this.props.permissionsStatus
    ) {
      this.props.fetchUserPermissions({
        companyId: this.props.currentCompany.id,
      });
    }

    if (
      this.props.settingsStatus == null ||
      this.props.companiesStatus == null
    ) {
      // Fetch settings and companies
      if (this.props.settingsStatus == null) {
        this.props.fetchSettings();
      }
      if (this.props.companiesStatus == null) {
        this.props.fetchCompanies();
      }
      return;
    }

    // Fetch countries
    if (this.props.countriesStatus === null && !this.props.countries?.length) {
      this.props.fetchCountries();
    }

    if (!this.props.loggedUser) {
      return;
    }

    // Select company if cookie exists or go to choose page
    if (
      !this.props.currentCompany &&
      this.props.companiesStatus === "success"
    ) {
      const companyCookieName =
        "company_id_" + AxiosConfig.getCookieNameDomain();
      const companyCookie = parseInt(this.props.cookies.get(companyCookieName));
      if (companyCookie) {
        for (let i = 0; i < this.props.companies.length; i++) {
          if (this.props.companies[i].id === companyCookie) {
            this.props.setCurrentCompany(companyCookie);
            return;
          }
        }
      }

      // If only one company available - choose it
      if (this.props.companies.length === 1) {
        this.props.setCurrentCompany(this.props.companies[0].id);
        return;
      }

      // Set to show Choose Organization page
      if (
        !this.state.companyPage &&
        this.props.fetchUserSettingsStatus !== "loading"
      ) {
        this.setState({ companyPage: true }, () => {
          history.push(
            `${this.props.location.pathname}#modal-choose-organization`
          );
        });
      }
      return;
    } else if (!this.props.currentCompany) {
      return;
    }

    // Load other data
    if (this.props.currentCompany && !this.state.otherLoaded) {
      this.setState({ otherLoaded: true }, () => {
        FcmConfig.init(
          this.props.loggedUser.id,
          localStorage.getItem(REFRESH_TOKEN),
          localStorage.getItem(ACCESS_TOKEN)
        );
        FcmConfig.sendParentMessage("user");
        PusherConfig.init(
          this.props.currentCompany.id,
          this.props.loggedUser.id,
          this.props.pusherConfigDispatchEvent
        );
        if (!isUserOrRestrictedUser(this.props.currentCompany)) {
          this.props.fetchCompanySettings(this.props.currentCompany.id);
        }
        this.props.fetchCredits(this.props.currentCompany.id);
        this.props.fetchNumbers(this.props.currentCompany.id);
        this.props.fetchTwilioToken(this.props.currentCompany.id);
      });
    }

    // Load number filter from current company data
    if (
      this.props.numbersStatus === "success" &&
      this.props.numbersFilterValue === null
    ) {
      this.loadNumbersFilter();
    }

    if (this.props.numbersStatus === "success") {
      this.loadSenderNumber();
    }

    if (
      this.props.fetchUserSettingsStatus === "success" &&
      this.props.settings?.default_app_version === "v2" &&
      process.env.NODE_ENV !== "development" // ensure that on development mode we stay on v3 regardless
    ) {
      window.location.href = formatURLWithTokens(
        process.env.REACT_APP_V2_APP_URL
      );
    }

    // After load other data
    window.loadingDebug?.logs?.push({
      text: "INSIDE COMPONENT DID UPDATE IN ROUTER LINE NO 401",
      appReady: this.state.appReady,
      loggedUser: this.props.loggedUser,
      fetchUserSettingsStatus: this.props.fetchUserSettingsStatus,
      cookies: cookies.getAll(),
      creditsStatus: this.props.creditsStatus,
      numbersStatus: this.props.numbersStatus,
      numbersFilterValue: this.props.numbersFilterValue,
      countsStatus: this.props.countsStatus,
      permissionsStatus: this.props.permissionsStatus,
    });
    if (
      !this.state.appReady &&
      this.props.creditsStatus === "success" &&
      this.props.numbersStatus === "success" &&
      this.props.numbersFilterValue !== null &&
      this.props.fetchUserSettingsStatus === "success" &&
      this.props.permissionsStatus === "success"
    ) {
      this.props.fetchSignatures(this.props.currentCompany.id, [
        ...this.props.numbers.map((number) => number.number),
        UNASSIGNED_NUMBER,
      ]);

      if (this.props.signatureUserNumber?.number) {
        this.props.fetchSignatureMergeFields(
          this.props.currentCompany.id,
          this.props.loggedUser.id,
          this.props.signatureUserNumber.number
        );
      }

      clearTimeout(window.loadingDebug.stuckTimer);
      this.setState({ appReady: true, companyPage: false });
      setReloadCountOnLocalStorage(0);
      if (localStorage.getItem(APP_SHARED_SESSION)) {
        BroadcastChannel.postBroadcastMessage(SESSION_STARTED);
      }
      this.props.toggleShowErrorModal(false);
      if (window.loadingDebug.isLoadingDebugDispatched) {
        logger.push({
          text: "loading app stuck - loaded",
        });
        window.loadingDebug.isLoadingDebugDispatched = false;
      }
      if (isReactNativeApp() && !isEmptyObject(this.props.settings)) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: "active-theme",
            theme: this.props.settings?.mobile_theme,
          })
        );
      }
      if (isReactNativeApp()) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: APP_LOADING,
            value: false,
          })
        );
      }

      // Set user data to Sentry
      Sentry.setUser({
        id: this.props?.loggedUser?.id || "",
        email: this.props?.loggedUser?.email || "",
        name: `${this.props?.loggedUser?.first_name || ""} ${
          this.props?.loggedUser?.last_name || ""
        }`,
      });
    }
  }

  loadNumbersFilter() {
    let numbersFilter = this.props.currentCompany.filter_number_settings;
    numbersFilter = numbersFilter ? numbersFilter.split(",") : ["all"];

    let numbers = [];
    if (numbersFilter[0] === "all") {
      for (let i = 0; i < this.props.numbers.length; i++) {
        numbers.push(this.props.numbers[i].number);
      }
    } else {
      for (let i = 0; i < this.props.numbers.length; i++) {
        if (numbersFilter.indexOf(this.props.numbers[i].number) !== -1) {
          numbers.push(this.props.numbers[i].number);
        }
      }
    }
    const all = this.props.numbers.length === numbers.length;
    this.props.setNumbersFilter(
      this.props.currentCompany.id,
      numbers,
      all,
      false,
      false // Is reset current company
    );
    PusherConfig.setNumbersFilter(numbers);
  }

  loadSenderNumber() {
    let senderNumber = null;
    for (let i = 0; i < this.props.numbers.length; i++) {
      if (i === 0 || this.props.numbers[i].is_default) {
        senderNumber = this.props.numbers[i].number;
      }
    }
    this.props.setSenderNumber(senderNumber);
  }

  checkRedirect() {
    const search = queryString.parse(this.props.location.search);
    const urls = [
      "",
      "/",
      "/login",
      "/logout",
      "/companies/switch",
      "/hub",
      "/hub/",
    ];
    if (search._return && urls.indexOf(search._return) === -1) {
      return { pathname: search._return };
    } else if (urls.indexOf(this.props.location.pathname) !== -1) {
      if (this.props.location.hash) {
        return {
          pathname: "/dashboard",
          hash: this.props.location.hash,
        };
      } else {
        return {
          pathname: isReactNativeApp()
            ? "/hub/messages/filter/received"
            : "/dashboard",
        };
      }
    } else if (
      !this.props.location.hash &&
      this.props.loggedUser &&
      this.props.loggedUser.skip_step5
    ) {
      return { hash: "modal-host-landline" };
    }
  }

  render() {
    const {
      breakpoint,
      voiceOneToOneCallingId,
      userPermissions,
      unsupportedMicrophoneModalData,
    } = this.props;
    let redirect = this.checkRedirect();
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    if (this.state.companyPage) {
      return (
        <Switch>
          <Route path="*" component={ChooseOrganizationPage} />
        </Switch>
      );
    }

    if (
      !this.state.appReady ||
      !this.props.loggedUser ||
      this.props.fetchUserSettingsStatus === "loading"
    ) {
      window.loadingDebug?.logs?.push({
        text: "Router > Show AppLoading",
        appReady: this.state.appReady,
        loggedUser: this.props.loggedUser,
        fetchUserSettingsStatus: this.props.fetchUserSettingsStatus,
        fetchUserSettingsError: this.props.fetchUserSettingsError,
        cookies: cookies.getAll(),
      });
      return <AppLoading />;
    }

    const intercomEnabled =
      this.props.loggedUser &&
      (this.props.intercomAppId || getIntercomAppId()) &&
      (!cookies.get("is_admin") || !cookies.get("is_autologin"));

    return (
      <>
        {intercomEnabled && (
          <RenderForRN show={false}>
            <Intercom
              appID={this.props.intercomAppId || getIntercomAppId()}
              user_id={this.props.loggedUser.id}
              user_hash={this.props.loggedUser.intercomUserHash}
              email={this.props.loggedUser.email}
              name={
                this.props.loggedUser.first_name +
                " " +
                this.props.loggedUser.last_name
              }
              custom_launcher_selector=".btn-intercom-launcher"
              hide_default_launcher={
                !showIntercomBubble(window.location.pathname) ||
                isMdOrBelowBreakpoint(breakpoint)
              }
            />
          </RenderForRN>
        )}

        <RenderForSmallDesktopResolution>
          <NotSupportedResolution />
        </RenderForSmallDesktopResolution>

        {/* Cordova app has a different flow for now. Will be changed in the future. */}
        {!isCordovaApp() && <TwilioInitializer />}
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/broadcast" component={BroadcastPage} />
          <Route exact path="/send-new-message" component={SendNewMessage} />
          <Route
            exact
            path="/hub/:tab/filter/:filter/:subfilter/submenu/:submenu"
            component={HubPage}
          />
          <Route
            exact
            path="/hub/:tab/filter/:filter/:subfilter/submenu/:submenu/:headertab"
            component={HubPage}
          />
          <Route
            exact
            path="/hub/:tab(groups)/filter/:integration/never-synced"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/:threadType(contact|group)/:interlocutorId/:infoTab(about|groups|tags|integrations|campaigns|messages|change-logs|people|web-widgets|qr-codes)"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/:threadType(contact|group)/:interlocutorId/:subcontactId/:infoTab(about|groups|tags|integrations|campaigns|messages|change-logs|people|web-widgets|qr-codes)"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people)/:threadType(contact|group)/:interlocutorId/:subcontactId"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/:threadType(contact|group)/:interlocutorId"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/filter/:filter/:threadType(contact|group)/:interlocutorId/:infoTab(about|groups|tags|integrations|campaigns|messages|change-logs|people|web-widgets|qr-codes)"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/filter/:filter/:threadType(contact|group)/:interlocutorId/:subcontactId/:infoTab(about|groups|tags|integrations|campaigns|messages|change-logs|people|web-widgets|qr-codes)"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/filter/:filter/:threadType(contact|group|tag)/:interlocutorId/:subcontactId"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups)/filter/:filter/:threadType(contact|group|tag)/:interlocutorId"
            component={HubPage}
          />
          <Route
            exact
            path="/hub/:tab/filter/:filter/:interlocutorId"
            component={HubPage}
          />
          <Route
            exact
            path="/hub/:tab/filter/:filter/:subfilter/:interlocutorId"
            component={HubPage}
          />
          <Route
            exact
            path="/hub/:tab/filter/:filter/:subfilter/:interlocutorId/:headertab"
            component={HubPage}
          />
          <Route
            path="/hub/:tab(messages|calls|people|groups|menus|campaigns)/filter/:filter"
            component={HubPage}
          />
          <Route path="/hub/:tab(groups)/:interlocutorId" component={HubPage} />
          <Route
            path="/hub/:tab(messages|calls|groups|people|menus|campaigns|import-contacts|auto-reply|data-capture|create-poll|sms-birthday|create-contest|automated-message|number-voice-broadcast|number-landline)"
            component={HubPage}
          />
          <Route path="*" component={NotFoundPage} />
        </Switch>
        <LegacyExtendSession />
        {!cookies.get("is_autologin") && <LegacyStartSession />}
        {this.state.appReady && isCordovaApp() && <NewVersionAvailableModal />}

        {/* On going call side drawer */}
        {getPermissionParsed(userPermissions?.messages_calls, "call") &&
          voiceOneToOneCallingId && (
            <NewCallContactDrawer
              show={voiceOneToOneCallingId}
              param={voiceOneToOneCallingId}
            />
          )}

        {/* Unsupported microphone modal */}
        {unsupportedMicrophoneModalData?.show && (
          <UnsupportedMicrophonePermissionsModal />
        )}

        {/* Incoming call banner with modal amd drawer */}
        {this.props.incomingCallStatus && (
          <IncomingCallMinimizeBar
            incomingCallStatus={this.props.incomingCallStatus}
          />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(withCookies(RouterClass)));
