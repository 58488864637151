import { useMemo } from "react";
import { isMdOrBelowBreakpoint } from "../breakpoints";
import { MOBILE } from "../constants";
import useUsersStore from "./ReduxHooks/userStore";
import useBreakpoint from "./useBreakpoints";

export const useWithMobileMode = (ignoreUserSetting = false) => {
  const { users } = useUsersStore();
  const breakpoint = useBreakpoint();
  const isOnMobileMode = useMemo(
    () =>
      (ignoreUserSetting || users.settings?.mobile_display === MOBILE) &&
      isMdOrBelowBreakpoint(breakpoint),
    [breakpoint, users, ignoreUserSetting]
  );

  return isOnMobileMode;
};
