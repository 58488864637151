import React from "react";
import PropTypes from "prop-types";
import { Radio } from "@material-ui/core";

export const RadioButtonWithLabel = (props) => {
  const {
    label,
    checked,
    onChange,
    subLabel,
    className,
    coloredChecked,
    labelStyle,
    radioProps,
  } = props;

  return (
    <div className={`radio-button-with-label-container ${className}`}>
      <div className="d-flex align-items-center">
        <Radio {...radioProps} checked={checked} onChange={onChange} />
        <span
          className={`radio-button-label ${!checked ? "not-checked" : ""}`}
          style={
            coloredChecked && checked
              ? {
                  color: "#202020",
                }
              : labelStyle
          }
        >
          {label}
        </span>
      </div>
      <div
        className={`radio-button-sub-label ${!checked ? "not-checked" : ""}`}
      >
        {subLabel}
      </div>
    </div>
  );
};

RadioButtonWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  coloredChecked: PropTypes.bool,
  labelStyle: PropTypes.object,
  radioProps: PropTypes.object,
};

RadioButtonWithLabel.defaultProps = {
  className: "",
  coloredChecked: false,
  labelStyle: {},
  radioProps: {},
};

export default RadioButtonWithLabel;
