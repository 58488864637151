import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfirmationModal from "../../modals/ActionsModals/ConfirmationModal";
import SimilarContactsModal from "../../modals/ActionsModals/SimilarContactsModal";
import { archiveContacts, addSnackData } from "../../../actions/contacts";
import {
  archiveGroups,
  addSnackData as addGroupSnackData,
} from "../../../actions/groups";
import { handleGroupItemsActions } from "../../../actions/groups";
import Snackbar from "../../elements/Snackbar";
import Avatar from "../../Avatar";
import {
  doGroupMembersContainDuplicates,
  doSelectedThreadsContainDuplicates,
  getGroupIcon,
  getNameBy,
  groupName,
} from "../../../helpers";
import { withRouter } from "react-router-dom";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { getSnackbarPosition } from "../../../utils/settingsHelpers";

function mapStateToProps(store, ownProps) {
  return {
    companyId: store.companies.currentCompany.id,
    archiveMembersStatus: store.contacts.archiveMembersStatus,
    archivedSuccessContacts: store.contacts.archivedSuccessContacts,
    failedToArchiveNumber: store.contacts.failedToArchiveNumber,
    archiveGroupsStatus: store.groups.archiveGroupsStatus,
    archivedContactsPathname: store.contacts.archivedContactsPathname,
    archivedContactsInGroups: store.groups.archivedContactsInGroups,
    archivedSuccessGroups: store.groups.archivedSuccessGroups,
    contactsData: store.contacts.data,
    groupsData: store.groups.data,
    groupMembers: store.groups.members,
    isGroupAllMembersSelected: store.groups.isGroupAllMembersSelected, // boolean that shows if all threads were selected
    membersFilter: store.groups.membersFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onArchiveContacts: (
      companyId,
      contactIds,
      groupIds,
      primaryAssignees,
      containDuplicates,
      workflowId,
      needRouting,
      isUndo,
      allParams
    ) =>
      dispatch(
        archiveContacts(
          companyId,
          contactIds,
          groupIds,
          primaryAssignees,
          containDuplicates,
          workflowId,
          needRouting,
          isUndo,
          allParams
        )
      ),
    onGroupItemsActions: (time, selectedThreads, groupData, subMembersData) =>
      dispatch(
        handleGroupItemsActions(
          time,
          selectedThreads,
          groupData,
          subMembersData
        )
      ),
    setSnackData: (data, entity) => dispatch(addSnackData(data, entity)),
    onArchiveGroups: (
      companyId,
      groupIds,
      primaryAssignees,
      contactDuplicates,
      contactsAlso,
      workflowId,
      needRouting,
      contactIds,
      isUndo,
      isGroupTag,
      allParams
    ) => {
      dispatch(
        archiveGroups(
          companyId,
          groupIds,
          primaryAssignees,
          contactDuplicates,
          contactsAlso,
          workflowId,
          needRouting,
          contactIds,
          isUndo,
          isGroupTag,
          allParams
        )
      );
    },
    setGroupSnackData: (data, entity) =>
      dispatch(addGroupSnackData(data, entity)),
  };
}

const Archive = ({
  // Redux props
  companyId,
  archiveMembersStatus,
  archivedSuccessContacts,
  archiveGroupsStatus,
  archivedContactsInGroups,
  archivedSuccessGroups,
  contactsData,
  groupsData,
  groupMembers,
  failedToArchiveNumber,
  // Redux func
  onGroupItemsActions,
  onArchiveContacts,
  setSnackData,
  onArchiveGroups,
  setGroupSnackData,
  // Props from parent
  runWorkflow,
  selectedThreads,
  selectedSubThreads,
  onArchiveComplete,
  confirmationModal,
  needRouting,
  group,
  selectedGroups,
  isAddingUndoWithRedirect,
  isGroupAllMembersSelected,
  match,
  membersFilter,
  numberOfSelectedPeople,
  allParams,
  isGroupTag,
  areAllGroupSelected,
  descriptionTextVisible,
  descriptionText,
  archivedContactsPathname,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalHeader, setConfirmationModalHeader] = useState("");
  const [confirmationModalContent, setConfirmationModalContent] = useState(
    <></>
  );
  const [needSimilarContactsModal, setNeedSimilarContactsModal] =
    useState(false);
  const [similarContactsModal, setSimilarContactsModal] = useState(false);
  const [infoSnackbar, setInfoSnackbar] = useState(false);
  const [singleContactData, setSingleContactData] = useState({});
  const [singleGroupData, setSingleGroupData] = useState({});
  const [workflowId, setWorkflowId] = useState();
  const [undoPayload, setUndoPayload] = useState();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (runWorkflow) {
      startWorkflow();
    }
    // eslint-disable-next-line
  }, [runWorkflow]);

  useEffect(() => {
    if (archiveMembersStatus === `success-${workflowId}`) {
      toggleUndoSnackbar(true)();
    } else if (archiveMembersStatus === `failure-${workflowId}`) {
      toggleUndoSnackbar(true)(true);
    }

    if (archiveGroupsStatus === `success-${workflowId}`) {
      toggleGroupUndoSnackbar(true)();
    } else if (archiveGroupsStatus === `failure-${workflowId}`) {
      toggleGroupUndoSnackbar(true)(true);
    }

    if (archiveGroupsStatus === `progress-${workflowId}`) {
      setSimilarContactsModal(true);
    }
    // eslint-disable-next-line
  }, [archiveMembersStatus, archiveGroupsStatus]);

  const handleContactState = () => {
    let multiContact = false;
    let totalContacts = 0;

    for (const item in selectedThreads) {
      if (
        selectedThreads[item].contacts_ids &&
        selectedThreads[item].contacts_ids.length > 1
      ) {
        multiContact = true;
      }
      if (selectedThreads[item]) {
        setSingleContactData(
          selectedThreads[item].contact
            ? selectedThreads[item].contact
            : selectedThreads[item]
        );
      }
      totalContacts++;
    }
    const subThreadsKeys = selectedSubThreads
      ? Object.keys(selectedSubThreads)
      : [];
    if (multiContact || subThreadsKeys.length >= 1) {
      for (const key of subThreadsKeys) {
        totalContacts += selectedSubThreads[key].length;
      }
      if (
        Object.keys(singleContactData).length < 1 &&
        selectedSubThreads &&
        selectedSubThreads[subThreadsKeys[0]]
      ) {
        setSingleContactData(selectedSubThreads[subThreadsKeys[0]][0]);
      }
      onGroupItemsActions("", selectedThreads, {}, selectedSubThreads);
      multiContact = true;
    } else {
      onGroupItemsActions("", selectedThreads, {}, {});
    }

    return {
      multiContact,
      totalContacts,
    };
  };

  const setGlobalState = (empty) => {
    let multiContact = false;
    let totalContacts = 0;
    if (empty) {
      onGroupItemsActions("", {}, {}, {});
      return;
    }
    if (group) {
      const groupKeys = Object.keys(selectedGroups);
      totalContacts = groupKeys.length;
      setSingleGroupData(selectedGroups[groupKeys[0]]);
      /* Population of contacts, see for duplicate contacts */
      // for (const key of groupKeys) {
      // }
    } else {
      const aux = handleContactState();
      multiContact = aux.multiContact;
      totalContacts = aux.totalContacts;
    }

    return {
      multiContact,
      totalContacts: numberOfSelectedPeople
        ? numberOfSelectedPeople
        : totalContacts,
    };
  };

  const getSelectedGroupBodyContent = (groupKeys) => {
    if (areAllGroupSelected || isGroupAllMembersSelected) {
      return "all";
    } else if (groupKeys.length > 1) {
      return groupKeys.length;
    }
    return "this";
  };

  const handleStartWorkflowForGroups = () => {
    const groupKeys = Object.keys(selectedGroups);
    const focalGroupData = selectedGroups[groupKeys[0]];
    const doContainDuplicates = doGroupMembersContainDuplicates(
      groupKeys,
      groupMembers,
      contactsData
    );

    const headerContent = (
      <span>
        Archive Group
        {groupKeys.length === 1 ? (
          <>
            <span>: </span>
            <span style={{ position: "relative", top: "9px" }}>
              <Avatar
                isGroup
                isAdhocGroup={focalGroupData && focalGroupData.addhoc_id}
                isAllPeopleGroup={
                  focalGroupData && focalGroupData.my_group_status > 0
                }
                isMultipleContacts={false}
                firstName={""}
                lastName={""}
                email={""}
                bgColor={""}
              />
            </span>
            <span>{groupName(selectedGroups[groupKeys[0]])}</span>
            <span>{getGroupIcon(selectedGroups[groupKeys[0]])}</span>
          </>
        ) : (
          <span>s</span>
        )}
      </span>
    );
    const bodyContent = `Are you sure you want to archive ${getSelectedGroupBodyContent(
      groupKeys
    )} ${groupKeys.length > 1 ? "Groups?" : "Group?"}`;
    setNeedSimilarContactsModal(doContainDuplicates);
    setOpenConfirmationModal(true);
    setConfirmationModalHeader(headerContent);
    setConfirmationModalContent(bodyContent);
  };

  const handleStartWorkflowForContacts = (multiContact, totalContacts) => {
    if (multiContact && totalContacts === 1) {
      setSimilarContactsModal(multiContact);
    } else if (confirmationModal && (!multiContact || totalContacts >= 1)) {
      setOpenConfirmationModal(true);
      setConfirmationModalHeader(
        `Archive ${totalContacts > 1 ? "People" : "Person"}`
      );
      setConfirmationModalContent(getConfirmationModalContent(totalContacts));
    } else if (multiContact && confirmationModal) {
      setNeedSimilarContactsModal(false);
    } else if (!confirmationModal) {
      proceedArchive(true, multiContact);
    }
  };

  const startWorkflow = () => {
    const { multiContact, totalContacts } = setGlobalState();

    if (!window.localStorage.getItem("archive-warning-hide")) {
      toggleInfoSnackbar(true)();
    }
    if (group && selectedGroups) {
      handleStartWorkflowForGroups();
    } else {
      handleStartWorkflowForContacts(multiContact, totalContacts);
    }
  };

  const getConfirmationModalContent = useCallback(
    (totalContacts) => {
      if (totalContacts > 1) {
        const peopleCount = isGroupAllMembersSelected ? "All" : totalContacts;
        return `Are you sure you want to archive ${peopleCount} People?`;
      } else {
        return `Are you sure you want to archive ${getNameBy(
          selectedThreads[Object.keys(selectedThreads)[0]].contacts_ids,
          [],
          contactsData,
          groupsData
        )} ?`;
      }
    },
    [contactsData, groupsData, selectedThreads, isGroupAllMembersSelected]
  );

  const proceedArchiveForGroup = (val, archiveContacts) => {
    if (val) {
      const groupIds = Object.keys(selectedGroups);
      const workflowId = `${singleGroupData.id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      setWorkflowId(workflowId);
      const undoData = {
        companyId,
        groupIds,
        contactIds: [],
        primaryAssignees: {},
        containDuplicates: false,
        workflowId,
        needRouting,
        contactsAlso: archiveContacts,
      };
      setUndoPayload(undoData);
      onArchiveGroups(
        companyId,
        groupIds,
        {},
        false,
        archiveContacts,
        workflowId,
        needRouting,
        [],
        false,
        isGroupTag,
        allParams
      );
      setGlobalState(true);
    } else {
      onArchiveComplete();
    }
  };

  const proceedArchiveForContact = (val, duplicates) => {
    if (val && (needSimilarContactsModal || duplicates)) {
      setSimilarContactsModal(true);
      setNeedSimilarContactsModal(duplicates);
    } else if (val) {
      /* Logic to archive contact */
      const contactIds =
        allParams && allParams?.enabled
          ? []
          : Object.keys(selectedThreads).map((id) => parseInt(id));
      const workflowId = `${singleContactData.id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      setWorkflowId(workflowId);
      const undoData = {
        companyId,
        contactIds,
        groupIds: [],
        primaryAssignees: {},
        containDuplicates: false,
        workflowId,
        needRouting,
        viewButton:
          isAddingUndoWithRedirect && contactIds.length === 1
            ? {
                redirectUrl: `/hub/people/filter/archived/contact/${contactIds[0]}/about`,
              }
            : undefined,
        all: allParams,
      };
      setUndoPayload(undoData);
      onArchiveContacts(
        companyId,
        contactIds,
        [],
        {},
        false,
        workflowId,
        needRouting,
        false,
        allParams
      );
      setGlobalState(true);
    } else {
      onArchiveComplete();
    }
  };

  const proceedArchive = (val, duplicates, archiveContacts = 0) => {
    setOpenConfirmationModal(false);
    setConfirmationModalHeader("");
    setConfirmationModalContent("");
    setNeedSimilarContactsModal(false);

    if (group) {
      proceedArchiveForGroup(val, archiveContacts);
    } else {
      if (!duplicates)
        proceedArchiveForContact(
          val,
          isGroupAllMembersSelected
            ? false
            : doSelectedThreadsContainDuplicates(selectedThreads)
        );
    }
  };

  const finalizeArchiveForGroup = (primaryAssignees, selectedList) => {
    const workflowId = `${singleGroupData.id}-${
      Math.floor(Math.random() * 50) + 1
    }`;
    setWorkflowId(workflowId);
    const groupIds = Object.keys(selectedGroups);
    const undoData = {
      companyId,
      groupIds,
      contactIds: selectedList,
      primaryAssignees,
      containDuplicates: true,
      workflowId,
      needRouting,
      contactsAlso: 1,
      all: allParams,
    };
    setUndoPayload(undoData);
    onArchiveGroups(
      companyId,
      groupIds,
      primaryAssignees,
      true,
      1,
      workflowId,
      needRouting,
      selectedList,
      false,
      isGroupTag,
      allParams
    );
    setGlobalState(true);
  };

  const finalizeArchiveForContacts = (primaryAssignees, selectedList) => {
    const workflowId = `${singleContactData.id}-${
      Math.floor(Math.random() * 50) + 1
    }`;
    setWorkflowId(workflowId);
    const undoData = {
      companyId,
      contactIds: selectedList,
      groupIds: [],
      primaryAssignees: {},
      containDuplicates: true,
      workflowId,
      needRouting,
      isUndo: false,
      all: allParams,
    };
    setUndoPayload(undoData);
    onArchiveContacts(
      companyId,
      selectedList,
      [],
      primaryAssignees,
      true,
      workflowId,
      needRouting,
      false,
      allParams
    );
    setGlobalState(true);
  };

  const finalizeArchive = (val, selectedList, primaryAssignees) => {
    setSimilarContactsModal(false);
    setNeedSimilarContactsModal(false);
    if (val) {
      if (group) {
        return finalizeArchiveForGroup(primaryAssignees, selectedList);
      } else {
        return finalizeArchiveForContacts(primaryAssignees, selectedList);
      }
    }
    setGlobalState(true);
    onArchiveComplete();
  };

  const toggleUndoSnackbar = (val) => (failure) => {
    let msg;
    if (failure) {
      if (archivedContactsPathname) {
        msg =
          "Archiving contacts is in progress. Please wait for the process to complete.";
      } else if (confirmationModal) {
        msg = `${singleContactData.first_name} ${singleContactData.last_name} couldn't be archived`;
      } else {
        msg = `Error: Selected contacts couldn't be archived`;
      }
    } else {
      if ((archivedSuccessContacts?.length > 1) & failedToArchiveNumber) {
        msg = `You successfully archived ${archivedSuccessContacts.length} People ${failedToArchiveNumber} people needs to be manually archived.`;
      } else if (archivedSuccessContacts.length > 1) {
        msg = `You archived ${archivedSuccessContacts.length} People.`;
      } else if (archivedSuccessContacts.length === 1 && singleContactData) {
        const label =
          singleContactData.first_name || singleContactData.last_name
            ? `${singleContactData.first_name} ${singleContactData.last_name}`
            : singleContactData.phone_number;
        msg = `You archived ${label}`;
      } else {
        msg = `You archived ${archivedSuccessContacts.length} People.`;
      }
    }
    if (val) {
      let data = {};
      if (archivedContactsPathname) {
        data = {
          id: workflowId,
          err: false,
          msg,
        };
      } else {
        data = {
          id: workflowId,
          err: failure,
          msg,
          undoPayload,
        };
      }
      setSnackData(data, "archive-contact");
    }

    if (onArchiveComplete) {
      onArchiveComplete();
    }
  };

  const toggleGroupUndoSnackbar = (val) => (failure) => {
    let msg;
    let msgMembers;
    const groupIds = Object.keys(selectedGroups);
    if (failure) {
      if (groupIds.length === 1) {
        msg = `${groupName(singleGroupData)} couldn't be archived`;
      } else {
        msg = `Error: Selected groups couldn't be archived`;
      }
    } else {
      if (archivedSuccessGroups.length > 1) {
        msg = `You archived ${archivedSuccessGroups.length} Groups.`;
      } else if (archivedSuccessGroups.length === 1 && singleGroupData) {
        msg = `You archived ${groupName(singleGroupData)}.`;
      } else {
        msg = `You archived ${archivedSuccessGroups.length}.`;
      }
      if (archivedContactsInGroups.length > 1) {
        msgMembers = `${archivedContactsInGroups.length} People have been archived.`;
      } else if (archivedContactsInGroups.length === 1) {
        msgMembers = `${archivedContactsInGroups.length} Person has been archived.`;
      }
    }
    if (val) {
      const data = {
        id: workflowId,
        err: failure,
        msg,
        undoPayload,
      };
      if (archivedContactsInGroups.length >= 1) {
        data.extraSnackData = [
          {
            msg: msgMembers,
            id: workflowId,
            undoPayload: {
              companyId,
              contactIds: archivedContactsInGroups,
              primaryAssignees: data.undoPayload.primaryAssignees,
              containDuplicates: data.undoPayload.containDuplicates,
            },
          },
        ];
      }
      setGroupSnackData(data, "archive-group");
    }

    if (onArchiveComplete) {
      onArchiveComplete();
    }
  };

  const toggleInfoSnackbar = (val) => () => {
    setInfoSnackbar(val);
  };

  const handleInfoSnack = (callerAction) => {
    window.localStorage.setItem("archive-warning-hide", 1);
    if (callerAction) {
      callerAction();
    }
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          show={openConfirmationModal}
          modalBodyContent={confirmationModalContent}
          onClose={proceedArchive} // for other actions we need to check in callback which action to proceed
          modalBodyHeader={confirmationModalHeader}
          isGroup={group}
          footerText="Also archive all People in this Group"
          isDescriptionTextVisible={descriptionTextVisible}
          descriptionText={descriptionText}
        />
      )}
      {similarContactsModal && (
        <SimilarContactsModal
          show={similarContactsModal}
          modalBodyContent={
            "We found multiple contacts using that number. Select the ones you want to archive."
          }
          modalBodyHeader={"Archive People"}
          onClose={finalizeArchive}
          sameActionPrefix="Archive"
          contactDuplicates={needSimilarContactsModal}
          isGroup={group}
        />
      )}
      <Snackbar
        open={infoSnackbar}
        onClose={toggleInfoSnackbar(false)}
        message="You are archiving the following People. They will be removed from scheduled messages and won’t receive any texts until they are unarchived."
        handleAction={handleInfoSnack}
        actionTitle="Don’t show again"
        longText
        position={getSnackbarPosition(breakpoint)}
      />
    </>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Archive)
);
