import React, { useContext } from "react";
import HeaderButton from "./HeaderButton";
import { ReactComponent as MessageIcon } from "../../../assets/img/icons-new/main-nav-v2/messages/messages-purple.svg";
import { ReactComponent as CloseSvg } from "../../../assets/img/icons/close.svg";
import clsx from "clsx";
import ThemeContext from "../../../ThemeContext/ThemeContext";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import { Chip } from "@material-ui/core";
import BasicMessageCreatorSwitcher from "./BasicMessageCreatorSwitcher";

const SendNewMessageHeader = (props) => {
  const breakpoint = useBreakpoint();
  const { buttons, credits } = props;
  const { globalTheme } = useContext(ThemeContext);

  return (
    <div className="send-message-header py-3 pt-sm-4 pb-sm-0 px-3 px-sm-5 w-100">
      <div className="d-flex justify-content-between align-items-center">
        <CloseSvg className="d-block d-sm-none" height={16} width={16} />

        <div className="send-message-header__title flexer-row mb-sm-3">
          <MessageIcon
            className={clsx("send-message-header__icon", globalTheme)}
            height={isMdOrBelowBreakpoint(breakpoint) ? 25 : 20}
            width={isMdOrBelowBreakpoint(breakpoint) ? 25 : 20}
          />
          <span className="h4 m-0 ml-2 font-weight-normal">
            {isMdOrBelowBreakpoint(breakpoint)
              ? "New"
              : "Send New Text Broadcast"}
          </span>
          <Chip
            label="Beta"
            classes={{
              root: "send-new-message-beta-chip mb-2",
              label: "send-new-message-beta-chip-label",
            }}
          />
          {!isMdOrBelowBreakpoint(breakpoint) && (
            <BasicMessageCreatorSwitcher
              className={"desktop-old-message-creator-switcher"}
            />
          )}
        </div>

        <div className="flexer-row align-items-start">
          {buttons.map((button, index) => (
            <HeaderButton key={index} credits={credits} {...button} />
          ))}
        </div>
      </div>
    </div>
  );
};

SendNewMessageHeader.propTypes = {};

SendNewMessageHeader.defaultProps = {};

export default SendNewMessageHeader;
