import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  clearDeleteContact,
  setFilter,
  toggleDeleteContactModal,
} from "../../actions/contacts";
import UnarchiveWorkflow from "../hub/ActionsWorkflows/Unarchive";
import DeleteContactModal from "../modals/DeleteContactModal";
import { history } from "../../store";
import ThreadsActionsPopover from "./ThreadsActionsPopover";
import { toggleGroupFromList } from "../../actions/groups";
import { useActionMenus } from "../../utils/hooks/useActionMenus";
import { getPermissionParsed } from "../../utils/settingsHelpers";

function mapStateToProps(store, ownProps) {
  return {
    isDeleteContactModal: store.contacts.isDeleteContactModal,
    deleteStatus: store.contacts.deleteStatus,
    userPermissions: store.users.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDeleteContactModal: (
      value,
      contact,
      contactToDeleteIds,
      isBatchDelete
    ) =>
      dispatch(
        toggleDeleteContactModal(
          value,
          contact,
          contactToDeleteIds,
          isBatchDelete
        )
      ),
    clearDeleteContact: () => dispatch(clearDeleteContact()),
    setFilter: (filter) => dispatch(setFilter(filter)),
    saveIdsForModal: (selectedThreads) =>
      dispatch(toggleGroupFromList(true, selectedThreads)),
  };
}

const ThreadsActionsArchivedPeople = (props) => {
  const {
    contact,
    showVerticalMenu,
    id,
    className,
    unarchiveProps,
    showDeleteButton,
    groupId,
    shouldRedirectArchiveAction,
    useUnarchiveModal,
    //Redux props
    isDeleteContactModal,
    filter,
    deleteStatus,
    userPermissions,
    // Redux func
    toggleDeleteContactModal,
    clearDeleteContact,
    setFilter,
    saveIdsForModal,
  } = props;

  const [unarchiveThis, setUnarchiveThis] = useState(false);
  const { isOpen, onClickMenu, toggle } = useActionMenus();

  useEffect(() => {
    if (deleteStatus && deleteStatus.startsWith("success")) {
      toggleDeleteContactModal(false, contact, [], false);
      clearDeleteContact();
    }
  }, [
    clearDeleteContact,
    contact,
    contact.id,
    deleteStatus,
    toggleDeleteContactModal,
  ]);

  const onUnArchiveComplete = () => {
    if (shouldRedirectArchiveAction) {
      history.push(`/hub/people/contact/${contact.id}/about`);
      setFilter(null);
    }
    setUnarchiveThis(false);
  };

  const unarchiveDefaultProps = useMemo(
    () => ({
      selectedThreads: { [contact.id]: contact },
      selectedSubThreads: {},
      selectedGroups: {},
      confirmationModal: true,
      ...unarchiveProps,
    }),
    [contact, unarchiveProps]
  );

  const handleContactPopover = () => {
    toggleDeleteContactModal(true, contact, [], false);
  };

  const handleUnArchived = useCallback(() => {
    if (useUnarchiveModal) {
      saveIdsForModal({
        ...unarchiveDefaultProps,
        runWorkflow: true,
      });
      history.push("#modal-unarchive");
    } else {
      setUnarchiveThis(true);
    }
  }, [saveIdsForModal, unarchiveDefaultProps, useUnarchiveModal]);

  const onCloseDeleteModal = () => {
    if (shouldRedirectArchiveAction)
      history.push(`/hub/people${filter ? `/filter/${filter}` : ""}`);
    toggleDeleteContactModal(false, contact, [], false);
  };

  const onDeleteClick = () => {
    if (shouldRedirectArchiveAction)
      history.push(`/hub/people${filter ? `/filter/${filter}` : ""}`);
  };

  const renderActionPopover = () => {
    return (
      <ThreadsActionsPopover
        showVerticalMenu={showVerticalMenu}
        id={id}
        isOpen={isOpen}
        toggle={toggle}
        isActionsMenu={true}
        menuItems={
          <>
            {getPermissionParsed(userPermissions?.people, "archive") && (
              <button onClick={onClickMenu(handleUnArchived)}>Unarchive</button>
            )}
            {showDeleteButton &&
              getPermissionParsed(userPermissions?.people, "delete") && (
                <button
                  className="marked-warning"
                  onClick={onClickMenu(handleContactPopover)}
                >
                  Delete
                </button>
              )}
          </>
        }
      />
    );
  };

  return (
    <>
      <div className={`lhs-archive-popover ${className}`}>
        {renderActionPopover()}
      </div>
      <div onClick={(event) => event.stopPropagation()}>
        {unarchiveThis && (
          <UnarchiveWorkflow
            onUnarchiveComplete={onUnArchiveComplete}
            runWorkflow={unarchiveThis}
            {...unarchiveDefaultProps}
          />
        )}
        {isDeleteContactModal && (
          <DeleteContactModal
            show={isDeleteContactModal}
            closeModal={onCloseDeleteModal}
            groupId={groupId}
            onDeleteClick={onDeleteClick}
          />
        )}
      </div>
    </>
  );
};

ThreadsActionsArchivedPeople.propTypes = {
  showVerticalMenu: PropTypes.bool,
  contact: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  unarchiveProps: PropTypes.objectOf(PropTypes.any),
  showDeleteButton: PropTypes.bool,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldRedirectArchiveAction: PropTypes.bool,
  useUnarchiveModal: PropTypes.bool,
};

ThreadsActionsArchivedPeople.defaultProps = {
  showVerticalMenu: true,
  id: "lhs-archived-people-actions-popover",
  className: "",
  unarchiveProps: {},
  showDeleteButton: true,
  shouldRedirectArchiveAction: false,
  useUnarchiveModal: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreadsActionsArchivedPeople);
