import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as settingActions from "../../../actions/settings";

export function useSettingsStore() {
  const _settings = useSelector((store) => store.settings);
  const dispatch = useDispatch();

  const fetchSettings = useCallback(
    async () => await dispatch(settingActions.fetchSettings()),
    [dispatch]
  );

  return {
    settings: _settings,
    fetchSettings,
  };
}

export default useSettingsStore;
