import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  clearSelection as clearSelectionAction,
  closeThreads,
  addSnackData,
  fetchClosedThreads,
  fetchCounts,
  fetchVoiceCounts,
} from "../../../actions/threads";
import { getPluralFromNumber, groupName } from "../../../helpers";
import { withRouter } from "react-router-dom";

function mapStateToProps(store) {
  return {
    companyId: store.companies.currentCompany.id,
    closeThreadsStatus: store.threads.closeThreadsStatus,
    closedSuccessThreads: store.threads.closedSuccessThreads,
    numbersFilter: store.numbers.numbersFilter,
    // unarchiveGroupsStatus: store.groups.unarchiveGroupsStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSelection: () => dispatch(clearSelectionAction(true)),
    onCloseThreads: (
      companyId,
      contactIds,
      groupIds,
      workflowId,
      needRouting
    ) =>
      dispatch(
        closeThreads(companyId, contactIds, groupIds, workflowId, needRouting)
      ),
    setSnackData: (data, entity) => dispatch(addSnackData(data, entity)),
    fetchClosedThreads: (companyId, numbersFilter, page, filter, type) =>
      dispatch(
        fetchClosedThreads(companyId, numbersFilter, page, filter, type)
      ),
    fetchThreadsCounts: (companyId, numbersFilterValue, fields) =>
      dispatch(fetchCounts(companyId, numbersFilterValue, fields)),
    fetchVoiceCounts: (companyId, numbersFilterValue, fields) =>
      dispatch(fetchVoiceCounts(companyId, numbersFilterValue, fields)),
  };
}

const Close = ({
  companyId,
  closeThreadsStatus,
  closedSuccessThreads,
  clearSelection,
  onCloseThreads,
  setSnackData,
  fetchThreadsCounts,
  fetchVoiceCounts,
  /* Props from parent */
  runWorkflow,
  selectedThreads,
  selectedGroups,
  selectedContacts,
  onCloseComplete,
  needRouting,
  group,
  numbersFilter,
  fetchClosedThreads,
  type,
  countThreads,
  // router
  match,
}) => {
  const [singleThreadData, setSingleThreadData] = useState({});
  const [workflowId, setWorkflowId] = useState();
  const [undoPayload, setUndoPayload] = useState();

  useEffect(() => {
    if (runWorkflow) {
      proceedClose();
    }
    // eslint-disable-next-line
  }, [runWorkflow]);

  useEffect(() => {
    if (closeThreadsStatus === `success-${workflowId}`) {
      toggleSnackbar(true)();
      fetchClosedThreads(companyId, numbersFilter, 1, "closed", type);
    } else if (closeThreadsStatus === `failure-${workflowId}`) {
      toggleSnackbar(true)(true);
    }

    // eslint-disable-next-line
  }, [closeThreadsStatus]);

  const proceedClose = () => {
    const { groupIds, contactIds, workflowId } = parseData();
    onCloseThreads(
      companyId,
      contactIds,
      groupIds,
      workflowId,
      needRouting
    ).then(() => {
      const { tab, filter } = match.params;
      if (tab !== "calls") {
        fetchThreadsCounts(
          companyId,
          numbersFilter,
          filter ? ["closed", filter] : ["all", "closed"]
        );
      } else {
        fetchVoiceCounts(
          companyId,
          numbersFilter,
          filter
            ? ["missed-unread", filter]
            : ["missed-unread", "all", "closed"]
        );
      }
    });
  };

  const parseData = () => {
    if (selectedThreads) {
      const threadKeys = Object.keys(selectedThreads).map((id) => parseInt(id));
      const workflowId = `${selectedThreads[threadKeys[0]].id}-${
        Math.floor(Math.random() * 50) + 1
      }`;
      const contactIds = [];
      const groupIds = [];
      const undoPayload = {
        companyId,
        groupIds,
        contactIds,
        workflowId,
        needRouting,
      };
      if (group) {
        groupIds.push(...threadKeys);
      } else {
        contactIds.push(...threadKeys);
      }
      undoPayload.groupIds = groupIds;
      undoPayload.contactIds = contactIds;
      if (onCloseComplete) {
        undoPayload.onComplete = onCloseComplete;
      }
      setWorkflowId(workflowId);
      setSingleThreadData(selectedThreads[threadKeys[0]]);
      setUndoPayload(undoPayload);
      return { groupIds, contactIds, workflowId };
    } else if (selectedGroups || selectedContacts) {
      let workflowId = ``;
      const contactIds = [];
      const groupIds = [];
      const undoPayload = {
        companyId,
        groupIds,
        contactIds,
        workflowId,
        needRouting,
      };
      if (selectedGroups && Object.keys(selectedGroups).length > 0) {
        const threadKeys = Object.keys(selectedGroups).map((id) =>
          parseInt(id)
        );
        workflowId = `${selectedGroups[threadKeys[0]].id}-${
          Math.floor(Math.random() * 50) + 1
        }`;
        groupIds.push(...threadKeys);
        setSingleThreadData(selectedGroups[threadKeys[0]]);
      }
      if (selectedContacts && Object.keys(selectedContacts).length > 0) {
        const threadKeys = Object.keys(selectedContacts).map((id) =>
          parseInt(id)
        );
        if (!workflowId) {
          workflowId = `${selectedContacts[threadKeys[0]].id}-${
            Math.floor(Math.random() * 50) + 1
          }`;
        }
        contactIds.push(...threadKeys);
        setSingleThreadData(selectedContacts[threadKeys[0]]);
      }
      undoPayload.groupIds = groupIds;
      undoPayload.contactIds = contactIds;
      undoPayload.workflowId = workflowId;
      if (onCloseComplete) {
        undoPayload.onComplete = onCloseComplete;
      }
      setWorkflowId(workflowId);
      setUndoPayload(undoPayload);
      return {
        groupIds,
        contactIds,
        workflowId,
      };
    }
  };

  const toggleSnackbar = (val) => (failure) => {
    let msg;
    if (failure) {
      let label;
      if (group) {
        label = groupName(singleThreadData);
      } else {
        label =
          singleThreadData.first_name || singleThreadData.last_name
            ? `${singleThreadData.first_name} ${singleThreadData.last_name}`
            : singleThreadData.phone_number;
      }
      msg = `Error: Conversation with ${label} couldn't be closed`;
    } else {
      msg = `You closed ${getPluralFromNumber(countThreads, "conversation")}`;
    }
    if (val) {
      const data = {
        id: workflowId,
        err: failure,
        msg,
        undoPayload,
      };
      setSnackData(data, "close");
    }
    clearSelection();
  };

  return <></>;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Close));
