import { useMemo } from "react";
import useCompaniesStore from "./ReduxHooks/companiesStore";

export const useIsOwner = () => {
  const {
    companies: { currentCompany },
  } = useCompaniesStore();
  const isOwner = useMemo(
    () => currentCompany?.is_main_owner || !!currentCompany?.invited_owner,
    [currentCompany]
  );
  return isOwner;
};
