import React from "react";
import PropTypes from "prop-types";
import "../../../assets/components_react/MergeFields.scss";
import ModalWrapper from "../../modals/Helpers/ModalWrapper";
import { Divider } from "@material-ui/core";

import {
  USER_MERGE_FIELDS,
  CONTACT_MERGE_FIELDS,
  ORGANIZATION_MERGE_FIELDS,
} from "../../../utils/enums/threadEnums";

const MergeField = (props) => {
  const { text, onClick, withoutBrackets } = props;

  return (
    <div
      className="merge-field"
      onClick={() => onClick(withoutBrackets ? text : `[${text}]`)}
    >
      [{text}]
    </div>
  );
};

MergeField.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  withoutBrackets: PropTypes.bool,
};

MergeField.defaultProps = {
  withoutBrackets: false,
};

const MergeFields = (props) => {
  const {
    onClickMergeField,
    className,
    organizationAdditionalMergeFields,
    show,
    closeModal,
  } = props;

  const allOrganizationMergeFields = [
    ...ORGANIZATION_MERGE_FIELDS,
    ...organizationAdditionalMergeFields,
  ];

  return (
    <ModalWrapper
      show={show}
      closeModal={closeModal}
      className="merge-fields-wrapper"
      id="merge-fields-modal"
    >
      <div className="title">Select Merge Fields</div>
      <div className="description">
        Click your desired Merge Fields to insert them in chat box
      </div>
      <div className="merge-field-category">By Contact Info</div>
      <div className={`d-flex flex-wrap ${className}`}>
        {CONTACT_MERGE_FIELDS.map((mergeField) => (
          <MergeField
            key={mergeField.text}
            text={mergeField.text}
            withoutBrackets={mergeField.withoutBrackets}
            onClick={(text) => {
              onClickMergeField(text);
            }}
          />
        ))}
      </div>
      <Divider className="my-4" />
      <div className="merge-field-category">By User Info (You)</div>
      <div className={`d-flex flex-wrap ${className} mb-4`}>
        {USER_MERGE_FIELDS.map((mergeField) => (
          <MergeField
            key={mergeField.text}
            text={mergeField.text}
            withoutBrackets={mergeField.withoutBrackets}
            onClick={(text) => {
              onClickMergeField(text);
            }}
          />
        ))}
      </div>

      <>
        <Divider />
        <div className="merge-field-category mt-4">By Organization Info</div>
        <div className={`d-flex flex-wrap ${className} mb-4`}>
          {allOrganizationMergeFields.map((mergeField) => (
            <MergeField
              key={mergeField.text}
              text={mergeField.text}
              withoutBrackets={mergeField.withoutBrackets}
              onClick={(text) => {
                onClickMergeField(text);
              }}
            />
          ))}
        </div>
        <div className="close-modal-button">
          <button className="btn btn-primary" onClick={closeModal}>
            Done
          </button>
        </div>
      </>
    </ModalWrapper>
  );
};

MergeFields.propTypes = {
  onClickMergeField: PropTypes.func.isRequired,
  className: PropTypes.string,
  organizationAdditionalMergeFields: PropTypes.arrayOf(PropTypes.any),
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

MergeFields.defaultProps = {
  className: "",
  organizationAdditionalMergeFields: [],
  show: false,
};

export default MergeFields;
