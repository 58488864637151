import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useWithHover } from "../../utils/hooks/useWithHover";
import "../../assets/base/_global-theme.scss";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #c9cecd",
    borderRadius: 5,
    padding: "8px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    color: "#777777",
    fontWeight: 500,
    cursor: "pointer",
  },
  containerHover: {
    border: "1px solid #6C37C9",
    boxShadow: "0px 0px 6px #D796FF",
    color: "#202020",
    // themed() {
    //   color: t("fontColor");
    // },
  },
  error: {
    border: "1px solid #ff5800",
    boxShadow: "0px 0px 6px #ff5800",
  },
  containerDisabled: {
    cursor: "default",
  },
  disabled: {
    opacity: 0.35,
  },
}));

const ButtonIconTextCard = (props) => {
  const {
    width,
    minHeight,
    icon,
    iconHover,
    text,
    disabled,
    className,
    onClick,
    active,
    hideHover,
    isError,
  } = props;

  const classes = useStyles();

  const { hoverActive, onMouseEnter, onMouseLeave } = useWithHover();

  const getContainerClassName = useCallback(() => {
    if (disabled) {
      return classes.containerDisabled;
    }

    if (isError) {
      return classes.error;
    }

    if ((!disabled && hoverActive && !hideHover) || active) {
      return classes.containerHover;
    }

    return "";
  }, [active, classes, disabled, hideHover, hoverActive, isError]);

  return (
    <div
      className={`${classes.container} ${getContainerClassName()} ${className}`}
      style={{ width, minHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={disabled ? undefined : onClick}
    >
      {icon && (
        <div className={`mr-1 ${disabled ? classes.disabled : ""}`}>
          {hoverActive && !disabled ? iconHover || icon : icon}
        </div>
      )}
      <div className={disabled ? classes.disabled : ""}>{text}</div>
    </div>
  );
};

ButtonIconTextCard.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  iconHover: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
  hideHover: PropTypes.bool,
  isError: PropTypes.bool,
};

ButtonIconTextCard.defaultProps = {
  width: 120,
  disabled: false,
  minHeight: "none",
  active: false,
  hideHover: false,
  isError: false,
};

export default ButtonIconTextCard;
