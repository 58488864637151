import { useMemo } from "react";
import useCompaniesStore from "./ReduxHooks/companiesStore";
import useUsersStore from "./ReduxHooks/userStore";
import { useIsAdminOrOwner } from "./useIsAdminOrOwner";
import { getAccountIssueInfo } from "../accountHelpers";

export const useAccountIssueInfo = () => {
  const {
    companies: { currentCompany },
  } = useCompaniesStore();
  const {
    users: { credits },
  } = useUsersStore();
  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);

  const accountIssueInfo = useMemo(() => {
    const {
      is_billing_issue: isBillingIssue,
      is_suspended: isSuspended,
      number_is_deleted: numberIsDeleted,
      package: billingPackage,
      billing_issue_days: billingIssueDays,
    } = currentCompany || {};
    return getAccountIssueInfo(
      isBillingIssue,
      isSuspended,
      numberIsDeleted,
      billingPackage,
      credits,
      billingIssueDays,
      isAdminOrOwner
    );
  }, [currentCompany, credits, isAdminOrOwner]);
  return accountIssueInfo;
};
