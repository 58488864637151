import { useMemo } from "react";
import useUsersStore from "./ReduxHooks/userStore";
import { parseUnpinnedMenusAndFilter } from "../filtersMenusHelper";

export const useUnpinnedMenusAndFilters = () => {
  // Redux store
  const { users } = useUsersStore();
  const { loggedUser } = users;

  const unpinnedMenus = useMemo(() => {
    const unpinnedMenus = parseUnpinnedMenusAndFilter(
      loggedUser?.unpinned_menus
    );
    return unpinnedMenus;
  }, [loggedUser]);

  const unpinnedFilters = useMemo(() => {
    const unpinnedFilters = parseUnpinnedMenusAndFilter(
      loggedUser?.unpinned_filters
    );
    return unpinnedFilters;
  }, [loggedUser]);

  return {
    // Unpinned menus
    sidebarTopUnpinnedMenus: unpinnedMenus?.sidebarTopUnpinnedMenus || [],
    sidebarBottomUnpinnedMenus: unpinnedMenus?.sidebarBottomUnpinnedMenus || [],
    // Unpinned filters
    groupTabUnpinnedFilters: unpinnedFilters?.groupTabUnpinnedFilters || [],
    messageTabUnpinnedFilters: unpinnedFilters?.messageTabUnpinnedFilters || [],
    peopleTabUnpinnedFilters: unpinnedFilters?.peopleTabUnpinnedFilters || [],
    textTabUnpinnedFilters: unpinnedFilters?.textTabUnpinnedFilters || [],
    voiceTabUnpinnedFilters: unpinnedFilters?.voiceTabUnpinnedFilters || [],
    parsedUnpinnedMenus: unpinnedMenus,
    parsedUnpinnedFilters: unpinnedFilters,
  };
};
