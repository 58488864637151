import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../store";
import { ModalsContext } from "../../ModalsContext";

import { fetchContactData } from "../../actions/contacts";
import { checkAccount, fetchVoicePrice } from "../../actions/calls";

//new icon
import { ReactComponent as CloseSvg } from "../../assets/img/icons-new/general/close/close-gray.svg";
import { ReactComponent as MinimizeSvg } from "../../assets/img/icons-new/general/minimize/minimize-black.svg";
import { ReactComponent as CallIcon } from "../../assets/img/icons-new/voice-tab/all-voice-message/all-voice-message-white.svg";

import Avatar from "../Avatar";
import UnsupportedBrowserModal from "./UnsupportedBrowserModal";
import { contactsName, formatDuration, formatPhoneNumber } from "../../helpers";
import SpanWithTooltip from "../SpanWithTooltip";
import TwilioConfig from "../../TwilioConfig";
import LongNamesModal from "./LongNamesModal";
import { MICROPHONE_PERMISIONS_NOT_GRANTED } from "../../utils/callConstants";

function mapStateToProps(store, ownProps) {
  const contactId = parseInt(ownProps.param);
  let contactData = null;
  let contactStatus = null;

  if (contactId) {
    let contact =
      typeof store.contacts.data[contactId] === "undefined"
        ? null
        : store.contacts.data[contactId];
    if (contact) {
      contactData = [];
      if (contact.contacts_ids) {
        for (let i = 0; i < contact.contacts_ids.length; i++) {
          if (
            typeof store.contacts.data[contact.contacts_ids[i]] !== "undefined"
          ) {
            contactData.push(store.contacts.data[contact.contacts_ids[i]]);
          }
        }
      }
    }
    contactStatus =
      typeof store.contacts.dataStatus[contactId] === "undefined"
        ? null
        : store.contacts.dataStatus[contactId];
  }

  let voicePrice = null;
  let voicePriceStatus = null;
  if (contactData && contactData.length) {
    const countryId = contactData[0].country_id;
    voicePrice =
      typeof store.calls.voicePriceByCountry[countryId] !== "undefined"
        ? store.calls.voicePriceByCountry[countryId]
        : null;
    voicePriceStatus =
      typeof store.calls.voicePriceByCountryStatus[countryId] !== "undefined"
        ? store.calls.voicePriceByCountryStatus[countryId]
        : null;
  }

  let callStatus = null;
  let callFrom = null;
  let callTime = null;
  let callStartedAt = null;
  let callMinutePrice = null;
  if (store.twilio.callContactId === contactId) {
    callStatus = store.twilio.callStatus;
    callFrom = store.twilio.callFrom;
    callTime = store.twilio.callTime;
    callStartedAt = store.twilio.callStartedAt;
    callMinutePrice = store.twilio.callMinutePrice;
  }

  return {
    companyId: store.companies.currentCompany.id,
    unsupportedBrowser: store.twilio.unsupportedBrowser,
    contactId: contactId,
    contactData: contactData,
    contactStatus: contactStatus,
    numbers: store.numbers.numbers,
    checkAccountResult: store.calls.checkAccount,
    checkAccountStatus: store.calls.checkAccountStatus,
    voicePrice: voicePrice,
    voicePriceStatus: voicePriceStatus,
    twilioReady: store.twilio.ready,
    callStatus: callStatus,
    callFrom: callFrom,
    callTime: callTime,
    callStartedAt: callStartedAt,
    callMinutePrice: callMinutePrice,
    twilioCallStatus: store.twilio.callStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactData: (companyId, contactId) =>
      dispatch(fetchContactData(companyId, contactId)),
    checkAccount: (companyId, contactId) =>
      dispatch(checkAccount(companyId, contactId)),
    fetchVoicePrice: (companyId, countryId) =>
      dispatch(fetchVoicePrice(companyId, countryId)),
  };
}

class CallContactModal extends React.Component {
  static contextType = ModalsContext;

  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.refreshContactTimeout = null;
    this.fromRef = React.createRef();
    this.updateView = this.updateView.bind(this);
    this.updateData = this.updateData.bind(this);
    this.close = this.close.bind(this);
    this.call = this.call.bind(this);
    this.decline = this.decline.bind(this);
    this.addTagsToUrl = this.addTagsToUrl.bind(this);

    this.state = {
      isShown: false,
      isHide: false,
      time: 0,
      spent: 0,
      longNameModalContent: "",
      showLongNameModal: false,
      isClosing: false,
      openUnsupportedMicrophoneModal: false,
    };
  }

  componentDidMount() {
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    const { twilioCallStatus, history } = this.props;
    if (
      prevProps.show !== this.props.show ||
      this.state.isShown !== prevState.isShown ||
      this.state.isHide !== prevState.isHide
    ) {
      this.updateView();
    }
    const currentOpen = this.state.isShown && !this.state.isHide;
    const prevOpen = prevState.isShown && !prevState.isHide;
    if (currentOpen !== prevOpen && currentOpen) {
      this.props.checkAccount(this.props.companyId, this.props.contactId);
    }

    if (
      !this.state.isClosing &&
      currentOpen &&
      this.props.contactStatus == null
    ) {
      this.props.fetchContactData(this.props.companyId, this.props.contactId);
    }
    if (
      currentOpen &&
      this.props.contactStatus === "success" &&
      this.props.voicePriceStatus == null
    ) {
      this.props.fetchVoicePrice(
        this.props.companyId,
        this.props.contactData[0].country_id
      );
    }
    if (
      this.props.callStatus === "connected" &&
      prevProps.callStatus !== "connected"
    ) {
      this.timeInterval = setInterval(this.updateData, 1000);
    }

    // Refresh contact data after failed call (to check number is unformatted)
    if (
      this.props.callStatus !== "connected" &&
      prevProps.callStatus === "connected" &&
      this.state.time < 5
    ) {
      clearTimeout(this.refreshContactTimeout);
      this.refreshContactTimeout = setTimeout(() => {
        this.props.fetchContactData(this.props.companyId, this.props.contactId);
      }, 2000);
    }

    if (currentOpen && twilioCallStatus === "connected") {
      history.push("#modal-call-in-progress");
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  updateView() {
    this.setState({ isClosing: false });

    if (this.props.show && !this.state.isShown) {
      setTimeout(() => {
        if (!this) {
          return;
        }
        this.setState({ isShown: true, isHide: false });
      }, 0);
    }
    if (!this.props.show && this.state.isShown) {
      if (!this) {
        return;
      }
      this.setState({ isHide: true });
      setTimeout(() => {
        this.setState({ isShown: false });
      }, 200);
    }
  }

  updateData() {
    const time = Math.ceil(
      (new Date().getTime() - this.props.callStartedAt) / 1000
    );
    const spent = Math.ceil(time / 60) * this.props.callMinutePrice;
    this.setState({
      time: time,
      spent: spent,
    });
  }

  close(e) {
    e.preventDefault();
    this.setState({ isClosing: true }, this.addTagsToUrl);
  }
  addTagsToUrl() {
    const urlHash = this.props.location.hash || "";
    const pathname = this.props.location.pathname;

    if (urlHash.endsWith("/?new")) {
      // It the request to open a modal for new user then on close, remove the contact ID from url path
      let newPathnameArray = pathname.split("/");
      newPathnameArray.pop();
      const newPathname = newPathnameArray.join("/");

      // in case of new user Add a ?refresh tag to url for HubVoiceList componentDidUpdate to
      // know to fetch update voiceThreadList
      history.push(`${newPathname}/?refresh`);
    } else {
      // in case of Existing user Add a ?refreshExisting tag to url for HubVoiceList componentDidUpdate to
      // know to fetch update voiceThreadList but keep the existing user selected
      history.push(`${pathname}?refreshExisting`);
    }
  }

  call(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    if (this.props.voicePriceStatus === "success") {
      TwilioConfig.callContact(
        this.fromRef.current.value,
        this.props.contactId,
        this.props.contactData[0].phone_number,
        this.props.voicePrice
      );
      history.push(window.location.pathname);
    }
  }

  decline(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    TwilioConfig.disconnect();
  }

  toggleLongNameModal = () => {
    this.setState({ showLongNameModal: !this.state.showLongNameModal });
  };

  handleLongName = (threadContactName) => {
    if (window.innerWidth < 600 && threadContactName?.length >= 16) {
      this.setState({ longNameModalContent: threadContactName });
      this.toggleLongNameModal();
    }
  };

  render() {
    const { unsupportedBrowser, contactData } = this.props;
    const { showLongNameModal, longNameModalContent } = this.state;

    if (unsupportedBrowser) {
      if (this.state.isShown && !this.state.isHide) {
        return (
          <UnsupportedBrowserModal
            mode="microphone"
            show
            closeModal={this.close}
            customMicrohphoneMessage={MICROPHONE_PERMISIONS_NOT_GRANTED}
          />
        );
      }
      return null;
    }

    const unformattedNumber =
      contactData &&
      contactData.length > 0 &&
      contactData[0].number_lookup &&
      !contactData[0].number_lookup.type;

    const disablePhoneButton =
      this.props.voicePriceStatus === "loading" ||
      (contactData?.length > 0 && contactData[0]?.status === 1);

    let content = <p className={"text-center text-muted"}>Loading...</p>;
    if (
      this.props.contactData &&
      this.props.contactData.length &&
      this.props.checkAccountStatus === "success"
    ) {
      if (this.props.checkAccountResult) {
        const name = contactsName(this.props.contactData, false, 4);
        const phonecode = this.props.contactData[0].country
          ? "" + this.props.contactData[0].country.phonecode
          : "";
        const number = formatPhoneNumber(
          "" + phonecode + this.props.contactData[0].phone_number
        );
        content = (
          <React.Fragment>
            {this.props.callStatus !== "connected" && (
              <h5 className="modal-title" id="MakeCallToUserLabel">
                {this.props.callStatus === "connecting"
                  ? "Calling"
                  : "Make a call to"}
              </h5>
            )}

            {this.props.contactData && this.props.contactData.length === 1 && (
              <Avatar
                isGroup={false}
                firstName={this.props.contactData[0].first_name}
                lastName={this.props.contactData[0].last_name}
                email={this.props.contactData[0].email}
                bgColor={this.props.contactData[0].color}
                size={75}
              />
            )}
            {this.props.contactData && this.props.contactData.length > 1 && (
              <Avatar isMultipleContacts size={75} />
            )}
            <div className="contact-info" style={{ padding: 0 }}>
              <SpanWithTooltip
                arrow
                enterDelay={500}
                title={name}
                onClick={() => this.handleLongName(name)}
                className="name mw-100 overflow-hidden d-inline-block text-truncate"
              >
                {name}
              </SpanWithTooltip>
              <span className="number">{number}</span>
            </div>

            {!unformattedNumber && (
              <>
                {this.props.callStatus === "connected" && (
                  <div className="time">
                    <span>{formatDuration(this.state.time)}</span>
                  </div>
                )}
                {this.props.callStatus !== "connected" && (
                  <div className="number-select">
                    {this.props.callStatus === "connecting" && (
                      <span>
                        From: {formatPhoneNumber(this.props.callFrom)}
                      </span>
                    )}
                    {this.props.callStatus !== "connecting" && (
                      <React.Fragment>
                        <span>From:</span>
                        <select ref={this.fromRef}>
                          {this.props.numbers
                            .filter(
                              (number) =>
                                number.type !== "shortcode" &&
                                number.type !== "backup-toll-free"
                            )
                            .map((number) => (
                              <option key={number.number} value={number.number}>
                                {`${number.number_formatted} (${
                                  number.is_default ? "Me, " : ""
                                }${number.nickname})`}
                              </option>
                            ))}
                        </select>
                      </React.Fragment>
                    )}
                  </div>
                )}
                <div className="credit-info">
                  {(this.props.voicePriceStatus !== "success" ||
                    !this.props.twilioReady) && (
                    <React.Fragment>
                      <span className="mr-3">Initializing...</span>
                    </React.Fragment>
                  )}
                  {this.props.voicePriceStatus === "success" &&
                    this.props.twilioReady && (
                      <React.Fragment>
                        {this.props.callStatus === "connected" && (
                          <span
                            className="mr-3 d-block"
                            style={{ fontWeight: 400 }}
                          >
                            You spent {this.state.spent} credits
                          </span>
                        )}
                        {this.props.callStatus !== "connected" && (
                          <span className="mr-3">Ready</span>
                        )}
                        <span>{this.props.voicePrice} credits/min</span>
                      </React.Fragment>
                    )}
                </div>
                {(this.props.callStatus === "connecting" ||
                  this.props.callStatus === "connected") && (
                  <a href="#void" className="decline" onClick={this.decline}>
                    <CallIcon />
                  </a>
                )}
                {this.props.callStatus !== "connecting" &&
                  this.props.callStatus !== "connected" && (
                    <span
                      href="#void"
                      className={`dial ${
                        disablePhoneButton
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      onClick={disablePhoneButton ? undefined : this.call}
                    >
                      <CallIcon />
                    </span>
                  )}
              </>
            )}
            {unformattedNumber && (
              <div className="mt-5 text-danger">
                <SpanWithTooltip
                  placement="top"
                  title={
                    <span>
                      Number lookup completed.
                      <br />
                      Format Error.
                    </span>
                  }
                  id="CallContactModalUnformattedMsg"
                >
                  <i
                    className="im im-warning mr-1"
                    style={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                      marginTop: "-4px",
                    }}
                  ></i>
                  Unformatted number
                </SpanWithTooltip>
              </div>
            )}
          </React.Fragment>
        );
      } else {
        content = (
          <p className={"text-center text-danger"}>
            Your account was marked for review, please contact support for any
            questions.
          </p>
        );
      }
    }
    return (
      <>
        <div
          className={
            "modal fade making-call" +
            (this.state.isShown && !this.state.isHide ? " show" : "")
          }
          style={
            this.props.show || this.state.isShown ? { display: "block" } : {}
          }
          id="CallContactModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="CallContactModalLabel"
          aria-hidden={this.props.show || this.state.isShown ? "false" : "true"}
        >
          <div className="modal-backdrop fade show" onClick={this.shakeModal} />
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
            style={{ zIndex: 100 }}
          >
            <div className="modal-content themed-modal">
              <div className="modal-header">
                {(this.props.callStatus === "connecting" ||
                  this.props.callStatus === "connected") && (
                  <div
                    className="close-icon"
                    data-dismiss="modal"
                    data-tooltip="tooltip"
                    data-placement="top"
                    title="Minimize"
                    onClick={this.close}
                  >
                    <MinimizeSvg width={18} />
                  </div>
                )}
                {this.props.callStatus !== "connecting" &&
                  this.props.callStatus !== "connected" && (
                    <div className="close-icon" onClick={this.close}>
                      <CloseSvg width={14} />
                    </div>
                  )}
              </div>
              <div className="modal-body">{content}</div>
            </div>
          </div>
        </div>
        {showLongNameModal && (
          <LongNamesModal
            show={showLongNameModal}
            onClose={this.toggleLongNameModal}
            modalBodyContent={longNameModalContent}
          />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CallContactModal));
