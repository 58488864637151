import React, { useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PlusMinusCheckbox = (props) => {
  const {
    disabled,
    disabledTranslucent,
    checked,
    classNameCondition,
    onChange,
    showPlus,
    showMinus,
    size,
    className,
    color,
  } = props;

  const getLabelClassName = useCallback(() => {
    if (showPlus) {
      return "checkmark checkall";
    } else if (showMinus) {
      return "checkmarkin checkindeterminate";
    } else return "custom-control-label";
  }, [showMinus, showPlus]);

  const getDisabledClassName = useCallback(() => {
    if (disabledTranslucent && disabled) return "disabled-archived";
    else if (disabled) return "disabled";
    else return "";
  }, [disabled, disabledTranslucent]);

  return (
    <div
      className={clsx(
        "plus-minus-checkbox-container",
        `${size}-size`,
        {
          [`${color}-color`]: !!color,
        },
        className
      )}
    >
      <div
        className={
          classNameCondition
            ? "container mb-0 pl-0"
            : `custom-control custom-checkbox ${getDisabledClassName()}`
        }
      >
        <input
          disabled={disabled}
          type="checkbox"
          className={classNameCondition ? "" : "custom-control-input"}
          checked={checked}
          onChange={onChange}
        />
        <label className={getLabelClassName()}></label>
      </div>
    </div>
  );
};

PlusMinusCheckbox.propTypes = {
  disabled: PropTypes.bool,
  disabledTranslucent: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  classNameCondition: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showPlus: PropTypes.bool,
  showMinus: PropTypes.bool,
  size: PropTypes.oneOf(["default", "small"]),
  className: PropTypes.string,
  color: PropTypes.oneOf(["danger", "primary"]),
};

PlusMinusCheckbox.defaultProps = {
  disabled: false,
  disabledTranslucent: false,
  showPlus: false,
  showMinus: false,
  size: "default",
  classNameCondition: false,
  className: "",
  color: undefined,
};

export default PlusMinusCheckbox;
