import React, { useMemo } from "react";
import ThreadItemIntegrationIcons from "./ThreadItemIntegrationIcons";
import {
  getIntegrationIcon,
  getMultipleItemsIntegrationNameArray,
  isFavorite,
} from "./threadItemHelper";
import ThreadItemStatusIcons from "./ThreadItemStatusIcons";

import { ReactComponent as UnsyncedGroupIcon } from "../../../assets/img/icons-new/group-tab/integrations/unsynced-group/unsynced-group-red.svg";
import { ReactComponent as BlockedIcon } from "../../../assets/img/icons-new/number-status/blocked/blocked-gray.svg";
import { ReactComponent as UnsubscribedIcon } from "../../../assets/img/icons-new/number-status/unsubscribed/unsubscribed-gray.svg";
import { ReactComponent as OptedOutIcon } from "../../../assets/img/icons-new/number-status/opted-out/opted-out-gray.svg";
import { ReactComponent as MobileIcon } from "../../../assets/img/icons-new/number-status/mobile/mobile-gray.svg";
import { ReactComponent as LandlineIcon } from "../../../assets/img/icons-new/number-status/landline/landline-gray.svg";
import { ReactComponent as VoipIcon } from "../../../assets/img/icons-new/number-status/voip/voip-gray.svg";
import { ReactComponent as PeopleGroupSvg } from "../../../assets/img/icons-new/group-tab/group-icon/group-icon-black.svg";
import { ReactComponent as ReprocessedIconGrey } from "../../../assets/img/icons-new/message-status/reprocessed/reprocessed-grey.svg";
import { ReactComponent as AlertGrey } from "../../../assets/img/icons-new/general/alert/alert-grey.svg";
import { ReactComponent as ProcessQueuesIconSvg } from "../../../assets/img/icons-new/group-tab/process-queue/process-queue-gray.svg";
import { ReactComponent as SavedSearchesIconSvg } from "../../../assets/img/icons-new/group-tab/saved-search/saved-search-gray.svg";
import { ReactComponent as CircleCheck } from "../../../assets/img/icons-new/circle-check/circle-check-black.svg";

import SpanWithTooltip from "../../SpanWithTooltip";

import PropTypes from "prop-types";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import ThreadItemMultipleContactsIntegrationIcons from "./ThreadItemMultipleContactsIntegrationIcons";

const ThreadItemIconsContainer = (props) => {
  const {
    withGroupMembersCount,
    groupMembersCount,
    handleIconTooltip,
    isMultipleContacts,
    tab,
    showCheckBox,
    isGroup,
    contact,
    contacts,
    contactsIds,
    withContactDetails,
    withUnsyncedIcon,
    withArchiveIcon,
    withIntegrationIcon,
    group,
    unread_count,
    log,
    undeliveredCount,
    withLabelAndOrangeStatusIcon,
    isAlreadyAdded,
    isAlreadyAddedButHidden,
    withNewDuplicateContactView,
  } = props;

  const lastMessageStateIcon = useMemo(() => {
    if (log && undeliveredCount === 0) {
      if (log?.original_log_id > 0) {
        return <ReprocessedIconGrey />;
      } else if (log?.original_log_id === 0 && log?.sms_status === "failed") {
        return <AlertGrey />;
      }
    }
  }, [log, undeliveredCount]);

  const integrationIcon = getIntegrationIcon(
    withContactDetails,
    withIntegrationIcon,
    isGroup,
    group,
    contact,
    isMultipleContacts
  );

  const isPcoWorkflow = isGroup && group?.pco_workflow_id > 0;
  const isSavedSearch = isGroup && group?.saved_search_id > 0;

  const favorite = isFavorite({ isGroup, group, contacts });
  const unread = unread_count !== undefined && unread_count > 0;

  const showGroupIcon = contactsIds && contactsIds.length > 1;
  const showBlockedIcon = contact?.status === 1;
  const showUnsubscribedIcon =
    contact?.status === 3 && contact?.unsub_by_user_id > 0;
  const showOptedOutIcon =
    contact?.status === 3 && contact?.unsub_by_user_id <= 0;
  const showLookupMobileIcon =
    contact?.number_lookup && contact?.number_lookup.type === "mobile";
  const showLandlineIcon =
    contact?.number_lookup && contact?.number_lookup.type === "landline";
  const showVoipIcon =
    contact?.number_lookup && contact?.number_lookup.type === "voip";

  return (
    <div className="thread-item-icons-container">
      <>
        {withUnsyncedIcon && (
          <SpanWithTooltip title="This group has been Unsynced.">
            <UnsyncedGroupIcon width={16} height={16} />
          </SpanWithTooltip>
        )}
        {!!withGroupMembersCount && (
          <small className="d-flex text-muted groupMemberCount">
            {groupMembersCount}
          </small>
        )}

        <ThreadItemStatusIcons
          withContactDetails={withContactDetails}
          contacts={contacts}
          isMultipleContacts={isMultipleContacts}
          handleIconTooltip={handleIconTooltip}
          contactsIds={contactsIds}
          favorite={favorite}
          isUnread={unread}
          group={group}
          withArchiveIcon={withArchiveIcon}
          withLabelAndOrangeStatusIcon={withLabelAndOrangeStatusIcon}
        />
        {withNewDuplicateContactView && isNotAnEmptyArray(contacts) ? (
          getMultipleItemsIntegrationNameArray(contacts, isGroup, group).map(
            (integrationIconName, idx) => (
              <ThreadItemMultipleContactsIntegrationIcons
                key={idx}
                handleIconTooltip={handleIconTooltip}
                isGroup={isGroup}
                integrationIconName={integrationIconName}
              />
            )
          )
        ) : (
          <ThreadItemIntegrationIcons
            tab={tab}
            handleIconTooltip={handleIconTooltip}
            withContactDetails={withContactDetails}
            withIntegrationIcon={withIntegrationIcon}
            group={group}
            isGroup={isGroup}
            isMultipleContacts={isMultipleContacts}
            contact={contact}
          />
        )}
        {withContactDetails && contact && (
          <>
            {/* group icon to show */}
            {showGroupIcon && (
              <span
                className="status-icon optedout-icon"
                style={{ verticalAlign: "text-bottom" }}
              >
                <PeopleGroupSvg className="icon-grey" width={16} height={16} />
              </span>
            )}
            {/* contact statuses icons */}
            {!isMultipleContacts && contact && (
              <>
                {showBlockedIcon && (
                  <span
                    className="status-icon optedout-icon"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <BlockedIcon className="icon-grey" width={16} height={16} />
                  </span>
                )}
                {showUnsubscribedIcon && (
                  <span
                    className="status-icon optedout-icon "
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <UnsubscribedIcon
                      className="icon-grey"
                      width={16}
                      height={16}
                    />
                  </span>
                )}
                {showOptedOutIcon && (
                  <span
                    className="status-icon optedout-icon"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <OptedOutIcon
                      className="icon-grey"
                      width={16}
                      height={16}
                    />
                  </span>
                )}
                {showLookupMobileIcon && (
                  <span
                    className="status-icon phone-icon "
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <MobileIcon className="icon-grey" width={16} height={16} />
                  </span>
                )}
                {showLandlineIcon && (
                  <span
                    className="status-icon phone-icon"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <LandlineIcon
                      className="icon-grey"
                      width={16}
                      height={16}
                    />
                  </span>
                )}
                {showVoipIcon && (
                  <span
                    className="status-icon phone-icon"
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    <VoipIcon className="icon-grey" width={16} height={16} />
                  </span>
                )}
              </>
            )}
          </>
        )}
        {integrationIcon && isPcoWorkflow && (
          <SpanWithTooltip
            className="icon"
            title="PCO Workflow"
            placement="top"
          >
            <ProcessQueuesIconSvg className={"icon-grey"} width={16} />
          </SpanWithTooltip>
        )}
        {!showCheckBox && integrationIcon && isSavedSearch && (
          <SpanWithTooltip
            placement="top"
            className="icon"
            title="Saved Searches"
          >
            <SavedSearchesIconSvg className={"icon-grey"} width={16} />
          </SpanWithTooltip>
        )}
        {lastMessageStateIcon}
        {(isAlreadyAdded || isAlreadyAddedButHidden) && (
          <SpanWithTooltip
            className={"ml-1"}
            title={`Already added to the group ${
              isAlreadyAddedButHidden ? "but hidden" : ""
            }`}
            placement={"top"}
          >
            <CircleCheck height={14} width={14} /> Added{" "}
            {isAlreadyAddedButHidden ? "but hidden" : ""}
          </SpanWithTooltip>
        )}
      </>
    </div>
  );
};

ThreadItemIconsContainer.propTypes = {
  withGroupMembersCount: PropTypes.bool.isRequired,
  groupMembersCount: PropTypes.number.isRequired,
  handleIconTooltip: PropTypes.func.isRequired,
  isMultipleContacts: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,
  showCheckBox: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool.isRequired,
  withUnsyncedIcon: PropTypes.bool.isRequired,
  withArchiveIcon: PropTypes.bool.isRequired,
  withIntegrationIcon: PropTypes.bool.isRequired,
  group: PropTypes.object,
  unread_count: PropTypes.number,
  undeliveredCount: PropTypes.number,
  log: PropTypes.object,
  contactsIds: PropTypes.array,
  contact: PropTypes.object,
  contacts: PropTypes.array,
  withContactDetails: PropTypes.bool,
  withLabelAndOrangeStatusIcon: PropTypes.bool,
  isAlreadyAdded: PropTypes.bool,
  isAlreadyAddedButHidden: PropTypes.bool,
  withNewDuplicateContactView: PropTypes.bool,
};

ThreadItemIconsContainer.defaultProps = {
  withLabelAndOrangeStatusIcon: false,
  isAlreadyAdded: false,
  isAlreadyAddedButHidden: false,
  withNewDuplicateContactView: false,
};

export default ThreadItemIconsContainer;
