import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as groupActions from "../../../actions/groups";

export function useGroupsStore() {
  const dispatch = useDispatch();
  const _groups = useSelector((store) => store.groups);

  const toggleGroupFromList = useCallback(
    async (value, selectedThreads) =>
      await dispatch(groupActions.toggleGroupFromList(value, selectedThreads)),
    [dispatch]
  );

  const fetchFilteredMembers = useCallback(
    async (
      companyId,
      groupId,
      query,
      limit,
      page,
      filter,
      orderBy,
      filtersAdv,
      requestForMobileMode
    ) =>
      await dispatch(
        groupActions.fetchFilteredMembers(
          companyId,
          groupId,
          query,
          limit,
          page,
          filter,
          orderBy,
          filtersAdv,
          requestForMobileMode
        )
      ),
    [dispatch]
  );

  const patchGroup = useCallback(
    async (companyId, groupId, patchData, requestId, upload) =>
      await dispatch(
        groupActions.patchGroup(
          companyId,
          groupId,
          patchData,
          requestId,
          upload
        )
      ),
    [dispatch]
  );

  const clearPatchGroup = useCallback(
    async (requestId) =>
      await dispatch(groupActions.clearPatchGroup(requestId)),
    [dispatch]
  );

  const setExportAll = useCallback(
    async (id, runWorkflow, toastId) =>
      await dispatch({
        type: "GROUPS/SET_EXPORT_ALL",
        runWorkflow,
        id,
        toastId,
      }),
    [dispatch]
  );

  const addToExportAll = useCallback(
    async (id) =>
      await dispatch({
        type: "GROUPS/ADD_TO_EXPORT_ALL",
        id,
      }),
    [dispatch]
  );

  const toggleGroupView = useCallback(
    () => dispatch(groupActions.toggleGroupView()),
    [dispatch]
  );

  const setGroupSearch = useCallback(
    (group) => dispatch(groupActions.setGroupSearch(group)),
    [dispatch]
  );

  const setGroupFilter = useCallback(
    (filter) => dispatch(groupActions.setFilter(filter)),
    [dispatch]
  );

  const setPrefillIntegrationGroup = useCallback(
    (group) => dispatch(groupActions.setPrefillIntegrationGroup(group)),
    [dispatch]
  );

  const handleGroupItemsActions = useCallback(
    (time, selectedThreads, groupData, subMembersData, typeOfAction) =>
      dispatch(
        groupActions.handleGroupItemsActions(
          time,
          selectedThreads,
          groupData,
          subMembersData,
          typeOfAction
        )
      ),
    [dispatch]
  );

  const patchMembers = useCallback(
    (
      companyId,
      groupId,
      addContactIds,
      addGroupIds,
      removeContactIds,
      clearAdhoc,
      removeGroupIds,
      allParams,
      cleargroupRHSSelectionAfterPatchedMember,
      withRefreshGroupData
    ) =>
      dispatch(
        groupActions.patchMembers(
          companyId,
          groupId,
          addContactIds,
          addGroupIds,
          removeContactIds,
          clearAdhoc,
          removeGroupIds,
          allParams,
          cleargroupRHSSelectionAfterPatchedMember,
          withRefreshGroupData
        )
      ),
    [dispatch]
  );

  const fetchMembers = useCallback(
    (companyId, groupId, limit, page, filters, orderBy, advFilters) =>
      dispatch(
        groupActions.fetchMembers(
          companyId,
          groupId,
          limit,
          page,
          filters,
          orderBy,
          advFilters
        )
      ),
    [dispatch]
  );

  const clearAddGroup = useCallback(
    () => dispatch(groupActions.clearAddGroup()),
    [dispatch]
  );

  const clearPatchGroupsStatus = useCallback(
    () => dispatch(groupActions.clearPatchGroupsStatus()),
    [dispatch]
  );

  const fetchGroupMembersCountStatus = useCallback(
    (companyId, groupId) =>
      dispatch(groupActions.fetchGroupMembersCountStatus(companyId, groupId)),
    [dispatch]
  );

  const fetchGroupData = useCallback(
    (companyId, groupId) =>
      dispatch(groupActions.fetchGroupData(companyId, groupId)),
    [dispatch]
  );

  const clearGroupIsNewGroup = useCallback(
    () => dispatch(groupActions.clearGroupIsNewGroup()),
    [dispatch]
  );

  const setCurrentMemberFilter = useCallback(
    (filter) => dispatch(groupActions.setCurrentMemberFilter(filter)),
    [dispatch]
  );

  const resetGroupPatchQueue = useCallback(
    () => dispatch(groupActions.resetGroupPatchQueue()),
    [dispatch]
  );

  return {
    groups: _groups,
    toggleGroupFromList,
    fetchFilteredMembers,
    patchGroup,
    clearPatchGroup,
    setExportAll,
    addToExportAll,
    toggleGroupView,
    setGroupSearch,
    setPrefillIntegrationGroup,
    setGroupFilter,
    handleGroupItemsActions,
    patchMembers,
    fetchMembers,
    clearAddGroup,
    fetchGroupMembersCountStatus,
    clearPatchGroupsStatus,
    fetchGroupData,
    clearGroupIsNewGroup,
    setCurrentMemberFilter,
    resetGroupPatchQueue,
  };
}

export default useGroupsStore;
