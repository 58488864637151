import React from "react";
import { ReactComponent as LeftArrowIcon } from "../../../../assets/img/icons-new/general/arrow/left-arrow-white.svg";
import { ReactComponent as RightArrowIcon } from "../../../../assets/img/icons-new/general/arrow/right-arrow-white.svg";
const OneWayIntegrationSyncBadge = (props) => {
  const { integrationType } = props;
  return (
    <div className="badge badge-pill badge-secondary one-way-integration-badge">
      {integrationType}{" "}
      {integrationType === "1-way-in" ? (
        <LeftArrowIcon width={12} height={12} />
      ) : (
        <RightArrowIcon
          width={12}
          height={12}
          style={{ "margin-top": "2px" }}
        />
      )}
    </div>
  );
};

export default OneWayIntegrationSyncBadge;
