export const ThreadItemsTypeEnums = {
  All: "all",
  Sent: "sent",
  Received: "received",
  ScheduledAll: "scheduled-all",
  ScheduledOneTime: "scheduled-one-time",
  ScheduledRecurring: "scheduled-recurring",
};

export const USER_MERGE_FIELDS = [
  { text: "User Full Name" },
  { text: "User Short Name" },
  { text: "User First Name" },
  { text: "User Last Name" },
  { text: "User Email" },
  { text: "User Primary Phone" },
  { text: "User Personal Phone" },
];

export const CONTACT_MERGE_FIELDS = [
  { text: "Full Name" },
  { text: "Short Name" },
  { text: "First Name" },
  { text: "Last Name" },
  { text: "Email" },
  { text: "Phone" },
  { text: "Birthday" },
  { text: "Address" },
];

export const ORGANIZATION_MERGE_FIELDS = [
  { text: "Org Full Name" },
  { text: "Org Short Name" },
];

export const ALL_MERGE_FIELDS = [
  ...USER_MERGE_FIELDS,
  ...CONTACT_MERGE_FIELDS,
  ...ORGANIZATION_MERGE_FIELDS,
];
