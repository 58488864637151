import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addSnackData,
  exportContacts,
  clearExportContacts,
  grouprhsItemsSelected,
} from "../../../actions/contacts";
import { clearSelection as clearSelectionAction } from "../../../actions/threads";
import { formatTextForModalSnackbar, getNameBy } from "../../../helpers";
import { saveAs } from "file-saver";
import { withRouter } from "react-router-dom";

function mapStateToProps(store, ownProps) {
  return {
    companyId: store.companies.currentCompany.id,
    exportContactsStatus: store.contacts.exportContactsStatus,
    exportContactsCSV: store.contacts.exportContactsCSV,
    contactsData: store.contacts.data,
    groupsData: store.groups.data,
    isGroupAllMembersSelected: store.groups.isGroupAllMembersSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSnackData: (data, entity) => dispatch(addSnackData(data, entity)),
    onExportContacts: (companyId, contactIds, groupIds, allQuery, statusId) =>
      dispatch(
        exportContacts(companyId, contactIds, groupIds, allQuery, statusId)
      ),
    clearExportContacts: (id) => dispatch(clearExportContacts(id)),
    clearSelection: () => {
      dispatch(clearSelectionAction(true));
      dispatch(grouprhsItemsSelected(0));
    },
  };
}

const Export = (props) => {
  const {
    runWorkflow,
    selectedContacts,
    selectedGroups,
    setExportWorkflow,
    allParams,
    // Redux obj props
    companyId,
    exportContactsCSV,
    exportContactsStatus,
    contactsData,
    groupsData,
    statusId, // used in mapToProps
    isGroupAllMembersSelected,
    // Redux functions
    onExportContacts,
    setSnackData,
    clearSelection,
    clearExportContacts,
  } = props;

  const [workflowId, setWorkflowId] = useState();

  const proceedUnsubscribing = useCallback(() => {
    setWorkflowId(`export-${Math.floor(Math.random() * 1000) + 1}`);
    const data = {
      contactIds: Object.keys(selectedContacts).map((id) => parseInt(id)),
      groupIds: Object.keys(selectedGroups).map((id) => parseInt(id)),
    };

    return data;
  }, [selectedContacts, selectedGroups]);

  useEffect(() => {
    if (runWorkflow && !workflowId) {
      const { contactIds, groupIds } = proceedUnsubscribing();
      onExportContacts(companyId, contactIds, groupIds, allParams, statusId);
    }
  }, [
    companyId,
    onExportContacts,
    proceedUnsubscribing,
    runWorkflow,
    statusId,
    workflowId,
    allParams,
  ]);

  const toggleSnackbar = useCallback(() => {
    const message = formatTextForModalSnackbar(
      Object.keys(selectedContacts),
      Object.keys(selectedGroups),
      contactsData,
      groupsData,
      ["Exported ", { addName: true }],
      ["All People in ", { addName: true }, " were exported"],
      [{ addName: true }, " people exported"],
      ["All People in selected groups are exported"],
      isGroupAllMembersSelected ? "All people exported." : undefined
    );

    setSnackData(
      {
        id: workflowId,
        err: null,
        msg: message,
      },
      "export"
    );
    clearSelection();
  }, [
    clearSelection,
    contactsData,
    groupsData,
    selectedContacts,
    selectedGroups,
    setSnackData,
    workflowId,
    isGroupAllMembersSelected,
  ]);

  const toggleSnackbarError = useCallback(() => {
    setSnackData(
      {
        id: workflowId,
        err: true,
        msg: "Something went wrong",
      },
      "export"
    );
    clearSelection();
  }, [clearSelection, setSnackData, workflowId]);

  const downloadExportedFile = useCallback(() => {
    const blob = new Blob([exportContactsCSV[statusId]], {
      type: "text/csv;charset=utf-8",
    });

    saveAs(
      blob,
      `${
        isGroupAllMembersSelected
          ? "all"
          : getNameBy(
              Object.keys(selectedContacts),
              Object.keys(selectedGroups),
              contactsData,
              groupsData
            )
      }.csv`
    );
  }, [
    isGroupAllMembersSelected,
    contactsData,
    exportContactsCSV,
    groupsData,
    selectedContacts,
    selectedGroups,
    statusId,
  ]);

  useEffect(() => {
    if (exportContactsStatus[statusId] === "success") {
      setExportWorkflow(false);
      toggleSnackbar();
      downloadExportedFile();
      clearExportContacts(statusId);
    } else if (exportContactsStatus[statusId] === "failure") {
      setExportWorkflow(false);
      toggleSnackbarError();
      clearExportContacts(statusId);
    }
  }, [
    setSnackData,
    setExportWorkflow,
    toggleSnackbar,
    toggleSnackbarError,
    exportContactsStatus,
    workflowId,
    downloadExportedFile,
    clearExportContacts,
    statusId,
  ]);

  return <></>;
};

Export.propTypes = {
  selectedContacts: PropTypes.objectOf(PropTypes.any),
  selectedGroups: PropTypes.objectOf(PropTypes.any),
  runWorkflow: PropTypes.bool.isRequired,
  setExportWorkflow: PropTypes.func.isRequired,
  statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Export.defaultProps = {
  selectedGroups: {},
  selectedContacts: {},
  allQuery: {},
  statusId: "default",
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Export));
