import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { formatPhoneNumber, mapSortTypeToApiType } from "../../helpers";

import { setNumbersFilter } from "../../actions/numbers";
import { resetThreadsPage } from "../../actions/threads";

//new icons
import { ReactComponent as GlobeSvg } from "../../assets/img/icons-new/settings/globe/globe-gray.svg";
import ModalWrapper from "./Helpers/ModalWrapper";
import SearchBox from "../hub/HelperComponents/SearchBox";
import TableInfinityScroll from "../hub/HubThreadViewComponents/TableInfinityScroll";
import TableHeader from "../hub/HubThreadViewComponents/TableHeader";
import SwitchWithLabel from "../hub/HelperComponents/SwitchWithLabel";
import { useLocalSearch } from "../../utils/hooks/useLocalSearch";
import SpanWithTooltip from "../SpanWithTooltip";
import NumberDesignationIcons from "../Menus/shared/NumberSettingsTableBody/NumberDesignationIcons";
import NumberCountryFlag from "../NumberCountryFlag";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import BackButton from "../BackButton/BackButton";
import NumberSettingsStatus from "../Menus/shared/NumberSettingsTableBody/NumberSettingsStatus";
import AutoResponderBanner from "../Menus/shared/NumberSettingsTableBody/AutoResponderBanner";

function mapStateToProps(store) {
  return {
    currentCompanyId: store.companies.currentCompany.id,
    numbersStatus: store.numbers.numbersStatus,
    numbers: store.numbers.numbers,
    numbersFilter: store.numbers.numbersFilter,
    senderNumber: store.numbers.senderNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNumbersFilter: (companyId, numbers, all, save, isResetCurrentCompany) =>
      dispatch(
        setNumbersFilter(companyId, numbers, all, save, isResetCurrentCompany)
      ),
    resetThreadsPage: () => dispatch(resetThreadsPage()),
  };
}

const FilterNumbersModal = (props) => {
  const {
    show,
    shakeModal,
    closeModal,

    // Redux props
    currentCompanyId,
    numbersStatus,
    numbers,
    numbersFilter,
    senderNumber,

    // Redux func
    setNumbersFilter,
    resetThreadsPage,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [mappedNumbers, setMappedNumbers] = useState([]);
  const [numbersToShow, setNumbersToShow] = useState([]);
  const { onChange } = useLocalSearch(
    mappedNumbers,
    setNumbersToShow,
    setSearchValue,
    (element) => element.number
  );
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (numbersStatus === "success") {
      setNumbersToShow(numbers);
      setMappedNumbers(numbers);
    }
  }, [numbers, numbersStatus]);

  const toggle = useCallback(
    (number) => () => {
      const newNumbersFilter = [...numbersFilter];
      if (newNumbersFilter.indexOf(number) === -1) {
        newNumbersFilter.push(number);
      } else {
        newNumbersFilter.splice(newNumbersFilter.indexOf(number), 1);
      }
      resetThreadsPage();
      setNumbersFilter(
        currentCompanyId,
        newNumbersFilter,
        newNumbersFilter.length === numbers.length,
        true, // Is save,
        true // Is reset current company
      );
    },
    [
      currentCompanyId,
      numbers,
      numbersFilter,
      resetThreadsPage,
      setNumbersFilter,
    ]
  );

  const onSort = useCallback(
    (columnToSort, sortType) => {
      const orderType = mapSortTypeToApiType(sortType);

      setNumbersToShow(
        [...mappedNumbers].keySort({
          [columnToSort]: orderType,
        })
      );
    },
    [mappedNumbers]
  );

  return (
    <ModalWrapper
      className="number-filter-modal"
      show={show}
      shakeModal={shakeModal}
      closeModal={closeModal}
      hasCloseBtn={!isMdOrBelowBreakpoint(breakpoint)}
      dialogClassNaame={
        isMdOrBelowBreakpoint(breakpoint) ? "modal-dialog-start" : ""
      }
    >
      <>
        <div className="modal-header">
          <h5 className="modal-title" id="FilterNumbersModalLabel">
            {isMdOrBelowBreakpoint(breakpoint) && (
              <BackButton onClick={closeModal} className="mr-2" />
            )}
            Number Filter
          </h5>
        </div>
        <div className="modal-body">
          <h5 className="mb-3 modal-info">
            You can filter your result of message threads via your personal
            numbers. To view/manage all your numbers, Go to{" "}
            <Link
              to="/hub/menus/filter/settings/user-settings/submenu/user-number"
              className="my-number-text"
            >
              My numbers
            </Link>
          </h5>
          <SearchBox
            placeholder="Search Number"
            className="number-filter-search"
            value={searchValue}
            onChange={onChange}
          />
          <TableInfinityScroll
            loadMore={() => {}}
            page={1}
            searchValue={""}
            fetchFunction={(page) => {}}
            className="number-filter-scroll-table"
          >
            <table className="table">
              <TableHeader
                cells={[
                  {
                    text: "Number",
                    stateProp: true,
                    sortName: "number",
                  },
                  {
                    text: "Type",
                    stateProp: true,
                    sortName: "type",
                  },
                ]}
                onSort={onSort}
                className="number-filter-table-header"
              />

              <tbody className="number-filter-table-body">
                {numbersToShow.map((number) => {
                  return (
                    <tr>
                      <td>
                        <SwitchWithLabel
                          checked={
                            numbersFilter &&
                            numbersFilter.indexOf(number.number) >= 0
                              ? true
                              : false
                          }
                          onChange={toggle(number.number)}
                          label=""
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-start flex-column">
                          <div className="d-flex align-items-center">
                            <NumberCountryFlag
                              countryCode={number.country_code}
                              number={formatPhoneNumber(number.number)}
                              innerClassName="d-inline-block"
                            />
                            {(number.src === 5 || number.src === 6) && (
                              <SpanWithTooltip title="Shared with all PastorsLine Clients">
                                <GlobeSvg
                                  width={13}
                                  height={13}
                                  className="ml-2 mb-1"
                                />
                              </SpanWithTooltip>
                            )}
                          </div>
                          <AutoResponderBanner
                            isVacationReplyOn={number?.vacation_autoresponder}
                            isAutoResponderOn={
                              number?.after_hours_autoresponder
                            }
                            className="mt-1"
                          />
                        </div>
                      </td>
                      <td>
                        <NumberDesignationIcons
                          number={number}
                          isChild={false}
                          isSenderNumSameAsAssigned={
                            senderNumber &&
                            !senderNumber?.assigned &&
                            senderNumber.number === number?.number
                          }
                        />
                        <NumberSettingsStatus
                          is_toll_free={number.is_toll_free}
                          is_toll_free_registered={
                            number.is_toll_free_registered
                          }
                          isUnverifiedTollFreeText="(Unverified)"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableInfinityScroll>
        </div>
      </>
    </ModalWrapper>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterNumbersModal)
);
