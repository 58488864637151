import React, { useState, useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { cookies, history } from "./store";
import { Route, Switch } from "react-router-dom";
import Intercom from "react-intercom";
import Verification from "./components/Verification/Verification";
import router from "./router";
import EmailLink from "./pages/EmailLink/EmailLink";
import AxiosConfig from "./AxiosConfig";
import {
  ACCESS_TOKEN,
  APP_SHARED_SESSION_COOKIE,
  APP_SHARED_SESSION,
  RELOAD_COUNT,
  USER_LOGOUT,
} from "./utils/constants";
import axios from "axios";
import {
  axiosErrorCallback,
  clearTokens,
  purgeStoreForNewLogin,
  logoutAuth,
  setAppSharedSession,
  setReloadCountOnLocalStorage,
  setTokens,
} from "./utils/auth";
import SessionExpired from "./components/modals/SessionExpired";
import { connect } from "react-redux";
import { toggleSessionExpired } from "./actions/main";
import { useComponentWillMount } from "./utils/hooks/useComponentWillMount";
import AppLoading from "./components/AppLoading";
import { isReactNativeApp } from "./helpers";
import useUsersStore from "./utils/hooks/ReduxHooks/userStore";
import SessionStarted from "./components/modals/SessionStartedModal";
import ErrorModal from "./components/modals/ErrorModal";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import VerifyYourEmail from "./pages/VerifyYourEmail/VerifyYourEmail";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import RenderForRN from "./components/hub/HelperComponents/RenderForRN";
import { getIntercomAppId } from "./utils/authHelpers";
import TurnstileChallenge from "./components/hub/HelperComponents/CfTurnstileChallenge";

// !!! IMPORTANT !!!
// In ./store.js we held some code that fixes some corner case using replacing the history of the URL
// if something works wrong when with reading/replacing the parts or URL or something related to URL, please look there  first
let search = window.location.search;
let params = new URLSearchParams(search);
const accessToken = params.get("accessToken");
const refreshToken = params.get("refreshToken");

if (accessToken && refreshToken) {
  purgeStoreForNewLogin(accessToken);
  setTokens(accessToken, refreshToken);
  const appSharedSession = cookies.get(APP_SHARED_SESSION_COOKIE);
  if (appSharedSession) {
    setAppSharedSession(appSharedSession);
  }
  window.history.replaceState({}, document.title, window.location.pathname);
}

// this is used to invalidate session when Pusher sends "session-ended" (PusherConfig.js)
window.appSharedSession = localStorage.getItem(APP_SHARED_SESSION);

// Set reload count on localstorage if not exist
if (localStorage.getItem(RELOAD_COUNT) == null) {
  setReloadCountOnLocalStorage(0);
}

AxiosConfig.setAuthToken(localStorage.getItem(ACCESS_TOKEN));
axios.interceptors.response.use((response) => response, axiosErrorCallback);

function mapStateToProps(store) {
  return {
    showSessionExpiredModal: store.main.showSessionExpiredModal,
    showSessionReplaced: store.main.showSessionReplaced,
    showErrorModal: store.main.showErrorModal,
    showChallengeOverlay: store.users.showChallengeOverlay,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSessionExpired: (show) => dispatch(toggleSessionExpired(show)),
  };
}

const RootApplication = connect(
  undefined,
  mapDispatchToProps
)((props) => {
  const {
    // Redux func
    toggleSessionExpired,
  } = props;
  const { users } = useUsersStore();
  const { logoutStatus } = users;

  const [mount, setMount] = useState(true);

  useComponentWillMount(() => {
    if (
      !AxiosConfig.getAuthToken() &&
      cookies.get(APP_SHARED_SESSION_COOKIE) == null
    ) {
      logoutAuth();
      setMount(false);
      return;
    }
    if (!AxiosConfig.getAuthToken() && !logoutStatus) {
      clearTokens();
      toggleSessionExpired(true);
      if (isReactNativeApp()) {
        window.ReactNativeWebView?.postMessage(USER_LOGOUT);
      }
      return;
    }
  });

  if (mount)
    return (
      <Switch>
        <Route exact path="/email-link/:companyId/*" component={EmailLink} />
        <Route path="*" component={router} />
      </Switch>
    );

  return <AppLoading />;
});

const RootRouter = (props) => {
  const {
    // Redux props
    showSessionExpiredModal,
    showSessionReplaced,
    showErrorModal,
    showChallengeOverlay,
  } = props;

  const intercomAppId = getIntercomAppId();

  const intercomAllowedPathname = [
    "/register",
    "/login",
    "/forgot-password",
    "/verify-your-email",
    "/verification",
  ];

  useEffect(() => {
    // It will fire an event called "session-expired". If user is on the react-native app and the session has expired
    if (isReactNativeApp() && showSessionExpiredModal) {
      window.ReactNativeWebView?.postMessage("session-expired");
    }
  }, [showSessionExpiredModal]);

  return (
    <>
      {showSessionExpiredModal && !isReactNativeApp() && <SessionExpired />}
      {showSessionReplaced && <SessionStarted />}
      {showErrorModal && !showSessionExpiredModal && <ErrorModal />}
      {showChallengeOverlay && <TurnstileChallenge />}

      {intercomAllowedPathname.includes(window?.location?.pathname) && (
        <RenderForRN show={false}>
          <Intercom appID={intercomAppId} />
        </RenderForRN>
      )}

      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/verify-your-email" component={VerifyYourEmail} />
          <Route exact path="/verification" component={Verification} />
          <Route path="/*" component={RootApplication} />
        </Switch>
      </ConnectedRouter>
    </>
  );
};

export default connect(mapStateToProps)(RootRouter);
