import React, { Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SpanWithTooltip from "../../SpanWithTooltip";
import { formatPhoneNumber } from "../../../helpers";
import NumberCountryFlag from "../../NumberCountryFlag";

const IS_SHARED_NUM_TOOLTIP =
  "You can test most of the features with this shared test number. Unfortunately, you will not be able to receive texts or calls.";

const UserPhoneNumbers = (props) => {
  const { assignedNumber, isSharedNumber, numbers, senderNumber } = props;

  const getPhoneNumber = useCallback(() => {
    return (
      <NumberCountryFlag
        countryCode={senderNumber?.country_code}
        number={formatPhoneNumber(assignedNumber)}
        flagIconSize={15}
        flagContainerClassName={"mr-1"}
        innerClassName={"d-inline-block"}
        className={"phone-number-wrapper"}
      />
    );
  }, [assignedNumber, senderNumber]);

  const getMoreNumbersCount = useCallback(() => {
    if (numbers?.length > 1) {
      return <span className="number">+{numbers.length - 1} more</span>;
    }
  }, [numbers]);

  return (
    <Link className="number-row" to="#modal-filter-numbers">
      {numbers && assignedNumber && (
        <Fragment>
          <SpanWithTooltip
            arrow={true}
            title={
              isSharedNumber
                ? IS_SHARED_NUM_TOOLTIP
                : "This is your Assigned number. Click to view/manage"
            }
          >
            {getPhoneNumber()}
          </SpanWithTooltip>
          {getMoreNumbersCount()}
        </Fragment>
      )}
      <i className="fa fa-chevron-right arrow-icon"></i>
    </Link>
  );
};

UserPhoneNumbers.defaultProps = {
  isSharedNumber: false,
  numbers: [],
  senderNumber: {},
};

UserPhoneNumbers.propTypes = {
  assignedNumber: PropTypes.number.isRequired,
  isSharedNumber: PropTypes.bool,
  numbers: PropTypes.array,
  senderNumber: PropTypes.object,
};

export default UserPhoneNumbers;
