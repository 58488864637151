import React from "react";
import PropTypes from "prop-types";
import BroadcastTableRow from "./BrodcastTableRow";

const BroadcastTableBody = (props) => {
  const {
    isLoading,
    isLoadingMore,
    broadcasts,
    columnSelectionState,
    menuRightPosition,
    setDataForScheduledMessageSummaryModal,
    handleEditScheduleMessage,
    handleDeleteScheduleMessage,
    handleSendNowSceduleMessage,
    handleStopNowScheduleMessage,
  } = props;

  return (
    <tbody style={isLoading ? { opacity: "0.5" } : {}}>
      {broadcasts &&
        Array?.isArray(broadcasts) &&
        broadcasts?.map((broadcast) => {
          if (broadcast == null) return null;

          return (
            <BroadcastTableRow
              member={broadcast}
              columnSelectionState={columnSelectionState}
              menuRightPosition={menuRightPosition}
              setDataForScheduledMessageSummaryModal={
                setDataForScheduledMessageSummaryModal
              }
              handleEditScheduleMessage={handleEditScheduleMessage}
              handleDeleteScheduleMessage={handleDeleteScheduleMessage}
              handleSendNowSceduleMessage={handleSendNowSceduleMessage}
              handleStopNowScheduleMessage={handleStopNowScheduleMessage}
            />
          );
        })}
      {isLoadingMore ? (
        <tr>
          <td colSpan="6">
            <p className="text-muted text-center mt-3">Loading...</p>
          </td>
        </tr>
      ) : null}
    </tbody>
  );
};

BroadcastTableBody.propTypes = {
  isLoading: PropTypes.bool,
  broadcasts: PropTypes.arrayOf(PropTypes.number),
  columnSelectionState: PropTypes.objectOf(PropTypes.any),
  isLoadingMore: PropTypes.bool,
  setDataForScheduledMessageSummaryModal: PropTypes.func.isRequired,
  handleEditScheduleMessage: PropTypes.func.isRequired,
  handleSendNowSceduleMessage: PropTypes.func.isRequired,
  handleDeleteScheduleMessage: PropTypes.func.isRequired,
  handleStopNowScheduleMessage: PropTypes.func.isRequired,
};

export default BroadcastTableBody;
