import { useCallback, useEffect, useState } from "react";

const isLayoutChanged = (changedLayout, layout) => {
  let counter = 0;
  if (changedLayout.length !== layout.length) return true;

  for (let i = 0; i < changedLayout.length; i++) {
    if (
      changedLayout[i].x !== layout[i].x ||
      changedLayout[i].y !== layout[i].y
    ) {
      counter += 1;
    }
  }

  return counter > 0;
};

const calculateH = (heightPx, rowHeight) => {
  let h = Math.ceil(heightPx / rowHeight);
  return { h };
};

const getResizedLayout = (oldLayout, result, index) => {
  const newLayout = [...oldLayout];
  const auxCard = { ...newLayout[index] };
  newLayout[index].h = result.h;
  newLayout.forEach((item, _index) => {
    if (item.x === auxCard.x && item.y > auxCard.y) {
      item.y += result.h - auxCard.h;
    }
  });
  return newLayout;
};

export const useDragAndDrop = (
  firstMountLayout,
  rowHeight,
  onDragStartCallback = () => {},
  onDragStopCallback = () => {}
) => {
  const [layout, setLayout] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const onSizeChange = useCallback(
    (height, index) => {
      const result = calculateH(height, rowHeight);
      setTimeout(() => {
        setLayout((oldLayout) => {
          if (oldLayout[index].h === result.h) {
            return oldLayout;
          } else {
            return getResizedLayout(oldLayout, result, index);
          }
        });
      }, 1);
    },
    [rowHeight]
  );

  useEffect(() => {
    setLayout(firstMountLayout);
  }, [firstMountLayout]);

  const onToggleDrag = useCallback(
    (bool) => () => {
      setTimeout(
        () =>
          setLayout(layout.map((element) => ({ ...element, static: bool }))),
        1
      );
    },
    [layout]
  );

  const onLayoutChange = useCallback(
    (_layout) => {
      if (isLayoutChanged(_layout, layout)) {
        setLayout(
          _layout.map((element) => ({
            ...element,
            static: true,
          }))
        );
      }
    },
    [layout]
  );

  const onDragStart = useCallback(
    (layout, oldItem, newItem) => {
      setIsDragging(true);
      onDragStartCallback(layout, oldItem, newItem);
    },
    [onDragStartCallback]
  );

  const onDragStop = useCallback(
    (layout) => {
      setIsDragging(false);
      onDragStopCallback(layout);
    },
    [onDragStopCallback]
  );

  return {
    layout,
    setLayout,
    isDragging,
    onToggleDrag,
    onLayoutChange,
    onDragStart,
    onDragStop,
    onSizeChange,
  };
};
