import PropTypes from "prop-types";
import { isReactNativeApp } from "../../../helpers";

const isRN = isReactNativeApp();
const RenderForRN = (props) => {
  const { show, children } = props;

  if (isRN && !show) {
    return null;
  }

  return children;
};

RenderForRN.propTypes = {
  show: PropTypes.bool,
};

RenderForRN.defaultProps = {
  show: false,
};

export default RenderForRN;
