import React, { useEffect, useState, useCallback, useMemo } from "react";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroller";
import useMediaStore from "../../../../../utils/hooks/ReduxHooks/mediaStore";
import Spinner from "../../../HelperComponents/Spinner";
import ButtonWithSpinner from "../../../HelperComponents/ButtonWithSpinner";

const ImageCustomSource = (props) => {
  const [toUpload, setToUpload] = useState({});
  const [isOnGridView, setIsOnGridView] = useState(false);
  const { actions, element } = props;

  // Redux store
  const {
    media,
    fetchMediaResources,
    deleteMediaResources,
    clearDeletehMediaResourcesStatus,
  } = useMediaStore();
  const {
    imageSource,
    fetchMediaResourceStatus,
    deleteMediaResourcesStatus,
    fetchMediaResourcePage,
    fetchMediaResourceLoadingMoreStatus,
    fetchMediaResourceLoadedAll,
  } = media;
  const loading = useMemo(
    () => fetchMediaResourceStatus === "loading",
    [fetchMediaResourceStatus]
  );
  const loadingMore = useMemo(
    () => fetchMediaResourceLoadingMoreStatus === "loading",
    [fetchMediaResourceLoadingMoreStatus]
  );

  useEffect(() => {
    const params = {
      page: 1,
      type: "image",
    };
    fetchMediaResources(params);
  }, [fetchMediaResources]);

  useEffect(() => {
    if (deleteMediaResourcesStatus === "success") {
      clearDeletehMediaResourcesStatus();
      setToUpload({});
    }
  }, [clearDeletehMediaResourcesStatus, deleteMediaResourcesStatus]);

  const loadMore = useCallback(
    (values) => {
      if (
        fetchMediaResourceStatus !== "loading" &&
        fetchMediaResourceStatus !== "error" &&
        !fetchMediaResourceLoadedAll &&
        fetchMediaResourceLoadingMoreStatus !== "loading"
      ) {
        const params = {
          page: fetchMediaResourcePage + 1,
          type: "image",
        };
        fetchMediaResources(params);
      }
    },
    [
      fetchMediaResourceLoadedAll,
      fetchMediaResourceLoadingMoreStatus,
      fetchMediaResourceStatus,
      fetchMediaResources,
      fetchMediaResourcePage,
    ]
  );

  const handleClick = useCallback(
    (idx, file) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      const updatedUploads = { ...toUpload };
      if (updatedUploads[idx]) {
        delete updatedUploads[idx];
      } else {
        updatedUploads[idx] = file;
      }

      setToUpload(updatedUploads);
    },
    [toUpload]
  );

  const handleRemoveResource = useCallback(() => {
    const ids = Object.values(toUpload).map((file) => file.id);
    const data = {
      ids,
    };
    deleteMediaResources(data, "image");
  }, [deleteMediaResources, toUpload]);

  const handleViewTypeClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (element.classList.contains("grid")) {
        setIsOnGridView(false);
        element.classList.remove("grid");
      } else {
        setIsOnGridView(true);
        element.classList.add("grid");
      }
    },
    [element]
  );

  const handleFinishClick = useCallback(() => {
    if (Object.keys(toUpload).length === 0) {
      return;
    }

    const todo = [];
    Object.values(toUpload).forEach((file) =>
      todo.push(actions.fetchAndAddUrl(file.url))
    );

    Promise.all(todo).then(() => {
      actions.showSummaryView();
      setToUpload({});
    });
  }, [actions, toUpload]);

  if (loading && !loadingMore) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={clsx(`fsp-picker--custom-source`, {
        "list-view-container": !isOnGridView,
      })}
    >
      <div className="fsp-picker--custom-source-header w-100 mb-2">
        <span
          className="view-type cursor-pointer"
          onClick={handleViewTypeClick}
        >
          <strong className="ml-4">{isOnGridView ? "List" : "Grid"}</strong>
        </span>
      </div>
      <InfiniteScroll
        pageStart={fetchMediaResourcePage}
        initialLoad={false}
        loadMore={loadMore}
        className={clsx({
          "grid-view-infinite-scroll": isOnGridView,
          "list-view-infinite-scroll": !isOnGridView,
        })}
        hasMore={!fetchMediaResourceLoadedAll}
        threshold={250}
        useWindow={false}
        loader={
          loading &&
          loadingMore && (
            <p className="text-muted text-center mt-3">Loading...</p>
          )
        }
      >
        <ul className="fsp-picker--custom-source-list p-0 w-100">
          {Boolean(imageSource.length) ? (
            imageSource.map((element, idx) => (
              <li
                key={`file-${idx}`}
                className={clsx("source-list-item", {
                  "file-selected": toUpload[idx],
                })}
                onClick={handleClick(idx, element)}
              >
                <img
                  src={element.url}
                  alt={element.filename}
                  width="20"
                  height="20"
                />
                <span>{element.filename}</span>
              </li>
            ))
          ) : (
            <h5 className="text-muted text-center mt-3">No data found.</h5>
          )}
        </ul>
      </InfiniteScroll>
      <div className="fsp-picker--custom-source-footer file-stack-image-footer-container">
        <span>Selected Files: {Object.keys(toUpload).length}</span>
        <div>
          {Object.keys(toUpload).length !== 0 && (
            <ButtonWithSpinner
              className={"delete-selected-button"}
              withBtnClassName={false}
              onClick={handleRemoveResource}
              loading={deleteMediaResourcesStatus === "loading"}
              disabled={deleteMediaResourcesStatus === "loading"}
            >
              Delete Selected
            </ButtonWithSpinner>
          )}
          <button
            onClick={handleFinishClick}
            className={clsx("btn", {
              "btn-disabled": Object.keys(toUpload).length === 0,
              "view-edit-btn": Object.keys(toUpload).length !== 0,
            })}
            disabled={deleteMediaResourcesStatus === "loading"}
          >
            View/Edit selected
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCustomSource;
