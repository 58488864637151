import React from "react";
import PropTypes from "prop-types";

const SideBarIconWrapper = (props) => {
  const {
    showStatus,
    showWhiteStatus,
    showBlueStatus,
    id,
    children,
    notificationBadgeRedClassName,
    notificationBadgeWhiteClassName,
    notificationBadgeBlueClassName,
    className,
  } = props;
  return (
    <div
      id={id}
      className={`icon-wrapper ${className} ${
        showStatus || showWhiteStatus || showBlueStatus
          ? "icon-with-status"
          : ""
      }`}
    >
      {children}
      {showStatus && (
        <span
          className={`notification-badge-undelivered ${notificationBadgeRedClassName}`}
        ></span>
      )}
      {showWhiteStatus && (
        <span
          className={`notification-badge-unread ${notificationBadgeWhiteClassName}`}
        ></span>
      )}
      {showBlueStatus && (
        <span
          className={`notification-badge-blue ${notificationBadgeBlueClassName}`}
        />
      )}
    </div>
  );
};

SideBarIconWrapper.propTypes = {
  showStatus: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  showWhiteStatus: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  notificationBadgeRedClassName: PropTypes.string,
  notificationBadgeWhiteClassName: PropTypes.string,
  notificationBadgeBlueClassName: PropTypes.string,
  showBlueStatus: PropTypes.bool,
};

SideBarIconWrapper.defaultProps = {
  notificationBadgeRedClassName: "",
  notificationBadgeWhiteClassName: "",
  notificationBadgeBlueClassName: "",
  className: "",
  showBlueStatus: false,
};

export default SideBarIconWrapper;
