import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export function fetchSettings() {
  return function (dispatch) {
    dispatch({ type: "SETTINGS/SETTINGS" });

    const url = "settings.json";
    const startTime = new Date().getTime();
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SETTINGS/SETTINGS.SUCCESS",
          payload: response.data,
          responseTime: new Date().getTime() - startTime,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SETTINGS/SETTINGS.ERROR",
          payload: error,
        });
      });
  };
}
