import React, { useCallback } from "react";
import { isReactNativeApp } from "../../../helpers";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import BackButton from "../../BackButton/BackButton";
// import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useActionMenus } from "../../../utils/hooks/useActionMenus";
import ThreadsActionsPopover from "../../elements/ThreadsActionsPopover";
import DashboardHeaderPopoverContent from "./DashboardHeaderPopoverContent";

const DashboardHeader = (props) => {
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const { isOpen, onClickMenu, toggle } = useActionMenus();

  const onClickCustomizeWidgets = useCallback(() => {
    history.push("#modal-customize-widgets");
  }, [history]);

  return (
    <div className="fixed-header dashboard-header">
      <div
        className="light-tone text-capitalize d-flex align-items-center justify-content-between"
        style={{ fontSize: 18 }}
      >
        <span className="d-flex align-items-center">
          {isMdOrBelowBreakpoint(breakpoint) && (
            <BackButton link={"/hub/menus"} className={"mr-2"} />
          )}
          <div>Dashboard</div>
        </span>
        {!isReactNativeApp() && (
          <ThreadsActionsPopover
            id="customize-widgets"
            toggle={toggle}
            isOpen={isOpen}
            menuItems={
              <DashboardHeaderPopoverContent
                onClickMenu={onClickMenu}
                onClickCustomizeWidgets={onClickCustomizeWidgets}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {};

export default DashboardHeader;
