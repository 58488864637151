import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as analyticsActions from "../../../actions/menus/analytics";

export function useAnalyticsStore() {
  const dispatch = useDispatch();
  const _analytics = useSelector((store) => store.analytics);

  const fetchLogs = useCallback(
    async (data, logReportTypes) =>
      await dispatch(analyticsActions.fetchLogs(data, logReportTypes)),
    [dispatch]
  );

  const setLogsEmbedURL = useCallback(
    async (embedURL) =>
      await dispatch(analyticsActions.setLogsEmbedURL(embedURL)),
    [dispatch]
  );

  const setStatisticsEmbedUrl = useCallback(
    async (embedURL) =>
      await dispatch(analyticsActions.setStatisticsEmbedUrl(embedURL)),
    [dispatch]
  );

  return {
    analytics: _analytics,
    fetchLogs,
    setLogsEmbedURL,
    setStatisticsEmbedUrl,
  };
}

export default useAnalyticsStore;
