import { isIOs, isReactNativeApp } from "../helpers";
import { ASK_CAMERA_PERMISSION } from "./constants";
import { isTollFreeOrShortCodeNumberSelectedAsSenderNumber } from "./numbersHelpers";

/**
 * Converts milliseconds to seconds and returns the result with two decimal places.
 * @param {number} milliseconds - The value in milliseconds to be converted.
 * @returns {number} The converted value in seconds with two decimal places.
 */
export const convertMillisecondsToSeconds = (milliseconds) => {
  const seconds = milliseconds / 1000;
  return Number(seconds?.toFixed(2));
};

/**
 * Converts bytes to megabytes and returns the result rounded to two decimal places.
 * @param {number} bytes - The value in bytes to be converted.
 * @returns {number} The converted value in megabytes rounded to two decimal places.
 */
export const convertBytesToMegabytes = (bytes) => {
  const megabytes = bytes / (1024 * 1024);
  return Number(megabytes?.toFixed(2));
};

/**
 * Formats a video duration in milliseconds into a human-readable time format.
 * @param {number} milliseconds - The duration in milliseconds to be formatted.
 * @returns {string} The formatted video duration in the format "HH:MM:SS.M".
 */
export const formatVideoDuration = (milliseconds) => {
  // Convert milliseconds to seconds and adjust milliseconds accordingly
  let seconds = Math.floor(milliseconds / 1000);
  milliseconds -= seconds * 1000;

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  // Format each component of the duration
  const formattedHours = ("0" + hours).substr(-2); // Ensure 2-digit format
  const formattedMinutes = ("0" + minutes).substr(-2); // Ensure 2-digit format
  const formattedSeconds = ("0" + Math.floor(seconds)).substr(-2); // Ensure 2-digit format
  const formattedMilliseconds = ("" + milliseconds).substr(0, 1); // Extract first digit

  // Construct the formatted duration string
  return `${
    minutes > 60 ? `:${formattedHours}` : "" // Include hours if duration is more than 60 minutes
  }${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
};

/**
 * Converts a given time in milliseconds to a formatted string representation.
 * @param {number} milliseconds - The time in milliseconds to convert.
 * @param {boolean} withHour - Optional. If true, includes hours in the formatted time.
 * @returns {string} The formatted time string in the format HH:MM:SS or MM:SS.
 */
export const convertMillisecondsToFormattedTime = (
  milliseconds,
  withHour = false
) => {
  if (isNaN(milliseconds) || milliseconds < 0) {
    return "Invalid input";
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  let formattedTime = "";

  if (hours === 0 && !withHour) {
    formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  return formattedTime;
};

/**
 * Converts seconds to milliseconds.
 * @param {number} seconds - The value in seconds to be converted.
 * @returns {number} The converted value in milliseconds.
 */
export const convertSecondsToMilliseconds = (seconds) => {
  return seconds * 1000;
};

/**
 * Calculates the absolute difference between two numbers and returns the result rounded to two decimal places.
 * @param {number} a - The first number.
 * @param {number} b - The second number.
 * @returns {string} The absolute difference rounded to two decimal places.
 */
export const getTimeDiff = (a, b) => {
  return Math.abs(a - b).toFixed(2);
};

/**
 * Calculates the time difference between two time values and returns the result
 * in either formatted time or milliseconds, based on input options.
 * @param {Date} endTime - The ending time.
 * @param {Date} startTime - The starting time.
 * @param {boolean} formatInTime - If true, the result will be formatted as time (HH:MM:SS).
 * @param {boolean} withHours - If true, includes hours in the formatted time.
 * @returns {string|number} The time difference in formatted time or milliseconds.
 */
export const formatTimeFromTimeDiff = (
  endTime,
  startTime,
  formatInTime,
  withHours
) => {
  const timeDifferenceInSeconds = getTimeDiff(endTime, startTime);
  const timeInMilliseconds = convertSecondsToMilliseconds(
    timeDifferenceInSeconds
  );

  if (formatInTime) {
    return convertMillisecondsToFormattedTime(timeInMilliseconds, withHours);
  } else {
    return timeInMilliseconds;
  }
};

export const calculateBitrates = (
  durationInSeconds,
  isOnTollFreeOrShortCodeNumber
) => {
  const targetFileSizeInBytes = 900000; // 0.9 MB in bytes

  // Calculate the maximum video bitrate (in Kbps) to fit within the target file size
  const maxVideoBitrateKbps =
    (targetFileSizeInBytes * 8) / (durationInSeconds * 950);

  // Assuming a typical audio bitrate (e.g., 64 Kbps)
  let audioBitrateKbps = 64; // You can adjust this value as needed

  // Calculate the remaining available bitrate for video after accounting for audio
  const maxAvailableVideoBitrateKbps = maxVideoBitrateKbps - audioBitrateKbps;

  // Ensure the video bitrate is at least 200 Kbps (adjust as needed)
  let videoBitrateKbps = Math.max(maxAvailableVideoBitrateKbps, 200);
  if (isOnTollFreeOrShortCodeNumber) {
    videoBitrateKbps = Math.max(maxAvailableVideoBitrateKbps, 100) / 1.9;
    audioBitrateKbps = audioBitrateKbps / 1.9;
  }

  return {
    videoBitrateKbps: Math.round(videoBitrateKbps),
    audioBitrateKbps: Math.round(audioBitrateKbps),
  };
};

export const hasLargeVideoAttachmentWithSpecialNumber = (
  attachedVideos = [],
  senderNumber
) => {
  const isSpecialNumberSelected =
    isTollFreeOrShortCodeNumberSelectedAsSenderNumber(senderNumber);
  if (
    !isSpecialNumberSelected ||
    !attachedVideos ||
    (attachedVideos &&
      Array.isArray(attachedVideos) &&
      attachedVideos.length === 0)
  )
    return false;
  const attachedVideo = attachedVideos.some((video) => {
    const { result } = video;
    if (result?.file_size > 600000) {
      return true;
    }
    return false;
  });
  return attachedVideo;
};

export const getVideoDeletionHandlers = (videoHandler = []) => {
  return { handles: videoHandler };
};

export const parseUploadedVideoData = (videoInfo, uploadedVideoFile) => {
  return {
    file: { url: uploadedVideoFile?.url },
    result: { ...videoInfo, mime_type: uploadedVideoFile?.mimetype },
    filename: uploadedVideoFile?.filename,
    originalPath: uploadedVideoFile?.originalPath,
    ...uploadedVideoFile,
  };
};

export const askForAndroidCameraPermission = () => {
  if (isReactNativeApp() && !isIOs()) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ event: ASK_CAMERA_PERMISSION })
    );
  }
};

export const isLargeFileUploaded = (
  fileSize,
  isOnTollFreeOrShortCodeNumber
) => {
  const videoSizeInMb = convertBytesToMegabytes(fileSize); // Size in mb
  const videoSizeCheck = isOnTollFreeOrShortCodeNumber
    ? fileSize > 600000
    : videoSizeInMb > 1;
  return videoSizeCheck;
};

export const getThumbnailByHandler = (videoSource = [], handler) => {
  const video = videoSource.find((video) => video.handle === handler);
  return video?.thumbnail_url || "";
};
