import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DeleteWorkflow from "../hub/ActionsWorkflows/Delete";
import UnarchiveWorkflow from "../hub/ActionsWorkflows/Unarchive";
import { connect } from "react-redux";
import { history } from "../../store";
import ThreadsActionsPopover from "./ThreadsActionsPopover";
import { useActionMenus } from "../../utils/hooks/useActionMenus";
import { getPermissionParsed } from "../../utils/settingsHelpers";

function mapStateToProps(store, ownProps) {
  return {
    filter: store.groups.filter,
    userPermissions: store.users.permissions,
  };
}

const ThreadsActionsArchivedGroup = (props) => {
  const {
    group,
    showVerticalMenu,
    // Redux props
    filter,
    userPermissions,
  } = props;

  const [unarchiveThis, setUnarchiveThis] = useState(false);
  const [deleteThis, setDeleteThis] = useState(false);
  const { isOpen, onClickMenu, toggle } = useActionMenus();

  const handleGroupPopover = (action) => () => {
    if (action === "unarchive") {
      setUnarchiveThis(true);
    } else if (action === "delete") {
      setDeleteThis(true);
    }
  };

  const onUnArchivedComplete = (isClosing) => {
    if (!isClosing)
      history.push(`/hub/groups${filter ? `/filter/${filter}` : ""}`);
    setUnarchiveThis(false);
  };

  const onDeleteComplete = (isClosing) => {
    if (!isClosing)
      history.push(`/hub/groups${filter ? `/filter/${filter}` : ""}`);
    setDeleteThis(false);
  };

  const renderActionPopover = useCallback(() => {
    return (
      <ThreadsActionsPopover
        showVerticalMenu={showVerticalMenu}
        id="lhs-archived-groups-actions-popover"
        isOpen={isOpen}
        toggle={toggle}
        menuItems={
          <>
            {getPermissionParsed(userPermissions?.groups, "archive") && (
              <button onClick={onClickMenu(handleGroupPopover("unarchive"))}>
                Unarchive
              </button>
            )}
            {getPermissionParsed(userPermissions?.groups, "delete") && (
              <button
                className="marked-warning"
                onClick={onClickMenu(handleGroupPopover("delete"))}
              >
                Delete
              </button>
            )}
          </>
        }
      />
    );
  }, [isOpen, onClickMenu, showVerticalMenu, toggle, userPermissions]);

  return (
    <>
      <div className="lhs-archive-popover">{renderActionPopover()}</div>
      {deleteThis && (
        <DeleteWorkflow
          runWorkflow={deleteThis}
          selectedGroups={{ [group.id]: group }}
          onDeleteComplete={onDeleteComplete}
          needRouting
        />
      )}
      {unarchiveThis && (
        <UnarchiveWorkflow
          runWorkflow={unarchiveThis}
          selectedThreads={{}}
          selectedSubThreads={{}}
          selectedGroups={{ [group.id]: group }}
          onUnarchiveComplete={onUnArchivedComplete}
          needRouting
          group={true}
        />
      )}
    </>
  );
};

ThreadsActionsArchivedGroup.propTypes = {
  group: PropTypes.objectOf(PropTypes.any).isRequired,
  showVerticalMenu: PropTypes.bool,
};

ThreadsActionsArchivedGroup.defaultProps = {
  showVerticalMenu: true,
};

export default connect(mapStateToProps)(ThreadsActionsArchivedGroup);
