import React from "react";
import {
  BillingIssueEnums,
  IssueKeyEnums,
  SuspendedAccountEnums,
} from "./enums/accountEnums";

export const getAccountIssueOverlayInfo = (
  isBillingIssue,
  isSuspended,
  numberIsDeleted,
  billingPackage
) => {
  let overlayIssue = {
    message: "",
    url: "",
    urlLabel: "",
    withOrganiztionSwitcher: false,
  };
  const billingURL =
    "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing";
  const payBillingURL =
    "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/pay-billing";
  if (
    isBillingIssue === BillingIssueEnums.ThirdFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspendedTwice &&
    billingPackage <= 35
  ) {
    overlayIssue = {
      message: (
        <span>
          Your trial has ended. Within 48 hours, you will lose access to your
          phone number(s) and messaging credits. Your number(s) will be released
          soon.
          <br />
          Use the invite code <strong>GRACE</strong> when choosing a plan and
          pay only $1 for 14 more days. This offer expires in 48 hrs.
        </span>
      ),
      url: billingURL,
      urlLabel: "Pick a plan",
      withOrganiztionSwitcher: true,
    };
  } else if (
    isBillingIssue === BillingIssueEnums.ThirdFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspendedTwice
  ) {
    overlayIssue = {
      message: (
        <span>
          Your account is now downgraded and we are releasing your number(s) and
          messaging credits in 48 hours. Renew now. Coupon <strong>ACT</strong>{" "}
          (500 bonus credits).
        </span>
      ),
      url: payBillingURL,
      urlLabel: "Pay invoice",
      withOrganiztionSwitcher: true,
    };
  } else if (
    isBillingIssue === BillingIssueEnums.ForthFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspendedTwice &&
    numberIsDeleted &&
    billingPackage <= 35
  ) {
    overlayIssue = {
      message: (
        <span>
          Your account is now closed. Your number(s) and messaging credits were
          deleted.
          <br />
          Use the invite code <strong>GRACE</strong> when choosing a plan and
          pay only $1 for 14 more days.
        </span>
      ),
      url: billingURL,
      urlLabel: "Pick a plan",
      withOrganiztionSwitcher: true,
    };
  } else if (
    isBillingIssue === BillingIssueEnums.ForthFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspendedTwice &&
    numberIsDeleted
  ) {
    overlayIssue = {
      message: (
        <span>
          After 3 auto-charge attempts your account is now closed, your
          number(s) released and your messaging credits deleted. To continue
          using this account, pick a new plan.
        </span>
      ),
      url: billingURL,
      urlLabel: "Pick a plan",
      withOrganiztionSwitcher: true,
    };
  }
  return overlayIssue;
};

export const getAccountIssueInfo = (
  isBillingIssue,
  isSuspended,
  numberIsDeleted,
  billingPackage,
  credits,
  billingIssueDays,
  isAdmin,
  isInvalidEmail
) => {
  if (
    isBillingIssue === BillingIssueEnums.NoFailedPaymentAttempt &&
    credits > 0 &&
    isInvalidEmail === 0
  ) {
    return null;
  }

  let issueKey = null;
  let icon = "payment";
  let alertClass = "";
  let message = "";
  let shortMessage = "";
  let dismissible = true;
  let sendLocked = false;
  let url = null;
  let isOverlay = false;
  let hasRefreshButton = false;
  let fontSize = "auto";
  if (isBillingIssue === BillingIssueEnums.FirstFailedPaymentAttempt) {
    issueKey = IssueKeyEnums.Soft;
    alertClass = "info";
    hasRefreshButton = true;
    if (billingPackage <= 35) {
      message =
        "Your trial has ended. Continue testing our platform by extending your trial period. Use the invite code <strong>GRACE</strong> when choosing a plan and pay only $1 for 14 more days.";
      shortMessage = "Your trial has ended. Please pick a plan.";
      url = isAdmin
        ? "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing"
        : null;
    } else {
      message =
        "Uh oh, it looks like your payment failed. Your account will be suspended in " +
        billingIssueDays +
        " days if you decide not to do anything.";
      shortMessage = "Your payment has been declined.";
      url = isAdmin
        ? "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/pay-billing"
        : null;
    }
  } else if (
    isBillingIssue === BillingIssueEnums.SecondFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspended
  ) {
    issueKey = IssueKeyEnums.Medium;
    alertClass = "warning";
    hasRefreshButton = true;
    if (billingPackage <= 35) {
      message =
        "Your trial has ended and sending messages is temporarily disabled. Your number(s) will be released soon. Continue testing our platform by extending your trial period. Use the invite code <strong>GRACE</strong> when choosing a plan and pay only $1 for 14 more days.";
      shortMessage = "Your trial has ended. Please pick a plan.";
      url = isAdmin
        ? "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing"
        : null;
      fontSize = "0.8rem";
    } else {
      message =
        "It looks like your payment failed again. Sending messages is disabled. Your account will lose access to your number(s) and all your credits in " +
        billingIssueDays +
        " days if this issue isn’t rectified asap.";
      shortMessage = "It looks like your payment failed again.";
      url = isAdmin
        ? "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/pay-billing"
        : null;
    }
    sendLocked = true;
  } else if (
    isBillingIssue === BillingIssueEnums.ThirdFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspendedTwice
  ) {
    issueKey = IssueKeyEnums.Hard;
    alertClass = "danger";
    isOverlay = true;
    hasRefreshButton = true;
    if (billingPackage <= 35) {
      message =
        "Within 48 hrs, you will lose access to your messaging credits, phone number(s) and access.";
      shortMessage = "Your trial has ended. Please pick a plan.";
    } else {
      message =
        "Payment Declined: In 48 hrs, your number(s) and messaging credits will be deleted and the account will be closed.";
      shortMessage = message;
    }
    dismissible = false;
    sendLocked = true;
  } else if (
    isBillingIssue === BillingIssueEnums.ForthFailedPaymentAttempt &&
    isSuspended === SuspendedAccountEnums.AccountSuspendedTwice &&
    numberIsDeleted
  ) {
    issueKey = IssueKeyEnums.Inactive;
    alertClass = "inactive";
    isOverlay = true;
    hasRefreshButton = true;
    if (billingPackage <= 35) {
      message = "Your account is no longer active.";
      shortMessage = "Your trial has ended. Please pick a plan.";
    } else {
      message =
        "Account closed: Your number(s) and messaging credits have been deleted.";
      shortMessage = message;
    }
    dismissible = false;
    sendLocked = true;
  } else if (credits !== null && credits <= 0) {
    issueKey = IssueKeyEnums.NoCredits;
    icon = "warning";
    alertClass = "info";
    message = "You are out of credits.";
    shortMessage = message;
    sendLocked = true;
    if (isAdmin) {
      url =
        "/hub/menus/filter/settings/admin-settings/submenu/admin-billing/billing";
    }
  } else if (isInvalidEmail) {
    issueKey = IssueKeyEnums.InvalidEmail;
    icon = "warning";
    alertClass = "info";
    message = "Your email appears to be invalid. Click here to edit.";
    shortMessage = message;
    dismissible = false;
    sendLocked = false;
    if (isAdmin) {
      url = "/hub/menus/filter/settings/user-settings/submenu/user-profile";
    }
  }

  if (!message) {
    return null;
  }

  return {
    issueKey,
    icon,
    alertClass,
    message,
    shortMessage,
    dismissible,
    sendLocked,
    url,
    billingPackage,
    billingIssueDays,
    isOverlay,
    hasRefreshButton,
    fontSize,
  };
};

export const isAccountIssueModalIssueKey = (issueKey) => {
  return (
    [IssueKeyEnums.Soft, IssueKeyEnums.Medium, IssueKeyEnums.NoCredits].indexOf(
      issueKey
    ) !== -1
  );
};
