import React, { useCallback, useEffect, useRef, useState } from "react";
import Textarea from "react-textarea-autosize";
import { isGsmAlphabet } from "../../helpers";
import PropTypes from "prop-types";
import HighlightUnicode from "../hub/HelperComponents/HighlightUnicode";
import { removeMergeFieldBracketsFromString } from "../../utils/messagesHelpers";

const prepareStylePropertyName = (name) => {
  return name
    .replace(/^(-\w)/, function (m) {
      return m.substring(1).toLowerCase();
    })
    .replace(/(-\w)/g, function (m) {
      return m.substring(1).toUpperCase();
    });
};

const prepareHightlights = (textMessage) => {
  const now = Date.now();
  const hightlights = [];
  let portion = [];
  let lastGsm = null;
  const raw = textMessage
    ? removeMergeFieldBracketsFromString(textMessage)
    : "";
  if (raw) {
    for (let i = 0; i < raw.length; i += 1) {
      const currentGsm = isGsmAlphabet(raw[i]);
      if (lastGsm !== null && lastGsm !== currentGsm) {
        if (lastGsm) {
          hightlights.push(portion);
        } else {
          hightlights.push(
            <mark key={`${now}-${i}`} title="Non-GSM character">
              {portion}
            </mark>
          );
        }
        portion = [];
      }
      lastGsm = currentGsm;
      portion.push(raw[i]);
    }
  }
  if (lastGsm === null || lastGsm) {
    hightlights.push(portion);
  } else {
    hightlights.push(
      <mark key={`${now}`} title="Non-GSM character">
        {portion}
      </mark>
    );
  }

  // Fix for non-displayed last new line in HTML
  if (raw && raw[raw.length - 1] === "\n") {
    hightlights.push(<br />);
  }

  return hightlights;
};

const MAX_KEY_DELAY = 100;

const MessageTextarea = ({
  refCallback,
  minHeight = 36,
  maxHeight = 160,
  value,
  className,
  style = null,
  placeholder,
  maxLength,
  disabled,
  isShortCode,
  shortCodeSignaturePreview,
  senderNumber,
  name = undefined,
  rotatedResize = false,
  outerMaxHeight = false,
  onFocus,
  onChange,
  onHeightChange,
  onSave,
  numberSignature,
  restrictionHighlight,
  restrictedMaxLength,
}) => {
  const inputRef = useRef(null);
  const hightlightsRef = useRef(null);
  const keysDownInterval = useRef(null);
  const restrictionRef = useRef(null);

  const [initialized, setInitialized] = useState(false);
  const [lastIsShortcode, setLastIsShortcode] = useState(null);
  const [focus, setFocus] = useState(false);
  const [lastRawValue, setLastRawValue] = useState("");
  const [hightlightsValue, setHightlightsValue] = useState([]);
  const [lastRawSignature, setLastRawSignature] = useState("");
  const [hightlightsSignature, setHightlightsSignature] = useState([]);

  const [layoutWrapperStyle, setLayoutWrapperStyle] = useState(null);
  const [layoutDivStyle, setLayoutDivStyle] = useState(null);
  const [layoutInputStyle, setLayoutInputStyle] = useState(null);

  const [keysDown, setKeysDown] = useState({});
  const [releaseKeyTime, setReleaseKeyTime] = useState({});

  const [messageAllowed, setMessageAllowed] = useState("");
  const [additionalChars, setAdditionalChars] = useState("");

  if (isShortCode !== lastIsShortcode) {
    const newLayoutWrapperStyle = { ...layoutWrapperStyle };
    if (outerMaxHeight) {
      newLayoutWrapperStyle["maxHeight"] = isShortCode
        ? "calc(100% - 23px)"
        : "100%";
      newLayoutWrapperStyle["height"] = isShortCode
        ? "calc(100% - 23px)"
        : "100%";
      newLayoutWrapperStyle["top"] = isShortCode ? "23px" : "0";
    } else {
      newLayoutWrapperStyle["maxHeight"] = maxHeight;
      delete newLayoutWrapperStyle["height"];
      delete newLayoutWrapperStyle["top"];
    }

    setLastIsShortcode(isShortCode);
    setLayoutWrapperStyle(newLayoutWrapperStyle);

    if (inputRef && inputRef.current && onHeightChange) {
      onHeightChange(inputRef.current.offsetHeight + (isShortCode ? 23 : 0));
    }
  }

  // Parse value and highlight non-GSM characters
  let currentHightlightsValue = hightlightsValue;
  if (value !== lastRawValue) {
    currentHightlightsValue = [];
    currentHightlightsValue = prepareHightlights(value);
    setLastRawValue(value);
    setHightlightsValue(currentHightlightsValue);
  }

  // Parse signature and highlight non-GSM characters
  let currentHightlightsSignature = hightlightsSignature;
  if (shortCodeSignaturePreview !== lastRawSignature) {
    currentHightlightsSignature = prepareHightlights(shortCodeSignaturePreview);
    setLastRawSignature(shortCodeSignaturePreview);
    setHightlightsSignature(currentHightlightsSignature);
  }

  useEffect(() => {
    if (!initialized && inputRef && inputRef.current) {
      if (refCallback) {
        refCallback(inputRef);
      }

      const newLayoutWrapperStyle = outerMaxHeight
        ? {
            maxHeight: isShortCode ? "calc(100% - 23px)" : "100%",
            height: isShortCode ? "calc(100% - 23px)" : "100%",
            top: isShortCode ? "23px" : "0",
          }
        : { maxHeight };
      const newLayoutDivStyle = {};
      const newLayoutInputStyle = {
        borderWidth: 0,
        borderRadius: 0,
        margin: 0,
        resize: "none",
        minHeight: "100%",
      };
      const styles = window.getComputedStyle(inputRef.current, null);

      const copyWrapperStyles = [
        "margin",
        "margin-left",
        "margin-right",
        "margin-top",
        "margin-bottom",
        "border-top-width",
        "border-right-width",
        "border-bottom-width",
        "border-left-width",
        "border-top-style",
        "border-right-style",
        "border-bottom-style",
        "border-left-style",
        "border-top-color",
        "border-right-color",
        "border-bottom-color",
        "border-left-color",
        "border-bottom-left-radius",
        "border-bottom-right-radius",
        "border-top-left-radius",
        "border-top-right-radius",
      ];
      const copyDivStyles = [
        "padding",
        "padding-left",
        "padding-right",
        "padding-top",
        "padding-bottom",
        "font",
        "font-family",
        "font-size",
        "white-space",
        "outline",
        "line-height",
      ];

      for (let i = 0, n = styles.length, property, value; i < n; i++) {
        if (copyWrapperStyles.indexOf(styles[i]) !== -1) {
          if ((value = styles.getPropertyValue(styles[i])) !== "") {
            property = prepareStylePropertyName(styles[i]);
            newLayoutWrapperStyle[property] = value;
          }
        } else if (copyDivStyles.indexOf(styles[i]) !== -1) {
          if ((value = styles.getPropertyValue(styles[i])) !== "") {
            property = prepareStylePropertyName(styles[i]);
            newLayoutDivStyle[property] = value;
          }
        }
      }
      setInitialized(true);
      setLayoutWrapperStyle(newLayoutWrapperStyle);
      setLayoutDivStyle(newLayoutDivStyle);
      setLayoutInputStyle(newLayoutInputStyle);
    }
  }, [initialized, isShortCode, maxHeight, outerMaxHeight, refCallback]);

  const onHeightChangeInner = (height) => {
    if (!outerMaxHeight) {
      setLayoutWrapperStyle({
        ...layoutWrapperStyle,
        height: height >= minHeight ? height : minHeight,
        overflow: height > maxHeight ? "hidden auto" : "hidden",
      });
    }
    if (onHeightChange) {
      onHeightChange(height + (isShortCode ? 23 : 0));
    }
  };

  const onFocusInner = (e) => {
    setFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const onBlurInner = () => {
    setFocus(false);
    setKeysDown({});
    setReleaseKeyTime({});
  };

  // Send message by ctrl (cmd) + enter
  useEffect(() => {
    // Ensure that multiple key presses send it once 300 milliseconds
    if ((keysDown["Control"] || keysDown["metaKey"]) && keysDown["Enter"]) {
      if (keysDownInterval.current) clearInterval(keysDownInterval.current);
      keysDownInterval.current = setTimeout(() => {
        onSave();
        keysDownInterval.current = null;
      }, 300);
    }
  }, [keysDown, onSave]);

  const onKeyDown = useCallback(
    (event) => {
      const { key, metaKey } = event;
      if (key === "Control" || key === "Enter" || metaKey) {
        const time = new Date().getTime();
        if (
          (releaseKeyTime[key] && time < releaseKeyTime[key] + MAX_KEY_DELAY) ||
          (releaseKeyTime["metaKey"] &&
            time < releaseKeyTime["metaKey"] + MAX_KEY_DELAY)
        ) {
          return;
        }
        setKeysDown((oldKeysDown) => ({
          ...oldKeysDown,
          [key]: true,
          metaKey: metaKey,
        }));
      }
    },
    [releaseKeyTime]
  );

  const onKeyUp = useCallback((event) => {
    const { key, metaKey } = event;
    if (key === "Control" || key === "Enter" || metaKey) {
      setKeysDown((oldKeysDown) => ({
        ...oldKeysDown,
        [key]: false,
        metaKey: metaKey,
      }));
      setReleaseKeyTime((oldReleaseKeyTime) => ({
        ...oldReleaseKeyTime,
        [key]: new Date().getTime(),
        metaKey: new Date().getTime(),
      }));
    }
  }, []);

  const getMessageParts = useCallback(() => {
    // Adding two characters for signature because of two new lines before the signature in the text area
    const signatureLength = numberSignature.length + 2;
    const messageAllowed = value.slice(
      0,
      restrictedMaxLength - signatureLength
    );
    const additionalChars = value.slice(restrictedMaxLength - signatureLength);

    return {
      messageAllowed,
      additionalChars,
    };
  }, [value, restrictedMaxLength, numberSignature]);

  useEffect(() => {
    const { messageAllowed, additionalChars } = getMessageParts();
    setMessageAllowed(messageAllowed);
    setAdditionalChars(additionalChars);
  }, [getMessageParts]);

  return (
    <>
      <div
        className={`position-relative form-control-wrapper message-textarea${
          rotatedResize ? " rotated-resize" : ""
        }${focus ? " focus" : ""}`}
        style={{ ...style, ...layoutWrapperStyle }}
      >
        {!restrictionHighlight && (
          <div className="highlights">
            <div ref={hightlightsRef} style={layoutDivStyle}>
              {currentHightlightsValue}
            </div>
          </div>
        )}
        <Textarea
          inputRef={inputRef}
          name={name}
          className={`${className} ${disabled ? "cursor-not-allowed" : ""}`}
          style={layoutInputStyle}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          onFocus={onFocusInner}
          onBlur={onBlurInner}
          onChange={onChange}
          onHeightChange={onHeightChangeInner}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          autoFocus={true}
        />
        {restrictionHighlight && (
          <div
            ref={restrictionRef}
            className="messages-card__textarea-field-highlight"
            style={{
              width: inputRef.current?.offsetWidth,
              maxWidth: inputRef.current?.offsetWidth,
            }}
          >
            <span>
              <HighlightUnicode text={messageAllowed} />
            </span>
            <span className="with-red">{additionalChars}</span>
          </div>
        )}
      </div>
      {isShortCode && (
        <div className="signature highlights-text">
          <span className="mr-auto info-text">{hightlightsSignature}</span>
          <a
            className="btn btn-link btn-sm"
            href={`#modal-signature/${senderNumber.number}`}
          >
            Set Signature
          </a>
        </div>
      )}
    </>
  );
};

MessageTextarea.propTypes = {
  restrictionHighlight: PropTypes.bool,
  restrictedMaxLength: PropTypes.number,
  numberSignature: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MessageTextarea.defaultProps = {
  restrictionHighlight: false,
  restrictedMaxLength: 0,
  numberSignature: 0,
};

export default MessageTextarea;
