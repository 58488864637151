import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ReactComponent as CircledQuestionMarkGrey } from "../../../assets/img/icons-new/people-tab/number-status/number-status-mid-gray.svg";
import SpanWithTooltip from "../../SpanWithTooltip";

const HeaderButton = (props) => {
  const {
    text,
    className,
    icon,
    more,
    help,
    onClick,
    credits,
    toolTipDisabled,
    toolTipTitle,
    buttonActive,
    moreToolTipTitle,
    moreToolTipDisabled,
    morePopover,
    openMorePopOver,
    disabled,
  } = props;
  const [creditAdded, setCreditAdded] = useState(false);

  useEffect(() => {
    setCreditAdded(true);
    setTimeout(() => {
      setCreditAdded(false);
    }, 1000);
  }, [credits]);

  return (
    <div className="header-button flexer-col">
      <div
        className={clsx("flexer-row", {
          "cursor-pointer": toolTipDisabled,
        })}
      >
        <div onClick={onClick}>
          <SpanWithTooltip disabled={toolTipDisabled} title={toolTipTitle}>
            <div
              className={clsx(
                "header-button__element btn",
                className,
                { active: buttonActive && !disabled },
                { "with-icon": icon },
                { "with-more": more },
                { "yellow-bg": creditAdded && help }
              )}
            >
              {icon}
              <small className={clsx(icon && "ml-2")}>{text}</small>
              {help && (
                <CircledQuestionMarkGrey
                  className="d-sm-none ml-1"
                  height={13}
                  width={13}
                />
              )}
            </div>
          </SpanWithTooltip>
        </div>
        {more && (
          <>
            <SpanWithTooltip
              disabled={moreToolTipDisabled}
              title={moreToolTipTitle}
            >
              <div
                className={clsx("header-button__more", {
                  active: buttonActive && !disabled,
                })}
                onClick={openMorePopOver}
              >
                <span className="header-button__more-arrow"></span>
              </div>
            </SpanWithTooltip>
            {morePopover}
          </>
        )}
      </div>
      {help && (
        <span className="header-button__help d-none d-sm-block">{help}</span>
      )}
    </div>
  );
};

HeaderButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  more: PropTypes.bool,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toolTipDisabled: PropTypes.bool,
  toolTipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonActive: PropTypes.bool,
  moreToolTipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  moreToolTipDisabled: PropTypes.bool,
  morePopover: PropTypes.node,
  openMorePopOver: PropTypes.func,
  disabled: PropTypes.bool,
};

HeaderButton.defaultProps = {
  type: "button",
  className: "muted",
  icon: null,
  more: false,
  help: "",
  toolTipDisabled: true,
  toolTipTitle: "",
  buttonActive: false,
  moreToolTipTitle: "",
  moreToolTipDisabled: true,
  morePopover: <></>,
  openMorePopOver: () => {},
  disabled: false,
};

export default HeaderButton;
