import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import NotificationBanner from "../NotificationBanner";
import { ReactComponent as VacationReplyIcon } from "../../../../assets/img/icons-new/general/umbrella/umbrella-black.svg";
import { ReactComponent as AutoResponderIcon } from "../../../../assets/img/icons-new/general/auto-responder/auto-responder-black.svg";

const AutoResponderBanner = (props) => {
  const { isVacationReplyOn, isAutoResponderOn, withCloseBtn, className } =
    props;
  const [notificationBannerIsOpen, setNotificationBannerIsOpen] =
    useState(true);

  const onClickClose = useCallback(() => {
    setNotificationBannerIsOpen(false);
  }, []);

  if (isAutoResponderOn && notificationBannerIsOpen) {
    return (
      <div className={className}>
        <NotificationBanner
          text={
            <span>
              <AutoResponderIcon height={12} width={12} /> After Hours Reply: On
            </span>
          }
          className={"m-0 auto-responder-banner-container"}
          color={"yellow"}
          containerClassName={"auto-responder-banner"}
          onClickClose={withCloseBtn ? onClickClose : null}
        />
      </div>
    );
  }

  if (isVacationReplyOn && notificationBannerIsOpen) {
    return (
      <div className={className}>
        <NotificationBanner
          text={
            <span>
              <VacationReplyIcon height={12} width={12} /> Vacation Reply: On
            </span>
          }
          className={"m-0 auto-responder-banner-container"}
          color={"yellow"}
          containerClassName={"auto-responder-banner"}
          onClickClose={withCloseBtn ? onClickClose : null}
        />
      </div>
    );
  }

  return null;
};

AutoResponderBanner.propTypes = {
  isVacationReplyOn: PropTypes.bool,
  isAutoResponderOn: PropTypes.bool,
  withCloseBtn: PropTypes.bool,
};

AutoResponderBanner.defaultProps = {
  isVacationReplyOn: false,
  isAutoResponderOn: false,
  withCloseBtn: false,
};

export default AutoResponderBanner;
