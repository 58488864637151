const initialState = {
  // DELETE FILESTACK FILES
  deleteFilestackFilesStatus: "",
};

export default function filestack(state = initialState, action) {
  switch (action.type) {
    case "FILE_STACK/DELETE_FILE_STACK_FILES": {
      return {
        ...state,
        deleteFilestackFilesStatus: "loading",
      };
    }

    case "FILE_STACK/DELETE_FILE_STACK_FILES.SUCCESS": {
      return {
        ...state,
        deleteFilestackFilesStatus: "success",
      };
    }

    case "FILE_STACK/DELETE_FILE_STACK_FILES.ERROR": {
      return {
        ...state,
        deleteFilestackFilesStatus: "error",
      };
    }

    default:
      return state;
  }
}
