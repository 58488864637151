// Microsoft Edge Helpers
export const MICROSOFT_EDGE_EXTENSION_LINK =
  "https://microsoftedge.microsoft.com/addons/detail/text-anywhere-by-pastors/kpfpaoicjifnhahdghfhmpnnknclhpom";
export const MICROSOFT_EDGE_EXTENSION_ID = "kpfpaoicjifnhahdghfhmpnnknclhpom";

export const isMicrosoftEdgeBrowser = () => {
  const userAgent = navigator.userAgent;
  return /Edg/.test(userAgent);
};

// Firefox Helpers
export const FIREFOX_EXTENSION_LINK =
  "https://addons.mozilla.org/en-US/firefox/addon/text-anywhere-by-pastorsline/";

export const isFirefoxBrowser = () => {
  const hasInstallTrigger = typeof InstallTrigger !== "undefined";
  const userAgent = navigator.userAgent;
  return /Firefox/.test(userAgent) || hasInstallTrigger;
};

// Safari
export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.includes("safari") && !userAgent.includes("chrome") && !isChrome()
  );
};

// Chrome helpers
export const CHROME_EXTENSION_LINK =
  "https://chromewebstore.google.com/detail/text-anywhere-by-pastorsl/epokpfbnfodeidpliggaehgnafpnjddo";
export const CHROME_EXTENSION_ID = "epokpfbnfodeidpliggaehgnafpnjddo";

export const isChromeOnMobileBrowser = () => {
  const userVendor = navigator.vendor;
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Google Inc/.test(userVendor) || /CriOS/.test(userAgent);
};

export const isChrome = () => {
  if (isFirefoxBrowser() || isMicrosoftEdgeBrowser()) return false;
  if (window.innerWidth < 992) {
    return isChromeOnMobileBrowser();
  } else {
    const userAgent = navigator.userAgent;

    return /Chrome/.test(userAgent) && !/Edge|Edg|OPR/.test(userAgent);
  }
};
