const initialState = {
  // Audio Uploads data
  data: {},
  dataStatus: {},

  // Add Audio Upload
  addId: null,
  addStatus: null,
  addErrors: null,
};

export default function reducer(state = initialState, action) {
  let data = null;
  let dataStatus = null;

  switch (action.type) {
    // Add Audio Upload
    case "AUDIO_UPLOAD/ADD":
      return {
        ...state,
        addId: null,
        addStatus: "loading",
        addErrors: null,
      };

    case "AUDIO_UPLOAD/ADD.SUCCESS":
      data = state.data;
      dataStatus = state.dataStatus;
      data[action.payload.audio_upload.id] = action.payload.audio_upload;
      dataStatus[action.payload.audio_upload.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        addId: action.payload.audio_upload.id,
        addStatus: "success",
        addErrors: null,
      };

    case "AUDIO_UPLOAD/ADD.ERROR":
      let errors = null;
      if (action.payload && typeof action.payload.errors !== "undefined") {
        errors = action.payload.errors;
      } else if (
        action.payload &&
        typeof action.payload.message !== "undefined"
      ) {
        errors = action.payload.message;
      }
      return {
        ...state,
        addId: null,
        addStatus: "error",
        addErrors: errors,
      };

    case "AUDIO_UPLOAD/ADD.CLEAR":
      return {
        ...state,
        addId: null,
        addStatus: null,
        addErrors: null,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
