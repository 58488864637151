import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { formatPhoneNumber } from "../../../helpers";
import Highlighter from "react-highlight-words";
import ThreadItemsNotfications from "./ThreadItemsNotfications";
// new icon

function ThreadItemMessageContent(props) {
  const {
    text,
    withContactDetails,
    undelivered_count,
    reprocessed_count,
    unread_count,
    icon,
    fromPrefix,
    searchedQuery,
    showCheckBox,
    contact,
    isContactUpdating,
    textClassName,
  } = props;
  const showText =
    !withContactDetails && (text || undelivered_count > 0 || unread_count > 0);

  const showNotifications =
    !showCheckBox &&
    !withContactDetails &&
    (unread_count > 0 || undelivered_count > 0 || reprocessed_count > 0);

  return (
    <>
      {showText && (
        <div className="message-info">
          {icon && <div className="icon">{icon}</div>}
          <p className={clsx("message-text", textClassName)}>
            {fromPrefix}
            <Highlighter
              highlightClassName="bg-yellow"
              searchWords={searchedQuery ? [searchedQuery] : []}
              textToHighlight={text}
            />
          </p>

          <ThreadItemsNotfications
            undeliveredCount={undelivered_count}
            unreadCount={unread_count}
            reprocessedCount={reprocessed_count}
            showNotifications={showNotifications}
          />
        </div>
      )}
      {withContactDetails && contact && (
        <p className="message-text">
          {/* phone number to show at message content line */}
          {isContactUpdating
            ? "updating..."
            : formatPhoneNumber(
                "" +
                  (contact?.country ? contact?.country.phonecode : "") +
                  contact?.phone_number
              )}
        </p>
      )}
    </>
  );
}

ThreadItemMessageContent.propTypes = {
  text: PropTypes.string,
  withContactDetails: PropTypes.bool,
  undelivered_count: PropTypes.number,
  reprocessed_count: PropTypes.number,
  unread_count: PropTypes.number,
  icon: PropTypes.element,
  fromPrefix: PropTypes.string,
  isSearchedThread: PropTypes.bool,
  searchedQuery: PropTypes.string,
  showCheckBox: PropTypes.bool,
  contact: PropTypes.object,
  isContactUpdating: PropTypes.bool,
  isMultipleContacts: PropTypes.bool,
  item_key: PropTypes.string,
  itemKey: PropTypes.string,
  contacts_ids: PropTypes.array,
  textClassName: PropTypes.string,
};

ThreadItemMessageContent.defaultProps = {
  textClassName: "",
};

export default ThreadItemMessageContent;
