import Pusher from "pusher-js";
import PusherActions from "./actions";
import AxiosConfig from "../AxiosConfig";

export default class PusherConfig {
  static _socket = null;
  static _channel = null;

  static key = process.env.REACT_APP_PUSHER_KEY;
  static cluster = process.env.REACT_APP_PUSHER_CLUSTER;

  static userId = null;
  static companyId = null;
  static numbersFilter = null;
  static ignoreOutgoingLogs = [];

  static init(companyId, userId, dispatchEvent) {
    if (PusherConfig._socket) {
      PusherConfig._socket.disconnect();
    }

    PusherConfig.companyId = companyId;
    PusherConfig.userId = userId;

    PusherConfig._socket = new Pusher(PusherConfig.key, {
      cluster: PusherConfig.cluster,
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_API_URL}pusher/auth.json`,
        headers: { Authorization: `Bearer ${AxiosConfig._token}` },
      },
    });
    PusherConfig._socket.connection.bind("error", function (err) {
      if (err.error && err.error?.data?.code === 4004) {
        console.log("Pusher connection failed - over limit");
      } else {
        console.log("Pusher connection failed");
      }
    });
    PusherConfig._channel = PusherConfig._socket.subscribe(
      "private-channel-" + userId
    );
    PusherConfig._channel = PusherConfig._socket.subscribe(
      `private-channel-${userId}-${companyId}`
    );
    PusherConfig._bindEvents(dispatchEvent);
  }

  static setNumbersFilter(numberFilter) {
    PusherConfig.numbersFilter = numberFilter;
  }

  static _bindEvents(dispatchEvent) {
    Object.keys(PusherActions).forEach((key) => {
      const segmentActions = PusherActions[key];

      Object.keys(segmentActions).forEach((actionKey) => {
        const action = segmentActions[actionKey];
        const { event, timeout, callback } = action();

        PusherConfig._channel.bind(event, (data) => {
          if (timeout) {
            setTimeout(
              () => callback(PusherConfig, dispatchEvent, data),
              timeout
            );
          } else {
            callback(PusherConfig, dispatchEvent, data);
          }
        });
      });
    });
  }

  static addIgnoreLogId(logId) {
    PusherConfig.ignoreOutgoingLogs.push(logId);
  }
}
