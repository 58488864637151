import React from "react";
import CustomModal from "./CustomModal";
import { formatPhoneNumber } from "../../helpers";
import { ReactComponent as UnsubscribedIcon } from "../../assets/img/icons-new/number-status/unsubscribed/unsubscribed-gray.svg";
import { ReactComponent as OptedOutIcon } from "../../assets/img/icons-new/number-status/opted-out/opted-out-white.svg";

const ContactStatusInfoModal = (props) => {
  const {
    isUnsubscribed,
    unsubedByUser,
    contactFullName,
    contactFullNumber,
    isBlocked,
  } = props;

  if (isUnsubscribed && unsubedByUser) {
    return (
      <CustomModal
        {...props}
        className={"locked-info-modal"}
        dialogClassName={"modal-dialog-centered"}
        noHeader
        noFooter
        closeOnClick
      >
        <span className={"icon"}>
          <UnsubscribedIcon className={"icon-white"} width={18} height={18} />
        </span>
        <p className="modal-details">
          {unsubedByUser.first_name} {unsubedByUser.last_name}
          <br />
          {unsubedByUser.assigned_number && (
            <React.Fragment>
              &nbsp;(
              {formatPhoneNumber(unsubedByUser.assigned_number)})
              <br />
            </React.Fragment>
          )}
          unsubscribed this person.
          <br />
        </p>
      </CustomModal>
    );
  } else if (isUnsubscribed && !unsubedByUser) {
    return (
      <CustomModal
        {...props}
        className={"locked-info-modal"}
        dialogClassName={"modal-dialog-centered"}
        noHeader
        noFooter
        closeOnClick
      >
        <span className={"icon"}>
          <OptedOutIcon width={18} height={18} />
        </span>
        <p className={"modal-details"}>
          {contactFullName}
          {contactFullNumber && (
            <React.Fragment>
              &nbsp;({formatPhoneNumber(contactFullNumber)})<br />
            </React.Fragment>
          )}
          opted out from receiving additions SMS from this account.
        </p>
      </CustomModal>
    );
  } else if (isBlocked) {
    return (
      <CustomModal
        {...props}
        className={"locked-info-modal"}
        dialogClassName={"modal-dialog-centered"}
        noHeader
        noFooter
        closeOnClick
      >
        <span className={"icon"}>
          <UnsubscribedIcon className={"icon-white"} width={18} height={18} />
        </span>
        <p className="modal-details">This person is currently blocked.</p>
      </CustomModal>
    );
  }
  return null;
};

export default ContactStatusInfoModal;
