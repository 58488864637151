const initialState = {
  numberSettingsData: null,

  // My Signatures
  selectedNumberSignature: null,

  // Number from Choose Default Signature modal => Manage Signatures
  numberToAutoSelect: "",

  // Auto Responder
  autoResponderStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "MENUS_SETTINGS/SET_NUMBER_SETTINGS_DATA":
      return {
        ...state,
        numberSettingsData: action.data?.selectedNumber,
        isFromAdminSettings: action.data?.isFromAdminSettings,
      };

    case "MENUS_SETTINGS/SET_SELECTED_NUMBER_SIGNATURE":
      return {
        ...state,
        selectedNumberSignature: action.selectedNumberSignature,
      };
    case "NUMBERS/EDIT_NUMBER.SUCCESS": {
      const updatedNumberSettingsData =
        window?.location?.hash === "#modal-on-boarding"
          ? state?.numberSettingsData
          : {
              ...state.numberSettingsData,
              ...action.payload.data,
            };
      return {
        ...state,
        numberSettingsData: updatedNumberSettingsData,
      };
    }
    case "MENUS_SETTINGS/UPDATE_NUMBER_SETTINGS": {
      return {
        ...state,
        numberSettingsData: {
          ...state.numberSettingsData,
          [action.payload.field]: action.payload.data,
        },
        updateNumberSettingsStatus: "success",
      };
    }

    case "MENUS_SETTINGS/CLEAR_UPDATE_NUMBER_SETTINGS_STATUS": {
      return {
        ...state,
        updateNumberSettingsStatus: "",
      };
    }

    case "USER_SETTINGS/SET_DEFAULT_SIGNATURE.SUCCESS": {
      if (action.requestedFrom === "numbersRHS") {
        return {
          ...state,
          numberSettingsData: {
            ...state.numberSettingsData,
            signature: action.selectedSignature,
          },
        };
      }
      return state;
    }

    case "MENUS_SETTINGS/SET_NUMBER_TO_AUTO_SELECT": {
      return {
        ...state,
        numberToAutoSelect: action.numberToAutoSelect,
      };
    }

    case "MENUS_SETTINGS/SET_NUMBER_SETTINGS_DATA.CLEAR": {
      return {
        ...state,
        numberSettingsData: null,
      };
    }

    case "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER": {
      return {
        ...state,
        autoResponderStatus: "loading",
      };
    }
    case "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER.SUCCESS": {
      return {
        ...state,
        autoResponderStatus: "success",
      };
    }
    case "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER.ERROR": {
      return {
        ...state,
        autoResponderStatus: "error",
      };
    }

    case "MENUS_SETTINGS/UPDATE_AUTO_RESPONDER.CLEAR": {
      return {
        ...state,
        autoResponderStatus: "",
      };
    }

    default:
      return state;
  }
}
