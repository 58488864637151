import { updatePaginationCount } from "../helpers";

const initialState = {
  // Data
  dataStatus: {},
  data: {},

  // Index
  indexStatus: null,
  indexMoreStatus: null,
  indexErrors: null,
  index: null,
  indexPage: null,
  indexLoadedAll: null,
  indexQuery: null,
  pagination: {},

  // Add
  addStatus: null,
  addErrors: null,
  addId: null,

  // Attachments add
  addAttachmentsStatus: null,
  addAttachmentsErrors: null,
  addAttachmentsUrls: null,

  // Delete
  deleteStatus: null,
  deleteId: null,

  // Insert
  insertTo: null,
  insertShortLink: null,

  // Edit
  editShortLinkStatus: null,

  // Video add
  addVideoStatus: null,
  addVideoErrors: null,
  addVideoUrls: null,
};

export default function reducer(state = initialState, action) {
  let dataStatus = null;
  let data = null;
  let index = null;
  let shortLinks = null;

  switch (action.type) {
    // Index
    case "SHORT_LINKS/FETCH":
      return {
        ...state,
        indexStatus: "loading",
        indexMoreStatus: action.page > 1 ? "loading" : "",
      };

    case "SHORT_LINKS/FETCH.SUCCESS":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      data = state.data ? state.data : {};
      index = action.page === 1 ? [] : state.index ? state.index : [];
      shortLinks = action.payload.short_links;

      for (let i = 0; i < shortLinks.length; i += 1) {
        dataStatus[shortLinks[i].id] = "success";
        data[shortLinks[i].id] = shortLinks[i];
        index.push(shortLinks[i].id);
      }

      return {
        ...state,
        pagination: action.payload.pagination,
        dataStatus,
        data,
        indexStatus: "success",
        indexMoreStatus: action.page > 1 ? "success" : "",
        indexErrors: null,
        index,
        indexPage: action.page,
        indexLoadedAll: shortLinks.length === 0,
        indexQuery: action.query,
      };

    case "SHORT_LINKS/FETCH.ERROR":
      return {
        ...state,
        indexStatus: "error",
        indexMoreStatus: action.page > 1 ? "error" : "",
        indexErrors: null,
        index: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
      };

    case "SHORT_LINKS/FETCH.CLEAR":
      return {
        ...state,
        indexStatus: null,
        indexErrors: null,
        index: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
      };

    // View
    case "SHORT_LINKS/FETCH_SINGLE":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      dataStatus[action.shortLinkId] = "loading";
      return {
        ...state,
        dataStatus,
      };

    case "SHORT_LINKS/FETCH_SINGLE.SUCCESS":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      data = state.data ? state.data : {};

      dataStatus[action.shortLinkId] = "success";
      data[action.shortLinkId] = action.payload.short_link;
      return {
        ...state,
        dataStatus,
        data,
      };

    case "SHORT_LINKS/FETCH_SINGLE.ERROR":
      dataStatus = state.dataStatus ? state.dataStatus : {};
      dataStatus[action.shortLinkId] = "error";

      return {
        ...state,
        dataStatus,
      };

    // Add
    case "SHORT_LINKS/ADD":
      return {
        ...state,
        addStatus: "loading",
        addErrors: null,
        addId: null,
      };

    case "SHORT_LINKS/ADD.SUCCESS":
      dataStatus = { ...state.dataStatus };
      data = { ...state.data };
      index = [...state.index];

      dataStatus[action.payload.short_link.id] = "success";
      data[action.payload.short_link.id] = action.payload.short_link;
      index.unshift(action.payload.short_link.id);

      return {
        ...state,
        dataStatus,
        data,
        index,
        addStatus: "success",
        addErrors: null,
        addId: action.payload.short_link.id,
        pagination: updatePaginationCount(state.pagination, true),

        // reset index
        indexStatus: null,
        indexErrors: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
      };

    case "SHORT_LINKS/ADD.ERROR":
      return {
        ...state,
        addStatus: "error",
        addErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
        addId: null,
      };

    // Attachments add
    case "SHORT_LINKS/ADD_ATTACHMENTS":
      return {
        ...state,
        addAttachmentsStatus: "loading",
        addAttachmentsErrors: null,
        addAttachmentsUrls: null,
      };

    case "SHORT_LINKS/ADD_ATTACHMENTS.SUCCESS":
      return {
        ...state,
        addAttachmentsStatus: "success",
        addAttachmentsErrors: null,
        addAttachmentsUrls: action.payload.short_links.map(
          (shortLink) => shortLink.short_url
        ),

        // reset index
        indexStatus: null,
        indexErrors: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
      };

    case "SHORT_LINKS/ADD_ATTACHMENTS.ERROR":
      return {
        ...state,
        addAttachmentsStatus: "error",
        addAttachmentsErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
        addAttachmentsUrls: null,
      };

    case "SHORT_LINKS/ADD_ATTACHMENTS.CLEAR":
      return {
        ...state,
        addAttachmentsStatus: null,
        addAttachmentsErrors: null,
        addAttachmentsUrls: null,
      };

    // Attachments add
    case "SHORT_LINKS/ADD_VIDEO":
      return {
        ...state,
        addVideoStatus: "loading",
        addVideoErrors: null,
        addVideoUrls: null,
      };

    case "SHORT_LINKS/ADD_VIDEO.SUCCESS":
      return {
        ...state,
        addVideoStatus: "success",
        addVideoErrors: null,
        addVideoUrls: action.payload.short_links.map(
          (shortLink) => shortLink.short_url
        ),

        // reset index
        indexStatus: null,
        indexErrors: null,
        indexPage: null,
        indexLoadedAll: null,
        indexQuery: null,
      };

    case "SHORT_LINKS/ADD_VIDEO.ERROR":
      return {
        ...state,
        addVideoStatus: "error",
        addVideoErrors:
          typeof action?.error !== "undefined"
            ? action.error?.data?.errors
            : null,
        addVideoUrls: null,
      };

    case "SHORT_LINKS/ADD_VIDEO.CLEAR":
      return {
        ...state,
        addVideoStatus: null,
        addVideoErrors: null,
        addVideoUrls: null,
      };

    case "SHORT_LINKS/ADD.CLEAR":
      return {
        ...state,
        addStatus: null,
        addErrors: null,
        addId: null,
      };

    // Delete
    case "SHORT_LINKS/DELETE":
      return {
        ...state,
        deleteStatus: "loading",
        deleteId: action.shortLinkId,
      };

    case "SHORT_LINKS/DELETE.SUCCESS":
      dataStatus = { ...state.dataStatus };
      data = { ...state.data };
      index = [...state.index];

      if (typeof dataStatus[action.shortLinkId] !== "undefined") {
        delete dataStatus[action.shortLinkId];
      }
      if (typeof data[action.shortLinkId] !== "undefined") {
        delete data[action.shortLinkId];
      }
      if (index && index.indexOf(action.shortLinkId) >= 0) {
        index.splice(index.indexOf(action.shortLinkId), 1);
      }

      return {
        ...state,
        dataStatus,
        data,
        index,
        deleteStatus: "success",
        deleteId: action.shortLinkId,
        pagination: updatePaginationCount(state.pagination, false),
      };

    case "SHORT_LINKS/DELETE.ERROR":
      return {
        ...state,
        deleteStatus: "error",
        deleteId: action.shortLinkId,
      };

    case "SHORT_LINKS/DELETE.CLEAR":
      return {
        ...state,
        deleteStatus: null,
        deleteId: null,
      };

    // Insert
    case "SHORT_LINKS/INSERT":
      return {
        ...state,
        insertTo: action.insertTo,
        insertShortLink: action.shortLink,
      };

    case "SHORT_LINKS/INSERT.CLEAR":
      return {
        ...state,
        insertTo: null,
        insertShortLink: null,
      };

    case "SHORT_LINKS/EDIT_SHORT_LINKS":
      return {
        ...state,
        editShortLinkStatus: "loading",
      };

    case "SHORT_LINKS/EDIT_SHORT_LINKS.SUCCESS": {
      data = { ...state.data };

      data[action.payload.short_link.id] = action.payload.short_link;
      return {
        ...state,
        editShortLinkStatus: "success",
        data,
      };
    }

    case "SHORT_LINKS/EDIT_SHORT_LINKS.ERROR":
      return {
        ...state,
        editShortLinkStatus: "error",
      };

    case "SHORT_LINKS/EDIT_SHORT_LINKS.CLEAR":
      return {
        ...state,
        editShortLinkStatus: null,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
