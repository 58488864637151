import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as twilioActions from "../../../actions/twilio";

export const useTwilioStore = () => {
  const dispatch = useDispatch();
  const _twilio = useSelector((store) => store.twilio);

  const setIncomingCallStatus = useCallback(
    (status) => dispatch(twilioActions.setIncomingCallStatus(status)),
    [dispatch]
  );

  const setIncomingCallsParameters = useCallback(
    (params) => dispatch(twilioActions.setIncomingCallsParameters(params)),
    [dispatch]
  );

  return {
    twilio: _twilio,
    setIncomingCallStatus,
    setIncomingCallsParameters,
  };
};
