import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import ModalWrapper from "../Helpers/ModalWrapper";
import ButtonWithSpinner from "../../hub/HelperComponents/ButtonWithSpinner";
import useShortLinkStore from "../../../utils/hooks/ReduxHooks/shortLinkStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import useMainStore from "../../../utils/hooks/ReduxHooks/mainStore";
import {
  injectTextInfoTextField,
  restoreCursorPosition,
} from "../../../utils/messagesHelpers";
import { useGetBitratedVideoDetails } from "../../../utils/hooks/useGetBitratedVideoDetails";
import { useNumbersStore } from "../../../utils/hooks/store/useNumbersStore";
import { isTollFreeOrShortCodeNumberSelectedAsSenderNumber } from "../../../utils/numbersHelpers";
import { acceptedVideoTypes } from "./videoConstants";
import { getVideoHandler } from "../../../utils/filestackHelpers";
import useMediaStore from "../../../utils/hooks/ReduxHooks/mediaStore";
import { history } from "../../../store";
import clsx from "clsx";
import { VIDEO_LIBRARY_ROUTE } from "../../Menus/routes";

const VideoConfirmationModal = (props) => {
  const {
    show,
    closeModal,
    textAreaRef,
    onMessageTextChange,
    onWarningTextChange,
    uploadedVideo,
    onAddVideos,
    onVideoError,
  } = props;
  const [recursiveCount, setRecursiveCount] = useState(1);
  const [isVideoProcessing, setIsVideoProcessing] = useState(false);

  // Redux store
  const { addVideoShortLink, clearAddVideoShortLink, shortLinks } =
    useShortLinkStore();
  const { companies } = useCompaniesStore();
  const { addInfoSnackBar } = useMainStore();
  const { media, addMediaResouce, clearAddMediaResourceStatus } =
    useMediaStore();
  const { addMediaResourceStatus } = media;
  const { numbers } = useNumbersStore();
  const { senderNumber } = numbers;
  const { currentCompany } = companies;
  const { addVideoStatus, addVideoUrls, addVideoErrors } = shortLinks;

  // Routes
  const params = useParams();

  const videoLibraryLibraryTemplatePrefix = useMemo(() => {
    if (params?.submenu === VIDEO_LIBRARY_ROUTE) {
      return "Add";
    }
    return "Attach";
  }, [params]);

  const isVideoLibraryTemplate = useMemo(() => {
    return history.location.pathname.includes(
      "video-library/submenu/video-library"
    );
  }, []);

  const isSenderNumberSelectedAsShortcodeOrTollFree = useMemo(
    () => isTollFreeOrShortCodeNumberSelectedAsSenderNumber(senderNumber),
    [senderNumber]
  );

  const { getBitratedVideo } = useGetBitratedVideoDetails(
    isSenderNumberSelectedAsShortcodeOrTollFree,
    recursiveCount,
    setRecursiveCount
  );

  useEffect(() => {
    if (addVideoStatus === "error") {
      const errorObj =
        addVideoErrors &&
        Array.isArray(addVideoErrors) &&
        Boolean(addVideoErrors.length)
          ? addVideoErrors[0]
          : {};
      let errorMessage =
        errorObj?.url?.unique ||
        "Something went wrong. Please try again Later!";
      addInfoSnackBar({
        msg: errorMessage,
        err: true,
      });
      clearAddVideoShortLink();
      closeModal();
    }
  }, [
    addInfoSnackBar,
    addVideoErrors,
    addVideoStatus,
    clearAddVideoShortLink,
    closeModal,
  ]);

  useEffect(() => {
    if (addMediaResourceStatus === "success") {
      clearAddMediaResourceStatus();
    }
  }, [addMediaResourceStatus, clearAddMediaResourceStatus]);

  useEffect(() => {
    // Inject the video link into the message
    if (addVideoStatus === "success") {
      let newValue = "";
      let restorePosition = null;
      try {
        ({ newValue, restorePosition } = injectTextInfoTextField(
          textAreaRef.current,
          addVideoUrls.join(" ")
        ));
      } catch (error) {
        textAreaRef.current.focus();
        if (error.message === "too-long") {
          const beggining =
            addVideoUrls.length > 1 ? "Video links" : "The Video link";
          onWarningTextChange(
            `${beggining} cannot be inserted because the message would exceed the limit of 1600 characters.`
          );
        }
      }

      clearAddVideoShortLink();
      onMessageTextChange(newValue);
      closeModal();
      if (restorePosition) {
        restoreCursorPosition(textAreaRef.current, restorePosition);
      }
    }
  }, [
    addVideoStatus,
    addVideoUrls,
    clearAddVideoShortLink,
    onMessageTextChange,
    onWarningTextChange,
    textAreaRef,
    closeModal,
  ]);

  const onClickAttachVideoAsUrl = useCallback(() => {
    const formData = {
      data: [],
    };
    formData.data.push({
      shortname: "Auto created link to video",
      url: uploadedVideo?.file?.url,
      link_type: 2,
    });
    addVideoShortLink(currentCompany?.id, formData);
  }, [addVideoShortLink, currentCompany, uploadedVideo]);

  const onClickedAttachVideo = useCallback(async () => {
    try {
      const newVideos = [];
      const dataForMediaResource = [];
      setIsVideoProcessing(true);
      const bitratedVideo = await getBitratedVideo(uploadedVideo);
      const videoHandlerForMediaResource = getVideoHandler(
        bitratedVideo?.file?.url
      );
      if (
        !uploadedVideo?.originalPath?.includes(
          "https://cdn.filestackcontent.com/"
        )
      ) {
        dataForMediaResource.push({
          type: "video",
          handle: videoHandlerForMediaResource,
          filename: uploadedVideo?.filename,
          url: bitratedVideo?.file?.url,
          thumbnail_url: bitratedVideo?.thumbnail,
        });
      }
      setRecursiveCount(1);
      setIsVideoProcessing(false);
      const bitratedMimetype = bitratedVideo?.result?.mime_type;
      if (bitratedVideo && acceptedVideoTypes.includes(bitratedMimetype)) {
        newVideos.push(bitratedVideo);
      } else {
        onVideoError(
          `Mismatch: Your video extension is (.${
            uploadedVideo?.originalFile.name.split(".")[1]
          }) but its MIME type shows as (${bitratedMimetype}). Please rectify & try again.`
        );
        return;
      }
      if (Boolean(dataForMediaResource?.length)) {
        addMediaResouce({ data: dataForMediaResource });
      }
      onAddVideos(newVideos);
      closeModal();
    } catch (error) {
      setIsVideoProcessing(false);
      onVideoError(error?.message || "Something went wrong");
    }
  }, [
    getBitratedVideo,
    uploadedVideo,
    onAddVideos,
    closeModal,
    onVideoError,
    addMediaResouce,
  ]);

  return (
    <ModalWrapper
      id="videoConfirmationModal"
      show={show}
      closeModal={closeModal}
      shakeModal={closeModal}
      className="video-confirmation-modal"
      hasCloseBtn={true}
    >
      <div className="video-confirmation-modal-body">
        <video preload="auto" width="100%" height="100%" controls>
          <source
            src={`${uploadedVideo?.file?.url}#t=0.1`}
            type={
              uploadedVideo.mimetype === "video/quicktime"
                ? "video/mp4"
                : uploadedVideo.mimetype
            }
          />
        </video>
      </div>

      <div
        className={clsx("video-confirmation-footer mb-4", {
          "justify-content-end": isVideoLibraryTemplate,
        })}
      >
        {!isVideoLibraryTemplate && (
          <ButtonWithSpinner
            loading={addVideoStatus === "loading"}
            disabled={addVideoStatus === "loading" || isVideoProcessing}
            bootstrapButtonClassName={
              "btn-outline-primary video-confirmation-border-buttons"
            }
            onClick={onClickAttachVideoAsUrl}
          >
            Send Video as URL (Hi-Res)
          </ButtonWithSpinner>
        )}
        <ButtonWithSpinner
          disabled={addVideoStatus === "loading" || isVideoProcessing}
          loading={isVideoProcessing}
          onClick={onClickedAttachVideo}
        >
          {isVideoProcessing
            ? "Processing"
            : `${videoLibraryLibraryTemplatePrefix} Real`}{" "}
          Video (Lo-res)
        </ButtonWithSpinner>
      </div>
    </ModalWrapper>
  );
};

VideoConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  textAreaRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onMessageTextChange: PropTypes.func.isRequired,
  onWarningTextChange: PropTypes.func.isRequired,
  uploadedVideo: PropTypes.object,
};

VideoConfirmationModal.defaultProps = {
  uploadedVideo: {},
};

export default VideoConfirmationModal;
