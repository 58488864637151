import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import VcfIcon from "../../../assets/img/vcf-icon.png";
import { ReactComponent as CloseWhiteSvg } from "../../../assets/img/icons/close-white.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/icons-new/general/close/close-gray.svg";
import TooltipWhenOverflow from "../HelperComponents/TooltipWhenOverflow";
import { isEmptyObject } from "../../../helpers";

const VCardThumbnail = (props) => {
  const {
    onClickRemove,
    hasCloseIcon,
    url,
    vCardDetails,
    isNewCloseIcon,
    className,
  } = props;

  return (
    <a
      className="d-inline-block position-relative mr-2 vcard-thumbnail mt-1"
      href={url}
      target="__blank"
    >
      <div
        className={clsx(
          "thumbnail-main-container flex-column cursor-pointer",
          className,
          {
            "pt-3": !isEmptyObject(vCardDetails),
          }
        )}
      >
        <img
          width={!isEmptyObject(vCardDetails) ? 40 : 55}
          height={!isEmptyObject(vCardDetails) ? 40 : 55}
          src={VcfIcon}
          alt="vcf-preview"
        />
        {!isEmptyObject(vCardDetails) && (
          <div className="d-flex align-items-center justify-content-start flex-wrap ml-1 mt-1">
            <strong className="m-0 p-0 thumbnail-detail-type">
              {vCardDetails?.type}
            </strong>
            <TooltipWhenOverflow
              title={`${vCardDetails?.first_name || ""} ${
                vCardDetails?.last_name || ""
              }`}
              className="m-0 p-0 thumbnail-detail-name"
              maxWidth={100}
            >
              <h6>
                {vCardDetails?.first_name || ""} {vCardDetails?.last_name || ""}
              </h6>
            </TooltipWhenOverflow>
          </div>
        )}
      </div>
      {hasCloseIcon && (
        <>
          {isNewCloseIcon ? (
            <div
              className="messages-card__textarea-image-close"
              onClick={onClickRemove}
            >
              <CloseWhiteSvg height={7} width={7} />
            </div>
          ) : (
            <div
              className="position-absolute close-icon-container"
              onClick={onClickRemove}
            >
              <CloseIcon />
            </div>
          )}
        </>
      )}
    </a>
  );
};

VCardThumbnail.propTypes = {
  url: PropTypes.string,
  onClickRemove: PropTypes.func.isRequired,
  hasCloseIcon: PropTypes.bool,
  isNewCloseIcon: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  vCardDetails: PropTypes.object,
  className: PropTypes.string,
};

VCardThumbnail.defaultProps = {
  hasCloseIcon: false,
  isNewCloseIcon: false,
  url: "",
  height: 100,
  width: 100,
  vCardDetails: {},
  className: "",
};

export default VCardThumbnail;
