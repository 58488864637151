import React from "react";

//new icons
import { ReactComponent as MobileIcon } from "../assets/img/icons-new/number-status/mobile/mobile-gray.svg";
import { ReactComponent as LandlineIcon } from "../assets/img/icons-new/number-status/landline/landline-gray.svg";
import { ReactComponent as VoipIcon } from "../assets/img/icons-new/number-status/voip/voip-gray.svg";
import { ReactComponent as InvalidNumberIcon } from "../assets/img/icons-new/number-status/invalid/invalid-gray.svg";
import { ReactComponent as DoLookupNewIcon } from "../assets/img/icons-new/general/search/search-gray.svg";
import { ReactComponent as UncheckedIcon } from "../assets/img/icons-new/number-status/unchecked/unchecked-gray.svg";

import SpanWithTooltip from "./SpanWithTooltip";

const defaultProps = {
  className: null,
  style: null,
  baseSize: 16,
  withoutLookup: false,
};

class LookupIcon extends React.PureComponent {
  render() {
    const { type, className, style, newLookupIcon, withoutLookup, baseSize } =
      this.props;
    if (type === "mobile") {
      return (
        <SpanWithTooltip title="Mobile">
          <MobileIcon
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (type === "landline") {
      return (
        <SpanWithTooltip title="Landline">
          <LandlineIcon
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (type === "voip") {
      return (
        <SpanWithTooltip title="VOIP">
          <VoipIcon
            className={className}
            style={style}
            width={baseSize}
            height={baseSize}
          />
        </SpanWithTooltip>
      );
    } else if (!withoutLookup) {
      if (newLookupIcon) {
        return (
          <SpanWithTooltip title="Invalid">
            <DoLookupNewIcon
              className={className}
              style={style}
              width={baseSize}
              height={baseSize}
            />
          </SpanWithTooltip>
        );
      } else if (type === null) {
        return (
          <SpanWithTooltip title="Format Error">
            <InvalidNumberIcon
              className={className}
              style={style}
              width={baseSize}
              height={baseSize}
            />
          </SpanWithTooltip>
        );
      } else {
        return (
          <SpanWithTooltip title="Unchecked">
            <UncheckedIcon
              className={className}
              style={style}
              width={baseSize}
              height={baseSize}
            />
          </SpanWithTooltip>
        );
      }
    }

    return null;
  }
}

LookupIcon.defaultProps = defaultProps;

export default LookupIcon;
