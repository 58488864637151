import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import withResponsive from "../withResponsive";
import LayoutDefault from "../components/LayoutDefault";
import {
  checkUserHasPermissionToRoute,
  hasNumberAssigned,
  isReactNativeApp,
} from "../helpers";
import AccessDeniedPage from "./AccessDeniedPage";
import NotificationBannerOverlayApp from "../components/elements/NotificationBannerOverlayApp";
import { history } from "../store";
import { ReactComponent as ShowInfo } from "../assets/img/icons-new/number-status/show-info/show-info-black.svg";
import { useIsAdminOrOwner } from "../utils/hooks/useIsAdminOrOwner";
import {
  ASSIGNED_NUMBER_ALERT_LINK,
  RETRY_RELOAD_CHUNK_ATTEMPTS,
} from "../utils/constants";
import {
  setIsOnPeopleTableView,
  setIsOnGroupsTableView,
  setRedirectedToDashboardWhenNoAssignedNumber,
} from "../actions/main";
import componentLoader from "../utils/componentLoader";
import { getInitialRedirectRoute } from "../utils/settingsHelpers";
import RenderTopNotificationBannerOverlayApp from "../components/hub/HelperComponents/RenderTopNotificationBannerOverlayApp";
import useBreakpoint from "../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../utils/breakpoints";
import { hasSrcOneOrTwoNumbers } from "../utils/numbersHelpers";

const HubContent = lazy(() =>
  componentLoader(
    () => import("../components/hub/HubContent/HubContent"),
    RETRY_RELOAD_CHUNK_ATTEMPTS
  )
);

function mapStateToProps(store) {
  return {
    userPermissions: store.users.permissions,
    numbers: store.numbers.numbers,
    numbersStatus: store.numbers.numbersStatus,
    currentCompany: store.companies.currentCompany,
    fetchSettingsStatus: store.users.fetchSettingsStatus,
    userSettings: store.users.settings,
    redirectedToDashboardWhenNoAssignedNumber:
      store.main.redirectedToDashboardWhenNoAssignedNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsOnPeopleTableView: (val) => dispatch(setIsOnPeopleTableView(val)),
    setIsOnGroupsTableView: (val) => dispatch(setIsOnGroupsTableView(val)),
    setRedirectedToDashboardWhenNoAssignedNumber: (isRedirected) =>
      dispatch(setRedirectedToDashboardWhenNoAssignedNumber(isRedirected)),
  };
}

const UnassignedNumberNotification = ({
  currentCompany,
  isSrcOneOrTwoNumberAvailable,
}) => {
  const [show, setShow] = useState(true);

  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);
  const breakpoint = useBreakpoint();
  const iconsSize = useMemo(
    () => (isMdOrBelowBreakpoint(breakpoint) ? "18px" : "23px"),
    [breakpoint]
  );

  const renderBannerBtnText = useMemo(() => {
    if (isAdminOrOwner) {
      if (isSrcOneOrTwoNumberAvailable) {
        return "Go To Admin Settings";
      }
      return "Add New Number";
    }
    return "";
  }, [isAdminOrOwner, isSrcOneOrTwoNumberAvailable]);

  const onClickedBannerBtn = useCallback(() => {
    if (isAdminOrOwner) {
      if (isSrcOneOrTwoNumberAvailable) {
        history.push(
          "/hub/menus/filter/settings/admin-settings/submenu/admin-numbers"
        );
      } else {
        history.push(ASSIGNED_NUMBER_ALERT_LINK);
      }
    }
  }, [isAdminOrOwner, isSrcOneOrTwoNumberAvailable]);

  if (!show) return null;

  return (
    <RenderTopNotificationBannerOverlayApp show={show}>
      <NotificationBannerOverlayApp
        text={
          <div className="d-flex align-items-center">
            <ShowInfo width={iconsSize} className="mr-2" />
            You have Not Set Up An Assigned Number.
            {!isAdminOrOwner ? (
              <span className="ml-1"> Contact an Admin</span>
            ) : (
              ""
            )}
          </div>
        }
        buttonText={renderBannerBtnText}
        color="yellow"
        onClickButton={onClickedBannerBtn}
        onClickClose={() => setShow(false)}
      />
    </RenderTopNotificationBannerOverlayApp>
  );
};

const HubPage = (props) => {
  const {
    // Routes
    history,

    // Redux props
    fetchSettingsStatus,
    userSettings,
    userPermissions,
    currentCompany,
    redirectedToDashboardWhenNoAssignedNumber,
    numbersStatus,
    numbers,

    // Redux func
    setIsOnPeopleTableView,
    setIsOnGroupsTableView,
    setRedirectedToDashboardWhenNoAssignedNumber,
    // responsive
    breakpoint,
  } = props;

  const pathname = history.location.pathname;

  let userHasPermission = checkUserHasPermissionToRoute(
    pathname,
    userPermissions
  );
  if (
    pathname.includes("admin-organization-profile") &&
    !currentCompany?.metadata?.can_view
  ) {
    userHasPermission = false;
  }

  const withAssignedNumber = useMemo(
    () => hasNumberAssigned(numbers),
    [numbers]
  );

  const isSrcOneOrTwoNumberAvailable = useMemo(
    () => hasSrcOneOrTwoNumbers(numbers),
    [numbers]
  );

  // redirect to dashboard if there isn't a number assigned
  useEffect(() => {
    if (!redirectedToDashboardWhenNoAssignedNumber) {
      if (
        !withAssignedNumber &&
        numbersStatus === "success" &&
        !isReactNativeApp() &&
        ["/hub/messages", "/hub/messages/"].includes(pathname)
      ) {
        const redirectRoute = getInitialRedirectRoute();
        history.push(redirectRoute);
      }
      setRedirectedToDashboardWhenNoAssignedNumber(true);
    }
  }, [
    redirectedToDashboardWhenNoAssignedNumber,
    setRedirectedToDashboardWhenNoAssignedNumber,
    withAssignedNumber,
    numbersStatus,
    history,
    pathname,
  ]);

  useEffect(() => {
    if (fetchSettingsStatus === "success") {
      setIsOnPeopleTableView(userSettings.people_default_view !== "column");
      setIsOnGroupsTableView(userSettings.groups_default_view !== "column");
    }
  }, [
    fetchSettingsStatus,
    setIsOnPeopleTableView,
    setIsOnGroupsTableView,
    userSettings,
  ]);

  return (
    <LayoutDefault className="inbox position-relative" breakpoint={breakpoint}>
      <>
        {!withAssignedNumber && numbersStatus === "success" && (
          <UnassignedNumberNotification
            currentCompany={props.currentCompany}
            isSrcOneOrTwoNumberAvailable={isSrcOneOrTwoNumberAvailable}
          />
        )}
        {userHasPermission ? <HubContent {...props} /> : <AccessDeniedPage />}
      </>
    </LayoutDefault>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(HubPage));
