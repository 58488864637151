import React from "react";
import PropTypes from "prop-types";
import SortingPopover, { getSortIcon } from "./HelperComponents/SortingPopover";
import SpanWithTooltip from "../SpanWithTooltip";

const SortingActions = (props) => {
  const { listItems, handleActiveItem, activeItem, className, isOnMobileMode } =
    props;

  const getActionBarIcon = () => {
    return getSortIcon(activeItem, isOnMobileMode);
  };

  return (
    <div>
      <SpanWithTooltip
        id="people-actions-popover"
        placement="left"
        title="Sort"
      >
        <div className="people-sorting-action-bar">
          <span className="people-action-icon">{getActionBarIcon()}</span>
        </div>
      </SpanWithTooltip>
      <SortingPopover
        listItems={listItems}
        handleActiveItem={handleActiveItem}
        activeItem={activeItem}
        target="people-actions-popover"
        className={className}
      />
    </div>
  );
};

SortingActions.propTypes = {
  listItems: PropTypes.objectOf(PropTypes.any).isRequired,
  handleActiveItem: PropTypes.func.isRequired,
  activeItem: PropTypes.string.isRequired,
  className: PropTypes.string,
  isOnMobileMode: PropTypes.bool,
};

SortingActions.defaultProps = {
  className: "",
  isOnMobileMode: false,
};

export default SortingActions;
