import React, { useMemo } from "react";
import SpanWithTooltip from "../../../SpanWithTooltip";
import { ReactComponent as CircledQuestionMarkSVG } from "../../../../assets/img/icons-new/settings/circled-question-mark/circled-question-mark-gray.svg";
import PropTypes from "prop-types";
import { getIsUnverifiedTollFree } from "../../../../utils/numbersHelpers";

const NumberSettingsStatus = (props) => {
  const {
    status,
    is_toll_free,
    is_toll_free_registered,
    isUnverifiedTollFreeText,
    className,
  } = props;

  const { displayText, isUnverifiedTollFree } = useMemo(() => {
    let displayText = "";
    let isUnverifiedTollFree = getIsUnverifiedTollFree({
      is_toll_free,
      is_toll_free_registered,
    });
    displayText = status;
    if (isUnverifiedTollFree) {
      displayText += isUnverifiedTollFreeText;
    }
    return { displayText, isUnverifiedTollFree };
  }, [is_toll_free, is_toll_free_registered, status, isUnverifiedTollFreeText]);

  return (
    <span className={className}>
      {displayText && (
        <span className={isUnverifiedTollFree && "orange-color"}>
          {displayText}
        </span>
      )}
      {isUnverifiedTollFree && (
        <SpanWithTooltip
          className="ml-1"
          leaveDelay={500}
          interactive={true}
          title={
            <div>
              Newly added toll-free numbers are limited until verified.{" "}
              <a
                className="color-primary cursor-pointer"
                target="blank"
                href="https://help.pastorsline.com/en/articles/3090769-v3-adding-a-toll-free-number-to-your-account"
              >
                Learn More
              </a>
            </div>
          }
        >
          <CircledQuestionMarkSVG width={17} height={17} />
        </SpanWithTooltip>
      )}
    </span>
  );
};

NumberSettingsStatus.propTypes = {
  status: PropTypes.string,
  is_toll_free: PropTypes.bool.isRequired,
  is_toll_free_registered: PropTypes.bool.isRequired,
  isUnverifiedTollFreeText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NumberSettingsStatus.defaultProps = {
  status: "",
};

export default NumberSettingsStatus;
