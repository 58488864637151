import React, { useCallback } from "react";
import PropTypes from "prop-types";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";

const IframeLauncher = (props) => {
  const { title, isEmbeddedPage, onLaunchIframe } = props;
  const { togggleShowEmbededScreemOnFullMode } = useMainStore();

  const onClickedLaunchIframe = useCallback(() => {
    onLaunchIframe();
    togggleShowEmbededScreemOnFullMode(true);
  }, [onLaunchIframe, togggleShowEmbededScreemOnFullMode]);

  return (
    <div className="iframe-launcher-container">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <button
          onClick={onClickedLaunchIframe}
          className="btn btn-primary launch-full-mode-button"
        >
          Launch {title}
        </button>
        <p className="desc">
          This is a full screen {isEmbeddedPage ? "embedded" : ""} page
        </p>
      </div>
    </div>
  );
};

IframeLauncher.propTypes = {
  title: PropTypes.string.isRequired,
  isEmbeddedPage: PropTypes.bool,
  onLaunchIframe: PropTypes.func,
};

IframeLauncher.defaultProps = {
  isEmbeddedPage: true,
  onLaunchIframe: () => {},
};

export default IframeLauncher;
