import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { droprightSettingsMenuList } from "./droprightSettingsMenuList";
import { useIsAdminOrOwner } from "../../../utils/hooks/useIsAdminOrOwner";
import { isReactNativeApp } from "../../../helpers";
import InstallChromeExtensionLink from "./InstallChromeExtensionLink";
import {
  isChrome,
  isFirefoxBrowser,
  isMicrosoftEdgeBrowser,
} from "../../../utils/extensionHelpers";
import InstallFirefoxExtensionLink from "./InstallFirefoxExtensionLink";
import InstallMicrosoftEdgeExtensionLink from "./InstallMicrosoftEdgeExtensionLink";

const DroprightSettingsMenu = (props) => {
  const { currentCompany, history, closePopup } = props;
  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);

  const navigateToUrl = useCallback(
    (url) => {
      if (url) {
        history.push(`/hub/menus/filter/settings${url}`);
        closePopup();
      } else {
        history.push(
          "/hub/menus/filter/settings/user-settings/submenu/user-profile"
        );
        closePopup();
      }
    },
    [closePopup, history]
  );

  const settingsMenus = useCallback(
    (menu) => {
      return (
        <span
          onClick={() => navigateToUrl(menu.url)}
          className="admin-user-menus-wrapper"
        >
          <menu.icon width={18} height={18} className="mr-1" />
          {menu.title}
        </span>
      );
    },
    [navigateToUrl]
  );

  return (
    <>
      {droprightSettingsMenuList.map((item) => {
        if (item.isAdminSettings && !isAdminOrOwner) {
          return null;
        }
        return settingsMenus(item);
      })}

      {/* Google Chrome browser link */}
      {isChrome() && !isReactNativeApp() && <InstallChromeExtensionLink />}

      {/* Mozilla Firefox browser link */}
      {isFirefoxBrowser() && !isReactNativeApp() && (
        <InstallFirefoxExtensionLink />
      )}

      {/* Microsoft Edge browser link */}
      {isMicrosoftEdgeBrowser() && !isReactNativeApp() && (
        <InstallMicrosoftEdgeExtensionLink />
      )}
    </>
  );
};

DroprightSettingsMenu.propTypes = {
  currentCompany: PropTypes.object.isRequired,
  userPermissions: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default withRouter(DroprightSettingsMenu);
