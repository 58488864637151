import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { plBasicTheme } from "../../../assets/base/theme";

//new icons
import { ReactComponent as CloseIcon } from "../../../assets/img/icons-new/general/close/close-gray.svg";

function mapStateToProps(store, ownProps) {
  return {
    infoSnackbarStatus: store.contacts.infoSnackbarStatus,
  };
}

const ConfirmationModal = ({
  show,
  modalBodyContent,
  modalBodyHeader,
  onClose,
  isGroup,
  deletion,
  footerText,
  unarchive,
  isDescriptionTextVisible,
  descriptionText,
  //Redux props
  infoSnackbarStatus,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [selectMembers, setSelectMembers] = useState(false);

  const updateView = useCallback(() => {
    if (show && !isShown) {
      setTimeout(() => {
        setIsShown(true);
        setIsHide(false);
      }, 0);
    }
    if (!show && isShown) {
      setIsHide(true);
      setTimeout(() => {
        setIsShown(false);
      }, 200);
    }
  }, [show, isShown]);

  useEffect(() => {
    updateView();
  }, [show, isShown, isHide, updateView]);

  const closeModal = (val) => (event) => {
    event && event.preventDefault();
    onClose(val, false, selectMembers ? 1 : 0);
  };

  const handleMembersSelect = () => {
    setSelectMembers(!selectMembers);
  };

  return (
    <div
      className={
        "modal fade inbox-modal confirm-group-message" +
        (isShown && !isHide ? " show" : "")
      }
      style={show || isShown ? { display: "block" } : {}}
      id="SkipContactModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="SkipContactModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-backdrop fade show"
        onClick={(e) => {
          if (infoSnackbarStatus !== "displaying") {
            e.preventDefault();
            closeModal(false)();
          }
        }}
      />
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        style={{ zIndex: 100 }}
      >
        <div className="modal-content themed-modal">
          <button
            type="button"
            className="close-archived"
            aria-label="Close"
            onClick={(e) => {
              e.preventDefault();
              closeModal(false)();
            }}
          >
            <CloseIcon />
          </button>
          <div className="modal-body">
            <div className="sleek mb-4 text-left">
              <span className="group-header-title">{modalBodyHeader}</span>
            </div>
            <div
              className={`content sleek ${deletion ? "warning-content" : ""}`}
            >
              <p className="themed-text">{modalBodyContent}</p>
            </div>

            <div
              className={`${
                isGroup || isDescriptionTextVisible
                  ? "group-modal-footer"
                  : "modal-footer"
              } mt-4`}
            >
              {isDescriptionTextVisible && (
                <span className="switch-modal-bottom-text">
                  {descriptionText}
                </span>
              )}
              {isGroup && (
                <div>
                  <MuiThemeProvider theme={plBasicTheme}>
                    <Checkbox
                      checked={selectMembers}
                      onChange={handleMembersSelect}
                    />

                    <span className="switch-modal-bottom-text">
                      {footerText}
                    </span>
                  </MuiThemeProvider>
                </div>
              )}
              <button
                type="button"
                id="archive-confirm-button"
                className={`btn ${
                  deletion ? "btn-delete-confirmation" : "btn-primary"
                }`}
                onClick={closeModal(true)}
                style={{
                  padding: "0.375rem 0.75rem",
                  fontSize: "1rem",
                }}
              >
                {deletion
                  ? "Delete"
                  : `Yes, ${unarchive ? "unarchive" : "archive"}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(mapStateToProps)(ConfirmationModal));
