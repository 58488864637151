import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as companiesActions from "../../../actions/companies";

export function useCompaniesStore() {
  const dispatch = useDispatch();
  const _companies = useSelector((store) => store.companies);

  const fetchCompanies = useCallback(
    async (isResetCurrentCompany) =>
      await dispatch(companiesActions.fetchCompanies(isResetCurrentCompany)),
    [dispatch]
  );

  const setCurrentCompany = useCallback(
    async (id, isAutoSwitched) =>
      await dispatch(companiesActions.setCurrentCompany(id, isAutoSwitched)),
    [dispatch]
  );

  const updateCurrentCompanyAfterFetch = useCallback(
    async (currentCompanyId) =>
      await dispatch(
        companiesActions.updateCurrentCompanyAfterFetch(currentCompanyId)
      ),
    [dispatch]
  );

  const closeAccountIssueBar = useCallback(
    async (companyId, issueKey) =>
      await dispatch(
        companiesActions.closeAccountIssueBar(companyId, issueKey)
      ),
    [dispatch]
  );

  const fetchUsers = useCallback(
    async (params) => await dispatch(companiesActions.fetchUsers(params)),
    [dispatch]
  );

  const setTypeOfFetchedUsers = useCallback(
    async (params) =>
      await dispatch(companiesActions.setTypeOfFetchedUsers(params)),
    [dispatch]
  );

  const fetchUserInfo = useCallback(
    async (params) => await dispatch(companiesActions.fetchUserInfo(params)),
    [dispatch]
  );

  const clearUserInfo = useCallback(
    async () => await dispatch(companiesActions.clearUserInfo()),
    [dispatch]
  );

  const updateUserInfo = useCallback(
    async (params) => await dispatch(companiesActions.updateUserInfo(params)),
    [dispatch]
  );

  const clearUpdateUserInfoStatus = useCallback(
    async () => await dispatch(companiesActions.clearUpdateUserInfoStatus()),
    [dispatch]
  );

  const inviteNewUser = useCallback(
    async (params) => await dispatch(companiesActions.inviteNewUser(params)),
    [dispatch]
  );

  const clearInviteNewUserStatus = useCallback(
    async () => await dispatch(companiesActions.clearInviteNewUserStatus()),
    [dispatch]
  );

  const deleteUser = useCallback(
    async (params) => await dispatch(companiesActions.deleteUser(params)),
    [dispatch]
  );

  const clearDeleteUserStatus = useCallback(
    async () => await dispatch(companiesActions.clearDeleteCompanyStatus()),
    [dispatch]
  );

  const fetchDeletedUsers = useCallback(
    async (params) =>
      await dispatch(companiesActions.fetchDeletedUsers(params)),
    [dispatch]
  );

  const resendInvitation = useCallback(
    async (params) => await dispatch(companiesActions.resendInvitation(params)),
    [dispatch]
  );

  const clearResendInvitationStatus = useCallback(
    async () => await dispatch(companiesActions.clearResendInvitationStatus()),
    [dispatch]
  );

  const removeDeletedUser = useCallback(
    async (data) => await dispatch(companiesActions.removeDeletedUser(data)),
    [dispatch]
  );

  const getCompanyPackage = useCallback(
    async (params) =>
      await dispatch(companiesActions.getCompanyPackage(params)),
    [dispatch]
  );

  const clearIsAutoSwitched = useCallback(
    async () => await dispatch(companiesActions.clearIsAutoSwitched()),
    [dispatch]
  );

  const transferCompanyOwnership = useCallback(
    async (companyId, data) =>
      await dispatch(
        companiesActions.transferCompanyOwnership(companyId, data)
      ),
    [dispatch]
  );

  const clearTransferCompanyOwnership = useCallback(
    async () =>
      await dispatch(companiesActions.clearTransferCompanyOwnership()),
    [dispatch]
  );

  const cancelCompanyTransferOwnership = useCallback(
    async (companyId) =>
      await dispatch(
        companiesActions.cancelCompanyTransferOwnership(companyId)
      ),
    [dispatch]
  );

  const clearCancelCompanyTransferOwnership = useCallback(
    async () =>
      await dispatch(companiesActions.clearCancelCompanyTransferOwnership()),
    [dispatch]
  );

  const resendCompanyTransferOwnership = useCallback(
    async (companyId) =>
      await dispatch(
        companiesActions.resendCompanyTransferOwnership(companyId)
      ),
    [dispatch]
  );

  const clearResendCompanyTransferOwnership = useCallback(
    async () =>
      await dispatch(companiesActions.clearResendCompanyTransferOwnership()),
    [dispatch]
  );

  const setSelectedCompany = useCallback(
    async (company) =>
      await dispatch(companiesActions.setSelectedCompany(company)),
    [dispatch]
  );

  const clearSelectedCompany = useCallback(
    async () => await dispatch(companiesActions.clearSelectedCompany()),
    [dispatch]
  );

  const addCompany = useCallback(
    async (data) => await dispatch(companiesActions.addCompany(data)),
    [dispatch]
  );

  const clearAddCompany = useCallback(
    async () => await dispatch(companiesActions.clearAddCompany()),
    [dispatch]
  );

  const checkEmail = useCallback(
    async (params) => await dispatch(companiesActions.checkEmail(params)),
    [dispatch]
  );

  const clearCheckEmail = useCallback(
    async () => await dispatch(companiesActions.clearCheckEmail()),
    [dispatch]
  );

  const leaveCompany = useCallback(
    async (companyId) =>
      await dispatch(companiesActions.leaveCompany(companyId)),
    [dispatch]
  );

  const clearLeaveCompany = useCallback(
    async () => await dispatch(companiesActions.clearLeaveCompany()),
    [dispatch]
  );

  const fetchExitedCompanies = useCallback(
    async () => await dispatch(companiesActions.fetchExitedCompanies()),
    [dispatch]
  );

  const deleteCompany = useCallback(
    async (companyId) =>
      await dispatch(companiesActions.deleteCompany(companyId)),
    [dispatch]
  );

  const clearDeleteCompanyStatus = useCallback(
    async () => await dispatch(companiesActions.clearDeleteCompanyStatus()),
    [dispatch]
  );

  const fetchCompanyUsers = useCallback(
    async (params) =>
      await dispatch(companiesActions.fetchCompanyUsers(params)),
    [dispatch]
  );

  const clearFetchCompanyUsersStatus = useCallback(
    async () => await dispatch({ type: "COMPANIES/FETCH_COMPANY_USERS.CLEAR" }),
    [dispatch]
  );

  return {
    companies: _companies,
    fetchCompanies,
    setCurrentCompany,
    updateCurrentCompanyAfterFetch,
    closeAccountIssueBar,
    fetchUsers,
    setTypeOfFetchedUsers,
    fetchUserInfo,
    clearUserInfo,
    updateUserInfo,
    clearUpdateUserInfoStatus,
    inviteNewUser,
    clearInviteNewUserStatus,
    deleteUser,
    clearDeleteUserStatus,
    fetchDeletedUsers,
    resendInvitation,
    clearResendInvitationStatus,
    removeDeletedUser,
    getCompanyPackage,
    clearIsAutoSwitched,
    transferCompanyOwnership,
    clearTransferCompanyOwnership,
    cancelCompanyTransferOwnership,
    clearCancelCompanyTransferOwnership,
    resendCompanyTransferOwnership,
    clearResendCompanyTransferOwnership,
    setSelectedCompany,
    clearSelectedCompany,
    addCompany,
    clearAddCompany,
    checkEmail,
    clearCheckEmail,
    leaveCompany,
    clearLeaveCompany,
    fetchExitedCompanies,
    deleteCompany,
    clearDeleteCompanyStatus,
    fetchCompanyUsers,
    clearFetchCompanyUsersStatus,
  };
}

export default useCompaniesStore;
