import React from "react";
import PropTypes from "prop-types";

const SendNewMessagePreviewBox = (props) => {
  const { message } = props;
  return <div className="send-message-preview__box">{message}</div>;
};

SendNewMessagePreviewBox.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SendNewMessagePreviewBox;
