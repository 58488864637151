import React, { useEffect, useState } from "react";
import FirefoxIcon from "../../../assets/img/icons-new/browsers/firefox-icon.png";
import { Chip } from "@material-ui/core";
import { FIREFOX_EXTENSION_LINK } from "../../../utils/extensionHelpers";

const InstallFirefoxExtensionLink = () => {
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    const element = document.getElementById("pastorsline-extension");
    if (element) {
      setIsInstalled(true);
    } else {
      setIsInstalled(false);
    }
  }, []);

  if (isInstalled) return null;

  return (
    <a
      href={FIREFOX_EXTENSION_LINK}
      className="admin-user-menus-wrapper extension-link-text"
      target="__blank"
    >
      <img
        src={FirefoxIcon}
        width={19}
        height={19}
        alt="firefox-icon"
        className="mr-1 mb-1"
      />
      Install Extension{" "}
      <Chip
        label="NEW"
        color="succes"
        size="small"
        classes={{
          root: "sidebar-menu-new-chip",
          label: "sidebar-menu-new-chip-label",
        }}
      />
    </a>
  );
};

export default InstallFirefoxExtensionLink;
