import React from "react";
import PropTypes from "prop-types";
import IntegrationIcon from "../Menus/AdminSettingsView/Integrations/IntegrationIcon";
import { connect } from "react-redux";

function mapStateToProps(store, ownProps) {
  return {
    currentCompany: store.companies.currentCompany,
  };
}

const ActiveIntegrations = (props) => {
  const {
    // Component props
    className,
    iconWrapperClassName,
    iconSize,

    // Redux props
    currentCompany,
  } = props;
  return (
    <div className={`d-flex align-items-center ${className}`}>
      {Boolean(currentCompany.mc_status) && (
        <div className={iconWrapperClassName}>
          <IntegrationIcon type="mailchimp" size={iconSize} />
        </div>
      )}

      {Boolean(currentCompany.is_ccb_status) && (
        <div className={iconWrapperClassName}>
          <IntegrationIcon type="ccb" size={iconSize} />
        </div>
      )}

      {Boolean(currentCompany.breeze_status) && (
        <div className={iconWrapperClassName}>
          <IntegrationIcon type="breeze" size={iconSize} />
        </div>
      )}

      {Boolean(currentCompany.elvanto_status) && (
        <div className={iconWrapperClassName}>
          <IntegrationIcon type="elvanto" size={iconSize} />
        </div>
      )}

      {Boolean(currentCompany.pco_status) && (
        <div className={iconWrapperClassName}>
          <IntegrationIcon type="planning-center" size={iconSize} />
        </div>
      )}

      {Boolean(currentCompany.is_rock_enabled) && (
        <div className={iconWrapperClassName}>
          <IntegrationIcon type="rock" size={iconSize} />
        </div>
      )}
    </div>
  );
};

ActiveIntegrations.propTypes = {
  className: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  iconSize: PropTypes.number,
};

ActiveIntegrations.defaultProps = {
  className: "",
  iconWrapperClassName: "mr-1",
  iconSize: 20,
};

export default connect(mapStateToProps)(ActiveIntegrations);
