import React, { useCallback, useState, useMemo } from "react";
import clsx from "clsx";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import { ReactComponent as GroupIcon } from "../../assets/img/icons-new/group-tab/group-icon/group-inverse-gray.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/icons-new/general/search/search-gray.svg";
import { ReactComponent as ColumnsIcon } from "../../assets/img/icons-new/general/columns/columns-gray.svg";
import SearchBox from "../hub/HelperComponents/SearchBox";
import SpanWithTooltip from "../SpanWithTooltip";
import ColumnSelectionDropDown from "../hub/HubThreadViewComponents/ColumnSelectionDropDown";
import useBroadcastStore from "../../utils/hooks/ReduxHooks/broadcastStore";
// import MultipleSelectCheckmarks from "../hub/HelperComponents/MultipleSelectCheckmark";
import { isNotAnEmptyArray } from "../../utils/settingsHelpers";
import QuickFilter from "../hub/HelperComponents/QuickFilter";
import CustomSelect from "../hub/HelperComponents/CustomSelect";

const filterOptions = [
  {
    name: "Broadcast Groups",
    value: "adhoc-group",
  },
  {
    name: "Regular Groups (Named)",
    value: "regular-group",
  },
];

const BroadcastFilterPanel = (props) => {
  const {
    columnSelectionState,
    onChangeFilterSelection,
    onChangeSearchValue,
    searchValue,
    fetchOnCancel,
    isLoading,
    selectedFilters,
    handleSelectChange,
    setSelectedFilters,
  } = props;
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const filteredFiltersForSelect = useMemo(() => {
    return selectedFilters.filter(
      (option) => !["broadcast-text", "broadcast-voice"].includes(option)
    );
  }, [selectedFilters]);

  // Breakpoint
  const breakpoint = useBreakpoint();

  // Redux store
  const { broadcasts } = useBroadcastStore();
  const { broadcastsCountData } = broadcasts;

  const renderBroadcastsCount = useMemo(() => {
    if (isNotAnEmptyArray(selectedFilters) && selectedFilters?.length === 1) {
      if (selectedFilters[0] === "broadcast-text") {
        return `${broadcastsCountData?.broadcast_text || 0} Text Broadcasts`;
      } else if (selectedFilters[0] === "broadcast-voice") {
        return `${broadcastsCountData?.broadcast_voice || 0} Voice Broadcasts`;
      }
    }
    return `${broadcastsCountData?.all || 0} Broadcasts`;
  }, [broadcastsCountData, selectedFilters]);

  const hideSearch = useCallback(() => {
    onChangeSearchValue({ target: { value: "" } });
    fetchOnCancel();
    setIsSearchOpen(false);
  }, [fetchOnCancel, onChangeSearchValue]);

  const showSearch = useCallback(() => {
    setIsSearchOpen(true);
  }, []);

  // Advance Filters
  // const {
  //   onClickFilter,
  //   filters,
  //   onAddFilter,
  //   onCloseFilter,
  //   onChangeCondition,
  //   onChangeSubCondition,
  //   onPopoverClose,
  //   addNewFiltersRow,
  //   hasError,
  //   isValidData,
  //   addFiltersProgrammatically,
  // } = useFilterChips({
  //   noConditionFilters: [],
  //   filtersData: BROADCAST_FILTERS,
  //   conditionsMapper: BROADCAST_FILTERS_CONDITIONS,
  // });
  // const activeFilters = filters.length >= 1 && filters[0].length > 0;
  // let filtersData = BROADCAST_FILTERS;

  // Advance filter code
  // const handleCloseFilter = useCallback(
  //   (rowIndex, filterIndex) => {
  //     setBroadcastsAdvancedFilterMutation([]);
  //     onCloseFilter(rowIndex, filterIndex);
  //   },
  //   [onCloseFilter, setBroadcastsAdvancedFilterMutation]
  // );

  // useEffect(() => {
  //   const filtersAdv = convertFiltersIntoApiArray(filters);
  //   if (
  //     filtersAdv.length > 1 &&
  //     !hasError &&
  //     isValidData &&
  //     !arraysEqual(filteredBroadcastAdvFilters, filtersAdv)
  //   ) {
  //     const delayDebounceFn = setTimeout(() => {
  //       fetchFilteredBroadcastsQuery(
  //         companies.currentCompany.id,
  //         1,
  //         getSortByParam?.(),
  //         searchValue?.trim(),
  //         filtersAdv
  //       );
  //     }, 500);
  //     return () => clearTimeout(delayDebounceFn);
  //   }
  // }, [
  //   broadcasts.broadcastPage,
  //   companies.currentCompany.id,
  //   fetchFilteredBroadcastsQuery,
  //   filteredBroadcastAdvFilters,
  //   filters,
  //   getSortByParam,
  //   hasError,
  //   isValidData,
  //   searchValue,
  // ]);

  const handleTextFilterClick = () => {
    setSelectedFilters(["broadcast-text"]);
  };
  const handleVoiceFilterClick = () => {
    setSelectedFilters(["broadcast-voice"]);
  };

  const handleClearFilter = useCallback(() => {
    const filteredClearFilters = selectedFilters.filter(
      (option) => !["adhoc-group", "regular-group"].includes(option)
    );
    setSelectedFilters(filteredClearFilters);
  }, [selectedFilters, setSelectedFilters]);

  const clearQuickFilters = useCallback(() => {
    setSelectedFilters([]);
  }, [setSelectedFilters]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-2 px-md-4 w-100 row">
        <div className="p-4">
          <h4 className="m-0 broadcast-subheader-text">
            {renderBroadcastsCount}
          </h4>
          <div className="d-flex">
            <QuickFilter
              isActive={!isNotAnEmptyArray(selectedFilters)}
              text={"All"}
              onClick={clearQuickFilters}
            />
            &nbsp;
            <QuickFilter
              isActive={selectedFilters.includes("broadcast-text")}
              text={`${broadcastsCountData?.broadcast_text || 0} Texts`}
              onClearFilter={clearQuickFilters}
              onClick={handleTextFilterClick}
            />
            &nbsp;
            <QuickFilter
              isActive={selectedFilters.includes("broadcast-voice")}
              text={`${broadcastsCountData?.broadcast_voice || 0} Voices`}
              onClearFilter={clearQuickFilters}
              onClick={handleVoiceFilterClick}
            />
          </div>
        </div>
        <div
          className={clsx(
            "d-flex aligns-center justify-content gap-2 row px-4 mb-4 mb-md-0",
            {
              row: isMdOrBelowBreakpoint(breakpoint),
            }
          )}
        >
          <div
            className={clsx({
              "d-flex aligns-center": !isMdOrBelowBreakpoint(breakpoint),
              "col-12 row w-100 mb-2 gap-1": isMdOrBelowBreakpoint(breakpoint),
            })}
          >
            <div className="d-flex ">
              {isSearchOpen ? (
                <>
                  <SearchBox
                    value={searchValue || ""}
                    onChange={onChangeSearchValue}
                    loading={isLoading}
                    className="mr-2"
                    placeholder={"Search broadcasts"}
                  />
                  <span className="search" onClick={hideSearch}>
                    Cancel
                  </span>
                </>
              ) : (
                <SpanWithTooltip
                  className="filter-icon show-search mr-3 cursor-pointer"
                  title="Search"
                  onClick={showSearch}
                  placement="top"
                >
                  <SearchIcon />
                </SpanWithTooltip>
              )}
            </div>
            <div>
              <SpanWithTooltip title="Column selection" placement="top">
                <ColumnsIcon
                  height={24}
                  id="dropdown-campaign"
                  className="cursor-pointer mr-3"
                />
              </SpanWithTooltip>
              <ColumnSelectionDropDown
                selectionFields={columnSelectionState}
                onChange={onChangeFilterSelection}
              />
            </div>
          </div>
          <div
            className={clsx("d-flex align-items-center", {
              "col-12": isMdOrBelowBreakpoint(breakpoint),
            })}
          >
            <CustomSelect
              subHeaders={[
                {
                  text: (
                    <strong>
                      <GroupIcon /> By Group Type
                    </strong>
                  ),
                  startIndex: 0,
                  endIndex: 1,
                },
              ]}
              options={filterOptions}
              value={
                isNotAnEmptyArray(filteredFiltersForSelect)
                  ? filteredFiltersForSelect[0]
                  : ""
              }
              onChange={handleSelectChange}
              fullWidth
              className="broadcast-filter-select"
              placeholder="Select Filters"
            />
            {isNotAnEmptyArray(filteredFiltersForSelect) && (
              <span className="group-name ml-2" onClick={handleClearFilter}>
                Clear
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Advance filters */}
      {/* <Filters
            filters={filtersData}
            onClickFilter={onClickFilter}
            showActionButton={true}
            filterIsActive={activeFilters}
          >
            {activeFilters ? <FilterActiveIcon /> : <FilterIcon />}
          </Filters> */}
      {/* <FiltersChipsContainer
        filtersData={filtersData}
        onAddFilter={onAddFilter}
        conditionsMapper={BROADCAST_FILTERS_CONDITIONS}
        filters={filters}
        onClose={handleCloseFilter}
        onChangeCondition={onChangeCondition}
        onChangeSubCondition={onChangeSubCondition}
        onPopoverClose={onPopoverClose}
        addNewFiltersRow={addNewFiltersRow}
        hasError={hasError}
      /> */}
    </>
  );
};

export default BroadcastFilterPanel;
