import React, { useEffect, useState, useCallback, useMemo } from "react";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroller";
import useMediaStore from "../../../../../utils/hooks/ReduxHooks/mediaStore";
import { ReactComponent as PlayIcon } from "../../../../../assets/img/icons-new/play-circle/play-circle-white.svg";
import { ReactComponent as ArrowBack } from "../../../../../assets/img/icons/menuIcons/arrow-back-white.svg";
import { ReactComponent as VideoThumbnail } from "../../../../../assets/img/icons-new/video/video-thumbnail/video-thumbnail.svg";
import Spinner from "../../../HelperComponents/Spinner";
import ButtonWithSpinner from "../../../HelperComponents/ButtonWithSpinner";
import TooltipWhenOverflow from "../../../HelperComponents/TooltipWhenOverflow";
import { isEmptyObject } from "../../../../../helpers";
import SpanWithTooltip from "../../../../SpanWithTooltip";
import useBreakpoint from "../../../../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../../../../utils/breakpoints";

const VideoCustomSource = (props) => {
  const [toUpload, setToUpload] = useState({});
  const [isOnGridView, setIsOnGridView] = useState(false);
  const [videoToView, setVideoToView] = useState({});
  const { actions, element } = props;

  const breakpoint = useBreakpoint();

  const filenameMaxWidth = useMemo(() => {
    if (isMdOrBelowBreakpoint(breakpoint) && !isOnGridView) {
      return 100;
    } else if (isOnGridView) {
      return 150;
    }
    return 300;
  }, [breakpoint, isOnGridView]);

  // Redux store
  const {
    media,
    fetchMediaResources,
    deleteMediaResources,
    clearDeletehMediaResourcesStatus,
  } = useMediaStore();
  const {
    videoSource,
    fetchMediaResourceStatus,
    deleteMediaResourcesStatus,
    fetchMediaResourcePage,
    fetchMediaResourceLoadingMoreStatus,
    fetchMediaResourceLoadedAll,
  } = media;

  const loading = useMemo(
    () => fetchMediaResourceStatus === "loading",
    [fetchMediaResourceStatus]
  );
  const loadingMore = useMemo(
    () => fetchMediaResourceLoadingMoreStatus === "loading",
    [fetchMediaResourceLoadingMoreStatus]
  );
  const isSelectMoreThanOneVideo = useMemo(
    () => Object.keys(toUpload).length > 1,
    [toUpload]
  );

  useEffect(() => {
    const params = {
      page: 1,
      type: "video",
    };
    fetchMediaResources(params);
  }, [fetchMediaResources]);

  useEffect(() => {
    if (deleteMediaResourcesStatus === "success") {
      clearDeletehMediaResourcesStatus();
      setToUpload({});
    }
  }, [clearDeletehMediaResourcesStatus, deleteMediaResourcesStatus]);

  const loadMore = useCallback(
    (values) => {
      if (
        fetchMediaResourceStatus !== "loading" &&
        fetchMediaResourceStatus !== "error" &&
        !fetchMediaResourceLoadedAll &&
        fetchMediaResourceLoadingMoreStatus !== "loading"
      ) {
        const params = {
          page: fetchMediaResourcePage + 1,
          type: "video",
        };
        fetchMediaResources(params);
      }
    },
    [
      fetchMediaResourceLoadedAll,
      fetchMediaResourceLoadingMoreStatus,
      fetchMediaResourceStatus,
      fetchMediaResources,
      fetchMediaResourcePage,
    ]
  );

  const handleClick = useCallback(
    (idx, file) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      const updatedUploads = { ...toUpload };
      if (updatedUploads[idx]) {
        delete updatedUploads[idx];
      } else {
        updatedUploads[idx] = file;
      }

      setToUpload(updatedUploads);
    },
    [toUpload]
  );

  const handleRemoveResource = useCallback(() => {
    const ids = Object.values(toUpload).map((file) => file.id);
    const data = {
      ids,
    };
    deleteMediaResources(data, "video");
  }, [deleteMediaResources, toUpload]);

  const handleViewTypeClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (element.classList.contains("grid")) {
        setIsOnGridView(false);
        element.classList.remove("grid");
      } else {
        setIsOnGridView(true);
        element.classList.add("grid");
      }
    },
    [element]
  );

  const handleFinishClick = useCallback(() => {
    if (Object.keys(toUpload).length === 0) {
      return;
    }

    const todo = [];
    Object.values(toUpload).forEach((file) =>
      todo.push(actions.fetchAndAddUrl(file.url))
    );

    Promise.all(todo).then(() => {
      actions.showSummaryView();
      setToUpload({});
    });
  }, [actions, toUpload]);

  const openVideoViewer = useCallback(
    (video) => (event) => {
      event?.preventDefault();
      event.stopPropagation();
      setVideoToView(video);
    },
    []
  );

  const closeVideoViewer = useCallback(() => {
    setVideoToView({});
  }, []);

  if (loading && !loadingMore) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  if (!isEmptyObject(videoToView)) {
    return (
      <div className="video-viewver-container">
        <video width="100%" height="100%" controls autoPlay>
          <source src={videoToView?.url} type={"video/mp4"} />
        </video>
        <ButtonWithSpinner className="play-btn mt-4" onClick={closeVideoViewer}>
          <ArrowBack height={20} className="mr-1" /> Go Back
        </ButtonWithSpinner>
      </div>
    );
  }

  return (
    <div
      className={clsx(`fsp-picker--custom-source`, {
        "list-view-container": !isOnGridView,
      })}
    >
      <div className="fsp-picker--custom-source-header w-100 mb-2">
        <span
          className="view-type cursor-pointer"
          onClick={handleViewTypeClick}
        >
          <strong className="ml-4">{isOnGridView ? "List" : "Grid"}</strong>
        </span>
      </div>
      <InfiniteScroll
        pageStart={fetchMediaResourcePage}
        initialLoad={false}
        loadMore={loadMore}
        className={clsx({
          "video-grid-view-infinite-scroll": isOnGridView,
          "video-list-view-infinite-scroll": !isOnGridView,
        })}
        hasMore={!fetchMediaResourceLoadedAll}
        threshold={250}
        useWindow={false}
        loader={
          loading &&
          loadingMore && (
            <p className="text-muted text-center mt-3">Loading...</p>
          )
        }
      >
        <ul className="fsp-picker--custom-source-list p-0 w-100">
          {videoSource &&
          Array.isArray(videoSource) &&
          Boolean(videoSource.length) ? (
            videoSource.map((element, idx) => (
              <li
                key={`file-${idx}`}
                className={clsx("source-list-item", {
                  "file-selected": toUpload[idx],
                })}
                onClick={handleClick(idx, element)}
              >
                <div
                  className={clsx("source-list-inner-container", {
                    "grid-video-view-inner-container": isOnGridView,
                  })}
                >
                  {element.thumbnail_url ? (
                    <img
                      src={element.thumbnail_url}
                      alt={element.filename}
                      className={clsx({
                        "grid-thumbnail": isOnGridView,
                        "list-thumbnail": !isOnGridView,
                      })}
                    />
                  ) : (
                    <div
                      className={clsx("default-video-icon-container", {
                        "grid-thumbnail": isOnGridView,
                        "list-thumbnail mr-2": !isOnGridView,
                      })}
                    >
                      <VideoThumbnail />
                    </div>
                  )}
                  <TooltipWhenOverflow
                    maxWidth={filenameMaxWidth}
                    text={element.filename}
                  >
                    {element.filename}
                  </TooltipWhenOverflow>
                </div>
                <ButtonWithSpinner
                  className="video-play-btn"
                  onClick={openVideoViewer(element)}
                >
                  <PlayIcon height={20} className="mr-1" /> Play
                </ButtonWithSpinner>
              </li>
            ))
          ) : (
            <h5 className="text-muted text-center mt-3">No data found.</h5>
          )}
        </ul>
      </InfiniteScroll>
      <div className="fsp-picker--custom-source-footer file-stack-image-footer-container">
        <span>Selected Files: {Object.keys(toUpload).length}</span>
        <div>
          {Object.keys(toUpload).length !== 0 && (
            <ButtonWithSpinner
              className={"delete-selected-button"}
              withBtnClassName={false}
              onClick={handleRemoveResource}
              loading={deleteMediaResourcesStatus === "loading"}
              disabled={deleteMediaResourcesStatus === "loading"}
            >
              Delete Selected
            </ButtonWithSpinner>
          )}
          <SpanWithTooltip
            title={
              isSelectMoreThanOneVideo ? "You can select only one video." : ""
            }
          >
            <button
              onClick={handleFinishClick}
              className={clsx("btn", {
                "btn-disabled": Object.keys(toUpload).length === 0,
                "view-edit-btn": Object.keys(toUpload).length !== 0,
              })}
              disabled={
                deleteMediaResourcesStatus === "loading" ||
                isSelectMoreThanOneVideo
              }
            >
              View/Edit selected
            </button>
          </SpanWithTooltip>
        </div>
      </div>
    </div>
  );
};

export default VideoCustomSource;
