import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const NothingFoundComponent = (props) => {
  const { className, specialText, specialTextClassName, groupSearch } = props;
  return (
    <div
      className={clsx(
        `message-single contact-tab-single text-center d-flex justify-content-center align-items-center`,
        className
      )}
    >
      {specialText !== "" ? (
        <div className={clsx(`detail`, specialTextClassName)}>
          <span className="name nothing-found">
            {specialText}
            {groupSearch}
          </span>
        </div>
      ) : (
        "Nothing found."
      )}
    </div>
  );
};

NothingFoundComponent.defaultProps = {
  className: "gray-color",
  specialText: "",
  groupSearch: null,
};

NothingFoundComponent.propTypes = {
  className: PropTypes.string,
  specialText: PropTypes.string,
  groupSearch: PropTypes.object,
};

export default NothingFoundComponent;
