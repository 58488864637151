import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { arrayToUrl, getRequest, postRequest } from "../utils/requests";
import { fetchCompanies } from "./companies";
import { setNumberSettingsData } from "./menus/settings";

export function fetchNumbers(companyId) {
  return function (dispatch) {
    dispatch({ type: "NUMBERS/NUMBERS" });

    const url = "numbers.json?companyId=" + companyId;
    return axios
      .get(AxiosConfig.getEndpointAddress() + url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "NUMBERS/NUMBERS.SUCCESS",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "NUMBERS/NUMBERS.ERROR",
          payload: error,
        });
      });
  };
}

export function setNumbersFilter(
  companyId,
  numbers,
  all,
  save = true,
  isResetCurrentCompany
) {
  return function (dispatch) {
    let value = all ? "all" : "no-all";
    if (!all) {
      value += "," + numbers.join(",");
    }
    dispatch({
      type: "NUMBERS/FILTER",
      numbers,
      value,
      all,
    });
    if (save) {
      const data = {
        company_id: companyId,
        numbers,
        all: all ? 1 : 0,
      };
      return axios
        .post(
          AxiosConfig.getEndpointAddress() + "numbers/saveFilter.json",
          data,
          AxiosConfig.getAuthConfig()
        )
        .then((response) => {
          dispatch({
            type: "NUMBERS/FILTER.SUCCESS",
            payload: response.data,
          });
        })
        .then(() => {
          dispatch(fetchCompanies(isResetCurrentCompany));
        })
        .catch((error) => {
          dispatch({
            type: "NUMBERS/FILTER.ERROR",
            payload: error,
          });
        });
    }
  };
}

export function setSenderNumber(number) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SENDER",
      number: number,
    });
  };
}

export function setUseSecondaryNumber(state) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SET_USE_SECONDARY_NUMBER",
      useSecondaryNumber: state,
    });
  };
}

export function setShortCodeSignature(userId, userNumber, signaturePreview) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SHORT_CODE_SIGNATURE",
      userId,
      userNumber,
      signaturePreview,
    });
  };
}

export function fetchSignatures(companyId, numbers) {
  const url = arrayToUrl(`signatures.json?`, numbers, "number");
  return (dispatch) =>
    getRequest(dispatch, "NUMBERS/FETCH_SIGNATURES", url, {
      axiosConfig: {
        params: {
          companyId,
        },
      },
    });
}

export function clearFetchSignaturesStatus() {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/CLEAR_FETCH_SIGNATURES_STATUS",
    });
  };
}

export function setSignatureToInsert(signatureToInsert) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SET_SIGNATURE_TO_INSERT",
      signatureToInsert,
    });
  };
}

export function fetchCategorizedNumbers(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "NUMBERS/FETCH_CATEGORIZED_NUMBERS",
      "numbers/categorized.json",
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function fetchSignatureMergeFields(
  companyId,
  userId,
  signatureUserNumber
) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "NUMBERS/FETCH_SIGNATURE_MERGE_FIELDS",
      "signatures/merge-fields.json",
      {
        axiosConfig: {
          params: {
            company_id: companyId,
            user_id: userId,
            signature_user_number: signatureUserNumber,
          },
        },
        successDispatchData: {
          userId,
          signatureUserNumber,
        },
      }
    );
}

export function fetchNotificationNumbersFollowable(companyId) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_NOTIFICATION_NUMBERS_FOLLOWABLE",
      "numbers/notification_followable.json",
      {
        axiosConfig: { params: { companyId } },
      }
    );
  };
}

export function fetchNumbersExtended(
  params,
  numberToSetInRhsMenu = null,
  isFromAdminSettings = false
) {
  return function (dispatch) {
    dispatch({ type: "USER_SETTINGS/FETCH_NUMBERS_EXTENDED" });
    const config = Object.assign({}, AxiosConfig.getAuthConfig(), {
      params,
    });
    return axios
      .get(AxiosConfig.getEndpointAddress() + "numbers/index_ext.json", config)
      .then((response) => {
        dispatch({
          type: "USER_SETTINGS/FETCH_NUMBERS_EXTENDED.SUCCESS",
          payload: response.data,
        });
        const numbers = response?.data?.numbers || [];
        const currentSelectedNumber = numbers.find(
          (number) => number.number === numberToSetInRhsMenu
        );
        if (numberToSetInRhsMenu) {
          dispatch(
            setNumberSettingsData({
              selectedNumber: currentSelectedNumber,
              isFromAdminSettings,
            })
          );
        }
      })
      .catch(function (error) {
        dispatch({
          type: "USER_SETTINGS/FETCH_NUMBERS_EXTENDED.ERROR",
          payload: error,
        });
      });
  };
}

export function fetchReleaseShortCodeOrTollFreeNumberInfo(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "NUMBERS/FETCH_SHORT_CODE_OR_TOLL_FREE_NUMBER_INFO",
      "numbers/release_info.json",
      {
        axiosConfig: { params },
        successDispatchData: { params },
      }
    );
  };
}

export function fetchDefaultSenderSettings(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_DEFAULT_SENDER_SETTINGS",
      `numbers/default_sender.json?companyId=${params.companyId}`
    );
  };
}

export function checkCallerIdStatus(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_CALLER_ID_STATUS",
      `numbers/caller_id/status/${params.numberId}.json?companyId=${params.companyId}`
    );
  };
}

export function clearCheckCallerIdStatus() {
  return (dispatch) =>
    dispatch({ type: "USER_SETTINGS/FETCH_CALLER_ID_STATUS.CLEAR" });
}

export function clearNumberFilterStatus() {
  return (dispatch) => dispatch({ type: "NUMBERS/FILTER.CLEAR" });
}

export function fetchReleasedNumbers(params) {
  return (dispatch) => {
    getRequest(
      dispatch,
      "USER_SETTINGS/FETCH_RELEASED_NUMBERS",
      `numbers/released.json?companyId=${params.companyId}&orderBy=${params.orderBy}`
    );
  };
}

export function fetchVerificationNumberForCallerId(params) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "USER_SETTINGS/VERIFY_CALLER_ID",
      `numbers/caller_id/verify.json`,
      params
    );
  };
}

export function setShortCodeAsDefaultSender(body, actionOnFields) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "NUMBERS/SET_SHORT_CODE_AS_DEFAULT_SENDER",
      "numbers/setShortCodeAsDefaultSender.json",
      body,
      {
        successDispatchData: {
          actionOnFields: actionOnFields,
        },
      }
    );
  };
}

export function clearSetShortCodeAsDefaultSenderStatus() {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SET_SHORT_CODE_AS_DEFAULT_SENDER.CLEAR",
    });
  };
}

export function releaseNumber(params) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "ADMIN_SETTINGS/RELEASE_NUMBER",
      "numbers/release.json",
      params,
      {
        successDispatchData: {
          number: params.number,
        },
      }
    );
  };
}

export function clearReleaseNumber() {
  return (dispatch) =>
    dispatch({
      type: "ADMIN_SETTINGS/RELEASE_NUMBER.CLEAR",
    });
}

export function assignCompanyNumber(params) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "ADMIN_SETTINGS/ASSIGN_COMPANY_NUMBER",
      "numbers/assign_company_number.json",
      params
    );
  };
}

export function clearAssignCompanyNumberStatus() {
  return (dispatch) => {
    dispatch({
      type: "ADMIN_SETTINGS/CLEAR_ASSIGN_COMPANY_NUMBER",
    });
  };
}

export function editNumber(params, index) {
  return (dispatch) => {
    postRequest(dispatch, "NUMBERS/EDIT_NUMBER", "numbers/edit.json", params, {
      successDispatchData: {
        index,
        params,
      },
    });
  };
}

export function clearEditNumberStatus() {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/CLEAR_EDIT_NUMBER_STATUS",
    });
  };
}

export function removeAsSecondaryNumber(params) {
  return (dispatch) => {
    postRequest(
      dispatch,
      "NUMBERS/REMOVE_AS_SECONDARY_NUMBER",
      "numbers/remove-secondary.json",
      params
    );
  };
}

export function clearRemoveAsSecondaryNumber() {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/REMOVE_AS_SECONDARY_NUMBER.CLEAR",
    });
  };
}

export function clearFetchSignatureMergeFieldStatus() {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/FETCH_SIGNATURE_MERGE_FIELDS.CLEAR",
    });
  };
}

export function setShortCodeTollFreeCheckoutEmbedUrl(embedURL) {
  return function (dispatch) {
    dispatch({
      type: "NUMBERS/SET_SHORTCODE_TOLL_FREE_CHECKOUT_EMBED_URL",
      embedURL,
    });
  };
}
