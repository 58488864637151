import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SidebarCollapseNotification = (props) => {
  const { children, className, show } = props;

  if (!show) return null;

  return (
    <div className={clsx("sidebar-collapsed-noti-container", className)}>
      {children}
    </div>
  );
};

SidebarCollapseNotification.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
};

SidebarCollapseNotification.defaultProps = {
  show: true,
  className: "",
};

export default SidebarCollapseNotification;
