import { useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

export const useGridResize = (index, onSizeChange) => {
  const { height: headerHeight, ref: headerRef } = useResizeDetector();
  const { height: containerHeight, ref: containerRef } = useResizeDetector();

  useEffect(() => {
    if (headerHeight && containerHeight) {
      onSizeChange(containerHeight + headerHeight + 20, index); // 20 comes from the padding of  the header, useResizeDetector measures only inside the container without padding
    }
  }, [containerHeight, headerHeight, index, onSizeChange]);

  return {
    headerRef,
    containerRef,
  };
};
