import React, { useCallback } from "react";
import useMainStore from "../../utils/hooks/ReduxHooks/mainStore";
import ModalWrapper from "./Helpers/ModalWrapper";

const SessionStarted = () => {
  const { toggleSessionReplacedModal } = useMainStore();

  const onClickedOk = useCallback(() => {
    toggleSessionReplacedModal(false);
    window.location.reload();
  }, [toggleSessionReplacedModal]);

  return (
    <ModalWrapper
      id="SessionStarted"
      show
      className="session-started-modal"
      hasCloseBtn={false}
      closeModal={() => {}}
    >
      <div className="session-expired-container themed-modal">
        <h1>Session Started</h1>
        <p>Session open in another browser/tab, load here instead.</p>

        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={onClickedOk}>
            OK
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SessionStarted;
