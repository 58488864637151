import React from "react";
import background from "../../assets/img/register/register-banner.svg";
import pastorsLineLogo from "../../assets/img/pastorsline-logo.png";
import { ReactComponent as AccountInReviewIcon } from "../../assets/img/verification/account-in-review.svg";
import { ReactComponent as PastorslineLogoWhite } from "../../assets/img/pastorsline-logo-white.svg";

const AccountIsInReview = (props) => {
  return (
    <div className="account-is-in-review-container">
      <img src={background} alt="background" className="background" />
      <div className="logo-container">
        <PastorslineLogoWhite />
      </div>
      <div className="white-container">
        <div className="logo-container">
          {" "}
          <img src={pastorsLineLogo} alt="pastorsline-logo" />
        </div>
        <div
          style={{ width: 108, height: 108 }}
          className="account-in-review-icon"
        >
          <AccountInReviewIcon />
        </div>
        <h1 className="title">Your Account is Being Reviewed</h1>
        <div className="text-container">
          <p className="text">
            Hi Jason! Your account is being reviewed by our Audit Team. This is
            a one time process and should be resolved within 2-3 business days!
          </p>
          <p className="text">Thank you for your patience!</p>
          <div className="link-container">
            <a href="#void">Is there a problem? Please let us know!</a>
          </div>
        </div>
      </div>
      <div className="copyright">PastorsLine © 2018</div>
    </div>
  );
};

AccountIsInReview.propTypes = {};

export default AccountIsInReview;
