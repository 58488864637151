import React, { useState, useCallback, useMemo } from "react";
import { Popover, PopoverBody } from "reactstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

const PopoverSelect = (props) => {
  const {
    text,
    selectBoxClassName,
    options,
    value,
    onSelect,
    id,
    withRedLabel,
  } = props;
  const [isOpen, setIsopen] = useState(false);

  const togglePopover = useCallback(() => {
    setIsopen(!isOpen);
  }, [isOpen]);

  const onClickedOption = useCallback(
    (selectedOption) => () => {
      togglePopover();
      onSelect(selectedOption);
    },
    [onSelect, togglePopover]
  );

  const renderOption = useMemo(() => {
    return options.map((option, index) => (
      <div
        key={index}
        className="d-flex align-items-center justify-content-start"
      >
        <div
          onClick={onClickedOption(option.value)}
          className={clsx("cursor-pointer py-1", {
            active: value === option.value,
          })}
        >
          {option.name}
        </div>
      </div>
    ));
  }, [onClickedOption, options, value]);

  return (
    <>
      <div className={selectBoxClassName} onClick={togglePopover}>
        <div id={id}>
          <span
            className={clsx({
              "text-danger": withRedLabel,
            })}
          >
            {text}
          </span>{" "}
          <i className="fa fa-caret-down fa-lg ml-2"></i>
        </div>
      </div>
      <Popover
        isOpen={isOpen}
        toggle={togglePopover}
        placement={"bottom"}
        target={id}
        className={"popover-select-box-container"}
        trigger="legacy"
      >
        <PopoverBody>{renderOption}</PopoverBody>
      </Popover>
    </>
  );
};

PopoverSelect.propTypes = {
  text: PropTypes.string,
  value: PropTypes.any,
  selectBoxClassName: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  withRedLabel: PropTypes.bool,
};

PopoverSelect.defaultProps = {
  text: "",
  selectBoxClassName: "",
  value: "",
  options: [],
  withRedLabel: false,
};

export default PopoverSelect;
