import React, { useMemo, useCallback } from "react";
import clsx from "clsx";
import { groupName, contactName, formatPhoneNumber } from "../../../helpers";
import { ReactComponent as GroupIcon } from "../../../assets/img/icons-new/group-tab/group-icon/group-inverse-gray.svg";
import { ReactComponent as CloseGraySvg } from "../../../assets/img/icons-new/general/close-circled/close-circled.svg";
import SpanWithTooltip from "../../SpanWithTooltip";
import useMessagesStore from "../../../utils/hooks/ReduxHooks/messagesStore";
import {
  getContactStatusNew,
  getGroupAndContactToBeRemoved,
} from "../../../utils/sendMessageHelpers";
import ThreadItemIntegrationIcons from "../../hub/ThreadItems/ThreadItemIntegrationIcons";
import NewContactStatusIcons from "../../NewContactStatusIcons";

const RecipientAddedPill = (props) => {
  const { recipient } = props;

  const { setRecipientToBeRemoved, messages } = useMessagesStore();
  const { filteredRecipients, recipientGroups } = messages;

  const isGroup = useMemo(
    () =>
      recipient?.hasOwnProperty("group_name") ||
      recipient?.hasOwnProperty("group_type"),
    [recipient]
  );

  const handleDeleteRecipient = useCallback(() => {
    const contactAndGroupToBeRemoved = getGroupAndContactToBeRemoved(
      recipient,
      filteredRecipients,
      recipientGroups
    );
    setRecipientToBeRemoved(contactAndGroupToBeRemoved);
  }, [filteredRecipients, recipient, setRecipientToBeRemoved, recipientGroups]);

  const setRecipientName = useMemo(() => {
    if (
      recipient?.hasOwnProperty("group_name") ||
      recipient?.hasOwnProperty("group_type")
    ) {
      return groupName(recipient);
    } else {
      return contactName(
        recipient?.first_name,
        recipient?.last_name,
        recipient?.phone_number
      );
    }
  }, [recipient]);

  const renderRecipientStatusIcon = useMemo(() => {
    if (
      recipient?.hasOwnProperty("group_name") ||
      recipient?.hasOwnProperty("group_type")
    ) {
      return "";
    } else {
      const stats = getContactStatusNew([recipient]);
      return <NewContactStatusIcons withFormatError={false} {...stats} />;
    }
  }, [recipient]);

  return (
    <SpanWithTooltip
      title={
        <div className="recipient-pill-tooltip d-flex align-items-start justify-content-center flex-column">
          <h5 className="m-0 p-0">
            {isGroup && <GroupIcon />} {setRecipientName}
          </h5>
          <h5 className="m-0 p-0">
            {isGroup
              ? `${recipient?.members_count} ${
                  recipient?.members_count > 1 ? "People" : "Person"
                }`
              : formatPhoneNumber(
                  recipient?.phone_number,
                  recipient?.country?.phonecode
                )}
          </h5>
          <span className="d-flex align-items-center justify-content-start">
            <ThreadItemIntegrationIcons
              withContactDetails={false}
              withIntegrationIcon={true}
              group={isGroup ? recipient : null}
              isGroup={isGroup}
              contact={!isGroup ? recipient : null}
            />
            {renderRecipientStatusIcon}
          </span>
        </div>
      }
      arrow={false}
      placement={"bottom"}
    >
      <div className="flexer-row-start border rounded-lg px-2 recipient-added-pill-container">
        <span
          className={clsx("recipient-added-pill text-muted mr-1", {
            "recipient-pill-group": isGroup,
          })}
        >
          <div className="recipient-pill-text">
            {isGroup && <GroupIcon />} {setRecipientName}
          </div>
        </span>
        <CloseGraySvg
          className="cursor-pointer"
          onClick={handleDeleteRecipient}
          height={10}
          width={10}
        />
      </div>
    </SpanWithTooltip>
  );
};

export default RecipientAddedPill;
