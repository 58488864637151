import React from "react";
import SimpleEmptyLineChart from "../../charts/SimpleEmptyLineChart";
import PropTypes from "prop-types";

const sumUpArray = (array) => array.reduce((prev, cur) => prev + cur, 0);

const MessagesCallsWidgetStats = (props) => {
  const { outgoing, incoming, labels, type } = props;

  const outgoingCount = sumUpArray(outgoing);
  const incomingCount = sumUpArray(incoming);
  return (
    <div>
      <div className="text-label mb-3">Stats:</div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div className="text-label-darker mb-1">Outgoing {type}:</div>
          <div className="text-label mb-1">
            {outgoingCount} {type}
          </div>
          {/* <div className="text-label d-flex align-items-center mb-3">
                    -17% from last week{" "}
                    <span className="arrow-down ml-2 arrow-rose" />
                  </div> */}
          <SimpleEmptyLineChart
            dataItems={outgoing}
            dataLabels={labels}
            width={140}
            height={40}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div className="text-label-darker mb-1">Incoming {type}:</div>
          <div className="text-label mb-1">
            {incomingCount} {type}
          </div>
          {/* <div className="text-label d-flex align-items-center mb-3">
                    12% from last week{" "}
                    <span className="arrow-down ml-2 arrow-green" />
                  </div> */}
          <SimpleEmptyLineChart
            dataItems={incoming}
            dataLabels={labels}
            width={140}
            height={40}
            color="green"
          />
        </div>
      </div>
    </div>
  );
};
MessagesCallsWidgetStats.propTypes = {
  outgoing: PropTypes.arrayOf(PropTypes.number).isRequired,
  incoming: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
};

export default MessagesCallsWidgetStats;
