import React, { useCallback } from "react";
import PropTypes from "prop-types";

const ThreadItemsNotfications = (props) => {
  const { undeliveredCount, unreadCount, showNotifications, reprocessedCount } =
    props;

  const renderUndeliveredCount = useCallback(() => {
    if (showNotifications && undeliveredCount > 0) {
      return (
        <span className="circle-noti undelivered-noti">{undeliveredCount}</span>
      );
    }
  }, [showNotifications, undeliveredCount]);

  const renderUnreadCount = useCallback(() => {
    if (showNotifications && unreadCount > 0) {
      return <span className="circle-noti unread-noti">{unreadCount}</span>;
    }
  }, [showNotifications, unreadCount]);

  const renderReprocessedUnreadCount = useCallback(() => {
    if (showNotifications && reprocessedCount > 0) {
      return (
        <span className="circle-noti undelivered-noti">{reprocessedCount}</span>
      );
    }
  }, [reprocessedCount, showNotifications]);

  return (
    <div className="info info-position">
      {renderUndeliveredCount()}
      {renderUnreadCount()}
      {renderReprocessedUnreadCount()}
    </div>
  );
};

ThreadItemsNotfications.defaultProps = {
  log: {},
  showNotifications: false,
  showCheckBox: false,
};

ThreadItemsNotfications.propTypes = {
  undeliveredCount: PropTypes.number.isRequired,
  unreadCount: PropTypes.number.isRequired,
  log: PropTypes.object,
  showNotifications: PropTypes.bool,
  showCheckBox: PropTypes.bool,
};

export default ThreadItemsNotfications;
