import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../../../Helpers/ModalWrapper";
import { Divider } from "@material-ui/core";
import NumberBody from "./NumberBody";
import RadioButtonWithLabel from "../../../../hub/HelperComponents/RadioButtonWithLabel";

// TODO: ADD THEMED COLORS
const PickDifferentNumberModal = (props) => {
  const { show, closeModal } = props;

  const [isLocalNumberChecked, setIsLocalNumberChecked] = useState(true);

  const onChoose = useCallback(() => {
    // to send data from here
    closeModal("something");
  }, [closeModal]);

  return (
    <ModalWrapper
      id="PickDifferentNumberModal"
      show={show}
      closeModal={closeModal}
      className="pick-different-number-modal"
      classNameCloseIcon="pick-different-number-close-icon"
    >
      <div className="pick-different-number-container themed-modal">
        <div className="d-flex justify-content-center pb-3">
          <RadioButtonWithLabel
            label="Local Number"
            checked={isLocalNumberChecked}
            onChange={() => setIsLocalNumberChecked(true)}
          />
          <RadioButtonWithLabel
            label="Toll-free Number"
            subLabel="Toll-free number cannot be used to send MMS*"
            className="ml-4"
            checked={!isLocalNumberChecked}
            onChange={() => setIsLocalNumberChecked(false)}
          />
        </div>
        <Divider />

        <NumberBody
          closeModal={closeModal}
          onChoose={onChoose}
          isTollFreeNumber={!isLocalNumberChecked}
        />
      </div>
    </ModalWrapper>
  );
};

PickDifferentNumberModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default PickDifferentNumberModal;
