import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";
import Scrollbars from "react-custom-scrollbars";
import MessagesCard from "./MessagesCard";
import MessageTextarea from "../../elements/MessageTextarea";
import MessagesFeature from "./MessagesFeature";
import ActionButton from "../../ActionButton/ActionButton";
import SendNewMessagePreview from "../SendNewMessagePreview/SendNewMessagePreview";
import HeaderButton from "../SendNewMessageHeader/HeaderButton";
import CreditEstimate from "../../hub/HubThreadMessageForm/AboveTextArea/CreditEstimate";
import Spinner from "../../hub/HelperComponents/Spinner";
import RecipientAddedPill from "./RecipientAddedPill";
import Feature from "../../hub/HubThreadMessageForm/Features/Feature";
import { ReactComponent as CirclePlusIcon } from "../../../assets/img/icons-new/circle-plus/circle-plus-white.svg";
import { ReactComponent as ViewIconSvg } from "../../../assets/img/icons-new/general/preview/preview-gray.svg";
import { ReactComponent as MergeFieldsGraySvg } from "../../../assets/img/icons-new/general/merge-fields/merge-fields-gray.svg";
import { ReactComponent as TemplatesDarkerGreySvg } from "../../../assets/img/icons-new/general/templates/templates-darker-grey.svg";
import { history } from "../../../store";
import useMessagesStore from "../../../utils/hooks/ReduxHooks/messagesStore";
import { useNumbersStore } from "../../../utils/hooks/store/useNumbersStore";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import {
  formatMessageTitle,
  maxMessageLength,
} from "../../../utils/sendMessageHelpers";
import { getSignatureToInsert, isEmptyObject } from "../../../helpers";
import { getScheduleMessageSummary } from "../../../utils/recurringMessagesHelpers";
import VideoLengthExceedModal from "../../hub/HubThreadMessageForm/Features/VideoFeatureComponent/VideoLengthExceedModal";
import VideoConfirmationModal from "../../modals/Video/VideoConfirmationModal";
import NewImageThumbnail from "./NewImageThumbnail";
import NewVideoThumbnail from "./NewVideoThumbnail";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import VCardThumbnail from "../../hub/HubThreadMessageForm/VCardThumbnail";
import { isTollFreeOrShortCodeNumberSelectedAsSenderNumber } from "../../../utils/numbersHelpers";
import {
  sendNewMessageBodyDefaultProps,
  sendNewMessageBodyPropTypes,
} from "./SendNewMessageBodyPropTypes";
import ExcludingMembers from "./ExcludingMembers";
import useGroupsStore from "../../../utils/hooks/ReduxHooks/groupsStore";
import { CONTACT } from "../../../utils/constants";
import BasicMessageCreatorSwitcher from "../SendNewMessageHeader/BasicMessageCreatorSwitcher";

const SendNewMessageBody = (props) => {
  const {
    buttons,
    messageValue,
    setMessageValue,
    unicodeCharacters,
    credits,
    characters,
    smsCount,
    mmsCount,
    images,
    setImages,
    videos,
    setVideos,
    isVideoProcessing,
    setIsVideoProcessing,
    isImagesProcessing,
    setIsImagesProcessing,
    messageSignature,
    setMessageSignature,
    setSidebar,
    setImagesErrorText,
    setVideoErrorText,
    setVideoLengthExceedData,
    setUploadedVideo,
    recipientsCount,
    handleEditSchedule,
    alertNotifier,
    disabled,
    uploadedVideo,
    videoLengthExceedData,
    setWarningText,
    closeVideoLengthExceedModal,
    closeUploadedVideoConfirmationModal,
    onClickedTrimVideo,
    subscribersCount,
    vCards,
    setVcards,
    interlocutorId,
    handleRemoveSchedule,
    setMainGroupId,
    groupData,
    threadType,
    schedule,
  } = props;
  // Refs
  let textAreaRef = useRef(null);
  const recipientPillsRef = useRef(null);
  const signatureInputRef = useRef(null);

  // Redux store
  const {
    messages,
    setBasicInfo,
    clearFilteredRecipients,
    clearRecipientGroupsForMessage,
    setExistingGroupId,
    clearManuallyAddedContactIds,
  } = useMessagesStore();
  const { numbers, setSignatureToInsert, setSenderNumber } = useNumbersStore();
  const { users } = useUsersStore();
  const { clearAddGroup, clearGroupIsNewGroup } = useGroupsStore();
  const { companies } = useCompaniesStore();
  const { filteredRecipients, recipientGroups } = messages;
  const { signatures, senderNumber, signatureMergeFieldsStatus } = numbers;
  const { loggedUser } = users;
  const { currentCompany } = companies;
  const senderNumbers = numbers.numbers;
  const multipleUsers = companies.users;

  // Local component state
  const [showMergeFields, setShowMergeFields] = useState(false);
  const [recipientsForPills, setRecipientsForPills] =
    useState(filteredRecipients);

  const isSenderNumberSelectedAsShortcodeOrTollFree = useMemo(
    () => isTollFreeOrShortCodeNumberSelectedAsSenderNumber(senderNumber),
    [senderNumber]
  );

  const handleClearFilteredRecipients = useCallback(() => {
    setRecipientsForPills([]);
    clearFilteredRecipients();
    setMainGroupId(null);
    clearAddGroup();
    clearGroupIsNewGroup();
    clearRecipientGroupsForMessage();
    setExistingGroupId(null);
    clearManuallyAddedContactIds();
    handleRemoveSchedule();
  }, [
    clearAddGroup,
    clearFilteredRecipients,
    clearRecipientGroupsForMessage,
    setExistingGroupId,
    setMainGroupId,
    clearManuallyAddedContactIds,
    handleRemoveSchedule,
    clearGroupIsNewGroup,
  ]);

  const changeMessageValue = useCallback(
    (e) => {
      // Restricting more then one space and a space at the beginning of a new line
      // Because of additional red text that cannot display more then one space
      const textToSend = e.target.value
        .replace(/ +(?![\r\n])/g, " ")
        .replace(/^ +/gm, "");
      setMessageValue(textToSend);
    },
    [setMessageValue]
  );

  const onChangeMessageText = useCallback(
    ({ target: { value } }) => {
      setMessageValue(value);
    },
    [setMessageValue]
  );

  const totalTextsNumber = useMemo(() => {
    const charsPerText = unicodeCharacters > 0 ? 70 : 160;
    const textCount = Math.ceil(characters / charsPerText);

    return textCount;
  }, [unicodeCharacters, characters]);

  const onChangeNumberFrom = useCallback(
    (value) => {
      setSenderNumber(value);
    },
    [setSenderNumber]
  );

  const handleAddContacts = useCallback(() => {
    if (
      isNotAnEmptyArray(recipientGroups) ||
      isNotAnEmptyArray(filteredRecipients)
    ) {
      const filteredManuallyAddedRecipients = filteredRecipients.filter(
        (recipient) => recipient?.added_by?.toLowerCase() === "manually"
      );

      const selectedContacts =
        threadType === CONTACT
          ? filteredRecipients
          : filteredManuallyAddedRecipients;
      const prevGroupIds = recipientGroups.map((group) => group.id);
      const prevContactIds = selectedContacts.map((contact) => contact.id);
      history.push(`#modal-new-message-receiver`, {
        selectedItems: [...recipientGroups, ...selectedContacts],
        prevGroupId: interlocutorId,
        prevGroupIds: prevGroupIds,
        prevContactIds: prevContactIds,
      });
    } else {
      history.push(`#modal-new-message-receiver`);
    }
  }, [filteredRecipients, interlocutorId, recipientGroups, threadType]);

  const onClickAddTemplate = useCallback(() => {
    history.push("#modal-message-templates/hub-thread-view");
  }, []);

  const toggleMergeFields = useCallback(() => {
    setShowMergeFields(!showMergeFields);
  }, [showMergeFields]);

  const handleCloseMergeFieldsModal = useCallback(() => {
    setShowMergeFields(false);
  }, []);

  const handleRemoveImage = useCallback(
    (index) => (event) => {
      event?.stopPropagation();
      event?.preventDefault();
      const updated = images.filter((item, idx) => idx !== index);
      setImages(updated);
    },
    [images, setImages]
  );

  const handleRemoveVideo = useCallback(
    (index) => (event) => {
      event?.stopPropagation();
      event?.preventDefault();
      const updated = videos.filter((item, idx) => idx !== index);
      setVideos(updated);
    },
    [videos, setVideos]
  );

  const removeVCard = useCallback(
    (idx) => (event) => {
      event && event.stopPropagation();
      event && event.preventDefault();
      const filteredVCards = vCards.filter((vCard, index) => index !== idx);
      setVcards(filteredVCards);
    },
    [vCards, setVcards]
  );

  const handleRefCallback = (elem) => {
    textAreaRef.current = elem.current;
  };

  const handleChangeSignature = useCallback(() => {
    setMessageSignature(signatureInputRef.current.value);
  }, [setMessageSignature]);

  const onSetMessageTextAndCloseFeatures = useCallback(
    (text) => {
      setMessageValue(text);
    },
    [setMessageValue]
  );

  const handleAddedPills = useCallback(() => {
    if (filteredRecipients && Array.isArray(filteredRecipients)) {
      // buttonWidth represents width of a button + gap dimension
      const buttonWidth = 110;
      const contWidth = recipientPillsRef?.current?.offsetWidth;

      const buttonsInRow = Math.floor(contWidth / buttonWidth);

      // Formula (2 * buttonsInRow) - 1 means: 2 rows of buttons, - 1 for "View all"
      const manuallyAddedRecipients = filteredRecipients.filter(
        (item) => item?.added_by?.toLowerCase() === "manually"
      );
      const recipientsToRender =
        filteredRecipients?.length === 1 && groupData == null
          ? filteredRecipients
          : manuallyAddedRecipients;
      const newItems = recipientsToRender.slice(0, 2 * buttonsInRow - 1);

      setRecipientsForPills(newItems);
    }
  }, [filteredRecipients, groupData]);

  useEffect(() => {
    handleAddedPills();
  }, [handleAddedPills]);

  useEffect(() => {
    if (
      signatureMergeFieldsStatus === "success" &&
      senderNumber &&
      signatures?.length > 0
    ) {
      const dataToInsert = getSignatureToInsert({
        signatures,
        chosenNumber: senderNumber.number,
        loggedUser,
        users: multipleUsers,
        numbers: senderNumbers,
        currentCompany,
        threadType: "contact",
      });
      setSignatureToInsert(dataToInsert);
    }
  }, [
    signatureMergeFieldsStatus,
    senderNumber,
    setSignatureToInsert,
    senderNumbers,
    signatures,
    loggedUser,
    currentCompany,
    multipleUsers,
  ]);

  const onClickPreview = useCallback(() => {
    setBasicInfo(
      senderNumber?.number_formatted,
      messageValue + "\n\n" + messageSignature
    );
    history.push("#modal-preview-message");
  }, [senderNumber, messageValue, messageSignature, setBasicInfo]);

  return (
    <>
      <Scrollbars autoHeight autoHeightMax={"calc(100vh - 68.5px)"} autoHide>
        <div className="send-message-body pt-3 px-3 pt-sm-5 px-sm-5 w-100 flexer-row align-items-start">
          <div className="pr-sm-5 w-100">
            {/* Recipients and credit buttons */}
            <div className="d-md-none flex-column d-flex align-content-center align-items-center mb-5">
              <div className="flexer-row align-items-start">
                {buttons.slice(0, 2).map((button, index) => (
                  <HeaderButton key={index} {...button} />
                ))}
              </div>
              <BasicMessageCreatorSwitcher className={"mt-2"} />
            </div>

            {/* Message title card */}
            <MessagesCard className={"messages-card__title mb-3"}>
              <input
                className="w-100 border-0 outline-0"
                type="text"
                value={formatMessageTitle()}
                onChange={() => {}}
                disabled={true}
              />
            </MessagesCard>

            {/* Message input card */}
            <div className="d-flex align-items-center mb-2">
              <Feature
                className="px-1 flexer-row border rounded-lg mr-2"
                svg={<MergeFieldsGraySvg height={16} width={16} />}
                text={<small className="feature-label">Add Merge Fields</small>}
                onClick={toggleMergeFields}
                subscribersCount={subscribersCount}
              />
              <Feature
                className="px-1 flexer-row border rounded-lg mr-2"
                svg={<TemplatesDarkerGreySvg height={16} width={16} />}
                text={<small className="feature-label">Add Template</small>}
                onClick={onClickAddTemplate}
              />
            </div>
            <div className="flexer-col align-items-end mb-4">
              <MessagesCard padding={"0"} className={"w-100"}>
                <div className="messages-card__textarea position-relative">
                  <div className="messages-card__textarea-container">
                    <MessageTextarea
                      className="messages-card__textarea-chars border-0 outline-0 w-100"
                      maxHeight={300}
                      // Removing two characters for signature because of two new lines before the signature in the text area
                      maxLength={2000 - messageSignature?.length - 2}
                      numberSignature={messageSignature}
                      restrictedMaxLength={maxMessageLength}
                      placeholder="Type your Message here"
                      value={messageValue}
                      onChange={changeMessageValue}
                      restrictionHighlight
                      refCallback={handleRefCallback}
                      onSave={null}
                      disabled={disabled}
                    />
                    <input
                      ref={signatureInputRef}
                      className={clsx("messages-card__signature-input", {
                        disabled: isSenderNumberSelectedAsShortcodeOrTollFree,
                      })}
                      value={messageSignature}
                      onChange={handleChangeSignature}
                      disabled={isSenderNumberSelectedAsShortcodeOrTollFree}
                    />
                    <div className="d-flex align-items-center">
                      <NewImageThumbnail
                        images={images}
                        handleRemoveImage={handleRemoveImage}
                      />
                      <NewVideoThumbnail
                        videos={videos}
                        handleRemoveVideo={handleRemoveVideo}
                      />

                      {isNotAnEmptyArray(vCards) &&
                        vCards.map((url, idx) => (
                          <VCardThumbnail
                            key={idx}
                            onClickRemove={removeVCard(idx)}
                            url={url}
                            hasCloseIcon={true}
                            isNewCloseIcon={true}
                            className={"border-0 h-auto w-auto ml-1 mb-1"}
                          />
                        ))}
                    </div>
                  </div>
                  <div>
                    {alertNotifier}
                    <div className="messages-card__features flexer-row justify-content-between flex-column flex-xl-row align-items-start align-items-xl-center border-top">
                      <MessagesFeature
                        message={messageValue}
                        images={images}
                        videos={videos}
                        setImages={setImages}
                        setVideos={setVideos}
                        isVideoProcessing={isVideoProcessing}
                        setIsVideoProcessing={setIsVideoProcessing}
                        isImagesProcessing={isImagesProcessing}
                        setIsImagesProcessing={setIsImagesProcessing}
                        textAreaRef={textAreaRef}
                        onChange={onChangeMessageText}
                        numberSignature={messageSignature}
                        characters={characters}
                        setImagesErrorText={setImagesErrorText}
                        setVideoErrorText={setVideoErrorText}
                        setVideoLengthExceedData={setVideoLengthExceedData}
                        setUploadedVideo={setUploadedVideo}
                        subscribersCount={subscribersCount}
                        handleCloseMergeFieldsModal={
                          handleCloseMergeFieldsModal
                        }
                        toggleMergeFields={toggleMergeFields}
                        showMergeFields={showMergeFields}
                        vCards={vCards}
                      />
                      <div className="flexer-row flex-wrap justify-content-start justify-content-xl-center ml-xl-2 mt-2 mt-xl-0">
                        <span
                          className={clsx("messages-card__label mb-0 ml-0", {
                            "messages-card__label-red":
                              characters > maxMessageLength,
                          })}
                        >
                          Characters: {characters}/{maxMessageLength}
                        </span>
                        <span className="messages-card__label mb-0">
                          Total Texts: {totalTextsNumber}/10 (1 text ={" "}
                          {unicodeCharacters > 0 ? 70 : 160} char.)
                        </span>
                        <CreditEstimate
                          credits={credits}
                          characters={characters}
                          smsCount={smsCount}
                          mmsCount={mmsCount}
                          unicodeCharacters={unicodeCharacters}
                          hideText
                        />
                      </div>
                    </div>
                  </div>
                  {isImagesProcessing ||
                    (isVideoProcessing && (
                      <div className="messages-card__textarea-spinner d-flex flex-column align-items-center">
                        <Spinner />
                        <span className="text-muted">
                          {isImagesProcessing ? "Image" : "Video"} is processing
                        </span>
                      </div>
                    ))}
                </div>
              </MessagesCard>

              <Feature
                className="px-1 mt-2 flexer-row border rounded-lg d-lg-none"
                svg={<ViewIconSvg height={16} width={16} />}
                text={<small>Preview</small>}
                onClick={onClickPreview}
              />
            </div>

            {/* Message recipients card */}
            <MessagesCard
              label="To:"
              padding={"15px"}
              className={"mb-4 px-2 px-md-4"}
            >
              <div
                ref={recipientPillsRef}
                className="flexer-col align-items-start"
              >
                <span className="messages-card__recipients mb-3">
                  {recipientsCount} Likely Recipients
                </span>
                <div className="d-flex align-items-center">
                  <ActionButton
                    text={
                      <span className="d-flex align-items-center">
                        <CirclePlusIcon className="mr-1" /> Add by Contacts,
                        Groups or Tags
                      </span>
                    }
                    type="primary"
                    onClick={handleAddContacts}
                  />
                  {(isNotAnEmptyArray(filteredRecipients) ||
                    isNotAnEmptyArray(recipientGroups)) && (
                    <span
                      className="link-text ml-2"
                      onClick={handleClearFilteredRecipients}
                    >
                      Clear all
                    </span>
                  )}
                </div>
                {(isNotAnEmptyArray(recipientsForPills) ||
                  isNotAnEmptyArray(recipientGroups)) && (
                  <div className="flexer-row-start flex-wrap gap-2 mt-4">
                    {isNotAnEmptyArray(recipientGroups) &&
                      recipientGroups.map((addedGroup) => (
                        <RecipientAddedPill
                          key={addedGroup?.id}
                          recipient={addedGroup}
                          isExistingContactAdded={
                            filteredRecipients?.length === 1 &&
                            groupData == null
                          }
                        />
                      ))}

                    {isNotAnEmptyArray(recipientsForPills) &&
                      recipientsForPills.map((recipient, index) => (
                        <RecipientAddedPill
                          key={
                            (typeof recipient.group_name === "undefined"
                              ? "c-"
                              : "g-") +
                            recipient.id +
                            "-" +
                            index
                          }
                          recipient={recipient}
                        />
                      ))}
                    <span
                      className="link-text"
                      onClick={() => setSidebar(true)}
                    >
                      View all
                    </span>
                  </div>
                )}
              </div>
            </MessagesCard>

            {/* Sender message card */}
            <MessagesCard
              popoverId={"send-new-message-body-viewing"}
              label="From:"
              type="select"
              options={senderNumbers}
              optionType="senderNumbers"
              className={"mb-4"}
              onChangeSelectValue={onChangeNumberFrom}
              selected={senderNumber}
            />

            {/* Message scheduling card */}
            <MessagesCard label="Scheduling:" className={"mb-4"}>
              <div className="messages-card__scheduling flexer-row justify-content-between">
                <span>
                  {schedule ? getScheduleMessageSummary(schedule) : "None"}
                </span>
                <div className="flexer-row">
                  {schedule && (
                    <button
                      className="messages-card__scheduling-button scheduling-remove"
                      onClick={handleRemoveSchedule}
                    >
                      Remove
                    </button>
                  )}
                  <button
                    className="messages-card__scheduling-button"
                    onClick={handleEditSchedule}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </MessagesCard>

            {/* Message exclude card */}
            <MessagesCard label="We are automatically excluding:" type="filled">
              <ExcludingMembers
                threadType={threadType}
                interlocutorId={interlocutorId}
                openRecipientSidebar={() => setSidebar(true)}
              />
            </MessagesCard>
          </div>
          <SendNewMessagePreview
            number={senderNumber?.number_formatted}
            message={messageValue + "\n\n" + messageSignature}
            className="d-none d-lg-flex"
            images={images}
            videos={videos}
            vCards={vCards}
          />
        </div>
      </Scrollbars>
      {/* Video Length Exceed confirmation modal */}
      {!isEmptyObject(videoLengthExceedData) && (
        <VideoLengthExceedModal
          show={!isEmptyObject(videoLengthExceedData)}
          closeModal={closeVideoLengthExceedModal}
          onClickedTrimVideo={onClickedTrimVideo}
          textAreaRef={textAreaRef}
          onMessageTextChange={onSetMessageTextAndCloseFeatures}
          onWarningTextChange={setWarningText}
          videoLengthExceedData={videoLengthExceedData}
        />
      )}

      {/* Video Confirmation modal */}
      {!isEmptyObject(uploadedVideo) && (
        <VideoConfirmationModal
          show={!isEmptyObject(uploadedVideo)}
          closeModal={closeUploadedVideoConfirmationModal}
          textAreaRef={textAreaRef}
          onMessageTextChange={onSetMessageTextAndCloseFeatures}
          onWarningTextChange={setWarningText}
          uploadedVideo={uploadedVideo}
          onAddVideos={setVideos}
          onVideoError={setVideoErrorText}
        />
      )}
    </>
  );
};

SendNewMessageBody.propTypes = {
  ...sendNewMessageBodyPropTypes,
};

SendNewMessageBody.defaultPropTypes = {
  ...sendNewMessageBodyDefaultProps,
};

export default SendNewMessageBody;
