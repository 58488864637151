import PropTypes from "prop-types";
import { useUnpinnedMenusAndFilters } from "../../../utils/hooks/useUnpinnedMenusAndFilters";

const RenderBottomPinnedAndUnpinnedMenus = (props) => {
  const { renderPinnedMenus, renderUnPinnedMenus, menuKey, children } = props;

  const { sidebarBottomUnpinnedMenus } = useUnpinnedMenusAndFilters();

  if (
    renderPinnedMenus &&
    !sidebarBottomUnpinnedMenus?.includes(menuKey) &&
    children
  ) {
    return children;
  }

  if (
    renderUnPinnedMenus &&
    sidebarBottomUnpinnedMenus?.includes(menuKey) &&
    children
  ) {
    return children;
  }

  return null;
};

RenderBottomPinnedAndUnpinnedMenus.propTypes = {
  renderPinnedMenus: PropTypes.bool,
  renderUnPinnedMenus: PropTypes.bool,
  menuKey: PropTypes.string.isRequired,
};

RenderBottomPinnedAndUnpinnedMenus.defaultProps = {
  renderPinnedMenus: false,
  renderUnPinnedMenus: false,
};

export default RenderBottomPinnedAndUnpinnedMenus;
