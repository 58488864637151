import { removeDuplicatesFromArray } from "../utils/sendMessageHelpers";

const initialState = {
  sendStatus: null,
  sendError: null,
  sendErrorStatus: null,
  sendErrorData: null,
  sendLogId: null,
  openFilters: true,
  isNewScheduledMessage: null,
  messageRecipientId: null,

  // Send Scheduled Now
  sendScheduledNowStatus: null,
  sendScheduledNowId: null,

  // Stop Scheduled Now
  stopScheduledNowStatus: null,
  stopScheduledNowId: null,

  // Delete message
  deleteMessageStatus: null,
  deleteMessageLogId: null,
  deleteMessageGroupSmsBlastId: null,
  deleteMessageScheduleMessageId: null,
  deleteMessageError: null,
  deleteMessageEnableScheduleStopNow: false,

  // Template message
  templateMessage: null,

  // Show features
  showFeatures: false,

  // Signature remove
  isSignatureRemoved: false,

  // Send New Message modal
  senderNumber: null,
  messageContent: null,
  isModalToAdd: false,
  filteredRecipients: [],
  exludedRecipients: [],
  filteredRecipientsStatus: null,
  recipientGroups: [],
  recipientToBeRemovedData: {},
  existingGroupId: null,
  manuallyAddedContactIds: [],

  // Indicator for closing right side panel in messages
  isSendMessageWizard: false,

  // Do not show duplicate profile updated info modal
  doNotShowDuplicateProfileUpdatedInfoModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_MESSAGE":
      return {
        ...state,
        sendStatus: "loading",
        sendError: null,
        sendErrorStatus: null,
        sendErrorData: null,
        sendLogId: null,
        messageRecipientId: action.receiverId,
        isNewScheduledMessage: false,
      };
    case "SEND_MESSAGE_SUCCESS":
      const isScheduledMessage =
        action.payload.new_item &&
        action.payload.new_item?.length &&
        action.payload.new_item[0]?.schedule_message
          ? true
          : false;
      return {
        ...state,
        sendStatus: "success",
        sendError: action.payload.message,
        sendErrorStatus: action.payload.status,
        sendErrorData: null,
        sendLogId: action.payload.log_id,
        isNewScheduledMessage: isScheduledMessage,
      };
    case "SEND_MESSAGE_ERROR":
      return {
        ...state,
        sendStatus: "error",
        sendError: action.payload
          ? action.payload.message
          : "An error occurred while sending message.",
        sendErrorStatus: action.payload ? action.payload.status : null,
        sendErrorData: {
          restrictedPhrases: action.payload
            ? action.payload.restricted_phrases
            : null,
        },
      };

    case "SEND_MESSAGE.CLEAR":
      return {
        ...state,
        sendStatus: null,
        messageRecipientId: null,
      };

    // Send Scheduled Now
    case "MESSAGES/SEND_SCHEDULED_NOW":
      return {
        ...state,
        sendScheduledNowStatus: "loading",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/SEND_SCHEDULED_NOW.SUCCESS":
      return {
        ...state,
        sendScheduledNowStatus: "success",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/SEND_SCHEDULED_NOW.ERROR":
      return {
        ...state,
        sendScheduledNowStatus: "error",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/SEND_SCHEDULED_NOW.CLEAR":
      return {
        ...state,
        sendScheduledNowStatus: null,
        sendScheduledNowId: null,
      };

    // Stop Scheduled Now
    case "MESSAGES/STOP_SCHEDULED_NOW":
      return {
        ...state,
        stopScheduledNowStatus: "loading",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/STOP_SCHEDULED_NOW.SUCCESS": {
      return {
        ...state,
        stopScheduledNowStatus: "success",
        sendScheduledNowId: action.scheduleMessageId,
      };
    }

    case "MESSAGES/STOP_SCHEDULED_NOW.ERROR":
      return {
        ...state,
        stopScheduledNowStatus: "error",
        sendScheduledNowId: action.scheduleMessageId,
      };

    case "MESSAGES/STOP_SEND_SCHEDULED_NOW.CLEAR":
      return {
        ...state,
        stopScheduledNowStatus: null,
        sendScheduledNowStatus: null,
      };

    // Delete message
    case "MESSAGES/DELETE":
      return {
        ...state,
        deleteMessageStatus: "loading",
        deleteMessageLogId: action.logId,
        deleteMessageGroupSmsBlastId: action.groupSmsBlastId,
        deleteMessageScheduleMessageId: action.scheduleMessageId,
        deleteMessageError: null,
        deleteMessageEnableScheduleStopNow: false,
        sendError: null,
        sendErrorStatus: null,
        sendErrorData: null,
      };

    case "MESSAGES/DELETE.SUCCESS":
      return {
        ...state,
        deleteMessageStatus: "success",
        deleteMessageLogId: action.logId,
        deleteMessageGroupSmsBlastId: action.groupSmsBlastId,
        deleteMessageScheduleMessageId: action.scheduleMessageId,
        deleteMessageError: null,
        deleteMessageEnableScheduleStopNow: false,
      };

    case "MESSAGES/DELETE.ERROR":
      return {
        ...state,
        deleteMessageStatus: "error",
        deleteMessageLogId: action.logId,
        deleteMessageGroupSmsBlastId: action.groupSmsBlastId,
        deleteMessageScheduleMessageId: action.scheduleMessageId,
        deleteMessageError: action.payload ? action.payload.error : null,
        deleteMessageEnableScheduleStopNow: action.payload
          ? action.payload.enableScheduleStopNow
          : null,
      };

    case "MESSAGES/DELETE.CLEAR":
      return {
        ...state,
        deleteMessageStatus: null,
        deleteMessageLogId: null,
        deleteMessageGroupSmsBlastId: null,
        deleteMessageScheduleMessageId: null,
        deleteMessageError: null,
        deleteMessageEnableScheduleStopNow: false,
      };

    case "MESSAGES/SET_TEMPLATE":
      return {
        ...state,
        templateMessage: action.templateMessage,
      };

    case "MESSAGES/TOGGLE_SHOW_FEATURES": {
      return {
        ...state,
        showFeatures: action.show,
      };
    }

    case "TOGGLE_SIGNATURE_REMOVED": {
      return {
        ...state,
        isSignatureRemoved: action.bool,
      };
    }

    case "MESSAGES/TOGGLE_FILTERS":
    case "TOGGLE_GROUP_FILTERS":
      return {
        ...state,
        openFilters:
          typeof action.bool === "boolean" ? action.bool : !state.openFilters,
      };

    // Send New Message Wizard
    case "MESSAGES/SET_BASIC_INFO":
      return {
        ...state,
        senderNumber: action.senderNumber,
        messageContent: action.messageContent,
      };

    case "MESSAGES/SET_MODAL_TO_ADD":
      return {
        ...state,
        isModalToAdd:
          typeof action.isModalToAdd === "boolean"
            ? action.isModalToAdd
            : state.isModalToAdd,
      };

    case "MESSAGES/ADD_FILTERED_RECIPIENTS.SUCCESS": {
      return {
        ...state,
        filteredRecipients: action?.isOnExcludedMembersFilter
          ? state.filteredRecipients
          : action.payload,
        exludedRecipients: action?.isOnExcludedMembersFilter
          ? action.payload
          : state.exludedRecipients,
      };
    }

    case "MESSAGES/REMOVE_FILTERED_RECIPIENTS.SUCCESS": {
      let recipients = null;
      if (Array.isArray(action.payload)) {
        recipients = state.filteredRecipients.filter(
          (recipient) =>
            !action.payload.some(
              (removedRecipient) => removedRecipient.id === recipient.id
            )
        );
      } else {
        recipients = state.filteredRecipients.filter(
          (recipient) => recipient.id !== action.payload.id
        );
      }
      return {
        ...state,
        filteredRecipients: recipients,
      };
    }

    case "MESSAGES/FILTERED_RECIPIENTS.CLEAR": {
      return {
        ...state,
        filteredRecipients: [],
      };
    }

    case "MESSAGES/ADD_GROUPS_TO_RECIPIENTS.SUCCESS": {
      let groups = [];
      const newItemsToAdd = action.payload?.filter((item) => {
        const itemId = item.id;
        return !state.recipientGroups.some(
          (existingItem) => existingItem.id === itemId
        );
      });
      groups = [...state.recipientGroups, ...newItemsToAdd];
      return {
        ...state,
        recipientGroups: groups,
      };
    }

    case "MESSAGES/REMOVE_GROUPS_FROM_RECIPIENTS.SUCCESS": {
      let groups = null;
      if (Array.isArray(action.payload)) {
        groups = state.recipientGroups.filter(
          (group) =>
            !action.payload.some(
              (removedGroup) => removedGroup.group.id === group.id
            )
        );
      } else {
        groups = state.recipientGroups.filter(
          (group) => group.id !== action.payload.id
        );
      }
      return {
        ...state,
        recipientGroups: groups,
      };
    }

    case "MESSAGES/GROUPS_FROM_RECIPIENTS.CLEAR": {
      return {
        ...state,
        recipientGroups: [],
      };
    }

    case "MESSAGES/SET_RECIPIENT_TO_BE_REMOVED.SUCCESS": {
      return {
        ...state,
        recipientToBeRemovedData: action.payload || {},
      };
    }

    case "MESSAGES/SET_EXISTING_GROUP_ID.SUCCESS": {
      return {
        ...state,
        existingGroupId: action.payload,
      };
    }

    case "MESSAGES/SET_MANUALLY_ADDED_CONTACT_IDS": {
      const mergeManuallyIds = [
        ...state.manuallyAddedContactIds,
        ...action.payload,
      ];
      const removeDuplicates = removeDuplicatesFromArray(mergeManuallyIds);
      return {
        ...state,
        manuallyAddedContactIds: removeDuplicates,
      };
    }

    case "MESSAGES/SET_MANUALLY_ADDED_CONTACT_IDS_CLEAR": {
      return {
        ...state,
        manuallyAddedContactIds: [],
      };
    }

    case "MESSAGES/TOGGLE_SEND_MESSAGE_WIZARD": {
      return {
        ...state,
        isSendMessageWizard:
          action?.payload === true || action?.payload === false
            ? action?.payload
            : !state.isSendMessageWizard,
      };
    }

    case "MESSAGES/TOGGLE_DO_NOT_SHOW_DUP_PROFILE_UPDATED_INFO_MODAL": {
      return {
        ...state,
        doNotShowDuplicateProfileUpdatedInfoModal: action?.payload,
      };
    }

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
