import { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setCurrentCompany } from "../../actions/companies";
import { getV2Url } from "../../helpers";
import { history } from "../../store";
import { useSetParamCompAndRedirection } from "../../utils/hooks/useSetParamCompAndRedirection";

function mapStateToProps(store) {
  return {
    loggedUser: store.users.loggedUser,
    currentCompany: store.companies.currentCompany,
    currentCompanyStatus: store.companies.currentCompanyStatus,
    companiesStatus: store.companies.companiesStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentCompany: (id) => dispatch(setCurrentCompany(id)),
  };
}

function MessagesUndelivered(props) {
  const {
    // Redux props
    loggedUser,
    currentCompany,
    currentCompanyStatus,
    companiesStatus,

    // Redux functions
    setCurrentCompany,

    // Router
    match,
  } = props;

  const { companyId } = match.params;

  const redeirectUserByDefaultApp = useCallback(() => {
    if (loggedUser?.default_app_version === "v3") {
      history.push("/hub/messages/filter/undelivered-unread", {
        isRedirectFromEmailLink: true,
      });
    } else if (loggedUser?.default_app_version === "v2") {
      window.location.href = getV2Url("hub/messages#filter-undelivered");
    }
  }, [loggedUser]);

  useSetParamCompAndRedirection({
    companiesStatus,
    companyId,
    setCurrentCompany,
    redeirectUserByDefaultApp,
    currentCompanyStatus,
    currentCompany,
  });

  return null;
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessagesUndelivered)
);
