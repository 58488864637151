import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { ReactComponent as KeywordAutoreplyIcon } from "../../../assets/img/icons-new/campaign-type/automated-message/automated-message-black.svg";
import { ReactComponent as AutomatedMessageIcon } from "../../../assets/img/icons-new/campaigns/automated-message/automated-message.svg";
import { ReactComponent as ContestIcon } from "../../../assets/img/icons-new/campaigns/contest/contest.svg";
import { ReactComponent as DataCaptureIcon } from "../../../assets/img/icons-new/campaigns/data-capture/data-capture.svg";
import { ReactComponent as PollIcon } from "../../../assets/img/icons-new/campaigns/poll/poll.svg";
import { ReactComponent as BirthdayIcon } from "../../../assets/img/icons-new/campaigns/sms-birthday/sms-birthday.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/img/icons-new/dashboard/expand/expand-gray.svg";
import { ReactComponent as MinimizeIcon } from "../../../assets/img/icons-new/dashboard/minimize/minimize-gray.svg";
import { ReactComponent as CsvExportIcon } from "../../../assets/img/icons-new/general/csv/csv-green.svg";
import { ReactComponent as SyncIcon } from "../../../assets/img/icons-new/group-tab/integrations/import-sync/import-sync-black.svg";
import { ReactComponent as AddGroupsIcon } from "../../../assets/img/icons-new/main-submenu/add-group-black.svg";
import { ReactComponent as AddContactIcon } from "../../../assets/img/icons-new/main-submenu/add-person-black.svg";
import { ReactComponent as ExportIcon } from "../../../assets/img/icons-new/main-submenu/export-black.svg";
import { ReactComponent as NewCall } from "../../../assets/img/icons-new/main-submenu/new-call-black.svg";
import { ReactComponent as AddNewMessageIcon } from "../../../assets/img/icons-new/main-submenu/new-message-black.svg";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  hasAtleastOneActiveIntegration,
  isReactNativeApp,
} from "../../../helpers";
import { isMdOrBelowBreakpoint } from "../../../utils/breakpoints";
import useBreakpoint from "../../../utils/hooks/useBreakpoints";
import { getPermissionParsed } from "../../../utils/settingsHelpers";
import ActionButton from "../../ActionButton/ActionButton";
import ActiveIntegrations from "../../elements/ActiveIntegrations";

function mapStateToProps(store) {
  return {
    userPermissions: store.users.permissions,
    currentCompany: store.companies.currentCompany,
  };
}

const AddNewWidget = (props) => {
  const {
    // Redux props
    userPermissions,
    currentCompany,
  } = props;

  const [showAll, setShowAll] = useState(false);
  const history = useHistory();

  const breakpoint = useBreakpoint();
  const isMobile = useMemo(
    () => isMdOrBelowBreakpoint(breakpoint),
    [breakpoint]
  );

  const { messages_calls, groups, keywords, people, campaigns } =
    userPermissions;
  const rows = useMemo(() => {
    let initialRows = [
      {
        type: "primary",
        options: [
          getPermissionParsed(messages_calls, "send_msg")
            ? {
                icon: <AddNewMessageIcon />,
                label: "Send Text, Pic or Video",
                to: isReactNativeApp()
                  ? "#modal-new-message-receiver"
                  : "/send-new-message",
              }
            : null,
          getPermissionParsed(messages_calls, "call")
            ? {
                icon: <NewCall />,
                label: "Make a Call / Broadcast",
                to: "#modal-new-call",
              }
            : null,
          getPermissionParsed(groups, "add")
            ? {
                icon: <AddGroupsIcon />,
                label: "Create a Group",
                to: "#modal-new-group",
              }
            : null,
          getPermissionParsed(keywords, "view")
            ? {
                icon: <KeywordAutoreplyIcon />,
                label: "Create a Keyword/Autoreply",
                to: "/hub/auto-reply",
              }
            : null,
        ],
      },
    ];
    if (showAll) {
      initialRows.push({
        type: "ghost",
        options: [
          getPermissionParsed(people, "import") && !isReactNativeApp()
            ? {
                icon: <ExportIcon />,
                label: "Import (Upload) Contacts",
                to: "/hub/import-contacts",
                rightIcon: <CsvExportIcon height="23" width="23" />,
              }
            : null,
          hasAtleastOneActiveIntegration(currentCompany)
            ? {
                icon: <SyncIcon />,
                label: "Import (Sync) Group",
                to: "#modal-new-group",
                param: {
                  activeIntegrationByDefault: true,
                },
                rightIcon: <ActiveIntegrations className={"ml-3 mb-1"} />,
              }
            : null,
          getPermissionParsed(people, "add")
            ? {
                icon: <AddContactIcon />,
                label: "Add a Person",
                to: "#modal-add-person",
              }
            : null,
          campaigns && !isReactNativeApp()
            ? {
                icon: <DataCaptureIcon />,
                label: "Data Capture",
                to: "/hub/data-capture",
              }
            : null,
        ],
      });
      initialRows.push({
        type: "ghost",
        options: [
          campaigns
            ? {
                icon: <PollIcon />,
                label: "Create Poll",
                to: "/hub/create-poll",
              }
            : null,
          campaigns
            ? {
                icon: <BirthdayIcon />,
                label: "SMS Birthday",
                to: "/hub/sms-birthday",
              }
            : null,
          campaigns
            ? {
                icon: <ContestIcon />,
                label: "Create Contest",
                to: "/hub/create-contest",
              }
            : null,
          campaigns
            ? {
                icon: <AutomatedMessageIcon />,
                label: "Automated Message",
                to: "/hub/automated-message",
              }
            : null,
        ],
      });
    }

    return initialRows;
  }, [
    campaigns,
    currentCompany,
    groups,
    keywords,
    messages_calls,
    people,
    showAll,
  ]);

  const onClickToggleRow = useCallback(() => {
    setShowAll((prev) => !prev);
  }, []);

  const onClickButton = useCallback(
    (url, param = null) =>
      () => {
        history.push(url, param);
      },
    [history]
  );

  return (
    <div className="add-new-widget">
      {rows.map((row, index) => {
        return (
          <div key={index} className="add-new-widget-row">
            {row.options.map((item) => {
              if (!item) return null;

              return (
                <div className="add-new-widget-column">
                  <ActionButton
                    buttonClassName="add-new-widget-row-button"
                    size={isMobile ? "medium" : "small"}
                    text={item.label}
                    icon={item.icon}
                    type={row.type}
                    rightIcon={item.rightIcon}
                    onClick={onClickButton(item.to, item?.param)}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
      <div className="add-new-widget-row pt-2 mt-n2" onClick={onClickToggleRow}>
        <span className="cursor-pointer more-actions">
          {showAll ? (
            <React.Fragment>
              <span className="title">Less Actions</span>
              <MinimizeIcon />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span className="title">More Actions</span>
              <ExpandIcon />
            </React.Fragment>
          )}
        </span>
      </div>
    </div>
  );
};

AddNewWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
  userPermissions: PropTypes.object,
};

export default connect(mapStateToProps)(AddNewWidget);
