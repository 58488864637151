import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = (props) => {
  return (
    <div className="error-page-wrapper">
      <div className="logo-icon" />
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="container-error-content col">
            <div className="center-icon">
              <span>404</span>
            </div>
            <h1>Page not found</h1>
            <p>
              Uh oh, we can't seem to find the page you're looking for. Try
              going back to the previous page or see our{" "}
              <a href="https://help.pastorsline.com/en/" className="help-text">
                Help Center
              </a>{" "}
              for more information.
            </p>
            <Link
              to="/dashboard"
              className="btn btn-outline-primary btn-md dashboard-button"
            >
              Go to Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
