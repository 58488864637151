import { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setCurrentCompany } from "../../actions/companies";
import { getPrimaryUrl } from "../../helpers";
import { history } from "../../store";
import { useSetParamCompAndRedirection } from "../../utils/hooks/useSetParamCompAndRedirection";

function mapStateToProps(store) {
  return {
    loggedUser: store.users.loggedUser,
    currentCompany: store.companies.currentCompany,
    currentCompanyStatus: store.companies.currentCompanyStatus,
    companiesStatus: store.companies.companiesStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentCompany: (id) => dispatch(setCurrentCompany(id)),
  };
}

const AddHostedNumber = (props) => {
  const {
    // Redux props
    loggedUser,
    currentCompany,
    currentCompanyStatus,
    companiesStatus,

    // Redux functions
    setCurrentCompany,

    // Router
    match,
  } = props;
  const { companyId } = match.params;

  const redeirectUserByDefaultApp = useCallback(() => {
    if (loggedUser?.default_app_version === "v3") {
      history.push(
        `/hub/menus/filter/settings/admin-settings/submenu/admin-numbers/#modal-add-new-number`
      );
    } else if (loggedUser?.default_app_version === "v2") {
      window.location.href = getPrimaryUrl(
        `user_numbers/add_hosted_number_step_1`,
        companyId
      );
    }
  }, [companyId, loggedUser]);

  useSetParamCompAndRedirection({
    companiesStatus,
    companyId,
    setCurrentCompany,
    redeirectUserByDefaultApp,
    currentCompanyStatus,
    currentCompany,
  });

  return null;
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddHostedNumber)
);
