import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { ReactComponent as RedDot } from "../../../assets/img/icons-new/general/dot/dot-red.svg";
import ArchiveProgressGif from "../../../assets/img/gif/archive-progress/archive-progress-purple.gif";
import CloseProgressGif from "../../../assets/img/gif/close-progress/close-progress-purple.gif";

const ThreadItemActionMessage = (props) => {
  const {
    archivalProgress,
    unArchivalProgress,
    deletionProgress,
    closingProgress,
    reopeningProgress,
    contactDeleteProgress,
    batchDeleteProgress,
    isGroup,
  } = props;

  const renderIcon = useCallback((Icon, action) => {
    return <img src={Icon} width="22px" alt={`${action} in progress`} />;
  }, []);

  return (
    <div className="archival-progress-overlay">
      {archivalProgress && (
        <Fragment>
          {renderIcon(ArchiveProgressGif, "archiving")}
          <span className="archival-progress-text">
            You are archiving this {isGroup ? "Group" : "Person"}
          </span>
        </Fragment>
      )}
      {unArchivalProgress && (
        <Fragment>
          <RedDot />
          <span className="archival-progress-text">
            You are unarchiving this {isGroup ? "Group" : "Person"}
          </span>
        </Fragment>
      )}
      {deletionProgress && (
        <Fragment>
          <RedDot />
          <span className="archival-progress-text">
            You are deleting this {isGroup ? "Group" : "Person"}
          </span>
        </Fragment>
      )}
      {closingProgress && (
        <Fragment>
          {renderIcon(CloseProgressGif, "closing")}
          <span className="archival-progress-text">
            You are closing this conversation
          </span>
        </Fragment>
      )}
      {reopeningProgress && (
        <Fragment>
          <RedDot />
          <span className="archival-progress-text">
            You are reopening this conversation
          </span>
        </Fragment>
      )}
      {(contactDeleteProgress || batchDeleteProgress) && (
        <Fragment>
          <RedDot />
          <span className="archival-progress-text">
            You are deleting this person
          </span>
        </Fragment>
      )}
    </div>
  );
};

ThreadItemActionMessage.propTypes = {
  archivalProgress: PropTypes.bool,
  unArchivalProgress: PropTypes.bool,
  deletionProgress: PropTypes.bool,
  closingProgress: PropTypes.bool,
  reopeningProgress: PropTypes.bool,
  contactDeleteProgress: PropTypes.bool,
  batchDeleteProgress: PropTypes.bool,
  isGroup: PropTypes.bool,
};

export default ThreadItemActionMessage;
