import { getRequest, postRequest, putRequest } from "../../utils/requests";

export function fetchCompanyDefaultList(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "ADMIN_SETTINGS/FETCH_COMPANY_DEFAULT_LIST",
      "signatures/company-default.json",
      {
        axiosConfig: {
          params: {
            companyId,
          },
        },
      }
    );
}

export function updateCompanyDefault(body, newData, index) {
  return (dispatch) =>
    putRequest(
      dispatch,
      "ADMIN_SETTINGS/UPDATE_COMPANY_DEFAULT_LIST",
      "signatures/company-default.json",
      body,
      {
        successDispatchData: {
          newData,
          index,
        },
      }
    );
}

export function updateCompanyDefaultClear() {
  return (dispatch) =>
    dispatch({
      type: "ADMIN_SETTINGS/UPDATE_COMPANY_DEFAULT_LIST.CLEAR",
    });
}

export function fetchCompanySettings(companyId) {
  return (dispatch) =>
    getRequest(
      dispatch,
      "ADMIN_SETTINGS/FETCH_COMPANY_SETTINGS",
      "companies/settings.json",
      {
        axiosConfig: {
          params: { companyId },
        },
      }
    );
}
export function updateCompanySettings(data, updateField, actionOnFields) {
  const formData = new FormData();
  Object.keys(data || {}).forEach((key) => formData.append(key, data[key]));

  return (dispatch) =>
    postRequest(
      dispatch,
      "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS",
      "companies/settings.json",
      formData,
      {
        requestDispatchData: {
          field: updateField,
        },
        successDispatchData: {
          updateValue: data[updateField],
          field: updateField,
          actionOnFields,
          data,
        },
        errorDispatchData: {
          field: updateField,
        },
      }
    );
}
export function updateCompanySettingsClear(field) {
  return (dispatch) =>
    dispatch({
      type: "ADMIN_SETTINGS/UPDATE_COMPANY_SETTINGS_QUEUE.CLEAR",
      field,
    });
}

export function setSelectedUser(selectedUserRhs) {
  return (dispatch) =>
    dispatch({
      type: "ADMIN_SETTINGS/SET_SELECTED_USER",
      selectedUserRhs,
    });
}

export function setPaymentInfo(paymentInfo) {
  return (dispatch) =>
    dispatch({
      type: "ADMIN_SETTINGS/SET_PAYMENT_INFO",
      paymentInfo,
    });
}
