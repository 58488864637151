import React, { useCallback } from "react";

//new icons
import { ReactComponent as BroadcastIconSvg } from "../../../assets/img/icons-new/message-call-type/broadcast/broadcast-gray.svg";
import { ReactComponent as MissedCallSvg } from "../../../assets/img/icons-new/message-call-type/missed-call/missed-call-purple.svg";
import { ReactComponent as CallOutgoingSvg } from "../../../assets/img/icons-new/message-call-type/outgoing-call/outgoing-call-purple.svg";
import { ReactComponent as CallIncomingSvg } from "../../../assets/img/icons-new/message-call-type/incoming-call/incoming-call-purple.svg";
import { ReactComponent as VoicemailSvg } from "../../../assets/img/icons-new/message-call-type/voice-mail/voice-mail-purple.svg";
import { ReactComponent as PictureIconSvg } from "../../../assets/img/icons-new/general/image/image-purple.svg";
import { ReactComponent as NewMessageIcon } from "../../../assets/img/icons-new/message-tab/New-contact-action-icons/new-message-purple.svg";
import { ReactComponent as NewContactIcon } from "../../../assets/img/icons-new/message-tab/New-contact-action-icons/add-person-purple.svg";
import { ReactComponent as NewGroupIcon } from "../../../assets/img/icons-new/group-tab/add-group/add-group-gray.svg";
import { ReactComponent as CallIcon } from "../../../assets/img/icons-new/voice-tab/all-voice-message/all-voice-message-green.svg";
import { ReactComponent as ApplycommitteIcon } from "../../../assets/img/icons-new/general/double-arrow/double-arrow-right/double-arrow-right-grey.svg";
import {
  contactName,
  contactsName,
  formatDuration,
  formatPhoneNumber,
  groupName as getGroupName,
  tagName as getTagName,
  isCordovaApp,
  isPastorsLineGroup,
  LightTooltip,
} from "../../../helpers";
import ThreadsActionsArchivedGroup from "../../elements/ThreadsActionsArchivedGroup";
import SingleThreadActionsBlockClose from "../../elements/SingleThreadActionsBlockClose";
import AnchorWithTooltip from "../../AnchorWithTooltip";
import {
  getPermissionParsed,
  isNotAnEmptyArray,
} from "../../../utils/settingsHelpers";
import { removeDuplicatesFromArray } from "../../../utils/sendMessageHelpers";
import SpanWithTooltip from "../../SpanWithTooltip";

export const getDataPayload = ({ tab, group, contacts }) => {
  let dataPayload;
  switch (tab) {
    case "people":
      [dataPayload] = contacts;
      break;
    case "groups":
      dataPayload = group;
      break;
    case "messages":
    case "calls":
      dataPayload = group ? group : contacts[0];
      break;
    default:
      dataPayload = {};
  }

  return dataPayload;
};

export const getIntegrationIcon = (
  withContactDetails,
  withIntegrationIcon,
  isGroup,
  group,
  contact,
  isMultipleContacts,
  withPlIcon
) => {
  let integrationIcon = null;
  if (withContactDetails || withIntegrationIcon) {
    if (isGroup && group) {
      if (!!group.ccb_status) {
        integrationIcon = "CCB";
      } else if (!!group.pco_status) {
        integrationIcon = "PCO";
      } else if (!!group.mc_status) {
        integrationIcon = "MAILCHIMP";
      } else if (!!group.is_elvanto) {
        integrationIcon = "ELVANTO";
      } else if (!!group.is_breeze) {
        integrationIcon = "BREEZE";
      } else if (!!group.is_rock) {
        integrationIcon = "ROCK";
      }
    } else if (!isGroup && !isMultipleContacts && contact) {
      if (!!contact.is_ccb) {
        integrationIcon = "CCB";
      } else if (!!contact.is_pco) {
        integrationIcon = "PCO";
      } else if (!!contact.is_mc) {
        integrationIcon = "MAILCHIMP";
      } else if (!!contact.is_elvanto) {
        integrationIcon = "ELVANTO";
      } else if (!!contact.is_breeze) {
        integrationIcon = "BREEZE";
      } else if (!!contact.is_rock) {
        integrationIcon = "ROCK";
      } else if (withPlIcon) {
        integrationIcon = "PL";
      }
    }
  }
  return integrationIcon;
};

export const checkProgress = (progressIds, contacts) => {
  return progressIds?.some(
    (progressId) => !!contacts?.find((contact) => progressId === contact.id)
  );
};

export const checkGroupProgress = (progressIds, group) => {
  return !!progressIds?.find((progressId) => progressId === group.id);
};

export const getUrl = ({
  purpose,
  group,
  isGroup,
  contacts_ids,
  log,
  params,
  isSingleMemberDuplicateRestArchived,
  singleUnArchivedContact,
  tab,
  isGroupTag,
  isOnMobileMode,
}) => {
  let url = "#error";
  if (purpose === "call-modal") {
    if (isGroup && group) {
      url = `/hub/calls/group/${group?.id}#modal-new-voice-broadcast/${group.id}`;
    } else if (!isGroup && log && log.contact_id) {
      url = "#modal-call-contact/" + log.contact_id;
    } else if (!isGroup && contacts_ids && contacts_ids.length) {
      url = "#modal-call-contact/" + contacts_ids[0];
    }
  } else {
    if (isGroupTag && group) {
      url = `/hub/menus/filter/tags/${group.id}`;
    } else if (isGroup && group) {
      const { filter } = params;
      url = `/hub/${tab}/${filter ? `filter/${filter}/` : ""}group/${group.id}${
        tab === "groups" ? (isOnMobileMode ? "" : "/people") : ""
      }`;
    } else if (!isGroup && contacts_ids && contacts_ids.length) {
      const { filter } = params;
      let peopleTabUrl = "";
      if (tab === "people" && contacts_ids.length > 1) {
        peopleTabUrl = isSingleMemberDuplicateRestArchived
          ? `/${singleUnArchivedContact.id}/about`
          : "/about";
      } else if (tab === "people") {
        peopleTabUrl = "/about";
      }

      url = `/hub/${tab}/${filter ? `filter/${filter}/` : ""}contact/${
        isSingleMemberDuplicateRestArchived
          ? singleUnArchivedContact.id
          : contacts_ids[0]
      }${peopleTabUrl}`;
    }
  }

  return url;
};

export const getFromPrefix = ({ route, user, loggedUser }) => {
  let fromPrefix = "";
  if (route === "outbox" && user && loggedUser) {
    let otherUserName = null;
    if (user.id === loggedUser.id) {
      otherUserName = "You";
    } else {
      otherUserName = user.first_name.trim();
      if (user.last_name.trim().length) {
        otherUserName += ` ${user.last_name.split("-")[0].trim().substr(0, 1)}`;
      }
    }

    fromPrefix = otherUserName.length > 0 ? `${otherUserName}: ` : "";
  }

  return fromPrefix;
};

export const getClassName = ({
  unarchiveThis,
  deleteThis,
  isAllFlowsInProgress,
  isAnyFlowInProgress,
  secondRow,
  style,
  purpose,
  className,
  handleNewContact,
  unread_count,
  isActive,
  withContactDetails,
  withNewDuplicateContactView,
}) => {
  let newClassName = "message-single" + (className ? " " + className : "");
  newClassName += handleNewContact ? " new-contact" : "";
  if (!purpose || purpose.indexOf("modal") === -1) {
    if (typeof unread_count !== "undefined" && unread_count > 0) {
      newClassName += " unread";
    }
    newClassName +=
      unarchiveThis || deleteThis || (isActive && !isAllFlowsInProgress)
        ? " active"
        : "";
    newClassName += withNewDuplicateContactView
      ? " duplicate-contact-thread"
      : "";
  }

  if (!secondRow) {
    newClassName += ` contact-tab-single ${
      withNewDuplicateContactView
        ? "duplicate-contact-thread-without-second-row"
        : "thread-item-without-second-row"
    }`;
  }

  if (isAnyFlowInProgress) {
    newClassName += " inactive-contact";
  }

  if (withContactDetails) {
    newClassName += " with-detail";
    style["alignItems"] = "center";
  }

  return newClassName;
};

export const getDisplayName = ({
  isNewGroup,
  newGroupName,
  isGroup,
  isTag,
  group,
  contact,
  contacts,
  singleUnArchivedContact,
  checkedArchivedButtonRedux,
  purpose,
  withNewDuplicateContactView,
  isMultipleContacts,
}) => {
  let name = "Unknown";
  let phoneNumber = null;
  let isContactUpdating = false;
  let groupName = "Unknown";
  if (isNewGroup) {
    name = (
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {newGroupName}
      </span>
    );
  } else if (isGroup) {
    groupName = getGroupName(group);
    name = (
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {isTag ? getTagName(group) : groupName}
      </span>
    );
  } else if (contacts && purpose === "group-members-modal") {
    name = `${formatPhoneNumber(
      contacts[0].phone_number,
      contacts[0].country ? contacts[0].country.phonecode : ""
    )}`;
  } else if (contacts && contacts.length) {
    if (
      withNewDuplicateContactView &&
      isMultipleContacts &&
      isNotAnEmptyArray(contacts)
    ) {
      const primaryContact = contacts[0];
      const primaryContactPhoneNumber = `${
        primaryContact?.country?.phonecode || ""
      }${primaryContact?.phone_number || ""}`;
      name = contactName(null, null, primaryContactPhoneNumber);
    } else {
      name = contactsName(
        // if single contact archived show only the unArchived contact
        singleUnArchivedContact && !checkedArchivedButtonRedux
          ? [singleUnArchivedContact]
          : contacts,
        true
      );
    }
    const currentContact =
      singleUnArchivedContact && !checkedArchivedButtonRedux
        ? singleUnArchivedContact
        : contacts[0];
    if (currentContact.phone_number && currentContact.phone_number !== "0") {
      phoneNumber =
        "" +
        (currentContact.country ? currentContact.country.phonecode : "") +
        currentContact.phone_number;
    }
    if (currentContact.is_pco && currentContact.is_pco_pending) {
      isContactUpdating = true;
    }
  } else if (contact) {
    name = contactsName([contact], true);
    if (contact.phone_number && contact.phone_number !== "0") {
      phoneNumber =
        "" +
        (contact.country ? contact.country.phonecode : "") +
        contact.phone_number;
    }
    if (contact.is_pco && contact.is_pco_pending) {
      isContactUpdating = true;
    }
  }

  return { name, phoneNumber, isContactUpdating, groupName };
};

export const getGroupNameCountForHover = ({
  isGroup,
  groupName,
  group,
  tab,
}) => {
  const withGroupMembersCount = tab === "groups";
  const groupMembersCount = isGroup && group ? group.members_count : 0;
  const showGroupFullNameOnHover =
    groupName.length >= 16 ? (
      <>
        {groupName}
        <br />
      </>
    ) : (
      ""
    );
  return { withGroupMembersCount, groupMembersCount, showGroupFullNameOnHover };
};

export const getContactForHover = ({
  name,
  phoneNumber,
  isContactUpdating,
  isNewGroup,
}) => {
  const tooltipWithOnlyNumber = formatPhoneNumber(phoneNumber);

  const tooltipWithNameAndNumber = (
    <>
      {name?.props?.children}
      <br />
      {formatPhoneNumber(phoneNumber)}
    </>
  );

  const tooltipWithOnlyName = name?.props?.children;

  const showContactNameOnHover =
    name?.props?.children?.length >= 16
      ? tooltipWithNameAndNumber
      : tooltipWithOnlyNumber;

  const contactTooltip = isContactUpdating ? (
    "updating..."
  ) : phoneNumber ? (
    showContactNameOnHover
  ) : isNewGroup ? (
    "Create a group"
  ) : (
    <>
      {name?.props?.children?.length >= 16 ? (
        <>
          {tooltipWithOnlyName}
          <br />
        </>
      ) : (
        ""
      )}
      No phone number
    </>
  );
  return contactTooltip;
};

export const getTooltip = ({
  iconTooltip,
  isGroup,
  showGroupFullNameOnHover,
  groupMembersCount,
  phoneNumber,
  isContactUpdating,
  name,
  contactTooltip,
  singleUnArchivedContact,
  checkedArchivedButtonRedux,
  contacts,
  withoutTooltip,
}) => {
  let itemTooltipProps = null;
  if (withoutTooltip) return itemTooltipProps;
  if (!Boolean(iconTooltip) && isGroup) {
    itemTooltipProps = {
      title: (
        <div style={{ lineHeight: 1.8, fontSize: "0.7rem" }}>
          {showGroupFullNameOnHover}
          {groupMembersCount} Member{groupMembersCount !== 1 ? "s" : ""}
        </div>
      ),
    };
  } else if (
    (!Boolean(iconTooltip) && phoneNumber) ||
    isContactUpdating ||
    name?.props?.children?.length
  ) {
    itemTooltipProps = {
      title: (
        <div style={{ lineHeight: 1.8, fontSize: "0.7rem" }}>
          {contactTooltip}
        </div>
      ),
    };
  }

  if (
    !Boolean(iconTooltip) &&
    itemTooltipProps &&
    !isContactUpdating &&
    contacts &&
    contacts.length > 1
  ) {
    const dupesNames = [];
    // if single contact archived show only the unArchived contact
    if (singleUnArchivedContact && !checkedArchivedButtonRedux) {
      dupesNames.push(
        <>
          {singleUnArchivedContact.first_name?.trim()}{" "}
          {singleUnArchivedContact.last_name?.trim()}
        </>
      );
    } else {
      contacts.forEach((contact) => {
        dupesNames.push(
          <>{`${contact?.first_name?.trim()} ${contact?.last_name?.trim()}`}</>
        );
      });
    }
    const phoneNumFormatted = phoneNumber
      ? formatPhoneNumber(phoneNumber)
      : "No phone number";
    itemTooltipProps.title = (
      <div style={{ lineHeight: 1.8, fontSize: "0.7rem" }}>
        {phoneNumFormatted}
        <br />
        {dupesNames.map((dupName) => (
          <>
            {dupName}
            <br />
          </>
        ))}
      </div>
    );
  }

  return itemTooltipProps;
};

export const getTextMessage = ({
  isEmail,
  msgType,
  duration,
  textMessage,
  route,
  isImage,
  emailSubject,
  groupKeyword,
}) => {
  // determine item type
  let itemType = "text";
  if (isEmail === 1) {
    itemType = "email";
  } else if (msgType === "text") {
    itemType = "text";
  } else if (msgType === "voice" && duration > 0) {
    itemType = "voicemail";
  } else if (msgType === "broadcast") {
    itemType = "broadcast";
  } else if ((msgType === "voice" || msgType === "called") && duration === 0) {
    itemType = "call-missed";
  } else if (msgType === "callforward") {
    itemType = "call-forwarded";
  } else if (msgType === "called" && duration > 0) {
    itemType = "in-app-call";
  } else {
    itemType = "call-connected";
  }

  let text = "";
  let icon = null;

  text = textMessage ? textMessage.substr(0, 100) : "";
  if (itemType === "email") {
    text = text.length > 0 ? text : ("Subject: " + emailSubject).substr(0, 100);
  } else if (itemType === "voicemail") {
    icon = <VoicemailSvg className={"mr-1 icon-grey"} width={16} height={16} />;
    text = "New Voicemail";
  } else if (itemType === "broadcast") {
    icon = <BroadcastIconSvg className={"mr-1 icon-grey"} height={16} />;
    if (text.length === 0) {
      text = "Broadcast";
    }
  } else if (itemType === "call-missed") {
    if (route === "inbox") {
      icon = <MissedCallSvg className={"mr-1 icon-grey"} height={16} />;
    } else {
      icon = <CallOutgoingSvg className={"mr-1 icon-grey"} height={16} />;
    }
    text = "Missed Call";
  } else if (itemType === "call-forwarded") {
    icon = (
      <span className="text-muted">
        (<CallOutgoingSvg className={"mr-1 icon-grey"} height={16} />)
        <CallIncomingSvg className={"mr-1 icon-grey"} height={16} />
      </span>
    );
    text =
      "Forwarded Call" + (duration > 0 ? " " + formatDuration(duration) : "");
  } else if (itemType === "call-connected") {
    icon = <CallOutgoingSvg className={"mr-1 icon-grey"} height={16} />;
    text = "Called" + (duration > 0 ? " " + formatDuration(duration) : "");
  } else if (itemType === "in-app-call") {
    icon = <CallIncomingSvg className={"icon-grey"} height={16} />;
    text =
      "In-App Called" + (duration > 0 ? " " + formatDuration(duration) : "");
  } else if (isImage) {
    icon = <PictureIconSvg className={"mr-1 icon-grey"} height={16} />;
    if (text.length === 0) {
      text = "Picture";
    }
  } else if (groupKeyword) {
    icon = <ApplycommitteIcon className={"mr-1 icon-grey"} width={12} />;
  }

  return { text, icon };
};

export const getActionMenuGroupArchive = ({
  showCheckBox,
  isAllFlowsInProgress,
  archiveFilter,
  group,
  tab,
}) => {
  return (
    showCheckBox &&
    !isAllFlowsInProgress &&
    group &&
    archiveFilter &&
    tab === "groups" &&
    isPastorsLineGroup(group) && (
      <div onClick={(event) => event.preventDefault()}>
        <ThreadsActionsArchivedGroup group={group} />
      </div>
    )
  );
};

export const getUnblockedActionIcon = ({
  showCheckBox,
  isAllFlowsInProgress,
  threadFilter,
  voiceFilter,
  contactFilter,
  contact,
  group,
  tab,
}) => {
  return (
    showCheckBox &&
    !isAllFlowsInProgress &&
    !group &&
    ((tab === "messages" && threadFilter === "blocked") ||
      (tab === "calls" && voiceFilter === "blocked") ||
      (tab === "people" && contactFilter === "blocked")) && (
      <div onClick={(event) => event.preventDefault()}>
        <SingleThreadActionsBlockClose contactData={contact} group={group} />
      </div>
    )
  );
};

export const GetNewContactActionIcons = ({
  handleNewContact,
  purpose,
  isNewGroup,
  groupName,
  newContact,
  showNewContactActionTooltip,
  setShowNewContactActionTooltip,
  userPermissions,
  thredItemNewContactPermissions,
}) => {
  const {
    showAddPersonIcon,
    showCallIcon,
    showNewMessageIcon,
    showNewGroupIcon,
  } = thredItemNewContactPermissions;
  const onClickedActionIcon = useCallback(
    (contact, action) => (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowNewContactActionTooltip(false);
      handleNewContact(event, contact, action);
    },
    [handleNewContact, setShowNewContactActionTooltip]
  );

  const onClickAddPersonOnGroupMembersModal = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      handleNewContact(
        event,
        { ...newContact, clickedOnIcon: true },
        "group-members-modal"
      );
    },
    [handleNewContact, newContact]
  );

  if (
    purpose === "group-members-modal" &&
    getPermissionParsed(userPermissions?.people, "add") &&
    showAddPersonIcon
  )
    return (
      <SpanWithTooltip
        title="Add Person"
        id="add-people-popover"
        className="ml-1 new-contact-icon"
        onClick={onClickAddPersonOnGroupMembersModal}
      >
        <NewContactIcon />
      </SpanWithTooltip>
    );

  return (
    handleNewContact &&
    purpose !== "new-call-modal" &&
    purpose !== "group-members-modal" && (
      <LightTooltip
        arrow
        title="Choose one of this actions"
        open={showNewContactActionTooltip}
        placement="top"
      >
        <div
          className={`action ${
            showNewContactActionTooltip ? "border rounded p-1" : ""
          }`}
        >
          {isNewGroup &&
            purpose !== "modal" &&
            !isCordovaApp() &&
            getPermissionParsed(userPermissions?.groups, "add") &&
            showNewGroupIcon && (
              <AnchorWithTooltip
                title="Create a Group"
                href="#void"
                onClick={onClickedActionIcon(groupName, "create-new-group")}
                className="filter-icon new-contact-icon"
              >
                <NewGroupIcon className={"icon-grey"} />
              </AnchorWithTooltip>
            )}

          {!isNewGroup && purpose !== "modal" && !isCordovaApp() && (
            <>
              {getPermissionParsed(userPermissions?.people, "add") &&
                showAddPersonIcon && (
                  <AnchorWithTooltip
                    href="#void"
                    title="Add Person"
                    id="add-people-popover"
                    className={"new-contact-icon"}
                    onClick={onClickedActionIcon(newContact, "add-new-person")}
                  >
                    <NewContactIcon />
                  </AnchorWithTooltip>
                )}
              {getPermissionParsed(userPermissions?.messages_calls, "call") &&
                showCallIcon && (
                  <AnchorWithTooltip
                    data-toggle="tab"
                    title="Make a call"
                    href="#void"
                    className={"new-call-icon"}
                    onClick={onClickedActionIcon(newContact, "call")}
                  >
                    <span>
                      <CallIcon />
                    </span>
                  </AnchorWithTooltip>
                )}
            </>
          )}
          {!isNewGroup &&
            purpose !== "call-modal" &&
            getPermissionParsed(userPermissions?.messages_calls, "send_msg") &&
            showNewMessageIcon && (
              <AnchorWithTooltip
                data-toggle="tab"
                title="Send Text, Pic or Video"
                href="#void"
                className={"new-contact-icon"}
                onClick={onClickedActionIcon(newContact, "message")}
              >
                <NewMessageIcon />
              </AnchorWithTooltip>
            )}
        </div>
      </LightTooltip>
    )
  );
};

export const getNewCallDialPadActionIcons = ({ name, purpose }) => {
  return (
    purpose === "new-call-modal" && (
      <span
        className="name"
        style={{
          display: "flex",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </span>
    )
  );
};

export const isFavorite = ({ isGroup, group, contacts }) => {
  let favorite = false;
  if (isGroup && (group?.is_favorite === 1 || group?.is_favorite === "1")) {
    favorite = true;
  } else if (contacts) {
    favorite = contacts.some(
      (contact) => !!contact.is_favorite || !!contact.is_number_favorite
    );
  }

  return favorite;
};

export const checkThreadCurrentState = ({
  contact,
  group,
  threadFilter,
  closedThreadsIds,
}) => {
  if (!threadFilter) {
    if (contact) {
      return closedThreadsIds?.contacts?.some(
        (closeIdContact) => closeIdContact === contact?.contacts_ids[0]
      );
    } else if (group) {
      return closedThreadsIds?.groups?.some(
        (closeThreadId) => closeThreadId === group?.id
      );
    }
  }
};

export const closeRHSAllowed = ["messages", "calls"];

export const getMultipleItemsIntegrationNameArray = (
  contacts = [],
  isGroup = false,
  group = {}
) => {
  const integrationIcons = [];
  contacts.forEach((contact) => {
    if (contact.is_primary) {
      const integrationIconName = getIntegrationIcon(
        false, // withContactDetails
        true, // withIntegrationIcon
        isGroup, // isGroup
        group, // group
        contact,
        false, // isMultipleContacts
        true // withPlIcon
      );
      integrationIcons.push(integrationIconName);
    }
  });
  const removeDuplicates = removeDuplicatesFromArray(integrationIcons);
  return removeDuplicates;
};
