import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../modals/CustomModal";

const SendScheduleMessageConfirmationModal = (props) => {
  const { sendScheduledNowConfirmed, sendScheduledNowCancel, loading } = props;
  return (
    <CustomModal
      show={true}
      title={"Send Scheduled Message Now"}
      dialogClassName="modal-dialog-centered"
      buttons={[
        {
          type: "confirm",
          caption: "Send Now",
          handler: sendScheduledNowConfirmed,
          loading,
          disabled: loading,
        },
        {
          type: "cancel",
          caption: "Cancel",
          handler: sendScheduledNowCancel,
        },
      ]}
      closeModal={sendScheduledNowCancel}
    >
      <p className="mb-3 text-left" style={{ fontSize: "initial" }}>
        Are you sure you want to send this scheduled message now?
      </p>
    </CustomModal>
  );
};

SendScheduleMessageConfirmationModal.propTypes = {
  sendScheduledNowConfirmed: PropTypes.func.isRequired,
  sendScheduledNowCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SendScheduleMessageConfirmationModal.defaultProps = {
  loading: false,
};

export default SendScheduleMessageConfirmationModal;
