import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import TableHeader from "../../hub/HubThreadViewComponents/TableHeader";
import DashboardCard from "./DashboardCard";
import { Chip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getPermissionParsed } from "../../../utils/settingsHelpers";
import useUsersStore from "../../../utils/hooks/ReduxHooks/userStore";
import {
  ADMIN_BILLING_ROUTE,
  ADMIN_NUMBERS_ROUTE,
  ADMIN_ORGANIZATION_PROFILE_ROUTE,
  ADMIN_SECURITY_ROUTE,
  ADMIN_SETTINGS_ROUTE,
  ADMIN_USERS_ACTIVE_USERS_ROUTE,
  ADMIN_USERS_ROUTE,
  APP_SETTINGS_ROUTE,
  APP_SETTINGS_MESSAGES_ROUTE,
  APP_SETTINGS_VOICE_ROUTE,
  APP_SETTINGS_PEOPLE_ROUTE,
  APP_SETTINGS_GROUPS_ROUTE,
  USER_GREETINGS_ROUTE,
  USER_NOTIFICATIONS_ROUTE,
  USER_NUMBER_ROUTE,
  USER_ORGANIZATIONS_ROUTE,
  USER_PROFILE_ROUTE,
  USER_SETTINGS_ROUTE,
  USER_SIGNATURES_ROUTE,
  ADMIN_BILLING_CURRENT_PLAN_ROUTE,
  ADMIN_SIGNATURES_ROUTE,
  USER_NOTIFICATIONS_ALL_ROUTE,
  USER_AFFILIATE_ROUTE,
} from "../../Menus/routes";
import useCampaignsStore from "../../../utils/hooks/ReduxHooks/campaignsStore";

const SitemapWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    isActive,
    index,
    onClickTrash,
  } = props;
  const {
    users: { permissions: userPermissions },
  } = useUsersStore();
  const { setCampaignsEmbedURL } = useCampaignsStore();

  const firstRow = useMemo(() => {
    return {
      cols: [
        {
          text: "Main",
          stateProp: true,
          showSortArrows: false,
        },
        {
          text: "More Menu",
          stateProp: true,
          showSortArrows: false,
        },
        {
          text: "Campaigns",
          stateProp: true,
          showSortArrows: false,
        },
      ],
      items: [
        [
          getPermissionParsed(userPermissions?.messages_calls, "send_msg")
            ? {
                label: "Message",
                to: "/hub/messages/filter/received",
              }
            : {},
          getPermissionParsed(userPermissions?.tags, "view")
            ? {
                label: "Tags",
                to: "/hub/menus/filter/tags",
              }
            : {},
          getPermissionParsed(userPermissions?.campaigns, "all")
            ? {
                label: "Automated Messages",
                to: "/hub/menus/filter/campaigns#campaigns/automation",
                onClick: () => setCampaignsEmbedURL("campaigns/automation"),
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.messages_calls, "call")
            ? {
                label: "Voice",
                to: "/hub/calls/filter/received",
              }
            : {},
          getPermissionParsed(userPermissions?.web_widgets, "all")
            ? {
                label: "Web Widgets",
                to: "/hub/menus/filter/web-widgets",
              }
            : {},
          getPermissionParsed(userPermissions?.campaigns, "all")
            ? {
                label: "Contest",
                to: "/hub/menus/filter/campaigns#campaigns/contest",
                onClick: () => setCampaignsEmbedURL("campaigns/contest"),
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.people, "add")
            ? {
                label: "People",
                to: "/hub/people",
              }
            : {},
          getPermissionParsed(userPermissions?.keywords, "view")
            ? {
                label: "Shortcode Keywords",
                to: "/hub/menus/filter/shortcode-keywords",
              }
            : {},
          getPermissionParsed(userPermissions?.campaigns, "all")
            ? {
                label: "Data Capture",
                to: "/hub/menus/filter/campaigns#campaigns/datacapture",
                onClick: () => setCampaignsEmbedURL("campaigns/datacapture"),
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.groups, "all")
            ? {
                label: "Groups",
                to: "/hub/groups",
              }
            : {},
          getPermissionParsed(userPermissions?.analytics, "all")
            ? {
                label: "Analytics",
                to: "/hub/menus/filter/analytics/logs/submenu/legacy-logs",
              }
            : {},
          getPermissionParsed(userPermissions?.keywords, "view")
            ? {
                label: "Keyword/Autoreply",
                to: "/hub/menus/filter/campaigns#campaigns/autoreply",
                onClick: () => setCampaignsEmbedURL("campaigns/autoreply"),
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.campaigns, "all")
            ? {
                label: "Campaigns",
                to: "/hub/menus/filter/campaigns",
              }
            : {},
          getPermissionParsed(userPermissions?.sms_templates, "all")
            ? {
                label: "Templates",
                to: "/hub/menus/filter/templates",
              }
            : {},
          getPermissionParsed(userPermissions?.campaigns, "all")
            ? {
                label: "Poll",
                to: "/hub/menus/filter/campaigns#campaigns/poll",
                onClick: () => setCampaignsEmbedURL("campaigns/poll"),
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.gifts, "all")
            ? {
                label: "Gifts",
                to: "#modal-gift-claim",
              }
            : {},
          {
            label: "QR Code",
            to: "/hub/menus/filter/QR-code",
          },
          getPermissionParsed(userPermissions?.campaigns, "all")
            ? {
                label: "SMS Birthday",
                to: "/hub/menus/filter/campaigns#campaigns/birthday",
                onClick: () => setCampaignsEmbedURL("campaigns/birthday"),
              }
            : {},
        ],
        [
          {},
          {
            label: "Settings",
            to: "/hub/menus/filter/settings",
          },
          {},
        ],
      ],
    };
  }, [userPermissions, setCampaignsEmbedURL]);

  const secondRow = useMemo(() => {
    return {
      cols: [
        {
          text: "User Settings",
          stateProp: true,
          showSortArrows: false,
        },
        {
          text: "Admin Settings",
          stateProp: true,
          showSortArrows: false,
        },
        {
          text: "App Settings",
          stateProp: true,
          showSortArrows: false,
        },
      ],
      items: [
        [
          {
            label: "My Profile",
            to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_PROFILE_ROUTE}`,
          },
          {
            label: "Organization Profile",
            to: `/hub/menus/filter/settings/${ADMIN_SETTINGS_ROUTE}/submenu/${ADMIN_ORGANIZATION_PROFILE_ROUTE}`,
          },
          {
            label: "Messages Settings",
            to: `/hub/menus/filter/settings/${APP_SETTINGS_ROUTE}/submenu/${APP_SETTINGS_MESSAGES_ROUTE}`,
          },
        ],
        [
          getPermissionParsed(userPermissions?.user_settings_my_numbers, "all")
            ? {
                label: "My Numbers",
                to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_NUMBER_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.admin_settings_users, "all")
            ? {
                label: "Users",
                to: `/hub/menus/filter/settings/${ADMIN_SETTINGS_ROUTE}/submenu/${ADMIN_USERS_ROUTE}/${ADMIN_USERS_ACTIVE_USERS_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.messages_calls, "all")
            ? {
                label: "Voice Settings",
                to: `/hub/menus/filter/settings/${APP_SETTINGS_ROUTE}/submenu/${APP_SETTINGS_VOICE_ROUTE}`,
              }
            : {},
        ],
        [
          getPermissionParsed(
            userPermissions?.user_settings_my_signatures,
            "all"
          )
            ? {
                label: "My Signatures",
                to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_SIGNATURES_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.billings, "all")
            ? {
                label: "Billing",
                to: `/hub/menus/filter/settings/${ADMIN_SETTINGS_ROUTE}/submenu/${ADMIN_BILLING_ROUTE}/${ADMIN_BILLING_CURRENT_PLAN_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.people, "all")
            ? {
                label: "People Settings",
                to: `/hub/menus/filter/settings/${APP_SETTINGS_ROUTE}/submenu/${APP_SETTINGS_PEOPLE_ROUTE}`,
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.user_settings_greetings, "all")
            ? {
                label: "My Greetings/VM",
                to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_GREETINGS_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.admin_settings_signatures, "all")
            ? {
                label: "Signatures",
                to: `/hub/menus/filter/settings/${ADMIN_SETTINGS_ROUTE}/submenu/${ADMIN_SIGNATURES_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.groups, "all")
            ? {
                label: "Group Settings",
                to: `/hub/menus/filter/settings/${APP_SETTINGS_ROUTE}/submenu/${APP_SETTINGS_GROUPS_ROUTE}`,
              }
            : {},
        ],
        [
          getPermissionParsed(userPermissions?.notifications, "all")
            ? {
                label: "My Notifications",
                to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_NOTIFICATIONS_ROUTE}/${USER_NOTIFICATIONS_ALL_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.admin_settings_numbers, "all")
            ? {
                label: "Numbers (admin)",
                to: `/hub/menus/filter/settings/${ADMIN_SETTINGS_ROUTE}/submenu/${ADMIN_NUMBERS_ROUTE}`,
              }
            : {},
          {
            label: "Display Settings",
            to: `#modal-display-preferences`,
          },
        ],
        [
          getPermissionParsed(userPermissions?.organizations, "list")
            ? {
                label: "My Organizations",
                to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_ORGANIZATIONS_ROUTE}`,
              }
            : {},
          getPermissionParsed(userPermissions?.security, "all")
            ? {
                label: "Security",
                to: `/hub/menus/filter/settings/${ADMIN_SETTINGS_ROUTE}/submenu/${ADMIN_SECURITY_ROUTE}`,
              }
            : {},
          {},
        ],
        [
          {
            label: (
              <div className="flexer-row-start">
                <span>Affiliate</span>
                <Chip
                  label="NEW"
                  color="succes"
                  size="small"
                  classes={{
                    root: "sidebar-menu-new-chip",
                    label: "sidebar-menu-new-chip-label",
                  }}
                />
              </div>
            ),
            to: `/hub/menus/filter/settings/${USER_SETTINGS_ROUTE}/submenu/${USER_AFFILIATE_ROUTE}`,
          },
          {},
          {},
        ],
      ],
    };
  }, [userPermissions]);

  const sitemapTable = useCallback((data) => {
    return (
      <>
        <TableHeader sitemap hideCheckBoxCell cells={data.cols} />
        {data.items.map((row, index) => (
          <tr key={index}>
            {row.map((item) => (
              <td key={item.to} className="px-3 py-2">
                <small>
                  <Link
                    onClick={item?.onClick ? item?.onClick : null}
                    to={item.to}
                  >
                    {item.label}
                  </Link>
                </small>
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  }, []);

  return (
    <DashboardCard
      headerContent={"Sitemap"}
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
    >
      <table className="table sitemap-widget-table">
        {sitemapTable(firstRow)}
        {sitemapTable(secondRow)}
      </table>
    </DashboardCard>
  );
};

SitemapWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};

export default SitemapWidget;
