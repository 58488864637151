import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//new icons
import { ReactComponent as MoreIcon } from "../../assets/img/icons-new/main-nav/more/more-gray.svg";
import { ReactComponent as VerticalMenu } from "../../assets/img/icons-new/general/more/more-gray.svg";
import { ReactComponent as ExportIcon } from "../../assets/img/icons-new/general/export/export-purple.svg";
import { ReactComponent as SendMessageIcon } from "../../assets/img/icons-new/message-tab/new-message/new-message-purple.svg";
import { ReactComponent as SmallDownArrow } from "../../assets/img/icons-new/general/expand/expand-white.svg";
import { ReactComponent as SelectDropDownSVG } from "../../assets/img/icons-new/general/arrow/select-drop-down-gray.svg";
import { ReactComponent as CallBroadcastIcon } from "../../assets/img/icons-new/number-type/local/local-2-purple.svg";

import { Popover as PopoverReactstrap, PopoverBody } from "reactstrap";
import ButtonWithTooltip from "../ButtonWithTooltip";
import MultiSelectOverlayLoader from "./MultiSelectOverlayLoader";
import { getPermissionParsed } from "../../utils/settingsHelpers";
import { isEmptyObject } from "../../helpers";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";

function mapStateToProps(store) {
  return {
    isGroupAllMembersSelected: store.groups.isGroupAllMembersSelected,
    headerInProgress: store.threads.headerInProgress,
    userPermissions: store.users.permissions,
  };
}

const ThreadsActionsPopover = (props) => {
  const {
    menuItems,
    id,
    toggle,
    isOpen,
    placement,
    showVerticalMenu,
    onThreadGroupView,
    showButtonMenu,
    verticalMenuProps,
    className,
    isGroupAllMembersSelected,
    headerInProgress,
    popOverClassName,
    userPermissions,
    isLocallyRemovedContactSelected,
    isOnLHSThreadsActions,
    isActionsMenu,
    icon,
    handleCall,
    areMoreContactsSelected,
    withAllOptionInMessagesTab,
  } = props;
  const breakpoint = useBreakpoint();

  const renderSendMessageText = useMemo(() => {
    if (isMdOrBelowBreakpoint(breakpoint)) {
      return "Send Message";
    }
    return "Send Message to Selection";
  }, [breakpoint]);

  const onClickMenuButton = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const renderMenuButton = useCallback(() => {
    if (isOnLHSThreadsActions) {
      return (
        <span className="d-flex align-items-center">
          Actions <SmallDownArrow />
        </span>
      );
    } else if (isActionsMenu) {
      return (
        <button className="action-button action-button-default action-button-medium">
          <span>Actions</span>
          <span className="action-button-icon-right">
            <span className="action-button-trailing-more-icon">
              <SelectDropDownSVG />
            </span>
          </span>
        </button>
      );
    } else if (showVerticalMenu) {
      return <VerticalMenu width={16} height={16} {...verticalMenuProps} />;
    }
    return <MoreIcon />;
  }, [
    isOnLHSThreadsActions,
    isActionsMenu,
    showVerticalMenu,
    verticalMenuProps,
  ]);

  const getButtonMenu = useCallback(() => {
    if (showButtonMenu && !isEmptyObject(menuItems?.props)) {
      return (
        <ButtonWithTooltip
          className={`${
            isOnLHSThreadsActions ? "lhs-thread-actions-btn" : "actions-btns"
          } ${className} themed-color`}
          placement="top"
          title="Action Menu"
          id={id}
          onClick={onClickMenuButton}
        >
          {renderMenuButton()}
        </ButtonWithTooltip>
      );
    } else {
      return null;
    }
  }, [
    className,
    id,
    menuItems,
    onClickMenuButton,
    showButtonMenu,
    renderMenuButton,
    isOnLHSThreadsActions,
  ]);

  const iconContent = useMemo(() => {
    if (icon) {
      return (
        <div className="cursor-pointer" id={id}>
          {icon}
        </div>
      );
    } else if (onThreadGroupView.sendMessage && !withAllOptionInMessagesTab) {
      return (
        <div className="group-selected">
          {getPermissionParsed(userPermissions?.messages_calls, "send_msg") &&
            !isLocallyRemovedContactSelected && (
              <div
                className="group-selected-item cursor-pointer"
                onClick={onThreadGroupView.sendMessage}
              >
                <SendMessageIcon width={24} height={24} />
                <span className="ml-2">{renderSendMessageText}</span>
              </div>
            )}
          {getPermissionParsed(
            userPermissions?.messages_calls,
            areMoreContactsSelected ? "send_broadcast" : "call"
          ) && (
            <div
              className="group-selected-item cursor-pointer"
              onClick={handleCall}
            >
              <CallBroadcastIcon />
              <span className="ml-2">
                Call {areMoreContactsSelected ? "Broadcast" : ""}
              </span>
            </div>
          )}
          {getPermissionParsed(userPermissions?.people, "export") &&
            !isLocallyRemovedContactSelected &&
            onThreadGroupView?.export && (
              <div
                className="group-selected-item cursor-pointer"
                onClick={onThreadGroupView.export}
              >
                <ExportIcon />
                <span className="ml-2">Export Selection</span>
              </div>
            )}
          <div className="group-selected-item cursor-pointer" id={id}>
            <span className="mr-1">More </span>
            <SelectDropDownSVG width={9} height={5} />
          </div>
        </div>
      );
    } else {
      return getButtonMenu();
    }
  }, [
    getButtonMenu,
    icon,
    id,
    isLocallyRemovedContactSelected,
    onThreadGroupView.export,
    onThreadGroupView.sendMessage,
    renderSendMessageText,
    userPermissions?.messages_calls,
    userPermissions?.people,
    areMoreContactsSelected,
    handleCall,
    withAllOptionInMessagesTab,
  ]);

  const isOnRhs = onThreadGroupView || isGroupAllMembersSelected;

  return (
    <>
      {(onThreadGroupView.exportWorkflow || headerInProgress) && (
        <MultiSelectOverlayLoader isOnRhs={isOnRhs}>
          Exporting
        </MultiSelectOverlayLoader>
      )}
      {iconContent}
      {!isEmptyObject(menuItems?.props) && (
        <PopoverReactstrap
          isOpen={isOpen}
          toggle={toggle}
          placement={placement}
          trigger="legacy"
          target={id}
          className={`thread-other-actions-popover ${popOverClassName}`}
        >
          <PopoverBody>{menuItems}</PopoverBody>
        </PopoverReactstrap>
      )}
    </>
  );
};

ThreadsActionsPopover.propTypes = {
  menuItems: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  placement: PropTypes.string,
  showVerticalMenu: PropTypes.bool,
  isOnLHSThreadsActions: PropTypes.bool,
  isLocallyRemovedContactSelected: PropTypes.bool,
  withAllOptionInMessagesTab: PropTypes.bool,
  onThreadGroupView: PropTypes.objectOf(PropTypes.any),
  verticalMenuProps: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  popOverClassName: PropTypes.string,
  icon: PropTypes.node,
};

ThreadsActionsPopover.defaultProps = {
  placement: "bottom",
  showVerticalMenu: true,
  onThreadGroupView: {},
  showButtonMenu: true,
  isLocallyRemovedContactSelected: false,
  verticalMenuProps: {},
  className: "",
  popOverClassName: "",
  isOnLHSThreadsActions: false,
  withAllOptionInMessagesTab: false,
  icon: null,
};

export default connect(mapStateToProps)(ThreadsActionsPopover);
