import React, { useState } from "react";
import PropTypes from "prop-types";
import DashboardCard from "./DashboardCard";
import TableHeader from "../../hub/HubThreadViewComponents/TableHeader";
import { ReactComponent as CircledCheckSvg } from "../../../assets/img/icons-new/message-tab/closed/closed-green.svg";
import TableInfinityScroll from "../../hub/HubThreadViewComponents/TableInfinityScroll";
import { useGridResize } from "./useGridResize";

const BasicsWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    onSizeChange,
    isActive,
    index,
    onClickTrash,
  } = props;

  const [viewData, setViewData] = useState(false);
  const { headerRef, containerRef } = useGridResize(index, onSizeChange);

  return (
    <DashboardCard
      headerRef={headerRef}
      headerContent={
        <div className="text-white font-weight-500">
          Getting Started: Learn Pastorsline Basics and Earn Rewards!
        </div>
      }
      headerColorType="purple"
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      onSizeChange={onSizeChange}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
    >
      <div className="basics-widget-container" ref={containerRef}>
        {!viewData ? (
          <div className="px-3 h-100 d-flex align-items-center mt-2">
            <div>
              You have 8 ways to earn more rewards!{" "}
              <span
                className="cursor-pointer color-primary"
                onClick={() => setViewData(true)}
              >
                {" "}
                View
              </span>
            </div>
          </div>
        ) : (
          <TableInfinityScroll
            loadMore={() => {}}
            page={1}
            searchValue={""}
            companyId={2}
            fetchFunction={() => {}}
          >
            <table className="table">
              <TableHeader
                className="basics-widget-table-header"
                cells={[
                  {
                    text: "Category",
                    stateProp: true,
                  },
                  {
                    text: "Reward",
                    stateProp: true,
                  },
                  {
                    text: "",
                    stateProp: true,
                    showSortArrows: false,
                  },
                ]}
              />
              <tbody className="basics-widget-table-body">
                <tr>
                  <td></td>
                  <td>Give us feedback!</td>
                  <td className="label-dark">100 Credits</td>
                  <td className="label-green">
                    <div className="d-flex align-items-center">
                      <CircledCheckSvg className="mr-2" /> Completed
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="color-primary">Give us feedback!</td>
                  <td className="label-dark">100 Credits</td>
                  <td />
                </tr>
                <tr>
                  <td></td>
                  <td className="color-primary">Give us feedback!</td>
                  <td className="label-dark">100 Credits</td>
                  <td />
                </tr>
              </tbody>
            </table>
          </TableInfinityScroll>
        )}
      </div>
    </DashboardCard>
  );
};

BasicsWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};

BasicsWidget.defaultProps = {
  isActive: false,
};

export default BasicsWidget;
