const initialState = {
  // Audio Recordings data
  data: {},
  dataStatus: {},

  // Add Audio Web Recording
  addWebId: null,
  addWebStatus: null,
  addWebErrors: null,

  // Add Audio Call Recording
  addCallId: null,
  addCallStatus: null,
  addCallErrors: null,

  // Terminate Call Recording
  terminateCallId: null,
  terminateCallStatus: null,
};

export default function reducer(state = initialState, action) {
  let data = null;
  let dataStatus = null;
  let errors = null;

  switch (action.type) {
    // Fetch single record data
    case "AUDIO_RECORDINGS/FETCH_DATA":
      dataStatus = { ...state.dataStatus };
      dataStatus[action.audioRecordingId] = "loading";
      return {
        ...state,
        dataStatus,
      };

    case "AUDIO_RECORDINGS/FETCH_DATA.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.audioRecordingId] = action.payload.audio_recording;
      dataStatus[action.audioRecordingId] = "success";
      return {
        ...state,
        data,
        dataStatus,
      };

    case "AUDIO_RECORDINGS/FETCH_DATA.ERROR":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.audioRecordingId] = null;
      dataStatus[action.audioRecordingId] = "error";
      return {
        ...state,
        data,
        dataStatus,
      };

    // Add Audio Web Recording
    case "AUDIO_RECORDINGS/ADD_WEB":
      return {
        ...state,
        addWebId: null,
        addWebStatus: "loading",
        addWebErrors: null,
      };

    case "AUDIO_RECORDINGS/ADD_WEB.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.payload.audio_recording.id] = action.payload.audio_recording;
      dataStatus[action.payload.audio_recording.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        addWebId: action.payload.audio_recording.id,
        addWebStatus: "success",
        addWebErrors: null,
      };

    case "AUDIO_RECORDINGS/ADD_WEB.ERROR":
      errors = null;
      if (action.payload && typeof action.payload.errors !== "undefined") {
        errors = action.payload.errors;
      } else if (
        action.payload &&
        typeof action.payload.message !== "undefined"
      ) {
        errors = action.payload.message;
      }
      return {
        ...state,
        addWebId: null,
        addWebStatus: "error",
        addWebErrors: errors,
      };

    case "AUDIO_RECORDINGS/ADD_WEB.CLEAR":
      return {
        ...state,
        addWebId: null,
        addWebStatus: null,
        addWebErrors: null,
      };

    // Add Audio Web Recording
    case "AUDIO_RECORDINGS/ADD_CALL":
      return {
        ...state,
        addCallId: null,
        addCallStatus: "loading",
        addCallErrors: null,
      };

    case "AUDIO_RECORDINGS/ADD_CALL.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.payload.audio_recording.id] = action.payload.audio_recording;
      dataStatus[action.payload.audio_recording.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        addCallId: action.payload.audio_recording.id,
        addCallStatus: "success",
        addCallErrors: null,
      };

    case "AUDIO_RECORDINGS/ADD_CALL.ERROR":
      errors = null;
      if (action.payload && typeof action.payload.errors !== "undefined") {
        errors = action.payload.errors;
      } else if (
        action.payload &&
        typeof action.payload.message !== "undefined"
      ) {
        errors = action.payload.message;
      }
      return {
        ...state,
        addCallId: null,
        addCallStatus: "error",
        addCallErrors: errors,
      };

    case "AUDIO_RECORDINGS/ADD_CALL.CLEAR":
      return {
        ...state,
        addCallId: null,
        addCallStatus: null,
        addCallErrors: null,
      };

    // Terminate Call Recording
    case "AUDIO_RECORDINGS/TERMINATE_CALL":
      return {
        ...state,
        terminateCallId: null,
        terminateCallStatus: "loading",
      };

    case "AUDIO_RECORDINGS/TERMINATE_CALL.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.payload.audio_recording.id] = action.payload.audio_recording;
      dataStatus[action.payload.audio_recording.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        terminateCallId: action.payload.audio_recording.id,
        terminateCallStatus: "success",
      };

    case "AUDIO_RECORDINGS/TERMINATE_CALL.ERROR":
      return {
        ...state,
        terminateCallId: null,
        terminateCallStatus: "error",
      };

    case "AUDIO_RECORDINGS/TERMINATE_CALL.CLEAR":
      return {
        ...state,
        terminateCallId: null,
        terminateCallStatus: null,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
