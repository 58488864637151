import React, { useRef, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchGraySvg } from "../../../assets/img/icons-new/general/search/search-gray.svg";
import { ReactComponent as CircleXMarkBlack } from "../../../assets/img/icons-new/general/cirle-xmark/circle-xmark-black.svg";
import { ReactComponent as CircleXMarkWhite } from "../../../assets/img/icons-new/general/cirle-xmark/circle-xmark-white.svg";
import ThemeContext from "../../../ThemeContext/ThemeContext";
import { GLOBAL_THEME } from "../../../utils/constants";
import useGroupsStore from "../../../utils/hooks/ReduxHooks/groupsStore";

const SearchBox = (props) => {
  const {
    value,
    onChange,
    loading,
    placeholder,
    className,
    onKeyUp,
    onClickClear,
    hasSearchingError,
    focusSearchBox,
    searchRef,
    disabled,
    hasClearQueryButton,
    withIcon,
    onPaste,
    autoFocus,
  } = props;

  const searchRefLocal = useRef("");
  const searchRefInternal = searchRef ? searchRef : searchRefLocal;
  const { globalTheme } = useContext(ThemeContext);

  // Redux hooks
  const { setGroupSearch } = useGroupsStore();

  useEffect(() => {
    //this useEffect will focus the input field by condition
    if (focusSearchBox) searchRefInternal.current.focus();
  }, [focusSearchBox, searchRefInternal]);

  const clearSearchInner = useCallback(
    (e) => {
      e && e.preventDefault();
      e && e.stopPropagation();

      setGroupSearch("");
      if (onClickClear) {
        onClickClear(e);
      } else {
        searchRefInternal.current.value = "";
        onChange({ ...e, target: { value: "" } });
      }
    },
    [setGroupSearch, onClickClear, searchRefInternal, onChange]
  );

  return (
    <div
      className={`search-wrapper  position-relative ${className} ${
        value === "" ? "" : " has-value"
      } ${withIcon ? "with-icon" : ""}`}
    >
      {withIcon && <SearchGraySvg className="search-icon" />}
      <input
        type="text"
        className="search-input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
        ref={searchRefInternal}
        style={{ color: hasSearchingError ? "red" : "" }}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {loading && (
        <div className="clear-icon mr-2">
          <div className="spinner-border search-box-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {value && hasClearQueryButton && (
        <a
          href="#void"
          className="clear-icon material-icons m-0 p-0"
          style={loading ? { transition: "none", visibility: "hidden" } : {}}
          onClick={clearSearchInner}
        >
          {globalTheme === GLOBAL_THEME.DARK ? (
            <CircleXMarkWhite className="m-0 p-0" />
          ) : (
            <CircleXMarkBlack className="m-0 p-0" />
          )}
        </a>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  onKeyUp: PropTypes.func,
  onPaste: PropTypes.func,
  onClickClear: PropTypes.func,
  hasSearchingError: PropTypes.bool,
  focusSearchBox: PropTypes.string,
  ref: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  hasClearQueryButton: PropTypes.bool,
  withIcon: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

SearchBox.defaultProps = {
  loading: false,
  placeholder: "",
  className: "",
  hasSearchingError: null,
  focusSearchBox: "",
  disabled: false,
  hasClearQueryButton: true,
  withIcon: false,
  autoFocus: false,
};

export default SearchBox;
