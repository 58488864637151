const initialState = {
  rejectStatus: {
    default: "",
  },

  autoMergeStatus: "",

  wizardGetterStatus: "",
  wizardData: {
    has_multiple_matches: [],
    has_one_match: [],
  },
  wizardInfo: null,

  contactMatchesStatus: "",
  contactMatchesMoreStatus: "",
  contactMatches: {},
  contactMatchesWizardInfo: {
    page: 1,
    limit: 5,
  },

  mergeContactStatus: {
    default: "",
  },

  solvedContact: null,

  contactsStatus: "",
  contactsMoreStatus: "",
  contactsData: {},
  contactsIds: [],
  contactsPage: 1,
  contactsLoadedAll: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SMART_SYNC/SET_CONTACTS":
      return {
        ...state,
        contactsData: action.contactsData,
        contactsIds: action.contactsIds,
      };

    case "SMART_SYNC/POST_REJECT":
      return {
        ...state,
        rejectStatus: {
          ...state.rejectStatus,
          [action.requestId]: "loading",
        },
      };

    case "SMART_SYNC/POST_REJECT.SUCCESS": {
      let newContactsIds = [...state.contactsIds];
      const newContactsData = { ...state.contactsData };

      let solvedContact = null;

      // save the solved contact for the snackbar
      if (action.contactIds.length === 1) {
        solvedContact = newContactsData[action.contactIds[0]];
      }

      for (let id of action.contactIds) {
        delete newContactsData[id];
        newContactsIds = newContactsIds.filter(
          (contactId) => Number(contactId) !== Number(id)
        );
      }

      return {
        ...state,
        rejectStatus: {
          ...state.rejectStatus,
          [action.requestId]: "success",
        },
        contactsData: newContactsData,
        contactsIds: newContactsIds,
        solvedContact,
      };
    }

    case "SMART_SYNC/POST_REJECT.ERROR":
      return {
        ...state,
        rejectStatus: {
          ...state.rejectStatus,
          [action.requestId]: "error",
        },
      };

    case "SMART_SYNC/POST_REJECT.CLEAR":
      return {
        ...state,
        rejectStatus: {
          default: "",
        },
      };

    case "SMART_SYNC/AUTO_MERGE":
      return {
        ...state,
        autoMergeStatus: "loading",
      };

    case "SMART_SYNC/AUTO_MERGE.SUCCESS":
      return {
        ...state,
        autoMergeStatus: "success",
      };

    case "SMART_SYNC/AUTO_MERGE.ERROR":
      return {
        ...state,
        autoMergeStatus: "error",
      };

    case "SMART_SYNC/AUTO_MERGE.CLEAR":
      return {
        ...state,
        autoMergeStatus: "",
      };

    case "SMART_SYNC/GET_WIZARD":
      return {
        ...state,
        wizardGetterStatus: "loading",
      };

    case "SMART_SYNC/GET_WIZARD.SUCCESS":
      return {
        ...state,
        wizardGetterStatus: "success",
        wizardData: action.payload.data,
        wizardInfo: action.payload.wizard_info,
      };

    case "SMART_SYNC/GET_WIZARD.ERROR":
      return {
        ...state,
        wizardGetterStatus: "error",
      };

    case "SMART_SYNC/GET_WIZARD.CLEAR":
      return {
        ...state,
        wizardGetterStatus: "",
      };

    case "SMART_SYNC/GET_CONTACT_MATCHES":
      return {
        ...state,
        contactMatchesStatus: "loading",
        contactMatchesMoreStatus: action.page > 1 ? "loading" : "",
      };

    case "SMART_SYNC/GET_CONTACT_MATCHES.SUCCESS":
      return {
        ...state,
        contactMatchesStatus: "success",
        contactMatchesMoreStatus: action.page > 1 ? "success" : "",
        contactMatches: action.isAllSelected
          ? {
              ...state.contactMatches,
              ...action.payload.data,
            }
          : action.payload.data,
        contactMatchesWizardInfo: action.payload.wizard_info,
      };

    case "SMART_SYNC/GET_CONTACT_MATCHES.CLEAR":
      return {
        ...state,
        contactMatchesStatus: "",
        contactMatchesMoreStatus: action.page > 1 ? "error" : "",
      };

    case "SMART_SYNC/GET_CONTACT_MATCHES.ERROR":
      return {
        ...state,
        contactMatchesStatus: "error",
      };

    case "SMART_SYNC/MERGE_CONTACT":
      return {
        ...state,
        mergeContactStatus: {
          ...state.mergeContactStatus,
          [action.requestId]: `loading`,
        },
      };

    case "SMART_SYNC/MERGE_CONTACT.SUCCESS": {
      let newContactsIds = [...state.contactsIds];
      const newContactsData = { ...state.contactsData };

      let solvedContact = null;

      // save the solved contact for the snackbar
      if (action.contactIds.length === 1) {
        solvedContact = newContactsData[action.contactIds[0]];
      }

      for (let id of action.contactIds) {
        delete newContactsData[id];
        newContactsIds = newContactsIds.filter(
          (contactId) => Number(contactId) !== Number(id)
        );
      }

      return {
        ...state,
        mergeContactStatus: {
          ...state.mergeContactStatus,
          [action.requestId]: `success`,
        },
        contactsData: newContactsData,
        contactsIds: newContactsIds,
        solvedContact,
      };
    }

    case "SMART_SYNC/MERGE_CONTACT.CLEAR":
      return {
        ...state,
        mergeContactStatus: {
          ...state.mergeContactStatus,
          default: ``,
        },
      };

    case "SMART_SYNC/MERGE_CONTACT.ERROR":
      return {
        ...state,
        mergeContactStatus: {
          ...state.mergeContactStatus,
          [action.requestId]: `error`,
        },
      };

    case "SMART_SYNC/FETCH_CONTACTS":
      return {
        ...state,
        contactsPage: action.page,
        contactsStatus: "loading",
        contactsMoreStatus: action.page > 1 ? "loading" : "",
      };

    case "SMART_SYNC/FETCH_CONTACTS.SUCCESS":
      return {
        ...state,
        contactsData:
          action.page > 1
            ? { ...state.contactsData, ...action.payload.contacts }
            : action.payload.contacts,
        contactsIds:
          action.page > 1
            ? [...state.contactsIds, ...action.payload.contacts_ids]
            : action.payload.contacts_ids,
        contactsStatus: "success",
        contactsMoreStatus: action.page > 1 ? "success" : "",
      };

    case "SMART_SYNC/FETCH_CONTACTS.ERROR":
      return {
        ...state,
        contactsData: {},
        contactsIds: [],
        contactsStatus: "error",
        contactsMoreStatus: action.page > 1 ? "error" : "",
      };

    default:
      return state;
  }
}
