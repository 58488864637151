import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../../../../modals/Helpers/ModalWrapper";
import ButtonWithSpinner from "../../../HelperComponents/ButtonWithSpinner";
import useShortLinkStore from "../../../../../utils/hooks/ReduxHooks/shortLinkStore";
import useCompaniesStore from "../../../../../utils/hooks/ReduxHooks/companiesStore";
import {
  injectTextInfoTextField,
  restoreCursorPosition,
} from "../../../../../utils/messagesHelpers";
import useMainStore from "../../../../../utils/hooks/ReduxHooks/mainStore";
import { history } from "../../../../../store";
import clsx from "clsx";

const VideoLengthExceedModal = (props) => {
  const {
    show,
    closeModal,
    onClickedTrimVideo,
    textAreaRef,
    onMessageTextChange,
    onWarningTextChange,
    videoLengthExceedData,
  } = props;

  // Redux store
  const { addVideoShortLink, clearAddVideoShortLink, shortLinks } =
    useShortLinkStore();
  const { companies } = useCompaniesStore();
  const { addInfoSnackBar } = useMainStore();
  const { currentCompany } = companies;
  const { addVideoStatus, addVideoUrls, addVideoErrors } = shortLinks;

  const isVideoLibraryTemplate = useMemo(() => {
    return history.location.pathname.includes(
      "video-library/submenu/video-library"
    );
  }, []);

  useEffect(() => {
    if (addVideoStatus === "error") {
      const errorObj =
        addVideoErrors &&
        Array.isArray(addVideoErrors) &&
        Boolean(addVideoErrors.length)
          ? addVideoErrors[0]
          : {};
      let errorMessage =
        errorObj?.url?.unique ||
        "Something went wrong. Please try again Later!";
      addInfoSnackBar({
        msg: errorMessage,
        err: true,
      });
      clearAddVideoShortLink();
      closeModal();
    }
  }, [
    addInfoSnackBar,
    addVideoErrors,
    addVideoStatus,
    clearAddVideoShortLink,
    closeModal,
  ]);

  useEffect(() => {
    // Inject the video link into the message
    if (addVideoStatus === "success") {
      let newValue = "";
      let restorePosition = null;
      try {
        ({ newValue, restorePosition } = injectTextInfoTextField(
          textAreaRef.current,
          addVideoUrls.join(" ")
        ));
      } catch (error) {
        textAreaRef.current.focus();
        if (error.message === "too-long") {
          const beggining =
            addVideoUrls.length > 1 ? "Video links" : "The Video link";
          onWarningTextChange(
            `${beggining} cannot be inserted because the message would exceed the limit of 1600 characters.`
          );
        }
      }

      clearAddVideoShortLink();
      onMessageTextChange(newValue);
      closeModal();
      if (restorePosition) {
        restoreCursorPosition(textAreaRef.current, restorePosition);
      }
    }
  }, [
    addVideoStatus,
    addVideoUrls,
    clearAddVideoShortLink,
    onMessageTextChange,
    onWarningTextChange,
    textAreaRef,
    closeModal,
  ]);

  const onClickAttachVideoAsUrl = useCallback(() => {
    const formData = {
      data: [],
    };
    formData.data.push({
      shortname: "Auto created link to video",
      url: videoLengthExceedData?.file?.url,
      link_type: 2,
    });
    addVideoShortLink(currentCompany?.id, formData);
  }, [addVideoShortLink, currentCompany, videoLengthExceedData]);

  return (
    <ModalWrapper
      id="VideoLengthExceedModal"
      show={show}
      closeModal={closeModal}
      className="large-video-confirmation-modal-container-modal"
    >
      <div className="large-video-confirmation-modal-container">
        <h1 className="large-video-confirmation-modal-title">
          Video Length Limit Exceeded
        </h1>
        <div className="large-video-confirmation-modal-sub-container">
          <p className="large-video-confirmation-modal-description">
            Your video exceeds our 25-second limit. To proceed, you can either
            trim the video within 25 seconds using a video trimmer tool, or
            share the video as a URL.
          </p>
        </div>
        <div
          className={clsx("large-video-confirmation-modal-footer", {
            "justify-content-end": isVideoLibraryTemplate,
          })}
        >
          {!isVideoLibraryTemplate && (
            <ButtonWithSpinner
              loading={addVideoStatus === "loading"}
              disabled={addVideoStatus === "loading"}
              bootstrapButtonClassName={
                "btn-outline-primary large-video-border-buttons"
              }
              onClick={onClickAttachVideoAsUrl}
            >
              Send Video as URL (Hi-Res)"
            </ButtonWithSpinner>
          )}
          <ButtonWithSpinner
            disabled={addVideoStatus === "loading"}
            onClick={onClickedTrimVideo}
          >
            Trim Real Video (Lo-res)
          </ButtonWithSpinner>
        </div>
      </div>
    </ModalWrapper>
  );
};

VideoLengthExceedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  characters: PropTypes.number.isRequired,
  textAreaRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onMessageTextChange: PropTypes.func,
  onWarningTextChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  videoLengthExceedData: PropTypes.object,
};

VideoLengthExceedModal.defaultProps = {
  text: "",
  videoLengthExceedData: {},
};

export default VideoLengthExceedModal;
