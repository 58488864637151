import { ReactComponent as AccountCircleIcon } from "../../../assets/img/icons-new/sidebar-dropright/settings-tab/user-settings-dark-black.svg";
import { ReactComponent as AdminPanelSetting } from "../../../assets/img/icons-new/sidebar-dropright/settings-tab/admin-settings-dark-black.svg";
import { ReactComponent as AppSettingIcon } from "../../../assets/img/icons-new/sidebar-dropright/settings-tab/hub-settings-dark-black.svg";
import { ReactComponent as AllMessagesIcon } from "../../../assets/img/icons-new/message-tab/all-messages/all-messages-gray.svg";

import { ReactComponent as AllVideosIcon } from "../../../assets/img/icons-new/general/video/video-darker-grey.svg";

import { ReactComponent as CallIcon } from "../../../assets/img/icons-new/main-nav-v2/call/call-purple.svg";
import { ReactComponent as CampaignTemplatesIcon } from "../../../assets/img/icons-new/sidebar-dropright/templates-tab/templates-campaing-templates-gray.svg";
import { ReactComponent as LinkLighterDarkGreySvg } from "../../../assets/img/icons-new/general/link/link-lighter-darker-grey.svg";
// import { ReactComponent as CustomFieldsIcon } from "../../../assets/img/icons-new/sidebar-dropright/templates-tab/custom-fields-grey.svg";
import { ReactComponent as UnfavoriteIcon } from "../../../assets/img/icons-new/message-tab/unfavorites/unfavorite-purple.svg";
import { ReactComponent as IntegrationsIcon } from "../../../assets/img/icons-new/group-tab/integrations/integrations-black.svg";
import { ReactComponent as LocalNumberIcon } from "../../../assets/img/icons-new/number-type/local/local-gray.svg";
import { ReactComponent as ShortCodeIcon } from "../../../assets/img/icons-new/other/short-code/short-code-light-gray.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/img/icons-new/people-tab/archived/archived-light-gray.svg";
import { ReactComponent as KeywordsIcon } from "../../../assets/img/icons-new/general/keywords/keywords-grey.svg";

import {
  ADMIN_BILLING_ROUTE,
  ADMIN_INTEGRATIONS_ROUTE,
  ADMIN_NUMBERS_ROUTE,
  ADMIN_ORGANIZATION_PROFILE_ROUTE,
  ADMIN_SECURITY_ROUTE,
  ADMIN_SETTINGS_ROUTE,
  ADMIN_INTEGRATIONS_ACTIVE_APPS_ROUTE,
  ADMIN_USERS_ACTIVE_USERS_ROUTE,
  ADMIN_USERS_ROUTE,
  APP_SETTINGS_ROUTE,
  APP_SETTINGS_MESSAGES_ROUTE,
  APP_SETTINGS_VOICE_ROUTE,
  APP_SETTINGS_PEOPLE_ROUTE,
  APP_SETTINGS_GROUPS_ROUTE,
  APP_SETTINGS_CAMPAINGS_ROUTE,
  APP_SETTINGS_ANALYTICS_ROUTE,
  // USER_REWARDS_CENTER_ROUTE,
  // USER_REWARDS_CENTER_REFERRAL_ROUTE,
  USER_GREETINGS_ROUTE,
  USER_NOTIFICATIONS_ROUTE,
  USER_NUMBER_ROUTE,
  USER_ORGANIZATIONS_ROUTE,
  USER_PROFILE_ROUTE,
  USER_SETTINGS_ROUTE,
  USER_SIGNATURES_ROUTE,
  USER_VOICEMAIL_ROUTE,
  ADMIN_BILLING_CURRENT_PLAN_ROUTE,
  MESSAGE_TEMPLATES_ROUTE,
  VIDEO_LIBRARY_ROUTE,
  MESSAGE_TEMPLATES_SMS_ROUTE,
  MESSAGE_TEMPLATES_VOICE_ROUTE,
  MESSAGE_TEMPLATES_EMAIL_ROUTE,
  CAMPAIGN_TEMPLATES_ROUTE,
  SHORTENED_LINKS_ROUTE,
  CAMPAIGN_TEMPLATES_CAMPAIGN_ROUTE,
  CAMPAIGN_TEMPLATES_CUSTOM_FIELDS_ROUTE,
  CAMPAIGN_TEMPLATES_MODULES_ROUTE,
  CUSTOM_FIELDS_ROUTE,
  KEYWORDS_ALL_KEYWORDS_ROUTE,
  KEYWORDS_FAVORITES_ROUTE,
  KEYWORDS_LOCAL_NUMBERS_ROUTE,
  KEYWORDS_CAMPAIGN_ALL_ROUTE,
  KEYWORDS_CAMPAIGN_SHORT_CODE_ROUTE,
  KEYWORDS_CAMPAIGN_LOCAL_NUMBERS_ROUTE,
  KEYWORDS_INTEGRATIONS_NONE_ROUTE,
  KEYWORDS_INTEGRATIONS_BREEZE_ROUTE,
  KEYWORDS_INTEGRATIONS_CCB_ROUTE,
  KEYWORDS_INTEGRATIONS_ELVANTO_ROUTE,
  KEYWORDS_INTEGRATIONS_MAILCHIMP_ROUTE,
  KEYWORDS_INTEGRATIONS_PCO_ROUTE,
  KEYWORDS_SHORT_CODE_MY_KEYWORDS_ROUTE,
  KEYWORDS_SHORT_CODE_WATCH_LIST_ROUTE,
  KEYWORDS_SHORT_CODE_STORE_ROUTE,
  KEYWORDS_RELEASED_ALL_ROUTE,
  KEYWORDS_RELEASED_LOCAL_NUMBERS_ROUTE,
  KEYWORDS_RELEASED_SHORT_CODE_ROUTE,
  ANALYTICS_LOGS_ROUTE,
  ANALYTICS_REPORTS_ROUTE,
  ANALYTICS_MESSAGES_CALLS_ROUTE,
  ADMIN_SIGNATURES_ROUTE,
  ANALYTICS_LOGS_NEW_ROUTE,
  ANALYTICS_LOGS_LEGACY_ROUTE,
  USER_AFFILIATE_ROUTE,
} from "../routes";

export const subMenusSettingsLabelRoutes = {
  [USER_SETTINGS_ROUTE]: "User Settings", // User Settings
  [USER_PROFILE_ROUTE]: "My Profile",
  [USER_NUMBER_ROUTE]: "My Numbers",
  [USER_SIGNATURES_ROUTE]: "My Signatures",
  [USER_GREETINGS_ROUTE]: "Greetings",
  [USER_VOICEMAIL_ROUTE]: "Voicemail",
  [USER_NOTIFICATIONS_ROUTE]: "Notifications",
  // [USER_REWARDS_CENTER_ROUTE]: "RewardsCenter",
  [USER_ORGANIZATIONS_ROUTE]: "My Organizations",
  [USER_AFFILIATE_ROUTE]: "Affiliate",

  [ADMIN_SETTINGS_ROUTE]: "Admin Settings", // Admin settings
  [ADMIN_ORGANIZATION_PROFILE_ROUTE]: "Organization Profile",
  [ADMIN_USERS_ROUTE]: "Users",
  [ADMIN_BILLING_ROUTE]: "Billing",
  [ADMIN_NUMBERS_ROUTE]: "Numbers",
  [ADMIN_INTEGRATIONS_ROUTE]: "Integrations",
  [ADMIN_SECURITY_ROUTE]: " Security",
  [APP_SETTINGS_ROUTE]: "App", // App settings
  [APP_SETTINGS_MESSAGES_ROUTE]: "Messages",
  [APP_SETTINGS_VOICE_ROUTE]: "Voice",
  [APP_SETTINGS_PEOPLE_ROUTE]: "People",
  [APP_SETTINGS_GROUPS_ROUTE]: "Groups",
  [APP_SETTINGS_CAMPAINGS_ROUTE]: "Campaigns",
  [APP_SETTINGS_ANALYTICS_ROUTE]: "Analytics",

  [MESSAGE_TEMPLATES_ROUTE]: "Message Templates",
  [VIDEO_LIBRARY_ROUTE]: "Video Library", // Message Templates
  [MESSAGE_TEMPLATES_SMS_ROUTE]: "Text Templates",
  [MESSAGE_TEMPLATES_VOICE_ROUTE]: "Voice Templates",
  [MESSAGE_TEMPLATES_EMAIL_ROUTE]: "Email Templates",
  [CAMPAIGN_TEMPLATES_ROUTE]: "Campaign Templates", // Campaign Templates
  [CAMPAIGN_TEMPLATES_CAMPAIGN_ROUTE]: "Campaigns",
  [CAMPAIGN_TEMPLATES_CUSTOM_FIELDS_ROUTE]: "Campaign Custom Fields",
  [CAMPAIGN_TEMPLATES_MODULES_ROUTE]: "Modules",
  [SHORTENED_LINKS_ROUTE]: "Shortened Links", // Shortened Links
  [CUSTOM_FIELDS_ROUTE]: "Custom Fields", // Custom Fields

  [KEYWORDS_ALL_KEYWORDS_ROUTE]: "All Keywords", // Keywords
  [KEYWORDS_FAVORITES_ROUTE]: "Favorites",
  [KEYWORDS_CAMPAIGN_ALL_ROUTE]: "Campaign > All ", // Campaign
  [KEYWORDS_CAMPAIGN_LOCAL_NUMBERS_ROUTE]: "Campaign > Local Numbers",
  [KEYWORDS_CAMPAIGN_SHORT_CODE_ROUTE]: "Campaign > Short Code",
  [KEYWORDS_INTEGRATIONS_NONE_ROUTE]: "Integrations > No (Pastorline)", // Integrations
  [KEYWORDS_INTEGRATIONS_BREEZE_ROUTE]: "Integrations > Breeze",
  [KEYWORDS_INTEGRATIONS_CCB_ROUTE]: "Integrations > CCB",
  [KEYWORDS_INTEGRATIONS_ELVANTO_ROUTE]: "Integrations > Elvanto",
  [KEYWORDS_INTEGRATIONS_MAILCHIMP_ROUTE]: "Integrations > Mail Chimp",
  [KEYWORDS_INTEGRATIONS_PCO_ROUTE]: "Integrations > PCO",
  [KEYWORDS_LOCAL_NUMBERS_ROUTE]: "Local Numbers", // Local Numbers
  [KEYWORDS_SHORT_CODE_MY_KEYWORDS_ROUTE]: "Short Code > My Keywords", // Short Code
  [KEYWORDS_SHORT_CODE_WATCH_LIST_ROUTE]: "Short Code > Watch List",
  [KEYWORDS_SHORT_CODE_STORE_ROUTE]: "Short Code > Store",
  [KEYWORDS_RELEASED_ALL_ROUTE]: "Released  > All", // Released
  [KEYWORDS_RELEASED_LOCAL_NUMBERS_ROUTE]: "Released > Local Numbers",
  [KEYWORDS_RELEASED_SHORT_CODE_ROUTE]: "Released > Short code",
};

// Settings sub menus
export const settingsSubMenus = [
  {
    id: "userSettings",
    route: USER_SETTINGS_ROUTE,
    label: "User Settings",
    icon: AccountCircleIcon,
    items: [
      {
        title: "My Profile",
        route: `/submenu/${USER_PROFILE_ROUTE}`,
      },
      {
        title: "Preferences",
        route: `#modal-display-preferences`,
      },
      {
        title: "My Numbers",
        route: `/submenu/${USER_NUMBER_ROUTE}`,
        userPermission: "user_settings_my_numbers",
      },
      {
        title: "My Signatures",
        route: `/submenu/${USER_SIGNATURES_ROUTE}`,
        userPermission: "user_settings_my_signatures",
      },
      {
        id: "greetingsVm",
        title: "Greetings/VM",
        menus: [
          {
            title: "Greetings",
            route: `/submenu/${USER_GREETINGS_ROUTE}`,
            userPermission: "user_settings_greetings",
          },
          {
            title: "Voicemail",
            route: `/submenu/${USER_VOICEMAIL_ROUTE}`,
            userPermission: "user_settings_voicemails",
          },
        ],
      },
      {
        title: "Notifications",
        route: `/submenu/${USER_NOTIFICATIONS_ROUTE}/all`,
        userPermission: "notifications",
        mobileRoute: `/submenu/${USER_NOTIFICATIONS_ROUTE}`,
      },
      {
        title: "My Organizations",
        route: `/submenu/${USER_ORGANIZATIONS_ROUTE}`,
        userPermission: "organizations",
        permissionType: "list",
      },
      {
        title: "Affiliate",
        route: `/submenu/${USER_AFFILIATE_ROUTE}`,
        isNewMenu: true,
      },
    ],
  },
  {
    id: "adminSettings",
    route: ADMIN_SETTINGS_ROUTE,
    label: "Admin Settings",
    icon: AdminPanelSetting,
    items: [
      {
        id: "organization-profile",
        title: "Organization Profile",
        route: `/submenu/${ADMIN_ORGANIZATION_PROFILE_ROUTE}`,
        withoutPermission: false, // this is used inside SubMenuSettings to get the particular company permission, will be replace there with correct value
      },
      {
        title: "Users",
        route: `/submenu/${ADMIN_USERS_ROUTE}/${ADMIN_USERS_ACTIVE_USERS_ROUTE}`,
        userPermission: "admin_settings_users",
      },
      {
        title: "Billing",
        route: `/submenu/${ADMIN_BILLING_ROUTE}/${ADMIN_BILLING_CURRENT_PLAN_ROUTE}`,
        userPermission: "billings",
      },
      {
        title: "Signatures",
        route: `/submenu/${ADMIN_SIGNATURES_ROUTE}`,
        userPermission: "admin_settings_signatures",
      },
      {
        title: "Numbers",
        route: `/submenu/${ADMIN_NUMBERS_ROUTE}`,
        userPermission: "admin_settings_numbers",
      },
      {
        title: "Integrations",
        route: `/submenu/${ADMIN_INTEGRATIONS_ROUTE}/${ADMIN_INTEGRATIONS_ACTIVE_APPS_ROUTE}`,
        userPermission: "admin_settings_integrations",
      },
      {
        title: "Security",
        route: `/submenu/${ADMIN_SECURITY_ROUTE}`,
        userPermission: "security",
      },
    ],
  },
  {
    id: "appSettings",
    route: APP_SETTINGS_ROUTE,
    label: "App Settings",
    icon: AppSettingIcon,
    items: [
      {
        title: "Messages",
        route: `/submenu/${APP_SETTINGS_MESSAGES_ROUTE}`,
      },
      {
        title: "Voice",
        route: `/submenu/${APP_SETTINGS_VOICE_ROUTE}`,
        userPermission: "messages_calls",
      },
      {
        title: "People",
        route: `/submenu/${APP_SETTINGS_PEOPLE_ROUTE}`,
        userPermission: "people",
      },
      {
        title: "Groups",
        route: `/submenu/${APP_SETTINGS_GROUPS_ROUTE}`,
        userPermission: "groups",
      },
    ],
  },
];

export const collapsibleSettingsMenus = [
  {
    id: "userSettings",
    parent_id: "heading-userSettings",
    items: [
      "embed",
      // USER_REWARDS_CENTER_ROUTE,
      USER_NOTIFICATIONS_ROUTE,
      USER_NUMBER_ROUTE,
      USER_ORGANIZATIONS_ROUTE,
      USER_PROFILE_ROUTE,
      USER_SETTINGS_ROUTE,
      USER_SIGNATURES_ROUTE,
      USER_AFFILIATE_ROUTE,
    ],
    groups: [
      {
        id: "greetingsVm",
        parent_id: "heading-greetingsVm",
        items: [USER_GREETINGS_ROUTE, USER_VOICEMAIL_ROUTE],
      },
    ],
  },
  {
    id: "adminSettings",
    parent_id: "heading-adminSettings",
    items: [
      ADMIN_BILLING_ROUTE,
      ADMIN_INTEGRATIONS_ROUTE,
      ADMIN_NUMBERS_ROUTE,
      ADMIN_ORGANIZATION_PROFILE_ROUTE,
      ADMIN_SECURITY_ROUTE,
      ADMIN_SIGNATURES_ROUTE,
      ADMIN_USERS_ROUTE,
    ],
  },
  {
    id: "appSettings",
    parent_id: "heading-appSettings",
    items: [
      APP_SETTINGS_MESSAGES_ROUTE,
      APP_SETTINGS_VOICE_ROUTE,
      APP_SETTINGS_PEOPLE_ROUTE,
      APP_SETTINGS_GROUPS_ROUTE,
    ],
  },
];

// Templates sub menus

export const templatesSubMenus = [
  {
    id: "textTemplates",
    route: `${MESSAGE_TEMPLATES_ROUTE}/submenu/${MESSAGE_TEMPLATES_SMS_ROUTE}`,
    withParentRoute: true,
    label: "Text Templates",
    icon: AllMessagesIcon,
  },
  {
    id: "voiceTemplates",
    route: `${MESSAGE_TEMPLATES_ROUTE}/submenu/${MESSAGE_TEMPLATES_VOICE_ROUTE}`,
    withParentRoute: true,
    label: "Voice Templates",
    icon: CallIcon,
  },
  // {
  //   id: "campaignTemplates",
  //   route: CAMPAIGN_TEMPLATES_ROUTE,
  //   label: "Campaign Templates",
  //   icon: CampaignTemplatesIcon,
  //   items: [
  //     {
  //       title: "Campaigns",
  //       route: `/submenu/${CAMPAIGN_TEMPLATES_CAMPAIGN_ROUTE}`,
  //     },
  //     {
  //       title: "Campaign Custom Fields",
  //       route: `/submenu/${CAMPAIGN_TEMPLATES_CUSTOM_FIELDS_ROUTE}`,
  //     },
  //     {
  //       title: "Modules",
  //       route: `/submenu/${CAMPAIGN_TEMPLATES_MODULES_ROUTE}`,
  //     },
  //   ],
  // },
  {
    id: "shortenedLinks",
    route: `${SHORTENED_LINKS_ROUTE}/submenu/${SHORTENED_LINKS_ROUTE}`,
    withParentRoute: true,
    label: "Shortened Links",
    icon: LinkLighterDarkGreySvg,
    userPermission: "shortlinks",
  },
  {
    id: "videoTemplates",
    route: `${VIDEO_LIBRARY_ROUTE}/submenu/${VIDEO_LIBRARY_ROUTE}`,
    withParentRoute: true,
    label: "Video Library",
    icon: AllVideosIcon,
  },
  // {
  //   id: "customFields",
  //   route: CUSTOM_FIELDS_ROUTE,
  //   label: "Custom Fields",
  //   icon: CustomFieldsIcon,
  //   items: [
  //     {
  //       title: "test",
  //       route: "",
  //     },
  //   ],
  // },
];

export const collapsibleTemplatesMenus = [
  {
    id: "messageTemplates",
    parent_id: "heading-messageTemplates",
    items: [MESSAGE_TEMPLATES_EMAIL_ROUTE],
  },
  {
    id: "campaignTemplates",
    parent_id: "heading-campaignTemplates",
    items: [
      CAMPAIGN_TEMPLATES_CAMPAIGN_ROUTE,
      CAMPAIGN_TEMPLATES_CUSTOM_FIELDS_ROUTE,
      CAMPAIGN_TEMPLATES_MODULES_ROUTE,
    ],
  },
];

// Keywords sub menus

export const keywordsSubMenus = [
  {
    id: "allKeywords",
    route: KEYWORDS_ALL_KEYWORDS_ROUTE,
    withParentRoute: true,
    label: "All Keywords",
    icon: KeywordsIcon,
  },
  {
    id: "favorites",
    route: KEYWORDS_FAVORITES_ROUTE,
    withParentRoute: true,
    label: "Favorites",
    icon: UnfavoriteIcon,
  },
  {
    id: "campaign",
    route: "",
    label: "Campaign",
    icon: CampaignTemplatesIcon,
    items: [
      {
        title: "All",
        route: `/${KEYWORDS_CAMPAIGN_ALL_ROUTE}`,
      },
      {
        title: "Local Numbers",
        route: `/${KEYWORDS_CAMPAIGN_LOCAL_NUMBERS_ROUTE}`,
      },
      {
        title: "Short Code (77411)",
        route: `/${KEYWORDS_CAMPAIGN_SHORT_CODE_ROUTE}`,
      },
    ],
  },
  {
    id: "integrations",
    route: "",
    label: "Integrations",
    icon: IntegrationsIcon,
    items: [
      {
        title: "None (Pastorsline)",
        route: `/${KEYWORDS_INTEGRATIONS_NONE_ROUTE}`,
      },
      {
        title: "Breeze",
        route: `/${KEYWORDS_INTEGRATIONS_BREEZE_ROUTE}`,
      },
      {
        title: "CCB",
        route: `/${KEYWORDS_INTEGRATIONS_CCB_ROUTE}`,
      },
      {
        title: "Elvanto",
        route: `/${KEYWORDS_INTEGRATIONS_ELVANTO_ROUTE}`,
      },
      {
        title: "MailChimp",
        route: `/${KEYWORDS_INTEGRATIONS_MAILCHIMP_ROUTE}`,
      },
      {
        title: "PCO",
        route: `/${KEYWORDS_INTEGRATIONS_PCO_ROUTE}`,
      },
    ],
  },
  {
    id: "localNumbers",
    route: KEYWORDS_LOCAL_NUMBERS_ROUTE,
    label: "Local Numbers",
    icon: LocalNumberIcon,
    withParentRoute: true,
  },
  {
    id: "shortCode",
    route: "short-code",
    label: "Short Code",
    icon: ShortCodeIcon,
    withParentRoute: true,
  },
  // {
  //   id: "shortCode",
  //   route: "",
  //   label: "Short Code",
  //   icon: ShortCodeIcon,
  //   items: [
  //     {
  //       title: "My Keywords",
  //       route: `/${KEYWORDS_SHORT_CODE_MY_KEYWORDS_ROUTE}`,
  //     },
  //     {
  //       title: "Watchlist",
  //       route: `/${KEYWORDS_SHORT_CODE_WATCH_LIST_ROUTE}`,
  //     },
  //     {
  //       title: "Watchlist",
  //       route: `/${KEYWORDS_SHORT_CODE_STORE_ROUTE}`,
  //     },
  //   ],
  // },
  {
    id: "released",
    route: "",
    label: "Released",
    icon: ArchiveIcon,
    items: [
      {
        title: "All",
        route: `/${KEYWORDS_RELEASED_ALL_ROUTE}`,
      },
      {
        title: "Local Numbers",
        route: `/${KEYWORDS_RELEASED_LOCAL_NUMBERS_ROUTE}`,
      },
      {
        title: "Short Code (77421)",
        route: `/${KEYWORDS_RELEASED_SHORT_CODE_ROUTE}`,
      },
    ],
  },
];

export const analyticsSubMenus = [
  {
    id: "logs",
    route: ANALYTICS_LOGS_ROUTE,
    label: "Logs",
    show: true,
    items: [
      {
        title: "V3 (Beta)",
        route: `/submenu/${ANALYTICS_LOGS_NEW_ROUTE}`,
      },
      {
        title: "V2 (Legacy)",
        route: `/submenu/${ANALYTICS_LOGS_LEGACY_ROUTE}`,
      },
    ],
  },
  {
    id: "reports",
    route: ANALYTICS_REPORTS_ROUTE,
    label: "Reports",
    userPermission: "analytics",
    items: [
      {
        title: "V2 (Legacy Reports)",
        route: `/submenu/${ANALYTICS_REPORTS_ROUTE}`,
      },
      {
        title: "Messages and Calls Summary",
        route: `/submenu/${ANALYTICS_MESSAGES_CALLS_ROUTE}`,
      },
    ],
  },
];

export const collapsibleAnalyticMenu = [
  {
    id: "logs",
    parent_id: "heading-logs",
    items: [ANALYTICS_LOGS_NEW_ROUTE, ANALYTICS_LOGS_LEGACY_ROUTE],
  },
];

export const campaignsSubMenus = [
  {
    id: "campaigns",
    filter: "campaigns",
    label: "All",
  },
  {
    id: "datacapture",
    filter: "campaigns/datacapture",
    label: "Autoreply (Keyword) <br /> and Data Capture",
  },
  {
    id: "automation",
    filter: "campaigns/automation",
    label: "Automated Message",
  },
  {
    id: "contest",
    filter: "campaigns/contest",
    label: "Contest",
  },
  {
    id: "poll",
    filter: "campaigns/poll",
    label: "Poll",
  },
  {
    id: "birthday",
    filter: "campaigns/birthday",
    label: "SMS Birthday",
  },
];
