import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BroadcastStatusTag = (props) => {
  const { label, className } = props;
  return <span className={clsx("ml-2", className)}>{label}</span>;
};

BroadcastStatusTag.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

BroadcastStatusTag.defaultProps = {
  className: "",
};

export default BroadcastStatusTag;
