import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../modals/CustomModal";

const StopNowScheduleMessageConfirmationModal = (props) => {
  const { stopScheduledNowConfirmed, stopScheduledNowCancel, loading } = props;
  return (
    <CustomModal
      show={true}
      title={"Stop Scheduled Message Now"}
      dialogClassName="modal-dialog-centered"
      buttons={[
        {
          type: "confirm",
          caption: loading ? "Stopping" : "Stop Now",
          handler: stopScheduledNowConfirmed,
          loading,
          disabled: loading,
        },
        {
          type: "cancel",
          caption: "Cancel",
          handler: stopScheduledNowCancel,
        },
      ]}
      closeModal={stopScheduledNowCancel}
    >
      <p className="mb-3 text-left text-dark" style={{ fontSize: "initial" }}>
        Are you sure you want to stop sending this scheduled message now?
      </p>
    </CustomModal>
  );
};

StopNowScheduleMessageConfirmationModal.propTypes = {
  stopScheduledNowConfirmed: PropTypes.func.isRequired,
  stopScheduledNowCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

StopNowScheduleMessageConfirmationModal.defaultProps = {
  loading: false,
};

export default StopNowScheduleMessageConfirmationModal;
