import React from "react";
import PropTypes from "prop-types";
import { LightTooltip } from "../../../../helpers";
import { Chip } from "@material-ui/core";

const Feature = (props) => {
  const {
    svg,
    text,
    onClick,
    className,
    tooltipTitle,
    loading,
    interactive,
    more,
    isBetaFeature,
  } = props;

  return (
    <LightTooltip title={tooltipTitle} interactive={interactive}>
      <div className={`feature-container ${className}`} onClick={onClick}>
        {svg}
        <span className="ml-1">
          {text}{" "}
          {isBetaFeature && (
            <Chip className="beta-chip" label="Beta" size="small" />
          )}
          {loading && (
            <span
              className="spinner-border spinner-border-sm spinner-xs ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </span>
        {more && (
          <span className="header-button__more-arrow ml-2 black-more"></span>
        )}
      </div>
    </LightTooltip>
  );
};

Feature.propTypes = {
  svg: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltipTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  loading: PropTypes.bool,
  interactive: PropTypes.bool,
  more: PropTypes.bool,
  isBetaFeature: PropTypes.bool,
};

Feature.defaultProps = {
  className: "",
  tooltipTitle: "",
  loading: false,
  interactive: false,
  more: false,
  isBetaFeature: false,
  onClick: () => {},
};

export default Feature;
