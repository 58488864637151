export const getMappedLocalStorageForLoadingDebugPayload = () => {
  const cloneLocalStorage = { ...window.localStorage };
  const persistStorage = cloneLocalStorage["persist:root"]
    ? JSON.parse(cloneLocalStorage["persist:root"])
    : {};
  const { countries, threads, contacts, groups, ...rest } = persistStorage;
  const localStorageForPayload = {
    ...cloneLocalStorage,
    "persist:root": rest,
  };
  return JSON.stringify(localStorageForPayload);
};
