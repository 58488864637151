import { useCallback } from "react";
import PropTypes from "prop-types";
import USFlag from "../assets/img/us-flag.png";
import { ReactComponent as GlobeSvg } from "../assets/img/icons-new/settings/globe/globe-gray.svg";
import { ReactComponent as CanadaCountryFlag } from "../assets/img/icons-new/general/flags/Canada-flag.svg";

const NumberCountryFlag = (props) => {
  const {
    countryCode,
    number,
    className,
    flagIconSize,
    flagContainerClassName,
    innerClassName,
  } = props;

  const getCountryCodeFlag = useCallback(() => {
    if (countryCode === "US") {
      return (
        <div className={flagContainerClassName}>
          <img src={USFlag} alt="US Flag" height={flagIconSize} />
        </div>
      );
    } else if (countryCode === "CA") {
      return (
        <div className={flagContainerClassName}>
          <CanadaCountryFlag height={flagIconSize} />
        </div>
      );
    }
    return (
      <div className={flagContainerClassName}>
        <GlobeSvg height={flagIconSize} />
      </div>
    );
  }, [countryCode, flagContainerClassName, flagIconSize]);

  return (
    <div className={className}>
      <div className={innerClassName}>{getCountryCodeFlag()}</div>
      {number}
    </div>
  );
};

NumberCountryFlag.propTypes = {
  flagIconSize: PropTypes.number,
  flagContainerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  className: PropTypes.string,
};

NumberCountryFlag.defaultProps = {
  flagIconSize: 18,
  flagContainerClassName: "mr-2",
  innerClassName: "",
  className: "",
};

export default NumberCountryFlag;
