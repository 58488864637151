const initialState = {
  campaignEmbedURL: "campaigns",
};

export default function campaigns(state = initialState, action) {
  switch (action.type) {
    case "CAMPAIGNS/SET_CAMPAIGNS_EMBED_URL": {
      return {
        ...state,
        campaignEmbedURL: action.embedURL,
      };
    }
    default:
      return state;
  }
}
